import { OnInit, Renderer2, AfterViewChecked, } from "@angular/core";
import { Validators, FormBuilder } from "@angular/forms";
import { ScsService } from "../../services/scs.service";
import { Chart, Bar, HistorySegment, HistoricalPerformance, TopGraphic, BottomPanel, Profile, Preset, } from "../../../core/models/app.models";
import { HISTORICAL_PERFORMANCE, ROUTE_PATH, CUSTOMIZATION_TYPE, CURRENCY, CUSTOM_PROFILE, LABEL_COLOR, } from "../../../constants/constants";
import { distinctUntilChanged } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { isNumber } from "util";
import { UtilityService } from "src/app/core/services/utility.service";
import { Theme, LinkIcon, Orientation } from "equitable-component-library";
import { ModalPopupComponent } from "src/app/core/components/modal-popup/modal-popup.component";
import { CoreService } from "../../../core/services/core.service";
var SegmentPerformanceComponent = /** @class */ (function () {
    /***
     *  Constructor
     ***/
    function SegmentPerformanceComponent(renderer, formBuilder, scsService, translateService, utilityService, coreService) {
        this.renderer = renderer;
        this.formBuilder = formBuilder;
        this.scsService = scsService;
        this.translateService = translateService;
        this.utilityService = utilityService;
        this.coreService = coreService;
        this.CUSTOMIZATION_TYPE = CUSTOMIZATION_TYPE;
        this.type = Theme.Dark;
        this.orientation = Orientation.Right;
        this.customProfile = "historicalPerformance.customProfile";
        this.customizeReport = "historicalPerformance.customizeReport";
        this.HISTORICAL_PERFORMANCE = HISTORICAL_PERFORMANCE;
        this.series = this.scsService.series;
        this.indexSelectionMaxCount = HISTORICAL_PERFORMANCE.INDEX_SELECTION_MAX_COUNT;
        this.doughnutData = [];
        this.doughnutlegends = [];
        this.maturityValue = 0;
        this.indexValue = 0;
        this.investment = HISTORICAL_PERFORMANCE.INVESTMENT;
        this.mode = HISTORICAL_PERFORMANCE.MODE;
        this.linkRightIcon = LinkIcon.RightIcon;
        this.isInitalLoadCompleted = false;
    }
    /***
     *  Page Load
     ***/
    SegmentPerformanceComponent.prototype.ngOnInit = function () {
        this.series = this.scsService.series;
        //if any customization report, profile show buttons
        this.customizationType = this.scsService.customizationType;
        //create form
        this.buildForm();
        //get historical data from service
        this.getHistoricalPerformance();
    };
    SegmentPerformanceComponent.prototype.ngAfterViewChecked = function () {
        if (this.historicalForm.get("index").value &&
            this.historicalForm.get("duration").value &&
            this.historicalForm.get("protection").value)
            this.isInitalLoadCompleted = true;
    };
    /***
     *  Create Form and intialize the values
     ***/
    SegmentPerformanceComponent.prototype.buildForm = function () {
        var _this = this;
        this.historicalForm = this.formBuilder.group({
            index: ["", Validators.required],
            duration: ["", Validators.required],
            protection: ["", Validators.required],
        });
        //Form on changes
        this.historicalForm.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe(function (data) {
            if (data.index &&
                data.index.length > 0 &&
                data.duration &&
                data.protection) {
                //build
                if (_this.historicalPerformance &&
                    _this.historicalPerformance.length > 0) {
                    _this.protectionOptions = Array.from(new Set(_this.historicalPerformance
                        .find(function (x) { return x.id === data.duration; })
                        .historicalPerformance.map(function (x) { return x.id; }))).map(function (x) { return ({
                        id: x,
                        value: x.replace("Year", "Year, -"),
                    }); });
                }
                //update data based on user selection
                var arr_1 = [];
                data.index.forEach(function (element, index) {
                    var obj = new HistorySegment();
                    obj.id = element.key;
                    obj.name = element.value;
                    obj.color = LABEL_COLOR[index];
                    obj.percentage =
                        _this.mode === HISTORICAL_PERFORMANCE.MODE &&
                            element.percentage != 0
                            ? Math.floor(element.percentage)
                            : Math.floor(HISTORICAL_PERFORMANCE.PERCENTAGE_MAX /
                                data.index.length /
                                HISTORICAL_PERFORMANCE.PERCENTAGE_MAX); //check if preset data is available for inital load
                    obj.value =
                        _this.mode === HISTORICAL_PERFORMANCE.MODE &&
                            element.percentage != 0
                            ? Math.floor(HISTORICAL_PERFORMANCE.PERCENTAGE_MAX * element.percentage)
                            : parseFloat(Math.floor(HISTORICAL_PERFORMANCE.PERCENTAGE_MAX / data.index.length).toFixed(0)); //check if preset data is available for inital load
                    obj.disabled = false;
                    arr_1.push(obj);
                });
                //updated selection
                var sumofSlider = arr_1.reduce(function (a, b) {
                    return a + b.value;
                }, 0);
                if (HISTORICAL_PERFORMANCE.PERCENTAGE_MAX - sumofSlider !== 0) {
                    arr_1[0].value =
                        arr_1[0].value +
                            (HISTORICAL_PERFORMANCE.PERCENTAGE_MAX - sumofSlider);
                }
                _this.selectedIndicies = arr_1;
                //update dognut value
                if (_this.selectedIndicies.length > 0) {
                    _this.doughnutlegends = _this.selectedIndicies.map(function (x) { return x.name; });
                    _this.doughnutData = _this.selectedIndicies.map(function (x) { return x.value; });
                    _this.mode = "";
                    //prepare initial chart
                    _this.prepareChart();
                    //update hypothetical return value
                    _this.updateHypotheticalValues();
                    //save the profile
                    _this.saveProfile();
                }
            }
            else {
                _this.selectedIndicies = [];
                _this.saveProfile();
            }
        });
    };
    /***
     *  Get historical data, preset data, master data from backend and updated initial chart
     ***/
    SegmentPerformanceComponent.prototype.getHistoricalPerformance = function () {
        var _this = this;
        this.utilityService.showLoader();
        console.log("SEG_PERF_COMPONENT in getHistoricalData series=", this.series);
        this.scsService.getHistoricalPerformance(this.series).subscribe(function (data) {
            console.log("SEG_PERF_COMPONENT in getHistoricalData", data);
            _this.historicalPerformance = data[0]; //performance Value
            console.log("SEG_PERF_COMPONENT after data0");
            _this.presetValues = data[1]; //preset Value
            var indicesData = data[2];
            var savedProfiles = data[3];
            console.log("SEG_PERF_COMPONENT data", data);
            var preset = _this.presetValues.presetData[0]; //set preset data from server
            //check if any custom profile currently opened
            if (savedProfiles &&
                savedProfiles.length > 0 &&
                ((_this.scsService.customizationType == CUSTOMIZATION_TYPE.PROFILE &&
                    _this.scsService.currentProfile) ||
                    _this.scsService.customizationType == CUSTOMIZATION_TYPE.REPORT)) {
                var profile = savedProfiles.find(function (x) {
                    return x.name ===
                        (_this.scsService.customizationType == CUSTOMIZATION_TYPE.PROFILE
                            ? _this.scsService.currentProfile
                            : CUSTOMIZATION_TYPE.REPORT) &&
                        x.currentSerie === _this.scsService.series;
                });
                if (profile && profile.historicalPerformance) {
                    preset = _this.loadSavedProfileData(profile);
                }
            }
            else if (_this.scsService.customizationType == CUSTOMIZATION_TYPE.INVESTOR_PROFILE) {
                //set preset
                var savedPreset = _this.presetValues.presetData.find(function (x) { return x.option == _this.scsService.investorData.preset; });
                if (savedPreset) {
                    preset = savedPreset;
                }
            }
            // else if (this.scsService.report.historicalPerformance) {
            //   preset = this.loadSavedProfileData(this.scsService.report);
            // }
            //if preset data is present use that
            _this.indexOptions = indicesData.map(function (x) { return ({
                key: x.id,
                value: x.value,
                checked: preset && preset.indices.findIndex(function (y) { return y.name === x.id; }) != -1
                    ? true
                    : false,
                disabled: false,
                percentage: preset && preset.indices.find(function (y) { return y.name === x.id; })
                    ? preset.indices.find(function (y) { return y.name === x.id; }).percentage
                    : 0,
            }); });
            _this.investment = parseFloat(preset.initialInvestment.replace(/,/g, ""));
            //update doughnet chart
            _this.selectedIndicies = preset.indices.map(function (x) {
                return ({
                    id: x.id,
                    name: x.name,
                    percentage: x.percentage,
                    disabled: false,
                    value: HISTORICAL_PERFORMANCE.PERCENTAGE_MAX * x.percentage,
                });
            });
            _this.historicalForm
                .get("index")
                .setValue(_this.indexOptions.filter(function (x) { return x.checked === true; }));
            //update dognut value
            _this.doughnutlegends = _this.selectedIndicies.map(function (x) { return x.name; });
            _this.doughnutData = _this.selectedIndicies.map(function (x) { return x.value; });
            //build dropdown using historical data
            _this.buildDropDownData(preset);
            _this.updateHypotheticalValues();
            _this.utilityService.hideLoader();
        });
    };
    SegmentPerformanceComponent.prototype.loadSavedProfileData = function (profile) {
        var savedPreset = new Preset();
        if (profile && profile.historicalPerformance) {
            savedPreset.id = 1;
            savedPreset.maturityDate =
                profile.historicalPerformance.topGraphic.maturityDate;
            savedPreset.series = profile.currentSerie;
            savedPreset.duration =
                profile.historicalPerformance.topGraphic.maturityDate;
            savedPreset.buffer = profile.historicalPerformance.topGraphic.protection;
            savedPreset.initialInvestment = profile.historicalPerformance.bottomPanel.investment.toString();
            Object.keys(profile.historicalPerformance.bottomPanel.indices).forEach(function (element, index) {
                var obj = {
                    id: index,
                    name: profile.historicalPerformance.bottomPanel.indices[element]["name"],
                    percentage: profile.historicalPerformance.bottomPanel.indices[element]["percent"] / 100,
                };
                savedPreset.indices.push(obj);
            });
        }
        return savedPreset;
    };
    /***
     *  Create Drop down for bar/pie chart
     ***/
    SegmentPerformanceComponent.prototype.buildDropDownData = function (preset) {
        console.log("HISPERF preset", preset);
        console.log("HISPERF this.historicalPerformance", this.historicalPerformance);
        this.durationOptions = Array.from(new Set(this.historicalPerformance.map(function (x) { return x.title; }))).map(function (x) { return ({ id: x, value: x }); });
        this.historicalForm.get("duration").setValue(preset.duration);
        this.protectionOptions = Array.from(new Set(this.historicalPerformance[0].historicalPerformance.map(function (x) { return x.id; }))).map(function (x) { return ({ id: x, value: x.replace("Year", "Year, -") }); });
        //not to use preset default value from site. it may not updated. Let use first value
        //this.historicalForm.get("duration").setValue(preset.duration);
        this.historicalForm.get("protection").setValue(preset.buffer);
    };
    /***
     *  User change the investment value
     ***/
    SegmentPerformanceComponent.prototype.onInvestmentChange = function (investment) {
        this.investment = investment;
        //update hypothetical data
        this.updateHypotheticalValues();
    };
    /***
     *  update hypothetical details based on index and performances
     ***/
    SegmentPerformanceComponent.prototype.updateHypotheticalValues = function () {
        var maturityValue = 0, indexValue = 0;
        for (var i = 0; i < this.selectedIndicies.length; i++) {
            var segmMaturityValue = this.investment *
                (this.selectedIndicies[i].value /
                    HISTORICAL_PERFORMANCE.PERCENTAGE_MAX) *
                (1 +
                    this.selectedIndicies[i].rateOfReturn /
                        HISTORICAL_PERFORMANCE.PERCENTAGE_MAX);
            var hypoteticalValue = this.investment *
                (this.selectedIndicies[i].value /
                    HISTORICAL_PERFORMANCE.PERCENTAGE_MAX) *
                (1 +
                    this.selectedIndicies[i].performanceRate /
                        HISTORICAL_PERFORMANCE.PERCENTAGE_MAX);
            maturityValue += segmMaturityValue;
            indexValue += hypoteticalValue;
        }
        this.maturityValue = maturityValue;
        this.indexValue = indexValue;
        this.saveProfile();
    };
    SegmentPerformanceComponent.prototype.onInputChange = function (event, option) {
        var history = this.selectedIndicies.find(function (x) { return x.name === option.name; });
        history.value = event.target.value.replace("%", "");
        this.selectedInput = option.name;
    };
    /***
     *  on Change event for sliders
     ***/
    SegmentPerformanceComponent.prototype.onChange = function (event, option) {
        if (this.selectedInput && option.name !== this.selectedInput) {
            return;
        }
        var newValue = Math.min(HISTORICAL_PERFORMANCE.PERCENTAGE_MAX, Math.max(0, Math.round(event.detail.value)));
        //change otherslider value based on selection
        if (this.changeIndexPercent(option, newValue, event) === false) {
            return false;
        }
        //update doughnat data
        this.doughnutData = this.selectedIndicies.map(function (x) { return x.value; });
        //update hypothetical return value
        this.updateHypotheticalValues();
    };
    /***
     *  on Change event for sliders
     ***/
    SegmentPerformanceComponent.prototype.changeIndexPercent = function (option, newValue, event) {
        var _this = this;
        var minValue = 0;
        var lockedSliders = this.selectedIndicies.filter(function (x) { return x.disabled === true; });
        if (this.selectedIndicies.filter(function (x) { return x.disabled != true; }).length < 2)
            minValue = HISTORICAL_PERFORMANCE.PERCENTAGE_MAX;
        var sumOfLockedSlider = lockedSliders.reduce(function (a, b) {
            return a + b.value;
        }, 0);
        if (newValue >= minValue &&
            newValue <= HISTORICAL_PERFORMANCE.PERCENTAGE_MAX - sumOfLockedSlider) {
            //get  slides to change vaue - current slider
            var activeSliders = this.selectedIndicies.filter(function (x) { return x.disabled != true && x.name != option.name; });
            //get value to reduce
            var reductionValue = HISTORICAL_PERFORMANCE.PERCENTAGE_MAX - sumOfLockedSlider - newValue;
            //get delta value current sum - last sum
            var delta = reductionValue -
                activeSliders.reduce(function (x, y) {
                    return x + y.value;
                }, 0);
            var inc_1 = Math.abs(delta) > 0 ? delta / activeSliders.length : 0;
            var sum_1 = newValue + sumOfLockedSlider;
            activeSliders.forEach(function (element) {
                if (newValue == 100) {
                    element.value = 0;
                }
                else {
                    var nextVal = Math.ceil(element.value + inc_1);
                    if (nextVal > 100) {
                        element.value = 100;
                    }
                    else if (nextVal < 0) {
                        element.value = 0;
                    }
                    else {
                        element.value = Math.ceil(element.value + inc_1);
                    }
                }
                sum_1 = sum_1 + element.value;
                _this.doughnutData[element.id] = element.value;
            });
            if (sum_1 > HISTORICAL_PERFORMANCE.SLIDER_MAX) {
                //newValue = newValue - (sum - HISTORICAL_PERFORMANCE.SLIDER_MAX);
                var active = activeSliders.find(function (x) { return x.value > 0; });
                active.value = active.value - (sum_1 - HISTORICAL_PERFORMANCE.SLIDER_MAX);
                if (active.value < HISTORICAL_PERFORMANCE.SLIDER_MIN) {
                    active.value = HISTORICAL_PERFORMANCE.SLIDER_MIN;
                }
            }
            option.value = newValue;
        }
        else {
            option.value = HISTORICAL_PERFORMANCE.PERCENTAGE_MAX - sumOfLockedSlider;
            event.stopImmediatePropagation();
            event.stopPropagation();
            return false;
        }
    };
    /***
     *  Lock/upload slider/selected index
     ***/
    SegmentPerformanceComponent.prototype.toggleLock = function (event, option) {
        var hasClass = event.target.classList.contains("on");
        if (!hasClass) {
            if (this.selectedIndicies.filter(function (x) { return x.disabled === false; }).length <= 2) {
                return;
            }
            this.renderer.addClass(event.target, "on");
            option.disabled = true;
        }
        else {
            this.renderer.removeClass(event.target, "on");
            option.disabled = false;
        }
    };
    /***
     *  prepare chart based on selected scenerio
     ***/
    SegmentPerformanceComponent.prototype.prepareChart = function () {
        var _this = this;
        if (this.historicalForm.valid &&
            this.historicalPerformance &&
            this.selectedIndicies) {
            var data_1 = this.historicalPerformance
                .find(function (x) { return x.id === _this.historicalForm.get("duration").value; })
                .historicalPerformance.find(function (x) { return x.id === _this.historicalForm.get("protection").value; });
            if (!data_1) {
                data_1 = this.historicalPerformance.find(function (x) { return x.id === _this.historicalForm.get("duration").value; }).historicalPerformance[0];
                this.historicalForm.get("protection").setValue(data_1.id);
            }
            var chart_1 = new Chart();
            chart_1.bar = Array();
            var max_1 = -9999; //just abb
            var min_1 = +9999; //just abb
            this.selectedIndicies.forEach(function (element) {
                var objBar = new Bar();
                objBar.id = element.id;
                objBar.label = element.name;
                //for peformace cap rate & Buffer lines for each section
                objBar.lines = new Array();
                objBar.data = new Array();
                var indexItem = data_1.indices.find(function (x) { return x.name === element.id; });
                if (indexItem) {
                    //update performace data for pie chart
                    element.performanceRate = indexItem.indexReturn;
                    element.rateOfReturn = indexItem.segmentRateValue;
                    objBar.data.push({
                        value: parseFloat(indexItem.segmentRateValue).toFixed(2),
                        label: _this.translateService.instant("howItsWorks.segmentYearlyReturn"),
                        topValue: 0,
                    });
                    objBar.data.push({
                        value: parseFloat(indexItem.indexReturn).toFixed(2),
                        label: _this.translateService.instant("howItsWorks.indexPerformanceRate"),
                        topValue: 0,
                    });
                    objBar.lines.push({
                        label: _this.translateService.instant("howItsWorks.performaceCapRate"),
                        value: parseFloat(indexItem.historicalCapRate).toFixed(1),
                    });
                    objBar.lines.push({
                        label: _this.translateService.instant("howItsWorks.segmentBuffer"),
                        value: parseFloat(data_1.buffer),
                    });
                    min_1 = Math.min(Math.min(parseFloat(indexItem.segmentRateValue), parseFloat(indexItem.indexReturn)), min_1);
                    max_1 = Math.max(Math.max(parseFloat(indexItem.segmentRateValue), parseFloat(indexItem.indexReturn)), max_1);
                    if (isNumber(parseFloat(indexItem.historicalCapRate))) {
                        max_1 = Math.max(max_1, parseFloat(indexItem.historicalCapRate) + 5);
                    }
                }
                else {
                    //disable element for slider
                    element.disabled = true;
                    element.percentage = 0;
                    element.value = 0;
                    objBar.data.push({
                        value: 0,
                        label: _this.translateService.instant("howItsWorks.segmentYearlyReturn"),
                        topValue: 0,
                    });
                    objBar.data.push({
                        value: 0,
                        label: _this.translateService.instant("howItsWorks.indexPerformanceRate"),
                        topValue: 0,
                    });
                    objBar.lines.push({
                        label: _this.translateService.instant("howItsWorks.performaceCapRate"),
                        value: 0,
                    });
                    objBar.lines.push({
                        label: _this.translateService.instant("howItsWorks.segmentBuffer"),
                        value: 0,
                    });
                }
                chart_1.bar.push(objBar);
            });
            //redistribute value if items are disabled
            if (this.selectedIndicies.filter(function (x) { return x.disabled === true; }).length > 0) {
                var activeItems_1 = this.selectedIndicies.filter(function (x) { return x.disabled === false; });
                if (activeItems_1.length > 0) {
                    activeItems_1.forEach(function (element) {
                        element.percentage = Math.floor(HISTORICAL_PERFORMANCE.PERCENTAGE_MAX /
                            activeItems_1.length /
                            HISTORICAL_PERFORMANCE.PERCENTAGE_MAX); //check if preset data is available for inital load
                        element.value = parseFloat(Math.floor(HISTORICAL_PERFORMANCE.PERCENTAGE_MAX / activeItems_1.length).toFixed(1)); //check if preset data is available for inital load
                    });
                    var sumofSlider = activeItems_1.reduce(function (a, b) {
                        return a + b.value;
                    }, 0);
                    if (HISTORICAL_PERFORMANCE.PERCENTAGE_MAX - sumofSlider !== 0) {
                        activeItems_1[0].value =
                            activeItems_1[0].value +
                                (HISTORICAL_PERFORMANCE.PERCENTAGE_MAX - sumofSlider);
                    }
                    //update dognut value
                    this.doughnutlegends = activeItems_1.map(function (x) { return x.name; });
                    this.doughnutData = activeItems_1.map(function (x) { return x.value; });
                }
            }
            if (!isNaN(data_1)) {
                min_1 = Math.min(data_1.buffer - 5, min_1);
            }
            chart_1.axis = new Array();
            chart_1.axis.push({
                max: Math.ceil(max_1 / 10) * 10,
                min: Math.min(Math.floor(min_1 / 10) * 10, 0),
                interval: 10,
                type: "Y",
            });
            chart_1.showDescription = false;
            chart_1.showLineToolTip = true;
            this.chartData = chart_1;
            //save profile session
            this.saveProfile();
        }
    };
    /***
     *  prepare chart based on selected scenerio
     ***/
    SegmentPerformanceComponent.prototype.saveProfile = function (mode) {
        var _this = this;
        var historicalPerformance;
        if (this.selectedIndicies && this.selectedIndicies.length > 0) {
            historicalPerformance = new HistoricalPerformance();
            //add bottom panel data
            historicalPerformance.bottomPanel = new BottomPanel();
            historicalPerformance.bottomPanel.investment = this.investment;
            historicalPerformance.bottomPanel.maturityValue =
                CURRENCY.USD_SYMBOL + this.maturityValue.toLocaleString();
            historicalPerformance.bottomPanel.indexValue =
                CURRENCY.USD_SYMBOL + this.indexValue.toLocaleString();
            //add to bar data
            historicalPerformance.topGraphic = new TopGraphic();
            historicalPerformance.topGraphic.scaleMin = this.chartData.axis[0].min;
            historicalPerformance.topGraphic.scaleMax = this.chartData.axis[0].max;
            historicalPerformance.topGraphic.scaleStep = this.chartData.axis[0].interval;
            historicalPerformance.topGraphic.maturityDate = this.historicalForm.get("duration").value;
            historicalPerformance.topGraphic.maturityDateKey = this.historicalForm.get("duration").value;
            historicalPerformance.topGraphic.protection = this.historicalForm.get("protection").value;
            historicalPerformance.topGraphic.protectionKey = this.historicalForm.get("protection").value;
            //multple bars to add
            this.chartData.bar.forEach(function (element, index) {
                historicalPerformance.topGraphic.indices[index] = {
                    name: element.id,
                    label: element.label,
                    rateOfReturn: element.data[0].value,
                    performanceRate: element.data[1].value,
                    historicalCapRate: element.lines[0].value,
                    disabled: false,
                };
            });
            this.selectedIndicies.forEach(function (element, index) {
                var segmMaturityValue = _this.investment *
                    (_this.selectedIndicies[index].value /
                        HISTORICAL_PERFORMANCE.PERCENTAGE_MAX) *
                    (1 +
                        _this.selectedIndicies[index].rateOfReturn /
                            HISTORICAL_PERFORMANCE.PERCENTAGE_MAX);
                var hypoteticalValue = _this.investment *
                    (_this.selectedIndicies[index].value /
                        HISTORICAL_PERFORMANCE.PERCENTAGE_MAX) *
                    (1 +
                        _this.selectedIndicies[index].performanceRate /
                            HISTORICAL_PERFORMANCE.PERCENTAGE_MAX);
                historicalPerformance.bottomPanel.indices[index] = {
                    name: element.id,
                    label: element.name,
                    disabled: element.disabled,
                    percent: element.value,
                    maturityValue: segmMaturityValue,
                    indexValue: hypoteticalValue,
                    investment: Math.round(_this.investment * (element.value / 100)),
                };
            });
            if (this.isInitalLoadCompleted &&
                this.scsService.customizationType ===
                    CUSTOMIZATION_TYPE.INVESTOR_PROFILE) {
                this.coreService.profile.next({
                    name: "",
                    type: CUSTOMIZATION_TYPE.REPORT,
                    profile: new Profile(),
                });
                this.scsService.customizationType = CUSTOMIZATION_TYPE.REPORT;
            }
        }
        //set in session
        //save for profile
        if (this.scsService.customizationType === CUSTOMIZATION_TYPE.PROFILE) {
            this.scsService.storeProfile(CUSTOM_PROFILE.HISTORICAL_PERFORMANCE, historicalPerformance);
            // //save for report
            // this.scsService.report.historicalPerformance = historicalPerformance;
            // this.scsService.storeProfile(
            //   CUSTOM_PROFILE.HISTORICAL_PERFORMANCE,
            //   historicalPerformance,
            //   true
            // );
        }
        else {
            //save for report
            this.scsService.report.historicalPerformance = historicalPerformance;
            this.scsService.storeProfile(CUSTOM_PROFILE.HISTORICAL_PERFORMANCE, historicalPerformance, true);
        }
        if (mode && mode == CUSTOMIZATION_TYPE.PROFILE) {
            this.utilityService.presentWithoutTitleModal(ModalPopupComponent);
        }
        else if (mode && mode == CUSTOMIZATION_TYPE.REPORT) {
            this.utilityService.navigate(ROUTE_PATH.SCS.TOOLS.CREATE_REPORT);
        }
    };
    //clear input
    SegmentPerformanceComponent.prototype.clearInput = function () {
        this.investment = undefined;
    };
    //validate investment
    SegmentPerformanceComponent.prototype.validateInvestment = function (event) {
        if (event.which != 8 &&
            event.which != 9 &&
            event.which != 13 &&
            isNaN(parseInt(String.fromCharCode(event.which)))) {
            event.preventDefault();
        }
    };
    return SegmentPerformanceComponent;
}());
export { SegmentPerformanceComponent };
