<equitable-footer
  [footer]="footer"
  [isWhiteFooterVisible]="isWhiteFooterVisible"
  [isBlueFooterVisible]="isBlueFooterVisible"
></equitable-footer>
<div class="profile" *ngIf="profileName != ''">
  {{ "general.profileHead" | translate }}
  <equitable-link
    class="equitable-link header-link-hide"
    text="{{ profileName }}"
    type="dark"
    orientation="left"
    (click)="goToProfile()"
  ></equitable-link>
  |
  <equitable-link
    class="equitable-link header-link-hide"
    text="{{ 'general.hide' | translate }}"
    type="dark"
    orientation="left"
    (click)="hideProfile()"
  ></equitable-link>
</div>
