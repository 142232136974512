
import { RipComponent } from "./components/rip/rip.component";
import { ProtectIncomeComponent } from "./components/protect-income/protect-income.component";
import { PreserveLegacyComponent } from "./components/preserve-legacy/preserve-legacy.component";
import { PreserveLegacyToolsComponent } from "./components/preserve-legacy-tools/preserve-legacy-tools.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { EquitableComponentLibraryModule } from "equitable-component-library";
import { RcRoutingModule } from "./rc-routing.module";
import { DiscoverComponent } from "./components/discover/discover.component";
import { GuaranteedIncomeComponent } from "./components/guaranteed-income/guaranteed-income.component";
import { InvestmentOptionsComponent } from "./components/investment-options/investment-options.component";
import { TranslateModule } from "@ngx-translate/core";
import { EvaluatePlanComponent } from "./components/evaluate-plan/evaluate-plan.component";
import { RetirementSolutionsComponent } from "./components/retirement-solutions/retirement-solutions.component";
import { RetirementChallengesComponent } from "./components/retirement-challenges/retirement-challenges.component";
import { RcAdvancedToolResourcesDashboardComponent } from "./components/rc-advanced-tool-resources-dashboard/rc-advanced-tool-resources-dashboard.component";
import { BuildWealthComponent } from "./components/build-wealth/build-wealth.component";
import { InvestTaxSmartComponent } from "./components/invest-tax-smart/invest-tax-smart.component";
import { CurrencyPipe } from "@angular/common";
import { DiversificationComponent } from "./components/diversification/diversification.component";
import { ReportRcComponent } from "./components/report-rc/report-rc.component";
import { CoreModule } from "../core/core.module";

@NgModule({
  declarations: [
    DiscoverComponent,
    EvaluatePlanComponent,
    RetirementChallengesComponent,
    RetirementSolutionsComponent,
    BuildWealthComponent,
    InvestTaxSmartComponent,
    ProtectIncomeComponent,
    RipComponent,
    PreserveLegacyComponent,
    PreserveLegacyToolsComponent,
    DiversificationComponent,
    InvestmentOptionsComponent,
    GuaranteedIncomeComponent,
    RcAdvancedToolResourcesDashboardComponent,
    ReportRcComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule.forRoot(),
    EquitableComponentLibraryModule,
    TranslateModule,
    RcRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule
  ],
  exports: [TranslateModule],
  providers: [{ provide: "Window", useValue: window }, CurrencyPipe],
})
export class RcModule { }
