import { OnInit } from "@angular/core";
import { UtilityService } from "../../services/utility.service";
import { ButtonType, Theme } from "equitable-component-library";
var PageNotFoundComponent = /** @class */ (function () {
    function PageNotFoundComponent(util) {
        this.util = util;
        this.theme = Theme.Light;
        this.type = ButtonType.Secondary;
    }
    PageNotFoundComponent.prototype.ngOnInit = function () { };
    PageNotFoundComponent.prototype.home = function () {
        this.util.navigate("home");
    };
    return PageNotFoundComponent;
}());
export { PageNotFoundComponent };
