import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { RcService } from "../../services/rc.service";
import { InvestTaxChart } from "../../../core/models/app.models";
import { TranslateService } from "@ngx-translate/core";
import { CurrencyPipe } from "@angular/common";
import { UtilityService } from "../../../core/services/utility.service";

@Component({
  selector: "app-invest-tax-smart",
  templateUrl: "./invest-tax-smart.component.html",
  styleUrls: ["./invest-tax-smart.component.scss"],
})
export class InvestTaxSmartComponent implements OnInit {
  form: FormGroup;
  taxBracketsOptions = [];
  durationOptions = [];
  data: InvestTaxChart;
  selectedTaxBracket: string;
  selectedDuration: string;
  maxYscale = 0;
  rateOfReturn;
  investment;
  chartLabel = [
    {
      labelHeader: "",
      labelDescription: "",
    },
    {
      labelHeader: "",
      labelDescription: "",
    },
  ];
  //chart Data
  chartData = [
    {
      description: "withoutTaxDefferal",
      value: 0,
    },
    {
      description: "withTaxDefferal",
      value: 0,
    },
  ];

  constructor(
    private utilityService: UtilityService,
    private rcService: RcService,
    private fb: FormBuilder,
    private translate: TranslateService,
    private currencyPipe: CurrencyPipe
  ) {
    this.form = this.fb.group({
      taxBracket: ["", Validators.required],
      duration: ["", Validators.required],
    });
  }

  ngOnInit() {
    this.getData();
  }

  /***
   * Update Chart Labels based on selected duration and year
   ***/
  getChartLabel(withoutTax, withTax, year): void {
    // dropdown data
    this.translate.get("rcTool.investTaxSmart").subscribe((translated) => {
      this.chartLabel = [
        {
          labelHeader: this.translate.instant(
            "rcTool.investTaxSmart.noTaxDefferalHeader"
          ),
          labelDescription: this.translate.instant(
            "rcTool.investTaxSmart.noTaxDefferallabelDescription",
            {
              noTaxValue: this.currencyPipe.transform(
                withoutTax,
                "USD",
                "$",
                "1.0"
              ),
              noTaxYear: year,
            }
          ),
        },
        {
          labelHeader: this.translate.instant(
            "rcTool.investTaxSmart.TaxDefferalHeader"
          ),
          labelDescription: this.translate.instant(
            "rcTool.investTaxSmart.TaxDefferallabelDescription",
            {
              taxValue: this.currencyPipe.transform(withTax, "USD", "$", "1.0"),
              taxYear: year,
            }
          ),
        },
      ];
    });
  }

  /***
   *  Method to fetch data from API
   ***/
  getData(): void {
    this.utilityService.showLoader();
    this.rcService
      .fetchInvestTaxSmartChartData()
      .subscribe((getData: InvestTaxChart) => {
        this.utilityService.hideLoader();
        this.data = getData;
        this.investment = this.data.options[0].investment;
        this.rateOfReturn = this.data.options[0].rateOfReturn;
        this.maxYscale = this.data.options[0].maxValue;
        if (getData["taxBrackets"]) {
          getData["taxBrackets"].forEach((item) => {
            const obj = {};
            obj["id"] = item;
            obj["value"] =
              item + this.translate.instant("rcTool.investTaxSmart.percent");
            this.taxBracketsOptions.push(obj);
          });
        }
        if (getData["durations"]) {
          getData["durations"].forEach((item) => {
            const obj = {};
            obj["id"] = item;
            obj["value"] =
              item +
              " " +
              this.translate.instant("rcTool.investTaxSmart.years");
            this.durationOptions.push(obj);
          });
        }
        this.form.controls["taxBracket"].setValue(this.data.taxBrackets[0]);
        this.form.controls["duration"].setValue(this.data.durations[0]);
        this.getTaxData();
        this.getChartLabel(
          this.data.options[0].noTaxDeferral,
          this.data.options[0].taxDeferral,
          this.data.durations[0]
        );
      });
  }

  /***
   *  Method to get tax Data after selecting duration and taxbracket from dropdown
   ***/
  getTaxData(): void {
    const chartData = this.chartData;
    this.selectedTaxBracket = this.form.controls["taxBracket"].value;
    this.selectedDuration = this.form.controls["duration"].value;
    const filteredData = this.data.options.find(
      (x) =>
        x.taxBracket == parseFloat(this.selectedTaxBracket) &&
        x.duration == parseInt(this.selectedDuration)
    );
    chartData[0].value = filteredData.noTaxDeferral;
    chartData[1].value = filteredData.taxDeferral;
    this.chartData = chartData;
    this.getChartLabel(
      chartData[0].value,
      chartData[1].value,
      this.selectedDuration
    );
  }

  /***
   *  Back button functionality
   ***/
  navigateTo(): void {
    this.utilityService.navigate("rc");
  }
}
