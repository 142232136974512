import { UtilityService } from "./../../services/utility.service";
import { QuestionnaireService } from "../../services/questionnaire.service";
import { OnInit, EventEmitter } from "@angular/core";
import { ButtonType, Theme, LinkType } from "equitable-component-library";
import { ROUTE_PATH, PRODUCT_NAME } from "../../../constants/constants";
var QuestionnaireResultComponent = /** @class */ (function () {
    function QuestionnaireResultComponent(questionnaireService, utilityService) {
        this.questionnaireService = questionnaireService;
        this.utilityService = utilityService;
        //Outputs
        this.retakeQuiz = new EventEmitter();
        this.selectedInvestorIndex = 0;
        this.linkType = LinkType.Main;
        this.button = {
            type: ButtonType.Primary,
            theme: Theme.Light
        };
    }
    QuestionnaireResultComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.questionnaireService
            .getResults()
            .subscribe(function (data) {
            _this.resultData = data;
            _this.suggestedAnnuity = data.suggestedAnnuity;
            _this.otherAnnuities = data.defaultAnnuity;
        });
    };
    QuestionnaireResultComponent.prototype.retakeButtonClicked = function () {
        this.retakeQuiz.emit();
    };
    QuestionnaireResultComponent.prototype.onClickInvestor = function (title) {
        if (title == this.resultData.investorProfiles[0].title) {
            this.selectedInvestorIndex = 0;
        }
        else {
            this.selectedInvestorIndex = 1;
        }
    };
    QuestionnaireResultComponent.prototype.navigateToProducts = function (item) {
        switch (item) {
            case PRODUCT_NAME.SCS:
                this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER);
                break;
            case PRODUCT_NAME.RC:
                this.utilityService.navigate(ROUTE_PATH.RC.DISCOVER);
                break;
            case PRODUCT_NAME.IE:
                this.utilityService.navigate(ROUTE_PATH.IE.DISCOVER);
                break;
        }
    };
    return QuestionnaireResultComponent;
}());
export { QuestionnaireResultComponent };
