import { PRODUCT_TYPE, ROUTE_PATH } from './../../../constants/constants';
import { UtilityService } from './../../services/utility.service';
import { OnInit } from '@angular/core';
import { ButtonType, Theme } from 'equitable-component-library';
var SelectedAnnuityComponent = /** @class */ (function () {
    function SelectedAnnuityComponent(utilityService) {
        this.utilityService = utilityService;
        // Button component Data
        this.button = {
            type: ButtonType.Main,
            theme: Theme.Light
        };
    }
    SelectedAnnuityComponent.prototype.ngOnInit = function () {
    };
    SelectedAnnuityComponent.prototype.navigateToProducts = function () {
        switch (this.selectedProduct) {
            case PRODUCT_TYPE.SCS:
                this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER);
                break;
            case PRODUCT_TYPE.RC:
                this.utilityService.navigate(ROUTE_PATH.RC.DISCOVER);
                break;
            case PRODUCT_TYPE.IE:
                this.utilityService.navigate(ROUTE_PATH.IE.DISCOVER);
                break;
        }
    };
    return SelectedAnnuityComponent;
}());
export { SelectedAnnuityComponent };
