import { Injectable } from "@angular/core";
import {
  LoadingController,
  AlertController,
  NavController,
  Platform,
} from "@ionic/angular";
import { BehaviorSubject, Observable } from "rxjs";
import { NavigationExtras, Router } from "@angular/router";
import { Location } from "@angular/common";
import { SITE } from "src/assets/sites";
import { Alert, Button } from "../models/app.models";
import { ATTRIBUTE_TYPE } from "equitable-component-library";
import { DomSanitizer } from "@angular/platform-browser";
import { ModalController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class UtilityService {
  loading: HTMLIonLoadingElement = null;
  loaderMessage: string;
  alertPresent = false;
  loaderPresent = false;
  private subject = new BehaviorSubject(true);

  constructor(
    public loader: LoadingController,
    private router: NavController,
    private location: Location,
    private sanitizer: DomSanitizer,
    private alertCtrl: AlertController,
    public modalController: ModalController,
    private platform: Platform
  ) {}

  async showAlert(obj: Alert) {
    const buttons: Button[] = [];
    if (obj.buttons.length > 0) {
      obj.buttons.forEach((element) => {
        buttons.push({
          text: element.text,
          role: element.role,
          handler: element.handler,
        });
      });
    }

    this.alertCtrl
      .create({
        header: obj.title,
        message: obj.message,
        buttons: buttons,
      })
      .then(async (alert) => {
        await alert.present();
      });
  }

  private myLoader(message?): Promise<HTMLIonLoadingElement> {
    const loader = this.loader.create({
      spinner: "crescent",
      message: message,
      translucent: true,
      cssClass: "custom-loading",
    });
    return loader;
  }

  async showLoader(message?): Promise<void> {
    message = message ? message : "Please wait...";
    if (!this.loaderPresent) {
      this.loading = await this.myLoader(message);
      await this.loading.present();
      this.loaderPresent = true;
    }
  }

  hideLoader(): void {
    // eslint-disable-next-line angular/timeout-service
    setTimeout(() => {
      if (this.loading) {
        this.loading.dismiss();
        this.loaderPresent = false;
      }
    }, 1500);
  }

  getLoader(): HTMLIonLoadingElement {
    if (this.loading) {
      return this.loading;
    }
  }

  createFileNameFromUrl(url: string): string {
    const urlObject = new URL(url);
    return urlObject.pathname.split("/").pop();
  }

  findFileExtension(url: string): string {
    return this.createFileNameFromUrl(url).split(".").pop();
  }

  sendScrollStatus(message: boolean) {
    this.subject.next(message);
  }

  getScrollStatus(): Observable<boolean> {
    return this.subject.asObservable();
  }

  toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  }

  getQueryParam(path: string) {
    const arr = [];
    //how many parmam
    const params = path.split("&");
    params.forEach((element) => {
      const q = element.split("=");
      arr.push({
        key: q[0],
        value: q[1],
      });
    });
    return arr;
  }

  navigate(toPage, data = {}, isExternal?) {
    if (decodeURI(toPage).indexOf("?") !== -1) {
      const subStr = toPage.split("?");
      toPage = subStr[0];
      this.getQueryParam(subStr[1]).forEach((element) => {
        data[element.key] = element.value;
      });
    }
    const navigationExtras: NavigationExtras = {
      queryParams: data,
      fragment: "anchor",
      replaceUrl: false,
      //skipLocationChange: false //isExternal ? true : false, //false
    };
    this.router.navigateRoot([toPage], navigationExtras);
  }

  visitEquitable(url: string) {
    if (this.platform.is("cordova")) {
      // eslint-disable-next-line angular/window-service
      window.open(url, "_system");
    } else {
      // eslint-disable-next-line angular/window-service
      window.open(url, "_blank");
    }
  }

  visitEquitableMicrosite(url: string) {
    if (this.platform.is("cordova")) {
      // eslint-disable-next-line angular/window-service
      window.open(url, "_system");
    } else {
      // eslint-disable-next-line angular/window-service
      window.open(url, "_blank");
    }
  }

  navigateToBack() {
    this.location.back();
  }

  getUrl(urlPath) {
    return SITE.AXA + urlPath;
  }

  getPath(iconName: string, type: string) {
    let finalPath = "./assets/";

    switch (type) {
      case ATTRIBUTE_TYPE.IMAGE:
        finalPath = finalPath + "images/" + iconName;
        break;
      case ATTRIBUTE_TYPE.ICON:
        finalPath = finalPath + "icons/" + iconName;
        break;
      default:
        break;
    }
    return finalPath;
  }

  sanitizeDom(type: string, url: string) {
    let sanitizedUrl;
    switch (type) {
      case ATTRIBUTE_TYPE.IMAGE:
        sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(url);
        break;
      case ATTRIBUTE_TYPE.URL:
        sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        break;
      default:
        sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        break;
    }
    return sanitizedUrl;
  }

  navigateWithoutParams(toPage) {
    this.router.navigateRoot([toPage]);
  }

  async presentWithoutTitleModal(ModalPopupComponent) {
    const modal = await this.modalController.create({
      component: ModalPopupComponent,
      cssClass: "xs-modal-popup",
    });
    return await modal.present();
  }

  showValidationErrorForControls(formGroup) {
    const validationErrors: string[] = [];
    Object.keys(formGroup.controls).forEach((controlName) => {
      const control = formGroup.controls[controlName];
      control.markAsTouched();
    });
    return validationErrors;
  }
}
