import { Component, OnInit } from "@angular/core";
import { UtilityService } from "../../services/utility.service";
import { ButtonType, Theme } from "equitable-component-library";

@Component({
  selector: "app-page-not-found",
  templateUrl: "./page-not-found.component.html",
  styleUrls: ["./page-not-found.component.scss"],
})
export class PageNotFoundComponent implements OnInit {
  constructor(private util: UtilityService) {}
  theme = Theme.Light;
  type = ButtonType.Secondary;

  ngOnInit() {}

  home() {
    this.util.navigate("home");
  }
}
