// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  serviceAPIs: {
    baseUrl: "",
    scsincome: "/assets/apis/scs-income/scs_main.json",
    scs: "/assets/apis/scs/scs_main.json",
    rc: "/assets/apis/retirement-cornerstone/rc_main.json",
    ie: "/assets/apis/ie/ie_main.json",
    utilizeScs: "https://int-generatepdf.equitable.com/generatepdf/IR/retirementguide/scs",
    utilizeIncome: "https://int-generatepdf.equitable.com/generatepdf/IR/retirementguide/scsIncome",
    utilizeScs21: "https://int-generatepdf.equitable.com/generatepdf/IR/retirementguide/scs21",
    utilizeRc: "https://int-generatepdf.equitable.com/generatepdf/IR/retirementguide/rc",
    utilizeIe: "https://int-generatepdf.equitable.com/generatepdf/IR/retirementguide/investmentedge",
    investorProfile: "/assets/apis/investor-profile/investor-profile-data.json",
    dynamicAPIUrl: "https://api.equitable.com/scs/v1/rates/retirement-guide",
    dynamicAPISubscriptionKey: "b063695de45040759eb72f31c1e94042",
  },
  imagesPath: "./assets/images/",
  config: "./assets/apis/config.json",
  howItsWorks: "./assets/apis/scs/howItsWorks.json",
  howItsWorksScsIncome: "./assets/apis/scs-income/howItsWorks.json",
  howItsWorksIE: "./assets/apis/ie/howItsWorks.json",
  scsTerms: "./assets/apis/scs/terms.json",
  rcTerms: "./assets/apis/retirement-cornerstone/terms.json",
  ieIndices: "./assets/apis/ie/get_start.json",
  scsIndices: "./assets/apis/scs/get_start.json",
  scsincomeIndices: "./assets/apis/scs/get_start.json",
  i18nLocation: "./assets/i18n/",
  piePicturePath: "./assets/historical-performance-charts/",
  EQUITABLE: "https://equitable.com/distributors",
  EQUITABLE_HOMEPAGE: "https://dev.equitable.com",
  tealiumEnvironment: "dev",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
