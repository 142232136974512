import { HowitworksToolComponent } from "./components/howitworks-tool/howitworks-tool.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DiscoverComponent } from "./components/discover/discover.component";
import { IeToolsComponent } from "./components/ie-tools/ie-tools.component";
import { IeAdvancedToolResourcesDashboardComponent } from "./components/ie-advanced-tool-resources-dashboard/ie-advanced-tool-resources-dashboard.component";
import { ROUTE_PATH, PRODUCT_TYPE, TOOLS_NAME } from "../constants/constants";
import { ReportIeComponent } from "./components/report-ie/report-ie.component";
import { FeatureFlagGuard } from '../ffguard';
import { BuildWealthComponent } from "./components/build-wealth/build-wealth.component";
import { FundFocusComponent } from "./components/fund-focus/fund-focus.component";
import { HowSioWorksComponent } from "./components/how-sio-works/how-sio-works.component";
import { SioComponent } from "./components/sio/sio.component";
import { ScsPiePicturesComponent } from "./components/scs-pie-pictures/scs-pie-pictures.component";
import { MarketTrendIndicatorComponent } from "./components/market-trend-indicator/market-trend-indicator.component"

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "/" + ROUTE_PATH.IE.DISCOVER
  },
  {
    path: ROUTE_PATH.IE.DISCOVER,
    component: DiscoverComponent,
    canActivate: [FeatureFlagGuard],
    data: {
      flags: PRODUCT_TYPE.IE
    },
  },
  {
    path: "ie/tools",
    component: IeToolsComponent,
    canActivate: [FeatureFlagGuard],
    data: {
      flags: PRODUCT_TYPE.IE
    },
  },
  {
    path: ROUTE_PATH.IE.TOOLS.HOME,
    component: IeAdvancedToolResourcesDashboardComponent,
    canActivate: [FeatureFlagGuard],
    data: {
      flags: PRODUCT_TYPE.IE
    },
  },
  {
    path: ROUTE_PATH.IE.TOOLS.HOW_IT_WORKS,
    component: HowitworksToolComponent,
    canActivate: [FeatureFlagGuard],
    data: {
      flags: PRODUCT_TYPE.IE
    },
  },
  {
    path: ROUTE_PATH.IE.TOOLS.FUND_FOCUS,
    component: FundFocusComponent,
    canActivate: [FeatureFlagGuard],
    data: {
      flags: PRODUCT_TYPE.IE
    },
  },
  {
    path: ROUTE_PATH.IE.TOOLS.DIVERSIFICATION,
    component: BuildWealthComponent,
    canActivate: [FeatureFlagGuard],
    data: {
      flags: PRODUCT_TYPE.IE
    },
  },
/*   {
    path: ROUTE_PATH.IE.TOOLS.HOW_SIO_WORKS_DETAILS,
    component: HowSioWorksComponent,
  },
  {
    path: ROUTE_PATH.IE.TOOLS.HOW_SIO_WORKS,
    component: SioComponent,
    canActivate: [FeatureFlagGuard],
    data: {
      flags: PRODUCT_TYPE.IE
    },
  }, */
  {
    path: ROUTE_PATH.IE.TOOLS.HOW_SIO_WORKS_DETAILS,
    component: HowSioWorksComponent,
  },
  {
    path: ROUTE_PATH.IE.TOOLS.HOW_SIO_WORKS,
    component: SioComponent,
  },
  {
    path: ROUTE_PATH.IE.TOOLS.HISTORICAL_PERFORMANCE_PLUS,
    component: ScsPiePicturesComponent,
  },
  {
    path: ROUTE_PATH.IE.TOOLS.MARKET_TREND,
    component: MarketTrendIndicatorComponent,
  },
  {
    path: ROUTE_PATH.IE.TOOLS.CREATE_REPORT,
    component: ReportIeComponent,
  }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IeRoutingModule { }
