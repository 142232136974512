import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomePage } from "./components/home/home.page";
import { GlossaryComponent } from "./components/glossary/glossary.component";
import { QuestionnaireComponent } from "./components/questionnaire/questionnaire.component";
import { DisclosureComponent } from "./components/disclosure/disclosure.component";
import { DisclosureRcComponent } from "./components/disclosure-rc/disclosure-rc.component";
import { DisclosureScsComponent } from "./components/disclosure-scs/disclosure-scs.component";
import { DisclosureScsPlusComponent } from "./components/disclosure-scs-plus/disclosure-scs-plus.component";
import { DisclosureScsPlus21Component } from "./components/disclosure-scs-plus21/disclosure-scs-plus21.component";
import { DisclosureScsIncomeComponent } from "./components/disclosure-scs-income/disclosure-scs-income.component";
import { DisclosureIeComponent } from "./components/disclosure-ie/disclosure-ie.component";
import { ProspectusComponent } from "./components/prospectus/prospectus.component";
import { InvestorProfileComponent } from "./components/investor-profile/investor-profile.component";
import { FeatureFlagGuard } from "../ffguard";
import { ROUTE_PATH } from "../constants/constants";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";

const routes: Routes = [
  {
    path: ROUTE_PATH.GLOSSARY,
    component: GlossaryComponent,
  },
  {
    path: ROUTE_PATH.QUESTIONNAIRE,
    component: QuestionnaireComponent,
    canActivate: [FeatureFlagGuard],
    data: {
      flags: "questionnaire",
    },
  },
  {
    path: ROUTE_PATH.DISCLOSURE,
    component: DisclosureComponent,
  },
  {
    path: ROUTE_PATH.DISCLOSURE_RC,
    component: DisclosureRcComponent,
  },
  {
    path: ROUTE_PATH.DISCLOSURE_SCS_INCOME,
    component: DisclosureScsComponent,
  },
  {
    path: ROUTE_PATH.DISCLOSURE_SCS,
    component: DisclosureScsComponent,
  },
  {
    path: ROUTE_PATH.DISCLOSURE_SCS_PLUS,
    component: DisclosureScsPlusComponent,
  },
  {
    path: ROUTE_PATH.DISCLOSURE_SCS_PLUS21,
    component: DisclosureScsPlus21Component,
  },
  {
    path: ROUTE_PATH.DISCLOSURE_SCS_INCOME,
    component: DisclosureScsIncomeComponent,
  },
  {
    path: ROUTE_PATH.DISCLOSURE_IE,
    component: DisclosureIeComponent,
  },
  {
    path: ROUTE_PATH.PROSPECTUS,
    component: ProspectusComponent,
  },
  {
    path: "",
    component: HomePage,
    pathMatch: "full",
    canActivate: [FeatureFlagGuard],
    data: {
      flags: "home",
    },
  },
  {
    path: ROUTE_PATH.SCS.TOOLS.INVESTOR_PROFILE,
    component: InvestorProfileComponent,
  },
  {
    path: ROUTE_PATH.RC.TOOLS.INVESTOR_PROFILE,
    component: InvestorProfileComponent,
  },
  {
    path: ROUTE_PATH.IE.TOOLS.INVESTOR_PROFILE,
    component: InvestorProfileComponent,
  },
  {
    path: "404",
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
