import { Observable, interval } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { StripIcon, Icon, CardImage, ATTRIBUTE_TYPE, InvestorImages, } from "equitable-component-library";
import { PRODUCT_TYPE, INVESTOR_PROFILE } from "src/app/constants/constants";
import { UtilityService } from "./utility.service";
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./utility.service";
var QuestionnaireService = /** @class */ (function () {
    function QuestionnaireService(translateService, utilityService) {
        var _this = this;
        this.translateService = translateService;
        this.utilityService = utilityService;
        this.questionnaire = {};
        this.questionnaireOneData = [];
        this.questionnaireTwoData = [];
        this.questionnaireThreeData = [];
        this.selectableQuestionnaireThreeData = [];
        this.investorsProfiles = {};
        this.annuity = {};
        this.questionnaire = {
            1: "",
            2: "",
            3: "",
        };
        this.translateService
            .get("questionnaire.careful")
            .subscribe(function (translated) {
            _this.questionnaireOneData = [
                {
                    cardMainIcon: Icon.Careful,
                    cardMainTitle: translated,
                    cardMainContent: _this.translateService.instant("questionnaire.careful-description"),
                    cardMainHeaderImage: CardImage.Careful,
                },
                {
                    cardMainIcon: Icon.Balanced,
                    cardMainTitle: _this.translateService.instant("questionnaire.balanced"),
                    cardMainContent: _this.translateService.instant("questionnaire.balanced-description"),
                    cardMainHeaderImage: CardImage.Balanced,
                },
                {
                    cardMainIcon: Icon.Bold,
                    cardMainTitle: _this.translateService.instant("questionnaire.bold"),
                    cardMainContent: _this.translateService.instant("questionnaire.bold-description"),
                    cardMainHeaderImage: CardImage.Bold,
                },
            ];
            _this.questionnaireTwoData = [
                {
                    stripIcon: StripIcon.GuaranteeIncome,
                    stripText: _this.translateService.instant("questionnaire.strip-text1"),
                    stripResultText: _this.translateService.instant("questionnaire.strip-result-text1"),
                    details: _this.translateService.instant("questionnaire.strip-details1"),
                },
                {
                    stripIcon: StripIcon.EnsuringDeath,
                    stripText: _this.translateService.instant("questionnaire.strip-text2"),
                    stripResultText: _this.translateService.instant("questionnaire.strip-result-text2"),
                    details: _this.translateService.instant("questionnaire.strip-details2"),
                },
                {
                    stripIcon: StripIcon.Profit,
                    stripText: _this.translateService.instant("questionnaire.strip-text3"),
                    stripResultText: _this.translateService.instant("questionnaire.strip-result-text3"),
                    details: _this.translateService.instant("questionnaire.strip-details3"),
                },
                {
                    stripIcon: StripIcon.MinimizeLoss,
                    stripText: _this.translateService.instant("questionnaire.strip-text4"),
                    stripResultText: _this.translateService.instant("questionnaire.strip-result-text4"),
                    details: _this.translateService.instant("questionnaire.strip-details4"),
                },
                {
                    stripIcon: StripIcon.DecreaseTaxes,
                    stripText: _this.translateService.instant("questionnaire.strip-text5"),
                    stripResultText: _this.translateService.instant("questionnaire.strip-result-text5"),
                    details: _this.translateService.instant("questionnaire.strip-details5"),
                },
                {
                    stripIcon: StripIcon.AdjustInvestment,
                    stripText: _this.translateService.instant("questionnaire.strip-text6"),
                    stripResultText: _this.translateService.instant("questionnaire.strip-result-text6"),
                    details: _this.translateService.instant("questionnaire.strip-details6"),
                },
            ];
            _this.questionnaireThreeData = [
                {
                    cardMainIcon: Icon.Volatility,
                    cardMainTitle: _this.translateService.instant("questionnaire.cardmain-title1"),
                    cardMainContent: _this.translateService.instant("questionnaire.cardmain-content1"),
                    cardResultContent: _this.translateService.instant("questionnaire.cardmain-result-content1"),
                    cardMainHeaderImage: CardImage.Volatility,
                },
                {
                    cardMainIcon: Icon.Inflation,
                    cardMainTitle: _this.translateService.instant("questionnaire.cardmain-title2"),
                    cardMainContent: _this.translateService.instant("questionnaire.cardmain-content2"),
                    cardResultContent: _this.translateService.instant("questionnaire.cardmain-result-content2"),
                    cardMainHeaderImage: CardImage.Inflation,
                },
                {
                    cardMainIcon: Icon.InterestRates,
                    cardMainTitle: _this.translateService.instant("questionnaire.cardmain-title3"),
                    cardMainContent: _this.translateService.instant("questionnaire.cardmain-content3"),
                    cardResultContent: _this.translateService.instant("questionnaire.cardmain-result-content3"),
                    cardMainHeaderImage: CardImage.InterestRates,
                },
                {
                    cardMainIcon: Icon.Longevity,
                    cardMainTitle: _this.translateService.instant("questionnaire.cardmain-title4"),
                    cardMainContent: _this.translateService.instant("questionnaire.cardmain-content4"),
                    cardResultContent: _this.translateService.instant("questionnaire.cardmain-result-content4"),
                    cardMainHeaderImage: CardImage.Longevity,
                },
                {
                    cardMainIcon: Icon.Taxes,
                    cardMainTitle: _this.translateService.instant("questionnaire.cardmain-title5"),
                    cardMainContent: _this.translateService.instant("questionnaire.cardmain-content5"),
                    cardResultContent: _this.translateService.instant("questionnaire.cardmain-result-content5"),
                    cardMainHeaderImage: CardImage.Taxes,
                },
                {
                    cardMainIcon: Icon.Legacy,
                    cardMainTitle: _this.translateService.instant("questionnaire.cardmain-title6"),
                    cardMainContent: _this.translateService.instant("questionnaire.cardmain-content6"),
                    cardResultContent: _this.translateService.instant("questionnaire.cardmain-result-content6"),
                    cardMainHeaderImage: CardImage.Legacy,
                },
            ];
            _this.investorsProfiles = {
                explorer: {
                    name: _this.translateService.instant("questionnaire.name1"),
                    investorType: _this.translateService.instant("questionnaire.investor-type1"),
                    quote: _this.translateService.instant("questionnaire.quote1"),
                    currentState: [
                        _this.translateService.instant("questionnaire.current-statedetails1"),
                        _this.translateService.instant("questionnaire.current-statedetails2"),
                    ],
                    upcomingConcerns: [
                        _this.translateService.instant("questionnaire.upcoming-concernsdetails1"),
                        _this.translateService.instant("questionnaire.upcoming-concernsdetails2"),
                    ],
                    description: _this.translateService.instant("questionnaire.investor-description1"),
                    subDescription: _this.translateService.instant("questionnaire.investor-subdescription1"),
                    relatedSolution: [
                        _this.translateService.instant("questionnaire.related-solutionsdetails1"),
                    ],
                    image: _this.utilityService.getPath(InvestorImages.Explorer, ATTRIBUTE_TYPE.IMAGE),
                    icon: _this.utilityService.getPath(Icon.Explorer, ATTRIBUTE_TYPE.ICON),
                },
                entrepreneur: {
                    name: _this.translateService.instant("questionnaire.name2"),
                    investorType: _this.translateService.instant("questionnaire.investor-type2"),
                    quote: _this.translateService.instant("questionnaire.quote2"),
                    currentState: [
                        _this.translateService.instant("questionnaire.current-statedetails1"),
                        _this.translateService.instant("questionnaire.current-statedetails2"),
                    ],
                    upcomingConcerns: [
                        _this.translateService.instant("questionnaire.upcoming-concernsdetails2"),
                        _this.translateService.instant("questionnaire.upcoming-concernsdetails3"),
                    ],
                    description: _this.translateService.instant("questionnaire.investor-description2"),
                    subDescription: _this.translateService.instant("questionnaire.investor-subdescription2"),
                    relatedSolution: [
                        _this.translateService.instant("questionnaire.related-solutionsdetails1"),
                    ],
                    image: _this.utilityService.getPath(InvestorImages.Entrepreneur, ATTRIBUTE_TYPE.IMAGE),
                    icon: _this.utilityService.getPath(Icon.Entrepreneur, ATTRIBUTE_TYPE.ICON),
                },
                diversifier: {
                    name: _this.translateService.instant("questionnaire.name3"),
                    investorType: _this.translateService.instant("questionnaire.investor-type3"),
                    quote: _this.translateService.instant("questionnaire.quote3"),
                    currentState: [
                        _this.translateService.instant("questionnaire.current-statedetails1"),
                        _this.translateService.instant("questionnaire.current-statedetails2"),
                    ],
                    upcomingConcerns: [
                        _this.translateService.instant("questionnaire.upcoming-concernsdetails1"),
                        _this.translateService.instant("questionnaire.upcoming-concernsdetails2"),
                    ],
                    description: _this.translateService.instant("questionnaire.investor-description3"),
                    subDescription: _this.translateService.instant("questionnaire.investor-subdescription3"),
                    relatedSolution: [
                        _this.translateService.instant("questionnaire.related-solutionsdetails1"),
                    ],
                    image: _this.utilityService.getPath(InvestorImages.Defender, ATTRIBUTE_TYPE.IMAGE),
                    icon: _this.utilityService.getPath(Icon.Defender, ATTRIBUTE_TYPE.ICON),
                },
                saver: {
                    name: _this.translateService.instant("questionnaire.name4"),
                    investorType: _this.translateService.instant("questionnaire.investor-type4"),
                    quote: _this.translateService.instant("questionnaire.quote4"),
                    currentState: [
                        _this.translateService.instant("questionnaire.current-statedetails6"),
                        _this.translateService.instant("questionnaire.current-statedetails7"),
                    ],
                    upcomingConcerns: [
                        _this.translateService.instant("questionnaire.upcoming-concernsdetail10"),
                        _this.translateService.instant("questionnaire.upcoming-concernsdetails2"),
                    ],
                    description: _this.translateService.instant("questionnaire.investor-description4"),
                    subDescription: _this.translateService.instant("questionnaire.investor-subdescription4"),
                    relatedSolution: [
                        _this.translateService.instant("questionnaire.related-solutionsdetails1"),
                    ],
                    image: _this.utilityService.getPath(InvestorImages.Saver, ATTRIBUTE_TYPE.IMAGE),
                    icon: _this.utilityService.getPath(Icon.Saver, ATTRIBUTE_TYPE.ICON),
                },
                disrupter: {
                    name: _this.translateService.instant("questionnaire.name5"),
                    investorType: _this.translateService.instant("questionnaire.investor-type5"),
                    quote: _this.translateService.instant("questionnaire.quote5"),
                    currentState: [
                        _this.translateService.instant("questionnaire.current-statedetails1"),
                        _this.translateService.instant("questionnaire.current-statedetails3"),
                    ],
                    upcomingConcerns: [
                        _this.translateService.instant("questionnaire.upcoming-concernsdetails4"),
                        _this.translateService.instant("questionnaire.upcoming-concernsdetails5"),
                    ],
                    description: _this.translateService.instant("questionnaire.investor-description5"),
                    subDescription: _this.translateService.instant("questionnaire.investor-subdescription5"),
                    relatedSolution: [
                        _this.translateService.instant("questionnaire.related-solutionsdetails2"),
                    ],
                    image: _this.utilityService.getPath(InvestorImages.Disrupter, ATTRIBUTE_TYPE.IMAGE),
                    icon: _this.utilityService.getPath(Icon.Disrupter, ATTRIBUTE_TYPE.ICON),
                },
                protector: {
                    name: _this.translateService.instant("questionnaire.name6"),
                    investorType: _this.translateService.instant("questionnaire.investor-type6"),
                    quote: _this.translateService.instant("questionnaire.quote6"),
                    currentState: [
                        _this.translateService.instant("questionnaire.current-statedetails6"),
                        _this.translateService.instant("questionnaire.current-statedetails4"),
                    ],
                    upcomingConcerns: [
                        _this.translateService.instant("questionnaire.upcoming-concernsdetails6"),
                        _this.translateService.instant("questionnaire.upcoming-concernsdetails7"),
                    ],
                    description: _this.translateService.instant("questionnaire.investor-description6"),
                    subDescription: _this.translateService.instant("questionnaire.investor-subdescription6"),
                    relatedSolution: [
                        _this.translateService.instant("questionnaire.related-solutionsdetails2")
                    ],
                    image: _this.utilityService.getPath(InvestorImages.Protector, ATTRIBUTE_TYPE.IMAGE),
                    icon: _this.utilityService.getPath(Icon.Protector, ATTRIBUTE_TYPE.ICON),
                },
                preserver: {
                    name: _this.translateService.instant("questionnaire.name7"),
                    investorType: _this.translateService.instant("questionnaire.investor-type7"),
                    quote: _this.translateService.instant("questionnaire.quote7"),
                    currentState: [
                        _this.translateService.instant("questionnaire.current-statedetails5"),
                        _this.translateService.instant("questionnaire.current-statedetails4"),
                    ],
                    upcomingConcerns: [
                        _this.translateService.instant("questionnaire.upcoming-concernsdetails8"),
                        _this.translateService.instant("questionnaire.upcoming-concernsdetails9"),
                    ],
                    description: _this.translateService.instant("questionnaire.investor-description7"),
                    subDescription: _this.translateService.instant("questionnaire.investor-subdescription7"),
                    relatedSolution: [
                        _this.translateService.instant("questionnaire.related-solutionsdetails2")
                    ],
                    image: _this.utilityService.getPath(InvestorImages.Preserver, ATTRIBUTE_TYPE.IMAGE),
                    icon: _this.utilityService.getPath(Icon.Preserver, ATTRIBUTE_TYPE.ICON),
                },
                coordinator: {
                    name: _this.translateService.instant("questionnaire.name9"),
                    investorType: _this.translateService.instant("questionnaire.investor-type9"),
                    quote: _this.translateService.instant("questionnaire.quote9"),
                    currentState: [
                        _this.translateService.instant("questionnaire.current-statedetails1"),
                        _this.translateService.instant("questionnaire.current-statedetails4"),
                    ],
                    upcomingConcerns: [
                        _this.translateService.instant("questionnaire.upcoming-concernsdetail11"),
                        _this.translateService.instant("questionnaire.upcoming-concernsdetail12"),
                    ],
                    description: _this.translateService.instant("questionnaire.investor-description9"),
                    subDescription: _this.translateService.instant("questionnaire.investor-subdescription9"),
                    relatedSolution: [
                        _this.translateService.instant("questionnaire.related-solutionsdetails3"),
                    ],
                    image: _this.utilityService.getPath(InvestorImages.Protector, ATTRIBUTE_TYPE.IMAGE),
                    icon: _this.utilityService.getPath(Icon.Protector, ATTRIBUTE_TYPE.ICON)
                },
                researcher: {
                    name: _this.translateService.instant("questionnaire.name8"),
                    investorType: _this.translateService.instant("questionnaire.investor-type8"),
                    quote: _this.translateService.instant("questionnaire.quote8"),
                    currentState: [
                        _this.translateService.instant("questionnaire.current-statedetails1"),
                        _this.translateService.instant("questionnaire.current-statedetails4"),
                    ],
                    upcomingConcerns: [
                        _this.translateService.instant("questionnaire.upcoming-concernsdetail11"),
                        _this.translateService.instant("questionnaire.upcoming-concernsdetail12"),
                    ],
                    description: _this.translateService.instant("questionnaire.investor-description8"),
                    subDescription: _this.translateService.instant("questionnaire.investor-subdescription8"),
                    relatedSolution: [
                        _this.translateService.instant("questionnaire.related-solutionsdetails3"),
                    ],
                    image: _this.utilityService.getPath(InvestorImages.Defender, ATTRIBUTE_TYPE.IMAGE),
                    icon: _this.utilityService.getPath(Icon.Defender, ATTRIBUTE_TYPE.ICON)
                }
            };
            _this.annuity = {
                scs: {
                    image: "",
                    name: _this.translateService.instant("questionnaire.annuity-name1"),
                    text: _this.translateService.instant("questionnaire.annuity-text1"),
                    benefits: [
                        _this.translateService.instant("questionnaire.annuity-benefits1"),
                        _this.translateService.instant("questionnaire.annuity-benefits2"),
                        _this.translateService.instant("questionnaire.annuity-benefits3"),
                    ],
                    id: PRODUCT_TYPE.SCS,
                },
                rc: {
                    image: "",
                    name: _this.translateService.instant("questionnaire.annuity-name2"),
                    text: _this.translateService.instant("questionnaire.annuity-text2"),
                    benefits: [
                        _this.translateService.instant("questionnaire.rc-benefits1"),
                        _this.translateService.instant("questionnaire.rc-benefits2"),
                        _this.translateService.instant("questionnaire.rc-benefits3"),
                    ],
                    id: PRODUCT_TYPE.RC,
                },
                ie: {
                    image: "",
                    name: _this.translateService.instant("questionnaire.annuity-name3"),
                    text: _this.translateService.instant("questionnaire.annuity-text3"),
                    benefits: [
                        _this.translateService.instant("questionnaire.ie-benefits1"),
                        _this.translateService.instant("questionnaire.ie-benefits2"),
                        _this.translateService.instant("questionnaire.ie-benefits3"),
                    ],
                    id: PRODUCT_TYPE.IE,
                },
            };
        });
    }
    QuestionnaireService.prototype.getQuestionnaire = function () {
        return this.questionnaire;
    };
    QuestionnaireService.prototype.setQuestionnaire = function (questionnaire) {
        this.questionnaire = questionnaire;
    };
    QuestionnaireService.prototype.getQuestionnairePageData = function (pageNo) {
        var _this = this;
        var questionnaireObservable = new Observable(function (observer) {
            var dataInterval = interval(100).subscribe(function () {
                if (_this.questionnaireOneData.length !== 0) {
                    dataInterval.unsubscribe();
                    switch (pageNo) {
                        case 1:
                            observer.next(_this.questionnaireOneData);
                            break;
                        case 2:
                            observer.next(_this.questionnaireTwoData);
                            break;
                        case 3:
                            observer.next(_this.getQuestionnaireThreeData());
                            break;
                        default:
                            break;
                    }
                }
            });
        });
        return questionnaireObservable;
    };
    QuestionnaireService.prototype.getResults = function () {
        var _this = this;
        var resultObservalble = new Observable(function (observer) {
            var dataInterval = interval(100).subscribe(function () {
                if (_this.questionnaireOneData.length !== 0) {
                    _this.resultData = {
                        answers: [],
                        investorProfiles: [],
                        suggestedAnnuity: {
                            image: "",
                            name: "",
                            text: "",
                            benefits: [],
                            id: "",
                        },
                        defaultAnnuity: [],
                    };
                    dataInterval.unsubscribe();
                    _this.getSuggestedInvestorAndProduct().then(function (investors) {
                        var selectedAnswer1 = {
                            detail: _this.translateService.instant("questionnaire.detail1"),
                            heading: _this.questionnaireOneData[_this.questionnaire[1]]
                                .cardMainTitle,
                            description: _this.questionnaireOneData[_this.questionnaire[1]]
                                .cardMainContent,
                            icon: _this.questionnaireOneData[_this.questionnaire[1]]
                                .cardMainIcon,
                        };
                        var selectedAnswer2 = {
                            detail: _this.translateService.instant("questionnaire.detail2"),
                            heading: _this.questionnaireTwoData[_this.questionnaire[2]]
                                .stripResultText,
                            description: _this.questionnaireTwoData[_this.questionnaire[2]]
                                .details,
                            icon: _this.questionnaireTwoData[_this.questionnaire[2]].stripIcon,
                        };
                        var selectedAnswer3 = {
                            detail: _this.translateService.instant("questionnaire.detail3"),
                            heading: _this.selectableQuestionnaireThreeData[_this.questionnaire[3]].cardMainTitle,
                            description: _this.selectableQuestionnaireThreeData[_this.questionnaire[3]].cardResultContent,
                            icon: _this.selectableQuestionnaireThreeData[_this.questionnaire[3]]
                                .cardMainIcon,
                        };
                        _this.resultData.answers.push(selectedAnswer1);
                        _this.resultData.answers.push(selectedAnswer2);
                        _this.resultData.answers.push(selectedAnswer3);
                        investors.investorArray.forEach(function (element) {
                            var investorObject = _this.investorsProfiles[element.toLowerCase()];
                            var investor = {
                                title: element.toLowerCase(),
                                icon: investorObject.icon,
                                investorDetails: {
                                    image: investorObject.image,
                                    name: investorObject.name,
                                    title: element,
                                    heading: investorObject.quote,
                                    text: investorObject.description,
                                    subText: investorObject.subDescription,
                                },
                                keypoints: [
                                    {
                                        heading: _this.translateService.instant("questionnaire.key-heading1"),
                                        details: investorObject.currentState,
                                    },
                                    {
                                        heading: _this.translateService.instant("questionnaire.key-heading2"),
                                        details: investorObject.upcomingConcerns,
                                    },
                                    {
                                        heading: _this.translateService.instant("questionnaire.key-heading3"),
                                        details: investorObject.relatedSolution,
                                    },
                                ],
                            };
                            _this.resultData.investorProfiles.push(investor);
                        });
                        for (var key in _this.annuity) {
                            if (key === investors.suggestedPlan) {
                                _this.resultData.suggestedAnnuity = _this.annuity[key];
                            }
                            else {
                                _this.resultData.defaultAnnuity.push(_this.annuity[key]);
                            }
                        }
                    });
                }
                observer.next(_this.resultData);
            });
        });
        return resultObservalble;
    };
    QuestionnaireService.prototype.getSuggestedInvestorAndProduct = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.questionnaire[2] !== "")
                switch (_this.questionnaire[2]) {
                    case 0:
                        resolve({
                            investorArray: [
                                INVESTOR_PROFILE.DISRUPTER,
                                INVESTOR_PROFILE.PROTECTOR,
                            ],
                            suggestedPlan: PRODUCT_TYPE.RC,
                        });
                        break;
                    case 1:
                        resolve({
                            investorArray: [
                                INVESTOR_PROFILE.PRESERVER,
                                INVESTOR_PROFILE.PROTECTOR,
                            ],
                            suggestedPlan: PRODUCT_TYPE.RC,
                        });
                        break;
                    case 2:
                        resolve({
                            investorArray: [
                                INVESTOR_PROFILE.SAVER,
                                INVESTOR_PROFILE.DIVERSIFIER,
                            ],
                            suggestedPlan: PRODUCT_TYPE.SCS,
                        });
                        break;
                    case 3:
                        resolve({
                            investorArray: [
                                INVESTOR_PROFILE.EXPLORER,
                                INVESTOR_PROFILE.DIVERSIFIER,
                            ],
                            suggestedPlan: PRODUCT_TYPE.SCS,
                        });
                        break;
                    case 4:
                        resolve({
                            investorArray: [
                                INVESTOR_PROFILE.RESEARCHER,
                                INVESTOR_PROFILE.COORDINATOR,
                            ],
                            suggestedPlan: PRODUCT_TYPE.IE,
                        });
                        break;
                    case 5:
                        resolve({
                            investorArray: [
                                INVESTOR_PROFILE.RESEARCHER,
                                INVESTOR_PROFILE.COORDINATOR,
                            ],
                            suggestedPlan: PRODUCT_TYPE.IE,
                        });
                        break;
                    default:
                        reject("Error");
                        break;
                }
        });
    };
    QuestionnaireService.prototype.getQuestionnaireThreeData = function () {
        this.selectableQuestionnaireThreeData = [];
        switch (this.questionnaire["2"]) {
            case 0:
                this.selectableQuestionnaireThreeData.push(this.questionnaireThreeData[3]);
                this.selectableQuestionnaireThreeData.push(this.questionnaireThreeData[1]);
                this.selectableQuestionnaireThreeData.push(this.questionnaireThreeData[2]);
                break;
            case 1:
                this.selectableQuestionnaireThreeData.push(this.questionnaireThreeData[5]);
                this.selectableQuestionnaireThreeData.push(this.questionnaireThreeData[1]);
                this.selectableQuestionnaireThreeData.push(this.questionnaireThreeData[2]);
                break;
            case 2:
                this.selectableQuestionnaireThreeData.push(this.questionnaireThreeData[1]);
                this.selectableQuestionnaireThreeData.push(this.questionnaireThreeData[2]);
                this.selectableQuestionnaireThreeData.push(this.questionnaireThreeData[0]);
                break;
            case 3:
                this.selectableQuestionnaireThreeData.push(this.questionnaireThreeData[0]);
                this.selectableQuestionnaireThreeData.push(this.questionnaireThreeData[4]);
                this.selectableQuestionnaireThreeData.push(this.questionnaireThreeData[2]);
                break;
            case 4:
                this.selectableQuestionnaireThreeData.push(this.questionnaireThreeData[4]);
                this.selectableQuestionnaireThreeData.push(this.questionnaireThreeData[3]);
                this.selectableQuestionnaireThreeData.push(this.questionnaireThreeData[1]);
                break;
            case 5:
                this.selectableQuestionnaireThreeData.push(this.questionnaireThreeData[3]);
                this.selectableQuestionnaireThreeData.push(this.questionnaireThreeData[2]);
                this.selectableQuestionnaireThreeData.push(this.questionnaireThreeData[0]);
                break;
            default:
                break;
        }
        return this.selectableQuestionnaireThreeData;
    };
    QuestionnaireService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QuestionnaireService_Factory() { return new QuestionnaireService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.UtilityService)); }, token: QuestionnaireService, providedIn: "root" });
    return QuestionnaireService;
}());
export { QuestionnaireService };
