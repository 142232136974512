<equitable-header-image
  *ngIf="resultData"
  image="./assets/images/results-header.png"
  description="{{ 'questionnaire.result-image-text' | translate }}"
></equitable-header-image>
<div class="result-brief" *ngIf="resultData">
  <div class="container">
    <h2 class="title">
      {{ "questionnaire.headerResultTitle" | translate }}
      {{ resultData.investorProfiles[0].title }} -
      {{ resultData.investorProfiles[1].title }}
    </h2>
    <div class="selected-answer">
      <div
        class="answer-details"
        *ngFor="let item of resultData.answers; let idx = index"
        [ngClass]="idx === resultData.answers.length - 1 ? 'no-border' : ''"
      >
        <div class="text-detail">{{ item.detail }}</div>
        <div class="text-heading">{{ item.heading }}</div>
        <div class="text-description">
          {{ item.description }}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container" *ngIf="resultData">
  <div class="investor-section">
    <div class="investor-heading">
      {{ "questionnaire.inv-heading" | translate }}
    </div>
    <div class="investor-options">
      <div
        class="investor-tabs"
        (click)="onClickInvestor(item.title)"
        *ngFor="let item of resultData.investorProfiles; let i = index"
      >
        <div
          [ngClass]="
            selectedInvestorIndex === i ? 'selected-icon' : 'investor-icon'
          "
        >
          <ion-icon
            [src]="item.icon"
            [ngClass]="selectedInvestorIndex === i ? 'icon-select' : 'icon'"
          >
          </ion-icon>
        </div>
        <div class="investor-title">{{ item.title }}</div>
        <div *ngIf="selectedInvestorIndex === i" class="triangle-up"></div>
      </div>
    </div>
    <div>
      <div class="selected-invester">
        <div class="desktop-investor selected-investor-brief">
          <img
            [alt]="
              resultData.investorProfiles[selectedInvestorIndex].investorDetails
                .name
            "
            [src]="
              resultData.investorProfiles[selectedInvestorIndex].investorDetails
                .image
            "
            class="selected-investor-image"
          />
          <div class="selected-investor-name">
            {{
              resultData.investorProfiles[selectedInvestorIndex].investorDetails
                .name
            }}
          </div>
          <div class="selected-investor-title">
            {{
              resultData.investorProfiles[selectedInvestorIndex].investorDetails
                .title
            }}
          </div>
        </div>
        <div class="mobile-investor selected-investor-brief">
          <img
            [alt]="
              resultData.investorProfiles[selectedInvestorIndex].investorDetails
                .name
            "
            [src]="
              resultData.investorProfiles[selectedInvestorIndex].investorDetails
                .image
            "
            class="selected-investor-image"
          />
          <div class="investor-detail">
            <div class="selected-investor-name">
              {{
                resultData.investorProfiles[selectedInvestorIndex]
                  .investorDetails.name
              }}
            </div>
            <div class="selected-investor-title">
              {{
                resultData.investorProfiles[selectedInvestorIndex]
                  .investorDetails.title
              }}
            </div>
          </div>
        </div>
        <div class="selected-investor-details">
          <div class="details-heading">
            {{
              resultData.investorProfiles[selectedInvestorIndex].investorDetails
                .heading
            }}
          </div>
          <div class="details-text">
            {{
              resultData.investorProfiles[selectedInvestorIndex].investorDetails
                .text
            }}
            <br /><br />
            {{
              resultData.investorProfiles[selectedInvestorIndex].investorDetails
                .subText
            }}
          </div>
        </div>
        <div class="selected-investor-keypoints">
          <span class="keypoint-heading">{{
            resultData.investorProfiles[selectedInvestorIndex].keypoints[0]
              .heading
          }}</span>
          <div class="keypoint-details">
            <div
              *ngFor="
                let item of resultData.investorProfiles[selectedInvestorIndex]
                  .keypoints[0].details
              "
            >
              {{ item }}
            </div>
          </div>
          <span class="keypoint-heading">{{
            resultData.investorProfiles[selectedInvestorIndex].keypoints[1]
              .heading
          }}</span>
          <div class="keypoint-details">
            <div
              *ngFor="
                let item of resultData.investorProfiles[selectedInvestorIndex]
                  .keypoints[1].details
              "
            >
              {{ item }}
            </div>
          </div>
          <span class="keypoint-heading">{{
            resultData.investorProfiles[selectedInvestorIndex].keypoints[2]
              .heading
          }}</span>
          <div class="keypoint-details">
            <equitable-link
              *ngFor="
                let item of resultData.investorProfiles[selectedInvestorIndex]
                  .keypoints[2].details
              "
              [text]="item"
              class="link-class"
              [type]="linkType"
              (click)="navigateToProducts(item)"
            ></equitable-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="annuity-section" *ngIf="resultData">
  <div class="annuity-heading">
    {{ "questionnaire.annuity-heading" | translate }}
  </div>
  <app-selected-annuity
    [title]="resultData.suggestedAnnuity.name"
    [content]="resultData.suggestedAnnuity.text"
    [benefits]="resultData.suggestedAnnuity.benefits"
    [selectedProduct]="resultData.suggestedAnnuity.id"
  ></app-selected-annuity>
</div>
<div class="export-section">
  <div class="export-text">
    {{ "questionnaire.retake-text" | translate }}
  </div>
  <div class="btn-width-style">
    <ion-button
      class="retake-button"
      fill="clear"
      (click)="retakeButtonClicked()"
      >{{ "questionnaire.retake-btn" | translate }}</ion-button
    >
  </div>
</div>
