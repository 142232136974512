import { Injectable } from "@angular/core";
import { HttpResponse, HttpHeaders } from "@angular/common/http";
import { from, Observable, forkJoin, of } from "rxjs";
import { map } from "rxjs/operators";
import { Storage } from "@ionic/storage";
import { MIME_TYPE, API_METHODS } from "../../constants/constants";
import * as plist from "plist";
import { RequestOption } from "../models/app.models";
import { environment } from "../../../environments/environment";
import { HttpRequestService } from "./http-request.service";
import { FileUrlService } from "./file-url.service";

@Injectable({
  providedIn: "root",
})
export class IonicStorageService {
  keys;
  constructor(
    private storage: Storage,
    private httpService: HttpRequestService,
    private urlService: FileUrlService
  ) {
    this.storage.ready().then(() => {});
  }

  // return the underlying driver used to implement storage
  async getDriver() {
    await this.storage.ready();
    this.keys = this.storage.keys();
    return this.storage.driver;
  }

  // save to ionic storage
  set<T, U>(url: string, response: HttpResponse<T>): Promise<U> {
    const body = {
      data:
        typeof response.body == "string"
          ? JSON.parse(response.body)
          : response.body,
    };
    body["lastModifiedDate"] = new Date().getTime();
    const res = this.storage.set(url, body);
    return res;
  }

  // retrieve from ionic storage
  get<T>(url: string): Observable<HttpResponse<T>> {
    return from(this.storage.get(url)).pipe(
      map((data) => {
        let headers: HttpHeaders = new HttpHeaders({});
        if (data) {
          const date =
            (data && data["lastModifiedDate"]
              ? data["lastModifiedDate"]
              : new Date().toString) + "";
          headers = new HttpHeaders({
            "Content-Type": MIME_TYPE.JSON,
            "X-cached-data": "true",
            "X-last-modified-date": date + "",
          });
        }
        return new HttpResponse({ body: data, headers: headers });
      })
    );
  }

  async setValueByKey(key: string, value: any) {
    return await this.storage.set(key, value);
  }

  async getValueByKey(key) {
    return await this.storage.get(key);
  }

  hasKey(key) {
    return this.keys.findIndex(key) != -1 ? true : false;
  }

  //store all urls in main json file
  storeURL(body, baseUrl?) {
    this.urlService.scanForUrl(body, baseUrl);
    const urls = this.urlService.getAllUrls();
    urls.forEach((element) => {
      const requst: RequestOption = {
        method: API_METHODS.GET,
        url: element,
        isBluk: true,
      };
      this.httpService.request(requst).subscribe(
        (res) => {
          // console.log("response stored");
        },
        (error) => {
          //need to check
          //this.storage.clear();
        }
      );
    });
  }

  loadMainData() {
    // return forkJoin([this.getSCSInfo(), this.getRCInfo()]);
    return forkJoin([this.getIEInfo(), this.getSCSInfo(), this.getRCInfo()]);
  }

// added for IE // 
  getIEInfo() {
    var baseURL = environment.serviceAPIs.baseUrl
    if (/https.*/.test(environment.serviceAPIs.ie)) {
      baseURL = ""
    } 
    console.log("XXXIE", environment.serviceAPIs.ie)
    const requst: RequestOption = {
      method: API_METHODS.GET,
      url: baseURL + environment.serviceAPIs.ie,
    };
    this.httpService.request(requst).subscribe((res: any) => {
      //get older storage information. if version is not same then store other
      this.get(requst.url).subscribe((response) => {
        const version = response.body ? response.body["data"]["version"].id : 0;
        if (version !== res.body.version.id) {
          this.storeURL(res.body);
        }
      });
    });
  }


  //method for getting RC info. May shift from here
  getRCInfo() {

    var baseURL = environment.serviceAPIs.baseUrl
    if (/https.*/.test(environment.serviceAPIs.rc)) {
      baseURL = ""
    } 
 console.log("XXXRC", environment.serviceAPIs.rc)

    const requst: RequestOption = {
      method: API_METHODS.GET,
      url: baseURL + environment.serviceAPIs.rc,
    };
    this.httpService.request(requst).subscribe((res: any) => {
      const body = res.body;
      //get older storage information. if version is not same then store other
      this.get(requst.url).subscribe((response) => {
        const version = response.body
          ? response.body["data"]["versionAvailable"]
          : 0;
        if (version !== body.versionAvailable) {
          this.storeURL(body, body.serverURL); //body.serverURL
        }
      });
    });
  }

  //method for getting RC info. May shift from here
  getSCSInfo() {

    var baseURL = environment.serviceAPIs.baseUrl
    if (/https.*/.test(environment.serviceAPIs.scs)) {
      baseURL = ""
    } 

    const requst: RequestOption = {
      method: API_METHODS.GET,
      url: baseURL + environment.serviceAPIs.scs,
    };
    this.httpService.request(requst).subscribe((res: any) => {
      //get older storage information. if version is not same then store other
      this.get(requst.url).subscribe((response) => {
        const version = response.body ? response.body["data"]["version"].id : 0;
        if (version !== res.body.version.id) {
          this.storeURL(res.body);
        }
      });
    });
  }

  parseResponse(url, data) {
    let xml;
    if (
      url.indexOf("scs_ipad_HistoricalReturnValues_v2") != -1 ||
      url.indexOf("scs_main") != -1
    ) {
      const parser = new DOMParser();
      xml = parser.parseFromString(data, "text/xml");
    } else {
      data =
        data.indexOf("<plist") == -1 ? "<plist>" + data + "</plist>" : data;
      xml = plist.parse(data.toString());
    }

    let json;
    // convert JSON object to ideal JSON object format
    if (url.indexOf("howItWorksDynamicData") != -1) {
      json = this.convertHowItWorksDyanmicData(xml);
    } else if (url.indexOf("scs_ipad_CapTrend") != -1) {
      json = this.convertCapTrend(xml);
    } else if (
      url.indexOf("scs_ipad_HistoricalPerformancePresetValues") != -1
    ) {
      json = this.covertHistoricalPresetData(xml);
    } else if (url.indexOf("scs_ipad_HistoricalReturnValues_v2") != -1) {
      json = this.covertHistoricalReturnData(xml);
    } else if (
      url.indexOf("scs_ipad_HistoricalPerformanceChartWithDurations") != -1 ||
      url.indexOf("scs_ipad_HistoricalPerformanceChart_SeriesADV") != -1 ||
      url.indexOf("scs_ipad_HistoricalPerformanceChart_SeriesC") != -1 ||
      url.indexOf("scs_ipad_HistoricalPerformance_5year") != -1
    ) {
      json = this.convertHistoricalPerformance(xml);
    } else if (url.indexOf("scs_main") != -1) {
      json = this.convertSCS(xml);
    } else if (
      url.indexOf("scs_ipad_threePieCharts_SeriesPLUS") !== -1 ||
      url.indexOf("scs_ipad_threePieCharts_SeriesPLUS21") !== -1 ||
      url.indexOf("scs_ipad_well_fargo_threePieCharts")
    ) {
      json = this.convertPiechart(xml);
    } else {
      json = data;
    }
    return json;
  }

  convertSCS(xmlDoc) {
    const seriesNodes = xmlDoc.getElementsByTagName("Series")[0].children;
    const series = [];

    function getKits(series) {
      const kits = [];
      const salesKits = series.getElementsByTagName("SalesKitFiles2")[0]
        .children;
      for (let i = 0; i < salesKits.length; i++) {
        const kit = salesKits[i];
        kits.push({
          type: "SALES",
          id: "PROSPECTUS",
          description: kit.children[0].childNodes[0].nodeValue.trim(),
          image: kit.children[1].childNodes[0].nodeValue.trim(),
          file: kit.children[2].childNodes[0].nodeValue.trim(),
        });
      }
      return kits;
    }

    for (let i = 0; i < seriesNodes.length; i++) {
      if (seriesNodes[i].nodeName != "#text") {
        const objSeries = {
          name: seriesNodes[i].nodeName.replace("Serie", ""),
          performance: {
            durations:
              seriesNodes[i].getElementsByTagName(
                "HistoricalPerformanceWithDurationsURLFile"
              ).length > 0
                ? seriesNodes[i]
                    .getElementsByTagName(
                      "HistoricalPerformanceWithDurationsURLFile"
                    )[0]
                    .childNodes[0].nodeValue.trim()
                : "",
            capTrend:
              seriesNodes[i].getElementsByTagName("PerformanceCapTrendURLFile")
                .length > 0
                ? seriesNodes[i]
                    .getElementsByTagName("PerformanceCapTrendURLFile")[0]
                    .childNodes[0].nodeValue.trim()
                : "",
            historicalReturns:
              seriesNodes[i].getElementsByTagName("HistoricalIndexURLFile_v2")
                .length > 0
                ? seriesNodes[i]
                    .getElementsByTagName("HistoricalIndexURLFile_v2")[0]
                    .childNodes[0].nodeValue.trim()
                : "",
            historicalPerformance:
              seriesNodes[i].getElementsByTagName(
                "HistoricalPerformancePresetValuesOptions"
              ).length > 0
                ? seriesNodes[i]
                    .getElementsByTagName(
                      "HistoricalPerformancePresetValuesOptions"
                    )[0]
                    .childNodes[0].nodeValue.trim()
                : "",
            howItWorks:
              seriesNodes[i].getElementsByTagName("HowItWorksDynamicData")
                .length > 0
                ? seriesNodes[i]
                    .getElementsByTagName("HowItWorksDynamicData")[0]
                    .childNodes[0].nodeValue.trim()
                : "",
            premiumDeathBenefit:
              seriesNodes[i].getElementsByTagName("ReturnPremiumDeathBenefit")
                .length > 0
                ? seriesNodes[i]
                    .getElementsByTagName("ReturnPremiumDeathBenefit")[0]
                    .childNodes[0].nodeValue.trim()
                : "",
            threePieChartsData:
              seriesNodes[i].getElementsByTagName("HMBAG_ThreePieChartsData")
                .length > 0
                ? seriesNodes[i]
                    .getElementsByTagName("HMBAG_ThreePieChartsData")[0]
                    .childNodes[0].nodeValue.trim()
                : "",
          },
          kits: getKits(seriesNodes[i]),
        };

        series.push(objSeries);
      }
    }
    const finalObj = {
      version: {
        id: xmlDoc
          .getElementsByTagName("versionavailable")[0]
          .childNodes[0].nodeValue.trim(),
        message: xmlDoc
          .getElementsByTagName("message")[0]
          .childNodes[0].nodeValue.trim(),
        mandatory: xmlDoc
          .getElementsByTagName("forceUpdate")[0]
          .childNodes[0].nodeValue.trim(),
      },
      notifications: {
        mobile: xmlDoc
          .getElementsByTagName("Notification")[0]
          .childNodes[0].nodeValue.trim(),
        web: xmlDoc
          .getElementsByTagName("NotificationWeb")[0]
          .childNodes[0].nodeValue.trim(),
      },
      languageBaseURL: "",
      series: series,
    };
    return finalObj;
  }

  covertHistoricalReturnData(xmlDoc) {
    const data = [];
    const nodes = xmlDoc.documentElement.childNodes;

    function getLosses(obj) {
      const losses = [];
      for (let j = 0; j < obj.attributes.length; j++) {
        if (obj.attributes[j].name.substr(0, 4) == "loss") {
          losses.push({
            type: obj.attributes[j].name.replace("loss", "").toLowerCase(),
            value: obj.attributes[j].value,
            typeOriginal: obj.attributes[j].name,
          });
        }
      }
      return losses;
    }

    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].nodeName == "index") {
        // console.log(nodes[i].childNodes[0].nodeValue);
        const returns = [];
        for (let j = 0; j < nodes[i].children.length; j++) {
          if (nodes[i].children[j].nodeName == "columm") {
            const objReturn = {
              id: j + 1,
              duration: nodes[i].children[j].attributes["duration"].value,
              averageReturn:
                nodes[i].children[j].attributes["averageReturn"].value,
              totalGainsAndLosses:
                nodes[i].children[j].attributes["totalGainsAndLosses"].value,
              numOfGains: nodes[i].children[j].attributes["numOfGains"].value,
              numOfLosses: nodes[i].children[j].attributes["numOfLosses"].value,
              losses: getLosses(nodes[i].children[j]),
              percentTimesAboveHighestBuffer:
                nodes[i].children[j].attributes[
                  "percentTimesAboveHighestBuffer"
                ].value,
              percentTimesAnyLosses:
                nodes[i].children[j].attributes["percentTimesAnyLosses"].value,
            };
            returns.push(objReturn);
          }
        }
        const obj = {
          id: nodes[i].attributes[0].value,
          label: nodes[i].attributes[1].value,
          returns: returns,
        };
        data.push(obj);
      }
    }
    //console.log(plist);
    return data;
  }

  convertHistoricalPerformance(plist) {
    const data = [];
    for (const key in plist) {
      const historicalPerformance = [];
      for (const bufferAndYear in plist[key]) {
        const indices = [];
        for (const i in plist[key][bufferAndYear].indices) {
          indices.push({
            id: plist[key][bufferAndYear].indices[i].Name,
            name: plist[key][bufferAndYear].indices[i].Name,
            segmentRateValue:
              plist[key][bufferAndYear].indices[i].SegmentRateValue,
            indexReturn: plist[key][bufferAndYear].indices[i].IndexReturn,
            historicalCapRate:
              plist[key][bufferAndYear].indices[i].HistoricalCapRate,
          });
        }
        historicalPerformance.push({
          id: bufferAndYear,
          title: bufferAndYear,
          buffer: plist[key][bufferAndYear].buffer,
          year: plist[key][bufferAndYear].duration,
          duration: plist[key][bufferAndYear].duration,
          indices: indices,
        });
      }
      data.push({
        id: key,
        title: key,
        date: key.split(",")[0],
        historicalPerformance: historicalPerformance,
      });
    }
    return { historicalPerformance: data };
  }

  convertCapTrend(plist) {
    const capTrend = [];

    plist.forEach((index) => {
      // merge the history and dates into a single object
      const history = [];
      for (let i = 0; i < index.History.length; i++) {
        history.push({
          date: index.Dates[i],
          value: index.History[i],
        });
      }

      // create the new object from the original object
      capTrend.push({
        id: index.ID,
        position: index.PositionIndex,
        name: index.Name,
        tenor: index.SubTitle1,
        buffer: index.SubTitle2,
        segment: index.Segment,
        metrics: {
          average: index.Average,
          min: index.Min,
          max: index.Max,
        },
        history: history,
      });
    });

    // console.log(capTrend);
    return capTrend;
  }

  //convert How it Works
  convertHowItWorksDyanmicData(obj) {
    const perfomance = [];

    function getAllPerformaceRate(obj) {
      const perfArray = [];
      for (const property in obj) {
        if (typeof obj[property] == "object") {
          perfArray.push(obj[property]["kIndexPerformanceRate"]);
        }
      }
      return perfArray;
    }

    const res = obj[Object.keys(obj)[0]];
    for (const property in res) {
      if (typeof res[property] == "object") {
        //add years
        //get all keys in years
        const buffers = Object.keys(res[property]);

        buffers.forEach((element) => {
          //get all performace rate
          const objPerformance = {
            id: property,
            max: res[property][element].kMaxValue,
            min: res[property][element].kMinValue,
            interval: res[property][element].kIntervalSteep,
            performaceCapRate: res[property][element].kPerformanceCapRate,
            buffer: element,
            performaceRate: getAllPerformaceRate(res[property][element]),
          };
          perfomance.push(objPerformance);
        });
      }
    }

    const finalObj = {
      id: Object.keys(obj)[0],
      title: res["kCompleteTitle"],
      barTitle: res["kBarTitle"],
      performance: perfomance,
    };
    return finalObj;
  }

  //convert HistoricalData
  covertHistoricalPresetData(obj) {
    const data = [];
    const allHistoricalData = Object.keys(obj);

    function getIndices(obj) {
      const indices = [];
      for (const property in obj) {
        if (typeof obj[property] == "object") {
          const objIndicies = {
            id: property,
            name: obj[property]["Name"],
            percentage: obj[property]["Percentage"],
          };
          indices.push(objIndicies);
        }
      }
      return indices;
    }

    allHistoricalData.forEach((element, index) => {
      const history = {
        id: index + 1,
        option: element,
        maturityDate:
          obj[element]["Maturity Date"] != undefined &&
          obj[element]["Maturity Date"] != null &&
          obj[element]["Maturity Date"] != ""
            ? obj[element]["Maturity Date"].split(",")[0]
            : "",
        series:
          obj[element]["Maturity Date"] != undefined &&
          obj[element]["Maturity Date"] != null &&
          obj[element]["Maturity Date"] != ""
            ? obj[element]["Maturity Date"].split(",")[1].trim()
            : "",
        duration:
          obj[element]["Maturity Date"] != undefined
            ? obj[element]["Maturity Date"]
            : "",
        buffer:
          obj[element]["Duration Buffer"] != undefined
            ? obj[element]["Duration Buffer"]
            : "",
        // duration:
        //   obj[element]["Duration Buffer"] != undefined &&
        //     obj[element]["Duration Buffer"] != null &&
        //     obj[element]["Duration Buffer"] != ""
        //     ? obj[element]["Duration Buffer"].split("Year")[0] + "Year"
        //     : "",
        // buffer:
        //   obj[element]["Duration Buffer"] != undefined &&
        //     obj[element]["Duration Buffer"] != null &&
        //     obj[element]["Duration Buffer"] != ""
        //     ? obj[element]["Duration Buffer"].split("Year")[1].trim()
        //     : "",
        initialInvestment: obj[element]["Initial Investment"],
        indices: getIndices(obj[element]["indices"]),
      };
      data.push(history);
    });
    return {
      presetData: data,
    };
  }

  //convert dropdownwithChartImage
  convertPiechart(plist) {
    const data = [];
    for (let index = 0; index < plist.length; index++) {
      const element = plist[index];
      const dataElement = {};
      dataElement["id"] = element.Index;
      dataElement["value"] = element.Label;
      dataElement["chartImage"] = element.ChartImage;
      data.push(dataElement);
    }
    return {
      pieChartData: data,
    };
  }
}
