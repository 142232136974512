<ion-content>
  <equitable-header-image
    class="top-space"
    image="./assets/images/ie-banner.png"
    [description]="'understandIE.headerTitle' | translate"
  ></equitable-header-image>

  
  <ion-grid class="ion-no-padding">
    <div class="container">
      <!-- IE-discription-section START -->
      <ion-row class="section-space">
        <ion-col>
          <ion-row class="rc-section">
            <ion-col size="12">
              <div class="page-description-wrapper">
                <p
                  class="page-description"
                  [innerHTML]="'understandIE.description' | translate"
                ></p>
              </div>
            </ion-col>
          </ion-row>
          <ion-row class="feature-section">
            <ion-col size="12">
              <h2
                class="feature-heading"
                [innerHtml]="'understandIE.featureTitle' | translate"
              ></h2>
              <!-- equitable-card-benefit  -->
              <div class="benefit-card">
                <div
                  *ngFor="let card of featureCards"
                  class="benefit-card-inner"
                >
                  <equitable-card-benefit
                    [icon]="card.icon"
                    [description]="card.description | translate"
                    [title]="card.title | translate"
                  ></equitable-card-benefit>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <!-- IE-discription-section END -->
    </div>
    <div class="video-section" *ngIf="videos.length > 0">
      <equitable-video
        class="child-video-section"
        [videos]="videos"
        [headerText]="'videoText' | translate"
      ></equitable-video>
    </div>

    <div class="graph-wrapper">
      <div class="container">
        <ion-row class="feature-section">
          <ion-col size="12">
            <h2
              class="feature-heading-graph"
              [innerHtml]="'understandIE.featureTitleGraph' | translate"
            ></h2>
            <div class="feature-subHeading">
              {{ "understandIE.featureSubTitleGraph" | translate }}
            </div>
          </ion-col>
        </ion-row>
        <div class="graph-section">
          <div class="graph-left">
            <h2
              class="feature-heading2-graph"
              [innerHtml]="'understandIE.graphSection.heading' | translate"
            ></h2>
            <div class="small-text">
              {{ "understandIE.graphSection.subText" | translate }}
            </div>
            <img
              [alt]="'understandIE.graphSection.heading' | translate"
              class="graph"
              src="./assets/images/graph-1.png"
            />
          </div>

          <div class="graph-right">
            <h2
              class="feature-heading2-graph"
              [innerHtml]="'understandIE.graphSection.rHeading' | translate"
            ></h2>
            <div class="small-text">
              {{ "understandIE.graphSection.rSubText" | translate }}
            </div>
            <img
              [alt]="'understandIE.graphSection.rHeading' | translate"
              class="graph"
              src="./assets/images/graph-2.png"
            />
          </div>
        </div>
        <ion-row class="feature-section-desc">
          <ion-col>
            <div class="legend-wrap">
              <div class="legend-left">
                <span class="first-circle"></span
                ><span class="custom-color medium-text">{{
                  "understandIE.graphSection.textSec" | translate
                }}</span>
                <span class="legend-text">{{
                  "understandIE.graphSection.subSec" | translate
                }}</span>
              </div>
              <div class="legend-right">
                <span class="first-circle"></span
                ><span class="medium-text">{{
                  "understandIE.graphSection.rTextsec" | translate
                }}</span>
                <span class="legend-text">{{
                  "understandIE.graphSection.rSubSec" | translate
                }}</span>
              </div>
            </div>
            <p class="small-text text-center graph-desc">
              {{ "understandIE.graphSection.details" | translate }}
            </p>
          </ion-col>
        </ion-row>
      </div>
    </div>

    <!-- Strategies START -->
    <section class="explore-other-strategies">
      <div class="container">
        <h2 [innerHtml]="'understandIE.exploreStrategies' | translate"></h2>
        <div class="card-image-container">
            
            <equitable-card-image 
            (eventMore)="gotoSCSINCOMELandingPage()"
            image="./assets/images/income-tile.jpg"
            [heading]="'discoverScs.incomeCardHeading' | translate"
            [description]="'discoverScs.incomeCardCopy' | translate"
            [subContent]="scsincomeBenefits"
          >
            {{ "discoverScs.learnMoreBtn" | translate }}
          </equitable-card-image>

          <equitable-card-image
            (eventMore)="gotoRCLandingPage()"
            image="./assets/images/guaranteed-income.jpg"
            [heading]="'understandIE.guaranteedIncome' | translate"
            [description]="'understandIE.guaranteedIncomeDesc' | translate"
            [subContent]="guaranteedIncome"
          >
            {{ "understandIE.learnMoreBtn" | translate }}
          </equitable-card-image>
          <equitable-card-image
            (eventMore)="gotoSCSLandingPage()"
            image="./assets/images/protection-from-market.jpg"
            [heading]="'understandIE.protectionVolatility' | translate"
            [description]="'understandIE.protectionVolatilityDesc' | translate"
            [subContent]="deferYourTaxes"
          >
            {{ "understandIE.learnMoreBtn" | translate }}
          </equitable-card-image>
        </div>
      </div>
    </section>
    <!-- Strategies END -->

    <!-- Advanced Tool And Resourcex START -->
    <ion-row>
      <ion-col>
        <equitable-advanced-tool
          image="./assets/images/advanced-tools.png"
          [title]="'understandIE.advancedToolsResources' | translate"
          [description]="'understandIE.advancedToolsResourcesDesc' | translate"
          [btnLabel]="'understandIE.learnMoreBtn' | translate"
          (btnClick)="gotoAdvancedToolPage()"
        ></equitable-advanced-tool>
      </ion-col>
    </ion-row>
    <!-- Advanced Tool And Resourcex END -->
  </ion-grid>
  <app-footer
    isBlueFooterVisible="true"
    isWhiteFooterVisible="true"
    selectedProduct="ie"
  ></app-footer>
</ion-content>
