import { OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DisclosureComponent } from "./../disclosure/disclosure.component";
import { DISCLOSURE } from "../../../constants/constants";
import { UtilityService } from "../../services/utility.service";
import { SITE } from "../../../../assets/sites";
import { LinkType } from "equitable-component-library";
var DisclosureRcComponent = /** @class */ (function () {
    function DisclosureRcComponent(translate, discloure, utilityService) {
        this.translate = translate;
        this.discloure = discloure;
        this.utilityService = utilityService;
        this.activeIndex = 0;
        this.site = SITE;
        // for read more link
        this.readMoreLinkType = LinkType.Dark;
        this.toolName = this.discloure.selectedTool;
    }
    DisclosureRcComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translate.get("disclosure.rc").subscribe(function (disclosure) {
            _this.btns = [
                { id: 0, lable: disclosure.terms },
                { id: 1, lable: disclosure.ageLimitation },
                { id: 2, lable: disclosure.diversity },
                { id: 3, lable: disclosure.guaranteedIncome },
                { id: 4, lable: disclosure.inflation },
                { id: 5, lable: disclosure.interestRate },
                { id: 6, lable: disclosure.investmentOptions },
                { id: 7, lable: disclosure.investTaxSmart },
                { id: 8, lable: disclosure.market },
                { id: 9, lable: disclosure.incomePotential }
            ];
        });
        this.toolSelection();
    };
    //tab change selection function
    DisclosureRcComponent.prototype.toolSelection = function () {
        if (DISCLOSURE.RC[this.toolName]) {
            this.selectedName = DISCLOSURE.RC[this.toolName];
        }
        else {
            this.selectedName = 0;
        }
        this.openTab(this.selectedName);
    };
    // To display div
    DisclosureRcComponent.prototype.openTab = function (id) {
        this.activeIndex = parseInt(id);
    };
    // visit external site Equitable.com
    DisclosureRcComponent.prototype.visitEquitable = function (url) {
        this.utilityService.visitEquitableMicrosite(url);
    };
    return DisclosureRcComponent;
}());
export { DisclosureRcComponent };
