import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { UtilityService } from "../../../core/services/utility.service";
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { LinkIcon, Icon } from "equitable-component-library";
import {
  CUSTOMIZATION_TYPE,
  ROUTE_PATH,
  SCS_SERIES,
  BUTTON_ROLE,
  REPORT_KEY,
  CHARACTER_LIMIT,
} from "../../../constants/constants";
import { Profile } from "../../../core/models/app.models";
import { ScsService } from "../../services/scs.service";
import { TranslateService } from "@ngx-translate/core";
import { CoreService } from "../../../core/services/core.service";
import { pairwise } from "rxjs/operators";
import { FeatureFlagService } from "../../../core/services/feature-flag.service";
import { TealiumUtagService } from '../../../tealium/utag.service';

@Component({
  selector: "app-custom-profile",
  templateUrl: "./custom-profile.component.html",
  styleUrls: ["./custom-profile.component.scss"],
})
export class CustomProfileComponent implements OnInit {
  linkRightIcon = LinkIcon.RightIcon;
  linkLeftIcon = LinkIcon.LeftIcon;
  AddProfile = Icon.AddProfile;
  profile: FormGroup;
  customProfileData: Profile[] = [];
  listIndex = 0;
  currentProfile: Profile = new Profile();
  customContents = [];
  btnSaveProfile;
  investorProfileText;
  maxlength = CHARACTER_LIMIT.INPUT;
  maxlengthTextArea = CHARACTER_LIMIT.TEXTAREA;
  series: string;
  backLink;
  backButtonSeries =
    this.scsService.series == SCS_SERIES.PLUS ? SCS_SERIES.PLUS : "";
  backButtonSeries21 =
    this.scsService.series == SCS_SERIES.PLUS21 ? SCS_SERIES.PLUS21 : "";
  isEditMode = false;
  showEdit = false;
  oldValue;
  /***
   *  Constructor
   ***/
  constructor(
    private utilityService: UtilityService,
    private formBuilder: FormBuilder,
    private scsService: ScsService,
    private coreService: CoreService,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private featureService: FeatureFlagService,
    private tealium: TealiumUtagService
  ) {
    this.series = this.scsService.series;
  }

  /***
   *  Page load
   ***/
  ngOnInit() {
    if (this.scsService.series === SCS_SERIES.PLUS) {
      this.backLink = this.translateService.instant(
        "createReport.backButtonTextPlus"
      );
    } 
    else if (this.scsService.series === SCS_SERIES.PLUS21) {
      this.backLink = this.translateService.instant(
        "createReport.backButtonTextPlus21"
      );
    }
    
    else {
      this.backLink = this.translateService.instant(
        "createReport.backButtonText"
      );
    }
    //build form
    this.buildForm();
    //page load data
    this.getPageData();
    //load all profile
    this.loadProfiles();

    // this.tealium.link({
    //   tealium_event: 'link',
    //   event_category: 'BodyClick',
    //   event_name: 'Retirement Guide Clicks',
    //   event_label: 'Click on Custom Profile'
    // })
  }

  /***
   *  page Load
   ***/
  ionViewWillEnter() {
    if (this.scsService.series === SCS_SERIES.PLUS) {
      this.backLink = this.translateService.instant(
        "createReport.backButtonTextPlus"
      );
    } 
    else if (this.scsService.series === SCS_SERIES.PLUS21) {
      this.backLink = this.translateService.instant(
        "createReport.backButtonTextPlus21"
      );
    }
    
    else {
      this.backLink = this.translateService.instant(
        "createReport.backButtonText"
      );
    }
    //build form
    this.buildForm();
    //page load data
    this.getPageData();
    //load all profile
    this.loadProfiles();
  }

  /***
   *  Build all form controls
   ***/
  buildForm() {
    this.profile = this.formBuilder.group({
      profileName: new FormControl(
        this.currentProfile.name,
        Validators.required
      ),
      profileComment: new FormControl(this.currentProfile.comment),
    });
    this.profile.valueChanges.pipe(pairwise()).subscribe((res) => {});
  }

  /***
   *  Reset form
   ***/
  resetForm() {
    this.profile.get("profileName").reset();
    this.profile.get("profileComment").reset();

    //enable which one loaded
    this.customContents.forEach((element) => {
      if (this.currentProfile[element.reportKey]) {
        element.link.label = this.investorProfileText.edit;
      } else {
        element.link.label =
          this.customProfileData.length > 0
            ? this.investorProfileText.customize
            : "";
      }
    });
    this.btnSaveProfile = this.investorProfileText.btnSaveProfile;
    this.showEdit = false;
    this.isEditMode = false;
  }

  /***
   *  Back button
   ***/
  backButton() {
    this.utilityService.navigate(ROUTE_PATH.SCS.TOOLS.HOME);
  }

  /***
   *  function for load all saved profile locally
   ***/
  loadProfiles() {
    this.scsService.getProfile().subscribe((profiles: Profile[]) => {
      profiles = profiles ? profiles : new Array<Profile>();
      profiles = profiles.filter(
        (x) =>
          x.currentSerie === this.scsService.series &&
          x.name !== CUSTOMIZATION_TYPE.REPORT
      );
      if (profiles && profiles.length > 0) {
        this.customProfileData = profiles;
        if (this.coreService.profile.getValue().name !== "") {
          const savedProfile = profiles.find(
            (x) => x.name === this.coreService.profile.getValue().name
          );
          if (savedProfile) {
            this.currentProfile = savedProfile;
          } else {
            this.currentProfile = profiles[0];
          }
        } else {
          this.currentProfile = profiles[0];
        }
        this.listIndex = profiles.findIndex(
          (x) => x.name === this.currentProfile.name
        );
        this.displayDetail(this.currentProfile, this.listIndex);
      } else {
        this.customProfileData = new Array<Profile>();
        this.currentProfile = new Profile();
        this.resetForm();
      }

      this.cd.detectChanges();
    });
  }

  /***
   *  get master page data for resources
   ***/
  getPageData() {
    this.translateService
      .get("investorProfileText")
      .subscribe((investorProfileText) => {
        this.investorProfileText = investorProfileText;
        this.btnSaveProfile = investorProfileText.btnSaveProfile;
      });

    //add custome content its dynamic based on series B,C, ADV, PLUS
    this.translateService.get("createReport").subscribe((createReport) => {
      const customContents = [
        {
          description: createReport.checkboxDescription1,
          link: {
            label: "",
            path: "/" + ROUTE_PATH.SCS.TOOLS.HOW_SIO_WORKS_DETAILS,
          },
          imagePath: "./assets/images/report-checkbox.jpg",
          reportKey: REPORT_KEY.HOW_SIO_WORKS,
        },
        {
          description: createReport.checkboxDescription2,
          link: {
            label: "",
            path:
              "/" +
              ROUTE_PATH.SCS.TOOLS.HISTORICAL_PERFORMANCE +
              "/" +
              ROUTE_PATH.SCS.TOOLS.SEGMENT_PERFORMANCE,
          },
          imagePath: "./assets/images/report-checkbox2.jpg",
          reportKey: REPORT_KEY.HISTORICAL_PERFORMANCE,
        },
        {
          description: createReport.checkboxDescription3,
          link: {
            label: "",
            path:
              "/" +
              ROUTE_PATH.SCS.TOOLS.HISTORICAL_PERFORMANCE +
              "/" +
              ROUTE_PATH.SCS.TOOLS.HISTORY_GUIDE,
          },
          imagePath: "./assets/images/report-checkbox3.jpg",
          reportKey: REPORT_KEY.HISTORY_GUIDE,
        },
        {
          description: createReport.checkboxDescription3,
          link: {
            label: "",
            path: "/" + ROUTE_PATH.SCS.TOOLS.HISTORICAL_PERFORMANCE_PLUS,
          },
          imagePath: "./assets/images/report-checkbox4.jpg",
          reportKey: REPORT_KEY.HISTORICAL_PERFORMANCE_PLUS,
        },
      ];

      //sppecial fix for primerica
      if (this.featureService.featureFlags.report === "PRIMERICA_REPORT") {
        customContents[2].imagePath = "./assets/images/report-checkbox4.jpg";
        customContents[2].link.label = this.scsService.report
          .historyMayBeAGuidePlus
          ? this.translateService.instant("createReport.edit")
          : this.translateService.instant("createReport.customize");
        customContents[2].reportKey = "historyMayBeAGuidePlus";
      }

      //if series plus then only how-sio-works and pie charts
      if (
        this.scsService.series === SCS_SERIES.B ||
        this.scsService.series === SCS_SERIES.ADV ||
        this.scsService.series === SCS_SERIES.C
      ) {
        customContents.splice(3, 1);
        customContents.splice(1, 1);
      } else if (this.scsService.series === SCS_SERIES.PLUS) {
        customContents.splice(1, 2);
      }
      else if (this.scsService.series === SCS_SERIES.PLUS21) {
        customContents.splice(1, 2);
      }
      this.customContents = customContents;
    });
  }

  /***
   *  add Profile button click event
   ***/
  addProfile() {
    this.isEditMode = false;
    this.showEdit = false;
    this.currentProfile = new Profile();
    this.resetForm();
    this.btnSaveProfile = this.translateService.instant(
      "investorProfileText.btnSaveProfile"
    );
  }

  editProfile() {
    this.isEditMode = true;
    this.oldValue = this.currentProfile.name;
    this.btnSaveProfile = this.translateService.instant(
      "investorProfileText.btnSaveProfile"
    );
  }

  /***
   *  delete Profile from storage
   ***/
  deleteProfile() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;
    if (this.currentProfile.name !== "") {
      this.utilityService.showAlert({
        message: this.investorProfileText.deleteProfile,
        title: "",
        buttons: [
          {
            role: BUTTON_ROLE.OK,
            text: this.investorProfileText.ok,
            handler: function () {
              _this.scsService
                .deleteProfile(_this.currentProfile.name)
                .subscribe(() => {
                  //remove if currently loaded profile in delete profile
                  if (
                    _this.scsService.currentProfile == _this.currentProfile.name
                  ) {
                    _this.coreService.profile.next({
                      name: "",
                      type: CUSTOMIZATION_TYPE.REPORT,
                      profile: new Profile(),
                    });
                  }
                  //reset form
                  _this.resetForm();

                  //load profile for current pages
                  _this.loadProfiles();
                });
            },
          },
          {
            role: BUTTON_ROLE.CANCEL,
            text: this.investorProfileText.cancel,
          },
        ],
      });
    }
  }

  /***
   *  Show current profile when user tap on item
   ***/
  displayDetail(profile: Profile, index: number) {
    this.isEditMode = false;
    this.showEdit = true;
    this.listIndex = index;
    this.profile.get("profileName").setValue(profile.name);
    this.profile.get("profileComment").setValue(profile.comment);
    //set text
    this.currentProfile = profile;
    this.btnSaveProfile = this.translateService.instant(
      "investorProfileText.btnLoadProfile"
    );

    //enable which one loaded
    this.customContents.forEach((element) => {
      if (this.currentProfile[element.reportKey]) {
        element.link.label = this.investorProfileText.edit;
      } else {
        element.link.label = this.investorProfileText.customize;
      }
    });
  }

  /***
   *  Save Profile in ionic storage for future persepective
   ***/
  saveProfile() {
    if (
      this.btnSaveProfile ==
      this.translateService.instant("investorProfileText.btnLoadProfile")
    ) {
      this.scsService.currentProfile = this.currentProfile.name;
      this.scsService.customizationType = CUSTOMIZATION_TYPE.PROFILE;
      this.coreService.profile.next({
        name: this.currentProfile.name,
        type: CUSTOMIZATION_TYPE.PROFILE,
        profile: this.currentProfile,
      });
    } else {
      if (this.profile.valid) {
        this.showEdit = true;
        this.scsService.customizationType = CUSTOMIZATION_TYPE.PROFILE;
        const profile = new Profile();

        this.scsService.getProfile().subscribe((profiles: Profile[]) => {
          profiles = profiles ? profiles : new Array<Profile>();
          const oldProfile = profiles.find(
            (x) =>
              x.name === this.oldValue &&
              x.currentSerie === this.scsService.series
          );
          if (oldProfile) {
            oldProfile.name = this.profile.get("profileName").value;
            oldProfile.comment = this.profile.get("profileComment").value;
          } else {
            profile.name = this.profile.get("profileName").value;
            profile.comment = this.profile.get("profileComment").value;
            profile.currentSerie = this.scsService.series;
            profiles.push(profile);
          }

          this.scsService.saveProfile(profiles);
          this.customProfileData = profiles.filter(
            (x) =>
              x.currentSerie == this.scsService.series &&
              x.name !== CUSTOMIZATION_TYPE.REPORT
          );
          this.listIndex = this.customProfileData.findIndex(
            (x) => x.name === profile.name
          );
          this.displayDetail(oldProfile ? oldProfile : profile, this.listIndex);
          //load newly added profile
          this.scsService.currentProfile = profile.name;
          this.scsService.customizationType = CUSTOMIZATION_TYPE.PROFILE;
          this.coreService.profile.next({
            name: profile.name,
            type: CUSTOMIZATION_TYPE.PROFILE,
            profile: oldProfile ? oldProfile : profile,
          });
        });
      }
    }
  }

  /******
   * Custom Content navigation
   */
  navigateToCustomContent(link: string) {
    this.coreService.profile.next({
      name: this.currentProfile.name,
      type: CUSTOMIZATION_TYPE.PROFILE,
      profile: this.currentProfile,
    });
    this.utilityService.navigate(link);
  }

  goToSampleProfile() {
    this.utilityService.navigate(ROUTE_PATH.SCS.TOOLS.INVESTOR_PROFILE, {
      section: "scs",
    });
  }
}
