import { Injectable } from "@angular/core";
import { HttpRequestService } from "../../core/services/http-request.service";
import { API_METHODS } from "../../constants/constants";
import { environment } from "../../../environments/environment";
import { map, catchError, switchMap } from "rxjs/operators";
import { of, throwError, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RcService {
  //property to save Main RC Data
  rc: any;

  /***
   *  RC Service Constructor
   ***/
  constructor(private httpRequestService: HttpRequestService) {}

  /***
   *  Fetch respurces
   ***/
  fetchResources() {
    var baseURL = environment.serviceAPIs.baseUrl
    if (/https/.test(environment.serviceAPIs.rc)) {
      var baseURL = ""
    } 

    const requestOptions = {
      method: API_METHODS.GET,
      url: baseURL + environment.serviceAPIs.rc,
    };
    return this.httpRequestService.request(requestOptions).pipe(
      map((data: any) => {
        this.rc = data.body;
        return data.body;
      }),
      catchError((err) => {
        throw throwError(err);
      })
    );
  }

  /***
   *  get RC Main Data
   ***/
  getRcData(): Observable<any> {
    if (this.rc) {
      return of(this.rc);
    } else {
      return this.fetchResources();
    }
  }

  /***
   *  fetch the DATA from server/storage
   ***/
  fetchRcData() {
    return this.getRcData().pipe(
      switchMap(() => {
        return this.httpRequestService
          .request({
            method: API_METHODS.GET,
            url: environment.serviceAPIs.baseUrl + 'assets/apis' + this.rc.GuaranteedIncomeForLifeJSON,
          })
          .pipe(
            map((data: any) => {
              return data.body;
            }),
            catchError((err) => {
              throw throwError(err);
            })
          );
      })
    );
  }

  //
  /***
   *  fetch InvestTaxResource from API
   ***/
  fetchInvestTaxSmartChartData(): Observable<any> {
    return this.getRcData().pipe(
      switchMap(() => {
        const requestOptions = {
          method: API_METHODS.GET,
          url:
            environment.serviceAPIs.baseUrl + 'assets/apis' + this.rc.TaxDeferredGrowthURLJSON,
        };
        return this.httpRequestService.request(requestOptions).pipe(
          map((data: any) => {
            return data.body;
          }),
          catchError((err) => {
            throw throwError(err);
          })
        );
      })
    );
  }

  /***
   *  fetch Resource from API
   ***/
  fetchDiversify() {
    return this.getRcData().pipe(
      switchMap(() => {
        const requestOptions = {
          method: API_METHODS.GET,
          url: environment.serviceAPIs.baseUrl + 'assets/apis' + this.rc.DiversificationJSON,
        };
        return this.httpRequestService.request(requestOptions);
      })
    );
  }

  //
  /***
   *  fetch InvestOptions Json from API
   ***/
  fetchInvestmentOptionsData(): Observable<any> {
    return this.getRcData().pipe(
      switchMap(() => {
        const requestOptions = {
          method: API_METHODS.GET,
          url:
          "assets/apis/retirement-cornerstone/YourInvestmentOptions.json",
            //environment.serviceAPIs.baseUrl + this.rc.YourInvestmentOptionsJSON,
        };
        return this.httpRequestService.request(requestOptions).pipe(
          map((data: any) => {
            return data.body;
          }),
          catchError((err) => {
            throw throwError(err);
          })
        );
      })
    );
  }
}
