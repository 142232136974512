import { TOOLS_NAME } from "./../../../constants/constants";
import { FeatureFlagService } from "./../../../core/services/feature-flag.service";
import { OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { LinkType, LinkIcon, Orientation, BoxButtonIcon, } from "equitable-component-library";
import { Profile, } from "../../../core/models/app.models";
import { UtilityService } from "../../../core/services/utility.service";
import { FormControl, Validators, FormBuilder, } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { SCSINCOME_SERIES, ROUTE_PATH, PRODUCT_TYPE, CUSTOMIZATION_TYPE, CONFIG_DATA, } from "../../../constants/constants";
import { SITE } from "src/assets/sites";
import { ScsIncomeService } from "../../services/scsincome.service";
import { ModalPopupComponent } from "../../../core/components/modal-popup/modal-popup.component";
import { CoreService } from "../../../core/services/core.service";
import { TealiumUtagService } from '../../../tealium/utag.service';
var ScsAdvancedToolResourcesDashboardComponent = /** @class */ (function () {
    function ScsAdvancedToolResourcesDashboardComponent(utilityService, translate, scsIncomeService, flagService, coreService, formBuilder, location, tealium) {
        this.utilityService = utilityService;
        this.translate = translate;
        this.scsIncomeService = scsIncomeService;
        this.flagService = flagService;
        this.coreService = coreService;
        this.formBuilder = formBuilder;
        this.location = location;
        this.tealium = tealium;
        this.LinkType = LinkType;
        this.LinkIcon = LinkIcon;
        this.Orientation = Orientation;
        this.isDisplay = true;
        this.isIR = CONFIG_DATA.ISIR;
        this.toolDashboardData = {
            tools: [],
        };
        this.getSeries = this.scsIncomeService.series || SCSINCOME_SERIES.B || SCSINCOME_SERIES.ADV;
        this.site = SITE;
    }
    ScsAdvancedToolResourcesDashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dashboardForm = this.formBuilder.group({
            seriesDropDown: new FormControl({ value: this.getSeries, disabled: false }, Validators.required),
        });
        //reset selection
        if (this.coreService.product !== PRODUCT_TYPE.SCSINCOME) {
            this.coreService.profile.next({
                name: "",
                type: CUSTOMIZATION_TYPE.REPORT,
                profile: new Profile(),
            });
            this.coreService.report = new Profile();
        }
        this.coreService.product = PRODUCT_TYPE.SCSINCOME;
        this.dashboardForm.valueChanges.subscribe(function (data) {
            //reset report data (if any)
            _this.scsIncomeService.report = new Profile();
            _this.scsIncomeService.customizationType = CUSTOMIZATION_TYPE.REPORT;
            //reset selected profile
            _this.scsIncomeService.currentProfile = "";
            _this.dropDownSection(data.seriesDropDown);
            _this.coreService.profile.next({
                name: "",
                type: CUSTOMIZATION_TYPE.REPORT,
                profile: new Profile(),
            });
        });
        // Dropdown Data
        this.translate.get("scsincomeDashboard").subscribe(function (translated) {
            _this.toolTipText = translated.tooltipText;
            _this.investorProfiles = translated.investorProfiles;
            _this.seriesOptions = [];
            if (_this.flagService.featureFlags.isPrimerica) {
                _this.seriesOptions = [
                    {
                        id: SCSINCOME_SERIES.B,
                        value: translated.seriesB,
                    },
                ];
            }
            else {
                _this.seriesOptions = [
                    {
                        id: SCSINCOME_SERIES.B,
                        value: translated.seriesB,
                    },
                    {
                        id: SCSINCOME_SERIES.ADV,
                        value: translated.seriesAdv,
                    },
                ];
            }
            _this.isNotPlusGuard = !_this.flagService.featureFlags.isPlusGuard;
            for (var toolName in _this.flagService.featureFlags.scsincome.tools) {
                switch (toolName) {
                    case TOOLS_NAME.PROTECT_INCOME:
                        if (_this.flagService.featureFlags.scsincome.tools[TOOLS_NAME.PROTECT_INCOME]) {
                            _this.toolDashboardData.tools.push({
                                icon: BoxButtonIcon.ProtectIncome,
                                label: translated.protectIncome,
                                routePath: ROUTE_PATH.SCSINCOME.TOOLS.PROTECT_INCOME,
                            });
                        }
                        break;
                    case TOOLS_NAME.HOW_SIO_WORKS:
                        if (_this.flagService.featureFlags.scsincome.tools[TOOLS_NAME.HOW_SIO_WORKS]) {
                            _this.toolDashboardData.tools.push({
                                icon: BoxButtonIcon.HowSIOWorks,
                                label: translated.howItWorks,
                                routePath: ROUTE_PATH.SCSINCOME.TOOLS.HOW_SIO_WORKS,
                            });
                        }
                        break;
                    case TOOLS_NAME.PRESERVE_LEGACY:
                        if (_this.flagService.featureFlags.scsincome.tools[TOOLS_NAME.PRESERVE_LEGACY]) {
                            _this.toolDashboardData.tools.push({
                                icon: BoxButtonIcon.PreserveLegacy,
                                label: translated.preserveLegacy,
                                routePath: ROUTE_PATH.SCSINCOME.TOOLS.PRESERVE_LEGACY,
                            });
                        }
                        break;
                    case TOOLS_NAME.PERFORMANCE_CAP_RATE:
                        if (_this.flagService.featureFlags.scsincome.tools[TOOLS_NAME.PERFORMANCE_CAP_RATE]) {
                            _this.toolDashboardData.tools.push({
                                icon: BoxButtonIcon.PerformanceCapRates,
                                label: translated.performanceCapRate,
                                routePath: ROUTE_PATH.SCSINCOME.TOOLS.PERFORMANCE_CAP,
                                newTab: true,
                            });
                        }
                        break;
                    case TOOLS_NAME.CREATE_A_REPORT:
                        if (_this.flagService.featureFlags.scsincome.tools[TOOLS_NAME.CREATE_A_REPORT]) {
                            _this.toolDashboardData.tools.push({
                                icon: BoxButtonIcon.CreateAReport,
                                label: translated.createReport,
                                routePath: ROUTE_PATH.SCSINCOME.TOOLS.CREATE_REPORT,
                            });
                        }
                        break;
                    case TOOLS_NAME.HISTORICAL_PERFORMANCE:
                        if (_this.flagService.featureFlags.scsincome.tools[TOOLS_NAME.HISTORICAL_PERFORMANCE]) {
                            console.log('CARDCLICK this.scsIncomeService.series', _this.scsIncomeService.series);
                            _this.toolDashboardData.tools.push({
                                icon: BoxButtonIcon.HistoricalPerformance,
                                label: translated.historicalPerformance,
                                routePath: ROUTE_PATH.SCSINCOME.TOOLS.HISTORICAL_PERFORMANCE,
                            });
                        }
                        break;
                    case TOOLS_NAME.PRODUCT_OVERVIEW:
                        if (_this.flagService.featureFlags.scsincome.tools[TOOLS_NAME.PRODUCT_OVERVIEW]) {
                            _this.toolDashboardData.tools.push({
                                icon: BoxButtonIcon.ProductOverview,
                                label: translated.productOverview,
                                routePath: ROUTE_PATH.SCSINCOME.DISCOVER,
                            });
                        }
                        break;
                    default:
                        break;
                }
            }
            if (_this.getSeries === SCSINCOME_SERIES.B) {
                _this.headingName = translated.headerTitlePlus;
            }
            else if (_this.getSeries === SCSINCOME_SERIES.ADV) {
                _this.headingName = translated.headerTitlePlus21;
            }
            else {
                _this.headingName = translated.headerTitle;
            }
        });
        //this.dropDownSection(this.getSeries);
        if (this.getSeries === SCSINCOME_SERIES.B) {
            this.isDisplay = true;
            this.headingName = this.translate.instant("scsincomeDashboard.headerTitle");
        }
        else if (this.getSeries === SCSINCOME_SERIES.ADV) {
            this.isDisplay = true;
            this.headingName = this.translate.instant("scsincomeDashboard.headerTitle");
        }
        else {
            this.isDisplay = false;
            this.headingName = this.translate.instant("scsincomeDashboard.headerTitle");
        }
        // Learning Resources
        this.getResourcesData(this.getSeries);
    };
    // display Benefits
    ScsAdvancedToolResourcesDashboardComponent.prototype.dropDownSection = function (data) {
        if (data === SCSINCOME_SERIES.B) {
            this.isDisplay = true;
            this.getResourcesData(SCSINCOME_SERIES.B);
            this.headingName = this.translate.instant("scsincomeDashboard.headerTitle");
        }
        else if (data === SCSINCOME_SERIES.ADV) {
            this.isDisplay = false;
            this.getResourcesData(SCSINCOME_SERIES.ADV);
            this.headingName = this.translate.instant("scsincomeDashboard.headerTitle");
        }
        //set series in session
        this.scsIncomeService.series = data;
        this.scsIncomeService.investorData.index = "";
        this.scsIncomeService.investorData.segment = "";
        this.scsIncomeService.investorData.duration = "";
        this.scsIncomeService.investorData.protection = "";
        if (data === SCSINCOME_SERIES.ADV)
            this.toolDashboardData.tools[1].routePath =
                ROUTE_PATH.SCSINCOME.TOOLS.HISTORICAL_PERFORMANCE_PLUS;
        else if (data === SCSINCOME_SERIES.B)
            this.toolDashboardData.tools[1].routePath =
                ROUTE_PATH.SCSINCOME.TOOLS.HISTORICAL_PERFORMANCE;
        else
            this.toolDashboardData.tools[1].routePath =
                "/" + ROUTE_PATH.SCSINCOME.TOOLS.HISTORICAL_PERFORMANCE;
    };
    // Learning Resources
    ScsAdvancedToolResourcesDashboardComponent.prototype.getResourcesData = function (data) {
        var _this = this;
        this.scsIncomeService.getResources(data).subscribe(function (kits) {
            _this.learningResources = kits;
        });
    };
    // On click Card button
    ScsAdvancedToolResourcesDashboardComponent.prototype.cardClick = function (link, label, newTab) {
        if (newTab) {
            window.open(link, '_blank');
        }
        else {
            if (label === this.investorProfiles) {
                this.utilityService.presentWithoutTitleModal(ModalPopupComponent);
            }
            else {
                this.utilityService.navigate(link, {
                    section: "SCSINCOME"
                });
            }
        }
    };
    // openExternalLink
    ScsAdvancedToolResourcesDashboardComponent.prototype.goToResources = function (resource) {
        this.tealium.link({
            tealium_event: 'link',
            event_category: 'BodyClick',
            event_name: 'Retirement Guide Clicks',
            event_label: 'Click on ' + resource.description
        });
        this.utilityService.visitEquitable(resource.file);
    };
    // back button navigation
    ScsAdvancedToolResourcesDashboardComponent.prototype.backButton = function () {
        this.location.back();
        // if (this.flagService.featureFlags.isIR) {
        //   this.utilityService.navigate(ROUTE_PATH.HOME);
        // } else {
        //   this.utilityService.navigate(ROUTE_PATH.HOME);
        // }
    };
    ScsAdvancedToolResourcesDashboardComponent.prototype.goToIeLandingPage = function () {
        this.utilityService.navigateWithoutParams(ROUTE_PATH.IE.TOOLS.HOME);
    };
    ScsAdvancedToolResourcesDashboardComponent.prototype.goToRcLandingPage = function () {
        this.utilityService.navigateWithoutParams(ROUTE_PATH.RC.TOOLS.HOME);
    };
    ScsAdvancedToolResourcesDashboardComponent.prototype.goToScsLandingPage = function () {
        this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER + '?series=scs16');
    };
    ScsAdvancedToolResourcesDashboardComponent.prototype.goToScsIncomeLandingPage = function () {
        this.utilityService.navigate(ROUTE_PATH.SCSINCOME.DISCOVER);
    };
    // visit external site Equitable.com
    ScsAdvancedToolResourcesDashboardComponent.prototype.visitEquitable = function (url) {
        this.utilityService.visitEquitableMicrosite(url);
    };
    return ScsAdvancedToolResourcesDashboardComponent;
}());
export { ScsAdvancedToolResourcesDashboardComponent };
