<ion-content>
  <ion-grid class="ion-headerless">
    <div class="container">
      <ion-row class="section-space">
        <ion-col>
          <div class="row">
            <div class="col-12">
              <h2 class="feature-heading">Fund Focus</h2>
              <h4 class="feature-subheading">Sustainable Investing</h4>
            </div>
            <div class="col-6">
              <p>These days, many investors would like to practice environmentally, governmentally and socially responsible investing. That’s why ESG funds are becoming more and more popular. With Equitable’s variable annuities, you can select from over 100 investment options including a variety of ESG funds that fit your needs and goals, provide exposure to dynamic sections in today’s economy, and help build a brighter tomorrow for our country and our world. </p>
              <p>Investment Edge<sup>®</sup> offers an investment lineup that includes ESG funds that focus on responsible investing themes such as sustainable energy, water conservation, and solar power.</p>

              <ul class="no-bullet">
                <li>
                  <p class="list-head">Learn more about our Sustainable ESG Funds</p>
                </li>
                <li>
                  <a href="https://doc.morningstar.com/LatestDoc.aspx?clientid=equitable&key=7ce0969355ba39d5&documenttype=124&sourceid=245&cusip=29439V804" target="_blank">1290 VT Smart Beta Equity Morningstar Fact Sheet</a>
                </li>
                <li>
                  <a href="https://doc.morningstar.com/LatestDoc.aspx?clientid=equitable&key=7ce0969355ba39d5&documenttype=124&sourceid=245&cusip=268940525" target="_blank">ClearBridge Large Cap Growth ESG Morningstar Fact Sheet</a>
                </li>
                <li>
                  <a href="https://doc.morningstar.com/LatestDoc.aspx?clientid=equitable&key=7ce0969355ba39d5&documenttype=124&sourceid=245&cusip=29439V176" target="_blank">Pimco Total Return ESG Morningstar Fact Sheet</a>
                </li>
                <li>
                  <a href="https://portal.equitable.com/appentry/EDoxRedirect?node_id=A2022052400002" target="_blank">EIM Sustainable Investing Flyer</a>
                </li>
              </ul>
            </div>
            <div class="col-6 fact-card-contain">
              <div class="fact-card" style="--index: 0;">
                <div class="fc-heading">76%</div>
                <div class="fc-content">of consumers expect to stop buying from companies that treat the environment,
                  employees or their communities poorly.</div>
              </div>
              <div class="fact-card" style="--index: 1;">
                <div class="fc-heading">217</div>
                <div class="fc-content">companies have signed The Climate Pledge, to reach the Paris Agreement goal 10
                  years early.</div>
              </div>
              <div class="fact-card" style="--index: 2;">
                <div class="fc-heading">$50t</div>
                <div class="fc-content">by 2025, more than one-third of a forecasted $40.5 in total global AUM is
                  expected to be in ESG assets.</div>
              </div>
            </div>
          </div>

        </ion-col>
      </ion-row>
    </div>
  </ion-grid>

  <app-footer isWhiteFooterVisible="false" isBlueFooterVisible="true" selectedProduct="ie"></app-footer>
</ion-content>