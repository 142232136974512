<div>
  <div class="main">
    <ion-row>
      <div class="intro">
        <ion-col>
          <div class="title">
            {{ "historyGuide.title" | translate }}
          </div>
          <div class="description">
            {{ "historyGuide.description" | translate }}
          </div>
        </ion-col>
      </div>
      <ion-col
        class="ion-no-padding"
        *ngIf="performanceHistory && performanceHistory.length > 0"
      >
        <div class="chart">
          <ion-row class="title-row" [formGroup]="historicalPerformance">
            <ion-col class="dropdown-box" size="3.5">&nbsp;</ion-col>
            <ion-col class="dropdown-box" *ngFor="let item of formKeys">
              <equitable-drop-down
                [formControl]="historicalPerformance.get(item)"
                [formControlName]="item"
                [options]="options"
              ></equitable-drop-down>
            </ion-col>
          </ion-row>
          <ion-row XXX
            class="performance-title"
            *ngFor="let item of performanceParameters; index as i"
            [ngClass]="{
              'title-column': !(i === 0 || i === 2 || i === 4 || i === 11),
              'title-column-medium': i === 0
            }"
          >
            <ion-col YYY
              size="3.5"
              [ngClass]="{
                'title-column-bold': i === 1 || i === 2 || i === 3 || i === 4,
                'title-column-medium': i === 11 || i === 12
              }"
            >
              {{ item.value }}
            </ion-col>

            <ion-col ZZZ class="performance-data" *ngFor="let data of formKeys">
              <ion-col
                class="values"
                *ngFor="let performance of getReturnsForIndex(data)"
                [ngClass]="{ 'title-column-height': i === 11 }"
              >
                <div *ngIf="i < 5 || i > 10">
                  {{ performance[item.key] }}
                </div>
                <div *ngIf="i >= 5 && i <= 10">
                  {{ performance.losses[item.key].value }}
                </div>
              </ion-col>
            </ion-col>
          </ion-row>
          <div class="series-title" *ngIf="isNotPlusGuard">
            {{ "historyGuide.viewingSeries" | translate }} {{ seriesType }}
          </div>
        </div>
      </ion-col>
    </ion-row>
  </div>
  <div class="link-container">
    <div class="first-link">
      <equitable-link
        [text]="customProfile | translate"
        [iconName]="linkRightIcon"
        [type]="type"
        [orientation]="orientation"
        (click)="saveProfile(CUSTOMIZATION_TYPE.PROFILE)"
      ></equitable-link>
    </div>
    <div class="second-link">
      <equitable-link
        [text]="customizeReport | translate"
        [iconName]="linkRightIcon"
        [type]="type"
        [orientation]="orientation"
        (click)="saveProfile(CUSTOMIZATION_TYPE.REPORT)"
      ></equitable-link>
    </div>
  </div>
</div>
