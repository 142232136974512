/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./homepage-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../../../../node_modules/equitable-component-library/src/lib/button/button.component.ngfactory";
import * as i5 from "equitable-component-library/src/lib/button/button.component";
import * as i6 from "@ngx-translate/core";
import * as i7 from "./homepage-popup.component";
import * as i8 from "../../../scsincome/services/scsincome.service";
import * as i9 from "../../services/utility.service";
var styles_HomePagePopupComponent = [i0.styles];
var RenderType_HomePagePopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomePagePopupComponent, data: {} });
export { RenderType_HomePagePopupComponent as RenderType_HomePagePopupComponent };
export function View_HomePagePopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 49, "div", [["class", "modal-full bg-primary text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Looking for more ways to invest?"])), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "animate-contain"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We have you covered with "])), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "animation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tax-Smart"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Investment"])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Income"])), (_l()(), i1.ɵted(-1, null, [" strategies."])), (_l()(), i1.ɵeld(16, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Equitable's history of innovation continues with our Structured Capital Strategies"])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "sup", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00AE"])), (_l()(), i1.ɵted(-1, null, [" variable annuity product suite."])), (_l()(), i1.ɵeld(21, 0, null, null, 12, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["View the new "])), (_l()(), i1.ɵeld(23, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.visitPage(_co.route.SCS.TOOLS.HOW_SIO_WORKS);
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Structured Capital Strategies PLUS"])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "sup", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00AE"])), (_l()(), i1.ɵted(-1, null, [" and "])), (_l()(), i1.ɵeld(28, 0, null, null, 4, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.visitPage(_co.route.SCSINCOME.TOOLS.HOW_SIO_WORKS);
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Structured Capital Strategies"])), (_l()(), i1.ɵeld(30, 0, null, null, 1, "sup", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00AE"])), (_l()(), i1.ɵted(-1, null, [" Income"])), (_l()(), i1.ɵted(-1, null, [" line up today!"])), (_l()(), i1.ɵeld(34, 0, null, null, 8, "ion-row", [], null, null, null, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(35, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(36, 0, null, 0, 6, "ion-col", [["class", "center-align"]], null, null, null, i2.View_IonCol_0, i2.RenderType_IonCol)), i1.ɵdid(37, 49152, null, 0, i3.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(38, 0, null, 0, 4, "equitable-button", [["class", "btn-main"], ["theme", "dark"], ["type", "primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ButtonComponent_0, i4.RenderType_ButtonComponent)), i1.ɵdid(39, 638976, null, 0, i5.ButtonComponent, [], { type: [0, "type"], theme: [1, "theme"] }, null), (_l()(), i1.ɵeld(40, 0, null, 0, 2, "span", [["class", "btn-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(41, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(43, 0, null, null, 6, "p", [["class", "sm"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please see the Disclosures section of this application for important related information and disclosures about Structured Capital Strategies"])), (_l()(), i1.ɵeld(45, 0, null, null, 1, "sup", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00AE"])), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(48, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" GE-5453169.1 (02/2023) (Exp. 02/2025)"]))], function (_ck, _v) { var currVal_0 = "primary"; var currVal_1 = "dark"; _ck(_v, 39, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 41, 0, i1.ɵnov(_v, 42).transform("sioFrontPage.popOver.btnLabel")); _ck(_v, 41, 0, currVal_2); }); }
export function View_HomePagePopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "homepage-popup", [], null, null, null, View_HomePagePopupComponent_0, RenderType_HomePagePopupComponent)), i1.ɵdid(1, 114688, null, 0, i7.HomePagePopupComponent, [i8.ScsIncomeService, i3.ModalController, i6.TranslateService, i9.UtilityService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomePagePopupComponentNgFactory = i1.ɵccf("homepage-popup", i7.HomePagePopupComponent, View_HomePagePopupComponent_Host_0, {}, {}, []);
export { HomePagePopupComponentNgFactory as HomePagePopupComponentNgFactory };
