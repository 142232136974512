/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./questionnaire.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../questionnaire-one/questionnaire-one.component.ngfactory";
import * as i3 from "../questionnaire-one/questionnaire-one.component";
import * as i4 from "../../services/questionnaire.service";
import * as i5 from "../questionnaire-two/questionnaire-two.component.ngfactory";
import * as i6 from "../questionnaire-two/questionnaire-two.component";
import * as i7 from "../questionnaire-three/questionnaire-three.component.ngfactory";
import * as i8 from "../questionnaire-three/questionnaire-three.component";
import * as i9 from "@angular/common";
import * as i10 from "../questionnaire-result/questionnaire-result.component.ngfactory";
import * as i11 from "../questionnaire-result/questionnaire-result.component";
import * as i12 from "../../services/utility.service";
import * as i13 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i14 from "@ionic/angular";
import * as i15 from "../footer/footer.component.ngfactory";
import * as i16 from "../footer/footer.component";
import * as i17 from "@angular/router";
import * as i18 from "../../services/core.service";
import * as i19 from "./questionnaire.component";
var styles_QuestionnaireComponent = [i0.styles];
var RenderType_QuestionnaireComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuestionnaireComponent, data: {} });
export { RenderType_QuestionnaireComponent as RenderType_QuestionnaireComponent };
function View_QuestionnaireComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-questionnaire-one", [], null, [[null, "selectedOption"], [null, "backButtonClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedOption" === en)) {
        var pd_0 = (_co.optionSelected($event) !== false);
        ad = (pd_0 && ad);
    } if (("backButtonClicked" === en)) {
        var pd_1 = (_co.backButtonClicked($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_QuestionnaireOneComponent_0, i2.RenderType_QuestionnaireOneComponent)), i1.ɵdid(1, 114688, null, 0, i3.QuestionnaireOneComponent, [i4.QuestionnaireService], { step: [0, "step"] }, { selectedOption: "selectedOption", backButtonClicked: "backButtonClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.step; _ck(_v, 1, 0, currVal_0); }, null); }
function View_QuestionnaireComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-questionnaire-two", [], null, [[null, "selectedOption"], [null, "backButtonClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedOption" === en)) {
        var pd_0 = (_co.optionSelected($event) !== false);
        ad = (pd_0 && ad);
    } if (("backButtonClicked" === en)) {
        var pd_1 = (_co.backButtonClicked($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_QuestionnaireTwoComponent_0, i5.RenderType_QuestionnaireTwoComponent)), i1.ɵdid(1, 114688, null, 0, i6.QuestionnaireTwoComponent, [i4.QuestionnaireService], { step: [0, "step"] }, { selectedOption: "selectedOption", backButtonClicked: "backButtonClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.step; _ck(_v, 1, 0, currVal_0); }, null); }
function View_QuestionnaireComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-questionnaire-three", [], null, [[null, "selectedOption"], [null, "backButtonClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedOption" === en)) {
        var pd_0 = (_co.optionSelected($event) !== false);
        ad = (pd_0 && ad);
    } if (("backButtonClicked" === en)) {
        var pd_1 = (_co.backButtonClicked($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_QuestionnaireThreeComponent_0, i7.RenderType_QuestionnaireThreeComponent)), i1.ɵdid(1, 114688, null, 0, i8.QuestionnaireThreeComponent, [i4.QuestionnaireService], { step: [0, "step"] }, { selectedOption: "selectedOption", backButtonClicked: "backButtonClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.step; _ck(_v, 1, 0, currVal_0); }, null); }
function View_QuestionnaireComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionnaireComponent_2)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionnaireComponent_3)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionnaireComponent_4)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.step === 1); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.step === 2); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.step === 3); _ck(_v, 6, 0, currVal_2); }, null); }
function View_QuestionnaireComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-questionnaire-result", [], null, [[null, "retakeQuiz"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("retakeQuiz" === en)) {
        var pd_0 = (_co.retakeQuiz() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_QuestionnaireResultComponent_0, i10.RenderType_QuestionnaireResultComponent)), i1.ɵdid(1, 114688, null, 0, i11.QuestionnaireResultComponent, [i4.QuestionnaireService, i12.UtilityService], null, { retakeQuiz: "retakeQuiz" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_QuestionnaireComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "ion-content", [], null, null, null, i13.View_IonContent_0, i13.RenderType_IonContent)), i1.ɵdid(1, 49152, null, 0, i14.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionnaireComponent_1)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_QuestionnaireComponent_5)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "app-footer", [["isBlueFooterVisible", "true"], ["selectedProduct", "questionnaire"]], null, null, null, i15.View_FooterComponent_0, i15.RenderType_FooterComponent)), i1.ɵdid(8, 638976, null, 0, i16.FooterComponent, [i17.Router, i18.CoreService, i12.UtilityService, i14.Platform], { isBlueFooterVisible: [0, "isBlueFooterVisible"], selectedProduct: [1, "selectedProduct"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.quesions; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.result; _ck(_v, 6, 0, currVal_1); var currVal_2 = "true"; var currVal_3 = "questionnaire"; _ck(_v, 8, 0, currVal_2, currVal_3); }, null); }
export function View_QuestionnaireComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-questionnaire", [], null, null, null, View_QuestionnaireComponent_0, RenderType_QuestionnaireComponent)), i1.ɵdid(1, 114688, null, 0, i19.QuestionnaireComponent, [i4.QuestionnaireService, i12.UtilityService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QuestionnaireComponentNgFactory = i1.ɵccf("app-questionnaire", i19.QuestionnaireComponent, View_QuestionnaireComponent_Host_0, {}, {}, []);
export { QuestionnaireComponentNgFactory as QuestionnaireComponentNgFactory };
