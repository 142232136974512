import { OnInit } from "@angular/core";
import { FormGroup, FormControl, FormBuilder, } from "@angular/forms";
import { UtilityService } from "../../../core/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { ROUTE_PATH, CHARACTER_LIMIT, PRODUCT_TYPE, } from "../../../constants/constants";
import { SITE } from "src/assets/sites";
import { Profile } from "../../../core/models/app.models";
import { CoreService } from "../../../core/services/core.service";
import { RcService } from "../../services/rc.service";
import { TealiumUtagService } from '../../../tealium/utag.service';
var ReportRcComponent = /** @class */ (function () {
    /***
     *  constructor
     ***/
    function ReportRcComponent(formBuilder, utilityService, translate, coreService, rcService, tealium) {
        this.formBuilder = formBuilder;
        this.utilityService = utilityService;
        this.translate = translate;
        this.coreService = coreService;
        this.rcService = rcService;
        this.tealium = tealium;
        //Variables
        this.route = ROUTE_PATH.RC.TOOLS;
        this.resourceMaterials = [];
        this.maxlength = CHARACTER_LIMIT.INPUT;
    }
    /***
     *  Page Init
     ***/
    ReportRcComponent.prototype.ngOnInit = function () {
        //create form
        this.buildForm();
        //get page data
        this.getPageData();
    };
    /***
     *  Page Load/Init
     ***/
    ReportRcComponent.prototype.ionViewWillEnter = function () {
        //create form
        this.buildForm();
        //get page data
        this.getPageData();
    };
    /***
     *  Back button event. Go back to tool home page
     ***/
    ReportRcComponent.prototype.backButton = function () {
        this.utilityService.navigate(ROUTE_PATH.RC.TOOLS.HOME);
    };
    /***
     *  Create form
     ***/
    ReportRcComponent.prototype.buildForm = function () {
        var _this = this;
        this.formGroup = this.formBuilder.group({
            professionalName: new FormControl({ value: this.coreService.report.profName, disabled: false }),
            name: new FormControl(this.coreService.report.name),
            firmName: new FormControl(this.coreService.report.firmName),
            contactNumber: new FormControl(this.coreService.report.contactNumber),
        });
        //save data to service using form change value
        this.formGroup.valueChanges.subscribe(function (data) {
            _this.coreService.report.name = data.name;
            _this.coreService.report.firmName = data.firmName;
            _this.coreService.report.contactNumber = data.contactNumber;
            _this.coreService.report.profName = data.professionalName;
        });
    };
    /***
     *  get Data for resources
     ***/
    ReportRcComponent.prototype.getPageData = function () {
        var _this = this;
        //get resources for services
        this.rcService.fetchResources().subscribe(function (res) {
            _this.resourceMaterials = res["SalesKitFiles"];
            //add more form control dynamically to track the resources
            _this.resourceMaterials.forEach(function (element) {
                var control = new FormControl(element.description);
                control.setValue(false);
                _this.formGroup.addControl(element.description, control);
            });
        });
    };
    /***
     *  get Data for resources
     ***/
    ReportRcComponent.prototype.generatePDF = function () {
        var _this = this;
        if (this.formGroup.valid) {
            var utilizeData = new Profile();
            utilizeData.sourceURL = SITE.AXA;
            utilizeData.name = this.formGroup.get("name").value;
            utilizeData.profName = this.formGroup.get("professionalName").value;
            utilizeData.firmName = this.formGroup.get("firmName").value;
            utilizeData.contactNumber = this.formGroup.get("contactNumber").value;
            //add learning resources
            var obj_1 = {};
            this.resourceMaterials.forEach(function (element) {
                if (_this.formGroup.get(element.description).value === true) {
                    obj_1[element.description] = element.file;
                }
            });
            utilizeData.learningResourcesIncluded = obj_1;
            //generate PDF
            this.coreService.pdfFetchResources(utilizeData, PRODUCT_TYPE.RC);
        }
        else {
            this.utilityService.showValidationErrorForControls(this.formGroup);
        }
        // add tealium_event: "Generate_PDF" ?
        this.tealium.link({
            event_name: "Retirement Guide Clicks - RC",
            tool_name: "BodyClicks",
            event_label: "Create a report"
        });
    };
    return ReportRcComponent;
}());
export { ReportRcComponent };
