import {
  Component,
  OnInit,
  Renderer2,
  AfterContentChecked,
  AfterViewInit,
  DoCheck,
  AfterViewChecked,
} from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ScsIncomeService } from "../../services/scsincome.service";
import {
  Dictionary,
  Chart,
  Bar,
  Item,
  Axis,
  HistorySegment,
  IndexOptions,
  HistoricalPerformance,
  TopGraphic,
  BottomPanel,
  Profile,
  Preset,
} from "../../../core/models/app.models";
import {
  HISTORICAL_PERFORMANCE,
  ROUTE_PATH,
  CUSTOMIZATION_TYPE,
  CURRENCY,
  CUSTOM_PROFILE,
  LABEL_COLOR,
  VALIDATION_TYPE,
} from "../../../constants/constants";
import { distinctUntilChanged } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { isNumber } from "util";
import { UtilityService } from "src/app/core/services/utility.service";
import { Theme, LinkIcon, Orientation } from "equitable-component-library";
import { ModalPopupComponent } from "src/app/core/components/modal-popup/modal-popup.component";
import { CoreService } from "../../../core/services/core.service";
import { PercentPipe } from '@angular/common';

@Component({
  selector: "app-segment-performance",
  templateUrl: "./segment-performance.component.html",
  styleUrls: ["./segment-performance.component.scss"],
})
export class SegmentPerformanceComponent implements OnInit, AfterViewChecked {
  CUSTOMIZATION_TYPE = CUSTOMIZATION_TYPE;
  type = Theme.Dark;
  orientation = Orientation.Right;
  customProfile = "historicalPerformance.customProfile";
  customizeReport = "historicalPerformance.customizeReport";
  HISTORICAL_PERFORMANCE = HISTORICAL_PERFORMANCE;
  historicalForm: FormGroup;
  indexOptions: IndexOptions[];
  durationOptions: Dictionary[];
  protectionOptions: Dictionary[];
  series = this.scsincomeService.series;
  indexSelectionMaxCount = HISTORICAL_PERFORMANCE.INDEX_SELECTION_MAX_COUNT;
  presetValues;
  historicalPerformance;
  chartData: Chart;
  selectedIndicies: HistorySegment[];
  doughnutData: Array<number> = [];
  doughnutlegends: Array<string> = [];
  maturityValue = 0;
  indexValue = 0;
  investment = HISTORICAL_PERFORMANCE.INVESTMENT;
  mode = HISTORICAL_PERFORMANCE.MODE;
  customizationType;
  linkRightIcon = LinkIcon.RightIcon;
  isInitalLoadCompleted = false;
  selectedInput;

  /***
   *  Constructor
   ***/
  constructor(
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private scsincomeService: ScsIncomeService,
    private translateService: TranslateService,
    private utilityService: UtilityService,
    private coreService: CoreService
  ) { }

  /***
   *  Page Load
   ***/
  ngOnInit() {
    this.series = this.scsincomeService.series;

    //if any customization report, profile show buttons
    this.customizationType = this.scsincomeService.customizationType;

    //create form
    this.buildForm();

    //get historical data from service
    this.getHistoricalPerformance();
  }

  ngAfterViewChecked() {
    if (
      this.historicalForm.get("index").value &&
      this.historicalForm.get("duration").value &&
      this.historicalForm.get("protection").value
    )
      this.isInitalLoadCompleted = true;
  }

  /***
   *  Create Form and intialize the values
   ***/
  buildForm(): void {
    this.historicalForm = this.formBuilder.group({
      index: ["", Validators.required],
      duration: ["", Validators.required],
      protection: ["", Validators.required],
    });

    //Form on changes
    this.historicalForm.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((data) => {
        if (
          data.index &&
          data.index.length > 0 &&
          data.duration &&
          data.protection
        ) {
          //build
          if (
            this.historicalPerformance &&
            this.historicalPerformance.length > 0
          ) {
            this.protectionOptions = Array.from(
              new Set(
                this.historicalPerformance
                  .find((x) => x.id === data.duration)
                  .historicalPerformance.map((x) => x.id)
              )
            ).map((x: string) => ({
              id: x,
              value: x.replace("Year", "Year, -"),
            }));
          }

          //update data based on user selection
          const arr = [];
          data.index.forEach((element, index) => {
            const obj = new HistorySegment();
            obj.id = element.key;
            obj.name = element.value;
            obj.color = LABEL_COLOR[index];

            obj.percentage =
              this.mode === HISTORICAL_PERFORMANCE.MODE &&
                element.percentage != 0
                ? Math.floor(element.percentage)
                : Math.floor(
                  HISTORICAL_PERFORMANCE.PERCENTAGE_MAX /
                  data.index.length /
                  HISTORICAL_PERFORMANCE.PERCENTAGE_MAX
                ); //check if preset data is available for inital load
            obj.value =
              this.mode === HISTORICAL_PERFORMANCE.MODE &&
                element.percentage != 0
                ? Math.floor(
                  HISTORICAL_PERFORMANCE.PERCENTAGE_MAX * element.percentage
                )
                : parseFloat(
                  Math.floor(
                    HISTORICAL_PERFORMANCE.PERCENTAGE_MAX / data.index.length
                  ).toFixed(0)
                ); //check if preset data is available for inital load
            obj.disabled = false;
            arr.push(obj);
          });
          //updated selection
          const sumofSlider = arr.reduce((a, b) => {
            return a + b.value;
          }, 0);
          if (HISTORICAL_PERFORMANCE.PERCENTAGE_MAX - sumofSlider !== 0) {
            arr[0].value =
              arr[0].value +
              (HISTORICAL_PERFORMANCE.PERCENTAGE_MAX - sumofSlider);
          }
          this.selectedIndicies = arr;
          //update dognut value
          if (this.selectedIndicies.length > 0) {
            this.doughnutlegends = this.selectedIndicies.map((x) => x.name);
            this.doughnutData = this.selectedIndicies.map((x) => x.value);
            this.mode = "";
            //prepare initial chart
            this.prepareChart();
            //update hypothetical return value
            this.updateHypotheticalValues();
            //save the profile
            this.saveProfile();
          }
        } else {
          this.selectedIndicies = [];
          this.saveProfile();
        }
      });
  }

  /***
   *  Get historical data, preset data, master data from backend and updated initial chart
   ***/
  getHistoricalPerformance(): void {
    this.utilityService.showLoader();
    console.log("SEG_PERF_COMPONENT in getHistoricalData series=", this.series);
    this.scsincomeService.getHistoricalPerformance(this.series).subscribe((data) => {
      console.log("SEG_PERF_COMPONENT in getHistoricalData", data);
      this.historicalPerformance = data[0]; //performance Value
      console.log("SEG_PERF_COMPONENT after data0")
      this.presetValues = data[1]; //preset Value
      const indicesData = data[2];
      const savedProfiles = data[3];
    console.log("SEG_PERF_COMPONENT data", data)
      let preset: Preset = this.presetValues.presetData[0]; //set preset data from server

      //check if any custom profile currently opened
      if (
        savedProfiles &&
        savedProfiles.length > 0 &&
        ((this.scsincomeService.customizationType == CUSTOMIZATION_TYPE.PROFILE &&
          this.scsincomeService.currentProfile) ||
          this.scsincomeService.customizationType == CUSTOMIZATION_TYPE.REPORT)
      ) {
        const profile = savedProfiles.find(
          (x) =>
            x.name ===
            (this.scsincomeService.customizationType == CUSTOMIZATION_TYPE.PROFILE
              ? this.scsincomeService.currentProfile
              : CUSTOMIZATION_TYPE.REPORT) &&
            x.currentSerie === this.scsincomeService.series
        );
        if (profile && profile.historicalPerformance) {
          preset = this.loadSavedProfileData(profile);
        }
      } else if (
        this.scsincomeService.customizationType == CUSTOMIZATION_TYPE.INVESTOR_PROFILE
      ) {
        //set preset
        const savedPreset = this.presetValues.presetData.find(
          (x) => x.option == this.scsincomeService.investorData.preset
        );
        if (savedPreset) {
          preset = savedPreset;
        }
      }
      // else if (this.scsincomeService.report.historicalPerformance) {
      //   preset = this.loadSavedProfileData(this.scsincomeService.report);
      // }

      //if preset data is present use that
      this.indexOptions = indicesData.map((x) => ({
        key: x.id,
        value: x.value,
        checked:
          preset && preset.indices.findIndex((y) => y.name === x.id) != -1
            ? true
            : false,
        disabled: false,
        percentage:
          preset && preset.indices.find((y) => y.name === x.id)
            ? preset.indices.find((y) => y.name === x.id).percentage
            : 0,
      }));

      this.investment = parseFloat(preset.initialInvestment.replace(/,/g, ""));

      //update doughnet chart
      this.selectedIndicies = preset.indices.map(
        (x) =>
          ({
            id: x.id,
            name: x.name,
            percentage: x.percentage,
            disabled: false,
            value: HISTORICAL_PERFORMANCE.PERCENTAGE_MAX * x.percentage,
          } as HistorySegment)
      );

      this.historicalForm
        .get("index")
        .setValue(this.indexOptions.filter((x) => x.checked === true));

      //update dognut value
      this.doughnutlegends = this.selectedIndicies.map((x) => x.name);
      this.doughnutData = this.selectedIndicies.map((x) => x.value);

      //build dropdown using historical data
      this.buildDropDownData(preset);
      this.updateHypotheticalValues();
      this.utilityService.hideLoader();
    });
  }

  loadSavedProfileData(profile) {
    const savedPreset = new Preset();
    if (profile && profile.historicalPerformance) {
      savedPreset.id = 1;
      savedPreset.maturityDate =
        profile.historicalPerformance.topGraphic.maturityDate;
      savedPreset.series = profile.currentSerie;
      savedPreset.duration =
        profile.historicalPerformance.topGraphic.maturityDate;
      savedPreset.buffer = profile.historicalPerformance.topGraphic.protection;
      savedPreset.initialInvestment = profile.historicalPerformance.bottomPanel.investment.toString();
      Object.keys(profile.historicalPerformance.bottomPanel.indices).forEach(
        (element, index) => {
          const obj = {
            id: index,
            name:
              profile.historicalPerformance.bottomPanel.indices[element][
              "name"
              ],
            percentage:
              profile.historicalPerformance.bottomPanel.indices[element][
              "percent"
              ] / 100,
          };
          savedPreset.indices.push(obj);
        }
      );
    }
    return savedPreset;
  }

  /***
   *  Create Drop down for bar/pie chart
   ***/
  buildDropDownData(preset): void {
    console.log("HISPERF preset", preset)
    console.log("HISPERF this.historicalPerformance", this.historicalPerformance)
    this.durationOptions = Array.from(
      new Set(this.historicalPerformance.map((x) => x.title))
    ).map((x: string) => ({ id: x, value: x }));

    this.historicalForm.get("duration").setValue(preset.duration);

    this.protectionOptions = Array.from(
      new Set(
        this.historicalPerformance[0].historicalPerformance.map((x) => x.id)
      )
    ).map((x: string) => ({ id: x, value: x.replace("Year", "Year, -") }));

    //not to use preset default value from site. it may not updated. Let use first value
    //this.historicalForm.get("duration").setValue(preset.duration);

    this.historicalForm.get("protection").setValue(preset.buffer);
  }

  /***
   *  User change the investment value
   ***/
  onInvestmentChange(investment) {
    this.investment = investment;
    //update hypothetical data
    this.updateHypotheticalValues();
  }

  /***
   *  update hypothetical details based on index and performances
   ***/
  updateHypotheticalValues() {
    let maturityValue = 0,
      indexValue = 0;
    for (let i = 0; i < this.selectedIndicies.length; i++) {
      const segmMaturityValue =
        this.investment *
        (this.selectedIndicies[i].value /
          HISTORICAL_PERFORMANCE.PERCENTAGE_MAX) *
        (1 +
          this.selectedIndicies[i].rateOfReturn /
          HISTORICAL_PERFORMANCE.PERCENTAGE_MAX);
      const hypoteticalValue =
        this.investment *
        (this.selectedIndicies[i].value /
          HISTORICAL_PERFORMANCE.PERCENTAGE_MAX) *
        (1 +
          this.selectedIndicies[i].performanceRate /
          HISTORICAL_PERFORMANCE.PERCENTAGE_MAX);
      maturityValue += segmMaturityValue;
      indexValue += hypoteticalValue;
    }
    this.maturityValue = maturityValue;
    this.indexValue = indexValue;

    this.saveProfile();
  }

  onInputChange(event, option: HistorySegment) {
    const history = this.selectedIndicies.find((x) => x.name === option.name);
    history.value = event.target.value.replace("%", "");
    this.selectedInput = option.name;
  }

  /***
   *  on Change event for sliders
   ***/
  onChange(event, option: HistorySegment) {
    if (this.selectedInput && option.name !== this.selectedInput) {
      return;
    }

    const newValue = Math.min(
      HISTORICAL_PERFORMANCE.PERCENTAGE_MAX,
      Math.max(0, Math.round(event.detail.value))
    );

    //change otherslider value based on selection
    if (this.changeIndexPercent(option, newValue, event) === false) {
      return false;
    }

    //update doughnat data
    this.doughnutData = this.selectedIndicies.map((x) => x.value);

    //update hypothetical return value
    this.updateHypotheticalValues();
  }

  /***
   *  on Change event for sliders
   ***/
  changeIndexPercent(option: HistorySegment, newValue, event) {
    let minValue = 0;
    const lockedSliders = this.selectedIndicies.filter(
      (x) => x.disabled === true
    );
    if (this.selectedIndicies.filter((x) => x.disabled != true).length < 2)
      minValue = HISTORICAL_PERFORMANCE.PERCENTAGE_MAX;

    const sumOfLockedSlider = lockedSliders.reduce((a, b) => {
      return a + b.value;
    }, 0);

    if (
      newValue >= minValue &&
      newValue <= HISTORICAL_PERFORMANCE.PERCENTAGE_MAX - sumOfLockedSlider
    ) {
      //get  slides to change vaue - current slider
      const activeSliders = this.selectedIndicies.filter(
        (x) => x.disabled != true && x.name != option.name
      );

      //get value to reduce
      const reductionValue =
        HISTORICAL_PERFORMANCE.PERCENTAGE_MAX - sumOfLockedSlider - newValue;

      //get delta value current sum - last sum
      const delta =
        reductionValue -
        activeSliders.reduce((x, y) => {
          return x + y.value;
        }, 0);

      const inc = Math.abs(delta) > 0 ? delta / activeSliders.length : 0;

      let sum = newValue + sumOfLockedSlider;
      activeSliders.forEach((element) => {
        if (newValue == 100) {
          element.value = 0;
        } else {
          const nextVal = Math.ceil(element.value + inc);
          if (nextVal > 100) {
            element.value = 100;
          } else if (nextVal < 0) {
            element.value = 0;
          } else {
            element.value = Math.ceil(element.value + inc);
          }
        }

        sum = sum + element.value;
        this.doughnutData[element.id] = element.value;
      });

      if (sum > HISTORICAL_PERFORMANCE.SLIDER_MAX) {
        //newValue = newValue - (sum - HISTORICAL_PERFORMANCE.SLIDER_MAX);
        const active = activeSliders.find((x) => x.value > 0);
        active.value = active.value - (sum - HISTORICAL_PERFORMANCE.SLIDER_MAX);

        if (active.value < HISTORICAL_PERFORMANCE.SLIDER_MIN) {
          active.value = HISTORICAL_PERFORMANCE.SLIDER_MIN;
        }
      }
      option.value = newValue;
    } else {
      option.value = HISTORICAL_PERFORMANCE.PERCENTAGE_MAX - sumOfLockedSlider;
      event.stopImmediatePropagation();
      event.stopPropagation();

      return false;
    }
  }

  /***
   *  Lock/upload slider/selected index
   ***/
  toggleLock(event, option: HistorySegment) {
    const hasClass = event.target.classList.contains("on");
    if (!hasClass) {
      if (
        this.selectedIndicies.filter((x) => x.disabled === false).length <= 2
      ) {
        return;
      }
      this.renderer.addClass(event.target, "on");
      option.disabled = true;
    } else {
      this.renderer.removeClass(event.target, "on");
      option.disabled = false;
    }
  }

  /***
   *  prepare chart based on selected scenerio
   ***/
  prepareChart(): void {
    if (
      this.historicalForm.valid &&
      this.historicalPerformance &&
      this.selectedIndicies
    ) {
      let data = this.historicalPerformance
        .find((x) => x.id === this.historicalForm.get("duration").value)
        .historicalPerformance.find(
          (x) => x.id === this.historicalForm.get("protection").value
        );

      if (!data) {
        data = this.historicalPerformance.find(
          (x) => x.id === this.historicalForm.get("duration").value
        ).historicalPerformance[0];
        this.historicalForm.get("protection").setValue(data.id);
      }

      const chart: Chart = new Chart();
      chart.bar = Array<Bar>();
      let max = -9999; //just abb
      let min = +9999; //just abb

      this.selectedIndicies.forEach((element) => {
        const objBar = new Bar();
        objBar.id = element.id;
        objBar.label = element.name;

        //for peformace cap rate & Buffer lines for each section
        objBar.lines = new Array<Item>();
        objBar.data = new Array<Item>();

        const indexItem = data.indices.find((x) => x.name === element.id);
        if (indexItem) {
          //update performace data for pie chart
          element.performanceRate = indexItem.indexReturn;
          element.rateOfReturn = indexItem.segmentRateValue;

          objBar.data.push({
            value: parseFloat(indexItem.segmentRateValue).toFixed(2),
            label: this.translateService.instant(
              "howItsWorks.segmentYearlyReturn"
            ),
            topValue: 0,
          });

          objBar.data.push({
            value: parseFloat(indexItem.indexReturn).toFixed(2),
            label: this.translateService.instant(
              "howItsWorks.indexPerformanceRate"
            ),
            topValue: 0,
          });

          objBar.lines.push({
            label: this.translateService.instant(
              "howItsWorks.performaceCapRate"
            ),
            value: parseFloat(indexItem.historicalCapRate).toFixed(1),
          });

          objBar.lines.push({
            label: this.translateService.instant("howItsWorks.segmentBuffer"),
            value: parseFloat(data.buffer),
          });
          min = Math.min(
            Math.min(
              parseFloat(indexItem.segmentRateValue),
              parseFloat(indexItem.indexReturn)
            ),
            min
          );
          max = Math.max(
            Math.max(
              parseFloat(indexItem.segmentRateValue),
              parseFloat(indexItem.indexReturn)
            ),
            max
          );
          if (isNumber(parseFloat(indexItem.historicalCapRate))) {
            max = Math.max(max, parseFloat(indexItem.historicalCapRate) + 5);
          }
        } else {
          //disable element for slider
          element.disabled = true;
          element.percentage = 0;
          element.value = 0;

          objBar.data.push({
            value: 0,
            label: this.translateService.instant(
              "howItsWorks.segmentYearlyReturn"
            ),
            topValue: 0,
          });

          objBar.data.push({
            value: 0,
            label: this.translateService.instant(
              "howItsWorks.indexPerformanceRate"
            ),
            topValue: 0,
          });

          objBar.lines.push({
            label: this.translateService.instant(
              "howItsWorks.performaceCapRate"
            ),
            value: 0,
          });

          objBar.lines.push({
            label: this.translateService.instant("howItsWorks.segmentBuffer"),
            value: 0,
          });
        }
        chart.bar.push(objBar);
      });

      //redistribute value if items are disabled
      if (this.selectedIndicies.filter((x) => x.disabled === true).length > 0) {
        const activeItems = this.selectedIndicies.filter(
          (x) => x.disabled === false
        );
        if (activeItems.length > 0) {
          activeItems.forEach((element) => {
            element.percentage = Math.floor(
              HISTORICAL_PERFORMANCE.PERCENTAGE_MAX /
              activeItems.length /
              HISTORICAL_PERFORMANCE.PERCENTAGE_MAX
            ); //check if preset data is available for inital load
            element.value = parseFloat(
              Math.floor(
                HISTORICAL_PERFORMANCE.PERCENTAGE_MAX / activeItems.length
              ).toFixed(1)
            ); //check if preset data is available for inital load
          });

          const sumofSlider = activeItems.reduce((a, b) => {
            return a + b.value;
          }, 0);
          if (HISTORICAL_PERFORMANCE.PERCENTAGE_MAX - sumofSlider !== 0) {
            activeItems[0].value =
              activeItems[0].value +
              (HISTORICAL_PERFORMANCE.PERCENTAGE_MAX - sumofSlider);
          }

          //update dognut value
          this.doughnutlegends = activeItems.map((x) => x.name);
          this.doughnutData = activeItems.map((x) => x.value);
        }
      }

      if (!isNaN(data)) {
        min = Math.min(data.buffer - 5, min);
      }
      chart.axis = new Array<Axis>();

      chart.axis.push({
        max: Math.ceil(max / 10) * 10,
        min: Math.min(Math.floor(min / 10) * 10, 0),
        interval: 10,
        type: "Y",
      });

      chart.showDescription = false;
      chart.showLineToolTip = true;

      this.chartData = chart;
      //save profile session
      this.saveProfile();
    }
  }

  /***
   *  prepare chart based on selected scenerio
   ***/
  saveProfile(mode?) {
    let historicalPerformance;
    if (this.selectedIndicies && this.selectedIndicies.length > 0) {
      historicalPerformance = new HistoricalPerformance();
      //add bottom panel data
      historicalPerformance.bottomPanel = new BottomPanel();
      historicalPerformance.bottomPanel.investment = this.investment;
      historicalPerformance.bottomPanel.maturityValue =
        CURRENCY.USD_SYMBOL + this.maturityValue.toLocaleString();
      historicalPerformance.bottomPanel.indexValue =
        CURRENCY.USD_SYMBOL + this.indexValue.toLocaleString();

      //add to bar data
      historicalPerformance.topGraphic = new TopGraphic();
      historicalPerformance.topGraphic.scaleMin = this.chartData.axis[0].min;
      historicalPerformance.topGraphic.scaleMax = this.chartData.axis[0].max;
      historicalPerformance.topGraphic.scaleStep = this.chartData.axis[0].interval;
      historicalPerformance.topGraphic.maturityDate = this.historicalForm.get(
        "duration"
      ).value;
      historicalPerformance.topGraphic.maturityDateKey = this.historicalForm.get(
        "duration"
      ).value;
      historicalPerformance.topGraphic.protection = this.historicalForm.get(
        "protection"
      ).value;
      historicalPerformance.topGraphic.protectionKey = this.historicalForm.get(
        "protection"
      ).value;

      //multple bars to add
      this.chartData.bar.forEach((element, index) => {
        historicalPerformance.topGraphic.indices[index] = {
          name: element.id,
          label: element.label,
          rateOfReturn: element.data[0].value,
          performanceRate: element.data[1].value,
          historicalCapRate: element.lines[0].value,
          disabled: false,
        };
      });

      this.selectedIndicies.forEach((element, index) => {
        const segmMaturityValue =
          this.investment *
          (this.selectedIndicies[index].value /
            HISTORICAL_PERFORMANCE.PERCENTAGE_MAX) *
          (1 +
            this.selectedIndicies[index].rateOfReturn /
            HISTORICAL_PERFORMANCE.PERCENTAGE_MAX);
        const hypoteticalValue =
          this.investment *
          (this.selectedIndicies[index].value /
            HISTORICAL_PERFORMANCE.PERCENTAGE_MAX) *
          (1 +
            this.selectedIndicies[index].performanceRate /
            HISTORICAL_PERFORMANCE.PERCENTAGE_MAX);

        historicalPerformance.bottomPanel.indices[index] = {
          name: element.id,
          label: element.name,
          disabled: element.disabled,
          percent: element.value,
          maturityValue: segmMaturityValue,
          indexValue: hypoteticalValue,
          investment: Math.round(this.investment * (element.value / 100)),
        };
      });

      if (
        this.isInitalLoadCompleted &&
        this.scsincomeService.customizationType ===
        CUSTOMIZATION_TYPE.INVESTOR_PROFILE
      ) {
        this.coreService.profile.next({
          name: "",
          type: CUSTOMIZATION_TYPE.REPORT,
          profile: new Profile(),
        });
        this.scsincomeService.customizationType = CUSTOMIZATION_TYPE.REPORT;
      }
    }
    //set in session

    //save for profile
    if (this.scsincomeService.customizationType === CUSTOMIZATION_TYPE.PROFILE) {
      this.scsincomeService.storeProfile(
        CUSTOM_PROFILE.HISTORICAL_PERFORMANCE,
        historicalPerformance
      );
      // //save for report
      // this.scsincomeService.report.historicalPerformance = historicalPerformance;
      // this.scsincomeService.storeProfile(
      //   CUSTOM_PROFILE.HISTORICAL_PERFORMANCE,
      //   historicalPerformance,
      //   true
      // );
    } else {
      //save for report
      this.scsincomeService.report.historicalPerformance = historicalPerformance;
      this.scsincomeService.storeProfile(
        CUSTOM_PROFILE.HISTORICAL_PERFORMANCE,
        historicalPerformance,
        true
      );
    }

    if (mode && mode == CUSTOMIZATION_TYPE.PROFILE) {
      this.utilityService.presentWithoutTitleModal(ModalPopupComponent);
    } else if (mode && mode == CUSTOMIZATION_TYPE.REPORT) {
      this.utilityService.navigate(ROUTE_PATH.SCSINCOME.TOOLS.CREATE_REPORT);
    }
  }
  //clear input
  clearInput() {
    this.investment = undefined;
  }
  //validate investment
  validateInvestment(event) {
    if (
      event.which != 8 &&
      event.which != 9 &&
      event.which != 13 &&
      isNaN(parseInt(String.fromCharCode(event.which)))
    ) {
      event.preventDefault();
    }
  }
}
