import { QuestionnaireService } from "../../services/questionnaire.service";
import { OnInit, EventEmitter } from "@angular/core";
import { Theme, Orientation, Icon } from "equitable-component-library";
var QuestionnaireTwoComponent = /** @class */ (function () {
    function QuestionnaireTwoComponent(questionnaireService) {
        this.questionnaireService = questionnaireService;
        //Outputs
        this.selectedOption = new EventEmitter();
        this.backButtonClicked = new EventEmitter();
        //Class Variables
        this.equitableStrips = [];
        this.type = Theme.Dark;
        this.backIcon = Icon.BackButton;
        this.orientation = Orientation.Left;
        this.selectedStrips = [];
        this.stripPriorities = [];
        this.disableContinue = true;
    }
    QuestionnaireTwoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.questionnaireService.getQuestionnairePageData(2).subscribe(function (data) {
            _this.equitableStrips = data;
            _this.initStripPriority();
        });
    };
    QuestionnaireTwoComponent.prototype.selectStrip = function (index) {
        if (this.selectedStrips.length < 3 && this.stripPriorities[index] == 0) {
            this.selectedStrips.push(index);
            this.stripPriorities[index] = this.selectedStrips.length;
        }
        if (this.selectedStrips.length >= 1)
            this.disableContinue = false;
    };
    QuestionnaireTwoComponent.prototype.submitSelections = function () {
        this.selectedOption.emit({
            step: this.step,
            index: this.selectedStrips[0],
        });
    };
    QuestionnaireTwoComponent.prototype.clearSelections = function () {
        this.selectedStrips = [];
        this.initStripPriority();
    };
    QuestionnaireTwoComponent.prototype.initStripPriority = function () {
        for (var i = 0; i < this.equitableStrips.length; i++)
            this.stripPriorities[i] = 0;
        this.disableContinue = true;
    };
    QuestionnaireTwoComponent.prototype.backButton = function () {
        this.backButtonClicked.emit(this.step);
    };
    return QuestionnaireTwoComponent;
}());
export { QuestionnaireTwoComponent };
