<!-- Mobile design back button -->
<div class="mobile-back">
  <equitable-link
    text="Back"
    iconName="arrow-back.svg"
    [type]="type"
    [orientation]="orientation"
    (click)="backButton()"
  ></equitable-link>
</div>
<!-- Web designback button -->
<div class="back-link">
  <equitable-link
    text=" {{ 'questionnaire.back-btn' | translate }} "
    [iconName]="backIcon"
    [type]="type"
    [orientation]="orientation"
    (click)="backButton()"
  ></equitable-link>
</div>
<div class="stepper">
  {{ "questionnaire.questionaire2-stepper" | translate }}
</div>
<h2 class="questionnaire-heading">
  {{ "questionnaire.questionaire2-title" | translate }}
</h2>
<h3 class="questionnaire-sub-heading">
  {{ "questionnaire.questionaire2-subtitle" | translate }}
</h3>
<section class="strip-container">
  <div class="clear-button">
    <span (click)="clearSelections()">{{
      "questionnaire.clear-btn" | translate
    }}</span>
  </div>
  <equitable-strip
    *ngFor="let strip of equitableStrips; let idx = index"
    [iconName]="strip.stripIcon"
    [text]="strip.stripText"
    [priority]="stripPriorities[idx]"
    (click)="selectStrip(idx)"
  ></equitable-strip>
</section>
<div class="continue-button">
  <equitable-button
    type="primary"
    theme="light"
    (click)="submitSelections()"
    [disabled]="disableContinue"
    >{{ "questionnaire.continue-btn" | translate }}</equitable-button
  >
</div>
