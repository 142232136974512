<div class="investment-container">
  <div class="feature-section">
    <div class="content-container">
      <div
        class="title-heading"
        [innerHTML]="'investmentOptions.titleHeading' | translate"
      ></div>
      <div class="feature-content">
        {{ "investmentOptions.headingDescription" | translate }}
      </div>
      <div class="short-description">
        {{ "investmentOptions.shortDescription" | translate }}
      </div>
    </div>
    <div class="portfolio-section">
      <h5>{{ "investmentOptions.portfolioHeading" | translate }}</h5>
      <hr />
      <div class="portfolio-container">
        <ion-item
          id="cursor"
          lines="none"
          *ngFor="let item of portfolios"
          (click)="onClickDownload(item.pdf)"
        >
          <span class="portfolio-title">{{ item.name }}</span>
        </ion-item>
      </div>
    </div>
  </div>
  <div class="chart-section">
    <div class="chart-container">
      <div class="doughnout-container">
        <equitable-doughnut-chart [data]="chartData" [type]="ChartType">
        </equitable-doughnut-chart>
      </div>
      <div class="side-legend-container">
        <div class="side-legend-dec">
          <label class="side-legend-text secondary-color"
            >{{ "investmentOptions.equities" | translate }}:</label
          >
          <div class="label-value-container">
            <label class="side-legend-value secondary-color">{{
              chartData[1]
            }}</label>
            <label class="percent secondary-color">{{
              "investmentOptions.percent" | translate
            }}</label>
          </div>
        </div>
        <div class="side-legend-dec">
          <label class="side-legend-text primary-color"
            >{{ "investmentOptions.fixedIncome" | translate }}:</label
          >
          <div class="label-value-container">
            <label class="side-legend-value primary-color">{{
              chartData[0]
            }}</label>
            <label class="percent primary-color">{{
              "investmentOptions.percent" | translate
            }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="slider-container">
      <equitable-slider
        min="0"
        max="7"
        [type]="sliderType"
        [value]="value"
        (slide)="onChange($event)"
      ></equitable-slider>
      <ion-item lines="none" class="margin-top-16 with-bg">
        <ion-label class="slider-text" slot="start">{{
          "investmentOptions.slider.conservative" | translate
        }}</ion-label>
        <ion-label class="slider-text display-content" slot="end">{{
          "investmentOptions.slider.aggressive" | translate
        }}</ion-label>
      </ion-item>
      <ion-item lines="none" class="with-bg">
        <ion-label class="slider-text ion-text-center">{{
          "investmentOptions.slider.bottomText" | translate
        }}</ion-label>
      </ion-item>
    </div>
  </div>
</div>
