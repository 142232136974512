<!-- Mobile design back button -->
<div class="mobile-back">
  <equitable-link
    text="Back"
    iconName="arrow-back.svg"
    [type]="type"
    [orientation]="orientation"
    (click)="backButton()"
  ></equitable-link>
</div>
<!-- Web design back button -->
<div class="back-link">
  <equitable-link
    text=" {{ 'questionnaire.back-btn' | translate }} "
    [iconName]="backIcon"
    [type]="type"
    [orientation]="orientation"
    (click)="backButton()"
  ></equitable-link>
</div>
<div class="stepper">
  {{ 'questionnaire.questionaire3-stepper' | translate }}
</div>
<div class="title">{{ 'questionnaire.questionaire3-title' | translate }}</div>
<p class="brief">
  {{ 'questionnaire.questionaire3-subtitle1' | translate }}
</p>
<p class="brief2">
  {{ 'questionnaire.questionaire3-subtitle2' | translate }}
</p>
<ion-card-content class="card-container">
  <div class="cards" *ngFor="let card of cardMainconfg; let idx = index">
    <equitable-investor-type
      [iconName]="cardMainconfg[idx].cardMainIcon"
      [title]="cardMainconfg[idx].cardMainTitle"
      [description]="cardMainconfg[idx].cardMainContent"
      (click)="selectedCard(idx)"
    ></equitable-investor-type>
  </div>
</ion-card-content>
