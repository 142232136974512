import { OnInit } from "@angular/core";
import { Theme, Orientation, Icon, EquitableUtilities, } from "equitable-component-library";
import { UtilityService } from "../../../core/services/utility.service";
import { PROTECT_INCOME_TOOLS } from "./../../../constants/constants";
var ProtectIncomeComponent = /** @class */ (function () {
    function ProtectIncomeComponent(utilityService, equitableUtilities) {
        this.utilityService = utilityService;
        this.equitableUtilities = equitableUtilities;
        // class variables
        this.type = Theme.Dark;
        this.orientation = Orientation.Left;
        this.backIcon = Icon.BackButton;
        this.IO = PROTECT_INCOME_TOOLS.IO;
        this.GIL = PROTECT_INCOME_TOOLS.GIL;
        this.RIP = PROTECT_INCOME_TOOLS.RIP;
        this.RB = PROTECT_INCOME_TOOLS.RB;
        this.activeTab = PROTECT_INCOME_TOOLS.IO;
        this.segments = [
            PROTECT_INCOME_TOOLS.IO,
            PROTECT_INCOME_TOOLS.GIL,
            PROTECT_INCOME_TOOLS.RIP,
            PROTECT_INCOME_TOOLS.RB,
        ];
    }
    ProtectIncomeComponent.prototype.ngOnInit = function () { };
    ProtectIncomeComponent.prototype.backButton = function () {
        this.utilityService.navigateToBack();
    };
    ProtectIncomeComponent.prototype.segmentChanged = function (event) {
        this.activeTab = event;
    };
    return ProtectIncomeComponent;
}());
export { ProtectIncomeComponent };
