<ion-grid fixed="true" class="wrapper">
  <ion-row>
    <ion-col class="intro" size-xl="5.5" size="15">
      <ion-row>
        <ion-col class="ion-no-padding" size-xl="15" size-md="7" size="15">
          <div class="heading">
            {{ 'ripData.heading' | translate }}
          </div>
          <div class="description">
            {{ 'ripData.descriptions.0' | translate }}
          </div>
        </ion-col>
        <ion-col class="ion-no-padding" size-xl="15" size-md="7" size="15">
          <div class="subheading">
            {{ 'ripData.subHeading' | translate }}
          </div>
          <div class="description">
            {{ 'ripData.descriptions.1' | translate }}
          </div>
        </ion-col>
        <ion-col class="ion-no-padding" size="15">
          <hr />
          <div class="heading">
            {{ 'ripData.mainRadioHeading' | translate }}
          </div>
          <form [formGroup]="formGroup" class="main-radio">
            <equitable-radio-button
              name="marketScenario"
              [items]="marketScenarioOptions"
              orientation="horizontal"
              formControlName="marketScenarioRadio"
              [formControl]="formGroup.get('marketScenarioRadio')"
            >
            </equitable-radio-button>
          </form>
        </ion-col>
      </ion-row>
    </ion-col>
    <ion-col class="middle-column" size-xl="4.5" size-md="7.5" size="15">
      <ng-container *ngIf="subOptions[activeScenario]">
        <div class="heading subradio">
          {{ subOptions[activeScenario]['subRadioHeading'] }}
        </div>
        <div
          *ngFor="
            let item of subOptions[activeScenario]['optionValues'];
            index as i
          "
          class="button-wrapper"
        >
          <equitable-button
            class="button-options"
            type="tertiary"
            [theme]="activeSubOption === 'option' + i ? 'dark' : 'light'"
            (click)="playAnimation('option' + i)"
          >
            {{ item }}
          </equitable-button>
        </div>
        <h4
          *ngIf="
            activeScenario === 'marketBased' &&
            (activeSubOption === 'option0' || activeSubOption === 'option1')
          "
        >
          {{ 'ripData.marketBased.' + activeSubOption + 'Title' | translate }}
        </h4>
        <div
          *ngFor="
            let item of subOptions[activeScenario][activeSubOption + 'Text']
          "
          class="description"
        >
          {{ item }}
        </div>
      </ng-container>
    </ion-col>
    <ion-col class="chart ion-no-padding" size-xl="5" size-md="7.5" size="15">
      <div #chart></div>
    </ion-col>
  </ion-row>
</ion-grid>
