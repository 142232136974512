//for models and interfaces
var Alert = /** @class */ (function () {
    function Alert() {
        this.buttons = new Array();
    }
    return Alert;
}());
export { Alert };
var Button = /** @class */ (function () {
    function Button() {
    }
    return Button;
}());
export { Button };
var Hypothetical = /** @class */ (function () {
    function Hypothetical() {
    }
    return Hypothetical;
}());
export { Hypothetical };
var Profile = /** @class */ (function () {
    function Profile() {
        this.name = "";
        this.forName = "";
        this.profName = "";
        this.firmName = "";
        this.contactNumber = "";
        this.date = new Date().toLocaleDateString();
    }
    return Profile;
}());
export { Profile };
var SCSHowSioWorks = /** @class */ (function () {
    function SCSHowSioWorks() {
        this.bars = {};
        this.commissions = {};
        this.scenarios = {};
    }
    return SCSHowSioWorks;
}());
export { SCSHowSioWorks };
var IEHowSioWorks = /** @class */ (function () {
    function IEHowSioWorks() {
        this.bars = {};
        this.commissions = {};
        this.scenarios = {};
    }
    return IEHowSioWorks;
}());
export { IEHowSioWorks };
var SCSIncomeHowSioWorks = /** @class */ (function () {
    function SCSIncomeHowSioWorks() {
        this.bars = {};
        this.commissions = {};
        this.scenarios = {};
    }
    return SCSIncomeHowSioWorks;
}());
export { SCSIncomeHowSioWorks };
var HistoricalPerformance = /** @class */ (function () {
    function HistoricalPerformance() {
    }
    return HistoricalPerformance;
}());
export { HistoricalPerformance };
var TopGraphic = /** @class */ (function () {
    function TopGraphic() {
        this.indices = {};
    }
    return TopGraphic;
}());
export { TopGraphic };
var BottomPanel = /** @class */ (function () {
    function BottomPanel() {
        this.indices = {};
    }
    return BottomPanel;
}());
export { BottomPanel };
var HistoryMayBeAGuidePlus = /** @class */ (function () {
    function HistoryMayBeAGuidePlus() {
    }
    return HistoryMayBeAGuidePlus;
}());
export { HistoryMayBeAGuidePlus };
var HistoryMayBeAGuideIE = /** @class */ (function () {
    function HistoryMayBeAGuideIE() {
    }
    return HistoryMayBeAGuideIE;
}());
export { HistoryMayBeAGuideIE };
var HistoryMayBeAGuideIncome = /** @class */ (function () {
    function HistoryMayBeAGuideIncome() {
    }
    return HistoryMayBeAGuideIncome;
}());
export { HistoryMayBeAGuideIncome };
var HistoryMayBeAGuideIncomeColumns = /** @class */ (function () {
    function HistoryMayBeAGuideIncomeColumns() {
    }
    return HistoryMayBeAGuideIncomeColumns;
}());
export { HistoryMayBeAGuideIncomeColumns };
var HistoryMayBeAGuidePlusColumns = /** @class */ (function () {
    function HistoryMayBeAGuidePlusColumns() {
    }
    return HistoryMayBeAGuidePlusColumns;
}());
export { HistoryMayBeAGuidePlusColumns };
var HistoryMayBeAGuide = /** @class */ (function () {
    function HistoryMayBeAGuide() {
    }
    return HistoryMayBeAGuide;
}());
export { HistoryMayBeAGuide };
var HistoryMayBeAGuideColumn = /** @class */ (function () {
    function HistoryMayBeAGuideColumn() {
    }
    return HistoryMayBeAGuideColumn;
}());
export { HistoryMayBeAGuideColumn };
var HistoryMayBeAGuideColumnTable = /** @class */ (function () {
    function HistoryMayBeAGuideColumnTable() {
        this.averageReturn = [];
        this.totalGainsAndLosses = [];
        this.numOfGains = [];
        this.numOfLosses = [];
        this.lossBelow10 = [];
        this.loss10to15 = [];
        this.loss15To20 = [];
        this.loss20to25 = [];
        this.loss25to30 = [];
        this.lossAbove30 = [];
        this.percentTimesAboveHighestBuffer = [];
        this.percentTimesAnyLosses = [];
    }
    return HistoryMayBeAGuideColumnTable;
}());
export { HistoryMayBeAGuideColumnTable };
var History = /** @class */ (function () {
    function History() {
    }
    return History;
}());
export { History };
var HistoryGuide = /** @class */ (function () {
    function HistoryGuide() {
    }
    return HistoryGuide;
}());
export { HistoryGuide };
var Investors = /** @class */ (function () {
    function Investors() {
    }
    return Investors;
}());
export { Investors };
var QuestionnaireResults = /** @class */ (function () {
    function QuestionnaireResults() {
    }
    return QuestionnaireResults;
}());
export { QuestionnaireResults };
var DiscoverRc = /** @class */ (function () {
    function DiscoverRc() {
    }
    return DiscoverRc;
}());
export { DiscoverRc };
var InvestTaxChart = /** @class */ (function () {
    function InvestTaxChart() {
    }
    return InvestTaxChart;
}());
export { InvestTaxChart };
///////////// bar chart
var Axis = /** @class */ (function () {
    function Axis() {
    }
    return Axis;
}());
export { Axis };
var Item = /** @class */ (function () {
    function Item() {
    }
    return Item;
}());
export { Item };
var Line = /** @class */ (function () {
    function Line() {
    }
    return Line;
}());
export { Line };
var Bar = /** @class */ (function () {
    function Bar() {
    }
    return Bar;
}());
export { Bar };
var Chart = /** @class */ (function () {
    function Chart() {
    }
    return Chart;
}());
export { Chart };
//===================== SCS ============================
var SCS = /** @class */ (function () {
    function SCS() {
    }
    return SCS;
}());
export { SCS };
//===================== SCS INCOME ============================
var SCSINCOME = /** @class */ (function () {
    function SCSINCOME() {
    }
    return SCSINCOME;
}());
export { SCSINCOME };
//===================== IE (Investment Edge) ============================
var IE = /** @class */ (function () {
    function IE() {
    }
    return IE;
}());
export { IE };
//=================================================//
var Version = /** @class */ (function () {
    function Version() {
    }
    return Version;
}());
export { Version };
var Notification = /** @class */ (function () {
    function Notification() {
    }
    return Notification;
}());
export { Notification };
var Series = /** @class */ (function () {
    function Series() {
    }
    return Series;
}());
export { Series };
var Performance = /** @class */ (function () {
    function Performance() {
    }
    return Performance;
}());
export { Performance };
var Kits = /** @class */ (function () {
    function Kits() {
    }
    return Kits;
}());
export { Kits };
var HistorySegment = /** @class */ (function () {
    function HistorySegment() {
        this.disabled = false;
        this.checked = false;
        this.rateOfReturn = 0;
        this.performanceRate = 0;
    }
    return HistorySegment;
}());
export { HistorySegment };
var IndexOptions = /** @class */ (function () {
    function IndexOptions() {
        this.percentage = 0;
    }
    return IndexOptions;
}());
export { IndexOptions };
var Preset = /** @class */ (function () {
    function Preset() {
        this.indices = [];
    }
    return Preset;
}());
export { Preset };
