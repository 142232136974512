import { PRESERVE_LEGACY_TOOLS, ROUTE_PATH } from "./../../../constants/constants";
import { UtilityService } from "../../../core/services/utility.service";
import { Component } from "@angular/core";

@Component({
  selector: "app-preserve-legacy",
  templateUrl: "./preserve-legacy.component.html",
  styleUrls: ["./preserve-legacy.component.scss"],
})
export class PreserveLegacyComponent {
  // class variables
  activeTab = PRESERVE_LEGACY_TOOLS.ROP;
  chartImage: string[];
  segments: string[];
  pageTitle: string;
  backLinkText: string;

  constructor(private utilityService: UtilityService) {
    this.segments = [
      PRESERVE_LEGACY_TOOLS.ROP,
      PRESERVE_LEGACY_TOOLS.HA,
   
    ];
    this.segmentChanged(this.activeTab);
  }

  backButton() {
    this.utilityService.navigateToBack();
  }

  segmentChanged(event) {
    if (event) {
      this.activeTab = event;
      switch (event) {
        case PRESERVE_LEGACY_TOOLS.ROP:
          this.chartImage = [
            "./assets/images/scs-chart-preserve-legacy-rop.jpg",
          ];
          break;
        case PRESERVE_LEGACY_TOOLS.HA:
          this.chartImage = [
            "./assets/images/scs-chart-preserve-legacy-ha.jpg",
          ];
          break;
       
        default:
          break;
      }
    }
  }
}
