import { HttpResponse, HttpHeaders } from "@angular/common/http";
import { from, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { File } from "@ionic-native/file/ngx";
import { FILE_EXTENSION, MIME_TYPE } from "../../constants/constants";
import * as i0 from "@angular/core";
import * as i1 from "@ionic-native/file/ngx/index";
var FileSystemService = /** @class */ (function () {
    function FileSystemService(file) {
        this.file = file;
    }
    // write to existing file, if does not exist, then create and write
    FileSystemService.prototype.createAndWrite = function (filename, data) {
        var _this = this;
        return this.file
            .checkFile(this.file.dataDirectory, filename)
            .then(function () {
            return _this.writeFile(filename, data);
        })
            .catch(function () {
            return _this.file
                .createFile(_this.file.dataDirectory, filename, false)
                .then(function () { return _this.writeFile(filename, data); });
        });
    };
    // write to existing file, if does not exist, then create and write
    FileSystemService.prototype.writeFile = function (filename, data) {
        return this.file.writeFile(this.file.dataDirectory, filename, data, {
            replace: true,
            append: false
        });
    };
    // read binary file
    FileSystemService.prototype.readFile = function (filename) {
        var _this = this;
        var extension = filename.split(".").pop();
        var contentType;
        if (extension === FILE_EXTENSION.JPG) {
            contentType = MIME_TYPE.JPG;
        }
        else if (extension === FILE_EXTENSION.JPEG) {
            contentType = MIME_TYPE.JPG;
        }
        else if (extension === FILE_EXTENSION.PNG) {
            contentType = MIME_TYPE.PNG;
        }
        else if (extension === FILE_EXTENSION.PDF) {
            contentType = MIME_TYPE.PDF;
        }
        // read content of file
        var contentPromise = this.file.readAsArrayBuffer(this.file.dataDirectory, filename);
        // read modification date of file
        var metaPromise = new Promise(function (resolve, reject) {
            _this.file
                .resolveLocalFilesystemUrl(_this.file.dataDirectory + "/" + filename)
                .then(function (file) {
                file.getMetadata(function (meta) {
                    resolve(new Date(meta.modificationTime).getTime());
                }, function (error) {
                    reject(error);
                });
            });
        });
        // combine content and modification date
        return from(Promise.all([contentPromise, metaPromise])).pipe(catchError(function (err) {
            console.log("error: " + err + " " + filename);
            return throwError(err);
        }), map(function (data) {
            var headers = new HttpHeaders({
                "Content-Type": contentType,
                "X-cached-data": "true",
                "X-last-modified-date": data[1] + ""
            });
            return new HttpResponse({
                body: new Blob([data[0]]),
                headers: headers
            });
        }));
    };
    FileSystemService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileSystemService_Factory() { return new FileSystemService(i0.ɵɵinject(i1.File)); }, token: FileSystemService, providedIn: "root" });
    return FileSystemService;
}());
export { FileSystemService };
