<section class="ion-padding modal-popup-container">
  <ion-toolbar *ngIf="modalTitle">
    <ion-title class="modal-popup-title">{{
      modalTitle | translate
    }}</ion-title>
  </ion-toolbar>
  <ion-img
    src="./assets/icons/icon-close.svg"
    class="icon-close-popup"
    (click)="closeModal()"
  ></ion-img>
  <!-- Generic HTMl Tamplate-->
  <article *ngIf="!isAddProfileForm" class="ion-text-center">
    <p [innerHTML]="'investorProfileText.modalPopSubTitle' | translate"></p>
    <ion-grid>
      <ion-row>
        <ion-col size="6">
          <equitable-button
            [type]="btnType"
            [theme]="btnTheme"
            (click)="customProfile(customRootPath)"
            >{{ "investorProfileText.customProfiles" | translate }}
          </equitable-button>
        </ion-col>
        <ion-col size="6">
          <equitable-button
            [type]="btnType"
            [theme]="btnTheme"
            (click)="sampleProfile(rootPath)"
            >{{ "investorProfileText.sampleProfiles" | translate }}
          </equitable-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </article>

  <!-- Add Profile Form-->
  <article *ngIf="isAddProfileForm"></article>
</section>
