<ion-content class="rc-tool-parent-container">
  <div class="page-header site-tools">
    <equitable-sub-header
      title="{{ 'sioFrontPage.headerText' | translate }}"
      [backLinkText]="backLink"
      showInfo="true"
      (backClick)="backButton()"
      (infoClick)="openModal()"
    ></equitable-sub-header>
  </div>
  <div
    class="sio-page-container site-tools"
    *ngIf="form && segmentOption && segmentOption.length > 0"
  >

    <div *ngIf="isPlusPage" class="sio-description">
      <p
        class="sio-desc-header"
        [innerHTML]="'sioFrontPage.getStartedText_PLUS' | translate"
      ></p>
      <p
        class="sio-desc-content"
        [innerHTML]="'sioFrontPage.description_PLUS' | translate"
      ></p>
    </div>
    <div *ngIf="isPlus21Page" class="sio-description">
      <p
        class="sio-desc-header"
        [innerHTML]="'sioFrontPage.getStartedText_PLUS21' | translate"
      ></p>
      <p
        class="sio-desc-content"
        [innerHTML]="'sioFrontPage.description_PLUS21' | translate"
      ></p>
    </div>
    <div *ngIf="!isPlusPage && !isPlus21Page" class="sio-description">
      <p
        class="sio-desc-header"
        [innerHTML]="'sioFrontPage.getStartedText' | translate"
      >
      </p>
      <p
        class="sio-desc-content"
        [innerHTML]="'sioFrontPage.description' | translate"
      ></p>
    </div>
    <div class="sio-chart-selection">
      <form [formGroup]="form">
        <p class="select-header">
          {{ "sioFrontPage.selectHeader" | translate }}
        </p>

        <div class="row-prop margin-rght">
          <div>
            <p class="segment-header">
              {{ "sioFrontPage.index" | translate }}
            </p>
            <form class="top-spacing" [formGroup]="form">
              <ion-list>
                <ion-radio-group
                  [formControl]="form.get('index')"
                  formControlName="index"
                >
                  <ion-list-header class="ion-hide"
                    ><ion-label> </ion-label>
                  </ion-list-header>
                  <div class="space">
                    <div class="inner" *ngFor="let options of indices">
                      <ion-item class="ion-item" lines="none">
                        <ion-label
                          [innerHTML]="
                            options.value.replace('®', '<sup>®</sup>')
                          "
                        ></ion-label>
                        <ion-radio
                          #ionRadioElRef
                          [value]="options.id"
                          [disabled]="options.disabled"
                          (click)="indexSelection($event.target.value)"
                        ></ion-radio>
                      </ion-item>
                    </div>
                  </div>
                </ion-radio-group>
              </ion-list>
            </form>
          </div>
        </div>
        <ion-row class="row-prop">
          <ion-col class="col-prop">
            <p class="segment-header">
              {{ "sioFrontPage.duration" | translate }}
            </p>
            <form *ngIf="showDuration" class="top-spacing" [formGroup]="form">
              <ion-list>
                <ion-radio-group
                  [formControl]="form.get('duration')"
                  formControlName="duration"
                >
                  <ion-list-header class="ion-hide"
                    ><ion-label> </ion-label>
                  </ion-list-header>
                  <div class="space">
                    <div class="inner" *ngFor="let options of durations">
                      <ion-item class="ion-item" lines="none">
                        <ion-label>{{ options.value }}</ion-label>
                        <ion-radio
                          #ionRadioElRef
                          [value]="options.id"
                          [disabled]="options.disabled"
                          (click)="durationSelection($event.target.value)"
                        ></ion-radio>
                      </ion-item>
                    </div>
                  </div>
                </ion-radio-group>
              </ion-list>
            </form>
          </ion-col>
          <ion-col class="left-spacing">
            <p class="segment-header">
              {{ "sioFrontPage.protection" | translate }}
            </p>
            <div *ngIf="showProtection" class="top-spacing" [formGroup]="form">
              <ion-list>
                <ion-radio-group
                  [formControl]="form.get('protection')"
                  formControlName="protection"
                  (click)="protectionSelection($event)"
                >
                  <ion-list-header class="ion-hide"
                    ><ion-label> </ion-label>
                  </ion-list-header>
                  <div class="space">
                    <div class="inner" *ngFor="let options of protections">
                      <ion-item class="ion-item" lines="none">
                        <ion-label>{{ options.value }}</ion-label>
                        <ion-radio
                          #ionRadioElRef
                          [value]="options.id"
                          [disabled]="options.disabled"
                        ></ion-radio>
                      </ion-item>
                    </div>
                  </div>
                </ion-radio-group>
              </ion-list>
            </div>
          </ion-col>
        </ion-row>
        <ion-row class="up-spacing button-parent">
          <ion-col style="padding-right: 6px;">
            <p class="segment-header">
              {{ "sioFrontPage.segmentOptionHeader" | translate }}
            </p>
            <form class="top-spacing" [formGroup]="form">
              <equitable-radio-button
                class="radio-button-col"
                [items]="segmentOption"
                orientation="horizontal"
                formControlName="segment"
                (click)="segmentSelection()"
                [formControl]="form.get('segment')"
              ></equitable-radio-button>
            </form>
          </ion-col>
          <ion-col class="top-spacing">
            <equitable-button
              (click)="viewResults()"
              class="btn-main"
              type="primary"
              theme="light"
              [disabled]="btnDisabled"
              ><span class="btn-text">{{
                "sioFrontPage.btnText" | translate
              }}</span
              ><span>
                <ion-icon
                  src="./assets/icons/arrow-front.svg"
                ></ion-icon> </span
            ></equitable-button>
          </ion-col>
        </ion-row>
      </form>
    </div>
  </div>
  <ion-row>
    <ion-col></ion-col>
  </ion-row>
  <app-footer
    isBlueFooterVisible="true"
    isWhiteFooterVisible="true"
    activeTab="How_SIO_works"
    [selectedProduct]="series"
  ></app-footer>
</ion-content>
