import * as tslib_1 from "tslib";
import { LoadingController, AlertController, NavController, Platform, } from "@ionic/angular";
import { BehaviorSubject } from "rxjs";
import { NavigationExtras } from "@angular/router";
import { Location } from "@angular/common";
import { SITE } from "src/assets/sites";
import { ATTRIBUTE_TYPE } from "equitable-component-library";
import { DomSanitizer } from "@angular/platform-browser";
import { ModalController } from "@ionic/angular";
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/common";
import * as i3 from "@angular/platform-browser";
var UtilityService = /** @class */ (function () {
    function UtilityService(loader, router, location, sanitizer, alertCtrl, modalController, platform) {
        this.loader = loader;
        this.router = router;
        this.location = location;
        this.sanitizer = sanitizer;
        this.alertCtrl = alertCtrl;
        this.modalController = modalController;
        this.platform = platform;
        this.loading = null;
        this.alertPresent = false;
        this.loaderPresent = false;
        this.subject = new BehaviorSubject(true);
    }
    UtilityService.prototype.showAlert = function (obj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var buttons;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                buttons = [];
                if (obj.buttons.length > 0) {
                    obj.buttons.forEach(function (element) {
                        buttons.push({
                            text: element.text,
                            role: element.role,
                            handler: element.handler,
                        });
                    });
                }
                this.alertCtrl
                    .create({
                    header: obj.title,
                    message: obj.message,
                    buttons: buttons,
                })
                    .then(function (alert) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, alert.present()];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    UtilityService.prototype.myLoader = function (message) {
        var loader = this.loader.create({
            spinner: "crescent",
            message: message,
            translucent: true,
            cssClass: "custom-loading",
        });
        return loader;
    };
    UtilityService.prototype.showLoader = function (message) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        message = message ? message : "Please wait...";
                        if (!!this.loaderPresent) return [3 /*break*/, 3];
                        _a = this;
                        return [4 /*yield*/, this.myLoader(message)];
                    case 1:
                        _a.loading = _b.sent();
                        return [4 /*yield*/, this.loading.present()];
                    case 2:
                        _b.sent();
                        this.loaderPresent = true;
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UtilityService.prototype.hideLoader = function () {
        var _this = this;
        // eslint-disable-next-line angular/timeout-service
        setTimeout(function () {
            if (_this.loading) {
                _this.loading.dismiss();
                _this.loaderPresent = false;
            }
        }, 1500);
    };
    UtilityService.prototype.getLoader = function () {
        if (this.loading) {
            return this.loading;
        }
    };
    UtilityService.prototype.createFileNameFromUrl = function (url) {
        var urlObject = new URL(url);
        return urlObject.pathname.split("/").pop();
    };
    UtilityService.prototype.findFileExtension = function (url) {
        return this.createFileNameFromUrl(url).split(".").pop();
    };
    UtilityService.prototype.sendScrollStatus = function (message) {
        this.subject.next(message);
    };
    UtilityService.prototype.getScrollStatus = function () {
        return this.subject.asObservable();
    };
    UtilityService.prototype.toTitleCase = function (str) {
        return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    };
    UtilityService.prototype.getQueryParam = function (path) {
        var arr = [];
        //how many parmam
        var params = path.split("&");
        params.forEach(function (element) {
            var q = element.split("=");
            arr.push({
                key: q[0],
                value: q[1],
            });
        });
        return arr;
    };
    UtilityService.prototype.navigate = function (toPage, data, isExternal) {
        if (data === void 0) { data = {}; }
        if (decodeURI(toPage).indexOf("?") !== -1) {
            var subStr = toPage.split("?");
            toPage = subStr[0];
            this.getQueryParam(subStr[1]).forEach(function (element) {
                data[element.key] = element.value;
            });
        }
        var navigationExtras = {
            queryParams: data,
            fragment: "anchor",
            replaceUrl: false,
        };
        this.router.navigateRoot([toPage], navigationExtras);
    };
    UtilityService.prototype.visitEquitable = function (url) {
        if (this.platform.is("cordova")) {
            // eslint-disable-next-line angular/window-service
            window.open(url, "_system");
        }
        else {
            // eslint-disable-next-line angular/window-service
            window.open(url, "_blank");
        }
    };
    UtilityService.prototype.visitEquitableMicrosite = function (url) {
        if (this.platform.is("cordova")) {
            // eslint-disable-next-line angular/window-service
            window.open(url, "_system");
        }
        else {
            // eslint-disable-next-line angular/window-service
            window.open(url, "_blank");
        }
    };
    UtilityService.prototype.navigateToBack = function () {
        this.location.back();
    };
    UtilityService.prototype.getUrl = function (urlPath) {
        return SITE.AXA + urlPath;
    };
    UtilityService.prototype.getPath = function (iconName, type) {
        var finalPath = "./assets/";
        switch (type) {
            case ATTRIBUTE_TYPE.IMAGE:
                finalPath = finalPath + "images/" + iconName;
                break;
            case ATTRIBUTE_TYPE.ICON:
                finalPath = finalPath + "icons/" + iconName;
                break;
            default:
                break;
        }
        return finalPath;
    };
    UtilityService.prototype.sanitizeDom = function (type, url) {
        var sanitizedUrl;
        switch (type) {
            case ATTRIBUTE_TYPE.IMAGE:
                sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(url);
                break;
            case ATTRIBUTE_TYPE.URL:
                sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                break;
            default:
                sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                break;
        }
        return sanitizedUrl;
    };
    UtilityService.prototype.navigateWithoutParams = function (toPage) {
        this.router.navigateRoot([toPage]);
    };
    UtilityService.prototype.presentWithoutTitleModal = function (ModalPopupComponent) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: ModalPopupComponent,
                            cssClass: "xs-modal-popup",
                        })];
                    case 1:
                        modal = _a.sent();
                        return [4 /*yield*/, modal.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    UtilityService.prototype.showValidationErrorForControls = function (formGroup) {
        var validationErrors = [];
        Object.keys(formGroup.controls).forEach(function (controlName) {
            var control = formGroup.controls[controlName];
            control.markAsTouched();
        });
        return validationErrors;
    };
    UtilityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilityService_Factory() { return new UtilityService(i0.ɵɵinject(i1.LoadingController), i0.ɵɵinject(i1.NavController), i0.ɵɵinject(i2.Location), i0.ɵɵinject(i3.DomSanitizer), i0.ɵɵinject(i1.AlertController), i0.ɵɵinject(i1.ModalController), i0.ɵɵinject(i1.Platform)); }, token: UtilityService, providedIn: "root" });
    return UtilityService;
}());
export { UtilityService };
