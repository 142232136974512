import { ScsService } from "./../../services/scs.service";
import { OnInit, AfterViewInit, ElementRef, QueryList } from "@angular/core";
import { Theme, Orientation, Icon } from "equitable-component-library";
import { Validators, FormBuilder } from "@angular/forms";
import { Dictionary, Profile, } from "../../../core/models/app.models";
import { UtilityService } from "../../../core/services/utility.service";
import { ROUTE_PATH, SCS_SERIES, CUSTOMIZATION_TYPE, INVESTOR_MODE, } from "../../../constants/constants";
import { TranslateService } from "@ngx-translate/core";
import { ModalController } from "@ionic/angular";
import { SioPopupComponent } from "../sio-popup/sio-popup.component";
import { distinctUntilChanged } from "rxjs/operators";
import { CoreService } from "src/app/core/services/core.service";
import { TealiumUtagService } from '../../../tealium/utag.service';
var SioComponent = /** @class */ (function () {
    function SioComponent(fb, scsService, utilityService, translate, modalController, coreService, tealium) {
        this.fb = fb;
        this.scsService = scsService;
        this.utilityService = utilityService;
        this.translate = translate;
        this.modalController = modalController;
        this.coreService = coreService;
        this.tealium = tealium;
        //Class variables
        this.type = Theme.Dark;
        this.orientation = Orientation.Left;
        this.backIcon = Icon.BackButton;
        this.btnDisabled = true;
        this.flag = 0;
        this.segmentOption = [];
        this.backButtonSeries = this.scsService.series === SCS_SERIES.PLUS ? SCS_SERIES.PLUS : "";
        this.isPlusPage = this.scsService.series === SCS_SERIES.PLUS ? true : false;
        this.isPlus21Page = this.scsService.series === SCS_SERIES.PLUS21 ? true : false;
    }
    /***
     *  Page initialize
     ***/
    SioComponent.prototype.ngOnInit = function () {
        this.getTranslated();
        if (this.scsService.series === SCS_SERIES.PLUS) {
            this.backLink = this.backLinkPlus;
        }
        else if (this.scsService.series === SCS_SERIES.PLUS21) {
            this.backLink = this.backLinkPlus21;
        }
        else {
            this.backLink = this.backLinkOther;
        }
        if (this.series !== this.scsService.series) {
            if (this.form)
                this.form.reset();
            this.series = this.scsService.series;
            this.buildForm();
        }
        // this.tealium.link({
        //   tealium_event: 'link',
        //   event_category: 'BodyClick',
        //   event_name: 'Retirement Guide Clicks',
        //   event_label: 'Click on How SIO Works'
        // })
        this.scsService.getDynamicSCSData();
    };
    SioComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.getTranslated();
        this.ionRadioElRef.changes.subscribe(function () {
            _this.ionRadioElRef.forEach(function (el) {
                var loadCheck = setInterval(function () {
                    var button = el.nativeElement.shadowRoot.childNodes[1];
                    if (button) {
                        button.setAttribute('aria-label', 'radio');
                        clearInterval(loadCheck);
                    }
                }, 100);
            });
        });
    };
    SioComponent.prototype.ionViewWillEnter = function () {
        this.getTranslated();
        if (this.scsService.series === SCS_SERIES.PLUS) {
            this.backLink = this.backLinkPlus;
        }
        else {
            this.backLink = this.backLinkOther;
        }
        if (this.scsService.series === SCS_SERIES.PLUS21) {
            this.backLink = this.backLinkPlus21;
        }
        else {
            this.backLink = this.backLinkOther;
        }
        if (this.series !== this.scsService.series) {
            if (this.form)
                this.form.reset();
            this.series = this.scsService.series;
            this.buildForm();
        }
    };
    /***
     *  Build form for pages
     ***/
    SioComponent.prototype.buildForm = function () {
        var _this = this;
        this.form = this.fb.group({
            segment: [this.scsService.investorData.segment, Validators.required],
            index: [this.scsService.investorData.index],
            duration: [this.scsService.investorData.duration, Validators.required],
            protection: [
                this.scsService.investorData.protection,
                Validators.required,
            ],
        });
        this.getData();
        this.form
            .get("segment")
            .valueChanges.pipe(distinctUntilChanged())
            .subscribe(function (data) {
            if (data)
                _this.segmentSelection();
        });
        this.form
            .get("index")
            .valueChanges.pipe(distinctUntilChanged())
            .subscribe(function (data) {
            if (data)
                _this.indexSelection(data);
        });
        this.form
            .get("duration")
            .valueChanges.pipe(distinctUntilChanged())
            .subscribe(function (data) {
            if (data)
                _this.durationSelection(data);
        });
        this.form
            .get("protection")
            .valueChanges.pipe(distinctUntilChanged())
            .subscribe(function (data) {
            if (data)
                _this.protectionSelection(data);
        });
    };
    /***
     *  Get All data based on  Selected series
     ***/
    SioComponent.prototype.getData = function () {
        var _this = this;
        this.indices = [];
        this.protections = [];
        this.durations = [];
        this.scsService.getHowitWorksMenuData().subscribe(function (response) {
            _this.masterData = response[0].body.series.find(function (x) { return x.name === _this.series; });
            var allIndex = [];
            var allDuration = [];
            var allProtection = [];
            var filteredProtection = [];
            _this.masterData.segments.forEach(function (element) {
                allIndex.push.apply(allIndex, element.data.map(function (x) { return x.index; }));
                allDuration.push.apply(allDuration, element.data.map(function (x) { return x.duration; }));
                var z = element.data.map(function (x) { return x.buffers; });
                filteredProtection.push.apply(filteredProtection, element.data.map(function (x) { return x.buffers; }));
            });
            filteredProtection.forEach(function (element) {
                allProtection.push.apply(allProtection, element.map(function (x) { return x; }));
            });
            _this.indices = Array.from(new Set(allIndex.map(function (x) { return x; }))).map(function (y) { return ({ id: y, value: y, disabled: true }); });
            _this.durations = Array.from(new Set(allDuration.map(function (x) { return x; })))
                .sort(function (a, b) { return a - b; })
                .map(function (y) { return ({
                id: y,
                value: y + " " + _this.translate.instant("sioFrontPage.year"),
                disabled: true,
            }); });
            _this.protections = Array.from(new Set(allProtection.map(function (x) { return x; })))
                .sort(function (a, b) { return b - a; })
                .map(function (y) { return ({
                id: y,
                value: y + _this.translate.instant("sioFrontPage.percentage"),
                disabled: true,
            }); });
            _this.segmentOption = Array.from(_this.masterData.segments.map(function (x) { return x.id; })).map(function (x) { return ({ id: x, value: x }); });
            //saved profile
            var savedProfiles = response[1];
            if (savedProfiles &&
                savedProfiles.length > 0 &&
                ((_this.scsService.customizationType === CUSTOMIZATION_TYPE.PROFILE &&
                    _this.scsService.currentProfile) ||
                    _this.scsService.customizationType === CUSTOMIZATION_TYPE.REPORT)) {
                var profile = savedProfiles.find(function (x) {
                    return x.name ===
                        (_this.scsService.customizationType == CUSTOMIZATION_TYPE.PROFILE
                            ? _this.scsService.currentProfile
                            : CUSTOMIZATION_TYPE.REPORT) &&
                        x.currentSerie === _this.scsService.series;
                });
                if (profile && profile.aboutSCSHowSioWorks) {
                    _this.form
                        .get("segment")
                        .setValue(profile.aboutSCSHowSioWorks.segmentOption);
                    _this.form.get("index").setValue(profile.aboutSCSHowSioWorks.index);
                    _this.form
                        .get("duration")
                        .setValue(profile.aboutSCSHowSioWorks.duration.replace("yr", ""));
                    _this.form
                        .get("protection")
                        .setValue(parseInt(profile.aboutSCSHowSioWorks.protection.replace("%", "")));
                    for (var j = 0; j < _this.indices.length; j++) {
                        if (_this.indices[j].id == _this.scsService.investorData.index) {
                            _this.indices[j].disabled = false;
                        }
                    }
                    for (var j = 0; j < _this.durations.length; j++) {
                        if (_this.durations[j].id == _this.scsService.investorData.duration) {
                            _this.durations[j].disabled = false;
                        }
                    }
                    for (var j = 0; j < _this.protections.length; j++) {
                        if (_this.protections[j].id == _this.scsService.investorData.protection) {
                            _this.protections[j].disabled = false;
                        }
                    }
                }
                else {
                    _this.form.controls["duration"].reset();
                    _this.form.controls["protection"].reset();
                    _this.form.controls["index"].reset();
                    _this.form.get("segment").setValue(_this.segmentOption[0].id);
                    _this.segmentSelect1();
                }
            }
            else if (_this.scsService.investorData.segment &&
                _this.scsService.investorData.index &&
                _this.scsService.investorData.duration &&
                _this.scsService.investorData.protection) {
                _this.form.get("segment").setValue(_this.scsService.investorData.segment);
                _this.form.get("index").setValue(_this.scsService.investorData.index);
                _this.form
                    .get("duration")
                    .setValue(_this.scsService.investorData.duration);
                _this.form
                    .get("protection")
                    .setValue(_this.scsService.investorData.protection);
                for (var j = 0; j < _this.indices.length; j++) {
                    if (_this.indices[j].id == _this.scsService.investorData.index) {
                        _this.indices[j].disabled = false;
                    }
                }
                for (var j = 0; j < _this.durations.length; j++) {
                    if (_this.durations[j].id == _this.scsService.investorData.duration) {
                        _this.durations[j].disabled = false;
                    }
                }
                for (var j = 0; j < _this.protections.length; j++) {
                    if (_this.protections[j].id == _this.scsService.investorData.protection) {
                        _this.protections[j].disabled = false;
                    }
                }
            }
            else {
                _this.form.controls["duration"].reset();
                _this.form.controls["protection"].reset();
                _this.form.controls["index"].reset();
                _this.form.get("segment").setValue(_this.segmentOption[0].id);
                _this.segmentSelect1();
            }
        });
        // eslint-disable-next-line angular/timeout-service
        setTimeout(function () {
            _this.repaintButtons();
        }, 1500);
    };
    SioComponent.prototype.repaintButtons = function () {
        // eslint-disable-next-line angular/document-service
        var items = document.querySelectorAll(".ion-item");
        items.forEach(function (item) {
            item.shadowRoot.querySelectorAll(".item-native").forEach(function (native) {
                native.setAttribute("style", "padding-left:0px");
                native.querySelectorAll(".item-inner").forEach(function (inner) {
                    inner.setAttribute("style", "padding:0px");
                });
            });
        });
        // eslint-disable-next-line angular/document-service
        var item2 = document.querySelectorAll(".ion-item ion-radio");
        // console.log("item2", item2);
        item2.forEach(function (item) {
            item.shadowRoot.querySelectorAll("button").forEach(function (nativeelem) {
                nativeelem.setAttribute("style", "padding:0px");
            });
        });
    };
    /***
     *  Method to get data based on default selected segment
     ***/
    SioComponent.prototype.segmentSelect1 = function () {
        var _this = this;
        this.form.get("segment").setValue(this.segmentOption[0].id);
        var segment = typeof this.form.get("segment").value === "object"
            ? this.form.get("segment").value.id
            : this.form.get("segment").value;
        this.segment = segment;
        var segmentMaster = this.masterData.segments.find(function (x) { return x.id === segment; })
            .data;
        this.segmentMaster = segmentMaster;
        this.indices1 = Array.from(new Set(segmentMaster.map(function (x) { return x.index; }))).map(function (x) { return ({ id: x, value: x }); });
        for (var i = 0; i < this.indices.length; i++) {
            this.indices[i].disabled = true;
            for (var j = 0; j < this.indices1.length; j++) {
                if (this.indices[i].id == this.indices1[j].id) {
                    this.indices[i].disabled = false;
                }
            }
        }
        this.showDuration = false;
        this.showProtection = false;
        // eslint-disable-next-line angular/timeout-service
        setTimeout(function () {
            _this.repaintButtons();
        }, 1000);
    };
    /***
     * Method to be called when  new segment is selected
     ***/
    SioComponent.prototype.segmentSelection = function () {
        var _this = this;
        this.form.controls["duration"].reset();
        this.form.controls["protection"].reset();
        this.form.controls["index"].reset();
        this.showDuration = false;
        this.showProtection = false;
        var segment = typeof this.form.get("segment").value === "object"
            ? this.form.get("segment").value.id
            : this.form.get("segment").value;
        this.segment = segment;
        var segmentMaster = this.masterData.segments.find(function (x) { return x.id === segment; })
            .data;
        this.segmentMaster = segmentMaster;
        this.indices1 = Array.from(new Set(segmentMaster.map(function (x) { return x.index; }))).map(function (x) { return ({ id: x, value: x }); });
        for (var i = 0; i < this.indices.length; i++) {
            this.indices[i].disabled = true;
            for (var j = 0; j < this.indices1.length; j++) {
                if (this.indices[i].id === this.indices1[j].id) {
                    this.indices[i].disabled = false;
                }
            }
        }
        this.durations1 = Array.from(new Set(segmentMaster.map(function (x) { return x.duration; }))).map(function (x) { return ({
            id: x,
            value: x + " " + _this.year,
        }); });
        this.durations = this.durations.slice();
        for (var i = 0; i < this.durations.length; i++) {
            this.durations[i].disabled = true;
            for (var j = 0; j < this.durations1.length; j++) {
                if (this.durations[i].id === this.durations1[j].id) {
                    this.durations[i].disabled = false;
                }
            }
        }
        this.protections1 = Array.from(new Set(segmentMaster.map(function (x, index) { return x.buffers[index]; }))).map(function (x) { return ({
            id: x,
            value: x + _this.percent,
        }); });
        for (var i = 0; i < this.protections.length; i++) {
            this.protections[i].disabled = true;
            for (var j = 0; j < this.protections1.length; j++) {
                if (this.protections[i].id === this.protections1[j].id) {
                    this.protections[i].disabled = false;
                }
            }
        }
        // eslint-disable-next-line angular/timeout-service
        setTimeout(function () {
            _this.repaintButtons();
        }, 200);
        this.buttonDisable();
    };
    /***
     *  Method to be called when index is selected
     ***/
    SioComponent.prototype.indexSelection = function (value) {
        var _this = this;
        this.showDuration = true;
        this.showProtection = false;
        this.form.controls["duration"].reset();
        this.form.controls["protection"].reset();
        for (var i = 0; i < this.durations.length; i++) {
            this.durations[i].disabled = true;
        }
        for (var i = 0; i < this.protections.length; i++) {
            this.protections[i].disabled = true;
        }
        this.segmentMaster.filter(function (item) {
            if (item.index === value) {
                for (var i = 0; i < _this.durations.length; i++) {
                    if (item.duration === _this.durations[i].id) {
                        _this.durations[i].disabled = false;
                    }
                }
            }
        });
        this.segmentMaster.filter(function (item) {
            if (item.index === _this.form.controls["index"].value) {
                for (var i = 0; i < _this.protections.length; i++) {
                    for (var j = 0; j < item.buffers.length; j++) {
                        if (item.buffers[j] === parseInt(_this.protections[i].id)) {
                            _this.protections[i].disabled = false;
                        }
                    }
                }
            }
        });
        // eslint-disable-next-line angular/timeout-service
        setTimeout(function () {
            _this.repaintButtons();
        }, 200);
        this.buttonDisable();
    };
    /***
     *  Method to be called when duration is selected
     ***/
    SioComponent.prototype.durationSelection = function (value) {
        var _this = this;
        this.showProtection = true;
        this.form.controls["protection"].reset();
        if (this.form.controls["index"].value != undefined && value != undefined) {
            for (var i = 0; i < this.protections.length; i++) {
                this.protections[i].disabled = true;
            }
            this.segmentMaster.filter(function (item) {
                if (item.index === _this.form.controls["index"].value &&
                    item.duration === value) {
                    for (var i = 0; i < _this.protections.length; i++) {
                        for (var j = 0; j < item.buffers.length; j++) {
                            if (item.buffers[j] === parseInt(_this.protections[i].id)) {
                                _this.protections[i].disabled = false;
                            }
                        }
                    }
                }
            });
        }
        // eslint-disable-next-line angular/timeout-service
        setTimeout(function () {
            _this.repaintButtons();
        }, 200);
        this.buttonDisable();
    };
    /***
     *  Method to be called click of button view Results
     ***/
    SioComponent.prototype.viewResults = function () {
        if (!this.btnDisabled) {
            if (this.scsService.customizationType ===
                CUSTOMIZATION_TYPE.INVESTOR_PROFILE &&
                (this.scsService.investorData.segment !==
                    this.form.controls["segment"].value ||
                    this.scsService.investorData.index !==
                        this.form.controls["index"].value ||
                    this.scsService.investorData.duration !==
                        this.form.controls["duration"].value ||
                    this.scsService.investorData.protection !==
                        this.form.controls["protection"].value)) {
                this.coreService.profile.next({
                    name: "",
                    type: CUSTOMIZATION_TYPE.REPORT,
                    profile: new Profile(),
                });
                this.scsService.customizationType = CUSTOMIZATION_TYPE.REPORT;
            }
            this.scsService.investorData.index = this.form.controls["index"].value;
            this.scsService.investorData.duration = this.form.controls["duration"].value;
            this.scsService.investorData.protection = this.form.controls["protection"].value;
            this.scsService.investorData.segment = this.form.controls["segment"].value;
            this.scsService.investorData.mode = INVESTOR_MODE.HOME;
            this.utilityService.navigate(ROUTE_PATH.SCS.TOOLS.HOW_SIO_WORKS_DETAILS);
            // const hypothetical = new SCSHowSioWorks();
            // hypothetical.segmentOption = this.scsService.investorData.segment;
            // hypothetical.duration = this.scsService.investorData.duration + "yr";
            // hypothetical.index = this.scsService.investorData.index;
            // hypothetical.protection = this.scsService.investorData.protection + "%";
            // const isReport =
            //   this.scsService.customizationType === CUSTOMIZATION_TYPE.REPORT
            //     ? true
            //     : false;
            // this.scsService
            //   .storeProfile(CUSTOM_PROFILE.HOW_IT_WORKS, hypothetical, isReport)
            //   .subscribe(() => {
            //     this.utilityService.navigate(
            //       ROUTE_PATH.SCS.TOOLS.HOW_SIO_WORKS_DETAILS
            //     );
            //   });
        }
    };
    /***
     *  Method to be called when protection is selected
     ***/
    SioComponent.prototype.protectionSelection = function (event) {
        this.buttonDisable();
    };
    /***
     *  button disable condition
     ***/
    SioComponent.prototype.buttonDisable = function () {
        if (this.form.controls["index"].value &&
            this.form.controls["duration"].value &&
            this.form.controls["protection"].value) {
            this.btnDisabled = false;
        }
        else {
            this.btnDisabled = true;
        }
    };
    /***
     *  back  button
     ***/
    SioComponent.prototype.backButton = function () {
        this.utilityService.navigate(ROUTE_PATH.SCS.TOOLS.HOME);
    };
    SioComponent.prototype.openModal = function () {
        this.scsService.openModal(SioPopupComponent, "modal-css");
    };
    SioComponent.prototype.getTranslated = function () {
        var _this = this;
        this.translate.get("sioFrontPage").subscribe(function (translated) {
            _this.year = translated.year;
            _this.percent = translated.percentage;
            _this.backLinkPlus = translated.backLinkPlus;
            _this.backLinkPlus21 = translated.backLinkPlus21;
            _this.backLinkOther = translated.backLink;
        });
    };
    return SioComponent;
}());
export { SioComponent };
