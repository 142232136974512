import { Component, OnInit } from "@angular/core";
import { ATTRIBUTE_TYPE } from "equitable-component-library";
import { UtilityService } from "../../../core/services/utility.service";
import { SCS_SERIES, ROUTE_PATH } from "../../../constants/constants";
import { ScsService } from "../../services/scs.service";
import { TranslateService } from "@ngx-translate/core";
import { FeatureFlagService } from "../../../core/services/feature-flag.service";
import { TealiumUtagService } from '../../../tealium/utag.service';   
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-market-trend-indicator",
  templateUrl: "./market-trend-indicator.component.html",
  styleUrls: ["./market-trend-indicator.component.scss"],
})
export class MarketTrendIndicatorComponent implements OnInit {
  series: string;
  marketTrendIndicatorUrl: string;
  backLink;

  backButtonSeries =
    this.determineSeries()
  mtiURL: string;

  constructor(
    private utilityService: UtilityService,
    private scsService: ScsService,
    private translate: TranslateService,
    private flagService: FeatureFlagService,
    private tealium: TealiumUtagService
  ) {}

  ngOnInit() {
    // this.flagService.featureFlags are controlled in the config.json files
    if (this.backButtonSeries === SCS_SERIES.PLUS) {
      console.log("PLUS")
      this.mtiURL = environment.EQUITABLE_HOMEPAGE + this.flagService.featureFlags.marketTrendIndicator_PLUS;
      console.log('mtiURL is: ')
      console.log(this.mtiURL)
    } 
    else if (this.backButtonSeries === SCS_SERIES.PLUS21) {
      console.log("PLUS21")
      this.mtiURL = environment.EQUITABLE_HOMEPAGE + this.flagService.featureFlags.marketTrendIndicator_PLUS21
      console.log('mtiURL is: ')
      console.log(this.mtiURL)
    }
    else {
      console.log('NOT PLUS');
      this.mtiURL = environment.EQUITABLE_HOMEPAGE + this.flagService.featureFlags.marketTrendIndicator;
      console.log('mtiURL is: ')
      console.log(this.mtiURL)
    }

    this.series = this.scsService.series;
    this.marketTrendIndicatorUrl = this.utilityService.sanitizeDom(
      ATTRIBUTE_TYPE.URL,
      this.mtiURL
    );

    // this.tealium.link({
    //   tealium_event: 'link',
    //   event_category: 'BodyClick',
    //   event_name: 'Retirement Guide Clicks',
    //   event_label: 'Click on Performance Cap Rate'
    // })
  }

  determineSeries(){
    if(this.scsService.series === SCS_SERIES.PLUS){
      return SCS_SERIES.PLUS
    }
    else if(this.scsService.series === SCS_SERIES.PLUS21){
      return SCS_SERIES.PLUS21
    }
    else {
      return ""
    }
  }

  ionViewWillEnter() {
    if (this.scsService.series == SCS_SERIES.PLUS) {
      this.backLink = this.translate.instant(
        "marketTrendIndicator.backLinkPlus"
      );
    } 
    else if (this.scsService.series == SCS_SERIES.PLUS21) {
      this.backLink = this.translate.instant(
        "marketTrendIndicator.backLinkPlus21"
      );
    }else {
      this.backLink = this.translate.instant("marketTrendIndicator.backLink");
    }
  }

  /***
   *  back  button
   ***/
  backButton() {
    this.utilityService.navigate(ROUTE_PATH.SCS.TOOLS.HOME);
  }
}
