import { PrimericaHomeComponent } from './scs/components/primerica-home/primerica-home.component';
import { FeatureFlagService } from './core/services/feature-flag.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { ScsPiePicturesComponent } from './scs/components/scs-pie-pictures/scs-pie-pictures.component';
import { ROUTE_PATH, CONFIG_DATA } from './constants/constants';

@Injectable({ providedIn: 'root' })

export class FeatureFlagGuard implements CanActivate {

  constructor(private featureFlagService: FeatureFlagService) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    //console.log("FFGUARDS:", route)
    if (route.data.flags === ROUTE_PATH.HOME) {
      //console.log("FFGUARDS:", "TEST1")
      if (!this.featureFlagService.featureFlags[route.data.flags]) {
        //console.log("FFGUARDS:", "TEST2")
        route.routeConfig.component = PrimericaHomeComponent;
      }
      return true;
    }
    else if (route.data.flags === CONFIG_DATA.USEPIE) {
      //console.log("FFGUARDS:", "TEST3")
      if (!this.featureFlagService.featureFlags[route.data.flags] ) {
        //ole.log("FFGUARDS:", "TEST4")
        route.routeConfig.component = ScsPiePicturesComponent;
      }
      //console.log("FFGUARDS:", "DONE1")
      return true;
    }
    else {
      //console.log("FFGUARDS:", "DONE2")
      return this.featureFlagService.featureFlags[route.data.flags];
    }
  }
}
