import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppPreloadingStrategy } from "./preload-strategy";

const routes: Routes = [
  {
    path: "scsincome",
    loadChildren: () => import("./scsincome/scsincome.module").then((m) => m.ScsIncomeModule),
  },
  {
    path: "scs",
    loadChildren: () => import("./scs/scs.module").then((m) => m.ScsModule),
  },
  {
    path: "home",
    loadChildren: () => import("./core/core.module").then((m) => m.CoreModule),
  },
  {
    path: "rc",
    loadChildren: () => import("./rc/rc.module").then((m) => m.RcModule),
  },
  {
    path: "ie",
    loadChildren: () => import("./ie/ie.module").then((m) => m.IeModule),
  },
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: AppPreloadingStrategy,
    }),
  ],
  exports: [RouterModule],
})

export class AppRoutingModule {}