import { FeatureFlagService } from 'src/app/core/services/feature-flag.service';
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Theme, Orientation, Icon } from "equitable-component-library";
import { UtilityService } from "../../../core/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { ROUTE_PATH, SCS_SERIES } from "../../../constants/constants";
import { ScsIncomeService } from "../../services/scsincome.service";
import { TealiumUtagService } from '../../../tealium/utag.service';  

@Component({
  selector: "app-historical-performance",
  templateUrl: "./historical-performance.component.html",
  styleUrls: ["./historical-performance.component.scss"],
})
export class HistoricalPerformanceComponent implements OnInit {
  @ViewChild("tabs", { read: ElementRef, static: false }) tabs: ElementRef;
  type = Theme.Dark;
  orientation = Orientation.Left;
  backIcon = Icon.BackButton;
  toggleTab: boolean;
  tabsConfig: any = [];
  backButtonSeries =
    this.scsincomeService.series == SCS_SERIES.B ? SCS_SERIES.ADV : "";
  header;
  series;
  usePie: boolean = false;
  constructor(
    private utilityService: UtilityService,
    private translate: TranslateService,
    private scsincomeService: ScsIncomeService,
    private featureService: FeatureFlagService,
    private tealium: TealiumUtagService
  ) { }

  ngOnInit() {
    this.series = this.scsincomeService.series;
    this.translate.get("historicalPerformance").subscribe((translated) => {
      this.tabsConfig = [
        {
          path: ROUTE_PATH.SCSINCOME.TOOLS.SEGMENT_PERFORMANCE,
          label: translated.segmentPerformance,
          isIcon: false,
          tooltipText: "",
        },
        {
          path: ROUTE_PATH.SCSINCOME.TOOLS.HISTORY_GUIDE,
          label: translated.historyMayBeaGuide,
          isIcon: false,
          tooltipText: "",
        },
      ];
    });
    this.usePie = this.featureService.featureFlags.usePie ? true : false;
    console.log("USEPIE", this.usePie)

    // this.tealium.link({
    //   tealium_event: 'link',
    //   event_category: 'BodyClick',
    //   event_name: 'Retirement Guide Clicks',
    //   event_label: 'Click on Historical Performance'
    // })

  }

  backButton() {
    this.utilityService.navigate(ROUTE_PATH.SCSINCOME.TOOLS.HOME);
  }

  public getSelectedTab(value: string): void {
    if (value == "segment-performance") {
      this.header = this.translate.instant(
        "historicalPerformance.segmentPerformance"
      );
      this.toggleTab = false;
    } else {
      this.header = this.translate.instant(
        "historicalPerformance.historyMayBeaGuide"
      );
      this.toggleTab = true;
    }
  }
}
