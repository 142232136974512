import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UtilizeScsComponent } from "./components/utilize-scs/utilize-scs.component";
import { ScsPiePicturesComponent } from "./components/scs-pie-pictures/scs-pie-pictures.component";
import { IonicModule } from "@ionic/angular";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ScsIncomeRoutingModule } from "./scsincome-routing.module";
import { EquitableComponentLibraryModule } from "equitable-component-library";
import { TranslateModule } from "@ngx-translate/core";
import { ScsIncomeService } from "./services/scsincome.service";
import { InvestmentOptionsComponent } from "./components/investment-options/investment-options.component";
import { DiscoverScsComponent } from "./components/discover-scs/discover-scs.component";
import { MarketTrendIndicatorComponent } from "./components/market-trend-indicator/market-trend-indicator.component";
import { SioComponent } from "./components/sio/sio.component";
import { HowSioWorksComponent } from "./components/how-sio-works/how-sio-works.component";
import { ScsAdvancedToolResourcesDashboardComponent } from "./components/scs-advanced-tool-resources-dashboard/scs-advanced-tool-resources-dashboard.component";
import { HistoryGuideComponent } from "./components/history-guide/history-guide.component";
import { HistoricalPerformanceComponent } from "./components/historical-performance/historical-performance.component";
import { SegmentPerformanceComponent } from "./components/segment-performance/segment-performance.component";
import { SioPopupComponent } from "./components/sio-popup/sio-popup.component";
import { ScsIncomeComponent } from "./components/scs-income/scs-income.component";
import { CoreModule } from "../core/core.module";
import { GuaranteedIncomeComponent } from "./components/guaranteed-income/guaranteed-income.component";
import { CustomProfileComponent } from "./components/custom-profile/custom-profile.component";
import { ProtectIncomeComponent } from "./components/protect-income/protect-income.component";
import { PreserveLegacyComponent } from "./components/preserve-legacy/preserve-legacy.component";
import { PreserveLegacyToolsComponent } from "./components/preserve-legacy-tools/preserve-legacy-tools.component";
import { RipComponent } from "./components/rip/rip.component";
import { RbComponent } from "./components/rb/rb.component";

@NgModule({
  declarations: [
    DiscoverScsComponent,
    MarketTrendIndicatorComponent,
    UtilizeScsComponent,
    InvestmentOptionsComponent,
    GuaranteedIncomeComponent,
    ProtectIncomeComponent,
    RipComponent,
    RbComponent,
    ScsPiePicturesComponent,
    ScsIncomeComponent,
    HowSioWorksComponent,
    MarketTrendIndicatorComponent,
    SioComponent,
    ScsAdvancedToolResourcesDashboardComponent,
    HistoryGuideComponent,
    HistoricalPerformanceComponent,
    SegmentPerformanceComponent,
    SioPopupComponent,
    PreserveLegacyComponent,
    ProtectIncomeComponent,
    PreserveLegacyToolsComponent,
    CustomProfileComponent
  ],
  providers: [ScsIncomeService],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    EquitableComponentLibraryModule,
    TranslateModule,
    CoreModule,
    ScsIncomeRoutingModule,
    ReactiveFormsModule
  ],
  exports: [TranslateModule],
  entryComponents: [SioPopupComponent]

})
export class ScsIncomeModule { }
