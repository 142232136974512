<!--THIS IS ACCEL INCOME OPTION-->

<div class="investment-container accelerated-income">
  <div class="feature-section col-12 col-lg-5">
    <div class="content-container">
        
         <div class="description">
              <!-- {{ 'ripData.descriptions.0' | translate }} -->
              The Accelerated Income option provides a higher income when the account value is greater than zero and a lower income if the account value falls to zero, unless the account value is depleted by an excess withdrawal. 
             </div>
        

        <div class="row">
          <div class="col-12">
            <!-- {{ 'ripData.heading' | translate }} -->
            <div class="profile-image col-4"><span class="img-jane"></span></div>
            <div class="col-8"> <h1>Meet Jane</h1></div>
        <div class="row">
        
            <div class="col-12">
                <h4>Current state</h4>
                <ul>
              
              <li>Retired earlier than expected</li>
                </ul>
            </div>

            <div class="col-12">
                <h4>Upcoming concerns</h4>
                <ul>
                  <li>Needs an income before taking income from Social Security</li>
                  <li>Needs higher income in early years of retirement</li>
                </ul>
            </div>
            <div class="col-12">
                <h4>Related strategy</h4>
                <ul>
                  <li>Accelerated Income Option</li>
                </ul>
            </div>
         
        </div>
           

          </div>
        </div>
        <ion-col class="ion-no-padding" size-xl="15" size-md="7" size="15">
        
              
              <!-- <div class="subheading">{{ 'ripData.subHeading' | translate }}</div>
               -->
          

          </ion-col>
     
      
    </div>
    
  </div>
  <div class="chart-section col-12 col-lg-7">
      <div class="chart-container">
        <div class="">
          <img
            alt="Accelerted income"
            src="./assets/images/accelerated.svg"
          />
        </div>
      </div>
  
    </div>


</div>


<div class="row">
    <div class="col-12">
      <div class="disclaimer">This example is a hypothetical intended for illustrative purposes only and is not indicative of the actual performance of any particular product. It assumes no annual resets which may increase future income rates if they occur. </div>
<br>
<ol><li>When Guaranteed Lifetime Withdrawal Benefit (GLWB) is active, an excess ​withdrawal that reduces the Account Value to zero will be treated as a surrender and the pro-rated fees will be subtracted from the withdrawal.</li>

    <li>All income rates are hypothetical. ​</li>
    </ol>
      </div>
  </div>


<!--THIS IS ACCELERATED INCOME OPTION-->
