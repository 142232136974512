import { OnInit, AfterViewChecked } from "@angular/core";
import { FormControl, FormBuilder } from "@angular/forms";
import { Theme, Orientation, Icon, LinkIcon, LinkType, } from "equitable-component-library";
import { UtilityService } from "../../../core/services/utility.service";
import { ScsIncomeService } from "../../services/scsincome.service";
import { ROUTE_PATH, CUSTOMIZATION_TYPE, CUSTOM_PROFILE, } from "../../../constants/constants";
import { HistoryMayBeAGuidePlus, HistoryMayBeAGuidePlusColumns, Profile, } from "../../../core/models/app.models";
import { ModalPopupComponent } from "../../../core/components/modal-popup/modal-popup.component";
import { FeatureFlagService } from "../../../core/services/feature-flag.service";
import { CoreService } from "../../../core/services/core.service";
import { environment } from "src/environments/environment";
import { TealiumUtagService } from '../../../tealium/utag.service';
var ScsPiePicturesComponent = /** @class */ (function () {
    /***
     *  Constructor
     ***/
    function ScsPiePicturesComponent(utilityService, scsincomeService, formBuilder, flagService, coreService, tealium) {
        this.utilityService = utilityService;
        this.scsincomeService = scsincomeService;
        this.formBuilder = formBuilder;
        this.flagService = flagService;
        this.coreService = coreService;
        this.tealium = tealium;
        this.CUSTOMIZATION_TYPE = CUSTOMIZATION_TYPE;
        this.options = [];
        //back link config
        this.backLinkType = Theme.Dark;
        this.backLinkIconOrientation = Orientation.Left;
        this.backLinkIconName = Icon.BackButton;
        //customizeLink link config
        this.customizeLinkIconName = LinkIcon.RightIcon;
        this.customizeLinkIconOrientation = Orientation.Right;
        this.customizeLinkType = LinkType.Dark;
        this.isInitalLoadCompleted = false;
        this.isPrimerica = false;
    }
    /***
     *  Page Load
     ***/
    ScsPiePicturesComponent.prototype.ngOnInit = function () {
        this.isIE = false;
        //if any customization report, profile show buttons
        this.customizationType = this.scsincomeService.customizationType;
        //create form
        this.buildForm();
        //get data for page/dropdowns
        this.getHistoricalPerformanceData();
        this.imageSrcPath = environment.piePicturePath;
        this.series = this.scsincomeService.series;
        console.log(this.series);
        console.log("in scs-pie-picturs this.flagService.featureFlags.isPrimerica", this.flagService.featureFlags.isNotPrimerica);
        this.isPrimerica = this.flagService.featureFlags.isPrimerica;
        this.isNotPlusGuard = !this.flagService.featureFlags.isPlusGuard;
        // this.tealium.link({
        //   tealium_event: 'link',
        //   event_category: 'BodyClick',
        //   event_name: 'Retirement Guide Clicks',
        //   event_label: 'Click on History May Be a Guide'
        // })
    };
    ScsPiePicturesComponent.prototype.ngOnChanges = function () {
        this.series = this.scsincomeService.series;
    };
    //content after load
    ScsPiePicturesComponent.prototype.ngAfterViewChecked = function () {
        if (this.formGroup.get("dropdown1").value &&
            this.formGroup.get("dropdown2").value &&
            this.formGroup.get("dropdown3").value)
            this.isInitalLoadCompleted = true;
    };
    /***
     *  Prepare form for historcal data
     ***/
    ScsPiePicturesComponent.prototype.buildForm = function () {
        var _this = this;
        this.formGroup = this.formBuilder.group({
            dropdown1: new FormControl(""),
            dropdown2: new FormControl(""),
            dropdown3: new FormControl(""),
        });
        //save profie in session
        this.formGroup.valueChanges.subscribe(function (data) {
            if (data.dropdown1 && data.dropdown2 && data.dropdown3)
                _this.saveProfile();
        });
    };
    /***
     *  get historical performance data
     ***/
    ScsPiePicturesComponent.prototype.getHistoricalPerformanceData = function () {
        var _this = this;
        this.utilityService.showLoader();
        this.scsincomeService.getThreePieData(this.isIE).subscribe(function (response) {
            _this.utilityService.hideLoader();
            _this.options = response.pieChartData;
            //set saved profile if required
            if (_this.scsincomeService.customizationType === CUSTOMIZATION_TYPE.PROFILE ||
                _this.scsincomeService.customizationType === CUSTOMIZATION_TYPE.REPORT) {
                _this.scsincomeService.getProfile().subscribe(function (profiles) {
                    if (profiles && profiles.length > 0) {
                        var profile = profiles.find(function (x) {
                            return x.name ===
                                (_this.scsincomeService.customizationType ==
                                    CUSTOMIZATION_TYPE.PROFILE
                                    ? _this.scsincomeService.currentProfile
                                    : CUSTOMIZATION_TYPE.REPORT) &&
                                x.currentSerie === _this.scsincomeService.series;
                        });
                        if (profile && profile.historyMayBeAGuideIncome) {
                            _this.loadOptions(profile.historyMayBeAGuideIncome.columnPlus1.id, profile.historyMayBeAGuideIncome.columnPlus2.id, profile.historyMayBeAGuideIncome.columnPlus3.id);
                            _this.formGroup
                                .get("dropdown1")
                                .setValue(profile.historyMayBeAGuideIncome.columnPlus1.id);
                            _this.formGroup
                                .get("dropdown2")
                                .setValue(profile.historyMayBeAGuideIncome.columnPlus2.id);
                            _this.formGroup
                                .get("dropdown3")
                                .setValue(profile.historyMayBeAGuideIncome.columnPlus3.id);
                        }
                        else if (!_this.flagService.featureFlags.isIR) {
                            _this.loadOptions(_this.options[0].id, _this.options[1].id, _this.options[2].id);
                        }
                        else {
                            _this.loadOptions(_this.options[0].id, _this.options[4].id, _this.options[8].id);
                        }
                    }
                    else if (!_this.flagService.featureFlags.isIR) {
                        _this.loadOptions(_this.options[0].id, _this.options[1].id, _this.options[2].id);
                    }
                    else {
                        _this.loadOptions(_this.options[0].id, _this.options[4].id, _this.options[8].id);
                    }
                });
            }
            else if (!_this.flagService.featureFlags.isIR) {
                _this.loadOptions(_this.options[0].id, _this.options[1].id, _this.options[2].id);
            }
            else if (_this.scsincomeService.report.historyMayBeAGuidePlus) {
                _this.loadOptions(_this.scsincomeService.report.historyMayBeAGuideIncome.columnPlus1.id, _this.scsincomeService.report.historyMayBeAGuideIncome.columnPlus2.id, _this.scsincomeService.report.historyMayBeAGuideIncome.columnPlus3.id);
            }
            else {
                _this.loadOptions(_this.options[0].id, _this.options[4].id, _this.options[8].id);
            }
        });
    };
    /***
     *  Load default options
     ***/
    ScsPiePicturesComponent.prototype.loadOptions = function (option1, option2, option3) {
        this.formGroup.get("dropdown1").setValue(option1);
        this.formGroup.get("dropdown2").setValue(option2);
        this.formGroup.get("dropdown3").setValue(option3);
    };
    /***
     *  back Button
     ***/
    ScsPiePicturesComponent.prototype.onBack = function () {
        this.utilityService.navigate(ROUTE_PATH.SCSINCOME.TOOLS.HOME);
    };
    /***
     *  Save data for customize report
     ***/
    ScsPiePicturesComponent.prototype.saveProfile = function (mode) {
        var _this = this;
        var historyMayBeAGuideIncome = new HistoryMayBeAGuidePlus();
        Object.keys(this.formGroup.controls).forEach(function (element, index) {
            var obj = _this.options.find(function (x) { return x.id == _this.formGroup.get(element).value; });
            historyMayBeAGuideIncome["columnPlus" + (index + 1)] = new HistoryMayBeAGuidePlusColumns();
            historyMayBeAGuideIncome["columnPlus" + (index + 1)].url = obj.chartImage;
            historyMayBeAGuideIncome["columnPlus" + (index + 1)].label = obj.value;
            historyMayBeAGuideIncome["columnPlus" + (index + 1)].id = obj.id;
        });
        if (this.isInitalLoadCompleted &&
            this.scsincomeService.customizationType === CUSTOMIZATION_TYPE.INVESTOR_PROFILE) {
            this.coreService.profile.next({
                name: "",
                type: CUSTOMIZATION_TYPE.REPORT,
                profile: new Profile(),
            });
            this.scsincomeService.customizationType = CUSTOMIZATION_TYPE.REPORT;
            this.customizationType = CUSTOMIZATION_TYPE.REPORT;
        }
        //navigate to customization page
        if (this.customizationType === CUSTOMIZATION_TYPE.PROFILE) {
            this.scsincomeService.storeProfile(CUSTOM_PROFILE.HISTORICAL_PERFORMANCE_PLUS, historyMayBeAGuideIncome);
        }
        else {
            //save for session
            this.scsincomeService.report.historyMayBeAGuidePlus = historyMayBeAGuideIncome;
            this.scsincomeService.storeProfile(CUSTOM_PROFILE.HISTORICAL_PERFORMANCE_PLUS, historyMayBeAGuideIncome, true);
        }
        //navigate
        if (mode && mode === CUSTOMIZATION_TYPE.PROFILE) {
            this.utilityService.presentWithoutTitleModal(ModalPopupComponent);
        }
        else if (mode && mode === CUSTOMIZATION_TYPE.REPORT) {
            this.utilityService.navigate(ROUTE_PATH.SCSINCOME.TOOLS.CREATE_REPORT);
        }
    };
    return ScsPiePicturesComponent;
}());
export { ScsPiePicturesComponent };
