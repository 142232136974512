import { TOOLS_NAME } from "./../../../constants/constants";
import { FeatureFlagService } from "./../../../core/services/feature-flag.service";
import { Component, OnInit } from "@angular/core";
import { Location } from '@angular/common'; 

import {
  LinkType,
  BoxButtonIcon,
  LinkIcon,
  Orientation,
} from "equitable-component-library";
import { 
  DiscoverRc,
  Kits,
  Dictionary,
  Profile, } from "../../../core/models/app.models";
import { UtilityService } from "../../../core/services/utility.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import {
  IE_SERIES,
  ROUTE_PATH,
  PRODUCT_TYPE,
  CUSTOMIZATION_TYPE,
  CONFIG_DATA,
} from "../../../constants/constants";
import { SITE } from "src/assets/sites";
import { IeService } from "../../services/ie.service";
import { ModalPopupComponent } from 'src/app/core/components/modal-popup/modal-popup.component';
import { CoreService } from "../../../core/services/core.service";
import { TealiumUtagService } from '../../../tealium/utag.service';  
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-ie-advanced-tool-resources-dashboard",
  templateUrl: "./ie-advanced-tool-resources-dashboard.component.html",
  styleUrls: ["./ie-advanced-tool-resources-dashboard.component.scss"],
})
export class IeAdvancedToolResourcesDashboardComponent implements OnInit {
  LinkType = LinkType;
  LinkIcon = LinkIcon;
  Orientation = Orientation;
  learningResources: Kits[];
  toolTipText: string;
  dashboardForm: FormGroup;
  seriesOptions: Dictionary[];
  isDisplay = true;
  isIR = CONFIG_DATA.ISIR;
  isNotPlusGuard: boolean;
  toolsDashboardData: DiscoverRc = {
    resource: [],
    tools: [],
  };
  investorProfiles: string;
  getSeries: string = this.ieService.series || IE_SERIES.B;
  site = SITE;
  headingName: string;
 
  constructor(
    private utilityService: UtilityService,
    private translate: TranslateService,
    private ieService: IeService,
    private flagService: FeatureFlagService,
    private coreService: CoreService,
    private formBuilder: FormBuilder,
    private location: Location,
    private tealium: TealiumUtagService
  ) {}

  ngOnInit() {
   this.dashboardForm = this.formBuilder.group({
      seriesDropDown: new FormControl(
        { value: this.getSeries, disabled: false },
        Validators.required
      ),
    }); 
    //reset selection
    if (this.coreService.product !== PRODUCT_TYPE.IE) {
      this.coreService.profile.next({
        name: "",
        type: CUSTOMIZATION_TYPE.REPORT,
        profile: new Profile(),
      });
      this.coreService.report = new Profile();
    }
    this.coreService.product = PRODUCT_TYPE.IE;

    this.dashboardForm.valueChanges.subscribe((data) => {
      //reset report data (if any)
      this.ieService.report = new Profile();
      this.ieService.customizationType = CUSTOMIZATION_TYPE.REPORT;
      //reset selected profile
      this.ieService.currentProfile = "";
      this.dropDownSection(data.seriesDropDown);
      this.coreService.profile.next({
        name: "",
        type: CUSTOMIZATION_TYPE.REPORT,
        profile: new Profile(),
      });
    });


        // Dropdown Data
      this.translate.get("ieDashboard").subscribe((translated) => {
          this.toolTipText = translated.tooltipText;
          this.investorProfiles = translated.investorProfiles;
    
          this.seriesOptions = [];
    
          if (this.flagService.featureFlags.isPrimerica) {
            this.seriesOptions = [
            
              {
                id: IE_SERIES.B,
                value: translated.seriesB,
              },
            ];
          } 
          else {
            this.seriesOptions = [
                
    
              {
                id: IE_SERIES.B,
                value: translated.seriesB,
              },
          
              {
                id: IE_SERIES.B,
                value: translated.seriesB,
              },
            ];
          }
    
    
         this.isNotPlusGuard = !this.flagService.featureFlags.isPlusGuard
    
    
          for (const toolName in this.flagService.featureFlags.ie.tools) {
            switch (toolName) {
            
              case TOOLS_NAME.HOW_SIO_WORKS:
                if (
                  this.flagService.featureFlags.ie.tools[TOOLS_NAME.HOW_SIO_WORKS]
                ) {
                  this.toolsDashboardData.tools.push({
                    icon: BoxButtonIcon.HowSIOWorks,
                    label: translated.howItWorks,
                    routePath: ROUTE_PATH.IE.TOOLS.HOW_SIO_WORKS,
                  });
                }
                break;
            
                case TOOLS_NAME.CREATE_A_REPORT:
                  if (
                    this.flagService.featureFlags.ie.tools[
                    TOOLS_NAME.CREATE_A_REPORT
                    ]
                  ) {
                    console.log('CARDCLICK this.IeService.series', this.ieService.series)
                  
                    this.toolsDashboardData.tools.push({
                      icon: BoxButtonIcon.CreateAReport,
                      label: translated.createReport,
                      routePath: ROUTE_PATH.IE.TOOLS.CREATE_REPORT,
                    });
                  }
                  break;
              
               
    
              case TOOLS_NAME.HISTORICAL_PERFORMANCE:
                if (
                  this.flagService.featureFlags.ie.tools[
                  TOOLS_NAME.HISTORICAL_PERFORMANCE
                  ]
                ) {
                  console.log('CARDCLICK this.IeService.series', this.ieService.series)
                
                  this.toolsDashboardData.tools.push({
                    icon: BoxButtonIcon.HistoricalPerformance,
                    label: translated.historicalPerformance,
                    routePath: ROUTE_PATH.IE.TOOLS.HISTORICAL_PERFORMANCE,
                  });
                }
                break;
               
              case TOOLS_NAME.PRODUCT_OVERVIEW:
                if (
                  this.flagService.featureFlags.ie.tools[
                  TOOLS_NAME.PRODUCT_OVERVIEW
                  ]
                ) {
                  this.toolsDashboardData.tools.push({
                    icon: BoxButtonIcon.ProductOverview,
                    label: translated.productOverview,
                    routePath: ROUTE_PATH.IE.DISCOVER,
                  });
                }
               
                break;
                case TOOLS_NAME.INVESTOR_PROFILE:
                  if (
                    this.flagService.featureFlags.ie.tools[
                    TOOLS_NAME.INVESTOR_PROFILE
                    ]
                  ) {
                    this.toolsDashboardData.tools.push({
                      icon: BoxButtonIcon.InvestorProfiles,
                      label: translated.investorProfiles,
                      routePath: ROUTE_PATH.IE.TOOLS.INVESTOR_PROFILE,
                    });
                  }
                  break;
                default:
                  break;
           
            }
          }
    
          if (this.getSeries === IE_SERIES.B) {
            this.headingName = translated.headerTitlePlus;
          } 
        
          else {
            this.headingName = translated.headerTitle;
          }
        });


    this.toolsDashboardData.tools = [
      {
        icon: BoxButtonIcon.Calculator,
        label: "ieDashbaord.tools.taxCalculators",
        routePath: ROUTE_PATH.IE.TOOLS.TAX_DEFERRAL,
      },
      {
        icon: BoxButtonIcon.HowSIOWorks,
        label: "ieDashbaord.tools.diversification",
        routePath: ROUTE_PATH.IE.TOOLS.DIVERSIFICATION,
      },
      {
        icon: BoxButtonIcon.HowSIOWorks,
        label: "ieDashbaord.tools.taxEfficientIncome",
        routePath: ROUTE_PATH.IE.TOOLS.HOW_IT_WORKS,
      },
      {
        icon: BoxButtonIcon.HowSIOWorks,
        label: "How SIO works",
        routePath: ROUTE_PATH.IE.TOOLS.HOW_SIO_WORKS,
      },
      {
        icon: BoxButtonIcon.CreateAReport,
        label: "ieDashbaord.tools.createReport",
        routePath: ROUTE_PATH.IE.TOOLS.CREATE_REPORT,
      },
      {
        icon: BoxButtonIcon.HistoricalPerformance,
        label: "Market history",
        routePath: ROUTE_PATH.IE.TOOLS.HISTORICAL_PERFORMANCE_PLUS,
      },
      {
        icon: BoxButtonIcon.InvestorProfiles,
        label: "ieDashbaord.tools.investorProfiles",
        routePath: ROUTE_PATH.IE.TOOLS.INVESTOR_PROFILE,
      },
      {
        icon: BoxButtonIcon.PerformanceCapRates,
        label: "Performance cap rate",
        routePath: ROUTE_PATH.IE.TOOLS.MARKET_TREND,
        newTab: true,
      },
      {
        icon: BoxButtonIcon.ProductOverview,
        label: "ieDashbaord.tools.productOverview",
        routePath: ROUTE_PATH.IE.DISCOVER,
      },
      {
        icon: "growth-opportunities.svg",
        label: "ieDashbaord.tools.fundFocus",
        routePath: ROUTE_PATH.IE.TOOLS.FUND_FOCUS,
      },
    ];

    this.getResource(this.getSeries);
  }
  dropDownSection(seriesDropDown: any) {
    throw new Error("Method not implemented.");
  }

  getResource(data) {

    this.ieService.getIEResources(data).subscribe((kits) => {
      this.learningResources = kits;
      console.log('IE SERIES =' + " "  + this.ieService.series);
    
    });
  }

  // On click Card button
/*   cardClick(link: string) {

    this.utilityService.navigate(link, {
      section: "ie",
    });
  } */

 /*   // On click Card button
   cardClick(link: string, label: string) {
    console.log('CARDCLICK link', link)
    console.log('CARDCLICK label ', link)
    console.log('CARDCLICK this.ieService.series ', this.ieService.series)
  
    this.utilityService.navigate(link, {
        section: "ie"
      });
    
  } */


    // On click Card button
    cardClick(link: string, label: string, newTab?: boolean) {
      if(newTab) {
        window.open(link, '_blank');
      }
      else {
        if (label === this.investorProfiles) {
          this.utilityService.presentWithoutTitleModal(ModalPopupComponent);
        } else {
          this.utilityService.navigate(link, {
            section: "ie"
          });
        }
      }
    }

  // openExternalLink
  goToResources(resource) {
    this.utilityService.visitEquitable(resource.file);
  }

  // back button navigation
  backButton() {
    this.location.back()
//    this.utilityService.navigate(ROUTE_PATH.HOME);
  }

  goToScsLandingPage(): void {
    this.utilityService.navigateWithoutParams(ROUTE_PATH.SCS.TOOLS.HOME);
  }
  goToScsIncomeLandingPage(): void {
    this.utilityService.navigateWithoutParams(ROUTE_PATH.SCSINCOME.TOOLS.HOME);
  }

  goToRcLandingPage() {
    this.utilityService.navigateWithoutParams(ROUTE_PATH.RC.TOOLS.HOME);
  }
}
