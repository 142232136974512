<!--THIS IS BOOST YOUR INCOME-->
<!--THIS IS ACCEL INCOME OPTION-->

<div class="investment-container boost-income">
  <div class="feature-section col-12 col-lg-4">
    <div class="container">

      <h1>
        Opportunities to increase your income
      </h1>
      
      <div class="row">

        <div class="justify-center col-sm">
          <h3>Annual Reset</h3>
          <div class="description">
            <!-- {{ 'ripData.descriptions.0' | translate }} -->

            Lock in market gains with automatic Annual Resets
          </div>

        </div>

        <div class="justify-center col-sm">
          <span class="icon-plus" id="plus"></span>
        </div>

        <div class="justify-center col-sm">
          <h3>Deferral Incentive</h3>

          <!-- <div class="subheading">{{ 'ripData.subHeading' | translate }}</div>
                 -->

          <div class="description">
            Get 6% simple interest just for waiting
            <!-- {{ 'ripData.descriptions.1' | translate }} -->
          </div>
        </div>
      </div>

      <div class="justify-center">
        <span class="icon-equals" id="equals"></span>
        <h3>Reset Boost*</h3>
        <div class="description">
          Innovative feature only offered by Equitable Financial that combines the Annual Reset and Deferral Incentive
          so that both apply the same year, and you receive more income in retirement.
        </div>
      </div>
    </div>

  </div>
  <div class="col-lg-1"></div>
  <div class="chart-section col-12 col-lg-7">
    <div class="chart-container">
      <img alt="Accelerted income" style="width:100% !important;" src="./assets/images/boost.svg" />
    </div>
  </div>
</div>


<div class="row">
  <div class="col-12">
    <div class="disclaimer">
      3. Illustration assumes no withdrawals.
      <br><br>
      * Owners should consider when to take the first withdrawal under the contract, since doing so will prohibit the
      owner from making any further contributions under the contract, and will also terminate all Deferral Incentives
      and income rate increases in connection with Annual Resets under the GLWB rider, which could significantly limit
      increases in the values under the GLWB as well as increases in the contract's account value and death benefit.
    </div>
  </div>
</div>


<!--THIS IS BOOST YOUR INCOME-->