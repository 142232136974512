import { OnInit } from "@angular/core";
import { ScsIncomeService } from "../../services/scsincome.service";
import { take } from "rxjs/operators";
import { UtilityService } from "src/app/core/services/utility.service";
var GuaranteedIncomeComponent = /** @class */ (function () {
    function GuaranteedIncomeComponent(ScsIncomeService, utilityService) {
        this.ScsIncomeService = ScsIncomeService;
        this.utilityService = utilityService;
        this.chartImage = "./assets/images/guaranteed-income-for-life.png";
    }
    GuaranteedIncomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.utilityService.showLoader();
        this.ScsIncomeService
            .fetchScsIncomeData("GuaranteedIncomeForLifeJSON")
            .pipe(take(1))
            .subscribe(function (chartData) {
            _this.utilityService.hideLoader();
            _this.takingIncomeRate = '5%';
            _this.deferralRate = chartData.deferralRate;
            _this.date = '3/29/2021';
        });
    };
    return GuaranteedIncomeComponent;
}());
export { GuaranteedIncomeComponent };
