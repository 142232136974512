import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { UtilityService } from "../../../core/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import {
  ROUTE_PATH,
  IE_SERIES,
  CUSTOMIZATION_TYPE,
  CHARACTER_LIMIT,
  PRODUCT_TYPE,
  VALIDATION_TYPE,
} from "../../../constants/constants";
import {
  Profile,
  HistoryMayBeAGuide,
  HistoryMayBeAGuideColumn,
  SCSHowSioWorks,
} from "../../../core/models/app.models";
import { SITE } from "src/assets/sites";
import { CoreService } from "../../../core/services/core.service";
import { IeService } from "../../services/ie.service";
import { TealiumUtagService } from '../../../tealium/utag.service';
import { FeatureFlagService } from "src/app/core/services/feature-flag.service";

@Component({
  selector: "app-report-ie",
  templateUrl: "./report-ie.component.html",
  styleUrls: ["./report-ie.component.scss"],
})
export class ReportIeComponent implements OnInit {
  //Variables
  route = ROUTE_PATH.IE.TOOLS;
  formGroup: FormGroup;
  maxlength = CHARACTER_LIMIT.INPUT;
  resourceMaterials = [];
  customeContents = [];
  series = "";
  backButtonText;
  backButtonSeries =
    this.ieService.series === IE_SERIES.B ? IE_SERIES.B : "";
  isNotPlusGuard: boolean;

  /***
   *  constructor
   ***/
  constructor(
    private formBuilder: FormBuilder,
    private utilityService: UtilityService,
    private translate: TranslateService,
    private coreService: CoreService,
    private ieService: IeService,
    private featureService: FeatureFlagService,
    private tealium: TealiumUtagService,
  ) { }

  /***
   *  Page Init
   ***/
  ngOnInit() {
    this.isNotPlusGuard = !this.featureService.featureFlags.isPlusGuard;
    this.series = this.ieService.series;
    this.translate.get("createReport").subscribe((translated) => {
      if (this.series === IE_SERIES.B) {
        this.backButtonText = translated.backButtonTextIE;
      } 
      
    });

    //create form
    this.buildForm();

    //get page data
    this.getPageData();
  }

  /***
   *  Page Load/Init
   ***/
  ionViewWillEnter() {
    this.series = this.ieService.series;
    //create form
    this.buildForm();

    //get page data
    this.getPageData();
  }

  /***
   *  Back button event. Go back to tool home page
   ***/
  backButton() {
    this.utilityService.navigate(ROUTE_PATH.IE.TOOLS.HOME);
  }

  /***
   *  Create form
   ***/
  buildForm() {
    this.formGroup = this.formBuilder.group({
      professionalName: new FormControl({ 
        value: this.coreService.report.profName, 
        disabled: false 
      }),
      name: new FormControl(this.coreService.report.name),
      firmName: new FormControl(this.coreService.report.firmName),
      contactNumber: new FormControl(this.coreService.report.contactNumber),
      structuredInvestment: new FormControl({
        value: false,
        disabled: this.ieService.report.aboutSCSHowSioWorks ? false : true,
      }),
      historicalPerformance: new FormControl({
        value: false,
        disabled: this.ieService.report.historicalPerformance ? false : true,
      }),
      historyGuide: new FormControl({
        value: false,
        disabled: this.ieService.report.historyMayBeAGuide ? false : true,
      }),
      historicalPerformancePlus: new FormControl({
        value: false,
        disabled: this.ieService.report.historyMayBeAGuidePlus ? false : true,
      })
    });

    //save data to service using form change value
    this.formGroup.valueChanges.subscribe((data) => {
      this.coreService.report.name = data.name;
      this.coreService.report.firmName = data.firmName;
      this.coreService.report.contactNumber = data.contactNumber;
      this.coreService.report.profName = data.professionalName;
    });
  }

  /***
   *  get Data for resources
   ***/
  getPageData() {


    this.ieService.getIEResources(this.ieService.series).subscribe((res) => {
    // this.resourceMaterials = res;
      // //add more form control dynamically to track the resources
      // res.forEach((element) => {
      //   const control = new FormControl(element.description);
      //   control.setValue(false);
      //   this.formGroup.addControl(element.description, control);
      // });
      this.resourceMaterials = res;
      
      this.resourceMaterials.forEach((element) => {
        const control = new FormControl(element.description);
        control.setValue(false);
        this.formGroup.addControl(element.description, control);
      });
    
      //add custome content its dynamic based on series B,C, ADV, PLUS
      const customeContents = [
        {
          formControlName: "structuredInvestment",
          formControl: this.formGroup.get("structuredInvestment"),
          description: this.translate.instant(
            "createReport.checkboxDescription1"
          ),
          link: {
            label: this.ieService.report.aboutSCSHowSioWorks
              ? this.translate.instant("createReport.edit")
              : this.translate.instant("createReport.customize"),
            path: "/" + ROUTE_PATH.IE.TOOLS.HOW_SIO_WORKS,
          },
          imagePath: "./assets/images/report-checkbox.jpg",
          reportKey: "aboutSCSHowSioWorks",
        },
        {
          formControlName: "historicalPerformancePlus",
          formControl: this.formGroup.get("historicalPerformancePlus"),
          description: this.translate.instant(
            "createReport.checkboxDescription3"
          ),
          link: {
            label: this.ieService.report.historyMayBeAGuidePlus
              ? this.translate.instant("createReport.edit")
              : this.translate.instant("createReport.customize"),
            path: "/" + ROUTE_PATH.IE.TOOLS.HISTORICAL_PERFORMANCE_PLUS,
          },
          imagePath: "./assets/images/report-checkbox4.jpg",
          reportKey: "historyMayBeAGuidePlus",
        }
      ];

      //sppecial fix for primerica
      if (this.featureService.featureFlags.report === "PRIMERICA_REPORT") {
        customeContents[2].imagePath = "./assets/images/report-checkbox4.jpg";
        customeContents[2].formControlName = "historicalPerformancePlus";
        customeContents[2].formControl = this.formGroup.get(
          "historicalPerformancePlus"
        );
        customeContents[2].link.label = this.ieService.report
          .historyMayBeAGuidePlus
          ? this.translate.instant("createReport.edit")
          : this.translate.instant("createReport.customize");
        customeContents[2].reportKey = "historyMayBeAGuidePlus";
      }

      if (
        this.ieService.customizationType === CUSTOMIZATION_TYPE.PROFILE ||
        this.ieService.customizationType === CUSTOMIZATION_TYPE.REPORT
      ) {
        this.ieService.getProfile().subscribe((profiles) => {
          if (profiles && profiles.length > 0) {
            const profile = profiles.find(
              (x) =>
                x.name ===
                  (this.ieService.customizationType ==
                  CUSTOMIZATION_TYPE.PROFILE
                    ? this.ieService.currentProfile
                    : CUSTOMIZATION_TYPE.REPORT) &&
                x.currentSerie === this.ieService.series
            );
            if (profile) {
              this.ieService.report = profile;
            } else {
              this.ieService.report = new Profile();
            }

            customeContents.forEach((element) => {
              if (this.ieService.report[element.reportKey]) {
                this.formGroup.get(element.formControlName).enable();
              } else {
                this.formGroup.get(element.formControlName).disable();
              }
            });
          }
        });
      }

      //if series plus then only how-sio-works and pie charts
      if (
        this.ieService.series === IE_SERIES.B
      ) {
        customeContents.splice(3, 1);
      } 

      this.customeContents = customeContents;
      this.utilityService.hideLoader();
    });
  }

  /***
   *  get Data for resources
   ***/
  generatePDF() {
    if (this.formGroup.valid) {
      const utilizeData = new Profile();
      utilizeData.sourceURL = SITE.AXA;
      utilizeData.name = this.formGroup.get("name").value;
      utilizeData.profName = this.formGroup.get("professionalName").value;
      utilizeData.firmName = this.formGroup.get("firmName").value;
      utilizeData.contactNumber = this.formGroup.get("contactNumber").value;

      //add section to the report if applicable
      const str = [];
      this.customeContents.forEach((element) => {
        if (element.formControl.value === true) {
          //add section key to array. PDF generate will based on that
          str.push(element.reportKey);
          //add section data

          utilizeData[element.reportKey] = this.ieService.report[
            element.reportKey
          ];
        }
      });

      //minor fix for remove space
      if (utilizeData.aboutSCSHowSioWorks) {
        utilizeData.aboutSCSHowSioWorks.segmentOption = utilizeData.aboutSCSHowSioWorks.segmentOption
          .replace(/\s/g, "")
          .toLowerCase();

        utilizeData.aboutSCSHowSioWorks.index = utilizeData.aboutSCSHowSioWorks.index.replace(
          "®",
          ""
        );
      }

      //change string array to string to
      utilizeData.sectionsName = str.toString()

      //add learning resources
      const obj = {};
      this.resourceMaterials.forEach((element) => {
        if (this.formGroup.get(element.description).value === true) {
          obj[element.description] = element.file;
        }
      });
      utilizeData.learningResourcesIncluded = obj;

      //add series name
      utilizeData.currentSerie = this.ieService.series;

      //sppecial fix for primerica. Series B but behave like PLUS
      if (
        (this.featureService.featureFlags.report === "PRIMERICA_REPORT" ||
          this.featureService.featureFlags.report === "WF_REPORT") &&
        utilizeData.historyMayBeAGuidePlus
      ) {
        utilizeData.historyMayBeAGuide = new HistoryMayBeAGuide();
        Object.keys(utilizeData.historyMayBeAGuidePlus).forEach(
          (element, index) => {
            utilizeData.historyMayBeAGuide[
              "column" + (index + 1)
            ] = new HistoryMayBeAGuideColumn();
            utilizeData.historyMayBeAGuide["column" + (index + 1)].label =
              utilizeData.historyMayBeAGuidePlus[
                "columnPlus" + (index + 1)
              ].label;
            utilizeData.historyMayBeAGuide["column" + (index + 1)].url =
              utilizeData.historyMayBeAGuidePlus[
                "columnPlus" + (index + 1)
              ].url;
          }
        );
        delete utilizeData.historyMayBeAGuidePlus;
        utilizeData.sectionsName = utilizeData.sectionsName.replace(
          "historyMayBeAGuidePlus",
          "historyMayBeAGuide"
        );
      }

      if (
        this.featureService.featureFlags.report === "WF_REPORT" &&
        utilizeData.aboutSCSHowSioWorks
      ) {
        /** for wells fargo create a report, create report API is not expecting data.
         * it seem everything is hardcoded in API and absed on some key value PDF generated
         * even bar values are in correct
         *  */
        // Object.keys(utilizeData.aboutSCSHowSioWorks.bars).forEach((element) => {
        //   console.log("UTILIZE utilizeData.aboutSCSHowSioWorks.bars[element]", element, utilizeData.aboutSCSHowSioWorks.bars[element])
        //   utilizeData.aboutSCSHowSioWorks.bars[element] = 0; //for e.g. every bar value changed to 0 stil working.
        // });

        if (
          utilizeData.aboutSCSHowSioWorks.duration === "6yr" &&
          (utilizeData.aboutSCSHowSioWorks.index
            .toUpperCase()
            .indexOf("S&P") !== -1 ||
            utilizeData.aboutSCSHowSioWorks.index
              .toUpperCase()
              .indexOf("RUSSELL") !== -1)
        ) {
          // API demand some data  on specific format
          if (
            utilizeData.aboutSCSHowSioWorks.index
              .toUpperCase()
              .indexOf("RUSSELL") !== -1
          ) {
            //API has not even check cases. it need to be in specific case
            utilizeData.aboutSCSHowSioWorks.index = "RUSSELL 2000 6 Yr";
          } else if (
            utilizeData.aboutSCSHowSioWorks.index
              .toUpperCase()
              .indexOf("S&P") !== -1
          ) {
            utilizeData.aboutSCSHowSioWorks.index = "S&P 500 6 Yr";
          }
        } else if (utilizeData.aboutSCSHowSioWorks.duration === "1yr") {
          if (
            utilizeData.aboutSCSHowSioWorks.index
              .toUpperCase()
              .indexOf("RUSSELL") !== -1
          ) {
            //API has not even check cases. it need to be in specific case
            utilizeData.aboutSCSHowSioWorks.index = "RUSSELL 2000 1 Yr";
          } else if (
            utilizeData.aboutSCSHowSioWorks.index
              .toUpperCase()
              .indexOf("S&P") !== -1
          ) {
            utilizeData.aboutSCSHowSioWorks.index = "S&P 500 1 Yr";
          }
        }
      }

 //generate PDF
 
      this.coreService.pdfFetchResources(utilizeData, PRODUCT_TYPE.IE);
    } else {
      this.utilityService.showValidationErrorForControls(this.formGroup);
    }

    // add tealium_event: "Generate_PDF" ?
    this.tealium.link({
      event_name: "Retirement Guide Clicks - IE",
      tool_name: "BodyClicks",
      event_label: "Create a report"
    })
  }
}
