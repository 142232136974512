import { FILE_EXTENSION } from "../../constants/constants";
import * as i0 from "@angular/core";
var FileUrlService = /** @class */ (function () {
    function FileUrlService() {
        this.urls = [];
    }
    FileUrlService.prototype.scanForUrl = function (data, baseURL) {
        if (Array.isArray(data)) {
            for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
                var el = data_1[_i];
                if (typeof el === "string") {
                    this.saveUrl(el, baseURL);
                }
                else {
                    this.scanForUrl(el, baseURL);
                }
            }
        }
        else if (data.constructor === Object) {
            for (var _a = 0, _b = Object.entries(data); _a < _b.length; _a++) {
                var _c = _b[_a], _ = _c[0], value = _c[1];
                if (typeof value === "string") {
                    this.saveUrl(value, baseURL);
                }
                else {
                    this.scanForUrl(value, baseURL);
                }
            }
        }
    };
    FileUrlService.prototype.saveUrl = function (data, baseURL) {
        if (baseURL && data.indexOf("http") == -1) {
            data = baseURL + data;
        }
        var urlPattern = new RegExp(".+." + FILE_EXTENSION.PNG + "|" + FILE_EXTENSION.JSON + "|" + FILE_EXTENSION.XML + "|" + FILE_EXTENSION.JPG + "|" + FILE_EXTENSION.JPEG + "|" + FILE_EXTENSION.PDF + "$", "i");
        if (data.match(urlPattern)) {
            this.urls.push(data);
        }
    };
    FileUrlService.prototype.getAllUrls = function () {
        var _this = this;
        var uniqueFiles = this.urls.filter(function (item, index) {
            return _this.urls.indexOf(item) === index;
        });
        this.urls = uniqueFiles;
        return uniqueFiles;
    };
    FileUrlService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileUrlService_Factory() { return new FileUrlService(); }, token: FileUrlService, providedIn: "root" });
    return FileUrlService;
}());
export { FileUrlService };
