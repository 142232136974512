import { TOOLS_NAME } from "./../../../constants/constants";
import { FeatureFlagService } from "./../../../core/services/feature-flag.service";
import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";

import {
  LinkType,
  LinkIcon,
  Orientation,
  BoxButtonIcon,
} from "equitable-component-library";
import {
  DiscoverRc,
  Kits,
  Dictionary,
  Profile,
} from "../../../core/models/app.models";
import { UtilityService } from "../../../core/services/utility.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import {
  SCSINCOME_SERIES,
  ROUTE_PATH,
  PRODUCT_TYPE,
  CUSTOMIZATION_TYPE,
  CONFIG_DATA,
} from "../../../constants/constants";
import { SITE } from "src/assets/sites";
import { ScsIncomeService } from "../../services/scsincome.service";
import { ModalPopupComponent } from "../../../core/components/modal-popup/modal-popup.component";
import { CoreService } from "../../../core/services/core.service";
import { TealiumUtagService } from '../../../tealium/utag.service';  

@Component({
  selector: "app-scs-advanced-tool-resources-dashboard",
  templateUrl: "./scs-advanced-tool-resources-dashboard.component.html",
  styleUrls: ["./scs-advanced-tool-resources-dashboard.component.scss"],
})
export class ScsAdvancedToolResourcesDashboardComponent implements OnInit {
  LinkType = LinkType;
  LinkIcon = LinkIcon;
  Orientation = Orientation;
  learningResources: Kits[];
  toolTipText: string;
  dashboardForm: FormGroup;
  seriesOptions: Dictionary[];
  isDisplay = true;
  isIR = CONFIG_DATA.ISIR;
  isNotPlusGuard: boolean;
  toolDashboardData: DiscoverRc = {
    tools: [],
  };
  investorProfiles: string;
  getSeries: string = this.scsIncomeService.series || SCSINCOME_SERIES.B || SCSINCOME_SERIES.ADV;
  site = SITE;
  headingName: string;

  constructor(
    private utilityService: UtilityService,
    private translate: TranslateService,
    private scsIncomeService: ScsIncomeService,
    private flagService: FeatureFlagService,
    private coreService: CoreService,
    private formBuilder: FormBuilder,
    private location: Location,
    private tealium: TealiumUtagService
  ) { }

  ngOnInit() {
    this.dashboardForm = this.formBuilder.group({
      seriesDropDown: new FormControl(
        { value: this.getSeries, disabled: false },
        Validators.required
      ),
    });

    //reset selection
    if (this.coreService.product !== PRODUCT_TYPE.SCSINCOME) {
      this.coreService.profile.next({
        name: "",
        type: CUSTOMIZATION_TYPE.REPORT,
        profile: new Profile(),
      });
      this.coreService.report = new Profile();
    }
    this.coreService.product = PRODUCT_TYPE.SCSINCOME;

    this.dashboardForm.valueChanges.subscribe((data) => {
      //reset report data (if any)
      this.scsIncomeService.report = new Profile();
      this.scsIncomeService.customizationType = CUSTOMIZATION_TYPE.REPORT;
      //reset selected profile
      this.scsIncomeService.currentProfile = "";
      this.dropDownSection(data.seriesDropDown);
      this.coreService.profile.next({
        name: "",
        type: CUSTOMIZATION_TYPE.REPORT,
        profile: new Profile(),
      });
    });

    // Dropdown Data
    this.translate.get("scsincomeDashboard").subscribe((translated) => {
      this.toolTipText = translated.tooltipText;
      this.investorProfiles = translated.investorProfiles;

      this.seriesOptions = [];

      if (this.flagService.featureFlags.isPrimerica) {
        this.seriesOptions = [
        
          {
            id: SCSINCOME_SERIES.B,
            value: translated.seriesB,
          },
        ];
      } 
      else {
        this.seriesOptions = [
            

          {
            id: SCSINCOME_SERIES.B,
            value: translated.seriesB,
          },
      
          {
            id: SCSINCOME_SERIES.ADV,
            value: translated.seriesAdv,
          },
        ];
      }


     this.isNotPlusGuard = !this.flagService.featureFlags.isPlusGuard


      for (const toolName in this.flagService.featureFlags.scsincome.tools) {
        switch (toolName) {
          case TOOLS_NAME.PROTECT_INCOME:
            if (
              this.flagService.featureFlags.scsincome.tools[
              TOOLS_NAME.PROTECT_INCOME
              ]
            ) {
              this.toolDashboardData.tools.push({
                icon: BoxButtonIcon.ProtectIncome,
                label: translated.protectIncome,
                routePath: ROUTE_PATH.SCSINCOME.TOOLS.PROTECT_INCOME,
              });
            }
            break;
          case TOOLS_NAME.HOW_SIO_WORKS:
            if (
              this.flagService.featureFlags.scsincome.tools[TOOLS_NAME.HOW_SIO_WORKS]
            ) {
              this.toolDashboardData.tools.push({
                icon: BoxButtonIcon.HowSIOWorks,
                label: translated.howItWorks,
                routePath: ROUTE_PATH.SCSINCOME.TOOLS.HOW_SIO_WORKS,
              });
            }
            break;
          case TOOLS_NAME.PRESERVE_LEGACY:
              if (
                this.flagService.featureFlags.scsincome.tools[
                TOOLS_NAME.PRESERVE_LEGACY
                ]
              ) {
                this.toolDashboardData.tools.push({
                  icon: BoxButtonIcon.PreserveLegacy,
                  label: translated.preserveLegacy,
                  routePath: ROUTE_PATH.SCSINCOME.TOOLS.PRESERVE_LEGACY,
                });
              }
            break;

          case TOOLS_NAME.PERFORMANCE_CAP_RATE:
              if (
                this.flagService.featureFlags.scsincome.tools[
                TOOLS_NAME.PERFORMANCE_CAP_RATE
                ]
              ) {
                this.toolDashboardData.tools.push({
                  icon: BoxButtonIcon.PerformanceCapRates,
                  label: translated.performanceCapRate,
                  routePath: ROUTE_PATH.SCSINCOME.TOOLS.PERFORMANCE_CAP,
                  newTab: true,
                });
              }
              break;

                 
          case TOOLS_NAME.CREATE_A_REPORT:
                if (
                  this.flagService.featureFlags.scsincome.tools[
                  TOOLS_NAME.CREATE_A_REPORT
                  ]
                ) {
                  this.toolDashboardData.tools.push({
                    icon: BoxButtonIcon.CreateAReport,
                    label: translated.createReport,
                    routePath: ROUTE_PATH.SCSINCOME.TOOLS.CREATE_REPORT,
                  });
                }
                break;

          case TOOLS_NAME.HISTORICAL_PERFORMANCE:
            if (
              this.flagService.featureFlags.scsincome.tools[
              TOOLS_NAME.HISTORICAL_PERFORMANCE
              ]
            ) {
              console.log('CARDCLICK this.scsIncomeService.series', this.scsIncomeService.series)
            
              this.toolDashboardData.tools.push({
                icon: BoxButtonIcon.HistoricalPerformance,
                label: translated.historicalPerformance,
                routePath: ROUTE_PATH.SCSINCOME.TOOLS.HISTORICAL_PERFORMANCE,
              });
            }
            break;
           
          case TOOLS_NAME.PRODUCT_OVERVIEW:
            if (
              this.flagService.featureFlags.scsincome.tools[
              TOOLS_NAME.PRODUCT_OVERVIEW
              ]
            ) {
              this.toolDashboardData.tools.push({
                icon: BoxButtonIcon.ProductOverview,
                label: translated.productOverview,
                routePath: ROUTE_PATH.SCSINCOME.DISCOVER,
              });
            }
           
            break;
          default:
            break;
        }
      }

      if (this.getSeries === SCSINCOME_SERIES.B) {
        this.headingName = translated.headerTitlePlus;
      } 
      else if (this.getSeries === SCSINCOME_SERIES.ADV) {
        this.headingName = translated.headerTitlePlus21;
      } 
      else {
        this.headingName = translated.headerTitle;
      }
    });

    //this.dropDownSection(this.getSeries);

    if (this.getSeries === SCSINCOME_SERIES.B) {
      this.isDisplay = true;
      this.headingName = this.translate.instant("scsincomeDashboard.headerTitle");
    } 
    else if (this.getSeries === SCSINCOME_SERIES.ADV) {
      this.isDisplay = true;
      this.headingName = this.translate.instant("scsincomeDashboard.headerTitle");
    }
    else {
      this.isDisplay = false;
      this.headingName = this.translate.instant("scsincomeDashboard.headerTitle");
    }

    // Learning Resources
    this.getResourcesData(this.getSeries);
  }

  // display Benefits
  dropDownSection(data) {
    if (data === SCSINCOME_SERIES.B) {
      this.isDisplay = true;
      this.getResourcesData(SCSINCOME_SERIES.B);
      this.headingName = this.translate.instant("scsincomeDashboard.headerTitle");
    }
    else if (data === SCSINCOME_SERIES.ADV) {
      this.isDisplay = false;
      this.getResourcesData(SCSINCOME_SERIES.ADV);
      this.headingName = this.translate.instant("scsincomeDashboard.headerTitle");
    }
  


    //set series in session
    this.scsIncomeService.series = data;
    this.scsIncomeService.investorData.index = "";
    this.scsIncomeService.investorData.segment = "";
    this.scsIncomeService.investorData.duration = "";
    this.scsIncomeService.investorData.protection = "";

    if (data === SCSINCOME_SERIES.ADV)
      this.toolDashboardData.tools[1].routePath =
        ROUTE_PATH.SCSINCOME.TOOLS.HISTORICAL_PERFORMANCE_PLUS;
    else if (data === SCSINCOME_SERIES.B)
    this.toolDashboardData.tools[1].routePath =
      ROUTE_PATH.SCSINCOME.TOOLS.HISTORICAL_PERFORMANCE;
    else this.toolDashboardData.tools[1].routePath =
      "/" + ROUTE_PATH.SCSINCOME.TOOLS.HISTORICAL_PERFORMANCE;
     
  }

  // Learning Resources
  getResourcesData(data) {
    this.scsIncomeService.getResources(data).subscribe((kits) => {
      this.learningResources = kits;
    });
  }

  // On click Card button
  cardClick(link: string, label: string, newTab?: boolean) {
    if(newTab) {
      window.open(link, '_blank');
    }
    else {
      if (label === this.investorProfiles) {
        this.utilityService.presentWithoutTitleModal(ModalPopupComponent);
      } else {
        this.utilityService.navigate(link, {
          section: "SCSINCOME"
        });
      }
    }
  }

  // openExternalLink
  goToResources(resource) {
    
    this.tealium.link({
      tealium_event: 'link',
      event_category: 'BodyClick',
      event_name: 'Retirement Guide Clicks',
      event_label: 'Click on ' + resource.description
    })

    this.utilityService.visitEquitable(resource.file);
  }

  // back button navigation
  backButton() {
    this.location.back()
    // if (this.flagService.featureFlags.isIR) {
    //   this.utilityService.navigate(ROUTE_PATH.HOME);
    // } else {
    //   this.utilityService.navigate(ROUTE_PATH.HOME);
    // }
  }

  goToIeLandingPage(): void {
    this.utilityService.navigateWithoutParams(ROUTE_PATH.IE.TOOLS.HOME);
  }

  goToRcLandingPage() {
    this.utilityService.navigateWithoutParams(ROUTE_PATH.RC.TOOLS.HOME);
  }
  goToScsLandingPage() {
    this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER + '?series=scs16');
  }
  goToScsIncomeLandingPage() {
    this.utilityService.navigate(ROUTE_PATH.SCSINCOME.DISCOVER);
  }

  // visit external site Equitable.com
  visitEquitable(url) {
    this.utilityService.visitEquitableMicrosite(url);
  }
}
