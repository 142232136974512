<ion-content>
  <div class="dashboard-page-wrapper">
    <div class="page-col col-first">
      <div class="back-arrow">
        <equitable-link
          [text]="'scsincomeDashboard.back' | translate"
          (click)="backButton()"
          [type]="LinkType.Light"
          [iconName]="LinkIcon.LeftIcon"
          [orientation]="Orientation.Left"
        ></equitable-link>
      </div>

      <!-- Dropdown Section -->
      <div class="resource-header-inner" *ngIf="isNotPlusGuard">
        <div class="resource-dropdown-container d-none">
          <label class="resource-dropdown-label">{{
            "disclosure.filterByProduct" | translate
          }}</label>
          <div [formGroup]="dashboardForm" class="drop-down-prop">
            <equitable-drop-down
              class="equitable-dropdown d-none"
              [formControl]="dashboardForm.get('seriesDropDown')"
              formControlName="seriesDropDown"
              [options]="seriesOptions"
            ></equitable-drop-down>
            <equitable-tooltip
              class="info-icon"
              [text]="toolTipText"
              css="--min-width: 412px;"
            ></equitable-tooltip>
          </div>
        </div>
      </div>

      <div class="heading" > <!--[innerHTML]="headingName"-->
        Structured Capital Strategies<sup>®</sup> Income
      </div>

      <ul class="benefits">
        <li>
          <!-- <span>   class="bold"
            *ngIf="
              'scsincomeDashboard.plusBenefit.timesOfUncertainty' | translate
            "
            [innerHTML]="
              'scsincomeDashboard.plusBenefit.timesOfUncertainty' | translate
            "
        
        </span> -->
        
       
        <div class="row">
            <span class="dash-col-icon icon-hand-money-circle" alt="cash in hand icon"></span>
            <div class="col-12 col-sm-8 col-md-10 dash-col-copy">      
        <h2>Lifetime income</h2>
         <span>Receive guaranteed income for as long as you live. </span>  
            
          </div>

        </div>
         
        </li>
        <li>
          <!-- <span
            class="bold"
            *ngIf="
              'scsincomeDashboard.plusBenefit.partialProtectionTitle' | translate
            "
            [innerHTML]="
              'scsincomeDashboard.plusBenefit.partialProtectionTitle' | translate
            "
          ></span
          ><span
            [innerHTML]="
              'scsincomeDashboard.plusBenefit.partialProtection' | translate
            "
          > -->
          <div class="row">
              <span class="dash-col-icon icon-graph-circle" alt="graph icon"></span>
              <div class="col-12 col-sm-8 col-md-10 dash-col-copy">      
          <h2>Opportunities to increase your income</h2>
           <span>Innovative features help to optimize and grow your income.</span>  
              
            </div>
  
          </div>

         
         
        </li>
        <li>
     <!--      <span
            class="bold"
            *ngIf="
              'scsincomeDashboard.plusBenefit.structuredInvestmentOptionTitle'
                | translate
            "
            [innerHTML]="
              'scsincomeDashboard.plusBenefit.structuredInvestmentOptionTitle'
                | translate
            "
          ></span
          ><span
            [innerHTML]="
              'scsincomeDashboard.plusBenefit.structuredInvestmentOption' | translate
            "
          ></span> -->
       

          <div class="row">
              <span class="dash-col-icon icon-umbrella-circle" alt="umbrella icon"></span>
              <div class="col-12 col-sm-8 col-md-10 dash-col-copy">      
          <h2>Level of downside protection</h2>
                <span>Gain a level of protection from market declines.
                  </span>  
              
            </div>
  
          </div>
        </li>
        <li>
      <!--     <span
            class="bold"
            *ngIf="'scsincomeDashboard.plusBenefit.zeroExplicitTitle' | translate"
            [innerHTML]="
              'scsincomeDashboard.plusBenefit.zeroExplicitTitle' | translate
            "
          ></span
          ><span
            [innerHTML]="'scsincomeDashboard.plusBenefit.zeroExplicit' | translate"
          ></span> -->
          <div class="row">
              <span class="dash-col-icon icon-piggy" alt="piggy bank icon"></span>
              <div class="col-12 col-sm-8 col-md-10 dash-col-copy">      
          <h2>Low cost, great value</h2>
                <span>High fees can destroy your returns. Keep your costs down with explicit fees as low as 1.50%.*
                  </span>  
              
            </div>
  
          </div>

          <h2></h2>
          <span>
             </span>
          <p></p>
        </li>
      </ul>

      <!-- other benefit -->
      <ul class="benefits" *ngIf="!isDisplay">
        <li *appFeatureFlag="'!isNational'">
         <!--  <span
            class="bold"
            *ngIf="
              'scsincomeDashboard.plusBenefit.timesOfUncertainty' | translate
            "
            [innerHTML]="
              'scsincomeDashboard.plusBenefit.timesOfUncertainty' | translate
            "
          ></span> /// 
        -->
        </li>
        <li>
         <!--  <span
            class="bold"
            [innerHTML]="
              'scsincomeDashboard.otherBenefit.partialProtectionTitle' | translate
            "
          ></span>
          <span
            [innerHTML]="
              'scsincomeDashboard.otherBenefit.partialProtection' | translate
            "
          ></span> -->
        </li>
        <li>
        <!--   <span class="bold">{{
            "scsincomeDashboard.otherBenefit.structuredInvestmentOptionTitle"
              | translate
          }}</span
          >{{
            "scsincomeDashboard.otherBenefit.structuredInvestmentOption" | translate
          }}
        </li>
        <li>
          <span class="bold">{{
            "scsincomeDashboard.otherBenefit.zeroExplicitTitle" | translate
          }}</span>
          <span
            [innerHTML]="'scsincomeDashboard.otherBenefit.zeroExplicit' | translate"
          ></span> -->
        </li>
      </ul>
      <div class="separate-line" *ngIf="isNotPlusGuard"></div>
      <div class="title hyperlinkTitle" *ngIf="isNotPlusGuard">
        {{ "scsincomeDashboard.exploreTitle" | translate }}
      </div>
      <ul class="hyperLink" *appFeatureFlag="'isIR'">
          <li>
              <span xxxx
                (click)="goToRcLandingPage()"
                [innerHTML]="'scsincomeDashboard.scsLink' | translate"
              ></span>
            </li>
        <li>
          <span xxxx
            (click)="goToRcLandingPage()"
            [innerHTML]="'scsincomeDashboard.rcLink' | translate"
          ></span>
        </li>
        <li>
          <span yyyy
            (click)="goToIeLandingPage()"
            [innerHTML]="'scsincomeDashboard.ieLink' | translate"
          ></span>
        </li>
      </ul>
      <ul class="hyperLink" *appFeatureFlag="'isPrimerica'">
        <li>
          <span xxx
            (click)="goToScsLandingPage()"
            [innerHTML]="'scsincomeDashboard.headerTitle' | translate"
          ></span>
        </li>
        <li>
          <span yyy
            (click)="goToRcLandingPage()"
            [innerHTML]="'scsincomeDashboard.rcLink' | translate"
          ></span>
        </li>
      </ul>
    </div>

    <div class="page-col col-second">
      <div class="content-col tools-div">
        <div class="separation-line">
          <!-- Tools START -->
          <div class="square-card" *ngFor="let tool of toolDashboardData.tools">
            <equitable-box-button
              [label]="tool.label"
              [icon]="tool.icon"
              (click)="cardClick(tool.routePath, tool.label, tool.newTab)"
            >
            </equitable-box-button>
          </div>
          <!-- Tools END -->
          <div></div>
        </div>
      </div>

      <div class="content-col learning-resources-div">
        <div class="title learning-resources-title">
          {{ "scsincomeDashboard.learningResources" | translate }}
        </div>
        <ul class="learning-resources">
          <li *ngFor="let resource of learningResources; let i = index">
            <equitable-link
              [text]="resource.description"
              class="resource-link"
              [type]="LinkType.Dark"
              (click)="goToResources(resource)"
            ></equitable-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
 
  <div class="container">
      <div class="row">
          <div class="col-12">
            <div class="disclaimer">*In addition, expenses related to administration, sales and certain risks in the contract are factored into the Performance Cap Rate. As long as your money is invested in the Structured Investment Option, you will not be charged additional fees. If you choose the optional Highest Anniversary Value (HAV) Death Benefit, or invest your money in a Variable Investment Option, additional fees and charges will apply.</div>
          </div>
        </div>
  </div>


  <app-footer
    isWhiteFooterVisible="true"
    isBlueFooterVisible="false"
    [selectedProduct]="dashboardForm.get('seriesDropDown').value"
  ></app-footer>
</ion-content>
