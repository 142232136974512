import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { throwError } from "rxjs";
import { HttpRequestService } from "./http-request.service";
import { map, catchError } from "rxjs/operators";

//Custom components
import { API_METHODS, PRODUCT_TYPE } from "../../constants/constants";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class GlossaryService {
  constructor(
    private translateService: TranslateService,
    private httpRequestService: HttpRequestService
  ) {}

  getDropdownData() {
    return this.translateService.get("productName").pipe(
      map((data) => {
        const dropdown = [
          {
            id: PRODUCT_TYPE.SCS,
            value: data.scs,
          },
          {
            id: PRODUCT_TYPE.RC,
            value: data.rc,
          },
        ];
        return dropdown;
      })
    );
  }

  getSearchResults(searchTerm, productType) {
    let url: string;

    switch (productType) {
      case PRODUCT_TYPE.SCS:
        url = environment.scsTerms;
        break;
      case PRODUCT_TYPE.RC:
        url = environment.rcTerms;
        break;
      default:
        url = environment.scsTerms;
        break;
    }

    const requestOptions = {
      method: API_METHODS.GET,
      url: url,
    };
    return this.httpRequestService.request(requestOptions).pipe(
      map((data: any) => {
        return data.body.terms["en-us"].filter((item) => {
          return item.word.toString().toLowerCase().includes(searchTerm);
        });
      }),
      catchError((err) => {
        throw throwError(err);
      })
    );
  }
}
