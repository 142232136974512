<div class="selected-annuity-card">
  <div
    class="selected-annuity-card-header"
    [ngClass]="{
      'scs-image': selectedProduct === 'scs',
      'rc-image': selectedProduct === 'rc',
      'ie-image': selectedProduct === 'ie'
    }"
  ></div>
  <div class="selected-annuity-card-content">
    <div class="selected-annuity-card-content-h1" [innerHTML]="title"></div>
    <h2 class="selected-annuity-content-h2" [innerHTML]="content"></h2>
    <div class="benefits-column">
      <p class="benefit-heading">{{ "questionnaire.benefits" | translate }}</p>
      <ul class="benefits-item">
        <li *ngFor="let item of benefits" class="benefit-item">{{ item }}</li>
      </ul>
    </div>
    <div class="learn-more">
      <equitable-button
        [type]="button.type"
        [theme]="button.theme"
        (click)="navigateToProducts()"
        >{{ "questionnaire.explore" | translate }}</equitable-button
      >
    </div>
  </div>
</div>
