import { OnInit } from "@angular/core";
import { Theme, Orientation, Icon } from "equitable-component-library";
import { UtilityService } from "../../../core/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { ROUTE_PATH, BUILD_WEALTH, BUILD_WEALTH_TABS } from "./../../../constants/constants";
var BuildWealthComponent = /** @class */ (function () {
    function BuildWealthComponent(utilityService, translate) {
        this.utilityService = utilityService;
        this.translate = translate;
        this.activeTab = BUILD_WEALTH.DIVERSIFY;
        this.type = Theme.Dark;
        this.orientation = Orientation.Left;
        this.backIcon = Icon.BackButton;
        this.tabsConfig = [];
        this.segments = [
            BUILD_WEALTH.DIVERSIFY,
            BUILD_WEALTH.INVEST_TAX,
        ];
        this.segmentChanged(this.activeTab);
    }
    BuildWealthComponent.prototype.ngOnInit = function () { };
    BuildWealthComponent.prototype.backButton = function () {
        this.utilityService.navigateWithoutParams(ROUTE_PATH.RC.TOOLS.HOME);
    };
    BuildWealthComponent.prototype.segmentChanged = function (tabName) {
        this.activeTab = tabName;
        if (tabName === BUILD_WEALTH.INVEST_TAX) {
            this.selectedTab = BUILD_WEALTH_TABS.INVESTMENT_TAX_SMART;
        }
        else {
            this.selectedTab = BUILD_WEALTH_TABS.DIVERSIFY_PORTFOLIO;
        }
    };
    return BuildWealthComponent;
}());
export { BuildWealthComponent };
