<ion-content class="rc-tool-parent-container">
  <div class="site-tools">
    <div class="rc-tool-tab-section">
      <equitable-sub-header
        [title]="header"
        backLinkText="{{
          'historicalPerformanceChart.backLinkText' | translate
        }}"
        (backClick)="backButton()"
      ></equitable-sub-header>
    </div>
    <div class="rc-tool-tab-section">
      <ion-card
        class="rc-tool-tab-container card-css"
        [ngClass]="{
          'tab-height-history': toggleTab,
          'tab-height-segment': !toggleTab,
          'primerica-pie-chart': toggleTab && usePie
        }"
      >
        <equitable-category-tabs
          #tabs
          [tabs]="tabsConfig"
          (selectedTab)="getSelectedTab($event)"
        >
        </equitable-category-tabs>
      </ion-card>
    </div>
  </div>
  <app-footer
    isWhiteFooterVisible="true"
    isBlueFooterVisible="true"
    [selectedProduct]="series"
    activeTab="Historical_performance"
  ></app-footer>
</ion-content>
