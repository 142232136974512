import { OnInit } from "@angular/core";
import { ATTRIBUTE_TYPE } from "equitable-component-library";
import { UtilityService } from "../../../core/services/utility.service";
import { SCSINCOME_SERIES, ROUTE_PATH } from "../../../constants/constants";
import { ScsIncomeService } from "../../services/scsincome.service";
import { TranslateService } from "@ngx-translate/core";
import { FeatureFlagService } from "../../../core/services/feature-flag.service";
import { TealiumUtagService } from '../../../tealium/utag.service';
import { environment } from "../../../../environments/environment";
var MarketTrendIndicatorComponent = /** @class */ (function () {
    function MarketTrendIndicatorComponent(utilityService, scsincomeService, translate, flagService, tealium) {
        this.utilityService = utilityService;
        this.scsincomeService = scsincomeService;
        this.translate = translate;
        this.flagService = flagService;
        this.tealium = tealium;
        this.backButtonSeries = this.determineSeries();
    }
    MarketTrendIndicatorComponent.prototype.ngOnInit = function () {
        if (this.backButtonSeries === SCSINCOME_SERIES.B) {
            console.log("B");
            this.mtiURL = environment.EQUITABLE_HOMEPAGE + '/retirement/products/variable-annuities/performance-cap-rates/nonav?product=Structured%20Capital%20Strategies%20Income';
            console.log('mtiURL is: ');
            console.log(this.mtiURL);
        }
        this.series = this.scsincomeService.series;
        this.marketTrendIndicatorUrl = this.utilityService.sanitizeDom(ATTRIBUTE_TYPE.URL, this.mtiURL);
        // this.tealium.link({
        //   tealium_event: 'link',
        //   event_category: 'BodyClick',
        //   event_name: 'Retirement Guide Clicks',
        //   event_label: 'Click on Performance Cap Rate'
        // })ng ser
    };
    MarketTrendIndicatorComponent.prototype.determineSeries = function () {
        if (this.scsincomeService.series === SCSINCOME_SERIES.B) {
            return SCSINCOME_SERIES.B;
        }
        else if (this.scsincomeService.series === SCSINCOME_SERIES.ADV) {
            return SCSINCOME_SERIES.ADV;
        }
        else {
            return "";
        }
    };
    MarketTrendIndicatorComponent.prototype.ionViewWillEnter = function () {
        if (this.scsincomeService.series == SCSINCOME_SERIES.B) {
            this.backLink = this.translate.instant("marketTrendIndicator.backLinkIncome");
        }
        else if (this.scsincomeService.series == SCSINCOME_SERIES.ADV) {
            this.backLink = this.translate.instant("marketTrendIndicator.backLinkIncome");
        }
        else {
            this.backLink = this.translate.instant("marketTrendIndicator.backLink");
        }
    };
    /***
     *  back  button
     ***/
    MarketTrendIndicatorComponent.prototype.backButton = function () {
        this.utilityService.navigate(ROUTE_PATH.SCSINCOME.TOOLS.HOME);
    };
    return MarketTrendIndicatorComponent;
}());
export { MarketTrendIndicatorComponent };
