import { Component, OnInit, HostListener } from "@angular/core";
import { LinkIcon, Orientation, LinkType } from "equitable-component-library";
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { Dictionary } from "../../models/app.models";
import { UtilityService } from "../../services/utility.service";
import { GlossaryService } from "../../services/glossary.service";
import { PRODUCT_TYPE } from "../../../constants/constants";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-glossary",
  templateUrl: "./glossary.component.html",
  styleUrls: ["./glossary.component.scss"]
})
export class GlossaryComponent implements OnInit {
  //back link config
  backLinkIconName = LinkIcon.LeftIcon;
  backLinkIconOrientation = Orientation.Left;
  backLinkType = LinkType.Dark;

  //dropDown variables
  dropDownOptions: Dictionary[];
  glossaryForm: FormGroup;
  formControlDropDown: FormControl;
  formControlSearchbar: FormControl;

  // search result variables
  resultCount = -1;
  termsUrl: string;
  searchResults;

  constructor(
    private formBuilder: FormBuilder,
    private utilityService: UtilityService,
    private glossaryService: GlossaryService,
    private activateRoute: ActivatedRoute
  ) {
    this.formControlDropDown = new FormControl("");
    this.formControlSearchbar = new FormControl("");
    this.activateRoute.params.subscribe(params => {
      if (params.product && params.product == PRODUCT_TYPE.RC) {
        this.formControlDropDown.setValue(PRODUCT_TYPE.RC);
      } else {
        this.formControlDropDown.setValue(PRODUCT_TYPE.SCS);
      }
    });
  }

  ngOnInit() {
    this.utilityService.sendScrollStatus(false);
    this.glossaryService.getDropdownData().subscribe(res => {
      this.dropDownOptions = res;
    });
    this.glossaryForm = this.formBuilder.group({
      formControlNameDropDown: this.formControlDropDown,
      formControlNameSearchbar: this.formControlSearchbar
    });
    //default search with "a"
    this.startSearch(
      "a",
      this.glossaryForm.get("formControlNameDropDown").value
    );
  }

  //search
  startSearch(value, dropDownVal?) {
    this.glossaryService.getSearchResults(value, dropDownVal).subscribe(res => {
      this.searchResults = res;
      this.resultCount = this.searchResults.length;
    });
  }

  @HostListener("change", ["$event"])
  onChange(event) {
    this.startSearch(
      this.glossaryForm.get("formControlNameSearchbar").value,
      this.glossaryForm.get("formControlNameDropDown").value
    );
  }
}
