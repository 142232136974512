import * as tslib_1 from "tslib";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { FeatureFlagService } from "./core/services/feature-flag.service";
import { environment } from "src/environments/environment";
var ɵ0 = function (appConfig) {
    function asyncInitializer() {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, appConfig.loadRemoteConfig()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    // NOTE: The factory function returns the asynchronous FUNCTION - it does
    // not execute the function directly.
    return asyncInitializer;
};
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export function translateLoader(httpClient) {
    return new TranslateHttpLoader(httpClient, environment.i18nLocation, ".json");
}
export { ɵ0 };
