import { FeatureFlagService } from './core/services/feature-flag.service';
import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
@Injectable({ providedIn: 'root' })

export class AppPreloadingStrategy implements PreloadingStrategy {

  constructor(private featureService: FeatureFlagService) {
  }

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    return this.featureService.featureFlags[route.path] ? load() : of(false);
  }
}
