import { RcService } from "../../services/rc.service";
import { Component, OnInit, Input } from "@angular/core";
import { DIVERSIFY } from "../../../constants/constants";
import { UtilityService } from "src/app/core/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { Theme } from "equitable-component-library";
import { PRODUCT_TYPE } from "../../../constants/constants";

@Component({
  selector: "equitable-diversification",
  templateUrl: "./diversification.component.html",
  styleUrls: ["./diversification.component.scss"],
})
export class DiversificationComponent implements OnInit {
  dark = Theme.Dark;
  light = Theme.Light;
  years: any = [];
  performanceData: any = [];
  activeSubOption = "";
  theme: string;
  performanceName = "";
  performanceJson = { performanceData: {} };
  lineWidth = 0;
  buttons: any[] = [];
  buttonsBottom: any[] = [];
  PRODUCT_TYPE = "";
  /***
   *  Constructor
   ***/
  constructor(
    private rcServie: RcService,
    private utilityService: UtilityService,
    private translateService: TranslateService
  ) {}

  /***
   *  page load
   ***/
  ngOnInit() {
    this.loadPageData();
  }

  /***
   *  page load data
   ***/
  loadPageData() {
    this.theme = "light";

    this.translateService.get("diversify.buttons").subscribe((buttons) => {
      this.buttons = [
        {
          text: buttons.usStocks,
          value: DIVERSIFY.uSStocks,
        },
        {
          text: buttons.usBonds,
          value: DIVERSIFY.uSBonds,
        },
        {
          text: buttons.realEstate,
          value: DIVERSIFY.realEstate,
        },
        {
          text: buttons.nonUSStocks,
          value: DIVERSIFY.nonUSStocks,
        },
        {
          text: buttons.nonUSBonds,
          value: DIVERSIFY.nonUSBonds,
        },
        {
          text: buttons.commodities,
          value: DIVERSIFY.commodities,
        },
      ];
      // this.buttonsBottom = [
      //   {
      //     text: buttons.nonUSStocks,
      //     value: DIVERSIFY.nonUSStocks,
      //   },
      //   {
      //     text: buttons.nonUSBonds,
      //     value: DIVERSIFY.nonUSBonds,
      //   },
      //   {
      //     text: buttons.commodities,
      //     value: DIVERSIFY.commodities,
      //   },
      // ];
    });

    this.rcServie.fetchDiversify().subscribe((data) => {
      this.performanceJson = JSON.parse(JSON.stringify(data)).body;
      this.years = [];
      this.performanceData = [];

      const tempData = [];
      const performanceData1 = [];
      const performanceData2 = [];
      const performanceData3 = [];
      const performanceData4 = [];
      const performanceData5 = [];
      Object.keys(this.performanceJson.performanceData).forEach((key) => {
        this.years.push(key);
        tempData.push(this.performanceJson.performanceData[key][0]);
        performanceData1.push(this.performanceJson.performanceData[key][1]);
        performanceData2.push(this.performanceJson.performanceData[key][2]);
        performanceData3.push(this.performanceJson.performanceData[key][3]);
        performanceData4.push(this.performanceJson.performanceData[key][4]);
        performanceData5.push(this.performanceJson.performanceData[key][5]);
      });

      this.performanceData.push(tempData);
      this.performanceData.push(performanceData1);
      this.performanceData.push(performanceData2);
      this.performanceData.push(performanceData3);
      this.performanceData.push(performanceData4);
      this.performanceData.push(performanceData5);
    });
  }

  /***
   *  draw line to all segments
   ***/
  drawLine(performanceName) {
    this.theme = "dark";
    if (performanceName != "") {
      this.activeSubOption = performanceName;
      const arrLeft = [];
      const clsName = this.returnClassName(performanceName);
      // eslint-disable-next-line angular/document-service
      const className = document.getElementsByClassName(clsName);
      for (let index = 0; index < className.length; index++) {
        const element = className[index];
        arrLeft.push(element);
      }
      arrLeft.sort((a, b) => {
        return a.offsetLeft - b.offsetLeft;
      });
      // eslint-disable-next-line angular/timeout-service
      setTimeout(() => {
        this.performanceName = performanceName;
      }, 400);

      let tempIndexX1 =
        arrLeft[0].offsetLeft + arrLeft[0].offsetWidth / 2 - this.lineWidth;
      let tempIndexY1 =
        arrLeft[0].offsetTop + arrLeft[0].offsetHeight / 2 - this.lineWidth;

      Object.keys(this.performanceJson.performanceData).forEach(
        (key, index) => {
          if (index > 0) {
            // eslint-disable-next-line angular/timeout-service
            setTimeout(() => {
              const toOffSetX =
                arrLeft[index].offsetLeft +
                arrLeft[index].offsetWidth / 2 -
                this.lineWidth;
              const toOffSetY =
                arrLeft[index].offsetTop + arrLeft[index].offsetHeight / 2;

              if (
                arrLeft[index - 1].offsetTop != arrLeft[index].offsetTop &&
                arrLeft[index - 1].offsetTop + arrLeft[0].offsetHeight !=
                arrLeft[index].offsetTop &&
                arrLeft[index - 1].offsetTop - arrLeft[0].offsetHeight !=
                arrLeft[index].offsetTop
              ) {
                this.randoRect(
                  performanceName,
                  tempIndexX1,
                  tempIndexY1,
                  toOffSetX,
                  toOffSetY,
                  0
                );
              }

              tempIndexX1 =
                arrLeft[index].offsetLeft +
                arrLeft[index].offsetWidth / 2 -
                this.lineWidth;
              tempIndexY1 =
                arrLeft[index].offsetTop +
                arrLeft[index].offsetHeight / 2 -
                this.lineWidth;
            }, 400);
          }
        }
      );
    }
  }

  /***
   *  return class name based on params
   ***/
  returnClassName(name) {
    const className = [
      { className: DIVERSIFY.t1, name: DIVERSIFY.nonUSStocks },
      { className: DIVERSIFY.t2, name: DIVERSIFY.commodities },
      { className: DIVERSIFY.t3, name: DIVERSIFY.realEstate },
      { className: DIVERSIFY.t4, name: DIVERSIFY.nonUSBonds },
      { className: DIVERSIFY.t5, name: DIVERSIFY.uSStocks },
      { className: DIVERSIFY.t6, name: DIVERSIFY.uSBonds },
    ];
    const clsName = className.filter((item) => {
      return name === item.name;
    });
    return clsName[0].className;
  }

  /***
   *  Draw rect
   ***/
  randoRect(performanceName, x1, y1, x2, y2, i) {
    // eslint-disable-next-line angular/document-service
    const canvasDraw: any = document.getElementById("cnv");
    const ctxDraw = canvasDraw.getContext("2d");
    ctxDraw.clearRect(0, 0, canvasDraw.width, canvasDraw.height);
    // eslint-disable-next-line angular/timeout-service
    setTimeout(() => {
      this.drawSquare(performanceName, i, x1, y1, x2, y2, ctxDraw);
    }, 200);
  }

  drawSquare(performanceName, i, x1, y1, x2, y2, ctxDraw) {
    ctxDraw.beginPath();
    ctxDraw.moveTo(x1, y1);
    ctxDraw.lineTo(x2, y2);
    ctxDraw.lineWidth = 3;
    ctxDraw.strokeStyle = DIVERSIFY.lineColor;
    ctxDraw.globalAlpha = 1;
    ctxDraw.stroke();
  }
}
