import { ROUTE_PATH } from "src/app/constants/constants";
import { Icon, Theme, Orientation } from "equitable-component-library";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { UtilityService } from "../../services/utility.service";
import { take } from "rxjs/operators";
import { PROSPECTUS_PRODUCTS_TYPE, PROSPECTUS_IFRAME_URL, PROSPECTUS_OPTIONS_VALUES, } from "../../../constants/constants";
import { FeatureFlagService } from "./../../../core/services/feature-flag.service";
var ProspectusComponent = /** @class */ (function () {
    function ProspectusComponent(route, translateService, utilityService, flagService, document) {
        this.route = route;
        this.translateService = translateService;
        this.utilityService = utilityService;
        this.flagService = flagService;
        this.document = document;
        this.checked = true;
        this.productSCS = PROSPECTUS_PRODUCTS_TYPE.SCS;
        this.url = PROSPECTUS_IFRAME_URL.URL;
        this.displayOptionValue = PROSPECTUS_OPTIONS_VALUES.DISPLAY_TYPE;
        this.InputOptions = [
            {
                id: PROSPECTUS_OPTIONS_VALUES.ID1,
                value: PROSPECTUS_OPTIONS_VALUES.VALUE1,
            },
            {
                id: PROSPECTUS_OPTIONS_VALUES.ID2,
                value: PROSPECTUS_OPTIONS_VALUES.VALUE2,
            },
            {
                id: PROSPECTUS_OPTIONS_VALUES.ID3,
                value: PROSPECTUS_OPTIONS_VALUES.VALUE3,
            },
        ];
        this.icon = Icon.BackButton;
        this.theme = Theme.Dark;
        this.linkOrientation = Orientation.Left;
    }
    ProspectusComponent.prototype.ionViewWillEnter = function () {
        var _this = this;
        this.route.queryParams.pipe(take(1)).subscribe(function (params) {
            _this.selectProduct(params["product"]);
        });
        this.utilityService.showLoader();
    };
    //product selection on basis of route params
    ProspectusComponent.prototype.selectProduct = function (productType) {
        var _this = this;
        console.log('PROSPECTUS: productType', productType);
        switch (productType) {
            case PROSPECTUS_PRODUCTS_TYPE.RC:
                this.options = [
                    {
                        id: PROSPECTUS_OPTIONS_VALUES.RC,
                        label: PROSPECTUS_PRODUCTS_TYPE.RC,
                    },
                ];
                this.isHide = true;
                this.productName = PROSPECTUS_PRODUCTS_TYPE.RC;
                break;
            case PROSPECTUS_PRODUCTS_TYPE.IE:
                this.options = [
                    {
                        id: PROSPECTUS_OPTIONS_VALUES.IE,
                        label: PROSPECTUS_PRODUCTS_TYPE.IE,
                    },
                ];
                this.isHide = true;
                this.productName = PROSPECTUS_PRODUCTS_TYPE.IE;
                break;
            case PROSPECTUS_PRODUCTS_TYPE.PLUSGUARD:
                this.options = [
                    {
                        id: PROSPECTUS_OPTIONS_VALUES.PLUSGUARD,
                        label: PROSPECTUS_PRODUCTS_TYPE.PLUSGUARD,
                    },
                ];
                this.isHide = true;
                this.productName = PROSPECTUS_PRODUCTS_TYPE.SCS;
                break;
            case PROSPECTUS_PRODUCTS_TYPE.ADV:
                this.translateService
                    .get("prospectus")
                    .pipe(take(1))
                    .subscribe(function (prospectus) {
                    if (_this.flagService.featureFlags.isNational) {
                        _this.options = [
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.ADV,
                                label: prospectus.scsDropdown.ADV,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.ADV,
                                label: prospectus.scsDropdown.C,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.ADV,
                                label: prospectus.scsDropdown.B,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.PLUS,
                                label: prospectus.scsDropdown.Plus,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.PLUS21,
                                label: prospectus.scsDropdown.Plus21,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.SCSINCOME,
                                label: prospectus.scsDropdown.SCSIncome,
                            },
                        ];
                    }
                });
                this.isHide = false;
                this.productName = PROSPECTUS_PRODUCTS_TYPE.SCS;
                break;
            case PROSPECTUS_PRODUCTS_TYPE.PLUS:
                this.translateService
                    .get("prospectus")
                    .pipe(take(1))
                    .subscribe(function (prospectus) {
                    if (_this.flagService.featureFlags.isNational) {
                        _this.options = [
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.PLUS21,
                                label: prospectus.scsDropdown.Plus21,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.SCSINCOME,
                                label: prospectus.scsDropdown.SCSIncome,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.PLUS,
                                label: prospectus.scsDropdown.Plus,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.ADV,
                                label: prospectus.scsDropdown.ADV,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.ADV,
                                label: prospectus.scsDropdown.C,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.ADV,
                                label: prospectus.scsDropdown.B,
                            },
                        ];
                    }
                    else if (_this.flagService.featureFlags.isPrimerica) {
                        _this.options = [
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.PLUS,
                                label: prospectus.scsDropdown.Plus,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.PLUS21,
                                label: prospectus.scsDropdown.Plus21,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.ADV,
                                label: prospectus.scsDropdown.B,
                            },
                        ];
                    }
                });
                this.isHide = false;
                this.productName = PROSPECTUS_PRODUCTS_TYPE.SCS;
                break;
            case PROSPECTUS_PRODUCTS_TYPE.PLUS21:
                this.translateService
                    .get("prospectus")
                    .pipe(take(1))
                    .subscribe(function (prospectus) {
                    if (_this.flagService.featureFlags.isNational) {
                        _this.options = [
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.PLUS21,
                                label: prospectus.scsDropdown.Plus21,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.SCSINCOME,
                                label: prospectus.scsDropdown.SCSIncome,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.PLUS,
                                label: prospectus.scsDropdown.Plus,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.ADV,
                                label: prospectus.scsDropdown.ADV,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.ADV,
                                label: prospectus.scsDropdown.C,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.ADV,
                                label: prospectus.scsDropdown.B,
                            },
                        ];
                    }
                    else if (_this.flagService.featureFlags.isPrimerica) {
                        _this.options = [
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.PLUS21,
                                label: prospectus.scsDropdown.Plus21,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.PLUS,
                                label: prospectus.scsDropdown.Plus,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.ADV,
                                label: prospectus.scsDropdown.B,
                            },
                        ];
                    }
                });
                this.isHide = false;
                this.productName = PROSPECTUS_PRODUCTS_TYPE.SCS;
                break;
            case PROSPECTUS_PRODUCTS_TYPE.SCSINCOME:
                this.translateService
                    .get("prospectus")
                    .pipe(take(1))
                    .subscribe(function (prospectus) {
                    if (_this.flagService.featureFlags.isNational) {
                        _this.options = [
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.SCSINCOME,
                                label: prospectus.scsDropdown.SCSIncome,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.PLUS21,
                                label: prospectus.scsDropdown.Plus21,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.PLUS,
                                label: prospectus.scsDropdown.Plus,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.ADV,
                                label: prospectus.scsDropdown.ADV,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.ADV,
                                label: prospectus.scsDropdown.C,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.ADV,
                                label: prospectus.scsDropdown.B,
                            },
                        ];
                    }
                });
                this.isHide = false;
                this.productName = PROSPECTUS_PRODUCTS_TYPE.SCSINCOME;
                break;
            case PROSPECTUS_PRODUCTS_TYPE.B:
                this.translateService
                    .get("prospectus")
                    .pipe(take(1))
                    .subscribe(function (prospectus) {
                    if (_this.flagService.featureFlags.isNational) {
                        _this.options = [
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.ADV,
                                label: prospectus.scsDropdown.B,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.SCSINCOME,
                                label: prospectus.scsDropdown.SCSIncome,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.PLUS21,
                                label: prospectus.scsDropdown.Plus21,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.PLUS,
                                label: prospectus.scsDropdown.Plus,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.ADV,
                                label: prospectus.scsDropdown.ADV,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.ADV,
                                label: prospectus.scsDropdown.C,
                            },
                        ];
                    }
                    else if (_this.flagService.featureFlags.isPrimerica) {
                        _this.options = [
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.ADV,
                                label: prospectus.scsDropdown.B,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.PLUS21,
                                label: prospectus.scsDropdown.Plus21,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.PLUS,
                                label: prospectus.scsDropdown.Plus,
                            },
                        ];
                    }
                });
                this.isHide = false;
                this.productName = PROSPECTUS_PRODUCTS_TYPE.SCS;
                break;
            case PROSPECTUS_PRODUCTS_TYPE.C:
                this.translateService
                    .get("prospectus")
                    .pipe(take(1))
                    .subscribe(function (prospectus) {
                    if (_this.flagService.featureFlags.isNational) {
                        _this.options = [
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.ADV,
                                label: prospectus.scsDropdown.C,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.ADV,
                                label: prospectus.scsDropdown.B,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.PLUS21,
                                label: prospectus.scsDropdown.Plus21,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.SCSINCOME,
                                label: prospectus.scsDropdown.SCSIncome,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.PLUS,
                                label: prospectus.scsDropdown.Plus,
                            },
                            {
                                id: PROSPECTUS_OPTIONS_VALUES.ADV,
                                label: prospectus.scsDropdown.ADV,
                            },
                        ];
                    }
                });
                this.isHide = false;
                this.productName = PROSPECTUS_PRODUCTS_TYPE.SCS;
                break;
            default:
                this.options = [
                    {
                        id: PROSPECTUS_OPTIONS_VALUES.RC,
                        label: PROSPECTUS_PRODUCTS_TYPE.RC,
                    },
                ];
                this.isHide = true;
                this.productName = PROSPECTUS_PRODUCTS_TYPE.RC;
        }
    };
    //calling lifecycle hook to load iframe
    ProspectusComponent.prototype.ionViewDidEnter = function () {
        this.loadIFrame();
        console.log('location search', document.location.search);
        if (document.location.search.indexOf('nonav=true') > -1) {
            var hm = document.querySelector('.hamburger-menu');
            if (hm && hm.style) {
                console.log('hide hm');
                hm.style.display = 'none';
            }
            var bl = document.querySelector('.back-link');
            if (bl && bl.style) {
                console.log('hide bl');
                bl.style.display = 'none';
            }
        }
    };
    //load IFrame
    ProspectusComponent.prototype.loadIFrame = function () {
        this.document.getElementById("ssoform").submit();
    };
    //change event fired on selection change, Applicable for **SCS**
    ProspectusComponent.prototype.onChange = function () {
        this.loadIFrame();
    };
    //back button event
    ProspectusComponent.prototype.backButton = function () {
        if (this.productName && this.productName === PROSPECTUS_PRODUCTS_TYPE.SCS) {
            this.utilityService.navigate(ROUTE_PATH.SCS.TOOLS.HOME);
        }
        else if (this.productName && this.productName === PROSPECTUS_PRODUCTS_TYPE.RC) {
            this.utilityService.navigate(ROUTE_PATH.RC.TOOLS.HOME);
        }
        else if (this.productName && this.productName === PROSPECTUS_PRODUCTS_TYPE.SCSINCOME) {
            this.utilityService.navigate(ROUTE_PATH.SCSINCOME.TOOLS.HOME);
        }
        else {
            this.utilityService.navigate(ROUTE_PATH.IE.TOOLS.HOME);
        }
    };
    ProspectusComponent.prototype.load = function () {
        this.utilityService.hideLoader();
    };
    return ProspectusComponent;
}());
export { ProspectusComponent };
