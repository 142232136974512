import { PrimericaHomeComponent } from './scs/components/primerica-home/primerica-home.component';
import { FeatureFlagService } from './core/services/feature-flag.service';
import { ScsPiePicturesComponent } from './scs/components/scs-pie-pictures/scs-pie-pictures.component';
import { ROUTE_PATH, CONFIG_DATA } from './constants/constants';
import * as i0 from "@angular/core";
import * as i1 from "./core/services/feature-flag.service";
var FeatureFlagGuard = /** @class */ (function () {
    function FeatureFlagGuard(featureFlagService) {
        this.featureFlagService = featureFlagService;
    }
    FeatureFlagGuard.prototype.canActivate = function (route) {
        //console.log("FFGUARDS:", route)
        if (route.data.flags === ROUTE_PATH.HOME) {
            //console.log("FFGUARDS:", "TEST1")
            if (!this.featureFlagService.featureFlags[route.data.flags]) {
                //console.log("FFGUARDS:", "TEST2")
                route.routeConfig.component = PrimericaHomeComponent;
            }
            return true;
        }
        else if (route.data.flags === CONFIG_DATA.USEPIE) {
            //console.log("FFGUARDS:", "TEST3")
            if (!this.featureFlagService.featureFlags[route.data.flags]) {
                //ole.log("FFGUARDS:", "TEST4")
                route.routeConfig.component = ScsPiePicturesComponent;
            }
            //console.log("FFGUARDS:", "DONE1")
            return true;
        }
        else {
            //console.log("FFGUARDS:", "DONE2")
            return this.featureFlagService.featureFlags[route.data.flags];
        }
    };
    FeatureFlagGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeatureFlagGuard_Factory() { return new FeatureFlagGuard(i0.ɵɵinject(i1.FeatureFlagService)); }, token: FeatureFlagGuard, providedIn: "root" });
    return FeatureFlagGuard;
}());
export { FeatureFlagGuard };
