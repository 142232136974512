import { OnInit } from "@angular/core";
import { ButtonType, LinkType, Icon } from "equitable-component-library";
import { ROUTE_PATH } from "../../../constants/constants";
import { UtilityService } from "../../../core/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
var DiscoverComponent = /** @class */ (function () {
    function DiscoverComponent(utilityService, translate) {
        this.utilityService = utilityService;
        this.translate = translate;
        this.image = "./assets/images/discover-rc-banner.png";
        this.LinkType = LinkType;
        this.ButtonType = ButtonType;
        this.guaranteedIncome = {
            headingSmall: "",
            listDetail: [],
        };
        this.deferYourTaxes = {
            headingSmall: "",
            listDetail: [],
        };
        this.scsincomeBenefits = {
            headingSmall: "",
            listDetail: [],
        };
        this.featureCards = [
            {
                icon: Icon.Investment,
                title: "discoverRc.opportunitiesForGrowth",
                description: "discoverRc.opportunitiesForGrowthDescription",
            },
            {
                icon: Icon.Lock,
                title: "discoverRc.protectionThatLasts",
                description: "discoverRc.protectionThatLastsDescription",
            },
            {
                icon: Icon.Arrow,
                title: "discoverRc.flexibilityForTheFuture",
                description: "discoverRc.flexibilityForTheFutureDescription",
            },
        ];
    }
    DiscoverComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translate.get("discoverRc").subscribe(function (discoverRc) {
            _this.guaranteedIncome = {
                headingSmall: discoverRc.benefitsTitle,
                listDetail: [
                    discoverRc.partialDownside,
                    discoverRc.upsidePotential,
                    discoverRc.zeroExplicit,
                ],
            };
            _this.deferYourTaxes = {
                headingSmall: discoverRc.benefitsTitle,
                listDetail: [
                    discoverRc.taxDeferral,
                    discoverRc.taxEfficient,
                    discoverRc.diversification,
                ],
            };
            _this.scsincomeBenefits = {
                headingSmall: discoverRc.benefitsTitle,
                listDetail: [
                    discoverRc.lifetimeIncome,
                    discoverRc.levelDownside,
                    discoverRc.lowCost,
                ],
            };
        });
    };
    DiscoverComponent.prototype.gotoSCSLandingPage = function () {
        this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER + '?series=scs16');
    };
    DiscoverComponent.prototype.gotoSCSPLUSLandingPage = function () {
        this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER + '?series=PLUS');
    };
    DiscoverComponent.prototype.gotoSCSINCOMELandingPage = function () {
        document.querySelector('.hero-main').scrollIntoView(true);
        this.utilityService.navigate(ROUTE_PATH.SCSINCOME.DISCOVER + '?series=B');
    };
    DiscoverComponent.prototype.gotoSCSPLUS21LandingPage = function () {
        this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER + '?series=PLUS21');
    };
    DiscoverComponent.prototype.gotoIELandingPage = function () {
        this.utilityService.navigateWithoutParams(ROUTE_PATH.IE.DISCOVER);
    };
    DiscoverComponent.prototype.gotoAdvancedToolPage = function () {
        this.utilityService.navigateWithoutParams(ROUTE_PATH.RC.TOOLS.HOME);
    };
    return DiscoverComponent;
}());
export { DiscoverComponent };
