import { DiversificationComponent } from "./components/diversification/diversification.component";
import { ProtectIncomeComponent } from "./components/protect-income/protect-income.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DiscoverComponent } from "./components/discover/discover.component";
import { PreserveLegacyComponent } from "./components/preserve-legacy/preserve-legacy.component";
import { RcAdvancedToolResourcesDashboardComponent } from "./components/rc-advanced-tool-resources-dashboard/rc-advanced-tool-resources-dashboard.component";
import { BuildWealthComponent } from "./components/build-wealth/build-wealth.component";
import { InvestTaxSmartComponent } from "./components/invest-tax-smart/invest-tax-smart.component";
import { ROUTE_PATH, PRODUCT_TYPE } from "../constants/constants";
import { ReportRcComponent } from "./components/report-rc/report-rc.component";
import { FeatureFlagGuard } from '../ffguard';

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "/" + ROUTE_PATH.RC.DISCOVER
  },
  {
    path: ROUTE_PATH.RC.DISCOVER,
    component: DiscoverComponent,
    canActivate: [FeatureFlagGuard],
    data: {
      flags: PRODUCT_TYPE.RC
    },
  },
  {
    path: ROUTE_PATH.RC.TOOLS.BUILD_WEALTH,
    component: BuildWealthComponent,
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo:
          "/" +
          ROUTE_PATH.RC.TOOLS.BUILD_WEALTH +
          "/" +
          ROUTE_PATH.RC.TOOLS.DIVERSIFY
      },
      {
        path: ROUTE_PATH.RC.TOOLS.INVEST_TAX,
        component: InvestTaxSmartComponent
      },
      {
        path: ROUTE_PATH.RC.TOOLS.DIVERSIFY,
        component: DiversificationComponent
      }
    ],
    canActivate: [FeatureFlagGuard],
    data: {
      flags: PRODUCT_TYPE.RC
    },
  },
  {
    path: ROUTE_PATH.RC.TOOLS.PROTECT_INCOME,
    component: ProtectIncomeComponent,
    canActivate: [FeatureFlagGuard],
    data: {
      flags: PRODUCT_TYPE.RC
    },
  },
  {
    path: ROUTE_PATH.RC.TOOLS.PRESERVE_LEGACY,
    component: PreserveLegacyComponent,
    canActivate: [FeatureFlagGuard],
    data: {
      flags: PRODUCT_TYPE.RC
    },
  },
  {
    path: ROUTE_PATH.RC.TOOLS.HOME,
    component: RcAdvancedToolResourcesDashboardComponent,
    canActivate: [FeatureFlagGuard],
    data: {
      flags: PRODUCT_TYPE.RC
    },
  },
  {
    path: ROUTE_PATH.RC.TOOLS.CREATE_REPORT,
    component: ReportRcComponent,
    canActivate: [FeatureFlagGuard],
    data: {
      flags: PRODUCT_TYPE.RC
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RcRoutingModule { }
