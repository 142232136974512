<ion-content>
  <form [formGroup]="formGroup">
    <div class="site-tools page-header" *ngIf="items && items.length > 0">
      <equitable-sub-header
        title="{{ 'howItsWorks.headerTextScsIncome' | translate }}"
        [backLinkText]="backLink"
        [showInfo]="showInfo"
        (backClick)="backButton()"
        (infoClick)="openModal()"
      ></equitable-sub-header>
    </div>
    <div class="tool-container site-tools">
      <ion-row>
        <ion-col class="tool-header-text">{{ toolHeaderText }}</ion-col>
      </ion-row>
      <ion-row class="row-width">
        <ion-col class="padding-left" size="6">
          <h6 class="radio">{{ "howItsWorks.segmentOption" | translate }}</h6>
          <equitable-radio-button
            *ngIf="items && items.length > 0"
            class="radio-button"
            [items]="items"
            formControlName="segment"
            [formControl]="formGroup.get('segment')"
          ></equitable-radio-button>
        </ion-col>
        <ion-col size="6">
          <ion-row>
            <ion-col>
              <h6 class="drop">{{ "howItsWorks.index" | translate }}</h6>
              <equitable-drop-down
                class="dropdown"
                [formControl]="formGroup.get('index')"
                formControlName="index"
                [options]="indices"
              ></equitable-drop-down>
            </ion-col>
            <ion-col>
              <h6 class="drop">{{ "howItsWorks.duration" | translate }}</h6>
              <equitable-drop-down
                [formControl]="formGroup.get('duration')"
                formControlName="duration"
                [options]="durations"
              ></equitable-drop-down>
            </ion-col>
            <ion-col>
              <h6 class="drop">
                {{ "howItsWorks.protection" | translate }}
              </h6>
              <equitable-drop-down
                [formControl]="formGroup.get('protection')"
                formControlName="protection"
                [options]="protections"
              ></equitable-drop-down>
            </ion-col>
            <ion-col *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.ENHANCED_UPSIDE">
                <h6 class="drop">
                  Enhanced Upside
                </h6>
                <equitable-drop-down
                  [formControl]="formGroup.get('enhancedUpside')"
                  formControlName="enhancedUpside"
                  [options]="enhancedUpsides"
                ></equitable-drop-down>
              </ion-col>
          </ion-row>
          <!-- START SEGMENT INVESTMENT DROPDOWN -->
          <ion-row *ngIf="isNational">
              <ion-col class="border-top">
                <label class="equitable-input-label">Segment Investment</label>
                <div class="input-contain">
                  <span class="equitable-input-prefix">$</span>
                  <input type="number" name="dollarInputValue" (click)="$event.target.select()" [(ngModel)]="dollarInputValue" [formControl]="formGroup.get('dynamicDollarAmount')" formControlName="dynamicDollarAmount" class="equitable-input dollar-input" id="segmentMaturity" placeholder="100000" 
                    [min]="minValue" [max]="maxValue" (keydown.enter)="onSubmitOfDollarAmount()" required [step]="1" [ngStyle]="{'border-color': isValid() ? '#767676' : 'red', 'border-width': '1px'}">
                  <button class="btn btn-primary" (click)="onSubmitOfDollarAmount()">Update</button>
                  <p class="invalid-feedback" *ngIf="dollarInputValue && (dollarInputValue < minValue || dollarInputValue > maxValue)">Value must be between {{minValue}} and {{maxValue}}</p>
                  <p class="invalid-feedback" *ngIf="dollarInputValue && dollarInputValue % 1 !== 0">Value must be a whole number</p>
                </div>
              </ion-col>
            </ion-row>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <div
            *ngIf="
              this.formGroup.get('segment').value === SEGMENT_TYPE.ANNUAL_LOCK
            "
            class="annualMarketScenerio"
          >
            <equitable-radio-button
              class="radio-button"
              [items]="marketScenerios"
              formControlName="marketScenerio"
              [formControl]="formGroup.get('marketScenerio')"
            ></equitable-radio-button>
          </div>

          <div>
              <!-- // IF the segment type is dual direction for primerica or plusguard, we want to display a static image.  -->
            <div class="dualImage" *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_DIRECTION && (!isNational)">
              <img alt="Dual Direction Chart" *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_DIRECTION && this.formGroup.get('protection').value == -10 && (isPrimerica)" src="/assets/images/dual_direction.png" />
              <img alt="Dual Direction Chart" *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_DIRECTION && this.formGroup.get('protection').value == -15 && (isPrimerica)" src="/assets/images/dual_direction15.png" />
              <img alt="Dual Direction Chart" *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_DIRECTION && this.formGroup.get('protection').value == -20 && (isPrimerica)" src="/assets/images/dual_direction20.png" />
              <img alt="Dual Direction Chart" *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_DIRECTION && this.formGroup.get('protection').value == -10 && (isPlusGuard)" src="/assets/images/dual_direction_15PG.png" />
              <img alt="Dual Direction Chart" *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_DIRECTION && this.formGroup.get('protection').value == -15 && (isPlusGuard)" src="/assets/images/dual_direction_10PG.png" />
            </div>

            <!-- display line and bar charts other than dual direction -->
            <equitable-bar-chart *ngIf="this.formGroup.get('segment').value !== SEGMENT_TYPE.DUAL_DIRECTION"
              [chartData]="chartData"
              isBarChart="true"
              lagendsLabel="{{ 'productKey' | translate }}"
              [segmentType]="this.formGroup.get('segment').value"
              [performanceData]="performanceData"
              [buffer]="this.formGroup.get('protection').value"
              [series]="this.series" 
              [index]="this.formGroup.get('index').value" 
              [duration]="this.formGroup.get('duration').value"
              productType="scsincome"
            ></equitable-bar-chart>

            <dual-direction *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_DIRECTION && isNational"
              [chartData]="chartData"
              [performanceData]="performanceData"
              [buffer]="this.formGroup.get('protection').value"
              [series]="this.series" 
              [index]="this.formGroup.get('index').value" 
              [duration]="this.formGroup.get('duration').value"
              [dynamicSegmentMaturityValue]="this.formGroup.get('dynamicDollarAmount').value"
              productType="scsincome"
            ></dual-direction>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col style="text-align: center;">
          <div *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.STEP_UP && (isPlusGuard)">
            The Segment Maturity Value reflects the deduction of the annual Death Benefit Rider Charge of 0.20%
          </div>
        </ion-col>
      </ion-row> 
      <ion-row>
        <ion-col>
          <div class="series-title" *ngIf="isNotPlusGuard">
            {{ "historyGuide.viewingSeries" | translate }} {{ series }}
          </div>
        </ion-col>
      </ion-row> 
      <ion-row>
        <ion-col style="text-align: center;">
          <div>
            Some Segment options may not be available in all firms or jurisdictions.
          </div>
          <div
            *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.STANDARD && this.scsincomeService.series == 'B' || this.scsincomeService.series == 'ADV'">
            This example has been simplified to demonstrate how the Segment Buffer and Performance Cap Rate work. Accordingly,
            this example does not deduct the GLWB rider fee or Highest Anniversary Value Death Benefit rider fee (if applicable).
            If these rider fees were deducted, the Segment Maturity Values in this example would be lower. The rider fee(s) are
            deducted on each contract date anniversary from the account value in Segments if the account value in the VIO, Segment
            Type Holding Accounts, and dollar cap averaging account are insufficient to cover the rider fee(s). If the rider
            fee(s) are deducted from a Segment, the Segment Investment for that Segment is reduced pro rata, meaning that the
            reduction may be greater than the dollar amount of the fee(s) deducted.
            The Performance Cap Rate is an assumed rate of {{ toolFooterTextPerformanceCapRate }}. This hypothetical example is
            intended for illustrative purposes only and is not indicative of actual product performance or contract owner outcome
            or experience, or as a product or investment recommendation of any kind. There is protection from some downside risk.
          </div>
          <div
            *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.ANNUAL_LOCK && this.scsincomeService.series == 'B' || this.scsincomeService.series == 'ADV'">
            This example has been simplified to demonstrate how the Segment Buffer and Performance Cap Rate work. Accordingly,
            this example does not deduct the GLWB rider fee or Highest Anniversary Value Death Benefit rider fee (if applicable).
            If these rider fees were deducted, each Annual Lock Anniversary Starting and Ending Amount and Segment Maturity Value
            in this example would be lower. The rider fee(s) are deducted on each contract date anniversary from the account value
            in Segments if the account value in the VIO, Segment Type Holding Accounts, and dollar cap averaging account are
            insufficient to cover the rider fee(s). If the rider fee(s) are deducted from a Segment, the Segment Investment and
            each Annual Lock Anniversary Starting and Ending Amount for that Segment are reduced pro rata, meaning that the
            reduction may be greater than the dollar amount of the fee(s) deducted.
            This hypothetical example is for illustrative purposes for the Annual Lock Segments only. The Performance Cap Rate is
            an assumed rate of {{ toolFooterTextPerformanceCapRate }}. You are protected from some downside risk; if the negative return is in excess of the Segment
            Buffer, there is a loss of principal, which could be substantial.
          </div>
          <div
            *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.STEP_UP && this.scsincomeService.series == 'B' || this.scsincomeService.series == 'ADV'">
            This example has been simplified to demonstrate how the Segment Buffer and Performance Cap Rate work. Accordingly,
            this example does not deduct the GLWB rider fee or Highest Anniversary Value Death Benefit rider fee (if applicable).
            If these rider fees were deducted, the Segment Maturity Values in this example would be lower. The rider fee(s) are
            deducted on each contract date anniversary from the account value in Segments if the account value in the VIO, Segment
            Type Holding Accounts, and dollar cap averaging account are insufficient to cover the rider fee(s). If the rider
            fee(s) are deducted from a Segment, the Segment Investment for that Segment is reduced pro rata, meaning that the
            reduction may be greater than the dollar amount of the fee(s) deducted. The Performance Cap Rate is an assumed rate of
            {{ toolFooterTextPerformanceCapRate }}. This hypothetical example is intended for illustrative purposes only and is not indicative of actual product
            performance or contract owner outcome or experience, or as a product or investment recommendation of any kind. There
            is protection from some downside risk.
          </div>
          <div
            *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_DIRECTION && this.scsincomeService.series == 'B' || this.scsincomeService.series == 'ADV'">
            This example has been simplified to demonstrate how the Segment Buffer and Performance Cap Rate work. Accordingly,
            this example does not deduct the GLWB rider fee or Highest Anniversary Value Death Benefit rider fee (if applicable).
            If these rider fees were deducted, the Segment Maturity Values in this example would be lower. The rider fee(s) are
            deducted on each contract date anniversary from the account value in Segments if the account value in the VIO, Segment
            Type Holding Accounts, and dollar cap averaging account are insufficient to cover the rider fee(s). If the rider
            fee(s) are deducted from a Segment, the Segment Investment for that Segment is reduced pro rata, meaning that the
            reduction may be greater than the dollar amount of the fee(s) deducted
            The Performance Cap Rate is an assumed rate of {{ toolFooterTextPerformanceCapRate }}. This hypothetical example is intended for illustrative purposes
            only and is not indicative of actual product performance or contract owner outcome or experience, or as a product or
            investment recommendation of any kind. There is protection from some downside risk.
          </div>
          <div
            *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.ENHANCED_UPSIDE && this.scsincomeService.series == 'B' || this.scsincomeService.series == 'ADV'">
            This example has been simplified to demonstrate how the Segment Buffer and Performance Cap Rate work. Accordingly,
            this example does not deduct the GLWB rider fee or Highest Anniversary Value Death Benefit rider fee (if applicable).
            If these rider fees were deducted, the Segment Maturity Values in this example would be lower. The rider fee(s) are
            deducted on each contract date anniversary from the account value in Segments if the account value in the VIO, Segment
            Type Holding Accounts, and dollar cap averaging account are insufficient to cover the rider fee(s). If the rider
            fee(s) are deducted from a Segment, the Segment Investment for that Segment is reduced pro rata, meaning that the
            reduction may be greater than the dollar amount of the fee(s) deducted. This hypothetical example is intended for
            illustrative purposes only and is not indicative of actual product performance or contract owner outcome or
            experience, or as a product or investment recommendation of any kind. There is protection from some downside risk.
          </div>
          <div
            *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_STEP_UP && this.scsincomeService.series == 'B' || this.scsincomeService.series == 'ADV'">
            This example has been simplified to demonstrate how the Segment Buffer and Performance Cap Rate work. Accordingly, this example does not deduct the GLWB rider fee or Highest Anniversary Value Death Benefit rider fee (if applicable). 
            If these rider fees were deducted, the Segment Maturity Values in this example would be lower. The rider fee(s) are deducted on each contract date anniversary from the account value in Segments if the account value in the VIO, Segment Type Holding Accounts, and dollar cap averaging account are insufficient to cover the rider fee(s). 
            If the rider fee(s) are deducted from a Segment, the Segment Investment for that Segment is reduced pro rata, meaning that the reduction may be greater than the dollar amount of the fee(s) deducted. The Performance Cap Rate is an assumed rate of 250%. 
            This hypothetical example is intended for illustrative purposes only and is not indicative of actual product performance or contract owner outcome or experience, or as a product or investment recommendation of any kind. There is protection from some downside risk.
          </div>
          <div
            *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.LOSS_LIMITER_90 && this.scsincomeService.series == 'B' || this.scsincomeService.series == 'ADV'">
            This example has been simplified to demonstrate how the Segment Buffer and Performance Cap Rate work. Accordingly, this example does not deduct the GLWB rider fee or Highest Anniversary Value Death Benefit rider fee (if applicable). 
            If these rider fees were deducted, the Segment Maturity Values in this example would be lower. The rider fee(s) are deducted on each contract date anniversary from the account value in Segments if the account value in the VIO, Segment Type Holding Accounts, and dollar cap averaging account are insufficient to cover the rider fee(s). 
            If the rider fee(s) are deducted from a Segment, the Segment Investment for that Segment is reduced pro rata, meaning that the reduction may be greater than the dollar amount of the fee(s) deducted. The Performance Cap Rate is an assumed rate of 250%. 
            This hypothetical example is intended for illustrative purposes only and is not indicative of actual product performance or contract owner outcome or experience, or as a product or investment recommendation of any kind. There is protection from some downside risk.
          </div>
          <div
          *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.LOSS_LIMITER_95 && this.scsincomeService.series == 'B' || this.scsincomeService.series == 'ADV'">
            This example has been simplified to demonstrate how the Segment Buffer and Performance Cap Rate work. Accordingly, this example does not deduct the GLWB rider fee or Highest Anniversary Value Death Benefit rider fee (if applicable). 
            If these rider fees were deducted, the Segment Maturity Values in this example would be lower. The rider fee(s) are deducted on each contract date anniversary from the account value in Segments if the account value in the VIO, Segment Type Holding Accounts, and dollar cap averaging account are insufficient to cover the rider fee(s). 
            If the rider fee(s) are deducted from a Segment, the Segment Investment for that Segment is reduced pro rata, meaning that the reduction may be greater than the dollar amount of the fee(s) deducted. The Performance Cap Rate is an assumed rate of 250%. 
            This hypothetical example is intended for illustrative purposes only and is not indicative of actual product performance or contract owner outcome or experience, or as a product or investment recommendation of any kind. There is protection from some downside risk.
        </div>
        </ion-col>
      </ion-row> 
      <div class="link-container">
        <!-- <div class="first-link">
          <equitable-link
            [text]="'howItsWorks.customProfile' | translate"
            [iconName]="linkRightIcon"
            [type]="type"
            [orientation]="orientation"
            (click)="saveProfile(CUSTOMIZATION_TYPE.PROFILE)"
          ></equitable-link>
        </div> -->
        <div class="second-link">
          <equitable-link
            [text]="'howItsWorks.customizeReport' | translate"
            [iconName]="linkRightIcon"
            [type]="type"
            [orientation]="orientation"
            (click)="saveProfile(CUSTOMIZATION_TYPE.REPORT)"
          ></equitable-link>
        </div>
      </div>
    </div>
  </form>
  <app-footer
    isBlueFooterVisible="true"
    isWhiteFooterVisible="true"
    [selectedProduct]="series"
    activeTab="How_SIO_works"
  ></app-footer>
</ion-content>
