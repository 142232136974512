import { ScsService } from "./../../services/scs.service";
import { Component, OnInit, AfterViewInit, ViewChildren, ElementRef, QueryList } from "@angular/core";
import { Theme, Orientation, Icon } from "equitable-component-library";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import {
  Dictionary,
  Profile,
  SCSHowSioWorks,
} from "../../../core/models/app.models";
import { UtilityService } from "../../../core/services/utility.service";
import {
  ROUTE_PATH,
  SCS_SERIES,
  CUSTOMIZATION_TYPE,
  CUSTOM_PROFILE,
  INVESTOR_MODE,
} from "../../../constants/constants";
import { TranslateService } from "@ngx-translate/core";
import { ModalController } from "@ionic/angular";
import { SioPopupComponent } from "../sio-popup/sio-popup.component";
import { distinctUntilChanged } from "rxjs/operators";
import { CoreService } from "src/app/core/services/core.service";
import { TealiumUtagService } from '../../../tealium/utag.service';

@Component({
  selector: "app-sio",
  templateUrl: "./sio.component.html",
  styleUrls: ["./sio.component.scss"],
})
export class SioComponent implements OnInit, AfterViewInit {
  @ViewChildren('ionRadioElRef', { read: ElementRef }) ionRadioElRef: QueryList<ElementRef>;
  //Class variables
  type = Theme.Dark;
  orientation = Orientation.Left;
  backIcon = Icon.BackButton;
  form: FormGroup;
  btnDisabled = true;
  flag = 0;
  masterData: any;
  series: string;
  segmentOption: Dictionary[] = [];
  indices: Dictionary[];
  durations: Dictionary[];
  protections: Dictionary[];
  segment: string;
  indices1: Dictionary[];
  durations1: Dictionary[];
  protections1: Dictionary[];
  segmentMaster;
  backLinkOther;
  showIndices: boolean;
  showDuration: boolean;
  showProtection: boolean;
  backLink;
  backLinkPlus;
  backLinkPlus21;
  year;
  percent;
  backButtonSeries =
    this.scsService.series === SCS_SERIES.PLUS ? SCS_SERIES.PLUS : "";

  isPlusPage: boolean = this.scsService.series === SCS_SERIES.PLUS ? true : false;
  isPlus21Page: boolean = this.scsService.series === SCS_SERIES.PLUS21 ? true : false;
  constructor(
    private fb: FormBuilder,
    private scsService: ScsService,
    private utilityService: UtilityService,
    private translate: TranslateService,
    public modalController: ModalController,
    private coreService: CoreService,
    private tealium: TealiumUtagService
  ) { }

  /***
   *  Page initialize
   ***/
  ngOnInit() {
    this.getTranslated();
    if (this.scsService.series === SCS_SERIES.PLUS) {
      this.backLink = this.backLinkPlus;
    } 
    else if (this.scsService.series === SCS_SERIES.PLUS21) {
      this.backLink = this.backLinkPlus21;
    }
    
    else {
      this.backLink = this.backLinkOther;
    }
    if (this.series !== this.scsService.series) {
      if (this.form) this.form.reset();
      this.series = this.scsService.series;
      this.buildForm();
    }

    // this.tealium.link({
    //   tealium_event: 'link',
    //   event_category: 'BodyClick',
    //   event_name: 'Retirement Guide Clicks',
    //   event_label: 'Click on How SIO Works'
    // })

    this.scsService.getDynamicSCSData();
  }

  ngAfterViewInit() {
    this.getTranslated();
    this.ionRadioElRef.changes.subscribe(() => {
      this.ionRadioElRef.forEach(el => {
        const loadCheck = setInterval(() => {
          const button = el.nativeElement.shadowRoot.childNodes[1] as HTMLButtonElement;
          if (button) {
            button.setAttribute('aria-label', 'radio');
            clearInterval(loadCheck);
          }
        }, 100);
      });
    });
  }

  ionViewWillEnter() {
    this.getTranslated();
    if (this.scsService.series === SCS_SERIES.PLUS) {
      this.backLink = this.backLinkPlus;
    } else {
      this.backLink = this.backLinkOther;
    }
    if (this.scsService.series === SCS_SERIES.PLUS21) {
      this.backLink = this.backLinkPlus21;
    } else {
      this.backLink = this.backLinkOther;
    }
    if (this.series !== this.scsService.series) {
      if (this.form) this.form.reset();
      this.series = this.scsService.series;
      this.buildForm();
    }
  }

  /***
   *  Build form for pages
   ***/
  buildForm() {
    this.form = this.fb.group({
      segment: [this.scsService.investorData.segment, Validators.required],
      index: [this.scsService.investorData.index],
      duration: [this.scsService.investorData.duration, Validators.required],
      protection: [
        this.scsService.investorData.protection,
        Validators.required,
      ],
    });

    this.getData();

    this.form
      .get("segment")
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((data) => {
        if (data) this.segmentSelection();
      });

    this.form
      .get("index")
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((data) => {
        if (data) this.indexSelection(data);
      });

    this.form
      .get("duration")
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((data) => {
        if (data) this.durationSelection(data);
      });

    this.form
      .get("protection")
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((data) => {
        if (data) this.protectionSelection(data);
      });
  }

  /***
   *  Get All data based on  Selected series
   ***/
  getData() {
    this.indices = [];
    this.protections = [];
    this.durations = [];
    this.scsService.getHowitWorksMenuData().subscribe((response: any) => {
      this.masterData = response[0].body.series.find(
        (x) => x.name === this.series
      );

      const allIndex = [];
      const allDuration = [];
      const allProtection = [];
      const filteredProtection = [];
      this.masterData.segments.forEach((element) => {
        allIndex.push(...element.data.map((x) => x.index));
        allDuration.push(...element.data.map((x) => x.duration));
        const z = element.data.map((x) => x.buffers);
        filteredProtection.push(...element.data.map((x) => x.buffers));
      });
      filteredProtection.forEach((element) => {
        allProtection.push(...element.map((x) => x));
      });
      this.indices = Array.from(new Set(allIndex.map((x) => x))).map<
        Dictionary
      >((y) => ({ id: y, value: y, disabled: true }));
      this.durations = Array.from(new Set(allDuration.map((x) => x)))
        .sort((a, b) => a - b)
        .map<Dictionary>((y) => ({
          id: y,
          value: y + " " + this.translate.instant("sioFrontPage.year"),
          disabled: true,
        }));
      this.protections = Array.from(new Set(allProtection.map((x) => x)))
        .sort((a, b) => b - a)
        .map<Dictionary>((y) => ({
          id: y,
          value: y + this.translate.instant("sioFrontPage.percentage"),
          disabled: true,
        }));
      this.segmentOption = Array.from(
        this.masterData.segments.map((x) => x.id)
      ).map<Dictionary>((x: string) => ({ id: x, value: x }));

      //saved profile
      const savedProfiles = response[1];
      if (
        savedProfiles &&
        savedProfiles.length > 0 &&
        ((this.scsService.customizationType === CUSTOMIZATION_TYPE.PROFILE &&
          this.scsService.currentProfile) ||
          this.scsService.customizationType === CUSTOMIZATION_TYPE.REPORT)
      ) {
        const profile: Profile = savedProfiles.find(
          (x) =>
            x.name ===
            (this.scsService.customizationType == CUSTOMIZATION_TYPE.PROFILE
              ? this.scsService.currentProfile
              : CUSTOMIZATION_TYPE.REPORT) &&
            x.currentSerie === this.scsService.series
        );
        if (profile && profile.aboutSCSHowSioWorks) {
          this.form
            .get("segment")
            .setValue(profile.aboutSCSHowSioWorks.segmentOption);
          this.form.get("index").setValue(profile.aboutSCSHowSioWorks.index);
          this.form
            .get("duration")
            .setValue(profile.aboutSCSHowSioWorks.duration.replace("yr", ""));
          this.form
            .get("protection")
            .setValue(
              parseInt(profile.aboutSCSHowSioWorks.protection.replace("%", ""))
            );

          for (let j = 0; j < this.indices.length; j++) {
            if (this.indices[j].id == this.scsService.investorData.index) {
              this.indices[j].disabled = false;
            }
          }
          for (let j = 0; j < this.durations.length; j++) {
            if (this.durations[j].id == this.scsService.investorData.duration) {
              this.durations[j].disabled = false;
            }
          }
          for (let j = 0; j < this.protections.length; j++) {
            if (
              this.protections[j].id == this.scsService.investorData.protection
            ) {
              this.protections[j].disabled = false;
            }
          }
        } else {
          this.form.controls["duration"].reset();
          this.form.controls["protection"].reset();
          this.form.controls["index"].reset();
          this.form.get("segment").setValue(this.segmentOption[0].id);
          this.segmentSelect1();
        }
      } else if (
        this.scsService.investorData.segment &&
        this.scsService.investorData.index &&
        this.scsService.investorData.duration &&
        this.scsService.investorData.protection
      ) {
        this.form.get("segment").setValue(this.scsService.investorData.segment);
        this.form.get("index").setValue(this.scsService.investorData.index);
        this.form
          .get("duration")
          .setValue(this.scsService.investorData.duration);
        this.form
          .get("protection")
          .setValue(this.scsService.investorData.protection);

        for (let j = 0; j < this.indices.length; j++) {
          if (this.indices[j].id == this.scsService.investorData.index) {
            this.indices[j].disabled = false;
          }
        }
        for (let j = 0; j < this.durations.length; j++) {
          if (this.durations[j].id == this.scsService.investorData.duration) {
            this.durations[j].disabled = false;
          }
        }
        for (let j = 0; j < this.protections.length; j++) {
          if (
            this.protections[j].id == this.scsService.investorData.protection
          ) {
            this.protections[j].disabled = false;
          }
        }
      } else {
        this.form.controls["duration"].reset();
        this.form.controls["protection"].reset();
        this.form.controls["index"].reset();
        this.form.get("segment").setValue(this.segmentOption[0].id);

        this.segmentSelect1();
      }
    });
    // eslint-disable-next-line angular/timeout-service
    setTimeout(() => {
      this.repaintButtons();
    }, 1500);
  }
  repaintButtons() {
    // eslint-disable-next-line angular/document-service
    const items = document.querySelectorAll(".ion-item");
    items.forEach((item) => {
      item.shadowRoot.querySelectorAll(".item-native").forEach((native) => {
        native.setAttribute("style", "padding-left:0px");
        native.querySelectorAll(".item-inner").forEach((inner) => {
          inner.setAttribute("style", "padding:0px");
        });
      });
    });

    // eslint-disable-next-line angular/document-service
    const item2 = document.querySelectorAll(".ion-item ion-radio");
    // console.log("item2", item2);
    item2.forEach((item) => {
      item.shadowRoot.querySelectorAll("button").forEach((nativeelem) => {
        nativeelem.setAttribute("style", "padding:0px");

      });
    });
  }

  /***
   *  Method to get data based on default selected segment
   ***/
  segmentSelect1() {
    this.form.get("segment").setValue(this.segmentOption[0].id);
    const segment =
      typeof this.form.get("segment").value === "object"
        ? this.form.get("segment").value.id
        : this.form.get("segment").value;
    this.segment = segment;
    const segmentMaster = this.masterData.segments.find((x) => x.id === segment)
      .data;
    this.segmentMaster = segmentMaster;
    this.indices1 = Array.from(new Set(segmentMaster.map((x) => x.index))).map<
      Dictionary
    >((x: string) => ({ id: x, value: x }));
    for (let i = 0; i < this.indices.length; i++) {
      this.indices[i].disabled = true;
      for (let j = 0; j < this.indices1.length; j++) {
        if (this.indices[i].id == this.indices1[j].id) {
          this.indices[i].disabled = false;
        }
      }
    }
    this.showDuration = false;
    this.showProtection = false;

    // eslint-disable-next-line angular/timeout-service
    setTimeout(() => {
      this.repaintButtons();
    }, 1000);
  }

  /***
   * Method to be called when  new segment is selected
   ***/
  segmentSelection() {
    this.form.controls["duration"].reset();
    this.form.controls["protection"].reset();
    this.form.controls["index"].reset();
    this.showDuration = false;
    this.showProtection = false;
    const segment =
      typeof this.form.get("segment").value === "object"
        ? this.form.get("segment").value.id
        : this.form.get("segment").value;
    this.segment = segment;
    const segmentMaster = this.masterData.segments.find((x) => x.id === segment)
      .data;
    this.segmentMaster = segmentMaster;
    this.indices1 = Array.from(new Set(segmentMaster.map((x) => x.index))).map<
      Dictionary
    >((x: string) => ({ id: x, value: x }));
    for (let i = 0; i < this.indices.length; i++) {
      this.indices[i].disabled = true;
      for (let j = 0; j < this.indices1.length; j++) {
        if (this.indices[i].id === this.indices1[j].id) {
          this.indices[i].disabled = false;
        }
      }
    }
    this.durations1 = Array.from(
      new Set(segmentMaster.map((x) => x.duration))
    ).map<Dictionary>((x: string) => ({
      id: x,
      value: x + " " + this.year,
    }));
    this.durations = [...this.durations];
    for (let i = 0; i < this.durations.length; i++) {
      this.durations[i].disabled = true;
      for (let j = 0; j < this.durations1.length; j++) {
        if (this.durations[i].id === this.durations1[j].id) {
          this.durations[i].disabled = false;
        }
      }
    }
    this.protections1 = Array.from(
      new Set(segmentMaster.map((x, index) => x.buffers[index]))
    ).map<Dictionary>((x: string) => ({
      id: x,
      value: x + this.percent,
    }));
    for (let i = 0; i < this.protections.length; i++) {
      this.protections[i].disabled = true;
      for (let j = 0; j < this.protections1.length; j++) {
        if (this.protections[i].id === this.protections1[j].id) {
          this.protections[i].disabled = false;
        }
      }
    }
    // eslint-disable-next-line angular/timeout-service
    setTimeout(() => {
      this.repaintButtons();
    }, 200);
    this.buttonDisable();
  }

  /***
   *  Method to be called when index is selected
   ***/
  indexSelection(value) {
    this.showDuration = true;
    this.showProtection = false;
    this.form.controls["duration"].reset();
    this.form.controls["protection"].reset();
    for (let i = 0; i < this.durations.length; i++) {
      this.durations[i].disabled = true;
    }
    for (let i = 0; i < this.protections.length; i++) {
      this.protections[i].disabled = true;
    }
    this.segmentMaster.filter((item) => {
      if (item.index === value) {
        for (let i = 0; i < this.durations.length; i++) {
          if (item.duration === this.durations[i].id) {
            this.durations[i].disabled = false;
          }
        }
      }
    });
    this.segmentMaster.filter((item) => {
      if (item.index === this.form.controls["index"].value) {
        for (let i = 0; i < this.protections.length; i++) {
          for (let j = 0; j < item.buffers.length; j++) {
            if (item.buffers[j] === parseInt(this.protections[i].id)) {
              this.protections[i].disabled = false;
            }
          }
        }
      }
    });
    // eslint-disable-next-line angular/timeout-service
    setTimeout(() => {
      this.repaintButtons();
    }, 200);
    this.buttonDisable();
  }

  /***
   *  Method to be called when duration is selected
   ***/
  durationSelection(value) {
    this.showProtection = true;
    this.form.controls["protection"].reset();
    if (this.form.controls["index"].value != undefined && value != undefined) {
      for (let i = 0; i < this.protections.length; i++) {
        this.protections[i].disabled = true;
      }
      this.segmentMaster.filter((item) => {
        if (
          item.index === this.form.controls["index"].value &&
          item.duration === value
        ) {
          for (let i = 0; i < this.protections.length; i++) {
            for (let j = 0; j < item.buffers.length; j++) {
              if (item.buffers[j] === parseInt(this.protections[i].id)) {
                this.protections[i].disabled = false;
              }
            }
          }
        }
      });
    }
    // eslint-disable-next-line angular/timeout-service
    setTimeout(() => {
      this.repaintButtons();
    }, 200);
    this.buttonDisable();
  }

  /***
   *  Method to be called click of button view Results
   ***/
  viewResults() {
    if (!this.btnDisabled) {
      if (
        this.scsService.customizationType ===
        CUSTOMIZATION_TYPE.INVESTOR_PROFILE &&
        (this.scsService.investorData.segment !==
          this.form.controls["segment"].value ||
          this.scsService.investorData.index !==
          this.form.controls["index"].value ||
          this.scsService.investorData.duration !==
          this.form.controls["duration"].value ||
          this.scsService.investorData.protection !==
          this.form.controls["protection"].value)
      ) {
        this.coreService.profile.next({
          name: "",
          type: CUSTOMIZATION_TYPE.REPORT,
          profile: new Profile(),
        });
        this.scsService.customizationType = CUSTOMIZATION_TYPE.REPORT;
      }

      this.scsService.investorData.index = this.form.controls["index"].value;
      this.scsService.investorData.duration = this.form.controls[
        "duration"
      ].value;
      this.scsService.investorData.protection = this.form.controls[
        "protection"
      ].value;
      this.scsService.investorData.segment = this.form.controls[
        "segment"
      ].value;

      this.scsService.investorData.mode = INVESTOR_MODE.HOME;

      this.utilityService.navigate(ROUTE_PATH.SCS.TOOLS.HOW_SIO_WORKS_DETAILS);
      // const hypothetical = new SCSHowSioWorks();
      // hypothetical.segmentOption = this.scsService.investorData.segment;
      // hypothetical.duration = this.scsService.investorData.duration + "yr";
      // hypothetical.index = this.scsService.investorData.index;
      // hypothetical.protection = this.scsService.investorData.protection + "%";

      // const isReport =
      //   this.scsService.customizationType === CUSTOMIZATION_TYPE.REPORT
      //     ? true
      //     : false;

      // this.scsService
      //   .storeProfile(CUSTOM_PROFILE.HOW_IT_WORKS, hypothetical, isReport)
      //   .subscribe(() => {
      //     this.utilityService.navigate(
      //       ROUTE_PATH.SCS.TOOLS.HOW_SIO_WORKS_DETAILS
      //     );
      //   });
    }
  }

  /***
   *  Method to be called when protection is selected
   ***/
  protectionSelection(event) {
    this.buttonDisable();
  }

  /***
   *  button disable condition
   ***/
  buttonDisable() {
    if (
      this.form.controls["index"].value &&
      this.form.controls["duration"].value &&
      this.form.controls["protection"].value
    ) {
      this.btnDisabled = false;
    } else {
      this.btnDisabled = true;
    }
  }

  /***
   *  back  button
   ***/
  backButton() {
    this.utilityService.navigate(ROUTE_PATH.SCS.TOOLS.HOME);
  }

  openModal() {
    this.scsService.openModal(SioPopupComponent, "modal-css");
  }

  getTranslated() {
    this.translate.get("sioFrontPage").subscribe((translated) => {
      this.year = translated.year;
      this.percent = translated.percentage;
      this.backLinkPlus = translated.backLinkPlus;
      this.backLinkPlus21 = translated.backLinkPlus21;
      this.backLinkOther = translated.backLink;
    });
  }
}
