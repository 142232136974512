import { HttpHeaders } from "@angular/common/http";

//for models and interfaces

export class Alert {
  message: string;
  buttons = new Array<Button>();
  title?: string;
}

export class Button {
  text: string;
  role: string;
  handler?: any;
}

export interface RequestOption {
  method?: string;
  url?: string;
  headers?: HttpHeaders;
  body?: any;
  params?: any;
  isblob?: boolean;
  isBluk?: boolean;
}

export interface Answer {
  detail: string;
  heading: string;
  description: string;
  icon: string;
}

export interface InvestorDetails {
  image: string;
  name: string;
  title: string;
  heading: string;
  text: string;
  subText: string;
}

export interface Annuity {
  image: string;
  name: string;
  text: string;
  benefits: Array<string>;
}

export interface Keypoint {
  heading: string;
  details: Array<string>;
}

export interface HeaderImage {
  title: string;
  description: string;
  image: string;
}

export interface SubMenu {
  name: string;
  path: string;
}

export interface HamburgerMenu {
  id: number;
  name: string;
  path?: string;
  isPrimary: boolean;
  isExternal?: boolean;
  isSignIn?: boolean;
  isSubMenu?: boolean;
  subMenu?: Array<SubMenu>;
}

export interface FeatureCard {
  icon: string;
  title: string;
  description: string;
}

export interface GlossaryLabels {
  backLinkText: string;
  searchbarPlaceholder: string;
  placeholderOption: string;
}

export interface Dictionary {
  id: string;
  value: string;
}

export class Hypothetical {
  index?: string;
  segment?: string;
  buffer?: string;
  protection?: string;
  duration?: string;
}

export class Profile {
  sourceURL?: string;
  name?: string = "";
  forName?: string = "";
  profName?: string = "";
  firmName?: string = "";
  contactNumber?: string = "";
  aboutSCSHowSioWorks?: SCSHowSioWorks;
  historicalPerformance?: HistoricalPerformance;
  historyMayBeAGuide?: HistoryMayBeAGuide;
  historyMayBeAGuidePlus?: HistoryMayBeAGuidePlus;
  historyMayBeAGuideIncome?: HistoryMayBeAGuideIncome;
  sectionsName?: string;
  learningResourcesIncluded?: object;
  currentSerie?: string;
  history: History; //for save profile
  comment?: string;
  date = new Date().toLocaleDateString();
}

export class SCSHowSioWorks {
  scaleMin: number;
  scaleMax: number;
  scaleStep: number;
  bars: object = {};
  performanceCap: number;
  segmentBuffer: number;
  scaleHeight: number;
  commissions: object = {};
  index: string;
  duration: string;
  protection: string;
  segmentOption: string;
  enhancedUpside: string;
  scenarios: object = {};
  upMarketBars: any;
  downMarketBars: any;
  segmentValue: any;
}
export class IEHowSioWorks {
  scaleMin: number;
  scaleMax: number;
  scaleStep: number;
  bars: object = {};
  performanceCap: number;
  segmentBuffer: number;
  scaleHeight: number;
  commissions: object = {};
  index: string;
  duration: string;
  protection: string;
  segmentOption: string;
  enhancedUpside: string;
  scenarios: object = {};
  upMarketBars: any;
  downMarketBars: any;
  segmentValue: any;
}
export class SCSIncomeHowSioWorks {
  scaleMin: number;
  scaleMax: number;
  scaleStep: number;
  bars: object = {};
  performanceCap: number;
  segmentBuffer: number;
  scaleHeight: number;
  commissions: object = {};
  index: string;
  duration: string;
  protection: string;
  segmentOption: string;
  enhancedUpside: string;
  scenarios: object = {};
  upMarketBars: any;
  downMarketBars: any;
  segmentValue: any;
}

export class HistoricalPerformance {
  topGraphic: TopGraphic;
  bottomPanel: BottomPanel;
}

export class TopGraphic {
  scaleMin: number;
  scaleMax: number;
  scaleStep: number;
  indices: object = {};
  maturityDate: string;
  maturityDateKey: string;
  protection: string;
  protectionKey: string;
  package: string;
}

export class BottomPanel {
  investment?: number;
  maturityValue?: string;
  indexValue?: string;
  indices?: object = {};
}

export class HistoryMayBeAGuidePlus {
  columnPlus1: HistoryMayBeAGuidePlusColumns;
  columnPlus2: HistoryMayBeAGuidePlusColumns;
  columnPlus3: HistoryMayBeAGuidePlusColumns;
}
export class HistoryMayBeAGuideIE {
  columnPlus1: HistoryMayBeAGuidePlusColumns;
  columnPlus2: HistoryMayBeAGuidePlusColumns;
  columnPlus3: HistoryMayBeAGuidePlusColumns;
}

export class HistoryMayBeAGuideIncome {
  columnPlus1: HistoryMayBeAGuideIncomeColumns;
  columnPlus2: HistoryMayBeAGuideIncomeColumns;
  columnPlus3: HistoryMayBeAGuideIncomeColumns;
}

export class HistoryMayBeAGuideIncomeColumns {
  label: string;
  url: string;
  id?: string;
}

export class HistoryMayBeAGuidePlusColumns {
  label: string;
  url: string;
  id?: string;
}
export class HistoryMayBeAGuide {
  column1?: HistoryMayBeAGuideColumn;
  column2?: HistoryMayBeAGuideColumn;
  column3?: HistoryMayBeAGuideColumn;
}

export class HistoryMayBeAGuideColumn {
  indexKey?: string;
  totalColumns?: number;
  table: HistoryMayBeAGuideColumnTable;

  label?: string;
  url?: string;
  id?: string;
}

export class HistoryMayBeAGuideColumnTable {
  averageReturn = [];
  totalGainsAndLosses = [];
  numOfGains = [];
  numOfLosses = [];
  lossBelow10 = [];
  loss10to15 = [];
  loss15To20 = [];
  loss20to25 = [];
  loss25to30 = [];
  lossAbove30 = [];
  percentTimesAboveHighestBuffer = [];
  percentTimesAnyLosses = [];
}

export class History {
  indexes?: Dictionary[];
  maturityDate?: string;
  segmentBuffer?: string;
  initialInvestment?: string;
}

export class HistoryGuide {
  index1?: string;
  index2?: string;
  index3?: string;
}

export interface ImageCard {
  headingSmall: string;
  listDetail: string[];
}

export interface Answer {
  detail: string;
  heading: string;
  description: string;
  icon: string;
}

export interface InvestorDetails {
  image: string;
  name: string;
  title: string;
  heading: string;
  text: string;
  subText: string;
}

export interface Annuity {
  image: string;
  name: string;
  text: string;
  benefits: Array<string>;
  id: string;
}

export interface Keypoint {
  heading: string;
  details: Array<string>;
}

export class Investors {
  title: string;
  icon: string;
  investorDetails: InvestorDetails;
  keypoints: Array<Keypoint>;
}

export class QuestionnaireResults {
  answers: Array<Answer>;
  investorProfiles: Array<Investors>;
  suggestedAnnuity: Annuity;
  defaultAnnuity: Array<Annuity>;
}

export interface HeaderImage {
  title: string;
  description: string;
  image: string;
}

export interface SubMenu {
  name: string;
  path: string;
}

export interface HamburgerMenu {
  id: number;
  name: string;
  path?: string;
  isPrimary: boolean;
  isSignIn?: boolean;
  isSubMenu?: boolean;
  subMenu?: Array<SubMenu>;
}

export class DiscoverRc {
  featureCards?: Array<FeatureCard>;
  featureCards_Plus21?: Array<FeatureCard>;
  featureCards_Income?: Array<FeatureCard>;
  featureCards_IE?: Array<FeatureCard>;
  getFileUrl?: string;
  resource?: Array<object>;
  tools?: Array<DashboardTools>;
}

export interface FeatureCard {
  icon: string;
  title: string;
  description: string;
}

export interface DashboardTools {
  icon?: string;
  label?: string;
  routePath?: string;
  newTab?: boolean;
}

export interface GlossaryLabels {
  backLinkText: string;
  searchbarPlaceholder: string;
  placeholderOption: string;
}

export interface Dictionary {
  id: string;
  value: string;
  disabled?: boolean;
}

export interface InvestTaxChartOptions {
  taxBracket: number;
  duration: number;
  investment: number;
  rateOfReturn: number;
  noTaxDeferral: number;
  taxDeferral: number;
  minValue: number;
  maxValue: number;
}

export class InvestTaxChart {
  taxBrackets: Array<number>;
  durations: Array<number>;
  options: Array<InvestTaxChartOptions>;
}

export interface BuildWeathTabsConfif {
  diversifyPortfolio: string;
  investTaxSmart: string;
  backLink: string;
  header: string;
  rc: string;
}

export interface ImageCard {
  headingSmall: string;
  listDetail: string[];
}

///////////// bar chart

export class Axis {
  type?: string;
  min?: number;
  max?: number;
  interval?: number;
}

export class Item {
  id?: number;
  label?: string;
  value?: any;
  topValue?: number;
}

export class Line {
  legends?: string;
  data?: Item[];
}

export class Bar {
  id?: number;
  label?: string;
  description?: string;
  lines?: Item[];
  data?: Item[];
}

export class Chart {
  axis?: Axis[];
  line?: Line;
  bar?: Bar[];
  description?: string;
  showLineToolTip: boolean;
  showDescription: boolean;
}

//===================== SCS ============================

export class SCS {
  version?: Version;
  notifications?: Notification;
  videos?: [];
  PLUSvideos?: [];
  PLUS21videos?: [];
  languageBaseURL?: string;
  series: Series[];
  lastModifiedDate: string;
}

//===================== SCS INCOME ============================
export class SCSINCOME {
  version?: Version;
  notifications?: Notification;
  videos?: [];
  PLUSvideos?: [];
  PLUS21videos?: [];
  scsincomevideos?: [];
  languageBaseURL?: string;
  series: Series[];
  lastModifiedDate: string;
}


//===================== IE (Investment Edge) ============================


export class IE {
  version?: Version;
  notifications?: Notification;
  videos?: [];
  PLUSvideos?: [];
  PLUS21videos?: [];
  ievideos?: [];
  languageBaseURL?: string;
  series: Series[];
  lastModifiedDate: string;
}


//=================================================//


export class Version {
  id?: number;
  message?: string;
  mandatory?: boolean;
}

export class Notification {
  mobile?: string;
  web?: string;
}

export class Series {
  name?: string;
  performance?: Performance;
  kits?: Kits[];
}

export class Performance {
  durations: string;
  capTrend: string;
  historicalReturns: string;
  historicalPerformance: string;
  howItWorks: string;
  premiumDeathBenefit: string;
  threePieChartsData: string;
}

export class Kits {
  type?: string;
  id?: string;
  description?: string;
  image?: string;
  file?: string;
}

export class HistorySegment {
  id?: number;
  name?: string;
  percentage?: number;
  disabled? = false;
  value?: number;
  checked? = false;
  rateOfReturn = 0;
  performanceRate = 0;
  color?: string;
}

export class IndexOptions {
  key?: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  percentage? = 0;
}

export interface ImageCard {
  headingSmall: string;
  listDetail: string[];
}

export interface Footer {
  addressLine1: string;
  addressLine2: string;
  socialLinks: Array<FooterSocialLinks>;
  footerElementLeft: Array<FooterElementLeft>;
  privacyAndSecurity: Array<FooterElementLeft>;
  disclosuresFooter: Array<FooterElementLeft>;
  copyrights: string;
  footerDescription1: string;
  footerDescription2: string;
  footerDescription3: string;
  finraUrl: string;
  finraText: string;
  sipcUrl: string;
  sipcText: string;
  pdfLink: string;
}

export interface FooterElementLeft {
  text: string;
  url: string;
}
export interface FooterSocialLinks {
  iconUrl: string;
  url: string;
}

export interface InvestorProfile {
  header: string;
  bodyCopy: string;
  ie: Array<[]>;
  scs: Array<[]>;
  scsincome: Array<[]>;
}

export class Preset {
  id: number;
  option: string;
  maturityDate: string;
  series: string;
  duration: string;
  buffer: string;
  initialInvestment: string;
  indices: any[] = [];
}

export interface Feature {
  [name: string]: boolean;
}
