import { OnInit } from "@angular/core";
import { ScsIncomeService } from "../../../scsincome/services/scsincome.service";
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from "../../services/utility.service";
import { ROUTE_PATH } from "../../../constants/constants";
var HomePagePopupComponent = /** @class */ (function () {
    function HomePagePopupComponent(scsincomeService, modalController, translateService, utilityService) {
        this.scsincomeService = scsincomeService;
        this.modalController = modalController;
        this.translateService = translateService;
        this.utilityService = utilityService;
        this.route = ROUTE_PATH;
    }
    HomePagePopupComponent.prototype.ngOnInit = function () {
    };
    HomePagePopupComponent.prototype.ionViewWillEnter = function () {
    };
    HomePagePopupComponent.prototype.closeModal = function () {
        this.modalController.dismiss({
            'dismissed': true
        });
    };
    HomePagePopupComponent.prototype.visitPage = function (route) {
        this.utilityService.navigate(route);
    };
    return HomePagePopupComponent;
}());
export { HomePagePopupComponent };
