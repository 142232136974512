<ion-content>
  <ion-row>
    <ion-col>
      <h5 class="header-popup">
        {{ "sioFrontPage.popOver.title" | translate }}
      </h5></ion-col
    >
  </ion-row>
  <div>
    <ion-row>
      <ion-col size="7">
        <div class="popover-text-container">
          <h5>
            {{ "sioFrontPage.popOver.header" | translate }}
          </h5>
          <p class="padding10">
            {{ popoverDesc }}
          </p>
          <h5 class="padding20">
            {{ "sioFrontPage.popOver.comparetoIndex" | translate }}
          </h5>
          <p class="padding15">
            {{ "sioFrontPage.popOver.unlike" | translate }}
          </p>
          <div class="fee-container">
            <p [innerHtml]="'sioFrontPage.popOver.fees' | translate"></p>
            <ion-row>
              <ion-col class="vio" size="2">
                {{ "sioFrontPage.popOver.sio" | translate }}</ion-col
              >
              <ion-col class="cap-rate" size="10">
                {{ "sioFrontPage.popOver.sioText" | translate }}</ion-col
              >
            </ion-row>
            <ion-row *appFeatureFlag="'isIR'">
              <ion-col class="vio" size="2">
                {{ "sioFrontPage.popOver.vio" | translate }}</ion-col
              >
              <ion-col class="cap-rate" size="10">
                {{ "sioFrontPage.popOver.vioText" | translate }}</ion-col
              >
            </ion-row>
          </div>
        </div>
      </ion-col>
      <ion-col size="5">
        <div class="popover-data-table-container">
          <div class="time-frame-seprator" *ngFor="let i of ar">
            <p class="table-header-text">{{ i.duration }}</p>
            <ion-row>
              <ion-col class="table-header">
                {{ "sioFrontPage.popOver.segmentOption" | translate }}
              </ion-col>
              <ion-col class="table-header">
                {{ "sioFrontPage.popOver.index" | translate }}</ion-col
              >
              <ion-col class="table-header">
                {{
                  "sioFrontPage.popOver.protectionBuffer" | translate
                }}</ion-col
              >
            </ion-row>
            <ion-row
              *ngFor="let j of i.data; index as n"
              class=" data-row data-row-{{ n }}"
            >
              <ion-col class="margin-auto">{{ j.approach }}</ion-col>
              <ion-col>
                <p *ngFor="let k of j.index">{{ k }}</p>
              </ion-col>
              <ion-col class="margin-auto"
                ><span *ngFor="let l of j.buffer">{{ l }}% </span
                ><span>&nbsp; </span></ion-col
              >
            </ion-row>
          </div>
        </div>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="center-align">
        <equitable-button
          (click)="closeModal()"
          class="btn-main"
          type="primary"
          theme="light"
          ><span class="btn-text">{{
            "sioFrontPage.popOver.btnLabel" | translate
          }}</span></equitable-button
        >
      </ion-col>
    </ion-row>
  </div>
</ion-content>
