import { UtilityService } from "./../../services/utility.service";
import { QuestionnaireService } from "../../services/questionnaire.service";
import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { QuestionnaireResults, Annuity } from "../../models/app.models";
import { ButtonType, Theme, LinkType } from "equitable-component-library";
import { ROUTE_PATH, PRODUCT_NAME } from "../../../constants/constants";

@Component({
  selector: "app-questionnaire-result",
  templateUrl: "./questionnaire-result.component.html",
  styleUrls: ["./questionnaire-result.component.scss"]
})
export class QuestionnaireResultComponent implements OnInit {
  //Outputs
  @Output() retakeQuiz = new EventEmitter<number>();
  //Class Variables
  resultData: QuestionnaireResults;
  selectedInvestorIndex = 0;
  suggestedAnnuity: Annuity;
  otherAnnuities: Array<Annuity>;
  description: string;
  linkType = LinkType.Main;
  button = {
    type: ButtonType.Primary,
    theme: Theme.Light
  };

  constructor(
    private questionnaireService: QuestionnaireService,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.questionnaireService
      .getResults()
      .subscribe((data: QuestionnaireResults) => {
        this.resultData = data;
        this.suggestedAnnuity = data.suggestedAnnuity;
        this.otherAnnuities = data.defaultAnnuity;
      });
  }

  retakeButtonClicked() {
    this.retakeQuiz.emit();
  }

  onClickInvestor(title) {
    if (title == this.resultData.investorProfiles[0].title) {
      this.selectedInvestorIndex = 0;
    } else {
      this.selectedInvestorIndex = 1;
    }
  }

  navigateToProducts(item) {
    switch (item) {
      case PRODUCT_NAME.SCS:
        this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER);
        break;
      case PRODUCT_NAME.RC:
        this.utilityService.navigate(ROUTE_PATH.RC.DISCOVER);
        break;
      case PRODUCT_NAME.IE:
        this.utilityService.navigate(ROUTE_PATH.IE.DISCOVER);
        break;
    }
  }
}
