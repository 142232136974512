import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DisclosureComponent } from "./../disclosure/disclosure.component";
import { DISCLOSURE } from "../../../constants/constants";
import { UtilityService } from "../../services/utility.service";
import { SITE } from "../../../../assets/sites";
import { LinkType } from "equitable-component-library";

@Component({
  selector: "app-disclosure-rc",
  templateUrl: "./disclosure-rc.component.html",
  styleUrls: ["./disclosure-rc.component.scss"]
})
export class DisclosureRcComponent implements OnInit {
  activeIndex = 0;
  btns: any;
  selectedName: number;
  toolName: string;
  site = SITE;
  // for read more link
  readMoreLinkType = LinkType.Dark;

  constructor(
    private translate: TranslateService,
    private discloure: DisclosureComponent,
    private utilityService: UtilityService
  ) {
    this.toolName = this.discloure.selectedTool;
  }

  ngOnInit() {
    this.translate.get("disclosure.rc").subscribe(disclosure => {
      this.btns = [
        { id: 0, lable: disclosure.terms },
        { id: 1, lable: disclosure.ageLimitation },
        { id: 2, lable: disclosure.diversity },
        { id: 3, lable: disclosure.guaranteedIncome },
        { id: 4, lable: disclosure.inflation },
        { id: 5, lable: disclosure.interestRate },
        { id: 6, lable: disclosure.investmentOptions },
        { id: 7, lable: disclosure.investTaxSmart },
        { id: 8, lable: disclosure.market },
        { id: 9, lable: disclosure.incomePotential }
      ];
    });
    this.toolSelection();
  }

  //tab change selection function
  toolSelection() {
    if (DISCLOSURE.RC[this.toolName]) {
      this.selectedName = DISCLOSURE.RC[this.toolName];
    } else {
      this.selectedName = 0;
    }
    this.openTab(this.selectedName);
  }

  // To display div
  openTab(id) {
    this.activeIndex = parseInt(id);
  }

  // visit external site Equitable.com
  visitEquitable(url) {
    this.utilityService.visitEquitableMicrosite(url);
  }
}
