import { UtilityService } from "../../../../../src/app/core/services/utility.service";
import { Component, OnInit } from "@angular/core";
import { LinkType, LinkIcon, Orientation } from "equitable-component-library";
import { ROUTE_PATH } from "src/app/constants/constants";

@Component({
  selector: "app-howitworks-tool",
  templateUrl: "./howitworks-tool.component.html",
  styleUrls: ["./howitworks-tool.component.scss"],
})
export class HowitworksToolComponent implements OnInit {
  //variables
  LinkType = LinkType;
  LinkIcon = LinkIcon;
  Orientation = Orientation;

  /***
   *  Constructor for the page
   ***/
  constructor(private utilityService: UtilityService) { }

  /***
   *  Page load for page
   ***/
  ngOnInit() { 
    console.log("This componenet is being used.");
  }

  /***
 *  back button click event
 ***/
  backButton() {
    this.utilityService.navigate(ROUTE_PATH.IE.TOOLS.HOME);
  }
}
