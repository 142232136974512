<ion-content class="glossary-page">
  <ion-grid class="glossary-grid">
    <ion-row class="back-link">
      <ion-col>
        <equitable-link
          text="{{ 'glossary.backLinkText' | translate }}"
          routerLink="/home"
          [type]="backLinkType"
          [iconName]="backLinkIconName"
          [orientation]="backLinkIconOrientation"
        ></equitable-link>
      </ion-col>
    </ion-row>
    <form [formGroup]="glossaryForm">
      <ion-row class="heading-filter ion-align-items-end">
        <ion-col>
          <ion-label class="glossary-label-text">{{
            "glossary.labelHeader" | translate
          }}</ion-label>
        </ion-col>
        <ion-col>
          <ion-grid class="ion-no-padding select-mod">
            <ion-row class="ion-no-padding">
              <ion-col>
                <ion-label>{{
                  "glossary.filterByProduct" | translate
                }}</ion-label>
              </ion-col>
            </ion-row>
            <ion-row class="ion-no-padding">
              <ion-col>
                <equitable-drop-down
                  [options]="dropDownOptions"
                  formControlName="formControlNameDropDown"
                  [formControl]="formControlDropDown"
                  (change)="startSearch('a', formControlDropDown.value)"
                ></equitable-drop-down>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>
      </ion-row>
      <ion-row class="searchbar">
        <ion-col>
          <equitable-search-bar
            placeholder="{{ 'glossary.searchbarPlaceholder' | translate }}"
            formControlName="formControlNameSearchbar"
            [formControl]="formControlSearchbar"
          ></equitable-search-bar>
        </ion-col>
      </ion-row>
    </form>
    <ion-row *ngIf="resultCount > 0">
      <ion-col>
        <ion-item class="search-result-item ion-no-padding">
          <ion-label class="search-result"
            >{{ "glossary.showing" | translate }} {{ resultCount }}
            {{ resultCount === 1 ? "Result" : "Results" }}</ion-label
          >
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="resultCount === 0">
      <ion-col>
        <ion-item class="search-result-item ion-no-padding">
          <ion-label class="search-result"
            >{{ "glossary.showing" | translate }} {{ resultCount }}
            {{ "glossary.result" | translate }}</ion-label
          >
        </ion-item>
      </ion-col>
    </ion-row>
    <div class="search-result-section">
      <ion-row *ngFor="let item of searchResults">
        <ion-col>
          <ion-row class="result-label-header">
            <ion-col>
              <ion-label> {{ item.word }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="result-label-text">{{
                item.definition
              }}</ion-label>
            </ion-col>
          </ion-row>
          <div class="item-divider"></div>
        </ion-col>
      </ion-row>
    </div>
  </ion-grid>
  <app-footer
    isWhiteFooterVisible="true"
    selectedProduct="glossary"
  ></app-footer>
</ion-content>
