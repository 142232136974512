<!-- Web design back button -->
<ion-content>
  <form [formGroup]="profile">
    <article class="container-custom-profile site-tools">
      <div class="back-link">
        <equitable-link
          class="equitable-link back-link"
          [text]="backLink"
          [iconName]="linkLeftIcon"
          type="dark"
          orientation="left"
          (click)="backButton()"
        ></equitable-link>
      </div>

      <ion-grid class="ion-padding-top">
        <ion-row class="ion-padding-top">
          <ion-col size="4" class="custom-profile-list pl-0">
            <ion-list-header class="pl-0 profile-add-btn-section">
              <ion-label class="label-text-title">{{
                "customProfileText.addProfileLabel" | translate
              }}</ion-label>
              <equitable-button
                type="secondary"
                background="light"
                (click)="addProfile()"
                >{{ "customProfileText.addProfile" | translate
                }}<ion-icon
                  class="icon"
                  src="./assets/icons/add-profile.svg"
                ></ion-icon
              ></equitable-button>
            </ion-list-header>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="4" class="custom-profile-list pl-0">
            <div class="inner-container">
              <ion-list class="investor-list">
                <div *ngIf="customProfileData && customProfileData.length > 0">
                  <ion-item
                    *ngFor="let item of customProfileData; let i = index"
                    lines="none"
                    class="custom-list-item"
                    [ngClass]="i === listIndex ? 'active' : ''"
                    (click)="displayDetail(item, i)"
                  >
                    <ion-label>
                      <ion-text class="profile-title">
                        <h3>{{ item.name }}</h3>
                      </ion-text>
                      <ion-text>
                        <p>{{ item.comment }}</p>
                      </ion-text>
                    </ion-label>
                  </ion-item>
                </div>
                <div *ngIf="customProfileData.length == 0">
                  <ion-item>
                    {{ "customProfileText.noSavedProfile" | translate }}
                  </ion-item>
                </div>
              </ion-list>
            </div>
          </ion-col>
          <ion-col size="8" class="custom-profile-detail-container">
            <ion-row>
              <ion-col size="6">
                <div *ngIf="currentProfile.name != '' && isEditMode === false">
                  <ion-text class="label-text-title">
                    <h3>{{ currentProfile.name }}</h3>
                  </ion-text>
                </div>
                <div class="section-devider">
                  <ion-item
                    class="form-element"
                    *ngIf="currentProfile.name == '' || isEditMode === true"
                  >
                    <ion-label class="ion-hide">{{
                      "customProfileText.enterName" | translate
                    }}</ion-label>
                    <ion-input
                      type="text"
                      formControlName="profileName"
                      placeholder="{{
                        'customProfileText.enterName' | translate
                      }}"
                      [maxlength]="maxlength"
                    ></ion-input>
                  </ion-item>
                  <ion-list-header class="pl-0 profile-comment-section">
                    <ion-text class="form-label">{{
                      "customProfileText.profileComments" | translate
                    }}</ion-text>
                    <ion-text *ngIf="currentProfile.name != ''" class="date">
                      {{ "customProfileText.dateCreated" | translate }}
                      {{ currentProfile.date }}
                    </ion-text>
                  </ion-list-header>
                  <div class="form-element-textarea">
                    <ion-item class="form-element">
                      <ion-textarea
                        formControlName="profileComment"
                        rows="10"
                        auto-grow="true"
                        placeholder="{{
                          'customProfileText.enterComments' | translate
                        }}"
                        [maxlength]="maxlengthTextArea"
                      ></ion-textarea>
                    </ion-item>
                  </div>
                  <article class="ion-text-right">
                    <equitable-link
                      *ngIf="!isEditMode && showEdit === true"
                      class="equitable-link margin-right-link"
                      text="{{ 'general.edit' | translate }}"
                      type="dark"
                      orientation="left"
                      (click)="editProfile()"
                    ></equitable-link>
                    <equitable-link
                      *ngIf="isEditMode"
                      class="equitable-link margin-right-link"
                      text="{{ 'general.delete' | translate }}"
                      type="dark"
                      orientation="left"
                      (click)="deleteProfile()"
                    ></equitable-link>
                    <equitable-button
                      type="primary"
                      theme="light"
                      (click)="saveProfile()"
                      [disabled]="!profile.valid"
                      >{{ btnSaveProfile }}
                    </equitable-button>
                  </article>
                </div>
              </ion-col>
              <ion-col size="6" class="custom-content-list">
                <ion-list>
                  <ion-text class="label-text-title">
                    <h3>
                      {{ "customProfileText.customContentTitle" | translate }}
                    </h3>
                  </ion-text>
                  <ion-text>
                    <p>
                      {{
                        "customProfileText.customContentSubTitle" | translate
                      }}
                    </p>
                  </ion-text>
                  <ion-item
                    class="item-padding-left"
                    lines="none"
                    *ngFor="let item of customContents"
                  >
                    <ion-avatar slot="start" class="image-square">
                      <img
                        alt="{{ item.description | translate }}"
                        class="image"
                        src="{{ item.imagePath }}"
                      />
                    </ion-avatar>
                    <ion-label class="custom-item-label">
                      <h2>
                        {{ item.description | translate }}
                      </h2>
                      <equitable-link
                        *ngIf="profile.valid"
                        class="equitable-link custom-content-btn"
                        text="{{ item.link.label | translate }}"
                        type="dark"
                        orientation="left"
                        (click)="navigateToCustomContent(item.link.path)"
                      ></equitable-link>
                    </ion-label>
                  </ion-item>
                </ion-list>
              </ion-col>
            </ion-row>
            <span class="now-viewing"
              >{{ "scs.nowView" | translate }} {{ series }}</span
            >
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="custom-profile-list pl-0">
            <div class="link-save-load-profile">
              <equitable-link
                class="equitable-link"
                text="{{ 'investorProfileText.btnSampleProfile' | translate }}"
                [iconName]="linkRightIcon"
                type="dark"
                orientation="right"
                (click)="goToSampleProfile()"
              ></equitable-link>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </article>
  </form>
  <app-footer
    isBlueFooterVisible="true"
    isWhiteFooterVisible="true"
    [selectedProduct]="series"
    activeTab="Investor_profile"
  ></app-footer>
</ion-content>
