<ion-content (ionScroll)="scroll($event)" [scrollEvents]="true">
  <div class="manage-page site-tools">
    <div class="scs-back-row">
      <equitable-sub-header
        title="{{ 'createReport.personalize-label' | translate }}"
        [backLinkText]="backButtonText"
        (backClick)="backButton()"
      ></equitable-sub-header>
    </div>
    <div class="print-label">
      {{ "createReport.pdf-guideline" | translate }}
    </div>
    <div class="sprite-card">
      <div class="utilize-cards">
        <ion-row class="ion-justify-content-center">
          <div class="create-report-page">
            <div class="utilize-cards-wrapper">
              <div class="step-desc">
                {{ "createReport.stepDescription" | translate }}
              </div>
              <div [formGroup]="formGroup">
                <div class="input-headings">
                  {{ "createReport.prepared-by" | translate }}
                </div>
                <div class="manage-input">
                  <equitable-input
                    placeholder=" {{
                      'createReport.professionalName' | translate
                    }} "
                    [formControl]="formGroup.get('professionalName')"
                    fieldName=" {{
                      'createReport.professionalName' | translate
                    }} "
                    [maxlength]="maxlength"
                  ></equitable-input>
                </div>
                <div class="manage-input">
                  <equitable-input
                    placeholder=" {{ 'createReport.firmName' | translate }} "
                    [formControl]="formGroup.get('firmName')"
                    fieldName=" {{ 'createReport.firmName' | translate }} "
                    [maxlength]="maxlength"
                  ></equitable-input>
                </div>
                <div class="manage-input">
                  <equitable-input
                    placeholder=" {{
                      'createReport.contactNumber' | translate
                    }} "
                    [formControl]="formGroup.get('contactNumber')"
                    fieldName=" {{ 'createReport.contactNumber' | translate }} "
                    [maxlength]="maxlength"
                  ></equitable-input>
                </div>
                <div class="utilize-prepared-for input-headings">
                  {{ "createReport.prepared-for" | translate }}
                </div>
                <div class="manage-input">
                  <equitable-input
                    placeholder=" {{ 'createReport.name' | translate }} "
                    [formControl]="formGroup.get('name')"
                    fieldName=" {{ 'createReport.name' | translate }} "
                    [maxlength]="maxlength"
                  ></equitable-input>
                </div>
              </div>
            </div>
            <div class="section-custom-content">
              <div class="step-desc">
                {{ "createReport.stepDescription2" | translate }}
              </div>
              <div class="step-two-label">
                {{ "createReport.step-two-label" | translate }}
              </div>
              <div [formGroup]="formGroup" class="utilize-checkbox-section">
                <div
                  class="utilize-checkbox"
                  *ngFor="let item of customeContents"
                >
                  <equitable-checkbox
                    [formControlName]="item.formControlName"
                    [formControl]="item.formControl"
                    [description]="item.description"
                    [link]="item.link"
                    [imagePath]="item.imagePath"
                  ></equitable-checkbox>
                </div>
              </div>
            </div>
            <div class="section-resource-material">
              <div class="step-desc">
                {{ "createReport.stepDescription3" | translate }}
                <span class="optional-text">
                  (optional)
                </span>
              </div>
              <div class="step-two-label">
                {{ "createReport.step-two-bottom-label" | translate }}
              </div>
              <div [formGroup]="formGroup" class="learning-checkbox">
                <div
                  class="select-learning-resource"
                  *ngFor="let item of resourceMaterials"
                >
                  <equitable-checkbox
                    [formControlName]="item.description"
                    [formControl]="formGroup.get(item.description)"
                    [description]="item.description"
                    [imagePath]="item.image"
                  ></equitable-checkbox>
                </div>
              </div>
            </div>
          </div>
        </ion-row>
      </div>
    </div>

    <div class="utilize-preview-button">
      <div class="view-report-button">
        <equitable-button type="primary" theme="light" (click)="generatePDF()">
          {{ "createReport.preview-document" | translate }}
        </equitable-button>
      </div>
      <div class="product-label" *ngIf="isNotPlusGuard">
        {{ "createReport.nowViewing" | translate }} {{ series }}
      </div>
    </div>

    <div class="pdf-label">
      <p>
        {{ "createReport.create-report-para1" | translate }}
      </p>
      <p>
        {{ "createReport.create-report-para2" | translate }}
      </p>
    </div>
  </div>
  <app-footer
    isBlueFooterVisible="true"
    isWhiteFooterVisible="true"
    [selectedProduct]="series"
  ></app-footer>
</ion-content>
