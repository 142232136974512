import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { UtilityService } from "../../../core/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import {
  ROUTE_PATH,
  CHARACTER_LIMIT,
  VALIDATION_TYPE,
  PRODUCT_TYPE,
} from "../../../constants/constants";
import { SITE } from "src/assets/sites";
import { Profile } from "../../../core/models/app.models";
import { CoreService } from "../../../core/services/core.service";
import { RcService } from "../../services/rc.service";
import { TealiumUtagService } from '../../../tealium/utag.service';

@Component({
  selector: "app-report-rc",
  templateUrl: "./report-rc.component.html",
  styleUrls: ["./report-rc.component.scss"],
})
export class ReportRcComponent implements OnInit {
  //Variables
  route = ROUTE_PATH.RC.TOOLS;
  formGroup: FormGroup;
  resourceMaterials = [];
  maxlength = CHARACTER_LIMIT.INPUT;

  /***
   *  constructor
   ***/
  constructor(
    private formBuilder: FormBuilder,
    private utilityService: UtilityService,
    private translate: TranslateService,
    private coreService: CoreService,
    private rcService: RcService,
    private tealium: TealiumUtagService,
  ) { }

  /***
   *  Page Init
   ***/
  ngOnInit() {
    //create form
    this.buildForm();

    //get page data
    this.getPageData();
  }

  /***
   *  Page Load/Init
   ***/
  ionViewWillEnter() {
    //create form
    this.buildForm();

    //get page data
    this.getPageData();
  }

  /***
   *  Back button event. Go back to tool home page
   ***/
  backButton() {
    this.utilityService.navigate(ROUTE_PATH.RC.TOOLS.HOME);
  }

  /***
   *  Create form
   ***/
  buildForm() {
    this.formGroup = this.formBuilder.group({
      professionalName: new FormControl(
        { value: this.coreService.report.profName, disabled: false }
      ),
      name: new FormControl(this.coreService.report.name),
      firmName: new FormControl(this.coreService.report.firmName),
      contactNumber: new FormControl(this.coreService.report.contactNumber),
    });

    //save data to service using form change value
    this.formGroup.valueChanges.subscribe((data) => {
      this.coreService.report.name = data.name;
      this.coreService.report.firmName = data.firmName;
      this.coreService.report.contactNumber = data.contactNumber;
      this.coreService.report.profName = data.professionalName;
    });
  }

  /***
   *  get Data for resources
   ***/
  getPageData() {
    //get resources for services
    this.rcService.fetchResources().subscribe((res) => {
      this.resourceMaterials = res["SalesKitFiles"];

      //add more form control dynamically to track the resources
      this.resourceMaterials.forEach((element) => {
        const control = new FormControl(element.description);
        control.setValue(false);
        this.formGroup.addControl(element.description, control);
      });
    });
  }

  /***
   *  get Data for resources
   ***/
  generatePDF() {
    if (this.formGroup.valid) {
      const utilizeData = new Profile();
      utilizeData.sourceURL = SITE.AXA;
      utilizeData.name = this.formGroup.get("name").value;
      utilizeData.profName = this.formGroup.get("professionalName").value;
      utilizeData.firmName = this.formGroup.get("firmName").value;
      utilizeData.contactNumber = this.formGroup.get("contactNumber").value;

      //add learning resources
      const obj = {};
      this.resourceMaterials.forEach((element) => {
        if (this.formGroup.get(element.description).value === true) {
          obj[element.description] = element.file;
        }
      });
      utilizeData.learningResourcesIncluded = obj;

      //generate PDF
      this.coreService.pdfFetchResources(utilizeData, PRODUCT_TYPE.RC);
    } else {
      this.utilityService.showValidationErrorForControls(this.formGroup);
    }

    // add tealium_event: "Generate_PDF" ?
    this.tealium.link({
      event_name: "Retirement Guide Clicks - RC",
      tool_name: "BodyClicks",
      event_label: "Create a report"
    })
  }
}
