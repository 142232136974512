<equitable-tab-slider
  (changeData)="openTab($event)"
  [names]="btns"
  [selectedName]="selectedName"
></equitable-tab-slider>

<!-- Tab 1  termsOfUse-->
<div *ngIf="activeIndex === 0">
  <h1
    class="title-heading"
    [innerHTML]="'disclosure.scs.tab1title' | translate"
  ></h1>
  <div class="content">
    <ul class="noindent">
      <span>{{ "disclosure.scs.listElementTab1ListHeading" | translate }}</span>
      <li
        [innerHTML]="'disclosure.scs.listElementTab1ListCont' | translate"
      ></li>
    </ul>

    <div [innerHTML]="'disclosure.scs.tab1.content1' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content2' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content3' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content4' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content5' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content6' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content7' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content8' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content9' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content10' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content11' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content12' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content13' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content14' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content15' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content16' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content17' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content18' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content19' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content20' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content21' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content22' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content23' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content24' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content25' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content26' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content27' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content28' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content29' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content30' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content31' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content32' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content33' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content34' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content35' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content37' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content38' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content39' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content40' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content41' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content42' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content43' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content44' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content45' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content46' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content47' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content48' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab1.content49' | translate"></div>
  </div>
</div>

<!-- Tab 2  Get Started-->
<div *ngIf="activeIndex === 1">
  <h1
    class="title-heading"
    [innerHTML]="'disclosure.scs.tab2.heading' | translate"
  ></h1>
  <div class="content">
    <ul>
      <span>{{ "disclosure.scs.listElementTab2Heading" | translate }}</span>
      <li
        [innerHTML]="'disclosure.scs.listElementTab2ListCont' | translate"
      ></li>
      <li [innerHTML]="'disclosure.scs.tab2.subHeading' | translate"></li>
    </ul>
    <div [innerHTML]="'disclosure.scs.tab2.content1' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab2.content2' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab2.content3' | translate"></div>

    <ul>
      {{
        "disclosure.scs.tab2.content4" | translate
      }}
      <li [innerHTML]="'disclosure.scs.tab2.content5' | translate"></li>
      <li [innerHTML]="'disclosure.scs.tab2.content6' | translate"></li>
    </ul>
  </div>
</div>

<!-- Tab 3  Historical Index Return-->
<div *ngIf="activeIndex === 2">
  <h1 class="title-heading">
    {{ "disclosure.scs.tab3.content1" | translate }}
  </h1>
  <div class="content" *appFeatureFlag="'isNational'">
    <div [innerHTML]="'disclosure.scs.tab3.content2' | translate"></div>
    <ul>
      <li [innerHTML]="'disclosure.scs.tab3.content10' | translate"></li>
      <li [innerHTML]="'disclosure.scs.tab3.content11' | translate"></li>
      <li [innerHTML]="'disclosure.scs.tab3.content12' | translate"></li>
      <li [innerHTML]="'disclosure.scs.tab3.content3' | translate"></li>
      <li [innerHTML]="'disclosure.scs.tab3.content4' | translate"></li>
      <li [innerHTML]="'disclosure.scs.tab3.content5' | translate"></li>
      <li [innerHTML]="'disclosure.scs.tab3.content6' | translate"></li>
      <li [innerHTML]="'disclosure.scs.tab3.content7' | translate"></li>
      <li [innerHTML]="'disclosure.scs.tab3.content8' | translate"></li>
    </ul>
    <div>
      {{ "disclosure.scs.tab3.content9" | translate }}
    </div>
  </div>
  <div class="content" *appFeatureFlag="'isPlusGuard'">
    <div [innerHTML]="'disclosure.scs.tab3.content2' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab3.content3' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab3.content4' | translate"></div>
    <ul>
      <li [innerHTML]="'disclosure.scs.tab3.content10' | translate"></li>
      <li [innerHTML]="'disclosure.scs.tab3.content11' | translate"></li>
      <li [innerHTML]="'disclosure.scs.tab3.content12' | translate"></li>
      <li [innerHTML]="'disclosure.scs.tab3.content3' | translate"></li>
      <li [innerHTML]="'disclosure.scs.tab3.content4' | translate"></li>
      <li [innerHTML]="'disclosure.scs.tab3.content5' | translate"></li>
      <li [innerHTML]="'disclosure.scs.tab3.content6' | translate"></li>
      <li [innerHTML]="'disclosure.scs.tab3.content7' | translate"></li>
      <li [innerHTML]="'disclosure.scs.tab3.content8' | translate"></li>
    </ul>
    <div>
      {{ "disclosure.scs.tab3.content9" | translate }}
    </div>
  </div>
  <div class="content" *appFeatureFlag="'isPrimerica'">
    <div [innerHTML]="'disclosure.scs.tab3.content2' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab3.content4' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab3.content5' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab3.content6' | translate"></div>
    <div [innerHTML]="'disclosure.scs.tab3.content7' | translate"></div>
    <ul class="bullet">
      <li [innerHTML]="'disclosure.scs.tab3.content8' | translate"></li>
      <li [innerHTML]="'disclosure.scs.tab3.content9' | translate"></li>
      <li [innerHTML]="'disclosure.scs.tab3.content10' | translate"></li>
      <li [innerHTML]="'disclosure.scs.tab3.content11' | translate"></li>
    </ul>
    <div [innerHTML]="'disclosure.scs.tab3.content12' | translate"></div>
    <div>{{ "disclosure.scs.tab3.content13" | translate }}</div>
    <div>{{ "disclosure.scs.tab3.content14" | translate }}</div>
    <ul class="bullet">
      <li [innerHTML]="'disclosure.scs.tab3.content15' | translate"></li>
      <li [innerHTML]="'disclosure.scs.tab3.content16' | translate"></li>
      <li [innerHTML]="'disclosure.scs.tab3.content17' | translate"></li>
    </ul>
  </div>
</div>

<!-- Tab 4  Performance Cap Trend-->
<div *ngIf="activeIndex === 3">
  <h1 class="title-heading">
    {{ "disclosure.scs.tab4.content1" | translate }}
  </h1>
  <div class="content">
    <div>
      {{ "disclosure.scs.tab4.content2" | translate }}
    </div>
    <div>
      {{ "disclosure.scs.tab4.content3" | translate }}
    </div>
  </div>
</div>

<!-- Tab 5  Investor Profiles-->
<div *ngIf="activeIndex === 4">
  <h1 class="title-heading">
    {{ "disclosure.scs.tab5.content1" | translate }}
  </h1>
  <div class="content">
    <div>
      {{ "disclosure.scs.tab5.content2" | translate }}
    </div>
  </div>
</div>
