export const SITE = {
  EQUITABLE: "https://equitable.com/",
  EQUITABLE_RATES: "https://equitable.com/retirement/products/variable-annuities/retirement-cornerstone/rates",
  AXA: "https://equitable.com",
  EQUITABLE_PERSPECTIVES_MICROSITE: "https://equitable.com/perspectives/third-party",
  EQUITABLE_ABOUT: "https://equitable.com/about-us",
  EQUITABLE_FOUNDATION: "https://equitable.com/foundation",
  EQUITABLE_CAREERS: "https://equitable.com/about-us/careers",
  QUALTRICS: "https://equitable.qualtrics.com/jfe/form/SV_9TQXXTOM2qi3XzT",
  EQUITABLE_HOLDINGS: "https://equitableholdings.com/",
  EQUITABLE_PRIVACY:
    "https://equitable.com/customer-service/privacy-security",
  EQUITABLE_USAGE: "https://equitable.com/about-us/usage",
  FINRA: "https://www.finra.org/",
  SIPC: "https://www.sipc.org/",
  EQUITABLE_FACEBOOK: "https://www.facebook.com/EquitableFinancial",
  EQUITABLE_TWITTER: "https://twitter.com/EquitableFin",
  EQUITABLE_LINKEDIN: "https://www.linkedin.com/company/equitable-financial",
  EQUITABLE_INSTAGRAM: "https://www.instagram.com/equitable.financial",
  FINRA_BROKER: "https://brokercheck.finra.org/",
  EQUITABLE_CONTACT: "https://equitable.com/support",
  MULTPL: "http://www.multpl.com/"
};
