import { FeatureFlagService } from "../../../core/services/feature-flag.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CoreService } from "../../services/core.service";
import { InvestorProfile, SCS } from "../../../core/models/app.models";
import { UtilityService } from "../../../core/services/utility.service";
import { ScsService } from "../../../scs/services/scs.service";

import {
  SCS_SERIES,
  PRODUCT_TYPE,
  ROUTE_PATH,
  CUSTOMIZATION_TYPE,
  INVESTOR_PROFILE,
  INVESTOR_PROFILE_REDIRECT,
} from "../../../constants/constants";
import { IonicStorageService } from "../../../core/services/ionic-storage.service";
import { Theme, LinkIcon, Orientation } from "equitable-component-library";

@Component({
  selector: "app-investor-profile",
  templateUrl: "./investor-profile.component.html",
  styleUrls: ["./investor-profile.component.scss"],
})
export class InvestorProfileComponent implements OnInit {
  investorDataItems: any = [];
  investorData: any;
  investorDataDetail: any;
  listIndex: any = 0;
  scenarios: any;
  investorHeader: string;
  investorBodyCopy: string;
  profileImage: string;
  profileTitle: string;
  profileSubTitle: string;
  profileQuote: string;
  profileDescription: any;
  profileDetail: any;
  sectionName: any;
  productDetail: any;
  scenariosSectionName: any;
  profileSolutionCTA: any;
  isScs = false;
  isNotPlusGuard: boolean;
  dashboardTool: string;
  ctaBackButton: string;
  productIndex: any = "";
  productDuration: any = "";
  productProtection: any = "";
  productUrl: any = "";
  linkRightIcon = LinkIcon.RightIcon;
  linkLeftIcon = LinkIcon.LeftIcon;
  seriesValue: string;
  constructor(
    private coreService: CoreService,
    private route: ActivatedRoute,
    private utilityService: UtilityService,
    private scsService: ScsService,
    private storageService: IonicStorageService,
    private flagService: FeatureFlagService
  ) {}

  ngOnInit() {
    this.setinvestorDataIData();
    this.route.queryParams.subscribe((params) => {
      this.dashboardTool = params["section"];
    });
    this.isNotPlusGuard = !this.flagService.featureFlags.isPlusGuard
  }

  // Method to get InvestorProfile data using translateservice
  setinvestorDataIData(): void {
    this.seriesValue = this.scsService.series
      ? this.scsService.series
      : SCS_SERIES.PLUS;
    this.coreService
      .getInvestorProfileResults()
      .subscribe((data: InvestorProfile[]) => {
        this.investorDataItems = data;
        this.investorHeader = this.investorDataItems.header;
        this.investorBodyCopy = this.investorDataItems.bodyCopy;
        if (this.flagService.featureFlags.isIR) {
          console.log('XXX1')
          if (this.dashboardTool === PRODUCT_TYPE.SCS) {
            console.log('XXX12')
            this.isScs = true;
            let itemData;
            this.investorDataItems.scs.filter((item) => {
              if (this.seriesValue === SCS_SERIES.B) {
                itemData = item["seriesB"];
              } else if (this.seriesValue === SCS_SERIES.C) {
                itemData = item["seriesC"];
              } else if (this.seriesValue === SCS_SERIES.PLUS21) {
                console.log('XXX321')
                //itemData = item["seriesPlus21"];
                itemData = item["seriesPlus"];
              } 
              else if (this.seriesValue === SCS_SERIES.PLUS) {
                console.log('XXX3')
                itemData = item["seriesPlus"];
              } else if (this.seriesValue === SCS_SERIES.ADV) {
                itemData = item["seriesADV"];
              }
            });
            this.investorData = itemData;
          } else if (this.dashboardTool === PRODUCT_TYPE.SCSINCOME) {
            this.isScs = false;
            this.investorData = this.investorDataItems.scsincome;
          }
          
          else if (this.dashboardTool === PRODUCT_TYPE.RC) {
            this.isScs = false;
            this.investorData = this.investorDataItems.rc;
          } else if (this.dashboardTool === PRODUCT_TYPE.IE) {
            this.isScs = false;
            this.investorData = this.investorDataItems.ie;
          }
        } else if (!this.flagService.featureFlags.isPrimerica) {
          console.log('XXX3')
          this.isScs = true;
          this.investorData = this.investorDataItems.wellsFargo;
        } else {
          console.log('XXX4')
          if (this.dashboardTool === PRODUCT_TYPE.RC) {
            this.isScs = false;
            this.investorData = this.investorDataItems.rc;
          } else {
            this.isScs = true;
            this.investorData = this.investorDataItems.primerica;
          }
          if (this.dashboardTool === PRODUCT_TYPE.SCS) {
            console.log('XXX5')
            this.isScs = true;
            let itemData;
            this.investorDataItems.primerica.filter((item) => {
              if (this.seriesValue === SCS_SERIES.B) {
                itemData = item["seriesB"];
              } else if (this.seriesValue === SCS_SERIES.C) {
                itemData = item["seriesC"];
              } else if (this.seriesValue === SCS_SERIES.PLUS) {
                itemData = item["seriesPlus"];
              } else if (this.seriesValue === SCS_SERIES.PLUS21) {
                itemData = item["seriesPlus21"];
              } 
              else if (this.seriesValue === SCS_SERIES.ADV) {
                itemData = item["seriesADV"];
              }
            });
            this.investorData = itemData;
          } else if (this.dashboardTool === PRODUCT_TYPE.RC) {
            this.isScs = false;
            this.investorData = this.investorDataItems.rc;
          }
        }
        if (
          this.coreService.profile.getValue().name !== "" &&
          this.coreService.profile.getValue().type ==
            CUSTOMIZATION_TYPE.INVESTOR_PROFILE
        ) {
          this.listIndex = this.investorData.findIndex(
            (x) => x.profileTitle == this.coreService.profile.getValue().name
          );
        }

        this.displayDetail(this.listIndex);
      });
  }

  displayDetail(index) {
    this.listIndex = index;
    this.investorDataDetail = this.investorData[index];
    this.profileImage = this.investorDataDetail.profileImage;
    this.profileTitle = this.investorDataDetail.profileTitle;
    this.profileSubTitle = this.investorDataDetail.profileSubTitle;
    this.profileQuote = this.investorDataDetail.profileQuote;
    this.profileDescription = this.investorDataDetail.profileDescription;
    this.profileDetail = this.investorDataDetail.profileDetail;
    this.sectionName = this.investorDataDetail.profileSolutionCTA[0].sectionName;
    this.profileSolutionCTA = this.investorDataDetail.profileSolutionCTA;
    this.scenarios =
      this.investorDataDetail.scenarios !== undefined &&
      this.investorDataDetail.scenarios !== null &&
      this.investorDataDetail.scenarios.length > 0
        ? this.investorDataDetail.scenarios
        : "";
    this.ctaBackButton =
      this.investorDataDetail.ctaBackButton !== undefined &&
      this.investorDataDetail.ctaBackButton !== null
        ? this.investorDataDetail.ctaBackButton
        : "Back";
    if (this.scenarios.length > 0) {
      this.productIndex = this.scenarios[0].index;
      this.productDuration = this.scenarios[0].duration
        ? this.scenarios[0].duration + " Year"
        : this.scenarios[0].duration + " Years";
      this.productProtection = this.scenarios[0].protection + "%";
      this.productUrl = this.scenarios[0].url;
    }
  }

  // back button navigation
  backButton() {
    this.utilityService.navigateToBack();
  }

  gotoOverview(name) {
    if (this.flagService.featureFlags.isIR) {
      if (name === INVESTOR_PROFILE_REDIRECT.RC) {
        this.utilityService.navigate(ROUTE_PATH.RC.DISCOVER);
      } else if (name === INVESTOR_PROFILE_REDIRECT.IE) {
        this.utilityService.navigate(ROUTE_PATH.IE.DISCOVER);
      } else {
        this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER);
      }
    } else {
      this.utilityService.navigate(ROUTE_PATH.HOME);
    }
  }

  goToButton() {
    this.utilityService.navigate(ROUTE_PATH.SCS.TOOLS.CUSTOM_PROFILE);
  }

  // On click Card button
  navigateSelections(link: string, data: any) {
    this.coreService.profile.next({
      name: this.profileTitle,
      type: CUSTOMIZATION_TYPE.INVESTOR_PROFILE,
    });

    this.scsService.investorData = data;
    this.scsService.customizationType = CUSTOMIZATION_TYPE.INVESTOR_PROFILE;
    this.utilityService.navigate(ROUTE_PATH.SCS.TOOLS.HOW_SIO_WORKS_DETAILS);
  }

  navigateButton(link: string) {
    this.utilityService.navigate(link);
  }
}
