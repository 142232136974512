<ion-content>
  <div class="tools-container">
    <div class="site-tools">
      <div class="page-header">
        <equitable-sub-header
          title="{{ 'ieDashbaord.tools.taxCalculators' | translate }}"
          backLinkText="{{ 'ieDashbaord.headerTitle' | translate }}"
          (backClick)="backButton()"
        ></equitable-sub-header>
      </div>

      <div class="rc-tool-tab-section">
        <ion-card class="rc-tool-tab-container">
          <ion-segment
            mode="md"
            class="segment-class"
            (ionChange)="segmentChanged($event.detail.value)"
          >
            <ion-segment-button
              *ngFor="let item of segments"
              mode="md"
              class="segment-button-class"
              [value]="item"
              [checked]="activeTab === item"
            >
              <ion-label *ngIf="item == 'tax-deferral'" class="segment-heading">Tax-deferral</ion-label>
              <ion-label *ngIf="item == 'break-even'" class="segment-heading">Break-even</ion-label>
              <ion-label *ngIf="item == 'tax-comparison'" class="segment-heading">Tax-comparison</ion-label>
              <ion-label *ngIf="item == 'tax-and-inflation'" class="segment-heading">Tax & inflation</ion-label>
              <ion-label *ngIf="item == 'federal-income-tax'" class="segment-heading">Federal income tax</ion-label>

            </ion-segment-button>
          </ion-segment>
            
        <div class="iframe-container">
          <iframe
            [src]="iFrameUrl"
            frameborder="0"
            id="newsframe"
            allowfullscreen
            title="{{ title | translate }}"
          ></iframe>
        </div>

        </ion-card>
      </div>
    </div>
  </div>
  <app-footer
    isWhiteFooterVisible="true"
    isBlueFooterVisible="true"
    selectedProduct="ie"
  ></app-footer>
</ion-content>
