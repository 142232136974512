import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { tap, catchError, map, switchMap } from "rxjs/operators";
import { Network } from "@ionic-native/network/ngx";
import { FILE_EXTENSION, MIME_TYPE } from "../../constants/constants";
import { UtilityService } from "../services/utility.service";
import { IonicStorageService } from "../services/ionic-storage.service";
import { FileSystemService } from "../services/file-system.service";

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(
    private platform: Platform,
    private network: Network,
    private ionicStorageService: IonicStorageService,
    private fileSystemService: FileSystemService,
    private utilityService: UtilityService
  ) {}

  intercept<T>(
    req: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    if (
      (this.platform.is("cordova") &&
        this.network.type === this.network.Connection.NONE) ||
      !navigator.onLine
    ) {
      return this.returnCachedData(req);
    }

    return next.handle(req).pipe(
      // map((event: HttpEvent<any>) => {
      //   if (event instanceof HttpResponse) {
      //     let parsedBody;
      //     if (req.url.indexOf("xml") != -1 || req.url.indexOf("plist") != -1) {
      //       parsedBody = this.ionicStorageService.parseResponse(
      //         req.url,
      //         event.body
      //       );
      //     }
      //     event = event.clone({ body: parsedBody });
      //     return event;
      //   }
      // }),
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          event.headers.get("Content-Type");
          //const contentType = event.headers.get("Content-Type");
          let fileExtension = "";
          if (req.url.indexOf("http") == -1) {
            fileExtension = req.url
              .substring(req.url.lastIndexOf("."))
              .replace(".", "");
          } else {
            fileExtension = this.utilityService.findFileExtension(req.url);
          }
          if (fileExtension) {
            if (
              fileExtension === FILE_EXTENSION.JSON ||
              fileExtension === FILE_EXTENSION.XML ||
              fileExtension === FILE_EXTENSION.PLIST
            ) {
              //update xml response to json

              let parsedBody;
              if (
                req.url.indexOf("xml") != -1 ||
                req.url.indexOf("plist") != -1
              ) {
                parsedBody = this.ionicStorageService.parseResponse(
                  req.url,
                  event.body
                );
                event = event.clone({ body: parsedBody });
              }
              this.ionicStorageService.set(req.url, event);
            } else if (
              fileExtension === FILE_EXTENSION.JPG ||
              fileExtension === FILE_EXTENSION.JPEG ||
              fileExtension === FILE_EXTENSION.PNG ||
              fileExtension === FILE_EXTENSION.PDF
            ) {
              if (this.platform.is("cordova")) {
                this.fileSystemService.createAndWrite(
                  this.utilityService.createFileNameFromUrl(req.url),
                  event.body
                );
              }
            }
          }

          return event;
        }
      }),
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          return this.returnCachedData(req);
        } else {
          return throwError(error);
        }
      })
    );
  }

  returnCachedData(req): Observable<HttpResponse<any>> {
    const fileExtension = this.utilityService.findFileExtension(req.url);
    if (
      fileExtension === FILE_EXTENSION.JSON ||
      fileExtension === FILE_EXTENSION.XML ||
      fileExtension === FILE_EXTENSION.PLIST
    ) {
      return this.ionicStorageService.get(req.url).pipe(
        map((data: HttpResponse<any>) => {
          if (data.body) return data.clone({ body: data.body.data });
          else return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
    } else if (
      fileExtension === FILE_EXTENSION.JPG ||
      fileExtension === FILE_EXTENSION.JPEG ||
      fileExtension === FILE_EXTENSION.PNG ||
      fileExtension === FILE_EXTENSION.PDF
    ) {
      if (this.platform.is("cordova")) {
        return this.fileSystemService.readFile(
          this.utilityService.createFileNameFromUrl(req.url)
        );
      } else {
        return of(new HttpResponse({ body: null }));
      }
    } else {
      return of(new HttpResponse({ body: null }));
    }
  }
}
