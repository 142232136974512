<div>
  <ion-row>
    <ion-col size="6">
      <h2 class="heading-small">{{ "diversify.heading" | translate }}</h2>
      <p class="content">
        We can’t predict asset class winners and losers in a given year. But, a diversified portfolio across many asset classes can help smooth out the ride to retirement. Tax-deferred variable annuities, like Investment Edge® can help you keep more of what you've earned.</p>
    </ion-col>
    <ion-col class="ion-align-self-center" size="6">
      <div class="custom-width-button">
        <div class="custom-button-left">
          <div
            class="custom-button"
            *ngFor="let button of buttons; let i = index"
          >
            <equitable-button
              class="button-text"
              type="tertiary"
              [theme]="activeSubOption === button.value ? dark : light"
              (click)="drawLine(button.value)"
            >
              {{ button.text }}
            </equitable-button>
          </div>
        </div>
      </div>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <div class="parent-grid">
        <div class="grid" style="visibility: visible;">
          <table cellspacing="0" cellpadding="0">
            <thead>
              <tr>
                <th *ngFor="let year of years">{{ year }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="r{{ ind + 1 }}"
                *ngFor="let data of performanceData; let ind = index"
              >
                <td
                  #td{{indd}}
                  *ngFor="let assetPerform of data; let indd = index"
                  column="0"
                  [ngClass]="{
                    t1: assetPerform.asset == 'Non-US Stocks',
                    t2: assetPerform.asset == 'Commodities',
                    t3: assetPerform.asset == 'Real Estate',
                    t4: assetPerform.asset == 'Non-US Bonds',
                    t5: assetPerform.asset == 'US Stocks',
                    t6: assetPerform.asset == 'US Bonds',
                    faded:
                      performanceName != '' &&
                      assetPerform.asset != performanceName,
                    active:
                      performanceName != '' &&
                      assetPerform.asset == performanceName
                  }"
                >
                  {{
                    assetPerform.asset == "Commodities"
                      ? "Commod"
                      : assetPerform.asset
                  }}
                  <div class="percent">{{ assetPerform.performance }} %</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <canvas id="cnv" width="2000" height="462"></canvas>
        <div
          class="overlay"
          [ngClass]="{ isDisplay: performanceName != '' }"
        ></div>
      </div>
    </ion-col>
  </ion-row>
</div>
