import { Component, OnInit } from "@angular/core";
import { RcService } from "../../services/rc.service";
import { UtilityService } from "../../../core/services/utility.service";
import { take } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { Type } from "equitable-component-library";

@Component({
  selector: "app-investment-options",
  templateUrl: "./investment-options.component.html",
  styleUrls: ["./investment-options.component.scss"],
})
export class InvestmentOptionsComponent implements OnInit {
  chartData: number[] = [40, 60];
  investmentData: any[];
  equities: number;
  fixedIncome: number;
  portfolios: any[];
  ChartType = Type.Primary;
  sliderType = Type.Secondary;
  value: number;

  constructor(
    private rcService: RcService,
    private utilityService: UtilityService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.utilityService.showLoader();
    this.rcService
      .fetchInvestmentOptionsData()
      .pipe(take(1))
      .subscribe((chartData) => {
        this.utilityService.hideLoader();
        this.investmentData = chartData.allocations;
        this.equities = this.investmentData[4] && this.investmentData[4].risk;
        this.portfolios =
          this.investmentData[4] && this.investmentData[4].portfolios;
        this.fixedIncome = this.calcFixedIncome(this.equities);
        this.chartData = [this.fixedIncome, this.equities];
        this.value = 4;
      });
  }

  calcFixedIncome(value: number) {
    return 100 - value;
  }

  onChange($event) {
    this.investmentData.filter((element, index) => {
      if (index === $event.detail.value) {
        this.equities = element.risk;
        this.fixedIncome = this.calcFixedIncome(this.equities);
        this.chartData = [this.fixedIncome, this.equities];
        this.portfolios = element.portfolios;
      }
    });
  }

  onClickDownload(url) {
    this.utilityService.visitEquitableMicrosite(url);
  }
}
