import { OnInit } from "@angular/core";
import { Theme, Orientation, Icon } from "equitable-component-library";
import { ROUTE_PATH } from "../../../constants/constants";
import { UtilityService } from "../../../core/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
var FundFocusComponent = /** @class */ (function () {
    function FundFocusComponent(utilityService, translate) {
        this.utilityService = utilityService;
        this.translate = translate;
        this.type = Theme.Dark;
        this.orientation = Orientation.Left;
        this.backIcon = Icon.BackButton;
    }
    FundFocusComponent.prototype.ngOnInit = function () { };
    FundFocusComponent.prototype.backButton = function () {
        this.utilityService.navigateWithoutParams(ROUTE_PATH.IE.TOOLS.HOME);
    };
    return FundFocusComponent;
}());
export { FundFocusComponent };
