import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { CUSTOMIZATION_TYPE, ROUTE_PATH } from "../../../constants/constants";
import { UtilityService } from "../../../core/services/utility.service";
import { ButtonType, Theme } from "equitable-component-library";

@Component({
  selector: "app-modal-popup",
  templateUrl: "./modal-popup.component.html",
  styleUrls: ["./modal-popup.component.scss"],
})
export class ModalPopupComponent implements OnInit {
  // Data passed in by componentProps
  htmlTamplate: any;
  modalTitle: string;
  isAddProfileForm: boolean;
  isCloseIcon: boolean;
  rootPath: string;
  customRootPath: string;
  btnType = ButtonType.Primary;
  btnTheme = Theme.Light;

  constructor(
    public modalController: ModalController,
    navParams: NavParams,
    private utilityService: UtilityService
  ) {
    this.customRootPath = ROUTE_PATH.SCS.TOOLS.CUSTOM_PROFILE;
    this.rootPath = ROUTE_PATH.SCS.TOOLS.INVESTOR_PROFILE;
    this.htmlTamplate = navParams.get("htmlTamplate");
    this.modalTitle = navParams.get("modalTitle");
    this.isCloseIcon = navParams.get("isCloseIcon");
    this.isAddProfileForm =
      navParams.get("isAddProfileForm") !== undefined &&
      navParams.get("isAddProfileForm") !== undefined
        ? navParams.get("isAddProfileForm")
        : false;
  }

  ngOnInit() {}

  closeModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  sampleProfile(link: string) {
    this.utilityService.navigate(link, {
      section: "scs",
    });
    this.closeModal();
  }

  customProfile(link: string) {
    this.utilityService.navigate(link, {
      section: "scs",
    });
    this.closeModal();
  }
}
