<ion-content>
  <div class="container container-full" *appFeatureFlag="'isNational'">
    <ion-row class="section-space">
      <ion-col>
            <div class="row">
              <div class="col-6 component card-cmpnt card-icon cc-centered">
                <div class="component-container">
                  <div class="cc-card">
                    <div class="cc-image">
                      <div class="cc-image-contain">
                        <img alt="Holding cash icon" src="./assets/images/our-perspective-on-market.png">
                      </div>
                    </div>
                    <div class="cc-content">
                      <h5 [innerHTML]="
                            'individualRetirementHome.ourPerspectiveOnMarket' | translate"></h5>
                      <p [innerHTML]="shiftsInMarket"></p>
                      <a href="https://equitable.com/perspectives/third-party" target="_blank">Learn more</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 component card-cmpnt card-icon cc-centered">
                <div class="component-container">
                  <div class="cc-card">
                    <div class="cc-image">
                      <div class="cc-image-contain">
                        <img alt="Holding cash icon" src="./assets/images/fund-focus.png">
                      </div>
                    </div>
                    <div class="cc-content">
                      <h5>
                        Fund focus
                      </h5>
                      <p>
                        Equitable's wide range of investment options give you the potential to build a portfolio for any
                        market environment. Learn more about our focus funds and how they can fit into your portfolio.
                      </p>
                      <a (click)="visitPage(route.IE.TOOLS.FUND_FOCUS)">Learn more</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

      </ion-col>
    </ion-row>
  </div>

  <article class="ir-app-home-page">
    <section class="our-perspective-section" *appFeatureFlag="'isPrimerica'">
      <div class="our-perspective-section-text">
        <h1 [innerHTML]="
            'individualRetirementHome.ourPerspectiveOnMarket' | translate
          "></h1>
        <p class="normal-view" [innerHTML]="shiftsInMarket"></p>

        <p class="mobile-view" [innerHTML]="
            shiftsInMarketWithoutBrk
          "></p>

        <equitable-link *appFeatureFlag="'isPrimerica'" (click)="gotToStrategiesSection()" class="read-more-link"
          text="{{ 'individualRetirementHome.readMore' | translate }}" [type]="readMoreLinkType" [iconName]=""
          [orientation]="solutionLinkIconOrientation"></equitable-link>


        <equitable-link *appFeatureFlag="'isNational'"
          (click)="visitEquitable('https://equitable.com/perspectives/third-party')" class="read-more-link"
          text="{{ 'individualRetirementHome.readMore' | translate }}" [type]="readMoreLinkType"
          [iconName]="externalLinkIcon" [orientation]="solutionLinkIconOrientation"></equitable-link>

      </div>
      <div *appFeatureFlag="'isPrimerica'" class="our-perspective-section-image"></div>
    </section>

    <div class="container-fluid">
      <section [ngClass]="isPrimerica ? 'explore-our-suite-section bottomspacer' : 'explore-our-suite-section'"
        name="ourstrategies">
        <h2>{{ "individualRetirementHome.exploreOurSuite" | translate }}</h2>
        <div class="solutions-suites-container">
          <equitable-generic-card class="solution-card" *appFeatureFlag="'isPrimerica'">
            <div class="card-image">
              <img [alt]="
                'individualRetirementHome.structuredCapitalStrategiesPLUS'
                  | translate
              " src="./assets/images/distribute.jpg" />
            </div>
            <div class="card-heading" [innerHTML]="
              'individualRetirementHome.structuredCapitalStrategiesPLUS' | translate
            "></div>
            <div class="card-subheading">
              {{ "individualRetirementHome.protectYourInvestment" | translate }}
            </div>
            <div class="card-description">
              {{ "individualRetirementHome.builtToEaseYourConcerns" | translate }}
            </div>
            <div class="card-footer-wrapper">
              <div class="card-footer">
                <equitable-link [text]="'individualRetirementHome.productOverview' | translate"
                  [type]="solutionLinkType" [iconName]="solutionLinkIcon" [orientation]="solutionLinkIconOrientation"
                  (click)="visitPage(route.SCS.DISCOVER + '?series=PLUS')"></equitable-link>
              </div>
              <div class="card-footer">
                <equitable-link [text]="
                  'individualRetirementHome.advancedToolsResources' | translate
                " [type]="solutionLinkType" [iconName]="solutionLinkIcon" [orientation]="solutionLinkIconOrientation"
                  (click)="visitPage(route.SCS.TOOLS.HOME)"></equitable-link>
              </div>
            </div>
          </equitable-generic-card>
          <equitable-generic-card class="solution-card">
            <div class="card-image">
              <img [alt]="
                'individualRetirementHome.structuredCapitalStrategies'
                  | translate
              " src="./assets/images/protection-from-market.jpg" />
            </div>
            <div class="card-heading" [innerHTML]="
              'individualRetirementHome.structuredCapitalStrategies' | translate
            "></div>
            <div class="card-subheading">
              {{ "individualRetirementHome.protectYourInvestment" | translate }}
            </div>
            <div class="card-description">
              {{ "individualRetirementHome.builtToEaseYourConcerns" | translate }}
            </div>
            <div class="card-footer-wrapper">
              <div class="card-footer">
                <equitable-link *appFeatureFlag="'isNational'"
                  [text]="'individualRetirementHome.productOverview' | translate" [type]="solutionLinkType"
                  [iconName]="solutionLinkIcon" [orientation]="solutionLinkIconOrientation"
                  (click)="visitPage(route.SCS.DISCOVER + '?series=PLUS')"></equitable-link>
                <equitable-link *appFeatureFlag="'isPrimerica'"
                  [text]="'individualRetirementHome.productOverview' | translate" [type]="solutionLinkType"
                  [iconName]="solutionLinkIcon" [orientation]="solutionLinkIconOrientation"
                  (click)="visitPage(route.SCS.DISCOVER + '?series=scs16')"></equitable-link>
                <equitable-link *appFeatureFlag="'isPlusGuard'"
                  [text]="'individualRetirementHome.productOverview' | translate" [type]="solutionLinkType"
                  [iconName]="solutionLinkIcon" [orientation]="solutionLinkIconOrientation"
                  (click)="visitPage(route.SCS.DISCOVER + '?series=scs16')"></equitable-link>
              </div>
              <div class="card-footer">
                <equitable-link [text]="
                  'individualRetirementHome.advancedToolsResources' | translate
                " [type]="solutionLinkType" [iconName]="solutionLinkIcon" [orientation]="solutionLinkIconOrientation"
                  (click)="visitPage(route.SCS.TOOLS.HOME)"></equitable-link>
              </div>
            </div>
          </equitable-generic-card>

          <equitable-generic-card class="solution-card" *ngIf="isNational">
            <div class="card-image">
              <img [alt]="
                'individualRetirementHome.structuredCapitalStrategiesIncome' | translate
              " src="./assets/images/income-tile.jpg" />
            </div>
            <div class="card-heading" [innerHTML]="
              'individualRetirementHome.structuredCapitalStrategiesIncome' | translate
            "></div>
            <div class="card-subheading">
              {{ "individualRetirementHome.incomePaycheck" | translate }}
            </div>
            <div class="card-description">
              {{ "individualRetirementHome.incomeParagraph" | translate }}
            </div>
            <div class="card-footer-wrapper">
              <div class="card-footer">
                <equitable-link [text]="'individualRetirementHome.productOverview' | translate"
                  [type]="solutionLinkType" [iconName]="solutionLinkIcon" [orientation]="solutionLinkIconOrientation"
                  (click)="visitPage(route.SCSINCOME.DISCOVER)"></equitable-link>
              </div>
              <div class="card-footer">
                <equitable-link [text]="
                  'individualRetirementHome.advancedToolsResources' | translate
                " [type]="solutionLinkType" [iconName]="solutionLinkIcon" [orientation]="solutionLinkIconOrientation"
                  (click)="visitPage(route.SCSINCOME.TOOLS.HOME)"></equitable-link>
              </div>
            </div>
          </equitable-generic-card>

          <equitable-generic-card class="solution-card">
            <div class="card-image">
              <img [alt]="
                'individualRetirementHome.retirementCornerstone' | translate
              " src="./assets/images/guaranteed-income.jpg" />
            </div>
            <div class="card-heading" [innerHTML]="
              'individualRetirementHome.retirementCornerstone' | translate
            "></div>
            <div class="card-subheading">
              {{ "individualRetirementHome.guaranteedIncome" | translate }}
            </div>
            <div class="card-description">
              {{
              "individualRetirementHome.designedToAccumulateAndProtect"
                | translate
            }}
            </div>
            <div class="card-footer-wrapper">
              <div class="card-footer">
                <equitable-link [text]="'individualRetirementHome.productOverview' | translate"
                  [type]="solutionLinkType" [iconName]="solutionLinkIcon" [orientation]="solutionLinkIconOrientation"
                  (click)="visitPage(route.RC.DISCOVER)"></equitable-link>
              </div>
              <div class="card-footer">
                <equitable-link [text]="
                  'individualRetirementHome.advancedToolsResources' | translate
                " [type]="solutionLinkType" [iconName]="solutionLinkIcon" [orientation]="solutionLinkIconOrientation"
                  (click)="visitPage(route.RC.TOOLS.HOME)"></equitable-link>
              </div>
            </div>
          </equitable-generic-card>
          <equitable-generic-card class="solution-card" *appFeatureFlag="'isNational'">
            <div class="card-image">
              <img [alt]="'individualRetirementHome.investmentEdge' | translate" src="./assets/images/distribute.jpg" />
            </div>
            <div class="card-heading" [innerHTML]="'individualRetirementHome.investmentEdge' | translate"></div>
            <div class="card-subheading">
              {{ "individualRetirementHome.taxEfficient" | translate }}
            </div>
            <div class="card-description">
              {{
              "individualRetirementHome.helpsPotentiallyGrowWealth" | translate
            }}
            </div>
            <div class="card-footer-wrapper">
              <div class="card-footer">
                <equitable-link [text]="'individualRetirementHome.productOverview' | translate"
                  [type]="solutionLinkType" [iconName]="solutionLinkIcon" [orientation]="solutionLinkIconOrientation"
                  (click)="visitPage(route.IE.DISCOVER)"></equitable-link>
              </div>
              <div class="card-footer">
                <equitable-link [text]="
                  'individualRetirementHome.advancedToolsResources' | translate
                " [type]="solutionLinkType" [iconName]="solutionLinkIcon" [orientation]="solutionLinkIconOrientation"
                  (click)="visitPage(route.IE.TOOLS.HOME)"></equitable-link>
              </div>
            </div>
          </equitable-generic-card>
        </div>
      </section>
    </div>

    <section class="not-sure-where-to-begin-section" *appFeatureFlag="'isNational'">
      <h2>{{ "individualRetirementHome.notSureWhereToBegin" | translate }}</h2>
      <p>{{ "individualRetirementHome.tryingToDecide" | translate }}</p>
      <equitable-button class="find-your-match" [type]="visitEquitableBtnType" [theme]="visitEquitableBtnTheme"
        (click)="visitPage(route.QUESTIONNAIRE)">{{
          "individualRetirementHome.findYourMatch" | translate
        }}</equitable-button>
    </section>

    <section class="about-equitable-section">
      <div class="about-equitable-section-image"></div>
      <div class="about-equitable-section-text">
        <div class="about-equitable-section-text__inner">
          <h2>{{ "individualRetirementHome.aboutEquitable" | translate }}</h2>
          <p>{{ "individualRetirementHome.ourMissionIsToHelp" | translate }}</p>
        </div>
      </div>
    </section>
  </article>
  <div class="footer-seprator"></div>
  <app-footer isBlueFooterVisible="true" selectedProduct="home"></app-footer>
</ion-content>