import { NgModule, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { IonicModule } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Network } from "@ionic-native/network/ngx";
import { IonicStorageModule } from "@ionic/storage";
import { File } from "@ionic-native/file/ngx";
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { CacheInterceptor } from "./core/interceptors/cache.interceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent, GlobalErrorHandler } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { Globalization } from "@ionic-native/globalization/ngx";

//import modules
import { CoreModule } from "./core/core.module";
import { ScsIncomeModule } from "./scsincome/scsincome.module";
import { ScsModule } from "./scs/scs.module";
import { IeModule } from "./ie/ie.module";
import { RcModule } from "./rc/rc.module";

//component lib
import { EquitableComponentLibraryModule } from "equitable-component-library";

//others
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { IonicStorageService } from "./core/services/ionic-storage.service";
import { APP_INITIALIZER } from "@angular/core";
import { FeatureFlagService } from "./core/services/feature-flag.service";
import { environment } from "src/environments/environment";

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot({
      name: "__equitable_db",
      driverOrder: ["indexeddb", "websql", "sqlite"],
    }),
    HttpClientModule,
    BrowserModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        useFactory: translateLoader,
        deps: [HttpClient],
      },
    }),
    CoreModule,
    ScsIncomeModule,
    ScsModule,
    IeModule,
    RcModule,
    FormsModule,
    ReactiveFormsModule,
    EquitableComponentLibraryModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    Network,
    File,
    FileOpener,
    Globalization,
    IonicStorageService,
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: function (appConfig: FeatureFlagService) {
        async function asyncInitializer(): Promise<void> {
          await appConfig.loadRemoteConfig();
        }

        // NOTE: The factory function returns the asynchronous FUNCTION - it does
        // not execute the function directly.
        return asyncInitializer;
      },
      deps: [FeatureFlagService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function translateLoader(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, environment.i18nLocation, ".json");
}
