import { OnChanges, ElementRef, QueryList, AfterViewInit, OnInit, } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { TimelineMax, Power2, Power1, gsap, Back } from "gsap";
import { PRESERVE_LEGACY_TOOLS, SVG } from "../../../constants/constants";
import { CoreService } from "./../../../core/services/core.service";
var PreserveLegacyToolsComponent = /** @class */ (function () {
    function PreserveLegacyToolsComponent(translate, coreService, document) {
        this.translate = translate;
        this.coreService = coreService;
        this.document = document;
        this.graphTimeline = new TimelineMax();
        this.replayTimeline = new TimelineMax({ paused: true, reversed: true });
        this.replayScrubTimeline = new TimelineMax({ paused: true, reversed: true });
        this.flag = true;
    }
    PreserveLegacyToolsComponent.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.chartResize = "chart-extra";
        }, 100);
    };
    PreserveLegacyToolsComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.ionRadioElRef.changes.subscribe(function () {
            _this.ionRadioElRef.forEach(function (el) {
                var loadCheck = setInterval(function () {
                    var button = el.nativeElement.shadowRoot
                        .childNodes[1];
                    if (button) {
                        button.setAttribute("aria-label", "radio");
                        clearInterval(loadCheck);
                    }
                }, 100);
            });
        });
    };
    PreserveLegacyToolsComponent.prototype.ngOnChanges = function () {
        var _this = this;
        this.translate
            .get("preserveLegacy." + this.activeTab + ".benefits")
            .subscribe(function (value) {
            _this.benefits = value;
        });
        switch (this.activeTab) {
            case PRESERVE_LEGACY_TOOLS.ROP:
                this.fetchAnimatedImages(SVG.PL_PRESERVE_PRINCIPAL);
                break;
            case PRESERVE_LEGACY_TOOLS.HA:
                this.fetchAnimatedImages(SVG.PL_HIGHEST_ANNIVERSARY);
                break;
            case PRESERVE_LEGACY_TOOLS.RMD:
                this.fetchAnimatedImages(SVG.PL_WEALTH_GUARD);
                break;
            default:
                break;
        }
    };
    PreserveLegacyToolsComponent.prototype.fetchAnimatedImages = function (image) {
        var _this = this;
        this.coreService.fetchSvg(image).subscribe(function (data) {
            _this.graphTimeline = new TimelineMax();
            _this.replayTimeline = new TimelineMax({ paused: true, reversed: true });
            _this.replayScrubTimeline = new TimelineMax({
                paused: true,
                reversed: true,
            });
            _this.chart.nativeElement.innerHTML = data;
            _this.replayBtn = _this.document.getElementById("replay_btn");
            switch (_this.activeTab) {
                case PRESERVE_LEGACY_TOOLS.ROP:
                    _this.initializePreservePrincipalAnimation();
                    break;
                case PRESERVE_LEGACY_TOOLS.HA:
                    _this.initializeHighestAnniversaryAnimation();
                    break;
                case PRESERVE_LEGACY_TOOLS.RMD:
                    _this.initializeWealthGuardAnimation();
                    break;
                default:
            }
        });
    };
    PreserveLegacyToolsComponent.prototype.initializePreservePrincipalAnimation = function () {
        gsap.set("#bar_74,#bar_75", { scaleY: 0.4, transformOrigin: "50%, 100%" });
        this.graphTimeline.addLabel("graphStart", 0);
        this.graphTimeline.set("#animatedContent", { opacity: 1 }, "graphStart");
        this.graphTimeline.from("#bar_59", 1.5, { scaleY: 0, transformOrigin: "50% 100%", ease: Power2.easeInOut }, "graphStart");
        this.graphTimeline.from("#bubble_initial", 0.75, { opacity: 0, y: 60, ease: Power2.easeOut }, "graphStart+=.75");
        this.graphTimeline.fromTo("#dashMask", 1.5, { x: -750 }, { x: 0, ease: Power2.easeInOut }, "graphStart+=1.5");
        this.graphTimeline.from("#bubble_death", 0.75, { opacity: 0, x: -300, ease: Power2.easeOut }, "graphStart+=2.25");
        this.graphTimeline.from(".graphBars", {
            duration: 1.5,
            scaleY: 0,
            transformOrigin: "50% 100%",
            ease: "power3.In",
            stagger: { each: 0.25, ease: "power2.In" },
        });
        this.replayTimeline.addLabel("start", 0);
        this.replayTimeline.to("#cyclePath", 2, { "stroke-dashoffset": 33 }, "start");
        this.replayTimeline.to("#arrow1", 0.2, { x: 3, rotation: 90 }, "start+=.1");
        this.replayTimeline.to("#arrow1", 0.25, { y: -13, rotation: 180, transformOrigin: "center" }, "start+=.25");
        this.replayTimeline.to("#arrow1", 1.5, { x: 22 }, "start+=.5");
        this.replayTimeline.to("#arrow2", 0.2, { x: 8, rotation: 90 }, "start+=.1");
        this.replayTimeline.to("#arrow2", 0.25, { y: 15, rotation: 180, transformOrigin: "center" }, "start+=.25");
        this.replayTimeline.to("#arrow2", 1.5, { x: -11 }, "start+=.5");
        //rollover color change
        this.replayTimeline.to("#arrow2,#arrow1,#cyclePath", 2, { stroke: "#2E5ACC" }, "start");
        this.replayTimeline.to("#replayText", 2, { fill: "#2E5ACC" }, "start");
        //creating an ease on the timeline playback for rollover
        this.replayScrubTimeline.to(this.replayTimeline, 0.45, {
            progress: 1,
            ease: Power1.easeInOut,
        });
        //Event Listeners
        this.replayBtn.addEventListener("click", this.onReplayClick.bind(this));
        this.replayBtn.addEventListener("mouseover", this.onReplayOver.bind(this));
        this.replayBtn.addEventListener("touchstart", this.onReplayOver.bind(this));
        this.replayBtn.addEventListener("mouseout", this.onReplayOut.bind(this));
        this.replayBtn.addEventListener("touchend", this.onReplayOut.bind(this));
    };
    PreserveLegacyToolsComponent.prototype.initializeHighestAnniversaryAnimation = function () {
        //Timeline for animating the graph
        this.graphTimeline.addLabel("graphStart", 0);
        this.graphTimeline.addLabel("graphStep2", 3.5);
        this.graphTimeline.addLabel("graphStep3", 7.25);
        this.graphTimeline.addLabel("graphStep4", 9.75);
        this.graphTimeline.set("#animatedContent", { opacity: 1 }, "graphStart");
        this.graphTimeline.from("#init_bubble", 0.5, { opacity: 0, y: 10, ease: Power2.easeOut }, "graphStart");
        this.graphTimeline.from(".step1", {
            duration: 1.5,
            scaleY: 0,
            transformOrigin: "50% 100%",
            ease: "power3.In",
            stagger: { each: 0.25, ease: "power2.In" },
        }, "graphStart");
        this.graphTimeline.from("#lock1", 0.5, { opacity: 0, ease: Power2.easeOut }, "graphStart+=2.25");
        this.graphTimeline.from("#lock1_arm", 0.5, { y: -7, transformOrigin: "0% 100%", ease: Back.easeIn }, "graphStart+=2.25");
        this.graphTimeline.fromTo("#dashMask", 2.5, { x: -775, y: 186 }, { x: -617, y: 186, transformOrigin: "0% 100%", ease: Power2.easeInOut }, "graphStart");
        this.graphTimeline.from("#reset1", 0.5, { opacity: 0, y: -7, ease: Back.easeIn }, "graphStart+=2.25");
        this.graphTimeline.to("#reset1", 0.5, { opacity: 0, ease: Power2.easeIn }, "graphStep2");
        this.graphTimeline.from(".step2", {
            duration: 1.5,
            scaleY: 0,
            transformOrigin: "50% 100%",
            ease: "power3.In",
            stagger: { each: 0.25, ease: "power2.In" },
        }, "graphStep2");
        this.graphTimeline.from("#lock2", 0.5, { opacity: 0, ease: Power2.easeOut }, "graphStep2+=2.5");
        this.graphTimeline.from("#lock2_arm", 0.5, { y: -7, transformOrigin: "0% 100%", ease: Back.easeIn }, "graphStep2+=2.5");
        this.graphTimeline.fromTo("#dashMask", 2.75, { x: -617, y: 110 }, { x: -392, y: 110, transformOrigin: "0% 100%", ease: Power2.easeInOut }, "graphStep2");
        this.graphTimeline.from("#reset2", 0.5, { opacity: 0, y: -7, ease: Back.easeIn }, "graphStep2+=2.5");
        this.graphTimeline.to("#reset2", 0.5, { opacity: 0, ease: Power2.easeIn }, "graphStep3");
        this.graphTimeline.from(".step3", {
            duration: 1.5,
            scaleY: 0,
            transformOrigin: "50% 100%",
            ease: "power3.In",
            stagger: { each: 0.25, ease: "power2.In" },
        }, "graphStep3");
        this.graphTimeline.from("#lock3", 0.5, { opacity: 0, ease: Power2.easeOut }, "graphStep3+=2.0");
        this.graphTimeline.from("#lock3_arm", 0.5, { y: -7, transformOrigin: "0% 100%", ease: Back.easeIn }, "graphStep3+=2.0");
        this.graphTimeline.fromTo("#dashMask", 2.25, { x: -392, y: 8 }, { x: -257, y: 8, transformOrigin: "0% 100%", ease: Power2.easeInOut }, "graphStep3");
        this.graphTimeline.from("#reset3", 0.5, { opacity: 0, y: -7, ease: Back.easeIn }, "graphStep3+=2.0");
        this.graphTimeline.from(".step4", {
            duration: 1.5,
            scaleY: 0,
            transformOrigin: "50% 100%",
            ease: "power3.In",
            stagger: { each: 0.25, ease: "power2.In" },
        }, "graphStep4");
        this.graphTimeline.fromTo("#dashMask", 2.5, { x: -257, y: 0 }, { x: 0, y: 0, transformOrigin: "0% 100%", ease: Power2.easeInOut }, "graphStep4");
        this.graphTimeline.from("#death_bubble", 0.75, { opacity: 0, x: -10, ease: Power2.easeInOut }, "graphStep4+=1.5");
        //Timeline created to animate the replay button
        this.replayTimeline.addLabel("start", 0);
        this.replayTimeline.to("#cyclePath", 2, { "stroke-dashoffset": 33 }, "start");
        this.replayTimeline.to("#arrow1", 0.2, { x: 3, rotation: 90 }, "start+=.1");
        this.replayTimeline.to("#arrow1", 0.25, { y: -13, rotation: 180, transformOrigin: "center" }, "start+=.25");
        this.replayTimeline.to("#arrow1", 1.5, { x: 22 }, "start+=.5");
        this.replayTimeline.to("#arrow2", 0.2, { x: 8, rotation: 90 }, "start+=.1");
        this.replayTimeline.to("#arrow2", 0.25, { y: 15, rotation: 180, transformOrigin: "center" }, "start+=.25");
        this.replayTimeline.to("#arrow2", 1.5, { x: -11 }, "start+=.5");
        //rollover color change
        this.replayTimeline.to("#arrow2,#arrow1,#cyclePath", 2, { stroke: "#2E5ACC" }, "start");
        this.replayTimeline.to("#replayText", 2, { fill: "#2E5ACC" }, "start");
        //creating an ease on the timeline playback for rollover
        this.replayScrubTimeline.to(this.replayTimeline, 0.45, {
            progress: 1,
            ease: Power1.easeInOut,
        });
        ///Event Listeners
        this.replayBtn.addEventListener("click", this.onReplayClick.bind(this));
        this.replayBtn.addEventListener("mouseover", this.onReplayOver.bind(this));
        this.replayBtn.addEventListener("touchstart", this.onReplayOver.bind(this));
        this.replayBtn.addEventListener("mouseout”", this.onReplayOut.bind(this));
        this.replayBtn.addEventListener("touchend", this.onReplayOut.bind(this));
    };
    PreserveLegacyToolsComponent.prototype.initializeWealthGuardAnimation = function () {
        this.graphTimeline.addLabel("graphStart", 0);
        this.graphTimeline.addLabel("graphStep2", 3.5);
        this.graphTimeline.addLabel("graphStep3", 7.25);
        this.graphTimeline.addLabel("graphStep4", 10.25);
        this.graphTimeline.set("#animatedContent", { opacity: 1 }, "graphStart");
        this.graphTimeline.from("#init_bubble", 0.5, { opacity: 0, y: 10, ease: Power2.easeOut }, "graphStart");
        this.graphTimeline.from(".step1", {
            duration: 1.5,
            scaleY: 0,
            transformOrigin: "50% 100%",
            ease: "power3.In",
            stagger: { each: 0.25, ease: "power2.In" },
        }, "graphStart");
        this.graphTimeline.from("#lock1", 0.5, { opacity: 0, ease: Power2.easeOut }, "graphStart+=2.25");
        this.graphTimeline.from("#lock1_arm", 0.5, { y: -7, transformOrigin: "0% 100%", ease: Back.easeIn }, "graphStart+=2.25");
        this.graphTimeline.fromTo("#dashMask", 2.5, { x: -775, y: 186 }, { x: -617, y: 186, transformOrigin: "0% 100%", ease: Power2.easeInOut }, "graphStart");
        this.graphTimeline.from("#reset1", 0.5, { opacity: 0, y: -7, ease: Back.easeIn }, "graphStart+=2.25");
        this.graphTimeline.to("#reset1", 0.5, { opacity: 0, ease: Power2.easeIn }, "graphStep2");
        this.graphTimeline.from(".step2", {
            duration: 1.5,
            scaleY: 0,
            transformOrigin: "50% 100%",
            ease: "power3.In",
            stagger: { each: 0.25, ease: "power2.In" },
        }, "graphStep2");
        this.graphTimeline.from("#lock2", 0.5, { opacity: 0, ease: Power2.easeOut }, "graphStep2+=2.5");
        this.graphTimeline.from("#lock2_arm", 0.5, { y: -7, transformOrigin: "0% 100%", ease: Back.easeIn }, "graphStep2+=2.5");
        this.graphTimeline.fromTo("#dashMask", 2.75, { x: -617, y: 110 }, { x: -392, y: 110, transformOrigin: "0% 100%", ease: Power2.easeInOut }, "graphStep2");
        this.graphTimeline.from("#reset2", 0.5, { opacity: 0, y: -7, ease: Back.easeIn }, "graphStep2+=2.5");
        this.graphTimeline.to("#reset2", 0.5, { opacity: 0, ease: Power2.easeIn }, "graphStep3");
        this.graphTimeline.from(".step3", {
            duration: 1.5,
            scaleY: 0,
            transformOrigin: "50% 100%",
            ease: "power3.In",
            stagger: { each: 0.25, ease: "power2.In" },
        }, "graphStep3");
        this.graphTimeline.from("#lock3", 0.5, { opacity: 0, ease: Power2.easeOut }, "graphStep3+=2.0");
        this.graphTimeline.from("#lock3_arm", 0.5, { y: -7, transformOrigin: "0% 100%", ease: Back.easeIn }, "graphStep3+=2.0");
        this.graphTimeline.fromTo("#dashMask", 2.25, { x: -392, y: 8 }, { x: -257, y: 8, transformOrigin: "0% 100%", ease: Power2.easeInOut }, "graphStep3");
        this.graphTimeline.from("#reset3", 0.5, { opacity: 0, y: -7, ease: Back.easeIn }, "graphStep3+=2.0");
        this.graphTimeline.to("#reset3", 0.5, { opacity: 0, ease: Power2.easeIn }, "graphStep4");
        this.graphTimeline.from(".step4", {
            duration: 1.5,
            scaleY: 0,
            transformOrigin: "50% 100%",
            ease: "power3.In",
            stagger: { each: 0.25, ease: "power2.In" },
        }, "graphStep4");
        this.graphTimeline.from(".percentage", {
            duration: 1.5,
            opacity: 0,
            ease: "power3.In",
            stagger: { each: 0.25, ease: "power2.In" },
        }, "graphStep4");
        this.graphTimeline.fromTo("#dashMask", 2.5, { x: -257, y: 0 }, { x: 0, y: 0, transformOrigin: "0% 100%", ease: Power2.easeInOut }, "graphStep4");
        this.graphTimeline.from("#benefit_bubble", 0.75, { opacity: 0, x: -10, ease: Power2.easeInOut }, "graphStep4+=1");
        this.graphTimeline.from("#death_bubble", 0.75, { opacity: 0, x: -10, ease: Power2.easeInOut }, "graphStep4+=1.5");
        //Timeline created to animate the replay button
        this.replayTimeline.addLabel("start", 0);
        this.replayTimeline.to("#cyclePath", 2, { "stroke-dashoffset": 33 }, "start");
        this.replayTimeline.to("#arrow1", 0.2, { x: 3, rotation: 90 }, "start+=.1");
        this.replayTimeline.to("#arrow1", 0.25, { y: -13, rotation: 180, transformOrigin: "center" }, "start+=.25");
        this.replayTimeline.to("#arrow1", 1.5, { x: 22 }, "start+=.5");
        this.replayTimeline.to("#arrow2", 0.2, { x: 8, rotation: 90 }, "start+=.1");
        this.replayTimeline.to("#arrow2", 0.25, { y: 15, rotation: 180, transformOrigin: "center" }, "start+=.25");
        this.replayTimeline.to("#arrow2", 1.5, { x: -11 }, "start+=.5");
        //rollover color change
        this.replayTimeline.to("#arrow2,#arrow1,#cyclePath", 2, { stroke: "#2E5ACC" }, "start");
        this.replayTimeline.to("#replayText", 2, { fill: "#2E5ACC" }, "start");
        //creating an ease on the timeline playback for rollover
        this.replayScrubTimeline.to(this.replayTimeline, 0.45, {
            progress: 1,
            ease: Power1.easeInOut,
        });
        ///Event Listeners
        this.replayBtn.addEventListener("click", this.onReplayClick.bind(this));
        this.replayBtn.addEventListener("mouseover", this.onReplayOver.bind(this));
        this.replayBtn.addEventListener("touchstart", this.onReplayOver.bind(this));
        this.replayBtn.addEventListener("mouseout", this.onReplayOut.bind(this));
        this.replayBtn.addEventListener("touchend", this.onReplayOut.bind(this));
    };
    PreserveLegacyToolsComponent.prototype.onReplayClick = function () {
        gsap.to("#animatedContent", 0.5, { opacity: 0, ease: Power2.easeOut });
        this.replayBtn.removeEventListener("click", this.onReplayClick);
        // eslint-disable-next-line angular/timeout-service
        setTimeout(function () {
            this.graphTimeline.play(0);
            this.replayBtn.addEventListener("click", this.onReplayClick);
        }.bind(this), 510);
    };
    PreserveLegacyToolsComponent.prototype.onReplayOver = function () {
        this.replayScrubTimeline.play();
    };
    PreserveLegacyToolsComponent.prototype.onReplayOut = function () {
        this.replayScrubTimeline.reverse();
    };
    return PreserveLegacyToolsComponent;
}());
export { PreserveLegacyToolsComponent };
