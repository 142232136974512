// import { DiscoverComponent } from './../../../ie/components/discover/discover.component';
// import { Series, FeatureCard, InvestorProfile } from './../../../core/models/app.models';
import { FeatureFlagService } from "./../../../core/services/feature-flag.service";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { OnInit, ElementRef } from "@angular/core";
import { IonSlides } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { ATTRIBUTE_TYPE, Icon, EquitableUtilities, LinkType, Orientation, } from "equitable-component-library";
import { SLIDE_OPTS, ROUTE_PATH } from "../../../constants/constants";
import { UtilityService } from "../../../core/services/utility.service";
import { ScsService } from "../../services/scs.service";
import { FormControl, Validators, FormBuilder } from "@angular/forms";
import { TealiumUtagService } from '../../../tealium/utag.service';
var DiscoverScsComponent = /** @class */ (function () {
    function DiscoverScsComponent(utilityService, equitableUtilities, translate, scsService, route, router, formBuilder, flagService, tealium) {
        this.utilityService = utilityService;
        this.equitableUtilities = equitableUtilities;
        this.translate = translate;
        this.scsService = scsService;
        this.route = route;
        this.router = router;
        this.formBuilder = formBuilder;
        this.flagService = flagService;
        this.tealium = tealium;
        //  Features & Benefits section
        this.discoverScsData = {
            featureCards: [
                {
                    icon: Icon.PartialProtection,
                    title: "discoverScs.PartialProtection",
                    description: "discoverScs.product1Desc",
                },
                {
                    icon: Icon.GrowthOpportunities,
                    title: "discoverScs.growthOpportunities",
                    description: "discoverScs.product2Desc",
                },
                {
                    icon: Icon.Customize,
                    title: "discoverScs.customizedToNeeds",
                    description: "discoverScs.product3Desc",
                },
            ],
            featureCards_Plus21: [
                {
                    icon: Icon.PartialProtection,
                    title: "discoverScs.PartialProtectionPlus21",
                    description: "discoverScs.product1DescPlus21",
                },
                {
                    icon: Icon.GrowthOpportunities,
                    title: "discoverScs.growthOpportunities",
                    description: "discoverScs.product2Desc",
                },
                {
                    icon: Icon.Customize,
                    title: "discoverScs.customizedToNeeds",
                    description: "discoverScs.product3Desc",
                },
            ],
            getFileUrl: "",
            resource: [],
        };
        // for video section
        this.videos = []; //environment.videos.scs;
        this.video_SCS = [];
        this.video_PLUS = [];
        this.video_PLUS21 = [];
        this.video = [];
        this.slideOpts = SLIDE_OPTS;
        this.iconArrowLeft = this.equitableUtilities.getPath(Icon.SliderNavLeft, ATTRIBUTE_TYPE.ICON);
        this.iconArrowRight = this.equitableUtilities.getPath(Icon.SliderNavRight, ATTRIBUTE_TYPE.ICON);
        this.orientation = Orientation;
        this.activeSlide = 0;
        // for stepper in History May Be a Guide section
        this.steps = [
            {
                index: 1,
                active: true,
                completed: true,
            },
            {
                index: 2,
                active: false,
                completed: false,
            },
            {
                index: 3,
                active: false,
                completed: false,
            },
        ];
        // for Explore the other strategies we offer section
        this.guaranteedIncome = {
            headingSmall: "",
            listDetail: [],
        };
        this.deferYourTaxes = {
            headingSmall: "",
            listDetail: [],
        };
        this.scsincomeBenefits = {
            headingSmall: "",
            listDetail: [],
        };
        // for link in Disclosure, Terms of Use section
        this.LinkType = LinkType;
        this.isForPLUS = true;
        this.isForPLUS21 = true;
        this.pageChanged = false;
    }
    DiscoverScsComponent.prototype.writeValue = function (obj) { };
    DiscoverScsComponent.prototype.registerOnChange = function (fn) { };
    DiscoverScsComponent.prototype.registerOnTouched = function (fn) { };
    DiscoverScsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isNational = this.flagService.featureFlags.isNational;
        this.isPrimerica = this.flagService.featureFlags.isPrimerica;
        this.scsService.getSCSData().subscribe(function (scs) {
            _this.scsData = scs;
            if (scs.videos && scs.videos.length > 0) {
                _this.video_SCS = scs.videos;
                _this.video_PLUS = scs.PLUSvideos;
                _this.video_PLUS21 = scs.PLUS21videos;
                if (_this.productValue === 'scsplus') {
                    console.log('setting videos for plus');
                    _this.videos = scs.PLUSvideos;
                }
                else if (_this.productValue === 'scsplus21') {
                    console.log('setting videos for plus 21');
                    _this.videos = scs.PLUS21videos;
                }
                else {
                    console.log('setting videos for scs');
                    _this.videos = scs.videos;
                }
            }
        });
        this.dashboardForm = this.formBuilder.group({
            seriesDropDown: new FormControl({ value: this.getSeries, disabled: false }, Validators.required),
        });
        // translations for Explore the other strategies we offer section
        this.translate.get("discoverScs").subscribe(function (discoverScs) {
            _this.discoverScs;
            _this.deferYourTaxes = {
                headingSmall: discoverScs.benefits,
                listDetail: [
                    discoverScs.taxDeferral,
                    discoverScs.taxEfficientDistributions,
                    discoverScs.diversification,
                ],
            };
            _this.scsincomeBenefits = {
                headingSmall: discoverScs.benefits,
                listDetail: [
                    discoverScs.lifetimeIncome,
                    discoverScs.levelDownside,
                    discoverScs.lowCost,
                ],
            };
            _this.guaranteedIncome = {
                headingSmall: discoverScs.benefits,
                listDetail: [
                    discoverScs.growYourHealth,
                    discoverScs.protectedBenefitAccount,
                    discoverScs.planBeyond,
                ],
            };
            _this.image = "./assets/images/discover-scs-banner.png";
            _this.options = [
                {
                    id: discoverScs.abbrplus21,
                    value: discoverScs.scsplus21
                },
                {
                    id: discoverScs.abbrplus,
                    value: discoverScs.scsplus
                },
                {
                    id: discoverScs.abbrscs,
                    value: discoverScs.scs
                },
            ];
            _this.pageDescription_PLUS = discoverScs.scsPLUSAdaptsToYourUniqueStyle;
            _this.howSCSWorks_PLUS = discoverScs.howSCSPLUSWorks;
            _this.standardSegmentIsAnOption_PLUS = discoverScs.plusStandardSegmentIsAnOption;
            _this.pageDescription_PLUS21 = discoverScs.scsPLUS21AdaptsToYourUniqueStyle;
            _this.howSCSWorks_PLUS21 = discoverScs.howSCSPLUS21Works;
            _this.standardSegmentIsAnOption_PLUS21 = discoverScs.plus21StandardSegmentIsAnOption;
            _this.pageDescription_SCS = discoverScs.scsAdaptsToYourUniqueStyle;
            _this.howSCSWorks_SCS = discoverScs.howSCSWorks;
            _this.standardSegmentIsAnOption_SCS = discoverScs.standardSegmentIsAnOption;
            _this.slideShowTitle = discoverScs.scsPlusSlide_1;
            _this.scsPlusSlide_0 = discoverScs.scsPlusSlide_0;
            _this.scsPlusSlide_1 = discoverScs.scsPlusSlide_1;
            _this.scsPlusSlide_2 = discoverScs.scsPlusSlide_2;
            _this.slideShowTitle = discoverScs.scsPlus21Slide_1;
            _this.scsPlus21Slide_0 = discoverScs.scsPlus21Slide_0;
            _this.scsPlus21Slide_1 = discoverScs.scsPlus21Slide_1;
            _this.scsPlus21Slide_2 = discoverScs.scsPlus21Slide_2;
            _this.scsSlide_0 = discoverScs.scsSlide_0;
            _this.scsSlide_1 = discoverScs.scsSlide_1;
            _this.scsSlide_2 = discoverScs.scsSlide_2;
            _this.route.queryParams.subscribe(function (qparams) {
                _this.qparams = qparams;
                console.log('DISCOVER: setting banner for this.qparams.series ', _this.qparams.series);
                if (_this.qparams.series && _this.qparams.series.toLowerCase() === 'plus21') {
                    console.log('DISCOVER: setting banner for ', discoverScs.scsplus21Desc);
                    _this.bannerDescription = discoverScs.scsplus21Desc;
                }
                else if (_this.qparams.series && _this.qparams.series.toLowerCase() === 'scs16') {
                    console.log('DISCOVER: setting banner for ', discoverScs.scs);
                    _this.bannerDescription = discoverScs.scs;
                }
                else if (_this.qparams.series && _this.qparams.series.toLowerCase() === 'plus') {
                    console.log('DISCOVER: setting banner for ', discoverScs.scsplus);
                    _this.bannerDescription = discoverScs.scsplus;
                }
                _this.setPageContent();
            });
        });
        // this.tealium.link({
        //   tealium_event: 'link',
        //   event_category: 'BodyClick',
        //   event_name: 'Retirement Guide Clicks',
        //   event_label: 'Click on Product Overview'
        // })
    };
    DiscoverScsComponent.prototype.setPageContent = function () {
        console.log("DISCOVER: setPageConent START");
        var qparams = this.qparams;
        this.activeSlide = 0;
        if (this.slider) {
            this.slideChanged();
        }
        // this line my be after above
        this.pageChanged = true;
        if (Object.keys(qparams).length === 0 || (qparams.series && qparams.series.toLowerCase() === 'plus')) {
            this.isForPLUS21 = false;
            this.isForPLUS = true;
            console.log('DISCOVER: AA setPageContent for PLUS setting productValue');
            this.productValue = 'scsplus';
            this.pageDescription = this.pageDescription_PLUS;
            this.howSCSWorks = this.howSCSWorks_PLUS;
            this.standardSegmentIsAnOption = this.standardSegmentIsAnOption_PLUS;
            this.videos = this.video_PLUS;
            // console.log('DISCOVER:in setPageContent setting banner to be', this.bannerDescription_PLUS)
            // this.bannerDescription = this.bannerDescription_PLUS
        }
        else if (Object.keys(qparams).length === 0 || (qparams.series && qparams.series.toLowerCase() === 'plus21')) {
            this.isForPLUS = false;
            this.isForPLUS21 = true;
            console.log('DISCOVER: AA setPageContent for PLUS 21 setting productValue');
            this.productValue = 'scsplus21';
            this.pageDescription = this.pageDescription_PLUS21;
            this.howSCSWorks = this.howSCSWorks_PLUS21;
            this.standardSegmentIsAnOption = this.standardSegmentIsAnOption_PLUS21;
            this.videos = this.video_PLUS21;
            // console.log('DISCOVER:in setPageContent setting banner to be', this.bannerDescription_PLUS)
            // this.bannerDescription = this.bannerDescription_PLUS
        }
        else if (qparams.series) {
            this.isForPLUS21 = false;
            this.isForPLUS = false;
            console.log('DISCOVER: AA setPageContent for SCS16 NOT_setting productValue');
            this.productValue = 'scs16';
            this.pageDescription = this.pageDescription_SCS;
            this.howSCSWorks = this.howSCSWorks_SCS;
            this.standardSegmentIsAnOption = this.standardSegmentIsAnOption_SCS;
            this.videos = this.video_SCS;
            //console.log('DISCOVER: in setPageConetn setting banner to', this.bannerDescription_SCS)
            //this.bannerDescription = this.bannerDescription_SCS
        }
        else {
            console.log("qparams not loaded as expected");
        }
    };
    // move slider left/right direction in History May Be a Guide section
    DiscoverScsComponent.prototype.slideTransition = function (slider, direction) {
        if (direction === this.orientation.Left) {
            slider.slidePrev();
        }
        else if (direction === this.orientation.Right) {
            slider.slideNext();
        }
    };
    // on slide change, update stepper in History May Be a Guide section
    DiscoverScsComponent.prototype.slideChanged = function () {
        var _this = this;
        this.slider.getActiveIndex().then(function (index) {
            if (_this.pageChanged) {
                index = 0;
                _this.activeSlide = 0;
            }
            _this.pageChanged = false;
            _this.activeSlide = index;
            var steps = _this.steps.slice();
            for (var _i = 0, steps_1 = steps; _i < steps_1.length; _i++) {
                var step = steps_1[_i];
                step.active = step.completed = false;
            }
            if (_this.isForPLUS) {
                _this.slideShowTitle = _this["scsPlusSlide_" + index];
            }
            else if (_this.isForPLUS21) {
                _this.slideShowTitle = _this["scsPlus21Slide_" + index];
            }
            else {
                _this.slideShowTitle = _this["scsSlide_" + index];
            }
            steps[index] = {
                index: index + 1,
                active: true,
                completed: true,
            };
            _this.steps = steps;
        });
    };
    DiscoverScsComponent.prototype.gotoSCSLandingPage = function () {
        document.querySelector('.hero-main').scrollIntoView(true);
        this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER + '?series=scs16');
    };
    DiscoverScsComponent.prototype.gotoSCSPLUSLandingPage = function () {
        document.querySelector('.hero-main').scrollIntoView(true);
        this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER + '?series=PLUS');
    };
    DiscoverScsComponent.prototype.gotoSCSINCOMELandingPage = function () {
        document.querySelector('.hero-main').scrollIntoView(true);
        this.utilityService.navigate(ROUTE_PATH.SCSINCOME.DISCOVER + '?series=B');
    };
    DiscoverScsComponent.prototype.gotoSCSPLUS21LandingPage = function () {
        document.querySelector('.hero-main').scrollIntoView(true);
        this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER + '?series=PLUS21');
    };
    DiscoverScsComponent.prototype.gotoRCLandingPage = function () {
        this.utilityService.navigateWithoutParams(ROUTE_PATH.RC.DISCOVER);
    };
    DiscoverScsComponent.prototype.gotoIELandingPage = function () {
        this.utilityService.navigateWithoutParams(ROUTE_PATH.IE.DISCOVER);
    };
    DiscoverScsComponent.prototype.gotoAdvancedToolsResourcesLandingPage = function () {
        this.utilityService.navigateWithoutParams(ROUTE_PATH.SCS.TOOLS.HOME);
    };
    DiscoverScsComponent.prototype.productMenuChange = function (status) {
        // var d = this.translate.get("discoverScs")
        console.log("PRODUCT MENU CHANGE");
        this.status = status;
        this.select1.nativeElement.blur();
        if (this.select1.nativeElement.value === 'scsplus') {
            console.log("DISCOVER: route to PLUS");
            console.log(this.select1.nativeElement.value);
            this.router.navigate([ROUTE_PATH.SCS.DISCOVER], { queryParams: { 'series': "PLUS" } });
        }
        else if (this.select1.nativeElement.value === 'scsplus21') {
            console.log("DISCOVER: route to PLUS 21");
            this.router.navigate([ROUTE_PATH.SCS.DISCOVER], { queryParams: { 'series': "PLUS21" } });
        }
        else {
            console.log("DISCOVER: route to SCS16");
            this.router.navigate([ROUTE_PATH.SCS.DISCOVER], { queryParams: { 'series': "SCS16" } });
        }
        //console.log("DISCOVER", d)
    };
    return DiscoverScsComponent;
}());
export { DiscoverScsComponent };
