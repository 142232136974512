import { Component, OnInit } from "@angular/core";
import { ScsIncomeService } from "../../services/scsincome.service";
import { take } from "rxjs/operators";
import { UtilityService } from "src/app/core/services/utility.service";

@Component({
  selector: "app-guaranteed-income",
  templateUrl: "./guaranteed-income.component.html",
  styleUrls: ["./guaranteed-income.component.scss"],
})
export class GuaranteedIncomeComponent implements OnInit {
  deferralRate: any;
  takingIncomeRate: any;
  date: string;
  incomeData: any;
  chartImage = "./assets/images/guaranteed-income-for-life.png";

  constructor(
    private ScsIncomeService: ScsIncomeService,
    private utilityService: UtilityService
  ) {}

  ngOnInit() {
    this.utilityService.showLoader();
    this.ScsIncomeService
      .fetchScsIncomeData("GuaranteedIncomeForLifeJSON")
      .pipe(take(1))
      .subscribe((chartData) => {
        this.utilityService.hideLoader();
        this.takingIncomeRate = '5%';
        this.deferralRate = chartData.deferralRate;
        this.date = '3/29/2021';
      });
  }
}
