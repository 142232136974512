import { FeatureFlagService } from './core/services/feature-flag.service';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./core/services/feature-flag.service";
var AppPreloadingStrategy = /** @class */ (function () {
    function AppPreloadingStrategy(featureService) {
        this.featureService = featureService;
    }
    AppPreloadingStrategy.prototype.preload = function (route, load) {
        return this.featureService.featureFlags[route.path] ? load() : of(false);
    };
    AppPreloadingStrategy.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppPreloadingStrategy_Factory() { return new AppPreloadingStrategy(i0.ɵɵinject(i1.FeatureFlagService)); }, token: AppPreloadingStrategy, providedIn: "root" });
    return AppPreloadingStrategy;
}());
export { AppPreloadingStrategy };
