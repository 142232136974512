<equitable-tab-slider (changeData)="openTab($event)" [names]="btns" [selectedName]="selectedName">
</equitable-tab-slider>

<!-- Tab 1  termsOfUse-->
<div *ngIf="activeIndex === 0">
  <h1 class="title-heading">
    Important Retirement Cornerstone<sup>®</sup> Disclosure, Terms of
    Use/Application Privacy Policy
  </h1>
  <div class="content">
    <div>
      A deferred variable annuity, such as Retirement Cornerstone<sup>®</sup>,
      is a long-term financial product designed for retirement purposes. It is a contractual agreement in which
      payment(s) are made to an insurance company, which agrees to pay out an income or lump-sum amount at a later date.
      Variable annuity contracts offer tax-deferred growth potential and optional features such as living and death
      benefits. Guarantees are based on the claims-paying ability of the issuing life insurance company.
    </div>
    <div>
      Withdrawals from an annuity contract are taxable as ordinary income and, if made prior to age 59½, may be subject
      to an additional 10% federal tax. The contingent withdrawal charge declines from 7% over a 7-year period for the
      Series B product. Amounts invested in an annuity’s portfolio are subject to fluctuation in value and market risk,
      including loss of principal. There are fees and charges associated with a variable annuity contract, which
      include, but are not limited to, operations charges, sales and withdrawal charges, administrative fees and
      additional charges for optional benefits. There are certain contract limitations and restrictions associated with
      the Retirement Cornerstone® contract, which include, but are not limited to, a contract fee and additional charges
      for optional benefits. Speak to a financial professional for costs and complete details.
    </div>
    <div>
      The Retirement Cornerstone<sup>®</sup> variable annuity contains two distinct accounts within a single
      tax-deferred product: one account, the Investment Account, offers an extensive selection of over 100 investment
      portfolios from well-known investment managers, and the other account, the Protected Benefit Account, offers a
      focused selection of investment portfolios, which fund the optional guaranteed benefit riders, available for an
      additional fee. As your needs change over the years, starting at age 50, you can simply transfer assets from the
      Investment Account to the Protected Benefit Account. Transfers from the Protected Benefit Account to the
      Investment Account are not allowed. The guaranteed riders may be elected only at the time of the application. For
      distributions made from this annuity contract, the aggregate account value in the Investment Account and the
      Protected Benefit Account will be used to determine the tax consequences of any such distribution.
    </div>
    <div>
      The income from the Guaranteed Minimum Income Benefit (GMIB) is calculated using your “benefit base”. A “benefit
      base” is used to generate a minimum income amount or withdrawal amount and is not a cash value. It is equal to the
      total amount of contributions and transfers into the Protected Benefit Account investment options and increases
      annually at a specified rate, called a Roll-Up Rate, during the GMIB Roll-Up Period. The Deferral and Annual
      Roll-Up Rates compound annually and are guaranteed to be locked in for the first 7 contract years. After the first
      7 contract years, the rate becomes a flexible rate tied to the recent average 10-Year Treasury rate plus 2.00% and
      is recalculated each contract year. Both the Deferral and Annual Roll-Up Rates can be as high as 10% and will
      never be less than 6%. Taking a withdrawal from the Protected Benefit Account greater than the Annual Withdrawal
      Amount (an “excess withdrawal”) in any given year will have an adverse effect on income guarantees, reducing your
      benefit base pro rata and potentially cancelling the No-Lapse Guarantee.
    </div>
    <div>
      The GMIB rider fee is 1.40% (2.50% maximum) of the benefit base deducted from the Protected Benefit Account Value
      on each contract anniversary. The issuing life insurance company has discretion to change the rider fee level
      after the first two contract years within the minimum/maximum. The Guaranteed Minimum Income Benefit can be
      dropped without penalty or exercised if the fee is increased.
    </div>
    <div>
      Certain types of contracts, features and benefits may not be available in all jurisdictions. Equitable Financial
      and Equitable America offer other variable annuity contracts with different fees, charges and features. Not every
      contract is available through the same selling broker/dealer. Equitable Financial and Equitable America may
      discontinue contributions and transfers among investment options or make other changes in contribution and
      transfer requirements and limitations. If we discontinue contributions and transfers into the Protected Benefit
      Account, you will no longer be able to fund your guaranteed benefits.
    </div>
    <div>
      This content is not a complete description of all material provisions of the variable annuity contract. This
      content must be preceded or accompanied by a current Retirement Cornerstone<sup>®</sup> Series
      prospectus and any applicable supplements.
    </div>
    <div>
      If you are purchasing an annuity contract to fund an Individual Retirement Account (IRA) or employer-sponsored
      retirement plan, you should be aware that such annuities do not provide tax-deferral benefits beyond those already
      provided by the Internal Revenue Code. Before purchasing one of these annuities, you should consider whether its
      features and benefits beyond tax deferral meet your needs and goals. You may also want to consider the relative
      features, benefits and costs of these annuities with any other investment that you may use in connection with your
      retirement plan or arrangement.
    </div>
    <div>
      When distributed outside of New York State by Equitable Advisors, LLC (member FINRA, SIPC) (Equitable Financial
      Advisors in MI and TN) through Equitable Advisors Financial Professionals whose business address is not in New
      York State, or when distributed by Equitable Distributors, LLC through financial professionals of unaffiliated
      broker-dealers when the solicitation state is not New York, the Retirement Cornerstone<sup>®</sup> variable
      annuity is issued by Equitable Financial Life Insurance Company of America (Equitable America), an AZ stock
      company. When offered by Equitable Advisors Financial Professionals whose business address is in New York State or
      when distributed by Equitable Distributors, LLC through financial professionals of unaffiliated broker-dealers
      when the solicitation state is New York, Retirement Cornerstone<sup>®</sup> is issued by Equitable Financial Life
      Insurance Company (Equitable Financial) (NY, NY). The obligations of Equitable America and Equitable Financial are
      backed solely by their own claims-paying abilities.
      <br />
      Equitable is the brand name of the retirement and protection subsidiaries of Equitable Holdings, Inc., including
      Equitable Financial Life Insurance Company (NY, NY); Equitable Financial Life Insurance Company of America, an AZ
      stock company; and Equitable Distributors, LLC. Equitable Advisors is the brand name of Equitable Advisors, LLC
      (member FINRA, SIPC) (Equitable Financial Advisors in MI & TN).
    </div>
    <div *appFeatureFlag="'isNational'">GE-4766162.1 (6/22) (Exp. 6/2024)</div>
    <div *appFeatureFlag="'isPrimerica'">GE-4766162.1 PFS (6/22) (Exp. 6/2024)</div>
  </div>
</div>

<!-- Tab 2 Age Limitation -->
<div *ngIf="activeIndex === 1">
  <h1 class="title-heading">
    Age Limitation Disclosure
  </h1>
  <div class="content">
    <div>
      In the event of the contract owner’s death, there are certain rules and
      restrictions for single and joint life spousal beneficiaries who elect to
      continue the contract under spousal continuation. Depending on the
      surviving spouse’s age, spousal continuation may not be available. If
      spousal continuation is elected, certain benefits, such as the Guaranteed
      Minimum Income Benefit (GMIB) and Guaranteed Minimum Death Benefits
      (GMDBs), either may not be available or may not continue to be funded
      through the Protected Benefit Account. Please consult the prospectus for
      the full rules covering spousal continuation.
    </div>
  </div>
</div>

<!-- Tab 3 Diversify Your Portfolio -->
<div *ngIf="activeIndex === 2">
  <h1 class="title-heading">
    Diversify Your Portfolio Disclosure
  </h1>
  <div class="content">
    <div>
      This example illustrates the historical results of the indices which
      represent the various industry sectors. Past performance is not a
      guarantee of future results. Individuals may not invest directly in an
      index. Diversification does not guarantee a profit, nor does it eliminate
      the risk of loss of principal. Please see the glossary for definitions of
      each industry category.
    </div>
  </div>
</div>

<!-- Tab 4 Guaranteed Income for Life -->
<div *ngIf="activeIndex === 3">
  <h1 class="title-heading">
    Guaranteed Income for Life Disclosure
  </h1>
  <div class="content">
    <div>
      Retirement Cornerstone<sup>®</sup> Guaranteed Minimum Income Benefit (GMIB) guarantees lifetime payments when you
      annuitize the GMIB Benefit Base after the specified waiting period (generally at the 10th or later contract
      anniversary following when you first fund the GMIB). You are eligible for resets until age 95 on the GMIB. Note
      that a reset may extend the GMIB exercise date. You will be enrolled in an automatic annual reset program at the
      time of the application unless a different reset option is selected. Once the benefit base is reset, a new waiting
      period to exercise the GMIB will apply from the date of reset but will never be later than age 95. As a result,
      the total dollar amount charged on future contract anniversaries may increase since the charges may be applied to
      a higher benefit base. Your Annual Withdrawal Amount is available to you unless your Protected Benefit Account
      value falls to zero. At that point, the GMIB is exercised automatically if the No-Lapse guarantee is in effect.
    </div>
    <div>
      Taking a withdrawal from the Protected Benefit Account greater than the Annual Withdrawal Amount (an “excess
      withdrawal”) in any given year will have an adverse effect on income guarantees, reducing your benefit base pro
      rata and potentially cancelling the No-Lapse Guarantee. A pro rata adjustment or reduction reduces the benefit
      base by the same percentage that the Protected Benefit Account Value is reduced by the withdrawal. Therefore, the
      amount of a pro rata reduction may be greater or lesser than the dollar amount of the withdrawal. If an excess
      withdrawal reduces the Protected Benefit Account Value to zero, the GMIB will terminate. The GMIB rider fee is
      1.40% (2.50% maximum) of the benefit base deducted from the Protected Benefit Account Value on each contract
      anniversary.
      <br />
      The issuing life insurance company has discretion to change the rider fee level after the first two contract years
      within the minimum/maximum. The Guaranteed Minimum Income Benefit can be dropped without penalty or exercised if
      the fee is increased.
    </div>
    <div>
      The RMD Wealth Guard GMDB is not available in New York.
    </div>
    <div>
      View current rates on
      <equitable-link (click)="visitEquitable(site.EQUITABLE_RATES)"
        text="{{ 'disclosure.rc.equitableLink' | translate }}" [type]="readMoreLinkType"></equitable-link>
    </div>
  </div>
</div>

<!-- Tab 5 Inflation -->
<div *ngIf="activeIndex === 4">
  <h1 class="title-heading">
    Inflation Disclosure
  </h1>
  <div class="content">
    <div>
      The predicted values in this hypothetical example were calculated based on
      an assumed 3% inflation rate.
    </div>
  </div>
</div>

<!-- Tab 6 Interest Rate -->
<div *ngIf="activeIndex === 5">
  <h1 class="title-heading">
    Interest Rate Disclosure
  </h1>
  <div class="content">
    <div>
      There is no guarantee that historical trends will continue in the future.
      <br />
      <br />
      The data contained in this chart was sourced as follows:
      <br />
      1) 10-Year Treasury rates came from
      <equitable-link (click)="visitEquitable(site.MULTPL)" text="{{ 'disclosure.rc.multpl' | translate }}"
        [type]="readMoreLinkType"></equitable-link>
      <br />
      2) Average 30 Year Mortgage rates came from Freddie Mac
    </div>
  </div>
</div>

<!-- Tab 7 Investment Options -->
<div *ngIf="activeIndex === 6">
  <h1 class="title-heading">
    Investment Options Disclosure
  </h1>
  <div class="content">
    <div>
      Variable investment portfolios are subject to market risk including loss
      of principal
    </div>
  </div>
</div>

<!-- Tab 8 Invest Tax Smart -->
<div *ngIf="activeIndex === 7">
  <h1 class="title-heading">
    Invest Tax Smart Disclosure
  </h1>
  <div class="content">
    <div>
      Assumes lump-sum withdrawal or distribution. This hypothetical chart does
      not represent actual performance of any specific product or investment.
      Withdrawals of tax-deferred earnings are subject to ordinary income tax. A
      10% federal tax may also apply if you take the withdrawal before you reach
      age 59½. Dividends and sales profits on annually taxed investments are
      generally taxed at capital gains tax rates, which can be lower than
      ordinary federal income tax rates. Using capital gains tax rates with the
      taxed-annually investment would reduce the difference between the
      taxed-annually and tax-deferred accounts shown above. Please note that
      this chart excludes expenses associated with Retirement
      Cornerstone<sup>®</sup>, including the administration, distribution and
      operations fees. Retirement Cornerstone<sup>®</sup> charges include 0.80%
      operations fee, 0.30% administration fee and 0.20% distribution fee for
      the Series B contract. The withdrawal charge declines from 7% to 1% over 7
      years for the Retirement Cornerstone<sup>®</sup>
      Series B contract. If expenses had been reflected, the tax-deferred
      amounts would be lower. Consider your personal investment horizon and
      income tax bracket, both current and anticipated, when making an
      investment decision. These factors, as well as changes in tax rates and
      the treatment of investment earnings, may further affect the results of
      this comparison. Actual results will vary. Rates of return will vary over
      time, particularly for long-term investments. Investments offering the
      potential for higher rates of return also involve a higher degree of risk.
    </div>
  </div>
</div>

<!-- Tab 9 Market Ups & Downs -->
<div *ngIf="activeIndex === 8">
  <h1 class="title-heading">
    Market Ups & Downs Disclosure
  </h1>
  <div class="content">
    <div>
      The S&P 500<sup>®</sup> Index comprises 500 of the largest companies in
      leading industries of the U.S. economy. Larger, more established companies
      may not be able to attain potentially higher growth rates of smaller
      companies, especially during extended periods of economic expansion.
      Individuals cannot invest directly in an index. Past performance is not a
      guarantee of future results. There is no guarantee that historical trends
      will continue into the future.
    </div>
  </div>
</div>

<!-- Tab 10 Rising Income Potential -->
<div *ngIf="activeIndex === 9">
  <h1 class="title-heading">
    Rising Income Potential Disclosure
  </h1>
  <div class="content">
    <div>
      With the GMIB, it is important to be aware that annual dollar-for-dollar withdrawals made before the rider is
      exercised can potentially be greater than the annual guaranteed income stream that is ultimately realized under
      the rider. In particular, if you take withdrawals on a dollar-for-dollar basis and the Protected Benefit Account
      Value goes to zero while the No-Lapse Guarantee is still in effect, or you annuitize under the GMIB, the
      guaranteed lifetime income amount could go down depending on your current age.
    </div>
    <div>
      You can take annual dollar-for-dollar withdrawals from the Protected Benefit Account cash value for income and
      still maintain a GMIB Benefit Base at least equal to the amounts contributed or transferred into the Protected
      Benefit Account. However, a guaranteed lifetime income amount is not established under GMIB until you exercise the
      rider through annuitization and begin receiving the supplemental annuity payments. That guaranteed lifetime income
      amount is calculated by applying the GMIB rider annuity payout rates to the benefit base, and it depends on your
      age and the size of the benefit base. The annual guaranteed lifetime income from the GMIB rider may be higher or
      lower than the annual dollar-for-dollar withdrawal amounts previously received.
    </div>
    <div>
      The GMIB rider fee may be raised or lowered at any time within the minimum/maximum, after the first two contract
      years, at the company’s discretion. Certain features available with the Retirement Cornerstone<sup>®</sup> 19
      variable annuity, including the Guaranteed Minimum Income Benefit, are optional and entail an additional cost; you
      do not have to elect these optional features in order to purchase a Retirement Cornerstone<sup>®</sup> contract.
    </div>
    <div>
      The IRS requires the owner of an IRA or Qualified Plan annuity contract to include the actuarial present value of
      certain optional benefits (which may include some of these Retirement Cornerstone<sup>®</sup> optional features)
      in the calculation of lifetime Required Minimum Distribution payments. Earnings are taxable as ordinary income
      when distributed and may be subject to a 10% additional federal tax if withdrawn before age 59½. Withdrawals may
      also be subject to withdrawal charges. Withdrawals will reduce the death benefit (other than RMD withdrawals under
      the RMD Wealth Guard GMDB) cash surrender value. For tax purposes, withdrawals will come from any gain in the
      contract first. The contingent withdrawal charge declines from 7% over a seven-year period for the Series B
      product. Please see the prospectus for the withdrawal charge scale for other annuities in the Retirement
      Cornerstone<sup>®</sup> Series.
    </div>
    <div>
      This content is not a complete description of all material provisions of the variable annuity contract. This
      material must be preceded or accompanied by a current Retirement Cornerstone<sup>®</sup> Series prospectus and any
      applicable supplements. The prospectus contains more complete information, including investment objectives, risks,
      charges, expenses, limitations and restrictions. Please read the prospectus and any applicable supplements, and
      consider this information carefully before purchasing a contract
    </div>
    <div>
      There are certain contract limitations and restrictions associated with the Retirement Cornerstone<sup>®</sup>
      contract, which include, but are not limited to, a contract fee and additional charges for optional benefits. In
      addition, the underlying investment portfolios charge management fees and have other expenses. For costs and
      complete details of coverage, speak to your financial professional/insurance-licensed registered representative.
      Certain types of contracts, features and benefits may not be available in all jurisdictions. Equitable Financial
      and Equitable America offer other variable annuity contracts with different fees, charges and features. Not every
      contract is available through the same selling broker/dealer. Equitable Financial and Equitable America may
      discontinue contributions and transfers among investment options or make other changes in contribution and
      transfer requirements and limitations. If we discontinue contributions and transfers into the Protected Benefit
      Account, you will no longer be able to fund your guaranteed benefits.
    </div>
    <div>
      You can contact Equitable at (212) 554-1234 to find out the availability of other contracts. This material was
      prepared to support the promotion and marketing of Equitable Financial Life Insurance Company and Equitable
      Financial Life Insurance Company of America variable annuities. Equitable Financial, Equitable America, its
      distributors and their respective representatives do not provide tax, accounting or legal advice. Any tax
      statements contained herein were not intended or written to be used, and cannot be used, for the purpose of
      avoiding U.S. federal, state or local tax penalties. Please consult your own independent advisors as to any tax,
      accounting or legal statements made herein. Call your financial professional or visit equitable.com.
    </div>
    <div>
      Guarantees described herein are subject to the claims-paying ability the issuing life insurance company. The
      guarantees do not apply to the investment portfolios.
    </div>
    <div>
      When distributed outside of New York State by Equitable Advisors, LLC (member FINRA, SIPC) (Equitable Financial
      Advisors in MI and TN) through Equitable Advisors Financial Professionals whose business address is not in New
      York State, or when distributed by Equitable Distributors, LLC through financial professionals of unaffiliated
      broker-dealers when the solicitation state is not New York, the Retirement Cornerstone® variable annuity is issued
      by Equitable Financial Life Insurance Company of America (Equitable America), an AZ stock company. When offered by
      Equitable Advisors Financial Professionals whose business address is in New York State or when distributed by
      Equitable Distributors, LLC through financial professionals of unaffiliated broker-dealers when the solicitation
      state is New York, Retirement Cornerstone® is issued by Equitable Financial Life Insurance Company (Equitable
      Financial) (NY, NY). The obligations of Equitable America and Equitable Financial are backed solely by their own
      claims-paying abilities.
    </div>
    <div>
      Equitable is the brand name of the retirement and protection subsidiaries of Equitable Holdings, Inc., including
      Equitable Financial Life Insurance Company (NY, NY); Equitable Financial Life Insurance Company of America, an AZ
      stock company; and Equitable Distributors, LLC. Equitable Advisors is the brand name of Equitable Advisors, LLC
      (member FINRA, SIPC) (Equitable Financial Advisors in MI & TN).
      <br>
      Contract form #s: ICC12BASE4, ICC12BASE3 and any state variations.
    </div>
  </div>
</div>