import { Component, OnInit } from "@angular/core";
import { Theme, Orientation, Icon } from "equitable-component-library";
import { PRODUCT_TYPE, ROUTE_PATH } from "../../../constants/constants";
import { UtilityService } from "../../../core/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { IeService } from "../../services/ie.service";
import { ScsService } from "../../../scs/services/scs.service";

@Component({
  selector: 'app-fund-focus',
  templateUrl: './fund-focus.component.html',
  styleUrls: ['./fund-focus.component.scss'],
})
export class FundFocusComponent implements OnInit {
  type = Theme.Dark;
  orientation = Orientation.Left;
  backIcon = Icon.BackButton;

  constructor(
    private utilityService: UtilityService,
    private translate: TranslateService
  ) { }

  ngOnInit() {}

  backButton() {
    this.utilityService.navigateWithoutParams(ROUTE_PATH.IE.TOOLS.HOME);
  }
}