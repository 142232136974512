import { Component, OnInit } from "@angular/core";
import { RcService } from "../../services/rc.service";
import { DiscoverRc, Profile } from "../../../core/models/app.models";
import { UtilityService } from "../../../core/services/utility.service";
import { Location } from '@angular/common';

import {
  LinkType,
  BoxButtonIcon,
  LinkIcon,
  Orientation,
} from "equitable-component-library";
import {
  ROUTE_PATH,
  CUSTOMIZATION_TYPE,
  PRODUCT_TYPE,
} from "src/app/constants/constants";
import { CoreService } from "src/app/core/services/core.service";

@Component({
  selector: "app-rc-advanced-tool-resources-dashboard",
  templateUrl: "./rc-advanced-tool-resources-dashboard.component.html",
  styleUrls: ["./rc-advanced-tool-resources-dashboard.component.scss"],
})
export class RcAdvancedToolResourcesDashboardComponent implements OnInit {
  toolDashboardData: DiscoverRc = {
    getFileUrl: "",
    resource: [],
    tools: [],
   
  };
  LinkType = LinkType;
  LinkIcon = LinkIcon;
  Orientation = Orientation;

  constructor(
    private rcService: RcService,
    private utilityService: UtilityService,
    private coreService: CoreService,
    private location: Location
  ) {}

  ngOnInit() {
    this.getResource();

    //reset selection
    if (this.coreService.product !== PRODUCT_TYPE.RC) {
      this.coreService.profile.next({
        name: "",
        type: CUSTOMIZATION_TYPE.REPORT,
        profile: new Profile(),
      });
      this.coreService.report = new Profile();
    }
    this.coreService.product = PRODUCT_TYPE.RC;

    this.toolDashboardData.tools = [
      {
        icon: BoxButtonIcon.HowSIOWorks,
        label: "rcDashbaord.tools.buildWealth",
        routePath: ROUTE_PATH.RC.TOOLS.BUILD_WEALTH,
      },
      {
        icon: BoxButtonIcon.HistoricalPerformance,
        label: "rcDashbaord.tools.protectIncome",
        routePath: ROUTE_PATH.RC.TOOLS.PROTECT_INCOME,
      },
      {
        icon: BoxButtonIcon.PerformanceCapRates,
        label: "rcDashbaord.tools.preserveReport",
        routePath: ROUTE_PATH.RC.TOOLS.PRESERVE_LEGACY,
      },
      {
        icon: BoxButtonIcon.CreateAReport,
        label: "rcDashbaord.tools.createReport",
        routePath: ROUTE_PATH.RC.TOOLS.CREATE_REPORT,
      },
      {
        icon: BoxButtonIcon.ProductOverview,
        label: "rcDashbaord.tools.productOverview",
        routePath: ROUTE_PATH.RC.DISCOVER,
      },
      {
        icon: BoxButtonIcon.InvestorProfiles,
        label: "rcDashbaord.tools.investorProfiles",
        routePath: ROUTE_PATH.RC.TOOLS.INVESTOR_PROFILE,
      },
    ];
  }

  // fetch Resource from API
  getResource() {
    this.rcService.fetchResources().subscribe((getData: DiscoverRc) => {
      if (getData["SalesKitFiles"]) {
        getData["SalesKitFiles"].forEach((item) => {
          this.toolDashboardData.resource.push(item);
        });
      }
    });
  }

  // on click of resources links
  goToResources(i) {
    const file = this.toolDashboardData.resource[i]["file"];
    this.utilityService.visitEquitable(file);
  }

  // On click Card button
  cardClick(link: string) {
    this.utilityService.navigate(link, {
      section: "rc",
    });
  }

  // back button navigation
  backButton() {
    this.location.back()
   // this.utilityService.navigate(ROUTE_PATH.HOME);
  }

  goToScsIncomeLandingPage(): void {
    //  this.utilityService.navigateWithoutParams(ROUTE_PATH.IE.TOOLS.HOME);
      this.utilityService.navigateWithoutParams(ROUTE_PATH.SCSINCOME.TOOLS.HOME);
    }

  goToScsLandingPage(): void {
  //  this.utilityService.navigateWithoutParams(ROUTE_PATH.IE.TOOLS.HOME);
    this.utilityService.navigateWithoutParams(ROUTE_PATH.SCS.TOOLS.HOME);
  }
  goToScsPLUSLandingPage(): void {
    this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER + '?series=PLUS');
  }

  goToScsPLUS21LandingPage(): void {
    this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER + '?series=PLUS21');
  }

  goToIeLandingPage() {
    this.utilityService.navigateWithoutParams(ROUTE_PATH.IE.TOOLS.HOME);
  }
}
