<ion-content>

  <!-- 
      <equitable-header-image
    description="{{ 'discoverScs.scs' | translate }}"
    image="./assets/images/discover-scs-banner.png"
  ></equitable-header-image>

  -->

  <!-- sourced from hader image -->



  <section class="hero-main">
    <div
      *ngIf="image"
      class="hero-container"
      [ngStyle]="{ 'background-image': 'url(' + image + ')' }"
    ></div>
  
    <div class="banner-text-container">
      <div *ngIf="title" class="banner-title">{{ title }}</div>
      <div
        *ngIf="bannerDescription"
        class="banner-description"
        innerHTML="{{ bannerDescription }}"
      ></div>

      <!-- added code from drop-down component 
      <div [formGroup]="dashboardForm" class="select-wrapper" >
        <select [ngModel]="productValue"
          #select1
          [formControl]="dashboardForm.get('seriesDropDown')"
          (focusin)="status = true"
          (focusout)="status = false"
          (change)="productMenuChange(false)"
          class="drop-down"
          aria-label="drop down"
          [ngClass]="status ? 'arrowUp' : 'arrowDown'"
        >
          <option
            *ngFor="let option of options"
            [value]="option.id"
            innerHTML="{{ option.value }}"
          ></option>
        </select>
      </div>-->


    </div>
  </section>
  
  <!-- // sourced from hader image -->
  
  <article class="discover-scs">
    <header>
      <p [innerHTML]="pageDescription"></p>
    </header>

    <div class="container">
      <ion-grid class="ion-no-padding">
        <ion-row class="section-space">
          <ion-col>
            <ion-row class="feature-section">
              <ion-col size="12">
                <h2 class="feature-heading">
                  {{ "discoverScs.featuresBenefits" | translate }}
                </h2>
                <!-- equitable-card-benefit  
                <div *ngIf="this.productValue == 'scsincome'" class="benefit-card">
                  <div
                    *ngFor="let card of discoverScsIncomeData.featureCards_Income"
                    class="benefit-card-inner"
                  >
                    <equitable-card-benefit
                      [icon]="card.icon"
                      [description]="card.description | translate"
                      [title]="card.title | translate"
                    ></equitable-card-benefit>
                  </div>
                </div>-->
                <!--starts new icon cards-->

                
<div class="row">
  <div class="component card-cmpnt card-icon col-6 col-lg-3">
      <div class="component-container">
          <div class="cc-card">
              <div class="cc-image">
                  <div class="cc-image-contain">
                      <a href="javascript:void(0)" aria-disabled="true">
                          <img src="./assets/images/icon-hand-money-circle.svg" alt="Holding cash icon">
                      </a>
                  </div>
              </div>
              <div class="cc-content">
                  <h5>Lifetime income</h5>
                  <p>Receive guaranteed income for as long as you live.<p>
              
              </div>
          </div>
      </div>
  </div>
  <div class="component card-cmpnt card-icon col-6 col-lg-3">
      <div class="component-container">
          <div class="cc-card">
              <div class="cc-image">
                  <div class="cc-image-contain">
                    <a href="javascript:void(0)" aria-disabled="true">
                          <img src="./assets/images/icon-graph-circle.svg" alt="Graph icon">
                      </a>
                  </div>
              </div>
              <div class="cc-content">
                  <h5>Opportunities to increase your income</h5>
                  <p>Innovative features help to optimize and grow your income.</p>
           
              </div>
          </div>
      </div>
  </div>
  <div class="component card-cmpnt card-icon col-6 col-lg-3">
      <div class="component-container">
          <div class="cc-card">
              <div class="cc-image">
                  <div class="cc-image-contain">
                    <a href="javascript:void(0)" aria-disabled="true">
                          <img src="./assets/images/icon-umbrella-circle.svg" alt="Umbrella icon">
                      </a>
                  </div>
              </div>
              <div class="cc-content">
                  <h5>Level of downside protection</h5>
                  <p>Gain a level of protection from market declines.</p>
               </div>
          </div>
      </div>
  </div>
  <div class="component card-cmpnt card-icon col-6 col-lg-3">
      <div class="component-container">
          <div class="cc-card">
              <div class="cc-image">
                  <div class="cc-image-contain">
                    <a href="javascript:void(0)" aria-disabled="true">
                          <img src="./assets/images/icon-piggy.svg" alt="piggy bank icon">
                      </a>
                  </div>
              </div>
              <div class="cc-content">
                  <h5>Low cost, great value</h5>
                  <p>High fees can destroy your returns. Keep your costs down with explicit fees as low as 1.50%.<sup>1</sup>
                </p>
                 
              </div>
          </div>
      </div>
  </div>
  
</div>
              
                 <!--//////////////////////ends new icon cards-->
                <div *ngIf="this.productValue != 'scsincome'" class="benefit-card d-none">
                  <div
                    *ngFor="let card of discoverScsIncomeData.featureCards"
                    class="benefit-card-inner"
                  >
                    <equitable-card-benefit
                      [icon]="card.icon"
                      [description]="card.description | translate"
                      [title]="card.title | translate"
                    ></equitable-card-benefit>
                  </div>
                </div>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

<div *appFeatureFlag="'isIR'">
    <div *ngIf="this.productValue != 'scsplus21'">
        <section *ngIf="videos.length > 0 && !this.isForPLUS">
          <equitable-video #videoFrame
            class="child-video-section"
            [videos]="video_SCSINCOME"
            [headerText]="'discoverScs.watchVideo' | translate"
          ></equitable-video>
        </section>
        <section class="video" *ngIf="this.isForPLUS && videos.length > 0" >
          <equitable-video #videoFrame
            class="child-video-section"
            [videos]="video_SCSINCOME"
            [headerText]="'discoverScs.watchVideo' | translate"
          ></equitable-video>
        </section>
      </div>
  </div> 



<!--     <div class="container d-none">
      <div class="scenario-description">
        <h2 [innerHTML]="howSCSWorks"></h2>
        <p
          [innerHTML]="standardSegmentIsAnOption"
        ></p>
      </div>
      <section class="how-scs-works">
        <div class="how-scs-works-scenarios">
          <div class="scenario-examples">
            <h5 class="scenario-examples-heading">
              {{ "discoverScs.4DifferentHypotheticalScenarios" | translate }}
            </h5>
            <img *ngIf="this.isForPLUS"
              src="./assets/images/scs-scenarios.png"
              alt="{{ 'discoverScs.4HypotheticalScenarios' | translate }}"
            />
            <img *ngIf="!this.isForPLUS"
              src="./assets/images/scs-scenarios16.png"
              alt="{{ 'discoverScs.4HypotheticalScenarios' | translate }}"
            />
          </div>
        </div>

        <div *ngIf="this.isForPLUS" class="how-scs-works-example">
          <img
            src="./assets/images/6-year-standard-segment.png"
            alt="{{ 'discoverScs.6YearStandardSegment' | translate }}"
          />
        </div>
       
        <div *ngIf="!this.isForPLUS" class="how-scs-works-example">
          <img
            src="./assets/images/6-year-standard-segment.png"
            alt="{{ 'discoverScs.6YearStandardSegment' | translate }}"
          />
        </div>
      </section>
    </div> -->



  <!--new cards start >>>>>>>>>>>>
    >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>-->


    <div class="component-container"><div class="hero-card-banner green">
      <div class="container">
          <div class="col-12 col-sm-8 mx-auto">
                            <h2>Options to set your income level.</h2>
                                      </div>                        
      </div>
  </div>
</div>


<div class="container">

<div class="row justify-content-center">
  <div class="component card-cmpnt two-up hero-card col-12 col-lg-6">
      <div class="component-container">
        
          <div class="cc-card">
              <div class="cc-image">
                  <div class="cc-image-contain">
                      <a href="">
                          <img src="./assets/images/level-overview.svg" alt="Level Income">
                      </a>
                  </div>
              </div>
              <div class="cc-content">
                  <p class="cc-category">Structured Capital Strategies<sup>®</sup> Income</p>
                  <h3>Level Income</h3>
                  <p>The Level Income Option provides income that does not decrease throughout the duration of your contract and may increase, and is guaranteed for the rest of your life.
                  </p>
                
              </div>
          </div>

      </div>
  </div>
  <div class="component card-cmpnt two-up hero-card col-12 col-lg-6">
      <div class="component-container">
          <div class="cc-card">
              <div class="cc-image">
                  <div class="cc-image-contain">
                      <a href="">
                          <img src="./assets/images/accelerated-overview.svg" alt="">
                      </a>
                  </div>
              </div>
              <div class="cc-content">
                  <p class="cc-category">Structured Capital Strategies<sup>®</sup> Income</p>
                  <h3>Accelerated Income</h3>
                  <p>The Accelerated Income Option provides a higher level of income early in retirement. You receive lower income if your account balance drops to zero.</p>
               
              </div>
          </div>

      </div>
  </div>
</div>
</div>
<!--cards end
////////////////////
////////////////////
////////////////////-->


    <div class="container">
      <section class="explore-other-strategies">
        <h2>{{ "discoverScs.exploreOtherStrategies" | translate }}</h2>

        <div class="card-image-container">

  
        
          <equitable-card-image 
          (eventMore)="gotoSCSPLUS21LandingPage()"
          image="./assets/images/protection-from-market.jpg"
          [heading]="'discoverRc.protectionVolatility' | translate"
          [description]="'discoverRc.protectionVolatilityDescIncome' | translate"
          [subContent]="guaranteedIncome"
        >
          {{ "discoverRc.learnMoreBtn" | translate }}
        </equitable-card-image>
          <equitable-card-image 
            (eventMore)="gotoRCLandingPage()"
            image="./assets/images/guaranteed-income.jpg"
            [heading]="'discoverScs.guaranteedIncome' | translate"
            [description]="
              'discoverScs.retirementCornerstoneDesigned' | translate
            "
            [subContent]="guaranteedIncome"
          >
            {{ "discoverScs.learnMore" | translate }}
          </equitable-card-image>

          <equitable-card-image *appFeatureFlag="'isNational'"
            (eventMore)="gotoIELandingPage()"
            image="./assets/images/distribute.jpg"
            [heading]="'discoverScs.deferYourTaxes' | translate"
            [description]="'discoverScs.investmentEdgeHelps' | translate"
            [subContent]="deferYourTaxes"
          >
            {{ "discoverScs.learnMore" | translate }}
          </equitable-card-image>

        
        </div>
      </section>
    </div>

    <equitable-advanced-tool
      image="./assets/images/advanced-tools-income.png"
      [title]="'discoverScs.advancedTools' | translate"
      [description]="'discoverScs.dynamicGraphsVisualizations' | translate"
      [btnLabel]="'discoverScs.learnMore' | translate"
      (btnClick)="gotoAdvancedToolsResourcesLandingPage()"
    ></equitable-advanced-tool>

    <div class="container">

   <div class="row">
      <div class="col-12">
        <div class="disclaimer">
          <span>
              This example is a hypothetical intended for illustrative purposes only and is not indicative
              of the actual performance of any particular product. It assumes no annual resets which may increase future income
              rates if they occur. 
          </span>
         
        <br>
        <ol>
          <li>In addition, expenses related to administration, sales and certain risks in the contract are factored into the Performance Cap Rate. As long as your money is invested in the Structured Investment Option, you will not be charged additional fees. If you choose the optional Highest Anniversary Value (HAV) Death Benefit, or invest your money in a Variable Investment Option, additional fees and charges will apply. </li>
          <li>When Guaranteed Lifetime Withdrawal Benefit (GLWB) is active, an excess withdrawal that reduces the Account Value to zero will be treated as a surrender and the pro-rated fees will be subtracted from the withdrawal.  ​</li>
          <li>All income rates are hypothetical.</li>
        </ol> 
      </div>
      </div>
    </div> 

  </div>
    
  </article>
  <app-footer
    isWhiteFooterVisible="true"
    isBlueFooterVisible="true"
    selectedProduct="scsincome"
  ></app-footer>
</ion-content>
