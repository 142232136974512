import { OnInit } from "@angular/core";
import { ButtonType, Theme } from "equitable-component-library";
import { ROUTE_PATH } from "../../../constants/constants";
import { UtilityService } from "../../../core/services/utility.service";
import { FeatureFlagService } from "../../../core/services/feature-flag.service";
import { CONFIG_DATA, } from "../../../constants/constants";
var PrimericaHomeComponent = /** @class */ (function () {
    function PrimericaHomeComponent(utilityService, featureService) {
        this.utilityService = utilityService;
        this.featureService = featureService;
        this.ButtonType = ButtonType;
        this.Theme = Theme;
        this.route = ROUTE_PATH;
        this.selectedProduct = "PLUS";
        this.isPlusGuard = CONFIG_DATA.ISPLUSGUARD;
        this.isNational = CONFIG_DATA.ISNATIONAL;
        if (this.featureService.featureFlags.isPrimerica)
            this.selectedProduct = "scs";
        else
            this.selectedProduct = "PLUS";
    }
    PrimericaHomeComponent.prototype.ngOnInit = function () { };
    PrimericaHomeComponent.prototype.visitPage = function (route) {
        this.utilityService.navigate(route);
    };
    PrimericaHomeComponent.prototype.visitEquitable = function (url) {
        window.open(url, '_blank');
    };
    return PrimericaHomeComponent;
}());
export { PrimericaHomeComponent };
