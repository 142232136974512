<ion-content>
  <form [formGroup]="formGroup">
    <div class="site-tools page-header" *ngIf="items && items.length > 0">
      <equitable-sub-header
        title="{{ 'howItsWorks.headerText' | translate }}"
        [backLinkText]="backLink"
        [showInfo]="showInfo"
        (backClick)="backButton()"
        (infoClick)="openModal()"
      ></equitable-sub-header>
    </div>
    
    <div class="tool-container site-tools">
      <ion-row>
        <ion-col class="tool-header-text">{{ toolHeaderText }}</ion-col>
      </ion-row>
      <ion-row class="row-width">
        <ion-col class="padding-left" size="6">
          <h6 class="radio">{{ "howItsWorks.segmentOption" | translate }}</h6>
          <equitable-radio-button
            *ngIf="items && items.length > 0"
            class="radio-button"
            [items]="items"
            formControlName="segment"
            [formControl]="formGroup.get('segment')"
          ></equitable-radio-button>
        </ion-col>
        <ion-col size="6">
          <ion-row>
            <ion-col>
              <h6 class="drop">{{ "howItsWorks.index" | translate }}</h6>
              <equitable-drop-down
                class="dropdown"
                [formControl]="formGroup.get('index')"
                formControlName="index"
                [options]="indices"
              ></equitable-drop-down>
            </ion-col>
            <ion-col>
              <h6 class="drop">{{ "howItsWorks.duration" | translate }}</h6>
              <equitable-drop-down
                [formControl]="formGroup.get('duration')"
                formControlName="duration"
                [options]="durations"
              ></equitable-drop-down>
            </ion-col>
            <ion-col>
              <h6 class="drop">
                {{ "howItsWorks.protection" | translate }}
              </h6>
              <equitable-drop-down
                [formControl]="formGroup.get('protection')"
                formControlName="protection"
                [options]="protections"
              ></equitable-drop-down>
            </ion-col>
          </ion-row>
          <!-- START SEGMENT INVESTMENT DROPDOWN -->
          <ion-row *ngIf="isNational">
            <ion-col class="border-top">
              <label class="equitable-input-label">Segment Investment</label>
              <div class="input-contain">
                <span class="equitable-input-prefix">$</span>
                <input type="number" name="dollarInputValue" (click)="$event.target.select()" [(ngModel)]="dollarInputValue" [formControl]="formGroup.get('dynamicDollarAmount')" formControlName="dynamicDollarAmount" class="equitable-input dollar-input" id="segmentMaturity" placeholder="100000" 
                  [min]="minValue" [max]="maxValue" (keydown.enter)="onSubmitOfDollarAmount()" required [step]="1" [ngStyle]="{'border-color': isValid() ? '#767676' : 'red', 'border-width': '1px'}">
                <button class="btn btn-primary" (click)="onSubmitOfDollarAmount()">Update</button>
                <p class="invalid-feedback" *ngIf="dollarInputValue && (dollarInputValue < minValue || dollarInputValue > maxValue)">Value must be between {{minValue}} and {{maxValue}}</p>
                <p class="invalid-feedback" *ngIf="dollarInputValue && dollarInputValue % 1 !== 0">Value must be a whole number</p>
              </div>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <div
            *ngIf="
              this.formGroup.get('segment').value === SEGMENT_TYPE.ANNUAL_LOCK
            "
            class="annualMarketScenerio"
          >
            <equitable-radio-button
              class="radio-button"
              [items]="marketScenerios"
              formControlName="marketScenerio"
              [formControl]="formGroup.get('marketScenerio')"
            ></equitable-radio-button>
          </div>

          <div>
            <div class="dualImage" *ngIf="!isDynamicChart">
              <img alt="Dual Direction Chart" *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_DIRECTION && this.formGroup.get('protection').value == -10 && (isPrimerica)" src="/assets/images/dual_direction.png" />
              <img alt="Dual Direction Chart" *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_DIRECTION && this.formGroup.get('protection').value == -15 && (isPrimerica)" src="/assets/images/dual_direction15.png" />
              <img alt="Dual Direction Chart" *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_DIRECTION && this.formGroup.get('protection').value == -20 && (isPrimerica)" src="/assets/images/dual_direction20.png" />
              <img alt="Dual Direction Chart" *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_DIRECTION && this.formGroup.get('protection').value == -10 && (isPlusGuard)" src="/assets/images/dual_direction_10PG.png" />
              <img alt="Dual Direction Chart" *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_DIRECTION && this.formGroup.get('protection').value == -15 && (isPlusGuard)" src="/assets/images/dual_direction_15PG.png" />
            </div>


            <equitable-bar-chart *ngIf="this.formGroup.get('segment').value !== SEGMENT_TYPE.DUAL_DIRECTION"
              [chartData]="chartData"
              isBarChart="true"
              lagendsLabel="{{ 'productKey' | translate }}"
              [segmentType]="this.formGroup.get('segment').value"
              [performanceData]="performanceData"
              [buffer]="this.formGroup.get('protection').value"
              [series]="this.series" 
              [index]="this.formGroup.get('index').value" 
              [duration]="this.formGroup.get('duration').value"
              productType="ie"
            ></equitable-bar-chart>

            <dual-direction *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_DIRECTION && isNational"
              [performanceData]="performanceData"
              [buffer]="this.formGroup.get('protection').value"
              [series]="this.series" 
              [index]="this.formGroup.get('index').value" 
              [duration]="this.formGroup.get('duration').value"
              [dynamicSegmentMaturityValue]="this.formGroup.get('dynamicDollarAmount').value"
              productType="ie"
          ></dual-direction>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <div  *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_DIRECTION" class="container">
            <div class="row">
              <div class="col-12">
                <p>For Series B and Select: This example is intended to demonstrate how the Segment Buffer and Performance Cap Rate work and does not reflect the contract fee, which is included in the Segment Rate of Return calculation.  For positive segment returns and returns within the Segment Buffer, the increase in the account value will always be less than the absolute value of the Index Performance Rate or the Performance Cap Rate, where applicable, due to the deduction of the Contract Fee.  For negative segment returns beyond the Segment Buffer, the decrease in account value will be more than the amount by which the Index Performance Rate exceeds the Segment Buffer due to the deduction of the contract fee. If there is a return of zero, the account value will decrease due to the deduction of the contract fee.</p>
              </div>
              
            </div>
          </div>

          <div *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.STANDARD" class="container">
            <div class="row">
              <div class="col-12">
                <p>For Series B and Select: This example is intended to demonstrate how the Segment Buffer and Performance Cap Rate work, and does not reflect the contract fee, which is included in the Segment Rate of Return calculation. For positive Segment returns, the increase in the account value will always be less than the index performance rate or the Performance Cap Rate, where applicable, due to the deduction of the contract fee. For negative Segment returns beyond the Segment Buffer, the decrease in account value will be more than the amount by which the index performance rate exceeds the Segment Buffer due to the deduction of the contract fee. If there is a return of zero or a return within the Segment Buffer, the account value will decrease due to the deduction of the contract fee. </p>
              </div>
            </div>
            </div>
            <div *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.STEP_UP" class="container">
                <div class="row">
                    <div class="col-12">
                      <p>For Series B and Select: This example is intended to demonstrate how the Segment Buffer and Performance Cap Rate work and does not reflect the contract fee, which is included in the Segment Rate of Return calculation. For positive Segment returns or returns of zero, the increase in the account value will always be less than the  Performance Cap Rate due to the deduction of the Contract Fee. For negative segment returns beyond the Segment Buffer, the decrease in account value will be more than the amount by which the index performance rate exceeds the Segment Buffer due to the deduction of the contract fee. If there is a return within the Segment Buffer, the account value will decrease due to the deduction of the contract fee.</p>
                    </div>
                  </div>
              
              
             </div>
          
        </ion-col>
      </ion-row> 
      <!-- <ion-row>
        <ion-col>
          <div class="series-title" *ngIf="isNotPlusGuard">
            {{ "historyGuide.viewingSeries" | translate }} {{ series }}
          </div>
        </ion-col>
      </ion-row>  -->
      <div class="link-container">
        <!-- <div class="first-link">
          <equitable-link
            [text]="'howItsWorks.customProfile' | translate"
            [iconName]="linkRightIcon"
            [type]="type"
            [orientation]="orientation"
            (click)="saveProfile(CUSTOMIZATION_TYPE.PROFILE)"
          ></equitable-link>
        </div> -->
        <div class="second-link">
          <equitable-link
            [text]="'howItsWorks.customizeReport' | translate"
            [iconName]="linkRightIcon"
            [type]="type"
            [orientation]="orientation"
            (click)="saveProfile(CUSTOMIZATION_TYPE.REPORT)"
          ></equitable-link>
        </div>
      </div>
    </div>
  </form>
  <app-footer
    isWhiteFooterVisible="true"
    isBlueFooterVisible="true"
    selectedProduct="ie"
  ></app-footer>
</ion-content>
