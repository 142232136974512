import { Component, OnInit, AfterViewChecked } from "@angular/core";
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import {
  Theme,
  Orientation,
  Icon,
  LinkIcon,
  LinkType,
} from "equitable-component-library";
import { UtilityService } from "../../../core/services/utility.service";
import { ScsService } from "../../services/scs.service";
import {
  SCS_SERIES,
  ROUTE_PATH,
  CUSTOMIZATION_TYPE,
  CUSTOM_PROFILE,
} from "../../../constants/constants";
import {
  HistoryMayBeAGuidePlus,
  HistoryMayBeAGuidePlusColumns,
  Profile,
} from "../../../core/models/app.models";
import { ModalPopupComponent } from "../../../core/components/modal-popup/modal-popup.component";
import { FeatureFlagService } from "../../../core/services/feature-flag.service";
import { CoreService } from "../../../core/services/core.service";
import { environment } from "src/environments/environment";
import { TealiumUtagService } from '../../../tealium/utag.service';  

@Component({
  selector: "app-scs-pie-pictures",
  templateUrl: "./scs-pie-pictures.component.html",
  styleUrls: ["./scs-pie-pictures.component.scss"],
})
export class ScsPiePicturesComponent implements OnInit, AfterViewChecked {
  CUSTOMIZATION_TYPE = CUSTOMIZATION_TYPE;
  formGroup: FormGroup;
  options = [];
  dropdowns: Array<string>;

  //back link config
  backLinkType = Theme.Dark;
  backLinkIconOrientation = Orientation.Left;
  backLinkIconName = Icon.BackButton;

  //customizeLink link config
  customizeLinkIconName = LinkIcon.RightIcon;
  customizeLinkIconOrientation = Orientation.Right;
  customizeLinkType = LinkType.Dark;
  customizationType: string;
  isInitalLoadCompleted = false;
  imageSrcPath: string;
  series: string;
  isPrimerica = false;
  isNotPlusGuard: boolean;
  isIE;
  /***
   *  Constructor
   ***/
  constructor(
    private utilityService: UtilityService,
    private scsService: ScsService,
    private formBuilder: FormBuilder,
    private flagService: FeatureFlagService,
    private coreService: CoreService,
    private tealium: TealiumUtagService
  ) { }

  /***
   *  Page Load
   ***/
  ngOnInit() {
    this.isIE = false;
    //if any customization report, profile show buttons
    this.customizationType = this.scsService.customizationType;
    //create form
    this.buildForm();

    //get data for page/dropdowns
    this.getHistoricalPerformanceData();

    this.imageSrcPath = environment.piePicturePath;
    this.series = this.scsService.series;
      console.log(this.series);
      console.log("Check if flag service not Primerica:", this.flagService.featureFlags.isNotPrimerica)
      this.isPrimerica = this.flagService.featureFlags.isPrimerica;
      this.isNotPlusGuard = !this.flagService.featureFlags.isPlusGuard;

      // this.tealium.link({
      //   tealium_event: 'link',
      //   event_category: 'BodyClick',
      //   event_name: 'Retirement Guide Clicks',
      //   event_label: 'Click on History May Be a Guide'
      // })
  }

  ngOnChanges() {
    this.series = this.scsService.series;
  }

  //content after load
  ngAfterViewChecked() {
    if (
      this.formGroup.get("dropdown1").value &&
      this.formGroup.get("dropdown2").value &&
      this.formGroup.get("dropdown3").value
    )
      this.isInitalLoadCompleted = true;
  }

  /***
   *  Prepare form for historcal data
   ***/
  buildForm() {
    this.formGroup = this.formBuilder.group({
      dropdown1: new FormControl(""),
      dropdown2: new FormControl(""),
      dropdown3: new FormControl(""),
    });

    //save profie in session
    this.formGroup.valueChanges.subscribe((data) => {
      if (data.dropdown1 && data.dropdown2 && data.dropdown3)
        this.saveProfile();
    });
  }

  /***
   *  get historical performance data
   ***/
  getHistoricalPerformanceData() {
    this.utilityService.showLoader();
    this.scsService.getThreePieData(this.isIE).subscribe((response) => {
      this.utilityService.hideLoader();
      this.options = response.pieChartData;

      //set saved profile if required
      if (
        this.scsService.customizationType === CUSTOMIZATION_TYPE.PROFILE ||
        this.scsService.customizationType === CUSTOMIZATION_TYPE.REPORT
      ) {
        this.scsService.getProfile().subscribe((profiles: Profile[]) => {
          if (profiles && profiles.length > 0) {
            const profile = profiles.find(
              (x) =>
                x.name ===
                (this.scsService.customizationType ==
                  CUSTOMIZATION_TYPE.PROFILE
                  ? this.scsService.currentProfile
                  : CUSTOMIZATION_TYPE.REPORT) &&
                x.currentSerie === this.scsService.series
            );
            if (profile && profile.historyMayBeAGuidePlus) {
              this.loadOptions(
                profile.historyMayBeAGuidePlus.columnPlus1.id,
                profile.historyMayBeAGuidePlus.columnPlus2.id,
                profile.historyMayBeAGuidePlus.columnPlus3.id
              );
              this.formGroup
                .get("dropdown1")
                .setValue(profile.historyMayBeAGuidePlus.columnPlus1.id);
              this.formGroup
                .get("dropdown2")
                .setValue(profile.historyMayBeAGuidePlus.columnPlus2.id);
              this.formGroup
                .get("dropdown3")
                .setValue(profile.historyMayBeAGuidePlus.columnPlus3.id);
            }
            else if (!this.flagService.featureFlags.isIR) {
              this.loadOptions(
                this.options[0].id,
                this.options[1].id,
                this.options[2].id
              );
            }
            else {
              this.loadOptions(
                this.options[0].id,
                this.options[4].id,
                this.options[8].id
              );
            }
          }
          else if (!this.flagService.featureFlags.isIR) {
            this.loadOptions(
              this.options[0].id,
              this.options[1].id,
              this.options[2].id
            );
          }
          else {
            this.loadOptions(
              this.options[0].id,
              this.options[4].id,
              this.options[8].id
            );
          }
        });
      }
      else if (!this.flagService.featureFlags.isIR) {
        this.loadOptions(
          this.options[0].id,
          this.options[1].id,
          this.options[2].id
        );
      }
      else if (this.scsService.report.historyMayBeAGuidePlus) {
        this.loadOptions(
          this.scsService.report.historyMayBeAGuidePlus.columnPlus1.id,
          this.scsService.report.historyMayBeAGuidePlus.columnPlus2.id,
          this.scsService.report.historyMayBeAGuidePlus.columnPlus3.id
        );
      }
      else {
        this.loadOptions(
          this.options[0].id,
          this.options[4].id,
          this.options[8].id
        );
      }
    });
  }

  /***
   *  Load default options
   ***/
  loadOptions(option1, option2, option3) {
    this.formGroup.get("dropdown1").setValue(option1);
    this.formGroup.get("dropdown2").setValue(option2);
    this.formGroup.get("dropdown3").setValue(option3);
  }

  /***
   *  back Button
   ***/
  onBack() {
    this.utilityService.navigate(ROUTE_PATH.SCS.TOOLS.HOME);
  }

  /***
   *  Save data for customize report
   ***/
  saveProfile(mode?) {
    const historyMayBeAGuidePlus = new HistoryMayBeAGuidePlus();
    Object.keys(this.formGroup.controls).forEach((element, index) => {
      const obj = this.options.find(
        (x) => x.id == this.formGroup.get(element).value
      );
      historyMayBeAGuidePlus[
        "columnPlus" + (index + 1)
      ] = new HistoryMayBeAGuidePlusColumns();
      
      historyMayBeAGuidePlus["columnPlus" + (index + 1)].url = obj.chartImage;
      historyMayBeAGuidePlus["columnPlus" + (index + 1)].label = obj.value;
      historyMayBeAGuidePlus["columnPlus" + (index + 1)].id = obj.id;

    });

    if (
      this.isInitalLoadCompleted &&
      this.scsService.customizationType === CUSTOMIZATION_TYPE.INVESTOR_PROFILE
    ) {
      this.coreService.profile.next({
        name: "",
        type: CUSTOMIZATION_TYPE.REPORT,
        profile: new Profile(),
      });
      this.scsService.customizationType = CUSTOMIZATION_TYPE.REPORT;
      this.customizationType = CUSTOMIZATION_TYPE.REPORT;
    }

    //navigate to customization page
    if (this.customizationType === CUSTOMIZATION_TYPE.PROFILE) {
      this.scsService.storeProfile(
        CUSTOM_PROFILE.HISTORICAL_PERFORMANCE_PLUS,
        historyMayBeAGuidePlus
      );
    } else {
      //save for session
      this.scsService.report.historyMayBeAGuidePlus = historyMayBeAGuidePlus;
      this.scsService.storeProfile(
        CUSTOM_PROFILE.HISTORICAL_PERFORMANCE_PLUS,
        historyMayBeAGuidePlus,
        true
      );
    }

    
    //navigate
    if (mode && mode === CUSTOMIZATION_TYPE.PROFILE) {
      this.utilityService.presentWithoutTitleModal(ModalPopupComponent);
    } else if (mode && mode === CUSTOMIZATION_TYPE.REPORT) {
      this.utilityService.navigate(ROUTE_PATH.SCS.TOOLS.CREATE_REPORT);
    }
  }
}
