import { Component, OnInit } from "@angular/core";
import { ButtonType, Theme } from "equitable-component-library";
import { ROUTE_PATH } from "../../../constants/constants";
import { UtilityService } from "../../../core/services/utility.service";
import { FeatureFlagService } from "../../../core/services/feature-flag.service";
import {
  CONFIG_DATA,
} from "../../../constants/constants";
@Component({
  selector: "app-primerica-home",
  templateUrl: "./primerica-home.component.html",
  styleUrls: ["./primerica-home.component.scss"],
})
export class PrimericaHomeComponent implements OnInit {
  ButtonType = ButtonType;
  Theme = Theme;
  route = ROUTE_PATH;
  selectedProduct = "PLUS";
  isPlusGuard = CONFIG_DATA.ISPLUSGUARD;
  isNational = CONFIG_DATA.ISNATIONAL;

  constructor(
    private utilityService: UtilityService,
    private featureService: FeatureFlagService
  ) {
    if (this.featureService.featureFlags.isPrimerica)
      this.selectedProduct = "scs";
    else this.selectedProduct = "PLUS";
  }

  ngOnInit() {}

  visitPage(route) {
    this.utilityService.navigate(route);
  }
  visitEquitable(url) {
    window.open(url, '_blank')
  }
}
