import * as tslib_1 from "tslib";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FeatureFlagService = /** @class */ (function () {
    function FeatureFlagService(httpClient) {
        this.httpClient = httpClient;
    }
    FeatureFlagService.prototype.loadRemoteConfig = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var remoteConfig;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.httpClient
                            .get(environment.config)
                            .toPromise()];
                    case 1:
                        remoteConfig = _a.sent();
                        Object.assign(this, remoteConfig);
                        return [2 /*return*/];
                }
            });
        });
    };
    FeatureFlagService.prototype.featureOff = function (featureName) {
        // Read the value from the config service
        // eslint-disable-next-line no-prototype-builtins
        if (this.featureFlags.hasOwnProperty(featureName)) {
            return !this.featureFlags[featureName];
        }
        return true; // if feature not found, default to turned off
    };
    FeatureFlagService.prototype.featureOn = function (featureName) {
        return !this.featureOff(featureName);
    };
    FeatureFlagService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeatureFlagService_Factory() { return new FeatureFlagService(i0.ɵɵinject(i1.HttpClient)); }, token: FeatureFlagService, providedIn: "root" });
    return FeatureFlagService;
}());
export { FeatureFlagService };
