import { Component, OnInit } from "@angular/core";
import { RcService } from "../../services/rc.service";
import { take } from "rxjs/operators";
import { UtilityService } from "src/app/core/services/utility.service";

@Component({
  selector: "app-guaranteed-income",
  templateUrl: "./guaranteed-income.component.html",
  styleUrls: ["./guaranteed-income.component.scss"],
})
export class GuaranteedIncomeComponent implements OnInit {
  deferralRate: any;
  takingIncomeRate: any;
  date: string;
  incomeData: any;
  chartImage = "./assets/images/rc-guaranteed-income-for-life.png";

  constructor(
    private rcService: RcService,
    private utilityService: UtilityService
  ) {}

  ngOnInit() {
    this.utilityService.showLoader();
    this.rcService
      .fetchRcData()
      .pipe(take(1))
      .subscribe((chartData) => {
        this.utilityService.hideLoader();
        this.takingIncomeRate = chartData.takingIncomeRate;
        this.deferralRate = chartData.deferralRate;
        this.date = chartData.date;
      });
  }
}
