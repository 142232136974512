import { Component, OnInit } from "@angular/core";
import { ScsIncomeService } from "../../../scsincome/services/scsincome.service";
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SCSINCOME_SERIES } from 'src/app/constants/constants';
import { UtilityService } from "../../services/utility.service";
import { ROUTE_PATH } from "../../../constants/constants";

@Component({
  selector: "homepage-popup",
  templateUrl: "./homepage-popup.component.html",
  styleUrls: ["./homepage-popup.component.scss"],
})
export class HomePagePopupComponent implements OnInit {

  route = ROUTE_PATH;

  constructor(private scsincomeService: ScsIncomeService, 
    public modalController: ModalController,
    private translateService: TranslateService,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {

  }

  ionViewWillEnter() {
 
  }


  closeModal() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  visitPage(route) {
    this.utilityService.navigate(route);
  }
}
