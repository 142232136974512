import { Component, OnInit, AfterViewChecked } from "@angular/core";
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { UtilityService } from "../../../core/services/utility.service";
import { ScsService } from "../../services/scs.service";
import {
  CUSTOMIZATION_TYPE,
  CUSTOM_PROFILE,
  ROUTE_PATH,
} from "../../../constants/constants";
import {
  Dictionary,
  HistoryMayBeAGuide,
  HistoryMayBeAGuideColumn,
  HistoryMayBeAGuideColumnTable,
  Profile,
} from "../../../core/models/app.models";
import { Theme, LinkIcon, Orientation } from "equitable-component-library";
import { ModalPopupComponent } from "../../../core/components/modal-popup/modal-popup.component";
import { CoreService } from "../../../core/services/core.service";
import { FeatureFlagService } from "./../../../core/services/feature-flag.service";

@Component({
  selector: "app-history-guide",
  templateUrl: "./history-guide.component.html",
  styleUrls: ["./history-guide.component.scss"],
})
export class HistoryGuideComponent implements OnInit, AfterViewChecked {
  CUSTOMIZATION_TYPE = CUSTOMIZATION_TYPE;
  customProfile = "historicalPerformance.customProfile";
  customizeReport = "historicalPerformance.customizeReport";
  historicalPerformance: FormGroup;
  performanceParameters: Array<{}>;
  dropdowns: Array<string>;
  performanceHistory: any;
  options: Dictionary[] = [];
  seriesType: string;
  formKeys: Array<string>;
  customizationType: string;
  // for solution links
  type = Theme.Dark;
  orientation = Orientation.Right;
  linkRightIcon = LinkIcon.RightIcon;
  isInitalLoadCompleted = false;
  isNotPlusGuard: boolean;
  /***
   *  consturctor
   ***/
  constructor(
    private utilityService: UtilityService,
    private scsService: ScsService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private coreService: CoreService,
    private featureService: FeatureFlagService
  ) {}

  /***
   *  Page load
   ***/
  ngOnInit() {
    //get series type
    this.seriesType = this.scsService.series;

    //which type of customization
    this.customizationType = this.scsService.customizationType;

    //build form
    this.buildForm();

    //get data
    this.getDataForHistoryGuide();
    console.log('this.featureService.featureFlags.isPlusGuard', this.featureService.featureFlags.isPlusGuard)
    this.isNotPlusGuard = !this.featureService.featureFlags.isPlusGuard;
  }

  //content after load
  ngAfterViewChecked() {
    if (
      this.historicalPerformance.get("performanceDropdown1").value &&
      this.historicalPerformance.get("performanceDropdown2").value &&
      this.historicalPerformance.get("performanceDropdown3").value
    )
      this.isInitalLoadCompleted = true;
  }

  /***
   *  Load default option for form
   ***/
  loadOptions(option1, option2, option3) {
    this.historicalPerformance.get("performanceDropdown1").setValue(option1);
    this.historicalPerformance.get("performanceDropdown2").setValue(option2);
    this.historicalPerformance.get("performanceDropdown3").setValue(option3);
  }

  /***
   *  Fetch SCS History Guide data
   ***/
  getDataForHistoryGuide() {
    this.utilityService.showLoader();
    this.scsService
      .getHistoricalReturnValues(this.seriesType)
      .subscribe((response) => {
        this.utilityService.hideLoader();
        if (response.body && response.body.length > 0) {
          this.performanceHistory = response.body;
          this.performanceHistory.forEach((x) => {
            x.returns.forEach((y) => {
              y.duration = y.duration.replace("yr", "-Year");
            });
          });
          this.performanceHistory.forEach((element, index) => {
            const option: Dictionary = {
              id: element["id"],
              value: element["label"],
            };

            this.options.push(option);
          });

          //set saved profile if required
          if (
            this.scsService.customizationType == CUSTOMIZATION_TYPE.PROFILE ||
            this.scsService.customizationType == CUSTOMIZATION_TYPE.REPORT
          ) {
            this.scsService.getProfile().subscribe((profiles: Profile[]) => {
              if (profiles && profiles.length > 0) {
                const profile = profiles.find(
                  (x) =>
                    x.name ===
                      (this.scsService.customizationType ==
                      CUSTOMIZATION_TYPE.PROFILE
                        ? this.scsService.currentProfile
                        : CUSTOMIZATION_TYPE.REPORT) &&
                    x.currentSerie === this.scsService.series
                );
                if (profile && profile.historyMayBeAGuide) {
                  this.loadOptions(
                    profile.historyMayBeAGuide.column1.indexKey,
                    profile.historyMayBeAGuide.column2.indexKey,
                    profile.historyMayBeAGuide.column3.indexKey
                  );
                } else {
                  this.loadOptions(
                    this.options[0].id,
                    this.options[1].id,
                    this.options[2].id
                  );
                }
              } else {
                this.loadOptions(
                  this.options[0].id,
                  this.options[1].id,
                  this.options[2].id
                );
              }
            });
          }
          // else if (this.scsService.report.historyMayBeAGuide) {
          //   this.loadOptions(
          //     this.scsService.report.historyMayBeAGuide.column1.indexKey,
          //     this.scsService.report.historyMayBeAGuide.column2.indexKey,
          //     this.scsService.report.historyMayBeAGuide.column3.indexKey
          //   );
          // }
          else {
            this.loadOptions(
              this.options[0].id,
              this.options[1].id,
              this.options[2].id
            );
          }
        }
      });

    //get master data
    this.translate.get("historyGuide").subscribe((translated) => {
      this.performanceParameters = [
        {
          key: "duration",
          value: "",
        },
        {
          key: "averageReturn",
          value: translated.avgReturn,
        },
        {
          key: "totalGainsAndLosses",
          value: translated.totalGainsAndLosses,
        },
        {
          key: "numOfGains",
          value: translated.numberOfGains,
        },
        {
          key: "numOfLosses",
          value: translated.numberOfLosses,
        },
        {
          key: "0",
          value: translated.lossLessThan10,
        },
        {
          key: "1",
          value: translated.lossBetween10to15,
        },
        {
          key: "2",
          value: translated.lossBetween15to20,
        },
        {
          key: "3",
          value: translated.lossBetween20to25,
        },
        {
          key: "4",
          value: translated.lossBetween25to30,
        },
        {
          key: "5",
          value: translated.lossMoreThan30,
        },
        {
          key: "percentTimesAboveHighestBuffer",
          value: translated.returnsAboveHBP,
        },
        {
          key: "percentTimesAnyLosses",
          value: translated.timesLossOccurred,
        },
      ];
    });
  }

  /***
   *  Build Form Constrol
   ***/
  buildForm() {
    this.historicalPerformance = this.formBuilder.group({
      performanceDropdown1: new FormControl("", Validators.required),
      performanceDropdown2: new FormControl("", Validators.required),
      performanceDropdown3: new FormControl("", Validators.required),
    });

    this.historicalPerformance.valueChanges.subscribe((data) => {
      if (
        data.performanceDropdown1 &&
        data.performanceDropdown2 &&
        data.performanceDropdown3
      )
        this.saveProfile();
    });
    //get length for form
    this.formKeys = Object.keys(this.historicalPerformance.controls);
  }

  getReturnsForIndex(index) {
    if (this.historicalPerformance.get(index).value) {
      return this.performanceHistory.find(
        (x) => x.id === this.historicalPerformance.get(index).value
      ).returns;
    }
  }

  /***
   *  Save Series Data
   ***/
  saveProfile(mode?) {
    const historyMayBeAGuide = new HistoryMayBeAGuide();
    this.formKeys.forEach((control, index) => {
      const i = index + 1;
      historyMayBeAGuide["column" + [i]] = new HistoryMayBeAGuideColumn();
      historyMayBeAGuide[
        "column" + [i]
      ].indexKey = this.historicalPerformance.get(control).value;
      const indexData = this.performanceHistory.find(
        (x) => x.id == historyMayBeAGuide["column" + [i]].indexKey
      );
      historyMayBeAGuide["column" + [i]].totalColumns =
        indexData.returns.length;
      historyMayBeAGuide[
        "column" + [i]
      ].table = new HistoryMayBeAGuideColumnTable();
      indexData.returns.forEach((element) => {
        historyMayBeAGuide["column" + [i]].table.averageReturn.push(
          element.averageReturn
        );
        historyMayBeAGuide["column" + [i]].table.numOfGains.push(
          element.numOfGains
        );
        historyMayBeAGuide["column" + [i]].table.numOfLosses.push(
          element.numOfLosses
        );
        historyMayBeAGuide[
          "column" + [i]
        ].table.percentTimesAboveHighestBuffer.push(
          element.percentTimesAboveHighestBuffer
        );
        historyMayBeAGuide["column" + [i]].table.percentTimesAnyLosses.push(
          element.percentTimesAnyLosses
        );
        historyMayBeAGuide["column" + [i]].table.totalGainsAndLosses.push(
          element.totalGainsAndLosses
        );
        element.losses.forEach((loss) => {
          historyMayBeAGuide["column" + [i]].table[loss.typeOriginal].push(
            loss.value
          );
        });
      });
    });

    if (
      this.isInitalLoadCompleted &&
      this.customizationType === CUSTOMIZATION_TYPE.INVESTOR_PROFILE
    ) {
      this.coreService.profile.next({
        name: "",
        type: CUSTOMIZATION_TYPE.REPORT,
        profile: new Profile(),
      });
      this.scsService.customizationType = CUSTOMIZATION_TYPE.REPORT;
      this.customizationType = CUSTOMIZATION_TYPE.REPORT;
    }

    //navigate to customization page
    if (this.customizationType === CUSTOMIZATION_TYPE.PROFILE) {
      this.scsService.storeProfile(
        CUSTOM_PROFILE.HISTORY_GUIDE,
        historyMayBeAGuide
      );
    } else {
      //save for session
      this.scsService.report.historyMayBeAGuide = historyMayBeAGuide;
      this.scsService.storeProfile(
        CUSTOM_PROFILE.HISTORY_GUIDE,
        historyMayBeAGuide,
        true
      );
    }

    //navigate
    if (mode && mode === CUSTOMIZATION_TYPE.PROFILE) {
      this.utilityService.presentWithoutTitleModal(ModalPopupComponent);
    } else if (mode && mode === CUSTOMIZATION_TYPE.REPORT) {
      this.utilityService.navigate(ROUTE_PATH.SCS.TOOLS.CREATE_REPORT);
    }
  }
}
