import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { filter } from "rxjs/operators";
import { Footer, Profile } from "../../../core/models/app.models";
import {
  ROUTE_PATH,
  VIEW_NAME,
  INVESTOR_PROFILE_PAGES,
  CUSTOMIZATION_TYPE,
  PRODUCT_TYPE,
} from "../../../constants/constants";
import { CoreService } from "../../services/core.service";
import { UtilityService } from "../../services/utility.service";
import { Platform } from "@ionic/angular";
import { basename } from 'path';

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnChanges, OnInit {
  footer: Footer;
  @Input() isWhiteFooterVisible: string;
  @Input() isBlueFooterVisible: string;
  @Input() activeTab: string;
  @Input() selectedProduct: string;
  tool: string;
  product: string;
  profile;
  profileName = "";

  constructor(
    private router: Router,
    private coreService: CoreService,
    private util: UtilityService,
    private platform: Platform
  ) {}

  setFooterDetails(product, tool): void {
    this.coreService
      .getFooterDetails(product, tool)
      .subscribe((footer: Footer) => {
        this.footer = footer;
      });
  }

  //hide proflle
  hideProfile() {
    this.coreService.profile.next({
      name: "",
      type: CUSTOMIZATION_TYPE.REPORT,
      profile: new Profile(),
    });
  }

  goToProfile() {
    if (this.profile.type === CUSTOMIZATION_TYPE.PROFILE) {
      this.util.navigate(ROUTE_PATH.SCS.TOOLS.CUSTOM_PROFILE);
    } else if (this.profile.type === CUSTOMIZATION_TYPE.INVESTOR_PROFILE) {
      this.util.navigate(ROUTE_PATH.SCS.TOOLS.INVESTOR_PROFILE, {
        section: PRODUCT_TYPE.SCS,
      });
    }
  }

  ngOnInit() {
    this.platform.ready().then(() => {
      if (this.platform.is("ipad")) {
        this.setFooterDetails(this.product, this.tool);
      }
    });
  }

  ngOnChanges() {
    this.coreService.profile.subscribe((profile) => {
      this.profile = profile;
      this.profileName = profile.name;
    });

    let urlSegment = this.router.url;
    urlSegment = urlSegment.split("?")[0];
    urlSegment = urlSegment.split(";")[0];
    urlSegment = urlSegment.split("#")[0];
    urlSegment = urlSegment.substring(1, urlSegment.length);

    if (INVESTOR_PROFILE_PAGES.indexOf(urlSegment) !== -1) {
      this.profileName = this.profile.name;
    } else {
      this.profileName = "";
    }

    if (
      this.router.url.indexOf("investor-profile") !== -1 &&
      this.router.url.split("/")[1] !== PRODUCT_TYPE.SCS
    ) {
      this.product = this.router.url.split("/")[1];
      // this.tool = this.selectedProduct;
    } else {
      if (this.activeTab) {
        this.tool = this.activeTab;
      }

      if (this.selectedProduct) {
        this.product = this.selectedProduct;
      }

      // if plusguard, split the URL to set the product
      if (document.location.pathname.split('/')[1] === PRODUCT_TYPE.PLUSGUARD.toLowerCase()) {
        this.product = PRODUCT_TYPE.PLUSGUARD;
      }

      // if scsincome, split the URL to set the product
      else if (document.location.pathname.split('/')[1] === PRODUCT_TYPE.SCSINCOME.toLowerCase()) {
        this.product = PRODUCT_TYPE.SCSINCOME;
      }
    }
    this.setFooterDetails(this.product, this.tool);
  }
}
