import { PRESERVE_LEGACY_TOOLS } from "./../../../constants/constants";
import { UtilityService } from "../../../core/services/utility.service";
var PreserveLegacyComponent = /** @class */ (function () {
    function PreserveLegacyComponent(utilityService) {
        this.utilityService = utilityService;
        // class variables
        this.activeTab = PRESERVE_LEGACY_TOOLS.ROP;
        this.segments = [
            PRESERVE_LEGACY_TOOLS.ROP,
            PRESERVE_LEGACY_TOOLS.HA,
            PRESERVE_LEGACY_TOOLS.RMD,
        ];
        this.segmentChanged(this.activeTab);
    }
    PreserveLegacyComponent.prototype.backButton = function () {
        this.utilityService.navigateToBack();
    };
    PreserveLegacyComponent.prototype.segmentChanged = function (event) {
        if (event) {
            this.activeTab = event;
            switch (event) {
                case PRESERVE_LEGACY_TOOLS.ROP:
                    this.chartImage = [
                        "./assets/images/scs-chart-preserve-legacy-rop.jpg",
                    ];
                    break;
                case PRESERVE_LEGACY_TOOLS.HA:
                    this.chartImage = [
                        "./assets/images/scs-chart-preserve-legacy-ha.jpg",
                    ];
                    break;
                case PRESERVE_LEGACY_TOOLS.RMD:
                    this.chartImage = [
                        "./assets/images/scs-chart-preserve-legacy-rmd.jpg",
                    ];
                    break;
                default:
                    break;
            }
        }
    };
    return PreserveLegacyComponent;
}());
export { PreserveLegacyComponent };
