import * as tslib_1 from "tslib";
import { FeatureFlagService } from "./../../core/services/feature-flag.service";
import { map, switchMap, catchError } from "rxjs/operators";
import { HttpRequestService } from "../../core/services/http-request.service";
import { Chart, Bar, Line, Profile, Preset, } from "../../core/models/app.models";
import { API_METHODS, PRODUCT_TYPE, SEGMENT_TYPE, SCS_SERIES, CUSTOM_PROFILE, CUSTOMIZATION_TYPE, INVESTOR_MODE, MARKET_SCENERIO, } from "../../../app/constants/constants";
import { of, from, forkJoin, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { IonicStorageService } from "../../core/services/ionic-storage.service";
import { CoreService } from "../../core/services/core.service";
import { ModalController } from "@ionic/angular";
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { UtilityService } from "../../core/services/utility.service";
import * as i0 from "@angular/core";
import * as i1 from "../../core/services/http-request.service";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../core/services/ionic-storage.service";
import * as i4 from "../../core/services/core.service";
import * as i5 from "@ionic/angular";
import * as i6 from "../../core/services/feature-flag.service";
import * as i7 from "@angular/common/http";
import * as i8 from "../../core/services/utility.service";
var ScsService = /** @class */ (function () {
    /***
     *  Constructor for SCS Service
     ***/
    function ScsService(httpRequestService, translateService, storageService, coreService, modalController, flagService, http, utilityService) {
        var _this = this;
        this.httpRequestService = httpRequestService;
        this.translateService = translateService;
        this.storageService = storageService;
        this.coreService = coreService;
        this.modalController = modalController;
        this.flagService = flagService;
        this.http = http;
        this.utilityService = utilityService;
        this.customizationType = CUSTOMIZATION_TYPE.REPORT;
        this.currentProfile = ""; //for storing current profile in session
        this.investorData = {
            segment: "",
            index: "",
            protection: "",
            duration: "",
            mode: "",
            preset: new Preset(),
        };
        this.upMarketBars = {};
        this.downMarketBars = {};
        this.dynamicDollarAmount = 100000;
        this.reportData = new Profile();
        if (this.flagService &&
            this.flagService.featureFlags &&
            this.flagService.featureFlags.scs) {
            if (this.flagService.featureFlags.isIR) {
                this.series = localStorage.getItem(this.flagService.featureFlags.currentSeries)
                    ? localStorage.getItem(this.flagService.featureFlags.currentSeries)
                    : this.flagService.featureFlags.scs.series;
            }
            else
                this.series = this.flagService.featureFlags.scs.series;
        }
        this.coreService.profile.subscribe(function (profile) {
            _this.currentProfile = profile.name;
            _this.customizationType = profile.type
                ? profile.type
                : CUSTOMIZATION_TYPE.REPORT;
            if (_this.currentProfile === "") {
                _this.investorData.mode = INVESTOR_MODE.HOME;
                _this.getProfile().subscribe(function (profiles) {
                    if (profiles && Array.isArray(profiles) && profiles.length > 0) {
                        var profile_1 = profiles.find(function (x) {
                            return x.name === CUSTOMIZATION_TYPE.REPORT &&
                                x.currentSerie === _this.series;
                        });
                        if (profile_1) {
                            _this.report = profile_1;
                        }
                    }
                });
            }
            else {
                _this.investorData.mode = INVESTOR_MODE.PROFILE;
            }
        });
    }
    Object.defineProperty(ScsService.prototype, "series", {
        get: function () {
            return this._series;
        },
        set: function (series) {
            this.storageService.setValueByKey(this.flagService.featureFlags.currentSeries, series);
            localStorage.setItem(this.flagService.featureFlags.currentSeries, series);
            this._series = series;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScsService.prototype, "report", {
        get: function () {
            return this.reportData;
        },
        set: function (reportData) {
            this.reportData = reportData;
        },
        enumerable: true,
        configurable: true
    });
    ScsService.prototype.setDollarAmount = function (dollarInputValue) {
        this.dynamicDollarAmount = dollarInputValue;
    };
    ScsService.prototype.getDollarAmount = function () {
        return this.dynamicDollarAmount;
    };
    ScsService.prototype.getDynamicSCSData = function () {
        // https://jasonwatmore.com/post/2019/09/06/angular-http-get-request-examples
        var _this = this;
        // show loader until all products have rendered from API 
        this.utilityService.showLoader();
        this.http.get(environment.serviceAPIs.dynamicAPIUrl, {
            headers: new HttpHeaders({
                'Ocp-Apim-Subscription-Key': environment.serviceAPIs.dynamicAPISubscriptionKey
            })
        })
            .subscribe(function (response) {
            _this.utilityService.hideLoader();
            // local json for testing: "assets/apis/scs/ratesforirapp.json"
            console.log('Constructor of SCS Service - Calling APIM');
            _this.dynamicData = response;
            console.log(_this.dynamicData);
        });
    };
    ScsService.prototype.getDynamicIEData = function () {
        // https://jasonwatmore.com/post/2019/09/06/angular-http-get-request-examples
        var _this = this;
        // show loader until all products have rendered from API 
        this.utilityService.showLoader();
        this.http.get(environment.serviceAPIs.dynamicAPIUrl, {
            headers: new HttpHeaders({
                'Ocp-Apim-Subscription-Key': environment.serviceAPIs.dynamicAPISubscriptionKey
            })
        })
            .subscribe(function (response) {
            _this.utilityService.hideLoader();
            // local json for testing: "assets/apis/scs/ratesforirapp.json"
            console.log('Constructor of SCS/IE Service - Calling APIM');
            _this.dynamicData = response;
            console.log(_this.dynamicData);
        });
    };
    /***
     *  Check the scs data from local variable
     ***/
    ScsService.prototype.getSCSData = function () {
        if (this.scs) {
            return of(this.scs);
        }
        else {
            return this.fetchSCSData(); // fetchSCSDataWithGenesAPI() or fetchSCSData()
        }
    };
    ScsService.prototype.getIEData = function () {
        if (this.ie) {
            return of(this.ie);
        }
        else {
            return this.fetchIEData(); // fetchSCSDataWithGenesAPI() or fetchSCSData()
        }
    };
    /***
     *  get master data to fill drop down on how it works
     ***/
    ScsService.prototype.getAllIndices = function () {
        var reqOptions = {
            method: API_METHODS.GET,
            url: environment.scsIndices,
        };
        return this.httpRequestService.request(reqOptions).pipe(map(function (data) {
            return data.body.indicesData;
        }));
    };
    /***
     *  Fetch SCS main data from server
     ***/
    ScsService.prototype.fetchSCSData = function () {
        var _this = this;
        var baseURL = environment.serviceAPIs.baseUrl;
        if (/https/.test(environment.serviceAPIs.scs)) {
            var baseURL = "";
        }
        var requestOption = {
            url: baseURL + environment.serviceAPIs.scs,
            method: API_METHODS.GET,
        };
        return this.httpRequestService.request(requestOption).pipe(map(function (response) {
            _this.scs = response.body;
            console.log(_this.scs);
            return _this.scs;
        }));
    };
    /***
    *  Fetch IE main data from server
    ***/
    ScsService.prototype.fetchIEData = function () {
        var _this = this;
        var baseURL = environment.serviceAPIs.baseUrl;
        if (/https/.test(environment.serviceAPIs.ie)) {
            var baseURL = "";
        }
        var requestOption = {
            url: baseURL + environment.serviceAPIs.ie,
            method: API_METHODS.GET,
        };
        return this.httpRequestService.request(requestOption).pipe(map(function (response) {
            _this.ie = response.body;
            console.log(_this.ie);
            return _this.ie;
        }));
    };
    /***
   *  get resource for an ie series
   ***/
    ScsService.prototype.getIEResources = function (seriesName) {
        return this.getIEData().pipe(switchMap(function (ie) {
            var series = ie.series.find(function (x) { return x.name == seriesName; });
            return of(series.kits);
        }));
    };
    /***
     *  get resource for a series
     ***/
    ScsService.prototype.getResources = function (seriesName) {
        return this.getSCSData().pipe(switchMap(function (scs) {
            var series = scs.series.find(function (x) { return x.name == seriesName; });
            return of(series.kits);
        }));
    };
    /***
     *  store custom profile
     ***/
    ScsService.prototype.storeProfile = function (contentType, data, isReport) {
        var _this = this;
        if (isReport === void 0) { isReport = false; }
        this.getProfile().subscribe(function (storedProfiles) {
            var profile = new Profile();
            var profiles = [];
            if (storedProfiles &&
                Array.isArray(storedProfiles) &&
                storedProfiles.length > 0) {
                profiles = storedProfiles;
                profile =
                    profiles.find(function (x) {
                        return x.name ===
                            (isReport ? CUSTOMIZATION_TYPE.REPORT : _this.currentProfile) &&
                            x.currentSerie == _this.series;
                    }) || profile;
            }
            if (!profile.name) {
                profile.name = isReport
                    ? CUSTOMIZATION_TYPE.REPORT
                    : _this.currentProfile;
                profile.currentSerie = _this.series;
                profiles.push(profile);
            }
            profile.currentSerie = _this.series;
            if (contentType === CUSTOM_PROFILE.HOW_IT_WORKS) {
                profile.aboutSCSHowSioWorks = data;
            }
            else if (contentType === CUSTOM_PROFILE.HISTORICAL_PERFORMANCE) {
                profile.historicalPerformance = data;
            }
            else if (contentType === CUSTOM_PROFILE.HISTORY_GUIDE) {
                profile.historyMayBeAGuide = data;
            }
            else if (contentType === CUSTOM_PROFILE.HISTORICAL_PERFORMANCE_PLUS) {
                profile.historyMayBeAGuidePlus = data;
            }
            _this.saveProfile(profiles);
        });
    };
    /***
     *  get resource for a series
     ***/
    ScsService.prototype.getProfile = function () {
        return from(this.storageService.getValueByKey(this.flagService.featureFlags.profile));
    };
    /***
     *  get resource for a series
     ***/
    ScsService.prototype.saveProfile = function (profiles) {
        return from(this.storageService.setValueByKey(this.flagService.featureFlags.profile, profiles));
    };
    /***
     *  get delete profile
     ***/
    ScsService.prototype.deleteProfile = function (profileName) {
        var _this = this;
        return this.getProfile().pipe(switchMap(function (profiles) {
            var index = profiles.findIndex(function (x) { return x.name === profileName && x.currentSerie === _this.series; });
            profiles.splice(index, 1);
            return _this.saveProfile(profiles);
        }));
    };
    //=============================Start how it works Here =============================== //
    /***
     *  get master data to fill drop down on how it works
     ***/
    ScsService.prototype.getHowItsWorksMasterData = function () {
        var reqOptions = {
            method: API_METHODS.GET,
            url: environment.howItsWorks,
        };
        return this.httpRequestService.request(reqOptions);
    };
    ScsService.prototype.getHowItsWorksIEMasterData = function () {
        var reqOptions = {
            method: API_METHODS.GET,
            url: environment.howItsWorksIE,
        };
        return this.httpRequestService.request(reqOptions);
    };
    ScsService.prototype.getHowitWorksMenuData = function () {
        return forkJoin([this.getHowItsWorksMasterData(), this.getProfile()]);
    };
    ScsService.prototype.getHowitWorksIEMenuData = function () {
        return forkJoin([this.getHowItsWorksIEMasterData(), this.getProfile()]);
    };
    ScsService.prototype.getHowitWorksData = function (seriesName) {
        return forkJoin([
            this.getHowItsWorksMasterData(),
            this.fetchHowitWorks(seriesName),
            this.getProfile(),
        ]);
    };
    ScsService.prototype.getHowitWorksIEData = function (seriesName) {
        return forkJoin([
            this.getHowItsWorksIEMasterData(),
            this.fetchHowitWorks(seriesName),
            this.getProfile(),
        ]);
    };
    // TODO: We should probably still update this function for calling the dynamic data. 
    /***
     *  fetch how it work from server/storage
     ***/
    ScsService.prototype.fetchHowitWorks = function (seriesName) {
        var _this = this;
        return this.getSCSData().pipe(switchMap(function (scs) {
            var series = scs.series.find(function (x) { return x.name == seriesName; });
            return _this.httpRequestService
                .request({
                url: series.performance.howItWorks,
                method: API_METHODS.GET,
            })
                .pipe(map(function (data) {
                return data.body;
            }));
        }));
    };
    /***
     *  parse response data for how it works tab
     ***/
    ScsService.prototype.parsePerformanceData = function (performance, segment, commission) {
        var _this = this;
        var segmentRates = [];
        var chartDetails = [];
        var objChartData = new Chart();
        objChartData.bar = new Array();
        if (segment != SEGMENT_TYPE.ANNUAL_LOCK &&
            segment != SEGMENT_TYPE.STEP_UP &&
            segment != SEGMENT_TYPE.ENHANCED_UPSIDE &&
            segment != SEGMENT_TYPE.DUAL_DIRECTION &&
            segment != SEGMENT_TYPE.DUAL_STEP_UP &&
            segment != SEGMENT_TYPE.LOSS_LIMITER_90 &&
            segment != SEGMENT_TYPE.LOSS_LIMITER_95) {
            //sort all scenerio positive first then negative
            performance.performaceRate = this.sortRates(performance.performaceRate);
        }
        performance.performaceRate.forEach(function (element, index) {
            var objBar = new Bar();
            objBar.id = index;
            var choiceLabel = "";
            if (segment == SEGMENT_TYPE.STEP_UP) {
                choiceLabel =
                    element > 0
                        ? _this.translateService.instant("howItsWorks.upMarket")
                        : element == 0
                            ? _this.translateService.instant("howItsWorks.flatMarket")
                            : _this.translateService.instant("howItsWorks.downMarket");
            }
            else if (segment == SEGMENT_TYPE.ENHANCED_UPSIDE) {
                if (element > 0) {
                    choiceLabel = _this.translateService.instant("howItsWorks.upMarket");
                }
                else {
                    choiceLabel = _this.translateService.instant("howItsWorks.downMarket");
                }
            }
            if (segment == SEGMENT_TYPE.STEP_UP) {
                objBar.label = choiceLabel;
            }
            else if (segment == SEGMENT_TYPE.ENHANCED_UPSIDE) {
                objBar.label = choiceLabel;
            }
            else if (segment == SEGMENT_TYPE.ANNUAL_LOCK) {
                objBar.label = _this.translateService.instant("howItsWorks.year") + " " + (index + 1); // add year labels to annual lock
            }
            else if (segment == SEGMENT_TYPE.STANDARD) {
                if (element > 0) {
                    objBar.label = _this.translateService.instant("howItsWorks.upMarket");
                }
                else {
                    objBar.label = _this.translateService.instant("howItsWorks.downMarket");
                }
            }
            else if (segment == SEGMENT_TYPE.DUAL_STEP_UP) {
                // this code is taken from what we have from STEP_UP and converted to if/else statements
                if (element > 0) {
                    choiceLabel = _this.translateService.instant("howItsWorks.upMarket");
                }
                else if (element == 0) {
                    choiceLabel = _this.translateService.instant("howItsWorks.flatMarket");
                }
                else {
                    choiceLabel = _this.translateService.instant("howItsWorks.downMarket");
                }
                objBar.label = choiceLabel;
            }
            else if (segment == SEGMENT_TYPE.LOSS_LIMITER_90) {
                if (element > 0) {
                    objBar.label = _this.translateService.instant("howItsWorks.upMarket");
                }
                else {
                    objBar.label = _this.translateService.instant("howItsWorks.downMarket");
                }
            }
            else if (segment == SEGMENT_TYPE.LOSS_LIMITER_95) {
                if (element > 0) {
                    objBar.label = _this.translateService.instant("howItsWorks.upMarket");
                }
                else {
                    objBar.label = _this.translateService.instant("howItsWorks.downMarket");
                }
            }
            else {
                objBar.label = _this.translateService.instant("howItsWorks.scenerio") + " " + (index + 1);
            }
            // Old way of determining the labels above the bar chart
            // objBar.label =
            //   segment == SEGMENT_TYPE.STEP_UP
            //     ? choiceLabel
            //     : (segment == SEGMENT_TYPE.ANNUAL_LOCK
            //         ? this.translateService.instant("howItsWorks.year")
            //         : this.translateService.instant("howItsWorks.scenerio")) +
            //       " " +
            //       (index + 1);
            //for peformace cap rate & Buffer lines for each section
            objBar.lines = new Array();
            objBar.lines.push({
                label: _this.translateService.instant("howItsWorks.performaceCapRate"),
                value: parseFloat(performance.performaceCapRate),
            });
            objBar.lines.push({
                label: _this.translateService.instant("howItsWorks.segmentBuffer"),
                value: parseFloat(performance.buffer),
            });
            //push segment(orange)
            //for Index Segment Yearly Rate of Return
            // // TODO: We should create two functions for getting an sSoR, one for dynamic and one from the static .json files
            var segmentRate;
            // console.log(performance.segmentRates)
            if (performance.segmentRates) {
                segmentRate = _this.sRoR(element, performance.performaceCapRate, performance.buffer, segment, commission, performance.id, performance.additionalCharges, index, performance.segmentRates[index], performance);
            }
            else {
                segmentRate = _this.sRoR(element, performance.performaceCapRate, performance.buffer, segment, commission, performance.id, performance.additionalCharges, index, 0, performance);
            }
            objBar.data = new Array();
            //description
            segmentRates.push(segmentRate.return);
            objBar.description = segmentRate.detail;
            // Note that the Index Performance Rate and Segment Rate of Return are swapped on the chart. 
            objBar.data.push({
                value: element,
                label: _this.isIE ? _this.translateService.instant("howItsWorks.indexPerformanceRateIE") : _this.translateService.instant("howItsWorks.indexPerformanceRate"),
                topValue: segmentRate.return > 0 && segment == SEGMENT_TYPE.CHOICE
                    ? commission
                    : 0,
            });
            //for Index Performance Rate
            objBar.data.push({
                value: segmentRate.return,
                label: _this.isIE ? _this.translateService.instant("howItsWorks.segmentYearlyReturnIE") : _this.translateService.instant("howItsWorks.segmentYearlyReturn"),
            });
            objChartData.bar.push(objBar);
            //adding segment details
            chartDetails.push(segmentRate.detail);
        });
        //adding various axis to the chart
        // console.log('xxx3')
        objChartData.axis = new Array();
        objChartData.axis.push({
            min: performance.min,
            max: performance.max,
            interval: performance.interval,
            type: "Y",
        });
        //for annual segment
        if (segment == SEGMENT_TYPE.ANNUAL_LOCK) {
            //do some thing for line chart
            objChartData.line = new Line();
            objChartData.line.legends = this.translateService.instant("howItsWorks.annualLockEndingAmount");
            objChartData.line.data = this.calculateAnnualSegment(segmentRates); // UPDATE TO PASS performance INSTEAD OF segmentRates
        }
        return { data: objChartData, details: chartDetails };
    };
    ScsService.prototype.calculateAnnualSegment = function (segmentRates) {
        var _this = this;
        var value = this.getDollarAmount();
        var annualArray = new Array();
        segmentRates.forEach(function (element, index) {
            value = Math.round(value + value * (element / 100));
            annualArray.push({
                id: index,
                value: value,
                label: _this.translateService.instant("howItsWorks.year") + " " + (index + 1),
            });
        });
        return annualArray;
    };
    /***
     *  Get a Segment Rate-of-Return
     **/
    ScsService.prototype.sRoR = function (indexPerformance, cap, buffer, segment, commission, year, additionalCharges, index, segmentRate, performance) {
        buffer = buffer ? parseFloat(buffer) : 0;
        additionalCharges =
            additionalCharges && segment !== SEGMENT_TYPE.ANNUAL_LOCK
                ? additionalCharges
                : 0;
        var segmentDetails = "";
        var rateOfReturn = indexPerformance;
        // hardcoded fixes for plus guard
        if (this.flagService.featureFlags.isPlusGuard) {
            if (indexPerformance == 0) {
                rateOfReturn = 6.8;
            }
            if (indexPerformance == 4) {
                rateOfReturn = 6.8;
            }
            if (indexPerformance == 11) {
                rateOfReturn = 6.8;
            }
        }
        // cap on positive indexPerformance
        if (indexPerformance > 0 && rateOfReturn <= cap) {
            segmentDetails = this.translateService
                .instant("howItsWorks.segmentReturn.belowPerformanceRate")
                .replace("$$INDEX_RETURN$$", indexPerformance)
                .replace("$$SEGMENT_RETURN$$", rateOfReturn);
        }
        if (this.series == SCS_SERIES.PLUS) {
            if (indexPerformance > 0 && (indexPerformance == 105 || indexPerformance == 150)) {
                segmentDetails = this.translateService
                    .instant("howItsWorks.segmentReturn.uncappedPerformanceRate")
                    .replace("$$INDEX_RETURN$$", indexPerformance)
                    .replace("$$SEGMENT_RETURN$$", rateOfReturn);
            }
        }
        if (this.series == SCS_SERIES.PLUS21) {
            if (indexPerformance > 0 && (indexPerformance == 105 || indexPerformance == 150)) {
                segmentDetails = this.translateService
                    .instant("howItsWorks.segmentReturn.uncappedPerformanceRate")
                    .replace("$$INDEX_RETURN$$", indexPerformance)
                    .replace("$$SEGMENT_RETURN$$", rateOfReturn);
            }
        }
        // cap on positive indexPerformance
        if (indexPerformance > 0 && rateOfReturn > cap) {
            rateOfReturn = cap - additionalCharges;
            segmentDetails = this.translateService
                .instant("howItsWorks.segmentReturn.abovePerformanceRate")
                .replace("$$INDEX_RETURN$$", indexPerformance)
                .replace("$$SEGMENT_RETURN$$", rateOfReturn);
        }
        if (indexPerformance > 0 && segment == SEGMENT_TYPE.CHOICE)
            rateOfReturn = rateOfReturn - commission - additionalCharges;
        if (indexPerformance >= 0 && (segment == SEGMENT_TYPE.STEP_UP || segment == SEGMENT_TYPE.DUAL_STEP_UP || segment == SEGMENT_TYPE.LOSS_LIMITER_90 || segment == SEGMENT_TYPE.LOSS_LIMITER_95)) {
            var isDynamic = void 0;
            if ((this.series == SCS_SERIES.PLUS || this.series == SCS_SERIES.PLUS21) && (!this.flagService.featureFlags.isPrimerica && !this.flagService.featureFlags.isPlusGuard)) {
                isDynamic = true;
            }
            else {
                isDynamic = false;
            }
            segmentDetails = this.translateService
                .instant("howItsWorks.segmentReturn.abovePerformanceRate")
                .replace("$$INDEX_RETURN$$", indexPerformance)
                .replace("$$SEGMENT_RETURN$$", isDynamic ? segmentRate : rateOfReturn);
            if (indexPerformance == 0) {
                segmentDetails = this.translateService
                    .instant("howItsWorks.segmentReturn.flatPerformance")
                    .replace("$$INDEX_RETURN$$", indexPerformance)
                    .replace("$$SEGMENT_RETURN$$", isDynamic ? segmentRate : rateOfReturn);
            }
        }
        // buffer on negative indexPerformance
        if (indexPerformance < 0) {
            rateOfReturn = rateOfReturn - buffer < 0 ? rateOfReturn - buffer : 0;
            rateOfReturn = rateOfReturn - additionalCharges; //fix for additional
            segmentDetails = this.translateService
                .instant("howItsWorks.segmentReturn.lossMoreThanBuffer")
                .replace("$$INDEX_RETURN$$", indexPerformance.toString().replace("-", ""))
                .replace("$$SEGMENT_RETURN$$", rateOfReturn)
                .replace("$$SEGMENT_BUFFER$$", buffer);
            if (indexPerformance < buffer) {
                segmentDetails = this.translateService
                    .instant("howItsWorks.segmentReturn.lossLessThanBuffer")
                    .replace("$$INDEX_RETURN$$", indexPerformance.toString().replace("-", ""))
                    .replace("$$SEGMENT_RETURN$$", rateOfReturn)
                    .replace("$$SEGMENT_BUFFER$$", buffer);
            }
        }
        // uncapped
        if (cap == segmentRate && cap == indexPerformance) {
            console.log('UNCAPPED');
        }
        // IF enhanced upside, display updated content underneath the graphs
        // && this.series != "PLUS21"
        if (segment == SEGMENT_TYPE.ENHANCED_UPSIDE) {
            if (index == 0) {
                segmentDetails = this.translateService
                    .instant("howItsWorks.segmentReturnEnhancedUpside.index0")
                    .replace("$$INDEX_RETURN$$", indexPerformance)
                    .replace("$$ENHANCED_UPSIDE_RATE$$", performance.enhancedUpsideRate)
                    .replace("$$SEGMENT_RETURN$$", segmentRate);
            }
            else if (index == 1) {
                segmentDetails = this.translateService
                    .instant("howItsWorks.segmentReturnEnhancedUpside.index1")
                    .replace("$$INDEX_RETURN$$", indexPerformance)
                    .replace("$$ENHANCED_UPSIDE_RATE$$", performance.enhancedUpsideRate)
                    .replace("$$SEGMENT_RETURN$$", segmentRate);
            }
            else if (index == 2) {
                segmentDetails = this.translateService
                    .instant("howItsWorks.segmentReturnEnhancedUpside.index2")
                    .replace("$$INDEX_RETURN$$", indexPerformance)
                    .replace("$$ENHANCED_UPSIDE_RATE$$", performance.enhancedUpsideRate)
                    .replace("$$SEGMENT_RETURN$$", segmentRate);
            }
            else if (index == 3) {
                segmentDetails = this.translateService
                    .instant("howItsWorks.segmentReturnEnhancedUpside.index3")
                    .replace("$$INDEX_RETURN$$", indexPerformance)
                    .replace("$$ENHANCED_UPSIDE_RATE$$", performance.enhancedUpsideRate)
                    .replace("$$SEGMENT_RETURN$$", segmentRate)
                    .replace("$$SEGMENT_BUFFER$$", buffer.toString().replace("-", ""));
            }
            else if (index == 4) {
                // index6Updated
                segmentDetails = this.translateService
                    .instant("howItsWorks.segmentReturnEnhancedUpside.index4")
                    .replace("$$INDEX_RETURN$$", indexPerformance)
                    .replace("$$ENHANCED_UPSIDE_RATE$$", performance.enhancedUpsideRate)
                    .replace("$$SEGMENT_RETURN$$", segmentRate)
                    .replace("$$SEGMENT_BUFFER$$", buffer.toString().replace("-", ""));
            }
        }
        if (segment == SEGMENT_TYPE.DUAL_STEP_UP) {
            // not sure if we need to use segmentRate or rateOfReturn here. We can test to confirm. If we need to use rateOfReturn, we may need to move this code below where the rateOfReturn is calculated. 
            // at some point, we should put this code into the JSON like this. But we are strapped for time so we can do it like the above. 
            // segmentDetails = this.translateService
            // .instant("howItsWorks.segmentReturnEnhancedUpside.index0")
            // .replace("$$INDEX_RETURN$$", indexPerformance)
            // .replace("$$SEGMENT_RETURN$$", segmentRate);
            if (index == 0) {
                segmentDetails = "The index returns " + indexPerformance + "%. The Segment Buffer absorbs the \uFB01rst " + buffer + "% of the loss, leaving you with only a " + segmentRate + "% loss.";
            }
            else if (index == 1) {
                segmentDetails = "The index returns " + indexPerformance + "%. Because the return is within the Segment Buffer, the Segment's rate of return is " + segmentRate + "%, which is the Performance Cap Rate.";
            }
            else if (index == 2) {
                segmentDetails = "The index returns " + indexPerformance + "%. Because the return is equal to " + indexPerformance + "%, the Segment's rate of return is " + segmentRate + "%, which is the Performance Cap Rate.";
            }
            else if (index == 3) {
                segmentDetails = "The index returns " + indexPerformance + "%. Because the return is positive, the Segment's rate of return is " + segmentRate + "%, which is the Performance Cap Rate.";
            }
            else if (index == 4) {
                segmentDetails = "The index returns " + indexPerformance + "%. Because the gain is in excess of the Performance Cap Rate, the Segment's rate of return is " + segmentRate + "%, which is the Performance Cap Rate.";
            }
        }
        else if (segment == SEGMENT_TYPE.LOSS_LIMITER_90 || segment == SEGMENT_TYPE.LOSS_LIMITER_95) {
            var bufferCacluation = void 0;
            if (segment == SEGMENT_TYPE.LOSS_LIMITER_90) {
                bufferCacluation = (buffer - 10).toString();
            }
            if (segment == SEGMENT_TYPE.LOSS_LIMITER_95) {
                bufferCacluation = (buffer - 5).toString();
            }
            if (index == 0) {
                segmentDetails = "The index returns " + indexPerformance + "%. The Segment Buffer absorbs the first " + buffer + "% of loss, and the Segment Investment Protection feature absorbs loss after " + bufferCacluation + "%, leaving you with a " + segmentRate + "% return.";
            }
            else if (index == 1) {
                segmentDetails = "The index returns " + indexPerformance + "%. The Segment Buffer absorbs the first " + buffer + "% of loss and you realize a " + segmentRate + "% return.";
            }
            else if (index == 2) {
                segmentDetails = "The index returns " + indexPerformance + "%. Because the return is within the Segment Buffer, you realize a " + segmentRate + "% return.";
            }
            else if (index == 3) {
                segmentDetails = "The index returns " + indexPerformance + "%. Because the return is below the Performance Cap Rate, you realize the full " + segmentRate + "% return.";
            }
            else if (index == 4) {
                segmentDetails = "The index returns " + indexPerformance + "%. Because the gain is in excess of the Performance Cap Rate, the Segment's rate of return is " + segmentRate + "%, which is the Performance Cap Rate.";
            }
        }
        // IF enhanced upside, display updated content underneath the graphs
        if (this.isIE) {
            if (segment == SEGMENT_TYPE.STANDARD) {
                if (index == 0) {
                    segmentDetails = "The index return is positive, but less than the Performance Cap​ Rate. Because the return is below the Performance Cap Rate, the​ segment return before contract fee is equal to the index return.​";
                }
                else if (index == 1) {
                    segmentDetails = "The index return is positive.​ Because the gain is in excess of the Performance Cap Rate, the segment​ return before contract fee is equal to the Performance Cap Rate.​";
                }
                else if (index == 2) {
                    segmentDetails = "The index return is negative.​ Since the return is within the Segment Buffer,​ the segment return before contract fee is zero.​";
                }
                else if (index == 3) {
                    segmentDetails = "The index return is negative.​ Because the negative return exceeds the Segment​ Buffer, segment return before contract fee is negative.​";
                }
            }
            else if (segment == SEGMENT_TYPE.STEP_UP) {
                if (index == 0) {
                    segmentDetails = "​The index return is negative.​ Because the return exceeds the Segment​ Buffer, the segment return before contract​ fee is negative.​";
                }
                else if (index == 1) {
                    segmentDetails = "​The index return is negative.​ Since the return is within the Segment Buffer,​ the segment return before contract fee is zero.​";
                }
                else if (index == 2) {
                    segmentDetails = "The index returns 0%.​ Because the return is equal to 0%, the​ segment return before contract fee is​ equal to the performance cap rate.​";
                }
                else if (index == 3) {
                    segmentDetails = "The index return is positive, but less than the Performance​ Cap Rate. Because the return is positive, the segment return​ before contract fee is equal to the Performance Cap Rate.​";
                }
                else if (index == 4) {
                    segmentDetails = "The index return is positive.​ Because the return is greater than the Performance Cap Rate, the​ segment return before contract fee is equal to the Performance Cap Rate.​";
                }
            }
            else if (segment == SEGMENT_TYPE.DUAL_DIRECTION) {
                if (buffer == '-10') {
                    if (index == 2) {
                        rateOfReturn = 7;
                        segmentDetails = "The index loses 7%. Because the index return is within the Segment Buffer, the Segment's rate of return is 7%.";
                    }
                }
                else if (buffer == '-15') {
                    if (index == 2) {
                        rateOfReturn = 12;
                        segmentDetails = "The index loses 12%. Because the index return is within the Segment Buffer, the Segment's rate of return is 12%.";
                    }
                }
                /* else if(index == 1) {
                  segmentDetails = "​The index return is negative.​ Since the return is within the Segment Buffer,​ the segment return before contract fee is zero.​";
                }
                else if(index == 2) {
                  segmentDetails = "The index returns 0%.​ Because the return is equal to 0%, the​ segment return before contract fee is​ equal to the performance cap rate.​";
                }
                else if(index == 3) {
                  segmentDetails = "The index return is positive, but less than the Performance​ Cap Rate. Because the return is positive, the segment return​ before contract fee is equal to the Performance Cap Rate.​";
                } */
            }
            // hardcoded fixes for IE
            if (indexPerformance == 4) {
                rateOfReturn = 4;
            }
            if (indexPerformance == 0) {
                rateOfReturn = 8;
            }
        }
        if ((this.series == SCS_SERIES.PLUS || this.series == SCS_SERIES.PLUS21) && (!this.flagService.featureFlags.isPrimerica && !this.flagService.featureFlags.isPlusGuard) && !this.isIE) {
            if (segment == SEGMENT_TYPE.STEP_UP ||
                segment == SEGMENT_TYPE.ENHANCED_UPSIDE ||
                segment == SEGMENT_TYPE.ANNUAL_LOCK ||
                segment == SEGMENT_TYPE.STANDARD ||
                segment == SEGMENT_TYPE.DUAL_STEP_UP ||
                segment == SEGMENT_TYPE.LOSS_LIMITER_90 ||
                segment == SEGMENT_TYPE.LOSS_LIMITER_95) {
                rateOfReturn = segmentRate;
            }
        }
        //show segment maturity value, if not IE
        if (!this.isIE) {
            segmentDetails = this.populateSegmentMaturityDescriptionUnderGraph(segmentDetails, rateOfReturn);
        }
        // round the rate of return to 2 decimal places
        if (this.series == SCS_SERIES.PLUS || SCS_SERIES.PLUS21) {
            if (this.isIE) {
                rateOfReturn = Math.round(rateOfReturn * 100) / 100; // TODO: How can we append a symbol of * here and still get it to interpret as a number?
            }
            else if (this.flagService.featureFlags.isPrimerica || this.flagService.featureFlags.isPlusGuard) {
                rateOfReturn = Math.round(rateOfReturn * 10) / 10; // round to tenths for plus guard and primerica
            }
            else {
                rateOfReturn = parseFloat(rateOfReturn).toFixed(2);
            }
        }
        else {
            rateOfReturn = Math.round(rateOfReturn * 100) / 100;
        }
        return { return: rateOfReturn, detail: segmentDetails };
    };
    ScsService.prototype.populateSegmentMaturityDescriptionUnderGraph = function (segmentDetails, rateOfReturn) {
        var descriptionResult;
        // if we are on the national version, display the description with the dollar amount on a new line
        if (this.flagService.featureFlags.isNational) {
            descriptionResult = segmentDetails + this.translateService.instant("howItsWorks.segmentReturn.segmentMaturityValue") + '<p class="seg-value">$' +
                Math.round(this.getDollarAmount() + this.getDollarAmount() * (rateOfReturn / 100)).toLocaleString() + '</p>';
        }
        else if (this.flagService.featureFlags.isPrimerica || this.flagService.featureFlags.isPlusGuard) {
            descriptionResult = segmentDetails + " Segment maturity value: $" + Math.round(this.getDollarAmount() + this.getDollarAmount() * (rateOfReturn / 100)).toLocaleString();
        }
        return descriptionResult;
    };
    //sorting positive to first and negative to last
    ScsService.prototype.sortRates = function (rates) {
        console.log(rates);
        var positive = [], negative = [];
        rates.forEach(function (element) {
            if (element > 0) {
                positive.push(element);
            }
            else {
                negative.push(element);
            }
        });
        return positive.concat(negative);
    };
    //======================================= End Here ===================================== //
    //============================== Start historical performance =============================== //
    ScsService.prototype.getHistoricalPerformance = function (seriesName) {
        console.log("HSERVICE");
        console.log("HSERVICE", seriesName);
        console.log("HSERVICE ghpd(seriesName)"), this.getHistoicalPerformanceData(seriesName);
        console.log("HSERVICE ghppv", this.getHistoicalPerformacePresetValues(seriesName));
        var data = forkJoin([
            this.getHistoicalPerformanceData(seriesName),
            this.getHistoicalPerformacePresetValues(seriesName),
            this.getAllIndices(),
            this.getProfile(),
        ]);
        console.log("data in ghp service", data);
        return data;
    };
    /***
     *  get historical performace for a series
     ***/
    ScsService.prototype.getHistoicalPerformanceData = function (seriesName) {
        var _this = this;
        return this.getSCSData().pipe(switchMap(function (scs) {
            var series = scs.series.find(function (x) { return x.name == seriesName; });
            return _this.httpRequestService
                .request({
                url: series.performance.durations,
                method: API_METHODS.GET,
            })
                .pipe(map(function (data) {
                return data.body.historicalPerformance;
            }));
        }));
    };
    /***
     *  get historical performace for a series
     ***/
    ScsService.prototype.getHistoicalPerformacePresetValues = function (seriesName) {
        var _this = this;
        return this.getSCSData().pipe(switchMap(function (scs) {
            var series = scs.series.find(function (x) { return x.name == seriesName; });
            console.log("getSCSData ------------------------------------------------");
            console.log("getSCSData", series.performance.historicalPerformance);
            return _this.httpRequestService
                .request({
                url: series.performance.historicalPerformance,
                method: API_METHODS.GET,
            })
                .pipe(map(function (data) {
                var body = data.body;
                return body;
            }));
        }));
    };
    /***
     *  get historical performace for ADV
     ***/
    ScsService.prototype.getThreePieData = function (isIE) {
        var _this = this;
        return this.getSCSData().pipe(switchMap(function (scs) {
            var series = scs.series.find(function (x) { return x.name == _this.series; });
            var url;
            if (isIE) {
                url = './assets/apis/scs/seriesPLUS/scs_ipad_threePieCharts_SeriesPLUS_IE.xml';
            }
            else {
                url = series.performance.threePieChartsData;
            }
            var requestOption = {
                url: url,
                method: API_METHODS.GET,
            };
            return _this.httpRequestService.request(requestOption).pipe(map(function (response) {
                return response.body;
            }));
        }));
    };
    /***
     *  get Historical Return Values for guide
     ***/
    ScsService.prototype.getHistoricalReturnValues = function (seriesName) {
        var _this = this;
        return this.getSCSData().pipe(switchMap(function (scs) {
            var series = scs.series.find(function (x) { return x.name == seriesName; });
            var requestOption = {
                url: series.performance.historicalReturns,
                method: API_METHODS.GET,
            };
            return _this.httpRequestService.request(requestOption).pipe(map(function (response) {
                return response;
            }), catchError(function (err) {
                throw throwError(err);
            }));
        }));
    };
    ScsService.prototype.getHardcodedStandardYear3and4Values = function (data, segment, index, duration, buffer) {
        var object = {
            indexRoR3Year: 0,
            segmentRoR3Year: 0,
            indexRoR4Year: 0,
            segmentRoR4Year: 0,
            minValue: 0,
        };
        if (duration == '1yr') {
            if (buffer == '-10%') {
                if (index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
                    object.indexRoR3Year = -8;
                    object.segmentRoR3Year = 0;
                    object.indexRoR4Year = -12;
                    object.segmentRoR4Year = -2;
                    if (index == "EURO STOXX 50" || index == "MSCI EM") {
                        object.minValue = -20;
                    }
                    else {
                        object.minValue = -16;
                    }
                }
            }
            else if (buffer == '-15%') {
                if (index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
                    object.indexRoR3Year = -12;
                    object.segmentRoR3Year = 0;
                    object.indexRoR4Year = -17;
                    object.segmentRoR4Year = -2;
                    object.minValue = -20;
                }
                else if (index == "S&P 500®") {
                    object.indexRoR3Year = -8;
                    object.segmentRoR3Year = 0;
                    object.indexRoR4Year = -17;
                    object.segmentRoR4Year = -2;
                    object.minValue = -20;
                }
            }
            else if (buffer == '-20%') {
                if (index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
                    object.indexRoR3Year = -15;
                    object.segmentRoR3Year = 0;
                    object.indexRoR4Year = -22;
                    object.segmentRoR4Year = -2;
                    object.minValue = -30;
                }
            }
            else if (buffer == '-40%') {
                if (index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
                    object.indexRoR3Year = -35;
                    object.segmentRoR3Year = 0;
                    object.indexRoR4Year = -42;
                    object.segmentRoR4Year = -2;
                    if (index == "NASDAQ 100®") {
                        object.minValue = -50;
                    }
                    else {
                        object.minValue = -44;
                    }
                }
            }
        }
        else if (duration == '6yr') {
            if (buffer == '-10%') {
                if (index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
                    object.indexRoR3Year = -8;
                    object.segmentRoR3Year = 0;
                    object.indexRoR4Year = -12;
                    object.segmentRoR4Year = -2;
                    object.minValue = -20;
                }
            }
            else if (buffer == '-15%') {
                if (index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
                    object.indexRoR3Year = -8;
                    object.segmentRoR3Year = 0;
                    object.indexRoR4Year = -17;
                    object.segmentRoR4Year = -2;
                    if (index == "Russell 2000®") {
                        object.minValue = -25;
                    }
                    else if (index == "NASDAQ 100®") {
                        object.minValue = -20;
                    }
                    else {
                        object.minValue = -100;
                    }
                }
            }
            else if (buffer == '-20%') {
                if (index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
                    object.indexRoR3Year = -15;
                    object.segmentRoR3Year = 0;
                    object.indexRoR4Year = -22;
                    object.segmentRoR4Year = -2;
                    if (index == "S&P 500®") {
                        object.minValue = -50;
                    }
                    else {
                        object.minValue = -40;
                    }
                }
            }
            else if (buffer == '-30%') {
                if (index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EAFE" || index == "EURO STOXX 50" || index == "NASDAQ 100®") {
                    object.indexRoR3Year = -20;
                    object.segmentRoR3Year = 0;
                    object.indexRoR4Year = -33;
                    object.segmentRoR4Year = -3;
                    object.minValue = -45;
                }
            }
            else if (buffer == '-40%') {
                if (index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EAFE" || index == "EURO STOXX 50" || index == "NASDAQ 100®") {
                    object.indexRoR3Year = -35;
                    object.segmentRoR3Year = 0;
                    object.indexRoR4Year = -42;
                    object.segmentRoR4Year = -2;
                    object.minValue = -50;
                }
            }
        }
        else {
            object.minValue = -20;
        }
        return object;
    };
    ScsService.prototype.getHardCodedEnhancedUpsideValues = function (enhancedUpside, buffer) {
        var hardcodedEnhancedUpsides = {
            indexRoRDownMarket1: 0,
            indexRoRDownMarket2: 0,
            segmentRoRDownMarket1: 0,
            segmentRoRDownMarket2: 0,
            minvalue: 0,
        };
        if (buffer == '-10%') {
            hardcodedEnhancedUpsides.indexRoRDownMarket1 = -5;
            hardcodedEnhancedUpsides.indexRoRDownMarket2 = -15;
            hardcodedEnhancedUpsides.segmentRoRDownMarket1 = 0;
            hardcodedEnhancedUpsides.segmentRoRDownMarket2 = -5;
            if (enhancedUpside === '110%') {
                hardcodedEnhancedUpsides.minvalue = -25;
            }
            else {
                hardcodedEnhancedUpsides.minvalue = -20;
            }
        }
        else if (buffer == '-15%') {
            hardcodedEnhancedUpsides.indexRoRDownMarket1 = -12;
            hardcodedEnhancedUpsides.indexRoRDownMarket2 = -17;
            hardcodedEnhancedUpsides.segmentRoRDownMarket1 = 0;
            hardcodedEnhancedUpsides.segmentRoRDownMarket2 = -2;
            hardcodedEnhancedUpsides.minvalue = -20;
        }
        return hardcodedEnhancedUpsides;
    };
    ScsService.prototype.getHardCodedDualDirectionValues = function (data, segment, index, duration, buffer) {
        var hardcodedDualDirectionValues = {
            indexRoRDownMarket1: 0,
            indexRoRDownMarket2: 0,
            segmentRoRDownMarket1: 0,
            segmentRoRDownMarket2: 0,
            minValue: 0,
        };
        if (buffer == '-10%') {
            hardcodedDualDirectionValues.indexRoRDownMarket1 = -8;
            hardcodedDualDirectionValues.indexRoRDownMarket2 = -12;
            hardcodedDualDirectionValues.segmentRoRDownMarket1 = -8;
            hardcodedDualDirectionValues.segmentRoRDownMarket2 = -12;
            hardcodedDualDirectionValues.minValue = -15;
        }
        else if (buffer == '-15%') {
            hardcodedDualDirectionValues.indexRoRDownMarket1 = -12;
            hardcodedDualDirectionValues.indexRoRDownMarket2 = -18;
            hardcodedDualDirectionValues.segmentRoRDownMarket1 = -12;
            hardcodedDualDirectionValues.segmentRoRDownMarket2 = -18;
            hardcodedDualDirectionValues.minValue = -20;
        }
        else if (buffer == '-20%') {
            hardcodedDualDirectionValues.indexRoRDownMarket1 = -17;
            hardcodedDualDirectionValues.indexRoRDownMarket2 = -22;
            hardcodedDualDirectionValues.segmentRoRDownMarket1 = -17;
            hardcodedDualDirectionValues.segmentRoRDownMarket2 = -22;
            hardcodedDualDirectionValues.minValue = -25;
        }
        return hardcodedDualDirectionValues;
    };
    ScsService.prototype.getHardCodedDualStepUpValues = function (data, segment, index, duration, buffer, item) {
        var object = {
            indexRoRDownMarket1: 0,
            indexRoRDownMarket2: 0,
            segmentRoRDownMarket1: 0,
            segmentRoRDownMarket2: 0,
        };
        if (duration == '1yr') {
            if (buffer == '-10%') {
                if (index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
                    object.indexRoRDownMarket1 = -12;
                    object.segmentRoRDownMarket1 = -2;
                    object.indexRoRDownMarket2 = -4;
                    object.segmentRoRDownMarket2 = item.perfcaprate;
                }
            }
            else if (buffer == '-15%') {
                if (index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
                    object.indexRoRDownMarket1 = -18;
                    object.segmentRoRDownMarket1 = -3;
                    object.indexRoRDownMarket2 = -4;
                    object.segmentRoRDownMarket2 = item.perfcaprate;
                }
            }
        }
        return object;
    };
    ScsService.prototype.getHardCodedAnnualLockData = function (data, segment, index, duration, buffer, item) {
        var object = {
            indexRoR1Year: 0,
            segmentRoR1Year: 0,
            indexRoR2Year: 0,
            segmentRoR2Year: 0,
            indexRoR3Year: 0,
            segmentRoR3Year: 0,
            indexRoR4Year: 0,
            segmentRoR4Year: 0,
            indexRoR5Year: 0,
            segmentRoR5Year: 0,
            indexRoR6Year: 0,
            segmentRoR6Year: 0,
            minValueUp: 0,
            minValueDown: 0,
        };
        if (duration == '6yr') {
            if (buffer == '-10%') {
                if (index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
                    object.indexRoR1Year = item.perfcaprate * 1.3;
                    object.segmentRoR1Year = item.perfcaprate;
                    object.indexRoR2Year = -5;
                    object.segmentRoR2Year = 0;
                    object.indexRoR3Year = item.perfcaprate;
                    object.segmentRoR3Year = item.perfcaprate;
                    object.indexRoR4Year = -12;
                    object.segmentRoR4Year = -2;
                    object.indexRoR5Year = item.perfcaprate * 1.5;
                    object.segmentRoR5Year = 0; //dynamic
                    object.indexRoR6Year = item.perfcaprate * 1.1;
                    object.segmentRoR6Year = item.perfcaprate;
                    object.minValueUp = -20;
                    object.minValueDown = -20;
                }
            }
        }
        return object;
    };
    ScsService.prototype.getHardcodedStepUpValues = function (data, segment, index, duration, buffer, item) {
        var object = {
            indexDM1: 0,
            segmentDM1: 0,
            indexDM2: 0,
            segmentDM2: 0,
            minValue: 0,
        };
        if (duration == '1yr') {
            if (buffer == '-10%') {
                if (index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
                    object.indexDM1 = -12;
                    object.segmentDM1 = -2;
                    object.indexDM2 = -8;
                    object.segmentDM2 = 0;
                    if (index == "NASDAQ 100®") {
                        object.minValue = -20;
                    }
                    else {
                        object.minValue = -16;
                    }
                }
            }
            if (buffer == '-15%') {
                if (index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
                    object.indexDM1 = -17;
                    object.segmentDM1 = -2;
                    object.indexDM2 = -8;
                    object.segmentDM2 = 0;
                    object.minValue = -20;
                }
            }
        }
        if (duration == '6yr') {
            if (buffer == '-10%') {
                if (index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
                    object.indexDM1 = -12;
                    object.segmentDM1 = -2;
                    object.indexDM2 = -8;
                    object.segmentDM2 = 0;
                    object.minValue = -20;
                }
            }
        }
        return object;
    };
    ScsService.prototype.round5 = function (num) {
        // https://stackoverflow.com/questions/18953384/javascript-round-up-to-the-next-multiple-of-5
        // min values are
        // -12, -16, -22, -33
        // -22 is a problem with SP / 6yr / -20
        // if between 1 and 30, use 4 as factor with floor
        if (num == -12) {
            return (Math.ceil(num)) - 8; // use floor or ceil
        }
        if (num == -18) {
            return (Math.floor(num)) - 82; // use floor or ceil
        }
        if (num == -22) {
            return (Math.floor(num)) - 78; // use floor or ceil
        }
        if (num == -33) {
            return (Math.floor(num)) - 67; // use floor or ceil
        }
        // else if (num == -16) {
        //   return (Math.ceil(num / 5) * 5); // use floor or ceil
        // }
        // else if (num == -22) {
        //   return num - 5;
        // }
        // else if (num == -33) {
        //   return (Math.ceil(num / 5) * 5); // use floor or ceil
        // }
        else {
            return (Math.floor(num / 5) * 5); // use floor or ceil
        }
    };
    ScsService.prototype.getIndexName = function (item) {
        var indexName;
        // ID, long name, short name 
        // DJR    DJ US Real Estate ETF    DJ Real Estate
        // MEM    MSCI Emerging Markets    MSCI EM
        // GSP    Gold SPDR Index    Gold SPDR
        // XLE    Energy Sector SPDR    Energy SPDR
        // MET    MSCI EAFE ETF Index    MSCI EAFE ETF
        // GLD    London Gold Market Fixing Price    Gold Index
        // SP     S&P 500 Index    S&P 500
        // RUS    Russell 2000 Index    Russell 2000
        // MSC    MSCI EAFE Index    MSCI EAFE
        // OIL    NYMEX West Texas Intermd Crude Oil    Oil Index
        // NSD    NASDAQ 100    NASDAQ 100
        // SX5    EURO STOXX 50    EURO STOXX 50
        // FIN    Financial SPDR Index    Financial SPDR
        if (item.indexid == "SP ") {
            indexName = 'S&P 500®';
        }
        else if (item.indexid == "RUS") {
            indexName = 'Russell 2000®';
        }
        else if (item.indexid == "MET") {
            indexName = 'MSCI EAFE ETF';
        }
        else if (item.indexid == "MEM") {
            indexName = 'MSCI EM';
        }
        else if (item.indexid == "NSD") {
            indexName = 'NASDAQ 100®';
        }
        else if (item.indexid == "MSC") {
            indexName = 'MSCI EAFE';
        }
        else if (item.indexid == "SX5") {
            indexName = 'EURO STOXX 50';
        }
        return indexName;
    };
    //  CHECK options in JSON object FOR PLUS AND PLUS 21
    ScsService.prototype.getOptionName = function (item) {
        var optionName;
        // S16 AL --> option=ALC and segopttype=AL
        // S21 AL --> option=S21 and segopttype=AL
        // PLUS 21
        if (item.option == "S21") {
            optionName = "PLUS21";
        }
        // the annual lock for PLUS uses ALC on the API (for some reason).
        else if (item.option == "S16" || item.option == "ALC") {
            optionName = "PLUS";
        }
        return optionName;
    };
    ScsService.prototype.getSegmentName = function (item) {
        var segmentName;
        if (item.segopttype == "ST") {
            segmentName = "Standard";
        }
        else if (item.segopttype == "AL") {
            segmentName = "Annual Lock";
        }
        else if (item.segopttype == "SU") {
            segmentName = "Step Up";
        }
        else if (item.segopttype == "DD") {
            segmentName = "Dual Direction";
        }
        else if (item.segopttype == "EU") {
            segmentName = "Enhanced Upside";
        }
        else if (item.segopttype == "DS") {
            segmentName = "Dual Step Up";
        }
        else if (item.segopttype == "LL-90") {
            segmentName = "Loss Limiter 90";
        }
        else if (item.segopttype == "LL-95") {
            segmentName = "Loss Limiter 95";
        }
        return segmentName;
    };
    ScsService.prototype.getEnhancedUpsideRate = function (item) {
        // enhupsiderate
        if (item.enhupsiderate == "125") {
            return '125%';
        }
        else if (item.enhupsiderate == "110") {
            return '110%';
        }
    };
    ScsService.prototype.getSegmentProductCode = function (item) {
        // S21ASH
        // S21BSH
        // S21CSH
        // s21
        if (item.prodcode == 'S21ASH') {
            return 'S21ASH';
        }
        // s21
        else if (item.prodcode == 'S21BSH') {
            return 'S21BSH';
        }
        // s21
        else if (item.prodcode == 'S21CSH') {
            return 'S21CSH';
        }
        // s16
        else if (item.prodcode == 'SADSHR') {
            return 'SADSHR';
        }
        // scsincome
        // SINASH
        else if (item.prodcode == 'SINASH') {
            return 'SINASH';
        }
        // scsincome
        // SINBSH 
        else if (item.prodcode == 'SINBSH') {
            return 'SINBSH';
        }
        // IE21BS
        else if (item.prodcode == 'IE21BS') {
            return 'IE21BS';
        }
        // IE21AS
        else if (item.prodcode == 'IE21AS') {
            return 'IE21AS';
        }
        // IE21AS
        else if (item.prodcode == 'IECSHR') {
            return 'IECSHR';
        }
        console.log(item.prodcode);
    };
    ScsService.prototype.hydrateObject = function (item, index, additionalCharges, hardcodedMin, performaceRate, segmentRates, segment, hardCodedPerCapRate) {
        if (hardCodedPerCapRate) {
            item.perfcaprate = hardCodedPerCapRate;
        }
        var objPerformance = {
            id: item.duration + 'yr',
            max: this.calculateMaxValue(Math.max(item.cases[0].indexROR.toFixed(0), item.cases[1].indexROR.toFixed(0)), segment, item),
            min: hardcodedMin,
            interval: this.calculateInterval(parseInt(item.perfcaprate).toFixed(0), segment, item),
            performaceCapRate: this.roundNumberToTenths(item.perfcaprate),
            buffer: item.buffer + '%',
            performaceRate: this.roundArrayToHundreths(performaceRate),
            segmentRates: this.roundArrayToHundreths(segmentRates),
            additionalCharges: additionalCharges,
            index: index,
            option: item.option,
            enhancedUpsideRate: item.enhupsiderate,
            prodcode: item.prodcode,
            segopttype: item.segopttype,
        };
        return objPerformance;
    };
    ScsService.prototype.getDynamicStandardData = function (data, segment, index, duration, buffer) {
        // TODD: need to take this out of the .plist files and howitWorks.json
        // item.cases[0].caseId == "ST-UP1" && item.cases[1].caseId == "ST-UP2"
        var performanceArray = [];
        // compare the buffer and duration from the front-end to the JSON from the API
        // plug this into buffer and duration at some point
        var newBuffer = buffer.toString().substring(0, 3);
        var newDuration = duration.toString().substring(0, 1);
        var hardCodedProductCode = ''; // hardcode to series A until we deteremine from business what they want rendered.
        if (this.product == PRODUCT_TYPE.IE) {
            hardCodedProductCode = 'IE21BS';
        }
        if (this.series == SCS_SERIES.PLUS21) {
            hardCodedProductCode = 'S21BSH';
        }
        if (this.series == SCS_SERIES.PLUS21) {
            hardCodedProductCode = 'S21BSH';
        }
        else if (this.series == SCS_SERIES.PLUS) {
            hardCodedProductCode = 'SADSHR';
        }
        var hardCodedScenario3And4Object = this.getHardcodedStandardYear3and4Values(data, segment, index, duration, buffer); // TODO: try to pass with the values defined here, otherwise move into the data.forEach and pass the item values
        console.log(hardCodedScenario3And4Object);
        data.forEach(function (item) {
            // the app and API do not have the same index names, so we need to check each one
            var indexName = this.getIndexName(item);
            var optionName = this.getOptionName(item);
            var segmentName = this.getSegmentName(item);
            var productCode = this.getSegmentProductCode(item);
            var objPerformance;
            //index performance rates
            var column1IndexPerformanceRateUpMarket = item.perfcaprate * 0.7; //item.cases[0].indexROR;
            var column2IndexPerformanceRateUpMarket = item.perfcaprate * 1.1; //item.cases[1].indexROR;
            var column3IndexPerformanceRateDownMarket = hardCodedScenario3And4Object.indexRoR3Year; //dynamic value is item.case[2].indexROR
            var column4IndexPerformanceRateDownMarket = hardCodedScenario3And4Object.indexRoR4Year; //dynamic value is item.case[3].indexROR
            //segment rates of return
            var column1SegmentRatesOfReturnUpMarket = item.perfcaprate * 0.7; //item.cases[0].segmentROR;
            var column2SegmentRatesOfReturnUpMarket = item.perfcaprate; //item.cases[1].segmentROR;
            var column3SegmentRatesOfReturnDownMarket = hardCodedScenario3And4Object.segmentRoR3Year; //dynamic value is item.case[2].indexROR
            var column4SegmentRatesOfReturnDownMarket = hardCodedScenario3And4Object.segmentRoR4Year; //dynamic value is item.case[3].indexROR
            // Hardcoded uncapped exception for MSCI EAFE, 6 year, -10
            if (item.buffer == '-10' && item.duration == '6' && item.indexid == "MSC" && segment == segmentName && this.series == optionName && hardCodedProductCode == productCode) {
                objPerformance = this.hydrateObject(item, 'MSCI EAFE', 0, hardCodedScenario3And4Object.minValue, [105, 150, column3IndexPerformanceRateDownMarket, column4IndexPerformanceRateDownMarket], [105, 150, column3SegmentRatesOfReturnDownMarket, column4SegmentRatesOfReturnDownMarket], segment);
                performanceArray.push(objPerformance);
            }
            // Hardcoded uncapped exception for MSCI EAFE, 6 year, -20
            if (item.buffer == '-20' && item.duration == '6' && item.indexid == "MSC" && segment == segmentName && this.series == optionName && hardCodedProductCode == productCode) {
                objPerformance = this.hydrateObject(item, 'MSCI EAFE', 0, hardCodedScenario3And4Object.minValue, [105, 150, column3IndexPerformanceRateDownMarket, column4IndexPerformanceRateDownMarket], [105, 150, column3SegmentRatesOfReturnDownMarket, column4SegmentRatesOfReturnDownMarket], segment, 150);
                performanceArray.push(objPerformance);
            }
            //hardcoded uncapped exception for S&P 500®, 6 year, -10
            if (item.buffer == '-10' && item.duration == '6' && item.indexid == "SP " && segment == segmentName && this.series == optionName && hardCodedProductCode == productCode) {
                objPerformance = this.hydrateObject(item, 'S&P 500®', 0, hardCodedScenario3And4Object.minValue, [105, 150, column3IndexPerformanceRateDownMarket, column4IndexPerformanceRateDownMarket], [105, 150, column3SegmentRatesOfReturnDownMarket, column4SegmentRatesOfReturnDownMarket], segment);
                performanceArray.push(objPerformance);
            }
            //hardcoded uncapped exception for value MSCI EAFE ETF, 6 year, -10
            if (item.buffer == '-10' && item.duration == '6' && item.indexid == "MET" && segment == segmentName && this.series == optionName && hardCodedProductCode == productCode) {
                objPerformance = this.hydrateObject(item, 'MSCI EAFE ETF', 0, hardCodedScenario3And4Object.minValue, [105, 150, column3IndexPerformanceRateDownMarket, column4IndexPerformanceRateDownMarket], [105, 150, column3SegmentRatesOfReturnDownMarket, column4SegmentRatesOfReturnDownMarket], segment);
                performanceArray.push(objPerformance);
            }
            if (item.buffer == newBuffer && item.duration == newDuration && segment == segmentName && this.series == optionName && hardCodedProductCode == productCode) {
                objPerformance = this.hydrateObject(item, indexName, 0, hardCodedScenario3And4Object.minValue, [column1IndexPerformanceRateUpMarket, column2IndexPerformanceRateUpMarket, column3IndexPerformanceRateDownMarket, column4IndexPerformanceRateDownMarket], [column1SegmentRatesOfReturnUpMarket, column2SegmentRatesOfReturnUpMarket, column3SegmentRatesOfReturnDownMarket, column4SegmentRatesOfReturnDownMarket], segment);
                performanceArray.push(objPerformance);
            }
        }, this); // https://stackoverflow.com/questions/45175605/how-to-call-this-inside-for-example-foreach-in-class
        return performanceArray;
    };
    ScsService.prototype.hydrateUpDownMarketObject = function (item) {
        var upDownMarketObject = {
            upward: {
                0: this.roundNumToHundreths(item.cases[0].segmentROR),
                1: this.roundNumToHundreths(item.cases[0].indexROR),
                2: this.roundNumToHundreths(0),
                3: this.roundNumToHundreths(-5),
                4: this.roundNumToHundreths(item.cases[1].segmentROR),
                5: this.roundNumToHundreths(item.cases[1].indexROR),
                6: this.roundNumToHundreths(-2),
                7: this.roundNumToHundreths(-12),
                8: this.roundNumToHundreths(item.cases[2].segmentROR),
                9: this.roundNumToHundreths(item.cases[2].indexROR),
                10: this.roundNumToHundreths(item.cases[3].segmentROR),
                11: this.roundNumToHundreths(item.cases[3].indexROR),
            },
            downward: {
                0: this.roundNumToHundreths(item.cases[0].segmentROR),
                1: this.roundNumToHundreths(item.cases[0].indexROR),
                2: this.roundNumToHundreths(0),
                3: this.roundNumToHundreths(-5),
                4: this.roundNumToHundreths(item.cases[1].segmentROR),
                5: this.roundNumToHundreths(item.cases[1].indexROR),
                6: this.roundNumToHundreths(-12),
                7: this.roundNumToHundreths(-22),
                8: this.roundNumToHundreths(0),
                9: this.roundNumToHundreths(-10),
                10: this.roundNumToHundreths(-8),
                11: this.roundNumToHundreths(-18),
            },
            index: this.getIndexName(item)
        };
        return upDownMarketObject;
    };
    ScsService.prototype.getDynamicAnnualLockData = function (data, segment, index, duration, buffer, marketTrend) {
        // caseId
        // AL-1.1
        // AL-1.3
        // AL-1.5
        // AL-1.6
        // AL-2.1 // DOWNMARKET
        // AL-2.3
        var performanceArray = [];
        var upDownMarketArray = [];
        var upDownMarketObject = {
            upward: {},
            downward: {},
        };
        var year4indexRoR, year4SegmentRoR, year5indexRoR, year5SegmentRoR, year6IndexRoR, year6SegmentRoR;
        var newBuffer = buffer.toString().substring(0, 3);
        var newDuration = duration.toString().substring(0, 1);
        var hardCodedProductCode = ''; // hardcode to series A until we deteremine from business what they want rendered. 
        if (this.series == SCS_SERIES.PLUS21) {
            hardCodedProductCode = 'S21BSH';
        }
        else if (this.series == SCS_SERIES.PLUS) {
            hardCodedProductCode = 'SADSHR';
        }
        data.forEach(function (item) {
            //let hardCodedAnnualLockObject = this.getHardCodedAnnualLockData(data, segment, index, duration, buffer, item);
            // the app and API do not have the same index names, so we need to check each one
            var indexName = this.getIndexName(item);
            var optionName = this.getOptionName(item);
            var segmentName = this.getSegmentName(item);
            var productCode = this.getSegmentProductCode(item);
            var objPerformance;
            if (item.buffer == newBuffer && item.duration == newDuration && segment == segmentName && this.series == optionName && hardCodedProductCode == productCode) {
                // IF(Upward, use values from the API. Otherwise, the downmarket scenarios for years 5 and 6 are hardcoded)
                if (marketTrend === MARKET_SCENERIO.UPWARD) {
                    year4indexRoR = -12;
                    year4SegmentRoR = -2;
                    year5indexRoR = item.cases[2].indexROR;
                    year5SegmentRoR = item.cases[2].segmentROR;
                    year6IndexRoR = item.cases[3].indexROR;
                    year6SegmentRoR = item.cases[3].segmentROR;
                }
                else {
                    year4indexRoR = -22;
                    year4SegmentRoR = -12;
                    year5indexRoR = -10;
                    year5SegmentRoR = 0;
                    year6IndexRoR = -18;
                    year6SegmentRoR = -8;
                }
                //index performance rates
                var column1IndexPerformanceRate = item.cases[0].indexROR;
                var column2IndexPerformanceRate = -5;
                var column3IndexPerformanceRate = item.cases[1].indexROR;
                var column4IndexPerformanceRate = year4indexRoR;
                var column5IndexPerformanceRate = year5indexRoR;
                var column6IndexPerformanceRate = year6IndexRoR;
                //segment rates of return
                var column1SegmentRatesOfReturn = item.cases[0].segmentROR;
                var column2SegmentRatesOfReturn = 0;
                var column3SegmentRatesOfReturn = item.cases[1].segmentROR;
                var column4SegmentRatesOfReturn = year4SegmentRoR;
                var column5SegmentRatesOfReturn = year5SegmentRoR;
                var column6SegmentRatesOfReturn = year6SegmentRoR;
                objPerformance = this.hydrateObject(item, indexName, 0, -20, [column1IndexPerformanceRate, column2IndexPerformanceRate, column3IndexPerformanceRate, column4IndexPerformanceRate, column5IndexPerformanceRate, column6IndexPerformanceRate], [column1SegmentRatesOfReturn, column2SegmentRatesOfReturn, column3SegmentRatesOfReturn, column4SegmentRatesOfReturn, column5SegmentRatesOfReturn, column6SegmentRatesOfReturn], segment);
                performanceArray.push(objPerformance);
                // for annual lock, we need to pass the API an object that includes both the upward and downward values
                upDownMarketObject = this.hydrateUpDownMarketObject(item);
                // push all the updown market objects onto an array
                upDownMarketArray.push(upDownMarketObject);
            }
        }, this);
        // iterate through the array, check if the index is equal, and update the values of the up and down market bars 
        upDownMarketArray.forEach(function (upOrDownMarketObject) {
            if (index == upOrDownMarketObject.index) {
                this.upMarketBars = upOrDownMarketObject.upward;
                this.downMarketBars = upOrDownMarketObject.downward;
            }
        }, this);
        return performanceArray;
    };
    ScsService.prototype.getDynamicStepUpData = function (data, segment, index, duration, buffer) {
        var performanceArray = []; // object needs to match testObject.performance
        // indexids
        // [9] = SP
        // [10] = "RUS"
        // [13] = "MET"
        var newBuffer = buffer.toString().substring(0, 3);
        var newDuration = duration.toString().substring(0, 1);
        var hardCodedProductCode = ''; // hardcode to series A until we deteremine from business what they want rendered. 
        if (this.series == SCS_SERIES.PLUS21) {
            hardCodedProductCode = 'S21BSH';
        }
        else if (this.series == SCS_SERIES.PLUS) {
            hardCodedProductCode = 'SADSHR';
        }
        data.forEach(function (item) {
            var hardCodedScenarios = this.getHardcodedStepUpValues(data, segment, index, duration, buffer, item);
            // the app and API do not have the same index names, so we need to check each one
            var indexName = this.getIndexName(item);
            var optionName = this.getOptionName(item);
            var segmentName = this.getSegmentName(item);
            var productCode = this.getSegmentProductCode(item);
            var objPerformance;
            //index performance rates
            var column1IndexPerformanceRateDownMarket = hardCodedScenarios.indexDM1;
            var column2IndexPerformanceRateDownMarket = hardCodedScenarios.indexDM2;
            var column3IndexPerformanceRateUpMarket = 0;
            var column4IndexPerformanceRateUpMarket = item.perfcaprate * 0.5; //item.cases[0].indexROR;
            var column5IndexPerformanceRateUpMarket = item.perfcaprate * 1.1; //item.cases[1].indexROR;
            //segment rates of return
            var column1SegmentRatesOfReturnUpMarket = hardCodedScenarios.segmentDM1;
            var column2SegmentRatesOfReturnUpMarket = hardCodedScenarios.segmentDM2;
            var column3SegmentRatesOfReturnDownMarket = item.perfcaprate;
            var column4SegmentRatesOfReturnDownMarket = item.perfcaprate; //item.cases[0].segmentROR;
            var column5SegmentRatesOfReturnDownMarket = item.cases[1].segmentROR;
            if (item.buffer == newBuffer && item.duration == newDuration && segment == segmentName && this.series == optionName && hardCodedProductCode == productCode) {
                objPerformance = this.hydrateObject(item, indexName, 0, hardCodedScenarios.minValue, [column1IndexPerformanceRateDownMarket, column2IndexPerformanceRateDownMarket, column3IndexPerformanceRateUpMarket, column4IndexPerformanceRateUpMarket, column5IndexPerformanceRateUpMarket], [column1SegmentRatesOfReturnUpMarket, column2SegmentRatesOfReturnUpMarket, column3SegmentRatesOfReturnDownMarket, column4SegmentRatesOfReturnDownMarket, column5SegmentRatesOfReturnDownMarket], segment);
                performanceArray.push(objPerformance);
            }
        }, this); // https://stackoverflow.com/questions/45175605/how-to-call-this-inside-for-example-foreach-in-class
        return performanceArray;
    };
    ScsService.prototype.getDynamicDualDirectionData = function (data, segment, index, duration, buffer) {
        var performanceArray = [];
        var newBuffer = buffer.toString().substring(0, 3);
        var newDuration = duration.toString().substring(0, 1);
        var hardCodedProductCode = ''; // hardcode to series A until we deteremine from business what they want rendered. 
        if (this.series == SCS_SERIES.PLUS21) {
            hardCodedProductCode = 'S21BSH';
        }
        else if (this.series == SCS_SERIES.PLUS) {
            hardCodedProductCode = 'SADSHR';
        }
        var hardCodedScenario3And4Object = this.getHardCodedDualDirectionValues(data, segment, index, duration, buffer);
        data.forEach(function (item) {
            // the app and API do not have the same index names, so we need to check each one
            var indexName = this.getIndexName(item);
            var optionName = this.getOptionName(item);
            var segmentName = this.getSegmentName(item);
            var productCode = this.getSegmentProductCode(item);
            var objPerformance;
            //index performance rates
            var scenario1IndexPerformanceRate = item.cases[0].indexROR;
            var scenario2IndexPerformanceRate = item.cases[1].indexROR;
            var scenario3IndexPerformance = hardCodedScenario3And4Object.indexRoRDownMarket1;
            var scenario4IndexPerformance = hardCodedScenario3And4Object.indexRoRDownMarket1;
            //segment rates of return - these are used for %
            var scenario1SegmentRatesOfReturn = item.perfcaprate * 1.1; //item.cases[0].segmentROR; 
            var scenario2SegmentRatesOfReturn = item.perfcaprate * 0.7; //item.cases[1].segmentROR; 
            var scenario3SegmentRatesOfReturn = hardCodedScenario3And4Object.segmentRoRDownMarket1;
            var scenario4SegmentRatesOfReturn = hardCodedScenario3And4Object.segmentRoRDownMarket2;
            if (item.buffer == newBuffer && item.duration == newDuration && segment == segmentName && this.series == optionName && hardCodedProductCode == productCode) {
                objPerformance = this.hydrateObject(item, indexName, 0, hardCodedScenario3And4Object.minValue, // interval. -10 == 10, -15 = 10, -20 = 5
                [scenario1IndexPerformanceRate, scenario2IndexPerformanceRate, scenario3IndexPerformance, scenario4IndexPerformance], [scenario1SegmentRatesOfReturn, scenario2SegmentRatesOfReturn, scenario3SegmentRatesOfReturn, scenario4SegmentRatesOfReturn], segment);
                performanceArray.push(objPerformance);
            }
        }, this); // https://stackoverflow.com/questions/45175605/how-to-call-this-inside-for-example-foreach-in-class
        return performanceArray;
    };
    ScsService.prototype.getDynamicEnhancedUpsideData = function (data, segment, index, duration, buffer, enhancedUpside) {
        // [23] in array, "EU-UP1", "EU-UP2", "EU-UP3"
        var newBuffer = buffer.toString().substring(0, 3);
        var newDuration = duration.toString().substring(0, 1);
        var hardCodedProductCode = ''; // hardcode to series A until we deteremine from business what they want rendered. 
        if (this.series == SCS_SERIES.PLUS21) {
            hardCodedProductCode = 'S21BSH';
        }
        else if (this.series == SCS_SERIES.PLUS) {
            hardCodedProductCode = 'SADSHR';
        }
        var performanceArray = [];
        var hardCodedDownMarketValues = this.getHardCodedEnhancedUpsideValues(enhancedUpside, buffer);
        // TODO: Figure out why the segment rates of return are not going to the proper values 
        data.forEach(function (item) {
            var indexName = this.getIndexName(item);
            var optionName = this.getOptionName(item);
            var segmentName = this.getSegmentName(item);
            var productCode = this.getSegmentProductCode(item);
            var enhancedUpsideNameFromAPI = this.getEnhancedUpsideRate(item);
            var objPerformance;
            if (item.buffer == newBuffer && item.duration == newDuration && segment == segmentName && this.series == optionName && enhancedUpside == enhancedUpsideNameFromAPI && hardCodedProductCode == productCode) {
                //index performance rates
                var column1IndexPerformanceRateUpMarket = item.cases[0].indexROR;
                var column2IndexPerformanceRateUpMarket = item.cases[1].indexROR;
                var column3IndexPerformanceRateUpMarket = item.cases[2].indexROR;
                var column4IndexPerformanceRateDownMarket = hardCodedDownMarketValues.indexRoRDownMarket1;
                var column5IndexPerformanceRateDownMarket = hardCodedDownMarketValues.indexRoRDownMarket2;
                //segment rates of return
                var column1SegmentRatesOfReturnUpMarket = item.cases[0].segmentROR;
                var column2SegmentRatesOfReturnUpMarket = item.cases[1].segmentROR;
                var column3SegmentRatesOfReturnUpMarket = item.cases[2].segmentROR;
                var column4SegmentRatesOfReturnDownMarket = hardCodedDownMarketValues.segmentRoRDownMarket1;
                var column5SegmentRatesOfReturnDownMarket = hardCodedDownMarketValues.segmentRoRDownMarket2;
                objPerformance = this.hydrateObject(item, indexName, 0, hardCodedDownMarketValues.minvalue, [column1IndexPerformanceRateUpMarket, column2IndexPerformanceRateUpMarket, column3IndexPerformanceRateUpMarket, column4IndexPerformanceRateDownMarket, column5IndexPerformanceRateDownMarket], [column1SegmentRatesOfReturnUpMarket, column2SegmentRatesOfReturnUpMarket, column3SegmentRatesOfReturnUpMarket, column4SegmentRatesOfReturnDownMarket, column5SegmentRatesOfReturnDownMarket], segment);
                performanceArray.push(objPerformance);
            }
        }, this);
        return performanceArray;
    };
    ScsService.prototype.getDynamicDualStepUpData = function (data, segment, index, duration, buffer) {
        var performanceArray = []; // object needs to match testObject.performance
        var newBuffer = buffer.toString().substring(0, 3);
        var newDuration = duration.toString().substring(0, 1);
        var hardCodedProductCode = ''; // hardcode to series A until we deteremine from business what they want rendered. 
        if (this.series == SCS_SERIES.PLUS21) {
            hardCodedProductCode = 'S21BSH';
        }
        else if (this.series == SCS_SERIES.PLUS) {
            hardCodedProductCode = 'SADSHR';
        }
        data.forEach(function (item) {
            var hardCodedValues = this.getHardCodedDualStepUpValues(data, segment, index, duration, buffer, item);
            // the app and API do not have the same index names, so we need to check each one
            var indexName = this.getIndexName(item);
            var optionName = this.getOptionName(item);
            var segmentName = this.getSegmentName(item);
            var productCode = this.getSegmentProductCode(item);
            var objPerformance;
            if (item.buffer == newBuffer && item.duration == newDuration && segment == segmentName && this.series == optionName && hardCodedProductCode == productCode) {
                //index performance rates
                var column1IndexPerformanceRateDownMarket = hardCodedValues.indexRoRDownMarket1;
                var column2IndexPerformanceRateDownMarket = hardCodedValues.indexRoRDownMarket2;
                var column3IndexPerformanceRateUpMarket = 0;
                var column4IndexPerformanceRateUpMarket = item.cases[0].indexROR; //item.perfcaprate * 0.5;
                var column5IndexPerformanceRateUpMarket = item.cases[1].indexROR; //item.perfcaprate * 1.1
                //segment rates of return
                var column1SegmentRatesOfReturnUpMarket = hardCodedValues.segmentRoRDownMarket1;
                var column2SegmentRatesOfReturnUpMarket = hardCodedValues.segmentRoRDownMarket2;
                var column3SegmentRatesOfReturnDownMarket = item.perfcaprate;
                var column4SegmentRatesOfReturnDownMarket = item.perfcaprate;
                var column5SegmentRatesOfReturnDownMarket = item.perfcaprate;
                objPerformance = this.hydrateObject(item, indexName, 0, -20, [column1IndexPerformanceRateDownMarket, column2IndexPerformanceRateDownMarket, column3IndexPerformanceRateUpMarket, column4IndexPerformanceRateUpMarket, column5IndexPerformanceRateUpMarket], [column1SegmentRatesOfReturnUpMarket, column2SegmentRatesOfReturnUpMarket, column3SegmentRatesOfReturnDownMarket, column4SegmentRatesOfReturnDownMarket, column5SegmentRatesOfReturnDownMarket], segment);
                performanceArray.push(objPerformance);
            }
        }, this); // https://stackoverflow.com/questions/45175605/how-to-call-this-inside-for-example-foreach-in-class
        return performanceArray;
    };
    ScsService.prototype.getDynamicLossLimiter90Data = function (data, segment, index, duration, buffer) {
        var performanceArray = []; // object needs to match testObject.performance
        var newBuffer = buffer.toString().substring(0, 3);
        var newDuration = duration.toString().substring(0, 1);
        var hardCodedProductCode = ''; // hardcode to series A until we deteremine from business what they want rendered. 
        if (this.series == SCS_SERIES.PLUS21) {
            hardCodedProductCode = 'S21BSH';
        }
        else if (this.series == SCS_SERIES.PLUS) {
            hardCodedProductCode = 'SADSHR';
        }
        data.forEach(function (item) {
            // the app and API do not have the same index names, so we need to check each one
            var indexName = this.getIndexName(item);
            var optionName = this.getOptionName(item);
            var segmentName = this.getSegmentName(item);
            var productCode = this.getSegmentProductCode(item);
            var objPerformance;
            //index performance rates
            var column1IndexPerformanceRateDownMarket = -30;
            var column2IndexPerformanceRateDownMarket = -13;
            var column3IndexPerformanceRateDownMarket = -7;
            var column4IndexPerformanceRateUpMarket = item.perfcaprate * 0.7; //item.cases[0].indexROR;
            var column5IndexPerformanceRateUpMarket = item.perfcaprate * 1.1; //item.cases[1].indexROR;
            //segment rates of return
            var column1SegmentRatesOfReturnDownMarket = -10;
            var column2SegmentRatesOfReturnDownMarket = -3;
            var column3SegmentRatesOfReturnDownMarket = 0;
            var column4SegmentRatesOfReturnUpMarket = item.perfcaprate * 0.7; //item.cases[0].segmentROR;
            var column5SegmentRatesOfReturnUpMarket = item.cases[1].segmentROR;
            if (item.buffer == newBuffer && item.duration == newDuration && segment == segmentName && this.series == optionName && hardCodedProductCode == productCode) {
                objPerformance = this.hydrateObject(item, indexName, 0, -30, [column1IndexPerformanceRateDownMarket, column2IndexPerformanceRateDownMarket, column3IndexPerformanceRateDownMarket, column4IndexPerformanceRateUpMarket, column5IndexPerformanceRateUpMarket], [column1SegmentRatesOfReturnDownMarket, column2SegmentRatesOfReturnDownMarket, column3SegmentRatesOfReturnDownMarket, column4SegmentRatesOfReturnUpMarket, column5SegmentRatesOfReturnUpMarket], segment);
                performanceArray.push(objPerformance);
            }
        }, this); // https://stackoverflow.com/questions/45175605/how-to-call-this-inside-for-example-foreach-in-class
        return performanceArray;
    };
    ScsService.prototype.getDynamicLossLimiter95Data = function (data, segment, index, duration, buffer) {
        var performanceArray = []; // object needs to match testObject.performance
        var newBuffer = buffer.toString().substring(0, 3);
        var newDuration = duration.toString().substring(0, 1);
        var hardCodedProductCode = ''; // hardcode to series A until we deteremine from business what they want rendered. 
        if (this.series == SCS_SERIES.PLUS21) {
            hardCodedProductCode = 'S21BSH';
        }
        else if (this.series == SCS_SERIES.PLUS) {
            hardCodedProductCode = 'SADSHR';
        }
        data.forEach(function (item) {
            // the app and API do not have the same index names, so we need to check each one
            var indexName = this.getIndexName(item);
            var optionName = this.getOptionName(item);
            var segmentName = this.getSegmentName(item);
            var productCode = this.getSegmentProductCode(item);
            var objPerformance;
            //index performance rates
            var column1IndexPerformanceRateDownMarket = -30;
            var column2IndexPerformanceRateDownMarket = -13;
            var column3IndexPerformanceRateDownMarket = -7;
            var column4IndexPerformanceRateUpMarket = item.perfcaprate * 0.7; //item.cases[0].indexROR;
            var column5IndexPerformanceRateUpMarket = item.perfcaprate * 1.1; //item.cases[1].indexROR;
            //segment rates of return
            var column1SegmentRatesOfReturnDownMarket = -5;
            var column2SegmentRatesOfReturnDownMarket = -3;
            var column3SegmentRatesOfReturnDownMarket = 0;
            var column4SegmentRatesOfReturnUpMarket = item.perfcaprate * 0.7; //item.cases[0].segmentROR;
            var column5SegmentRatesOfReturnUpMarket = item.cases[1].segmentROR;
            if (item.buffer == newBuffer && item.duration == newDuration && segment == segmentName && this.series == optionName && hardCodedProductCode == productCode) {
                objPerformance = this.hydrateObject(item, indexName, 0, -30, [column1IndexPerformanceRateDownMarket, column2IndexPerformanceRateDownMarket, column3IndexPerformanceRateDownMarket, column4IndexPerformanceRateUpMarket, column5IndexPerformanceRateUpMarket], [column1SegmentRatesOfReturnDownMarket, column2SegmentRatesOfReturnDownMarket, column3SegmentRatesOfReturnDownMarket, column4SegmentRatesOfReturnUpMarket, column5SegmentRatesOfReturnUpMarket], segment);
                performanceArray.push(objPerformance);
            }
        }, this); // https://stackoverflow.com/questions/45175605/how-to-call-this-inside-for-example-foreach-in-class
        return performanceArray;
    };
    ScsService.prototype.roundNumberToTenths = function (num) {
        return Math.round(num * 10) / 10;
    };
    ScsService.prototype.roundArrayToHundreths = function (array) {
        // const roundedArray = array.map(num => Math.round(num * 100) / 100);
        var roundedArray = array.map(function (num) { return parseFloat(num).toFixed(2); });
        return roundedArray;
    };
    ScsService.prototype.roundNumToHundreths = function (num) {
        return parseFloat(num).toFixed(2);
    };
    ScsService.prototype.calculateMaxValue = function (maxValue, segment, item) {
        // hardcode the max y-axis value for annual lock as it's dependent on the line graph 
        if (segment == SEGMENT_TYPE.ANNUAL_LOCK) {
            return 50;
        }
        if (item.buffer == '-10' && item.duration == '6' && (item.indexid == "SP " || item.indexid == "MET" || item.indexid == "MSC") && (item.cases[0].caseId == "ST-UP1" && item.cases[1].caseId == "ST-UP2")) {
            return 150;
        }
        // hardcoded for uncapped rates, MSCI and Standard, 6 year, -20
        if (this.series == SCS_SERIES.PLUS21) {
            if (item.buffer == '-20' && item.duration == '6' && (item.indexid == 'MSC') && (item.cases[0].caseId == "ST-UP1" && item.cases[1].caseId == "ST-UP2")) {
                return 150;
            }
        }
        // IF(indexROR is less than 20, add 5 to it)
        if (maxValue <= 20) {
            return maxValue + 2;
        }
        // IF(either indexROR is 0 to 200, add 10 to it)
        else if (maxValue < 200) {
            return maxValue + 10;
        }
        // IF(either indexROR is 200 to 1000, add 50 to it)
        else if (maxValue >= 200 && maxValue <= 1000) {
            return maxValue + 30;
        }
        // IF(either indexROR is greater than 1000, add 100 to it)
        else if (maxValue > 1000) {
            return maxValue + 100;
        }
        else {
            console.error('Return value unknown');
        }
    };
    // TODO: not sure we will need min value. may need to remain hardcoded
    ScsService.prototype.calculateMinValue = function (minValue) {
        // IF(indexROR is less than 20, add 5 to it)
        if (minValue <= 30) {
            return minValue - 2;
        }
        // IF(either indexROR is 0 to 200, add 10 to it)
        else if (minValue < 200) {
            return minValue - 10;
        }
        // IF(either indexROR is 200 to 1000, add 50 to it)
        else if (minValue >= 200 && minValue <= 1000) {
            return minValue - 50;
        }
        // IF(either indexROR is greater than 1000, add 100 to it)
        else if (minValue > 1000) {
            return minValue - 100;
        }
        else {
            console.error('Return value unknown');
        }
    };
    ScsService.prototype.calculateInterval = function (perfcaprate, segment, item) {
        // hardcode the max y-axis value for annual lock as it's dependent on the line graph 
        if (segment == SEGMENT_TYPE.ANNUAL_LOCK) {
            return 10;
        }
        // hardcoded for uncapped rates, Standard,MSCI,NASDAQ, 6 year, -10 
        if (item.buffer == '-10' && item.duration == '6' && (item.indexid == "SP " || item.indexid == "MET" || item.indexid == 'MSC' || item.indexid == 'NSD' || item.indexid == 'RUS') && (item.cases[0].caseId == "ST-UP1" && item.cases[1].caseId == "ST-UP2")) {
            if (item.indexid == 'RUS') {
                return 20;
            }
            else {
                return 10;
            }
        }
        // hardcoded for uncapped rates, Standard, S&P 500, 6 year, -20
        if (this.series == SCS_SERIES.PLUS21) {
            if (item.buffer == '-20' && item.duration == '6' && (item.indexid == "SP ")) {
                return 50;
            }
        }
        if (perfcaprate <= 25) {
            return 2;
        }
        else if (perfcaprate == 80 || perfcaprate == 90) {
            return 10;
        }
        else if (perfcaprate >= 100 && perfcaprate <= 124) {
            return 5;
        }
        else if (perfcaprate >= 125 && perfcaprate <= 199) {
            return 20;
        }
        else if (perfcaprate < 200) {
            return 10;
        }
        else if (perfcaprate == 200) {
            return 25;
        }
        else if (perfcaprate > 200 && perfcaprate < 300) {
            return 20;
        }
        else if (perfcaprate >= 300 && perfcaprate <= 499) {
            return 25;
        }
        else if (perfcaprate >= 500 && perfcaprate <= 1000) {
            return 100;
        }
        else {
            console.log('Return value unknown');
            return 10;
        }
    };
    //======================================= End Here ===================================== //
    /***
     *  Open Modal
     ***/
    ScsService.prototype.openModal = function (componentName, className) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: componentName,
                            cssClass: className,
                        })];
                    case 1:
                        modal = _a.sent();
                        return [4 /*yield*/, modal.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ScsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ScsService_Factory() { return new ScsService(i0.ɵɵinject(i1.HttpRequestService), i0.ɵɵinject(i2.TranslateService), i0.ɵɵinject(i3.IonicStorageService), i0.ɵɵinject(i4.CoreService), i0.ɵɵinject(i5.ModalController), i0.ɵɵinject(i6.FeatureFlagService), i0.ɵɵinject(i7.HttpClient), i0.ɵɵinject(i8.UtilityService)); }, token: ScsService, providedIn: "root" });
    return ScsService;
}());
export { ScsService };
