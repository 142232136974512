import { UtilityService } from "src/app/core/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { OnInit, ElementRef } from "@angular/core";
import { FormControl, FormBuilder } from "@angular/forms";
import { take } from "rxjs/operators";
import { TimelineMax, Power2, Back, gsap } from "gsap";
import { CoreService } from "../../../core/services/core.service";
var RbComponent = /** @class */ (function () {
    function RbComponent(formBuilder, translate, utilityService, coreService) {
        this.formBuilder = formBuilder;
        this.translate = translate;
        this.utilityService = utilityService;
        this.coreService = coreService;
        this.marketScenarioOptions = [];
        this.subOptions = {};
        this.currentSubOptions = [];
        this.activeScenario = "marketBased";
        this.activeSubOption = "default";
        this.formGroup = this.formBuilder.group({
            marketScenarioRadio: new FormControl("marketBased"),
        });
    }
    RbComponent.prototype.ngOnInit = function () {
        var _this = this;
        //this.fetchAnimatedImages(SVG.rb_MARKET_UP_DOWN);
        this.utilityService.showLoader();
        this.translate
            .get("rbData")
            .pipe(take(1))
            .subscribe(function (data) {
            _this.utilityService.hideLoader();
            if (data && data.marketScenarios && data.marketScenarios.length != 0) {
                _this.marketScenarioOptions = [
                    { value: data.marketScenarios[0], id: "marketBased" },
                    { value: data.marketScenarios[1], id: "rateBased" },
                    { value: data.marketScenarios[2], id: "needBased" },
                ];
                _this.subOptions = {
                    marketBased: data.marketBased,
                    rateBased: data.rateBased,
                    needBased: data.needBased,
                };
                _this.updateButtons(_this.subOptions["marketBased"]["optionValues"]);
            }
        });
        this.formGroup.controls.marketScenarioRadio.valueChanges.subscribe(function (value) {
            if (_this.subOptions[value]) {
                _this.updateButtons(_this.subOptions[value]["optionValues"]);
                _this.activeScenario = value;
                _this.setSubOption("default");
            }
        });
    };
    RbComponent.prototype.updateButtons = function (radioList) {
        var _this = this;
        this.currentSubOptions = [];
        radioList.forEach(function (value, index) {
            _this.currentSubOptions.push({ value: value, id: "option" + index });
        });
    };
    RbComponent.prototype.setSubOption = function (option) {
        this.activeSubOption = option;
        // this.createChartTimiline();
    };
    RbComponent.prototype.fetchAnimatedImages = function (image) {
        var _this = this;
        this.coreService.fetchSvg(image).subscribe(function (data) {
            _this.chart.nativeElement.innerHTML = data;
            switch (_this.activeScenario) {
                case "marketBased":
                    _this.initializeMarketBasedTimeLine();
                    break;
                case "rateBased":
                    _this.initializeRateBasedTimeLine();
                    break;
                case "needBased":
                    _this.initializeNeedBasedTimeLine();
                    break;
                default:
            }
        });
    };
    RbComponent.prototype.playAnimation = function (option) {
        if (option === "option0") {
            this.downTimeLine.kill();
            this.upTimeLine.play(0);
        }
        else {
            this.upTimeLine.kill();
            this.downTimeLine.play(0);
        }
        this.activeSubOption = option;
    };
    RbComponent.prototype.initializeMarketBasedTimeLine = function () {
        //Timeline for "UP" button press
        this.upTimeLine = new TimelineMax({ paused: true });
        //Timeline for "DOWN" button press
        this.downTimeLine = new TimelineMax({ paused: true });
        this.upTimeLine.addLabel("Up_start", 0);
        this.upTimeLine.addLabel("Up_one", 3);
        //Reset graph before playing
        this.upTimeLine.set("#up1_av_bubble", { y: 80 }, "Up_start");
        this.upTimeLine.set("#lock", { y: 0, opacity: 1 }, "Up_start");
        this.upTimeLine.set("#lock_arm", { y: 0, opacity: 1 }, "Up_start");
        this.upTimeLine.set("#av_bar", { scaleY: 1, transformOrigin: "50% 100%" }, "Up_start");
        this.upTimeLine.set("#bb_bar", { scaleY: 1, transformOrigin: "50% 100%" }, "Up_start");
        this.upTimeLine.set("#up1_av_bubble,#up2_bb_bubble,#down1_av_bubble,#down2_bb_bubble,#down3_bb_bubble", { opacity: 0 }, "Up_start");
        this.upTimeLine.to("#intro_bb_bubble", 0.5, { opacity: 0, ease: Power2.easeOut }, "Up_start");
        this.upTimeLine.to("#lock_arm", 0.5, { y: -10, transformOrigin: "0% 100%", ease: Back.easeIn }, "Up_start");
        this.upTimeLine.to("#lock", 0.5, { opacity: 0, ease: Power2.easeOut }, "Up_start+=.7");
        this.upTimeLine.to("#av_bar", 1.5, { scaleY: 1.2, transformOrigin: "50% 100%", ease: Power2.easeInOut }, "Up_start+=.75");
        this.upTimeLine.to("#up1_av_bubble", 1.5, { opacity: 1, y: 0, ease: Power2.easeInOut }, "Up_start+=.75");
        this.upTimeLine.set("#lock", { y: -60, ease: Power2.easeIn }, "Up_one");
        this.upTimeLine.to("#bb_bar", 1.5, { scaleY: 1.2, transformOrigin: "50% 100%", ease: Power2.easeInOut }, "Up_one");
        this.upTimeLine.to("#lock", 0.5, { opacity: 1, ease: Power2.easeIn }, "Up_one+=1");
        this.upTimeLine.to("#lock_arm", 1, { y: 0, transformOrigin: "0% 100%", ease: Back.easeOut }, "Up_one+=1.5");
        this.upTimeLine.to("#up2_bb_bubble", 0.5, { opacity: 1, ease: Power2.easeIn }, "Up_one+=1.5");
        this.downTimeLine.addLabel("Dwn_start", 0);
        this.downTimeLine.addLabel("Dwn_one", 3);
        this.downTimeLine.addLabel("Dwn_two", 7.0);
        //Reset graph before playing
        this.downTimeLine.set("#down1_av_bubble", { y: -80 }, "Dwn_reset");
        this.downTimeLine.set("#down3_bb_bubble", { y: 16 }, "Dwn_reset");
        this.downTimeLine.set("#lock", { y: -60, opacity: 1 }, "Dwn_reset");
        this.downTimeLine.set("#lock_arm", { y: 0, opacity: 1 }, "Dwn_reset");
        this.downTimeLine.set("#av_bar", { scaleY: 1, transformOrigin: "50% 100%" }, "Dwn_start");
        this.downTimeLine.set("#bb_bar", { scaleY: 1.2, transformOrigin: "50% 100%" }, "Dwn_start");
        this.downTimeLine.set("#intro_bb_bubble,#up1_av_bubble,#up2_bb_bubble,#down1_av_bubble,#down2_bb_bubble,#down3_bb_bubble", { opacity: 0 }, "Dwn_start");
        this.downTimeLine.to("#av_bar", 1.5, { scaleY: 0.8, transformOrigin: "50% 100%", ease: Power2.easeInOut }, "Dwn_start+=.5");
        this.downTimeLine.to("#bb_bar", 1.5, { scaleY: 1.2, transformOrigin: "50% 100%", ease: Power2.easeInOut }, "Dwn_start+=.5");
        this.downTimeLine.to("#down1_av_bubble", 1.5, { opacity: 1, y: 0, ease: Power2.easeInOut }, "Dwn_start+=.5");
        this.downTimeLine.to("#down2_bb_bubble", 0.5, { opacity: 1, ease: Power2.easeIn }, "Dwn_one");
        this.downTimeLine.to("#down2_bb_bubble,#down1_av_bubble", 0.5, { opacity: 0, ease: Power2.easeOut }, "Dwn_two");
        this.downTimeLine.to("#bb_bar", 1, { scaleY: 1.26, transformOrigin: "50% 100%", ease: Power2.easeInOut }, "Dwn_two+=.5");
        this.downTimeLine.to("#down3_bb_bubble", 1, { opacity: 1, y: 0, ease: Power2.easeInOut }, "Dwn_two+=.5");
    };
    RbComponent.prototype.initializeRateBasedTimeLine = function () {
        //Timeline for "Rising" button press
        this.upTimeLine = new TimelineMax({ paused: true });
        //Timeline for "Flat" button press
        this.downTimeLine = new TimelineMax({ paused: true });
        //Adjust for time between steps
        this.upTimeLine.addLabel("rise_start", 0);
        this.upTimeLine.addLabel("rise_one", 5);
        this.upTimeLine.addLabel("rise_two", 10);
        //Reset graph before playing
        this.upTimeLine.set("#av_bar", { scaleY: 1, transformOrigin: "50% 100%" }, "rise_start");
        this.upTimeLine.set("#bb_bar", { scaleY: 1, transformOrigin: "50% 100%" }, "rise_start");
        this.upTimeLine.set("#rising_av_bubble,#rising1_bb_bubble, #rising2_bb_bubble, #rising3_bb_bubble, #rising1_dashGroup, #rising2_dashGroup, #rising3_dashGroup", { opacity: 0, y: 0 }, "rise_start");
        this.upTimeLine.set("#flat_av_bubble,#flat1_bb_bubble, #flat2_bb_bubble, #flat3_bb_bubble, #flat1_dashGroup, #flat2_dashGroup, #flat3_dashGroup", { opacity: 0, y: 0 }, "rise_start");
        this.upTimeLine.set("#init_bubble", { opacity: 1 }, "rise_start");
        this.upTimeLine.to("#init_bubble", 0.5, { opacity: 0, ease: Power2.easeOut }, "rise_start");
        this.upTimeLine.to("#rising_av_bubble, #generalInfo", 0.5, { opacity: 1, ease: Power2.easeIn }, "rise_start+=.75");
        this.upTimeLine.to("#rising_av_bubble", 1.5, { y: 40, ease: Power2.easeInOut }, "rise_start+=.75");
        this.upTimeLine.to("#av_bar", 1.5, { scaleY: 0.85, transformOrigin: "50% 100%", ease: Power2.easeInOut }, "rise_start+=.75");
        this.upTimeLine.to("#bb_bar", 1.5, { scaleY: 1.5, transformOrigin: "50% 100%", ease: Power2.easeInOut }, "rise_start+=.75");
        this.upTimeLine.fromTo("#rising1_bb_bubble", 1.5, { opacity: 0, y: 100 }, { opacity: 1, y: 0, ease: Power2.easeInOut }, "rise_start+=.75");
        this.upTimeLine.fromTo("#rising1_dashGroup", 0.5, { opacity: 0 }, { opacity: 1, ease: Power2.easeInOut }, "rise_start+=2.25");
        this.upTimeLine.fromTo("#rising1_dashGroup_dash", 0.5, { "stroke-dashoffset": -20 }, { "stroke-dashoffset": 0, ease: Power2.easeInOut }, "rise_start+=2.25");
        this.upTimeLine.to("#rising1_bb_bubble", 0.5, { opacity: 0, ease: Power2.easeOut }, "rise_one");
        this.upTimeLine.to("#rising_av_bubble", 1.5, { y: 52, ease: Power2.easeInOut }, "rise_one+=.75");
        this.upTimeLine.to("#av_bar", 1.5, { scaleY: 0.79, transformOrigin: "50% 100%", ease: Power2.easeInOut }, "rise_one+=.75");
        this.upTimeLine.to("#bb_bar", 1.5, { scaleY: 1.68, transformOrigin: "50% 100%", ease: Power2.easeInOut }, "rise_one+=.75");
        this.upTimeLine.fromTo("#rising2_bb_bubble", 1.5, { opacity: 0, y: 40 }, { opacity: 1, y: 0, ease: Power2.easeInOut }, "rise_one+=.75");
        this.upTimeLine.fromTo("#rising2_dashGroup", 0.5, { opacity: 0 }, { opacity: 1, ease: Power2.easeInOut }, "rise_one+=2.25");
        this.upTimeLine.fromTo("#rising2_dashGroup_dash", 0.5, { "stroke-dashoffset": -20 }, { "stroke-dashoffset": 0, ease: Power2.easeInOut }, "rise_one+=2.25");
        this.upTimeLine.to("#rising2_bb_bubble", 0.5, { opacity: 0, ease: Power2.easeOut }, "rise_two");
        this.upTimeLine.to("#rising_av_bubble", 1.5, { y: 61, ease: Power2.easeInOut }, "rise_two+=.75");
        this.upTimeLine.to("#av_bar", 1.5, { scaleY: 0.75, transformOrigin: "50% 100%", ease: Power2.easeInOut }, "rise_two+=.75");
        this.upTimeLine.to("#bb_bar", 1.5, { scaleY: 1.95, transformOrigin: "50% 100%", ease: Power2.easeInOut }, "rise_two+=.75");
        this.upTimeLine.fromTo("#rising3_bb_bubble", 1.5, { opacity: 0, y: 60 }, { opacity: 1, y: 0, ease: Power2.easeInOut }, "rise_two+=.75");
        this.upTimeLine.fromTo("#rising3_dashGroup", 0.5, { opacity: 0 }, { opacity: 1, ease: Power2.easeInOut }, "rise_two+=2.25");
        this.upTimeLine.fromTo("#rising3_dashGroup_dash", 0.5, { "stroke-dashoffset": -20 }, { "stroke-dashoffset": 0, ease: Power2.easeInOut }, "rise_two+=2.25");
        //Adjust for time between steps
        this.downTimeLine.addLabel("flat_start", 0);
        this.downTimeLine.addLabel("flat_one", 5);
        this.downTimeLine.addLabel("flat_two", 10.0);
        //Reset graph before playing
        this.downTimeLine.set("#av_bar", { scaleY: 1, transformOrigin: "50% 100%" }, "flat_start");
        this.downTimeLine.set("#bb_bar", { scaleY: 1, transformOrigin: "50% 100%" }, "flat_start");
        this.downTimeLine.set("#rising_av_bubble,#rising1_bb_bubble, #rising2_bb_bubble, #rising3_bb_bubble, #rising1_dashGroup, #rising2_dashGroup, #rising3_dashGroup", { opacity: 0, y: 0 }, "flat_start");
        this.downTimeLine.set("#flat_av_bubble,#flat1_bb_bubble, #flat2_bb_bubble, #flat3_bb_bubble, #flat1_dashGroup, #flat2_dashGroup, #flat3_dashGroup", { opacity: 0, y: 0 }, "flat_start");
        this.downTimeLine.set("#init_bubble", { opacity: 1 }, "flat_start");
        this.downTimeLine.to("#init_bubble", 0.5, { opacity: 0, ease: Power2.easeOut }, "flat_start");
        this.downTimeLine.to("#flat_av_bubble, #generalInfo", 0.5, { opacity: 1, ease: Power2.easeIn }, "flat_start+=.75");
        this.downTimeLine.to("#flat_av_bubble", 1.5, { y: 40, ease: Power2.easeInOut }, "flat_start+=.75");
        this.downTimeLine.to("#av_bar", 1.5, { scaleY: 0.83, transformOrigin: "50% 100%", ease: Power2.easeInOut }, "flat_start+=.75");
        this.downTimeLine.to("#bb_bar", 1.5, { scaleY: 1.23, transformOrigin: "50% 100%", ease: Power2.easeInOut }, "flat_start+=.75");
        this.downTimeLine.fromTo("#flat1_bb_bubble", 1.5, { opacity: 0, y: 50 }, { opacity: 1, y: 0, ease: Power2.easeInOut }, "flat_start+=.75");
        this.downTimeLine.fromTo("#flat1_dashGroup", 0.5, { opacity: 0 }, { opacity: 1, ease: Power2.easeInOut }, "flat_start+=2.25");
        this.downTimeLine.fromTo("#flat1_dashGroup_dash", 0.5, { "stroke-dashoffset": -20 }, { "stroke-dashoffset": 0, ease: Power2.easeInOut }, "flat_start+=2.25");
        this.downTimeLine.to("#flat1_bb_bubble", 0.5, { opacity: 0, ease: Power2.easeOut }, "flat_one");
        this.downTimeLine.to("#flat_av_bubble", 1.5, { y: 45, ease: Power2.easeInOut }, "flat_one+=.75");
        this.downTimeLine.to("#av_bar", 1.5, { scaleY: 0.81, transformOrigin: "50% 100%", ease: Power2.easeInOut }, "flat_one+=.75");
        this.downTimeLine.to("#bb_bar", 1.5, { scaleY: 1.41, transformOrigin: "50% 100%", ease: Power2.easeInOut }, "flat_one+=.75");
        this.downTimeLine.fromTo("#flat2_bb_bubble", 1.5, { opacity: 0, y: 40 }, { opacity: 1, y: 0, ease: Power2.easeInOut }, "flat_one+=.75");
        this.downTimeLine.fromTo("#flat2_dashGroup", 0.5, { opacity: 0 }, { opacity: 1, ease: Power2.easeInOut }, "flat_one+=2.25");
        this.downTimeLine.fromTo("#flat2_dashGroup_dash", 0.5, { "stroke-dashoffset": -20 }, { "stroke-dashoffset": 0, ease: Power2.easeInOut }, "flat_one+=2.25");
        this.downTimeLine.to("#flat2_bb_bubble", 0.5, { opacity: 0, ease: Power2.easeOut }, "flat_two");
        this.downTimeLine.to("#flat_av_bubble", 1.5, { y: 50, ease: Power2.easeInOut }, "flat_two+=.75");
        this.downTimeLine.to("#av_bar", 1.5, { scaleY: 0.78, transformOrigin: "50% 100%", ease: Power2.easeInOut }, "flat_two+=.75");
        this.downTimeLine.to("#bb_bar", 1.5, { scaleY: 1.77, transformOrigin: "50% 100%", ease: Power2.easeInOut }, "flat_two+=.75");
        this.downTimeLine.fromTo("#flat3_bb_bubble", 1.5, { opacity: 0, y: 75 }, { opacity: 1, y: 0, ease: Power2.easeInOut }, "flat_two+=.75");
        this.downTimeLine.fromTo("#flat3_dashGroup", 0.5, { opacity: 0 }, { opacity: 1, ease: Power2.easeInOut }, "flat_two+=2.25");
        this.downTimeLine.fromTo("#flat3_dashGroup_dash", 0.5, { "stroke-dashoffset": -20 }, { "stroke-dashoffset": 0, ease: Power2.easeInOut }, "flat_two+=2.25");
    };
    RbComponent.prototype.initializeNeedBasedTimeLine = function () {
        gsap.set("#bb_bar", { scaleY: 1.04, transformOrigin: "50% 100%" });
        //Timeline for "Partial Income" button press
        this.upTimeLine = new TimelineMax({ paused: true });
        //Timeline for "All Income" button press
        this.downTimeLine = new TimelineMax({ paused: true });
        //Adjust for time between steps
        this.upTimeLine.addLabel("Partial_start", 0);
        //Reset graph before playing
        this.upTimeLine.set("#av_bar", { scaleY: 1, transformOrigin: "50% 100%" }, "Partial_start");
        this.upTimeLine.set("#bb_bar", { scaleY: 1.04, transformOrigin: "50% 100%" }, "Partial_start");
        this.upTimeLine.set("#all_bubble,#part_bubble,#all_info,#part_info", { opacity: 0, y: 0 }, "Partial_start");
        this.upTimeLine.set("#init_bubble", { opacity: 1 }, "Partial_start");
        this.upTimeLine.to("#init_bubble", 0.5, { opacity: 0, ease: Power2.easeOut }, "Partial_start");
        this.upTimeLine.to("#part_bubble, #part_info", 0.5, { opacity: 1, ease: Power2.easeIn }, "Partial_start+=.75");
        this.upTimeLine.from("#part_bubble", 1.5, { y: -5, ease: Power2.easeInOut }, "Partial_start+=.75");
        this.upTimeLine.to("#bb_bar", 1.5, { scaleY: 1.03, transformOrigin: "50% 100%", ease: Power2.easeInOut }, "Partial_start+=.75");
        //Adjust for time between steps
        this.downTimeLine.addLabel("All_start", 0);
        //Reset graph before playing
        this.downTimeLine.set("#av_bar", { scaleY: 1, transformOrigin: "50% 100%" }, "All_start");
        this.downTimeLine.set("#bb_bar", { scaleY: 1.04, transformOrigin: "50% 100%" }, "All_start");
        this.downTimeLine.set("#all_bubble,#part_bubble,#all_info, #part_info", { opacity: 0, y: 0 }, "All_start");
        //this.downTimeLine.set("#init_bubble",{opacity:1}, "All_start");
        this.downTimeLine.to("#init_bubble", 0.5, { opacity: 0, ease: Power2.easeOut }, "All_start");
        this.downTimeLine.to("#all_bubble, #all_info", 0.5, { opacity: 1, ease: Power2.easeIn }, "All_start+=.75");
        this.downTimeLine.from("#all_bubble", 1.5, { y: -15, ease: Power2.easeInOut }, "All_start+=.75");
        this.downTimeLine.to("#bb_bar", 1.5, { scaleY: 1.0, transformOrigin: "50% 100%", ease: Power2.easeInOut }, "All_start+=.75");
    };
    return RbComponent;
}());
export { RbComponent };
