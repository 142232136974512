import { FeatureFlagDirective } from "./directives/feature-flag.directive";
import { SelectedAnnuityComponent } from "./components/selected-annuity/selected-annuity.component";
import { QuestionnaireTwoComponent } from "./components/questionnaire-two/questionnaire-two.component";
import { QuestionnaireResultComponent } from "./components/questionnaire-result/questionnaire-result.component";
import { QuestionnaireOneComponent } from "./components/questionnaire-one/questionnaire-one.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
//component lib
import { EquitableComponentLibraryModule } from "equitable-component-library";
import { HomePage } from "./components/home/home.page";
import { CoreRoutingModule } from "./core-routing.module";
import { GlossaryComponent } from "./components/glossary/glossary.component";
import { QuestionnaireComponent } from "./components/questionnaire/questionnaire.component";
import { QuestionnaireThreeComponent } from "./components/questionnaire-three/questionnaire-three.component";
import { DisclosureComponent } from "./components/disclosure/disclosure.component";
import { DisclosureRcComponent } from "./components/disclosure-rc/disclosure-rc.component";
import { DisclosureScsComponent } from "./components/disclosure-scs/disclosure-scs.component";
import { DisclosureScsPlusComponent } from "./components/disclosure-scs-plus/disclosure-scs-plus.component";
import { DisclosureScsPlus21Component } from "./components/disclosure-scs-plus21/disclosure-scs-plus21.component";
import { DisclosureScsIncomeComponent } from "./components/disclosure-scs-income/disclosure-scs-income.component";
import { DisclosureIeComponent } from "./components/disclosure-ie/disclosure-ie.component";
import { ProspectusComponent } from "./components/prospectus/prospectus.component";
import { FooterComponent } from "./components/footer/footer.component";
import { InvestorProfileComponent } from "./components/investor-profile/investor-profile.component";
import { ModalPopupComponent } from "./components/modal-popup/modal-popup.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";

@NgModule({
  declarations: [
    HomePage,
    QuestionnaireComponent,
    QuestionnaireOneComponent,
    QuestionnaireResultComponent,
    QuestionnaireTwoComponent,
    QuestionnaireThreeComponent,
    SelectedAnnuityComponent,
    DisclosureComponent,
    DisclosureRcComponent,
    DisclosureScsComponent,
    DisclosureScsPlusComponent,
    DisclosureScsPlus21Component,
    DisclosureScsIncomeComponent,
    DisclosureIeComponent,
    GlossaryComponent,
    ProspectusComponent,
    FooterComponent,
    InvestorProfileComponent,
    ModalPopupComponent,
    FeatureFlagDirective,
    PageNotFoundComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    IonicModule,
    EquitableComponentLibraryModule,
    CoreRoutingModule,
    ReactiveFormsModule,
  ],
  exports: [TranslateModule, FooterComponent, FeatureFlagDirective],
  providers: [{ provide: "Window", useValue: window }],
  entryComponents: [ModalPopupComponent],
})
export class CoreModule {}
