<ion-content>
  <div class="site-tools">
    <div class="arround-space">
      <equitable-sub-header
        title="{{ 'marketTrendIndicator.headerText' | translate }}"
        [backLinkText]="backLink"
        (backClick)="backButton()"
      ></equitable-sub-header>
    </div>

    <iframe
      class="iframe"
      [src]="marketTrendIndicatorUrl"
      frameborder="0"
      id="mtiframe"
      title="{{ 'marketTrendIndicator.headerText' | translate }}"
    ></iframe>
  </div>
  <app-footer
    isBlueFooterVisible="true"
    isWhiteFooterVisible="true"
    selectedProduct="ie"
  ></app-footer>
</ion-content>
