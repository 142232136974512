import { Component, OnInit } from "@angular/core";
import { ATTRIBUTE_TYPE } from "equitable-component-library";
import { UtilityService } from "../../../core/services/utility.service";
import { SCS_SERIES, ROUTE_PATH } from "../../../constants/constants";
import { ScsService } from "../../../scs/services/scs.service";
import { TranslateService } from "@ngx-translate/core";
import { FeatureFlagService } from "../../../core/services/feature-flag.service";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-market-trend-indicator",
  templateUrl: "./market-trend-indicator.component.html",
  styleUrls: ["./market-trend-indicator.component.scss"],
})
export class MarketTrendIndicatorComponent implements OnInit {
  series: string;
  marketTrendIndicatorUrl: string;
  backLink;
  backButtonSeries =
    this.scsService.series === SCS_SERIES.PLUS ? SCS_SERIES.PLUS : "";
  mtiURL: string;

  constructor(
    private utilityService: UtilityService,
    private scsService: ScsService,
    private translate: TranslateService,
    private flagService: FeatureFlagService
  ) {}

  ngOnInit() {
    this.mtiURL = environment.EQUITABLE_HOMEPAGE + '/retirement/products/variable-annuities/performance-cap-rates/nonav?product=Investment%20Edge%2021';

    console.log('mtiURL is: ');
    console.log(this.mtiURL);

    this.marketTrendIndicatorUrl = this.utilityService.sanitizeDom(
      ATTRIBUTE_TYPE.URL,
      this.mtiURL
    );
  }

  ionViewWillEnter() {
    this.backLink = this.translate.instant("productName.ie");
  }

  /***
   *  back  button
   ***/
  backButton() {
    this.utilityService.navigate(ROUTE_PATH.IE.TOOLS.HOME);
  }
}
