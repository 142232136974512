<!-- Web design back button -->
<ion-content>
  <article class="container-inverstor-profile site-tools">
    <div class="back-link">
      <equitable-link
        class="equitable-link back-link"
        text="{{
          ctaBackButton
            ? ctaBackButton.replace('®', '<sup>®</sup>')
            : (ctaBackButton | translate)
        }}"
        [iconName]="linkLeftIcon"
        type="dark"
        orientation="left"
        (click)="backButton()"
      ></equitable-link>
    </div>

    <ion-grid>
      <ion-row>
        <ion-col size="4" class="investor-profile-list">
          <div class="investor-title">{{ investorHeader | translate }}</div>
          <p>
            {{ investorBodyCopy | translate }}
          </p>

          <ion-list class="investor-list">
            <ion-item
              *ngFor="let item of investorData; let i = index"
              lines="none"
              class="investor-list-item"
              [ngClass]="i === listIndex ? 'active' : ''"
              (click)="displayDetail(i)"
            >
              <ion-thumbnail slot="start" class="profile-list-icon">
                <ion-img
                  [src]="item.profileImage"
                  [alt]="'item.profileTitle' | translate"
                ></ion-img>
              </ion-thumbnail>
              <ion-label class="list-profile-title">{{
                item.profileTitle | translate
              }}</ion-label>
            </ion-item>
          </ion-list>

          <equitable-link
            *ngIf="isScs == true"
            class="equitable-link"
            text="{{ 'investorProfileText.btnSaveOrLoadProfile' | translate }}"
            [iconName]="linkRightIcon"
            type="dark"
            orientation="right"
            (click)="goToButton()"
          ></equitable-link>
        </ion-col>
        <ion-col size="8" class="bg-gray-color">
          <div class="investor-profile-detail">
            <ion-list>
              <ion-item lines="none" class="investor-list-item">
                <ion-thumbnail slot="start" class="profile-list-icon">
                  <ion-img
                    src="{{ profileImage }}"
                    [alt]="profileImage"
                  ></ion-img>
                </ion-thumbnail>
                <ion-label>
                  <h1 class="profile-name">
                    {{ profileTitle | translate }}
                  </h1>
                  <div class="sub-title">{{ profileSubTitle | translate }}</div>
                </ion-label>
              </ion-item>
            </ion-list>

            <div>
              <h3 class="section-heading section-heading-quote">
                “{{ profileQuote | translate }}”
              </h3>
              <p
                class="section-content"
                *ngFor="let item of profileDescription"
              >
                {{ item | translate }}
              </p>
            </div>

            <ion-row class="row-border-top row-border-bottom">
              <ion-col
                size-xl="4"
                size-lg="12"
                size="12"
                *ngFor="let pd of profileDetail"
              >
                <h3 class="section-heading">
                  {{ pd.sectionName | translate }}
                </h3>
                <ul>
                  <li *ngFor="let sd of pd.sectionDetail; let k = index">
                    {{ sd | translate }}
                  </li>
                </ul>
              </ion-col>
              <ion-col size-xl="4" size-lg="12" size="12">
                <h3 class="section-heading">
                  {{ sectionName | translate }}
                </h3>
                <div
                  class="section-content-solution"
                  *ngFor="let cta of profileSolutionCTA; let k = index"
                >
                  <equitable-link
                    class="equitable-link related-solution-link"
                    text="{{
                      cta.ctaText.replace('®', '<sup>®</sup>') | translate
                    }}"
                    type="dark"
                    orientation="left"
                    (click)="gotoOverview(cta.ctaText)"
                  ></equitable-link>
                </div>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col size="5" *ngIf="productIndex !== ''">
                <h3 class="section-heading">
                  {{ "investorProfileText.index" | translate }}
                </h3>
                <div class="section-content-link">
                  <span
                    innerHTML=" {{
                      productIndex.replace('®', '<sup>®</sup>') | translate
                    }}"
                  ></span>
                </div>
              </ion-col>
              <ion-col size="4" *ngIf="productDuration !== ''">
                <h3 class="section-heading">
                  {{ "investorProfileText.duration" | translate }}
                </h3>
                <div class="section-content-link">
                  <span> {{ productDuration | translate }}</span>
                </div>
              </ion-col>
              <ion-col size="3" *ngIf="productProtection !== ''">
                <h3 class="section-heading">
                  {{ "investorProfileText.protection" | translate }}
                </h3>
                <div class="section-content-link">
                  <span> {{ productProtection | translate }}</span>
                </div>
              </ion-col>
            </ion-row>

            <ion-row *ngIf="scenarios !== ''" class="ion-padding-top">
              <ion-col *ngFor="let scen of scenarios; let m = index">
                <equitable-button
                  type="primary"
                  theme="light"
                  (click)="navigateSelections(scen.url, scen)"
                  >{{ scen.name | translate }}
                </equitable-button>
                <ion-img
                  *ngIf="m === scenarios.length - 1 && scenarios.length > 1"
                  src="assets/icons/info-icon-blue.svg"
                  class="icon-info-profile"
                  alt=""
                ></ion-img>
              </ion-col>
            </ion-row>
            <span *ngIf="isScs && isNotPlusGuard" class="now-viewing" 
              >{{ "scs.nowView" | translate }} {{ seriesValue }}</span
            >
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </article>
  <app-footer
    isWhiteFooterVisible="true"
    isBlueFooterVisible="true"
    activeTab="Investor_profile"
    [selectedProduct]="seriesValue"
  ></app-footer>
</ion-content>
