import { OnInit } from "@angular/core";
import { RcService } from "../../services/rc.service";
import { UtilityService } from "../../../core/services/utility.service";
import { take } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { Type } from "equitable-component-library";
var InvestmentOptionsComponent = /** @class */ (function () {
    function InvestmentOptionsComponent(rcService, utilityService, translateService) {
        this.rcService = rcService;
        this.utilityService = utilityService;
        this.translateService = translateService;
        this.chartData = [40, 60];
        this.ChartType = Type.Primary;
        this.sliderType = Type.Secondary;
    }
    InvestmentOptionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.utilityService.showLoader();
        this.rcService
            .fetchInvestmentOptionsData()
            .pipe(take(1))
            .subscribe(function (chartData) {
            _this.utilityService.hideLoader();
            _this.investmentData = chartData.allocations;
            _this.equities = _this.investmentData[4] && _this.investmentData[4].risk;
            _this.portfolios =
                _this.investmentData[4] && _this.investmentData[4].portfolios;
            _this.fixedIncome = _this.calcFixedIncome(_this.equities);
            _this.chartData = [_this.fixedIncome, _this.equities];
            _this.value = 4;
        });
    };
    InvestmentOptionsComponent.prototype.calcFixedIncome = function (value) {
        return 100 - value;
    };
    InvestmentOptionsComponent.prototype.onChange = function ($event) {
        var _this = this;
        this.investmentData.filter(function (element, index) {
            if (index === $event.detail.value) {
                _this.equities = element.risk;
                _this.fixedIncome = _this.calcFixedIncome(_this.equities);
                _this.chartData = [_this.fixedIncome, _this.equities];
                _this.portfolios = element.portfolios;
            }
        });
    };
    InvestmentOptionsComponent.prototype.onClickDownload = function (url) {
        this.utilityService.visitEquitableMicrosite(url);
    };
    return InvestmentOptionsComponent;
}());
export { InvestmentOptionsComponent };
