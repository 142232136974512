import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class FeatureFlagService {
  public featureFlags!: {
    
    scsincome:{
      productHomepage: boolean;
      toolsHomePage: boolean;
      series: string;
      tools: {
        howSIOWorks: boolean;
        historicalPerformance: boolean;
        performanceCapRate: boolean;
        preserveLegacy: boolean;
        protectIncome: boolean;
        createAReport: boolean;
        productOverview: boolean;
      };
    };
    scs: {
      productHomepage: boolean;
      toolsHomePage: boolean;
      series: string;
      tools: {
        howSIOWorks: boolean;
        historicalPerformance: boolean;
        performanceCapRate: boolean;
        createAReport: boolean;
        productOverview: boolean;
        investorProfile: {
          defaultProfiles: boolean;
          customProfiles: boolean;
        };
      };
    };
    ie:{
      productHomepage: boolean;
      toolsHomePage: boolean;
      series: string;
      tools: {
        howSIOWorks: boolean;
        historicalPerformance: boolean;
        performanceCapRate: boolean;
        createAReport: boolean;
        productOverview: boolean;
      };
    };
 
    rc: boolean;
    demo: boolean;
    home: boolean;
    questionnaire: boolean;
    isIR: boolean;
    usePie: boolean;
    isNotPrimerica: boolean;
    isPrimerica: boolean;
    isNational: boolean;
    isPlusGuard: boolean;
    profile: string;
    report: string;
    currentSeries: string;
    prospectusPDF: string;
    marketTrendIndicator: string;
    marketTrendIndicator_PLUS: string;
    marketTrendIndicator_PLUS21: "https://equitable.com/scsplusnonav";
    marketTrendIndicator_INCOMEB: string;
    name: string;
  };

  constructor(private httpClient: HttpClient) {}

  public async loadRemoteConfig(): Promise<void> {
    const remoteConfig = await this.httpClient
      .get<FeatureFlagService>(environment.config)
      .toPromise();
    Object.assign(this, remoteConfig);
  }

  featureOff(featureName: string) {
    // Read the value from the config service
    // eslint-disable-next-line no-prototype-builtins
    if (this.featureFlags.hasOwnProperty(featureName)) {
      return !this.featureFlags[featureName];
    }
    return true; // if feature not found, default to turned off
  }

  featureOn(featureName: string) {
    return !this.featureOff(featureName);
  }
}
