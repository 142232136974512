import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DiscoverComponent } from "./components/discover/discover.component";
import { EvaluateComponent } from "./components/evaluate/evaluate.component";
import { HowitworksComponent } from "./components/howitworks/howitworks.component";
import { HowSioWorksComponent } from "./components/how-sio-works/how-sio-works.component";
import { TaxSavingsComponent } from "./components/tax-savings/tax-savings.component";
import { EquitableComponentLibraryModule } from "equitable-component-library";
import { IonicModule } from "@ionic/angular";
import { IeRoutingModule } from "./ie-routing.module";
import { TranslateModule } from "@ngx-translate/core";
import { IeToolsComponent } from "./components/ie-tools/ie-tools.component";
import { IeAdvancedToolResourcesDashboardComponent } from "./components/ie-advanced-tool-resources-dashboard/ie-advanced-tool-resources-dashboard.component";
import { HowitworksToolComponent } from "./components/howitworks-tool/howitworks-tool.component";
import { ReportIeComponent } from "./components/report-ie/report-ie.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CoreModule } from "../core/core.module";
import { IeService } from "./services/ie.service";
import { BuildWealthComponent } from './components/build-wealth/build-wealth.component'; // rename to diverisifcation
import { FundFocusComponent } from './components/fund-focus/fund-focus.component';
import { DiversificationComponentIE } from "./components/diversification/diversification.component";
import { SioComponent } from "./components/sio/sio.component";
import { CustomProfileComponent } from "./components/custom-profile/custom-profile.component";
import { SioPopupComponent } from "./components/sio-popup/sio-popup.component";
import { UtilizeIeComponent } from "./components/utilize-ie/utilize-ie.component";
import { ScsPiePicturesComponent } from "./components/scs-pie-pictures/scs-pie-pictures.component";
import { MarketTrendIndicatorComponent } from "./components/market-trend-indicator/market-trend-indicator.component"

@NgModule({
  declarations: [
    DiscoverComponent,
    EvaluateComponent,
    HowitworksComponent,
    HowSioWorksComponent,
    SioComponent,
    SioPopupComponent,
    TaxSavingsComponent,
    ScsPiePicturesComponent,
    MarketTrendIndicatorComponent,
    IeToolsComponent,
    UtilizeIeComponent,
    IeAdvancedToolResourcesDashboardComponent,
    HowitworksToolComponent,
    ReportIeComponent,
    BuildWealthComponent,
    FundFocusComponent,
    DiversificationComponentIE,
    CustomProfileComponent
  ],
  providers: [IeService],
    // { provide: "Window", useValue: window }],
  imports: [
    CommonModule,
    TranslateModule,
    EquitableComponentLibraryModule,
    IonicModule.forRoot(),
    IeRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
  ],
  exports: [TranslateModule],
  entryComponents: [SioPopupComponent]
})
export class IeModule { }
