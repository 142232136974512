import { HttpClient, HttpHeaders } from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { BUTTON_ROLE } from "../../constants/constants";
import { UtilityService } from "./utility.service";
import { TranslateService } from "@ngx-translate/core";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./utility.service";
import * as i3 from "@ngx-translate/core";
var HttpRequestService = /** @class */ (function () {
    function HttpRequestService(httpClient, utilityService, translate) {
        var _this_1 = this;
        this.httpClient = httpClient;
        this.utilityService = utilityService;
        this.translate = translate;
        this.handleError = function (data, isBluk) {
            console.log("ERROR", data);
            console.log("ERROR", isBluk);
            if (data.status != 200) {
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                var _this_2 = _this_1;
                if (!isBluk) {
                    _this_1.utilityService.showAlert({
                        message: _this_1.translate.instant("general.serverError"),
                        title: "",
                        buttons: [
                            {
                                text: _this_1.translate.instant("general.ok"),
                                role: BUTTON_ROLE.OK,
                                handler: function () {
                                    _this_2.utilityService.alertPresent = false;
                                },
                            },
                        ],
                    });
                }
            }
            // this.utilityService.navigate(ROUTE_PATH.HOME);
            _this_1.utilityService.hideLoader();
            throw throwError(data.error);
        };
    }
    HttpRequestService.prototype.getHttpHeaders = function () {
        return new HttpHeaders({
            "Content-Type": "application/json",
        });
    };
    HttpRequestService.prototype.request = function (requestOption) {
        var _this_1 = this;
        return this.httpClient
            .request(requestOption.method, requestOption.url, {
            headers: requestOption.headers
                ? requestOption.headers
                : this.getHttpHeaders(),
            body: requestOption.body,
            params: requestOption.params,
            observe: "response",
            responseType: requestOption.isblob
                ? "blob"
                : "text",
        })
            .pipe(map(function (data) {
            var body = data.body;
            if (body) {
                if (typeof body == "string") {
                    try {
                        data.body = JSON.parse(body);
                    }
                    catch (e) {
                        data.body = body;
                    }
                }
                return data;
            }
            else {
                _this_1.handleError(data, requestOption.isBluk);
            }
        }), catchError(function (data) {
            _this_1.handleError(data, requestOption.isBluk);
            throw throwError(data.error);
        }));
    };
    HttpRequestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpRequestService_Factory() { return new HttpRequestService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UtilityService), i0.ɵɵinject(i3.TranslateService)); }, token: HttpRequestService, providedIn: "root" });
    return HttpRequestService;
}());
export { HttpRequestService };
