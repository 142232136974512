import { QuestionnaireService } from "../../services/questionnaire.service";
import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { Theme, Orientation, Icon } from "equitable-component-library";

@Component({
  selector: "app-questionnaire-two",
  templateUrl: "./questionnaire-two.component.html",
  styleUrls: ["./questionnaire-two.component.scss"],
})
export class QuestionnaireTwoComponent implements OnInit {
  //Inputs
  @Input() step;
  //Outputs
  @Output() selectedOption = new EventEmitter<{}>();
  @Output() backButtonClicked = new EventEmitter<number>();
  //Class Variables
  equitableStrips = [];
  type = Theme.Dark;
  backIcon = Icon.BackButton;
  orientation = Orientation.Left;
  selectedStrips = [];
  stripPriorities = [];
  disableContinue = true;

  constructor(private questionnaireService: QuestionnaireService) {}

  ngOnInit() {
    this.questionnaireService.getQuestionnairePageData(2).subscribe((data) => {
      this.equitableStrips = data;
      this.initStripPriority();
    });
  }

  selectStrip(index) {
    if (this.selectedStrips.length < 3 && this.stripPriorities[index] == 0) {
      this.selectedStrips.push(index);
      this.stripPriorities[index] = this.selectedStrips.length;
    }
    if (this.selectedStrips.length >= 1) this.disableContinue = false;
  }

  submitSelections() {
    this.selectedOption.emit({
      step: this.step,
      index: this.selectedStrips[0],
    });
  }

  clearSelections() {
    this.selectedStrips = [];
    this.initStripPriority();
  }

  initStripPriority() {
    for (let i = 0; i < this.equitableStrips.length; i++)
      this.stripPriorities[i] = 0;
    this.disableContinue = true;
  }

  backButton() {
    this.backButtonClicked.emit(this.step);
  }
}
