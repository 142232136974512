import { OnInit, ChangeDetectorRef } from "@angular/core";
import { UtilityService } from "../../../core/services/utility.service";
import { Validators, FormBuilder, FormControl, } from "@angular/forms";
import { LinkIcon, Icon } from "equitable-component-library";
import { CUSTOMIZATION_TYPE, ROUTE_PATH, SCSINCOME_SERIES, BUTTON_ROLE, REPORT_KEY, CHARACTER_LIMIT, } from "../../../constants/constants";
import { Profile } from "../../../core/models/app.models";
import { ScsIncomeService } from "../../services/scsincome.service";
import { TranslateService } from "@ngx-translate/core";
import { CoreService } from "../../../core/services/core.service";
import { pairwise } from "rxjs/operators";
import { FeatureFlagService } from "../../../core/services/feature-flag.service";
import { TealiumUtagService } from '../../../tealium/utag.service';
var CustomProfileComponent = /** @class */ (function () {
    /***
     *  Constructor
     ***/
    function CustomProfileComponent(utilityService, formBuilder, scsincomeService, coreService, translateService, cd, featureService, tealium) {
        this.utilityService = utilityService;
        this.formBuilder = formBuilder;
        this.scsincomeService = scsincomeService;
        this.coreService = coreService;
        this.translateService = translateService;
        this.cd = cd;
        this.featureService = featureService;
        this.tealium = tealium;
        this.linkRightIcon = LinkIcon.RightIcon;
        this.linkLeftIcon = LinkIcon.LeftIcon;
        this.AddProfile = Icon.AddProfile;
        this.customProfileData = [];
        this.listIndex = 0;
        this.currentProfile = new Profile();
        this.customContents = [];
        this.maxlength = CHARACTER_LIMIT.INPUT;
        this.maxlengthTextArea = CHARACTER_LIMIT.TEXTAREA;
        this.backButtonSeries = this.scsincomeService.series == SCSINCOME_SERIES.B ? SCSINCOME_SERIES.ADV : "";
        this.isEditMode = false;
        this.showEdit = false;
        this.series = this.scsincomeService.series;
    }
    /***
     *  Page load
     ***/
    CustomProfileComponent.prototype.ngOnInit = function () {
        if (this.scsincomeService.series === SCSINCOME_SERIES.B) {
            this.backLink = this.translateService.instant("createReport.backButtonTextIncome");
        }
        else if (this.scsincomeService.series === SCSINCOME_SERIES.ADV) {
            this.backLink = this.translateService.instant("createReport.backButtonTextIncome");
        }
        else {
            this.backLink = this.translateService.instant("createReport.backButtonTextIncome");
        }
        //build form
        this.buildForm();
        //page load data
        this.getPageData();
        //load all profile
        this.loadProfiles();
        // this.tealium.link({
        //   tealium_event: 'link',
        //   event_category: 'BodyClick',
        //   event_name: 'Retirement Guide Clicks',
        //   event_label: 'Click on Custom Profile'
        // })
    };
    /***
     *  page Load
     ***/
    CustomProfileComponent.prototype.ionViewWillEnter = function () {
        if (this.scsincomeService.series === SCSINCOME_SERIES.B) {
            this.backLink = this.translateService.instant("createReport.backButtonTextIncome");
        }
        else if (this.scsincomeService.series === SCSINCOME_SERIES.ADV) {
            this.backLink = this.translateService.instant("createReport.backButtonTextIncome");
        }
        else {
            this.backLink = this.translateService.instant("createReport.backButtonTextIncome");
        }
        //build form
        this.buildForm();
        //page load data
        this.getPageData();
        //load all profile
        this.loadProfiles();
    };
    /***
     *  Build all form controls
     ***/
    CustomProfileComponent.prototype.buildForm = function () {
        this.profile = this.formBuilder.group({
            profileName: new FormControl(this.currentProfile.name, Validators.required),
            profileComment: new FormControl(this.currentProfile.comment),
        });
        this.profile.valueChanges.pipe(pairwise()).subscribe(function (res) { });
    };
    /***
     *  Reset form
     ***/
    CustomProfileComponent.prototype.resetForm = function () {
        var _this_1 = this;
        this.profile.get("profileName").reset();
        this.profile.get("profileComment").reset();
        //enable which one loaded
        this.customContents.forEach(function (element) {
            if (_this_1.currentProfile[element.reportKey]) {
                element.link.label = _this_1.investorProfileText.edit;
            }
            else {
                element.link.label =
                    _this_1.customProfileData.length > 0
                        ? _this_1.investorProfileText.customize
                        : "";
            }
        });
        this.btnSaveProfile = this.investorProfileText.btnSaveProfile;
        this.showEdit = false;
        this.isEditMode = false;
    };
    /***
     *  Back button
     ***/
    CustomProfileComponent.prototype.backButton = function () {
        this.utilityService.navigate(ROUTE_PATH.SCS.TOOLS.HOME);
    };
    /***
     *  function for load all saved profile locally
     ***/
    CustomProfileComponent.prototype.loadProfiles = function () {
        var _this_1 = this;
        this.scsincomeService.getProfile().subscribe(function (profiles) {
            profiles = profiles ? profiles : new Array();
            profiles = profiles.filter(function (x) {
                return x.currentSerie === _this_1.scsincomeService.series &&
                    x.name !== CUSTOMIZATION_TYPE.REPORT;
            });
            if (profiles && profiles.length > 0) {
                _this_1.customProfileData = profiles;
                if (_this_1.coreService.profile.getValue().name !== "") {
                    var savedProfile = profiles.find(function (x) { return x.name === _this_1.coreService.profile.getValue().name; });
                    if (savedProfile) {
                        _this_1.currentProfile = savedProfile;
                    }
                    else {
                        _this_1.currentProfile = profiles[0];
                    }
                }
                else {
                    _this_1.currentProfile = profiles[0];
                }
                _this_1.listIndex = profiles.findIndex(function (x) { return x.name === _this_1.currentProfile.name; });
                _this_1.displayDetail(_this_1.currentProfile, _this_1.listIndex);
            }
            else {
                _this_1.customProfileData = new Array();
                _this_1.currentProfile = new Profile();
                _this_1.resetForm();
            }
            _this_1.cd.detectChanges();
        });
    };
    /***
     *  get master page data for resources
     ***/
    CustomProfileComponent.prototype.getPageData = function () {
        var _this_1 = this;
        this.translateService
            .get("investorProfileText")
            .subscribe(function (investorProfileText) {
            _this_1.investorProfileText = investorProfileText;
            _this_1.btnSaveProfile = investorProfileText.btnSaveProfile;
        });
        //add custome content its dynamic based on series B,C, ADV, PLUS
        this.translateService.get("createReport").subscribe(function (createReport) {
            var customContents = [
                {
                    description: createReport.checkboxDescription1ScsIncome,
                    link: {
                        label: "",
                        path: "/" + ROUTE_PATH.SCS.TOOLS.HOW_SIO_WORKS_DETAILS,
                    },
                    imagePath: "./assets/images/report-checkbox.jpg",
                    reportKey: REPORT_KEY.HOW_SIO_WORKS,
                },
                /*   {
                    description: createReport.checkboxDescription2,
                    link: {
                      label: "",
                      path:
                        "/" +
                        ROUTE_PATH.SCS.TOOLS.HISTORICAL_PERFORMANCE +
                        "/" +
                        ROUTE_PATH.SCS.TOOLS.SEGMENT_PERFORMANCE,
                    },
                    imagePath: "./assets/images/report-checkbox2.jpg",
                    reportKey: REPORT_KEY.HISTORICAL_PERFORMANCE,
                  }, */
                {
                    description: createReport.checkboxDescription3,
                    link: {
                        label: "",
                        path: "/" +
                            ROUTE_PATH.SCS.TOOLS.HISTORICAL_PERFORMANCE +
                            "/" +
                            ROUTE_PATH.SCS.TOOLS.HISTORY_GUIDE,
                    },
                    imagePath: "./assets/images/report-checkbox3.jpg",
                    reportKey: REPORT_KEY.HISTORY_GUIDE,
                },
                {
                    description: createReport.checkboxDescription3,
                    link: {
                        label: "",
                        path: "/" + ROUTE_PATH.SCSINCOME.TOOLS.HISTORICAL_PERFORMANCE_PLUS,
                    },
                    imagePath: "./assets/images/report-checkbox4.jpg",
                    reportKey: REPORT_KEY.HISTORICAL_PERFORMANCE_PLUS,
                },
            ];
            //sppecial fix for primerica
            if (_this_1.featureService.featureFlags.report === "PRIMERICA_REPORT") {
                customContents[2].imagePath = "./assets/images/report-checkbox4.jpg";
                customContents[2].link.label = _this_1.scsincomeService.report
                    .historyMayBeAGuide
                    ? _this_1.translateService.instant("createReport.edit")
                    : _this_1.translateService.instant("createReport.customize");
                customContents[2].reportKey = "historyMayBeAGuide";
            }
            //if series plus then only how-sio-works and pie charts
            if (_this_1.scsincomeService.series === SCSINCOME_SERIES.B ||
                _this_1.scsincomeService.series === SCSINCOME_SERIES.ADV) {
                customContents.splice(3, 1);
                customContents.splice(1, 1);
            }
            else if (_this_1.scsincomeService.series === SCSINCOME_SERIES.B) {
                customContents.splice(1, 2);
            }
            else if (_this_1.scsincomeService.series === SCSINCOME_SERIES.ADV) {
                customContents.splice(1, 2);
            }
            _this_1.customContents = customContents;
        });
    };
    /***
     *  add Profile button click event
     ***/
    CustomProfileComponent.prototype.addProfile = function () {
        this.isEditMode = false;
        this.showEdit = false;
        this.currentProfile = new Profile();
        this.resetForm();
        this.btnSaveProfile = this.translateService.instant("investorProfileText.btnSaveProfile");
    };
    CustomProfileComponent.prototype.editProfile = function () {
        this.isEditMode = true;
        this.oldValue = this.currentProfile.name;
        this.btnSaveProfile = this.translateService.instant("investorProfileText.btnSaveProfile");
    };
    /***
     *  delete Profile from storage
     ***/
    CustomProfileComponent.prototype.deleteProfile = function () {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        var _this = this;
        if (this.currentProfile.name !== "") {
            this.utilityService.showAlert({
                message: this.investorProfileText.deleteProfile,
                title: "",
                buttons: [
                    {
                        role: BUTTON_ROLE.OK,
                        text: this.investorProfileText.ok,
                        handler: function () {
                            _this.scsincomeService
                                .deleteProfile(_this.currentProfile.name)
                                .subscribe(function () {
                                //remove if currently loaded profile in delete profile
                                if (_this.scsincomeService.currentProfile == _this.currentProfile.name) {
                                    _this.coreService.profile.next({
                                        name: "",
                                        type: CUSTOMIZATION_TYPE.REPORT,
                                        profile: new Profile(),
                                    });
                                }
                                //reset form
                                _this.resetForm();
                                //load profile for current pages
                                _this.loadProfiles();
                            });
                        },
                    },
                    {
                        role: BUTTON_ROLE.CANCEL,
                        text: this.investorProfileText.cancel,
                    },
                ],
            });
        }
    };
    /***
     *  Show current profile when user tap on item
     ***/
    CustomProfileComponent.prototype.displayDetail = function (profile, index) {
        var _this_1 = this;
        this.isEditMode = false;
        this.showEdit = true;
        this.listIndex = index;
        this.profile.get("profileName").setValue(profile.name);
        this.profile.get("profileComment").setValue(profile.comment);
        //set text
        this.currentProfile = profile;
        this.btnSaveProfile = this.translateService.instant("investorProfileText.btnLoadProfile");
        //enable which one loaded
        this.customContents.forEach(function (element) {
            if (_this_1.currentProfile[element.reportKey]) {
                element.link.label = _this_1.investorProfileText.edit;
            }
            else {
                element.link.label = _this_1.investorProfileText.customize;
            }
        });
    };
    /***
     *  Save Profile in ionic storage for future persepective
     ***/
    CustomProfileComponent.prototype.saveProfile = function () {
        var _this_1 = this;
        if (this.btnSaveProfile ==
            this.translateService.instant("investorProfileText.btnLoadProfile")) {
            this.scsincomeService.currentProfile = this.currentProfile.name;
            this.scsincomeService.customizationType = CUSTOMIZATION_TYPE.PROFILE;
            this.coreService.profile.next({
                name: this.currentProfile.name,
                type: CUSTOMIZATION_TYPE.PROFILE,
                profile: this.currentProfile,
            });
        }
        else {
            if (this.profile.valid) {
                this.showEdit = true;
                this.scsincomeService.customizationType = CUSTOMIZATION_TYPE.PROFILE;
                var profile_1 = new Profile();
                this.scsincomeService.getProfile().subscribe(function (profiles) {
                    profiles = profiles ? profiles : new Array();
                    var oldProfile = profiles.find(function (x) {
                        return x.name === _this_1.oldValue &&
                            x.currentSerie === _this_1.scsincomeService.series;
                    });
                    if (oldProfile) {
                        oldProfile.name = _this_1.profile.get("profileName").value;
                        oldProfile.comment = _this_1.profile.get("profileComment").value;
                    }
                    else {
                        profile_1.name = _this_1.profile.get("profileName").value;
                        profile_1.comment = _this_1.profile.get("profileComment").value;
                        profile_1.currentSerie = _this_1.scsincomeService.series;
                        profiles.push(profile_1);
                    }
                    _this_1.scsincomeService.saveProfile(profiles);
                    _this_1.customProfileData = profiles.filter(function (x) {
                        return x.currentSerie == _this_1.scsincomeService.series &&
                            x.name !== CUSTOMIZATION_TYPE.REPORT;
                    });
                    _this_1.listIndex = _this_1.customProfileData.findIndex(function (x) { return x.name === profile_1.name; });
                    _this_1.displayDetail(oldProfile ? oldProfile : profile_1, _this_1.listIndex);
                    //load newly added profile
                    _this_1.scsincomeService.currentProfile = profile_1.name;
                    _this_1.scsincomeService.customizationType = CUSTOMIZATION_TYPE.PROFILE;
                    _this_1.coreService.profile.next({
                        name: profile_1.name,
                        type: CUSTOMIZATION_TYPE.PROFILE,
                        profile: oldProfile ? oldProfile : profile_1,
                    });
                });
            }
        }
    };
    /******
     * Custom Content navigation
     */
    CustomProfileComponent.prototype.navigateToCustomContent = function (link) {
        this.coreService.profile.next({
            name: this.currentProfile.name,
            type: CUSTOMIZATION_TYPE.PROFILE,
            profile: this.currentProfile,
        });
        this.utilityService.navigate(link);
    };
    CustomProfileComponent.prototype.goToSampleProfile = function () {
        this.utilityService.navigate(ROUTE_PATH.SCS.TOOLS.INVESTOR_PROFILE, {
            section: "scs",
        });
    };
    return CustomProfileComponent;
}());
export { CustomProfileComponent };
