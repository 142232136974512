import { Theme, Orientation, Icon } from 'equitable-component-library';
import { QuestionnaireService } from '../../services/questionnaire.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-questionnaire-three',
  templateUrl: './questionnaire-three.component.html',
  styleUrls: ['./questionnaire-three.component.scss'],
})
export class QuestionnaireThreeComponent implements OnInit {
  //Inputs
  @Input() step;
  //Outputs
  @Output() selectedOption = new EventEmitter<{}>();
  @Output() backButtonClicked = new EventEmitter<number>();
  //Class Variables
  cardMainconfg = [];
  type = Theme.Dark;
  backIcon = Icon.BackButton;
  orientation = Orientation.Left;

  constructor(private questionnaireService: QuestionnaireService) { }

  ngOnInit() {
    this.questionnaireService.getQuestionnairePageData(3).subscribe((data) => {
      this.cardMainconfg = data;
    });
  }

  selectedCard(index) {
    this.selectedOption.emit({ step: this.step, index: index });
  }

  backButton() {
    this.backButtonClicked.emit(this.step);
  }
}
