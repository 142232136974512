<ion-content>
  <div class="how-it-works">
    <div class="site-tools">
      <div class="page-header">
        <equitable-sub-header
          title="{{ 'howItWorks.text' | translate }}"
          backLinkText="{{ 'howItWorks.linkText' | translate }}"
          (backClick)="backButton()"
        ></equitable-sub-header>
      </div>
      <div class="position-top-content">
        <div class="sub-heading">
          <h2>{{ "howItWorks.subText" | translate }}</h2>
          <p [innerHTML]="'howItWorks.detail' | translate"></p>
        </div>
        <div class="text-center right-image">
          <img
            [alt]="'howItWorks.text' | translate"
            src="./assets/images/how-it-works.png"
          />
        </div>
      </div>
    </div>
  </div>
  <app-footer
    isBlueFooterVisible="true"
    isWhiteFooterVisible="true"
    selectedProduct="ie"
  ></app-footer>
</ion-content>
