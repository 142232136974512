// import { DiscoverComponent } from './../../../ie/components/discover/discover.component';
// import { Series, FeatureCard, InvestorProfile } from './../../../core/models/app.models';
import { FeatureFlagService } from "./../../../core/services/feature-flag.service";
import { ActivatedRoute, Route } from "@angular/router";
import { Router } from "@angular/router";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { IonSlides } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import {
  ATTRIBUTE_TYPE,
  Icon,
  EquitableUtilities,
  LinkType,
  Orientation,
} from "equitable-component-library";
import { SLIDE_OPTS, ROUTE_PATH } from "../../../constants/constants";
import {
  DiscoverRc,
  ImageCard,
  Dictionary,
} from "../../../core/models/app.models";
import { UtilityService } from "../../../core/services/utility.service";
import { ScsService } from "../../services/scs.service";
import { FormControl, Validators, FormGroup, FormBuilder, ControlValueAccessor } from "@angular/forms";
import { TealiumUtagService } from '../../../tealium/utag.service';

@Component({
  selector: "app-discover-scs",
  templateUrl: "./discover-scs.component.html",
  styleUrls: ["./discover-scs.component.scss"],
})
export class DiscoverScsComponent implements OnInit, ControlValueAccessor {
  @ViewChild("select1", { static: true }) select1: ElementRef;
  @ViewChild("videoFrame", { static: true }) videoFrame: ElementRef;
  productValue: string;
  
  // added from header image componet code
  image: string;
  title: string;
  description: string;
  discoverScs: any;
  isNational: boolean;

  //  Features & Benefits section
  discoverScsData: DiscoverRc = {
    featureCards: [
      {
        icon: Icon.PartialProtection,
        title: "discoverScs.PartialProtection",
        description: "discoverScs.product1Desc",
      },
      {
        icon: Icon.GrowthOpportunities,
        title: "discoverScs.growthOpportunities",
        description: "discoverScs.product2Desc",
      },
      {
        icon: Icon.Customize,
        title: "discoverScs.customizedToNeeds",
        description: "discoverScs.product3Desc",
      },
    ],
    featureCards_Plus21: [
      {
        icon: Icon.PartialProtection,
        title: "discoverScs.PartialProtectionPlus21",
        description: "discoverScs.product1DescPlus21",
      },
      {
        icon: Icon.GrowthOpportunities,
        title: "discoverScs.growthOpportunities",
        description: "discoverScs.product2Desc",
      },
      {
        icon: Icon.Customize,
        title: "discoverScs.customizedToNeeds",
        description: "discoverScs.product3Desc",
      },
    ],
    getFileUrl: "",
    resource: [],
  };

    // for video section
    videos = []; //environment.videos.scs;
    video_SCS: [] = []
    video_PLUS: [] = []
    video_PLUS21: [] = []
    video: [] = []

  howSCSWorks: string;
  pageDescription: string;
  standardSegmentIsAnOption: string;

  howSCSWorks_SCS: string;
  pageDescription_SCS: string;
  standardSegmentIsAnOption_SCS: string;
  
  howSCSWorks_PLUS: string;
  pageDescription_PLUS: string;
  standardSegmentIsAnOption_PLUS: string;

  howSCSWorks_PLUS21: string;
  pageDescription_PLUS21: string;
  standardSegmentIsAnOption_PLUS21: string;

  qparams: any;


  //for slider in History May Be a Guide section
  @ViewChild("historicalReturnIndexSlider", { static: false })
  slider: IonSlides;
  slideOpts = SLIDE_OPTS;
  iconArrowLeft = this.equitableUtilities.getPath(
    Icon.SliderNavLeft,
    ATTRIBUTE_TYPE.ICON
  );
  iconArrowRight = this.equitableUtilities.getPath(
    Icon.SliderNavRight,
    ATTRIBUTE_TYPE.ICON
  );
  orientation = Orientation;
  activeSlide = 0;
  scsData: any;



  // for stepper in History May Be a Guide section
  steps = [
    {
      index: 1,
      active: true,
      completed: true,
    },
    {
      index: 2,
      active: false,
      completed: false,
    },
    {
      index: 3,
      active: false,
      completed: false,
    },
  ];




  // for Explore the other strategies we offer section
  guaranteedIncome: ImageCard = {
    headingSmall: "",
    listDetail: [],
  };
  deferYourTaxes: ImageCard = {
    headingSmall: "",
    listDetail: [],
  };
  scsincomeBenefits: ImageCard = {
    headingSmall: "",
    listDetail: [],
  };

  // for link in Disclosure, Terms of Use section
  LinkType = LinkType;

  // for select list
  options: Dictionary[];
  dashboardForm: FormGroup;
  getSeries: string;
  status: boolean;


  bannerDescription: string;
  bannerDescription_SCS: string;
  bannerDescription_PLUS: string;
  bannerDescription_PLUS21: string;

  isForPLUS = true;
  isForPLUS21 = true;

  slideShowTitle: string;

  scsPlusSlide_0: string
  scsPlusSlide_1: string
  scsPlusSlide_2: string
  scsPlus21Slide_0: string
  scsPlus21Slide_1: string
  scsPlus21Slide_2: string
  scsSlide_0: string
  scsSlide_1: string
  scsSlide_2: string
  pageChanged: boolean = false;
  isPrimerica: boolean;


  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {}
  registerOnTouched(fn: any): void {}

  constructor(
    private utilityService: UtilityService,
    private equitableUtilities: EquitableUtilities,
    private translate: TranslateService,
    private scsService: ScsService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private flagService: FeatureFlagService,
    private tealium: TealiumUtagService
  ) { }

  
  ngOnInit() {
    this.isNational = this.flagService.featureFlags.isNational;
    this.isPrimerica = this.flagService.featureFlags.isPrimerica

    this.scsService.getSCSData().subscribe((scs) => {
      this.scsData = scs
      if (scs.videos && scs.videos.length > 0) { 
        this.video_SCS = scs.videos
        this.video_PLUS = scs.PLUSvideos
        this.video_PLUS21 = scs.PLUS21videos
        if (this.productValue === 'scsplus') {
          console.log('setting videos for plus')
          this.videos = scs.PLUSvideos
        } 
        else if (this.productValue === 'scsplus21') {
          console.log('setting videos for plus 21')
          this.videos = scs.PLUS21videos
        } 
        else {
          console.log('setting videos for scs')
             this.videos = scs.videos
        }

      }
    });
   
    this.dashboardForm = this.formBuilder.group({
      seriesDropDown: new FormControl(
        { value: this.getSeries, disabled: false },
        Validators.required
      ),
    });



    // translations for Explore the other strategies we offer section
    this.translate.get("discoverScs").subscribe((discoverScs) => {
      this.discoverScs;
      this.deferYourTaxes = {
        headingSmall: discoverScs.benefits,
        listDetail: [
          discoverScs.taxDeferral,
          discoverScs.taxEfficientDistributions,
          discoverScs.diversification,
        ],
      };
      this.scsincomeBenefits = {
        headingSmall: discoverScs.benefits,
        listDetail: [
          discoverScs.lifetimeIncome,
          discoverScs.levelDownside,
          discoverScs.lowCost,
        ],
      };
    
      this.guaranteedIncome = {
        headingSmall: discoverScs.benefits,
        listDetail: [
          discoverScs.growYourHealth,
          discoverScs.protectedBenefitAccount,
          discoverScs.planBeyond,
        ],
      };

      this.image = "./assets/images/discover-scs-banner.png";

      this.options = [
        { 
          id: discoverScs.abbrplus21,
          value: discoverScs.scsplus21 
        },
        { 
          id: discoverScs.abbrplus,
          value: discoverScs.scsplus 
        },
        { 
          id: discoverScs.abbrscs, 
          value: discoverScs.scs
        },
      ];
   
      this.pageDescription_PLUS = discoverScs.scsPLUSAdaptsToYourUniqueStyle
      this.howSCSWorks_PLUS = discoverScs.howSCSPLUSWorks
      this.standardSegmentIsAnOption_PLUS = discoverScs.plusStandardSegmentIsAnOption
  
      this.pageDescription_PLUS21 = discoverScs.scsPLUS21AdaptsToYourUniqueStyle
      this.howSCSWorks_PLUS21 = discoverScs.howSCSPLUS21Works
      this.standardSegmentIsAnOption_PLUS21 = discoverScs.plus21StandardSegmentIsAnOption
      
        this.pageDescription_SCS = discoverScs.scsAdaptsToYourUniqueStyle
        this.howSCSWorks_SCS = discoverScs.howSCSWorks
        this.standardSegmentIsAnOption_SCS = discoverScs.standardSegmentIsAnOption


      this.slideShowTitle = discoverScs.scsPlusSlide_1
      this.scsPlusSlide_0 = discoverScs.scsPlusSlide_0
      this.scsPlusSlide_1 = discoverScs.scsPlusSlide_1
      this.scsPlusSlide_2 = discoverScs.scsPlusSlide_2
      this.slideShowTitle = discoverScs.scsPlus21Slide_1
      this.scsPlus21Slide_0 = discoverScs.scsPlus21Slide_0
      this.scsPlus21Slide_1 = discoverScs.scsPlus21Slide_1
      this.scsPlus21Slide_2 = discoverScs.scsPlus21Slide_2
      this.scsSlide_0 = discoverScs.scsSlide_0
      this.scsSlide_1 = discoverScs.scsSlide_1
      this.scsSlide_2 = discoverScs.scsSlide_2

      this.route.queryParams.subscribe((qparams)=>{
        this.qparams = qparams
        console.log('DISCOVER: setting banner for this.qparams.series ', this.qparams.series)
        if (this.qparams.series && this.qparams.series.toLowerCase() === 'plus21') {
          console.log('DISCOVER: setting banner for ', discoverScs.scsplus21Desc)
          this.bannerDescription =  discoverScs.scsplus21Desc
        }
        else if (this.qparams.series && this.qparams.series.toLowerCase() === 'scs16') {
          console.log('DISCOVER: setting banner for ', discoverScs.scs)
          this.bannerDescription =  discoverScs.scs
        }
        else if (this.qparams.series && this.qparams.series.toLowerCase() === 'plus') {
          console.log('DISCOVER: setting banner for ', discoverScs.scsplus)
          this.bannerDescription =  discoverScs.scsplus
        }
        this.setPageContent()
      })
      
    });

    // this.tealium.link({
    //   tealium_event: 'link',
    //   event_category: 'BodyClick',
    //   event_name: 'Retirement Guide Clicks',
    //   event_label: 'Click on Product Overview'
    // })

  }
  setPageContent() {
  console.log("DISCOVER: setPageConent START")
    var qparams = this.qparams
    this.activeSlide = 0;
    if (this.slider) {

      this.slideChanged()
    }
    // this line my be after above
    this.pageChanged = true;

    if ( Object.keys(qparams).length === 0 || (qparams.series && qparams.series.toLowerCase() === 'plus')){
      this.isForPLUS21 = false;
      this.isForPLUS = true;
      console.log('DISCOVER: AA setPageContent for PLUS setting productValue')
     this.productValue = 'scsplus'
      this.pageDescription = this.pageDescription_PLUS
      this.howSCSWorks = this.howSCSWorks_PLUS
      this.standardSegmentIsAnOption = this.standardSegmentIsAnOption_PLUS
      this.videos = this.video_PLUS
      // console.log('DISCOVER:in setPageContent setting banner to be', this.bannerDescription_PLUS)
      // this.bannerDescription = this.bannerDescription_PLUS

    } 

    else if ( Object.keys(qparams).length === 0 || (qparams.series && qparams.series.toLowerCase() === 'plus21')){
      this.isForPLUS = false;
      this.isForPLUS21 = true;
      console.log('DISCOVER: AA setPageContent for PLUS 21 setting productValue')
      this.productValue = 'scsplus21'
      this.pageDescription = this.pageDescription_PLUS21
      this.howSCSWorks = this.howSCSWorks_PLUS21
      this.standardSegmentIsAnOption = this.standardSegmentIsAnOption_PLUS21
      this.videos = this.video_PLUS21
      
      // console.log('DISCOVER:in setPageContent setting banner to be', this.bannerDescription_PLUS)
      // this.bannerDescription = this.bannerDescription_PLUS

    }
    
    
    else if (qparams.series) {
      this.isForPLUS21 = false;
      this.isForPLUS = false;
      console.log('DISCOVER: AA setPageContent for SCS16 NOT_setting productValue')
      this.productValue = 'scs16'
      this.pageDescription = this.pageDescription_SCS
      this.howSCSWorks = this.howSCSWorks_SCS
      this.standardSegmentIsAnOption = this.standardSegmentIsAnOption_SCS
      this.videos = this.video_SCS
      //console.log('DISCOVER: in setPageConetn setting banner to', this.bannerDescription_SCS)
      //this.bannerDescription = this.bannerDescription_SCS

    } else  {
      console.log("qparams not loaded as expected")
    }
   
  }



  // move slider left/right direction in History May Be a Guide section
  slideTransition(slider: IonSlides, direction: Orientation) {
    if (direction === this.orientation.Left) {
      slider.slidePrev();
    } else if (direction === this.orientation.Right) {
      slider.slideNext();
    }
  }

  // on slide change, update stepper in History May Be a Guide section
  slideChanged() {
    this.slider.getActiveIndex().then((index: number) => {
      if (this.pageChanged){
        index=0
        this.activeSlide=0
      }
      this.pageChanged = false;
  
      this.activeSlide = index;
      const steps = [...this.steps];
      for (const step of steps) {
        step.active = step.completed = false;
      }


      if (this.isForPLUS) {
        this.slideShowTitle = this["scsPlusSlide_" + index]
    } 
    else if (this.isForPLUS21) {
      this.slideShowTitle = this["scsPlus21Slide_" + index]
  } 
    
    else {
          this.slideShowTitle = this["scsSlide_" + index]
    }
      steps[index] = {
        index: index + 1,
        active: true,
        completed: true,
      };
      this.steps = steps;
    });
    
  }

  gotoSCSLandingPage(): void {

    document.querySelector('.hero-main').scrollIntoView(true)
    this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER + '?series=scs16')
  }
  gotoSCSPLUSLandingPage(): void {
    document.querySelector('.hero-main').scrollIntoView(true)
    this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER + '?series=PLUS')
  }
  gotoSCSINCOMELandingPage(): void {
    document.querySelector('.hero-main').scrollIntoView(true)
    this.utilityService.navigate(ROUTE_PATH.SCSINCOME.DISCOVER + '?series=B')
  }
  gotoSCSPLUS21LandingPage(): void {
    document.querySelector('.hero-main').scrollIntoView(true)
    this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER + '?series=PLUS21')
  }

  gotoRCLandingPage() {
    this.utilityService.navigateWithoutParams(ROUTE_PATH.RC.DISCOVER);
  }

  gotoIELandingPage() {
    this.utilityService.navigateWithoutParams(ROUTE_PATH.IE.DISCOVER);
  }

  gotoAdvancedToolsResourcesLandingPage() {
    this.utilityService.navigateWithoutParams(ROUTE_PATH.SCS.TOOLS.HOME);
  }
  productMenuChange(status) {
    // var d = this.translate.get("discoverScs")
    console.log("PRODUCT MENU CHANGE")
    this.status = status;
    this.select1.nativeElement.blur();
    if (this.select1.nativeElement.value === 'scsplus') {
      console.log("DISCOVER: route to PLUS")
      console.log(this.select1.nativeElement.value)
      this.router.navigate([ROUTE_PATH.SCS.DISCOVER], {queryParams: {'series': "PLUS"}})

    } 
    else if (this.select1.nativeElement.value === 'scsplus21') {
      console.log("DISCOVER: route to PLUS 21")
      this.router.navigate([ROUTE_PATH.SCS.DISCOVER], {queryParams: {'series': "PLUS21"}})

    }
    
    else {
      console.log("DISCOVER: route to SCS16")
      this.router.navigate([ROUTE_PATH.SCS.DISCOVER], {queryParams: {'series': "SCS16"}})
    }
    //console.log("DISCOVER", d)
   }
}
