import { OnInit } from "@angular/core";
import { ScsIncomeService } from "../../services/scsincome.service";
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SCSINCOME_SERIES } from 'src/app/constants/constants';
var SioPopupComponent = /** @class */ (function () {
    function SioPopupComponent(scsincomeService, modalController, translateService) {
        this.scsincomeService = scsincomeService;
        this.modalController = modalController;
        this.translateService = translateService;
    }
    SioPopupComponent.prototype.ngOnInit = function () {
        this.getData();
    };
    SioPopupComponent.prototype.ionViewWillEnter = function () {
        this.getData();
    };
    SioPopupComponent.prototype.getData = function () {
        var _this = this;
        this.scsincomeService.getHowItsWorksMasterData().subscribe(function (masterData) {
            var series = masterData.body.series.find(function (x) { return x.name === _this.scsincomeService.series; });
            var arrDurations = [];
            series.segments.forEach(function (element) {
                var durations = Array.from(new Set(element.data.map(function (x) { return x.duration; })));
                arrDurations = arrDurations.concat(durations);
            });
            arrDurations = Array.from(new Set(arrDurations));
            var id = 0;
            var finalArray = [];
            arrDurations.forEach(function (duration) {
                var obj = {
                    duration: duration + _this.translateService.instant("sioFrontPage.popOver.timeFrame"),
                    data: [],
                };
                series.segments.forEach(function (element) {
                    var selectedIndex = element.data.filter(function (x) { return x.duration == duration; });
                    var objIndex = {
                        approach: element.id,
                        index: Array.from(new Set(selectedIndex.map(function (x) { return x.index; }))),
                        buffer: Array.from(new Set([].concat.apply([], selectedIndex.map(function (x) { return x.buffers; })))),
                    };
                    if (objIndex.index.length > 0)
                        obj.data.push(objIndex);
                });
                finalArray.push(obj);
            });
            _this.ar = finalArray;
        });
        this.translateService.get("sioFrontPage.popOver").subscribe(function (translated) {
            if (_this.scsincomeService.series == SCSINCOME_SERIES.B) {
                _this.popoverDesc =
                    _this.translateService.instant("sioFrontPage.popOver.strcturedOptionINCOME", {
                        years: "1 or 6"
                    });
                console.log("PLUS21");
            }
            else if (_this.scsincomeService.series == SCSINCOME_SERIES.ADV) {
                _this.popoverDesc =
                    _this.translateService.instant("sioFrontPage.popOver.strcturedOptionINCOME", {
                        years: "1 or 6"
                    });
            }
            else {
                _this.popoverDesc =
                    _this.translateService.instant("sioFrontPage.popOver.strcturedOptionINCOME", {
                        years: "1, 3 or 5"
                    });
            }
        });
    };
    SioPopupComponent.prototype.closeModal = function () {
        this.modalController.dismiss({
            'dismissed': true
        });
    };
    return SioPopupComponent;
}());
export { SioPopupComponent };
