import { ScsComponent } from "./components/scs/scs.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UtilizeScsComponent } from "./components/utilize-scs/utilize-scs.component";
import { ScsPiePicturesComponent } from "./components/scs-pie-pictures/scs-pie-pictures.component";
import { IonicModule } from "@ionic/angular";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ScsRoutingModule } from "./scs-routing.module";
import { EquitableComponentLibraryModule } from "equitable-component-library";
import { TranslateModule } from "@ngx-translate/core";
import { ScsService } from "./services/scs.service";
import { DiscoverScsComponent } from "./components/discover-scs/discover-scs.component";
import { MarketTrendIndicatorComponent } from "./components/market-trend-indicator/market-trend-indicator.component";
import { SioComponent } from "./components/sio/sio.component";
import { HowSioWorksComponent } from "./components/how-sio-works/how-sio-works.component";
import { ScsAdvancedToolResourcesDashboardComponent } from "./components/scs-advanced-tool-resources-dashboard/scs-advanced-tool-resources-dashboard.component";
import { HistoryGuideComponent } from "./components/history-guide/history-guide.component";
import { HistoricalPerformanceComponent } from "./components/historical-performance/historical-performance.component";
import { SegmentPerformanceComponent } from "./components/segment-performance/segment-performance.component";
import { SioPopupComponent } from "./components/sio-popup/sio-popup.component";
import { HomePagePopupComponent } from "../core/components/homepage-popup/homepage-popup.component";
import { CoreModule } from "../core/core.module";
import { CustomProfileComponent } from "./components/custom-profile/custom-profile.component";
import { PrimericaHomeComponent } from "./components/primerica-home/primerica-home.component";

@NgModule({
  declarations: [
    ScsComponent,
    DiscoverScsComponent,
    MarketTrendIndicatorComponent,
    UtilizeScsComponent,
    ScsPiePicturesComponent,
    HowSioWorksComponent,
    MarketTrendIndicatorComponent,
    SioComponent,
    ScsAdvancedToolResourcesDashboardComponent,
    HistoryGuideComponent,
    HistoricalPerformanceComponent,
    SegmentPerformanceComponent,
    SioPopupComponent,
    HomePagePopupComponent,
    CustomProfileComponent,
    PrimericaHomeComponent
  ],
  providers: [ScsService],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    EquitableComponentLibraryModule,
    TranslateModule,
    CoreModule,
    ScsRoutingModule,
    ReactiveFormsModule
  ],
  exports: [TranslateModule],
  entryComponents: [SioPopupComponent, HomePagePopupComponent]

})
export class ScsModule { }
