<ion-content>
  <form [formGroup]="formGroup">
    <div class="site-tools page-header" *ngIf="items && items.length > 0">
      <equitable-sub-header
        title="{{ 'howItsWorks.headerText' | translate }}"
        [backLinkText]="backLink"
        [showInfo]="showInfo"
        (backClick)="backButton()"
        (infoClick)="openModal()"
      ></equitable-sub-header>
    </div>
    <div class="tool-container site-tools">
      <ion-row>
        <ion-col class="tool-header-text">{{ toolHeaderText }}</ion-col>
      </ion-row>
      <ion-row class="row-width">
        <ion-col class="padding-left" size="6">
          <h6 class="radio">{{ "howItsWorks.segmentOption" | translate }}</h6>
          <equitable-radio-button
            *ngIf="items && items.length > 0"
            class="radio-button"
            [items]="items"
            formControlName="segment"
            [formControl]="formGroup.get('segment')"
          ></equitable-radio-button>
        </ion-col>
        <ion-col size="6">
          <ion-row>
            <ion-col>
              <h6 class="drop">{{ "howItsWorks.index" | translate }}</h6>
              <equitable-drop-down
                class="dropdown"
                [formControl]="formGroup.get('index')"
                formControlName="index"
                [options]="indices"
              ></equitable-drop-down>
            </ion-col>
            <ion-col>
              <h6 class="drop">{{ "howItsWorks.duration" | translate }}</h6>
              <equitable-drop-down
                [formControl]="formGroup.get('duration')"
                formControlName="duration"
                [options]="durations"
              ></equitable-drop-down>
            </ion-col>
            <ion-col>
              <h6 class="drop">
                {{ "howItsWorks.protection" | translate }}
              </h6>
              <equitable-drop-down
                [formControl]="formGroup.get('protection')"
                formControlName="protection"
                [options]="protections"
              ></equitable-drop-down>
            </ion-col>
            <ion-col *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.ENHANCED_UPSIDE">
                <h6 class="drop">
                  Enhanced Upside
                </h6>
                <equitable-drop-down
                  [formControl]="formGroup.get('enhancedUpside')"
                  formControlName="enhancedUpside"
                  [options]="enhancedUpsides"
                ></equitable-drop-down>
              </ion-col>
          </ion-row>
          <!-- START SEGMENT INVESTMENT DROPDOWN -->
          <ion-row *ngIf="isNational">
            <ion-col class="border-top">
              <label class="equitable-input-label">Segment Investment</label>
              <div class="input-contain">
                <span class="equitable-input-prefix">$</span>
                <input type="number" name="dollarInputValue" (click)="$event.target.select()" [(ngModel)]="dollarInputValue" [formControl]="formGroup.get('dynamicDollarAmount')" formControlName="dynamicDollarAmount" class="equitable-input dollar-input" id="segmentMaturity" placeholder="100000" 
                  [min]="minValue" [max]="maxValue" (keydown.enter)="onSubmitOfDollarAmount()" required [step]="1" [ngStyle]="{'border-color': isValid() ? '#767676' : 'red', 'border-width': '1px'}">
                <button class="btn btn-primary" (click)="onSubmitOfDollarAmount()">Update</button>
                <p class="invalid-feedback" *ngIf="dollarInputValue && (dollarInputValue < minValue || dollarInputValue > maxValue)">Value must be between {{minValue}} and {{maxValue}}</p>
                <p class="invalid-feedback" *ngIf="dollarInputValue && dollarInputValue % 1 !== 0">Value must be a whole number</p>
              </div>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <div
            *ngIf="
              this.formGroup.get('segment').value === SEGMENT_TYPE.ANNUAL_LOCK
            "
            class="annualMarketScenerio"
          >
            <equitable-radio-button
              class="radio-button"
              [items]="marketScenerios"
              formControlName="marketScenerio"
              [formControl]="formGroup.get('marketScenerio')"
            ></equitable-radio-button>
          </div>

          <div>
              <!-- // IF the segment type is dual direction for primerica or plusguard, we want to display a static image.  -->
            <div class="dualImage" *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_DIRECTION && (!isNational)">
              <img alt="Dual Direction Chart" *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_DIRECTION && this.formGroup.get('protection').value == -10 && (isPrimerica)" src="/assets/images/dual_direction.png" />
              <img alt="Dual Direction Chart" *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_DIRECTION && this.formGroup.get('protection').value == -15 && (isPrimerica)" src="/assets/images/dual_direction15.png" />
              <img alt="Dual Direction Chart" *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_DIRECTION && this.formGroup.get('protection').value == -20 && (isPrimerica)" src="/assets/images/dual_direction20.png" />
              <img alt="Dual Direction Chart" *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_DIRECTION && this.formGroup.get('protection').value == -10 && (isPlusGuard)" src="/assets/images/dual_direction_15PG.png" />
              <img alt="Dual Direction Chart" *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_DIRECTION && this.formGroup.get('protection').value == -15 && (isPlusGuard)" src="/assets/images/dual_direction_10PG.png" />
            </div>

            <!-- display line and bar charts other than dual direction -->
            <equitable-bar-chart *ngIf="this.formGroup.get('segment').value !== SEGMENT_TYPE.DUAL_DIRECTION"
              [chartData]="chartData"
              isBarChart="true"
              lagendsLabel="{{ 'productKey' | translate }}"
              [segmentType]="this.formGroup.get('segment').value"
              [performanceData]="performanceData"
              [buffer]="this.formGroup.get('protection').value"
              [series]="this.series" 
              [index]="this.formGroup.get('index').value" 
              [duration]="this.formGroup.get('duration').value"
              productType="scs"
            ></equitable-bar-chart>

            <!-- OPTION 1 - Pass isDualDirection into property on component library. Note this flag doesn't do anything. -->
            <!-- <equitable-bar-chart *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_DIRECTION && (isNational)"
              isdualdirection="true"
          ></equitable-bar-chart> -->

          <dual-direction *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.DUAL_DIRECTION && isNational"
            [chartData]="chartData"
            [performanceData]="performanceData"
            [buffer]="this.formGroup.get('protection').value"
            [series]="this.series" 
            [index]="this.formGroup.get('index').value" 
            [duration]="this.formGroup.get('duration').value"
            [dynamicSegmentMaturityValue]="this.formGroup.get('dynamicDollarAmount').value"
            productType="scsplus"
          ></dual-direction>

          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col style="text-align: center;">
          <div *ngIf="this.formGroup.get('segment').value === SEGMENT_TYPE.STEP_UP && (isPlusGuard)">
            The Segment Maturity Value reflects the deduction of the annual Death Benefit Rider Charge of 0.20%
          </div>
        </ion-col>
      </ion-row> 
      <ion-row>
        <ion-col>
          <div class="series-title" *ngIf="isNotPlusGuard">
            {{ "historyGuide.viewingSeries" | translate }} {{ series }}
          </div>
        </ion-col>
      </ion-row> 
      <ion-row>
        <ion-col style="text-align: center;">
          <div *ngIf="this.scsService.series == 'PLUS21'">
            Some Segment options may not be available in all firms or jurisdictions.
          </div>
          <div *ngIf="this.scsService.series == 'PLUS' || this.scsService.series == 'PLUS21'">
            The example assumes the optional Return of Premium Death Benefit is not elected.
          </div>
        </ion-col>
      </ion-row> 
      <div class="link-container">
        <div class="first-link">
          <equitable-link
            [text]="'howItsWorks.customProfile' | translate"
            [iconName]="linkRightIcon"
            [type]="type"
            [orientation]="orientation"
            (click)="saveProfile(CUSTOMIZATION_TYPE.PROFILE)"
          ></equitable-link>
        </div>
        <div class="second-link">
          <equitable-link
            [text]="'howItsWorks.customizeReport' | translate"
            [iconName]="linkRightIcon"
            [type]="type"
            [orientation]="orientation"
            (click)="saveProfile(CUSTOMIZATION_TYPE.REPORT)"
          ></equitable-link>
        </div>
      </div>
    </div>
  </form>
  <app-footer
    isBlueFooterVisible="true"
    isWhiteFooterVisible="true"
    [selectedProduct]="series"
    activeTab="How_SIO_works"
  ></app-footer>
</ion-content>
