var TealiumUtagService = /** @class */ (function () {
    // Typically set "noview" flag (no first page automatic view event) to true for Single Page Apps (SPAs)
    function TealiumUtagService() {
        this.scriptSrc = '';
        window.utag_cfg_ovrd = { noview: true };
        window.utag_data = {};
    }
    // Generic script loader with callback
    TealiumUtagService.prototype.getScript = function (src, callback) {
        var d = document;
        var fn = function () { };
        var o = { callback: callback || fn };
        var s;
        var t;
        if (typeof src === 'undefined') {
            return;
        }
        s = d.createElement('script');
        s.language = 'javascript';
        s.type = 'text/javascript';
        s.async = 1;
        s.charset = 'utf-8';
        s.src = src;
        if (typeof o.callback === 'function') {
            if (d.addEventListener) {
                s.addEventListener('load', function () { o.callback(); }, false);
            }
            else {
                // old IE support
                s.onreadystatechange = function () {
                    if (this.readyState === 'complete' || this.readyState === 'loaded') {
                        this.onreadystatechange = null;
                        o.callback();
                    }
                };
            }
        }
        t = d.getElementsByTagName('script')[0];
        t.parentNode.insertBefore(s, t);
    };
    // Config settings used to build the path to the utag.js file
    TealiumUtagService.prototype.setConfig = function (config) {
        if (config.account !== undefined && config.profile !== undefined && config.environment !== undefined) {
            this.scriptSrc = 'https://tags.tiqcdn.com/utag/' + config.account + '/' + config.profile + '/' + config.environment + '/utag.js';
        }
    };
    // Data layer is optional set of key/value pairs
    TealiumUtagService.prototype.track = function (tealiumEvent, data) {
        if (this.scriptSrc === '') {
            console.log('Tealium config not set.');
            return;
        }
        if (window.utag === undefined) {
            this.getScript(this.scriptSrc, function () {
                window.utag.track(tealiumEvent, data);
            });
        }
        else {
            window.utag.track(tealiumEvent, data);
        }
    };
    TealiumUtagService.prototype.view = function (data) {
        this.track('view', data);
    };
    TealiumUtagService.prototype.link = function (data) {
        this.track('link', data);
    };
    return TealiumUtagService;
}());
export { TealiumUtagService };
