import { FeatureFlagService } from "./../../core/services/feature-flag.service";
import { Injectable } from "@angular/core";
import { map, switchMap, catchError } from "rxjs/operators";
import { HttpRequestService } from "../../core/services/http-request.service";
import {
  RequestOption,
  Chart,
  Item,
  Bar,
  Axis,
  Line,
  Kits,
  Series,
  Profile,
  Preset,
  IE,
} from "../../core/models/app.models";
import {
  API_METHODS,
  SEGMENT_TYPE,
  IE_PROFILE_KEY,
  IE_SERIES,
  PRODUCT_NAME,
  PRODUCT_TYPE, 
  CUSTOM_PROFILE,
  CUSTOMIZATION_TYPE,
  INVESTOR_MODE,
  MARKET_SCENERIO,
} from "../../../app/constants/constants";
import { of, Observable, from, forkJoin, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { IonicStorageService } from "../../core/services/ionic-storage.service";
import { CoreService } from "../../core/services/core.service";
import { ModalController } from "@ionic/angular";
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { UtilityService } from "../../core/services/utility.service";


@Injectable({
  providedIn: "root"
})
export class IeService {
    //property for storing main scs data
    private ie: IE;
    private reportData: Profile;
    private _series: string;
    product: string; 
    customizationType = CUSTOMIZATION_TYPE.REPORT;
    currentProfile = ""; //for storing current profile in session
    investorData = {
      segment: "",
      index: "",
      protection: "",
      duration: "",
      mode: "",
      preset: new Preset(),
    };
  
    isIE;
    public dynamicData;
  
    public upMarketBars = {};
    public downMarketBars = {};
    public dynamicDollarAmount = 100000;
    
  
  

  /***
   *  IE Service Constructor
   ***/
  /***
   *  Constructor for SCS Service
   ***/
  constructor(
    private httpRequestService: HttpRequestService,
    private translateService: TranslateService,
    private storageService: IonicStorageService,
    private coreService: CoreService,
    public modalController: ModalController,
    private flagService: FeatureFlagService,
    private http: HttpClient,
    private utilityService: UtilityService,
  ) {
    this.reportData = new Profile();
    if (
      this.flagService &&
      this.flagService.featureFlags &&
      this.flagService.featureFlags.ie
    ) {
     this.product = "ie";
     this.series = "B";
     
    }

    this.coreService.profile.subscribe((profile) => {
      this.currentProfile = profile.name;
      this.customizationType = profile.type
        ? profile.type
        : CUSTOMIZATION_TYPE.REPORT;
      if (this.currentProfile === "") {
        this.investorData.mode = INVESTOR_MODE.HOME;
        this.getProfile().subscribe((profiles) => {
          if (profiles && Array.isArray(profiles) && profiles.length > 0) {
            const profile = profiles.find(
              (x) =>
                x.name === CUSTOMIZATION_TYPE.REPORT &&
                x.currentSerie === this.series
            );
            if (profile) {
              this.report = profile;
            }
          }
        });
      } else {
        this.investorData.mode = INVESTOR_MODE.PROFILE;
      }
    });
  }
  




public get series(): string {
  return this._series;
}

public set series(series: string) {
  this.storageService.setValueByKey(
    this.flagService.featureFlags.currentSeries,
    series
  );
  localStorage.setItem(this.flagService.featureFlags.currentSeries, series);
  this._series = series;
}

public get report(): Profile {
  return this.reportData;
}

public set report(reportData: Profile) {
  this.reportData = reportData;
}

setDollarAmount(dollarInputValue: number) {
  this.dynamicDollarAmount = dollarInputValue
}

getDollarAmount() {
  return this.dynamicDollarAmount;
}


getDynamicIEData(){
  // https://jasonwatmore.com/post/2019/09/06/angular-http-get-request-examples

  // show loader until all products have rendered from API 
  this.utilityService.showLoader();

  this.http.get(environment.serviceAPIs.dynamicAPIUrl, {
    headers: new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.serviceAPIs.dynamicAPISubscriptionKey
    })
  })
  .subscribe(response => {
    this.utilityService.hideLoader();
    // local json for testing: "assets/apis/scs/ratesforirapp.json"
    console.log('Constructor of SCS/IE Service - Calling APIM')
    this.dynamicData = response;
    console.log(this.dynamicData);
  });
}

/***
 *  Check the scs data from local variable
 ***/


getIEData(): Observable<IE> {
  if (this.ie) {
    return of(this.ie);
  } else {
    return this.fetchIEData(); // fetchSCSDataWithGenesAPI() or fetchSCSData()
  }
}

/***
 *  get master data to fill drop down on how it works
 ***/
getAllIndices(): Observable<any> {
  const reqOptions: RequestOption = {
    method: API_METHODS.GET,
    url: environment.ieIndices,
  };
  return this.httpRequestService.request(reqOptions).pipe(
    map((data: any) => {
      return data.body.indicesData;
    })
  );
}

  /***
   *  Fetch SCS main data from server
   ***/

   /***
   *  Fetch IE main data from server
   ***/
  fetchIEData(): Observable<IE> {
    var baseURL = environment.serviceAPIs.baseUrl
    if (/https/.test(environment.serviceAPIs.ie)) {
      var baseURL = ""
    } 

    const requestOption: RequestOption = {
      url: baseURL + environment.serviceAPIs.ie, //  plugin baseURL to localhost to test. 
      method: API_METHODS.GET,
    };
    return this.httpRequestService.request(requestOption).pipe(
      map((response: any) => {
        this.ie = response.body;
        console.log("fetch IE Data" + this.ie);
        
        return this.ie;
      })
    );
  }
 /***
 *  Fetch IE main data from server

    /***
   *  get resource for an ie series
   ***/
  getIEResources(seriesName: string): Observable<Kits[]> {
    return this.getIEData().pipe(

      switchMap((ie: IE) => {
        console.log("We are in IE and seriesName" + seriesName + " ")
        const series: Series = ie.series.find((x) => x.name == seriesName);
        return of(series.kits);

      })
    );
  }

/***
 *  store custom profile
 ***/
storeProfile(contentType, data, isReport = false) {
  this.getProfile().subscribe((storedProfiles: []) => {
    let profile = new Profile();
    let profiles: Profile[] = [];
    if (
      storedProfiles &&
      Array.isArray(storedProfiles) &&
      storedProfiles.length > 0
    ) {
      profiles = storedProfiles;
      profile =
        profiles.find(
          (x) =>
            x.name ===
              (isReport ? CUSTOMIZATION_TYPE.REPORT : this.currentProfile) &&
            x.currentSerie == this.series
        ) || profile;
    }
    if (!profile.name) {
      profile.name = isReport
        ? CUSTOMIZATION_TYPE.REPORT
        : this.currentProfile;
      profile.currentSerie = this.series;
      profiles.push(profile);
    }
    profile.currentSerie = this.series;

    if (contentType === CUSTOM_PROFILE.HOW_IT_WORKS) {
      profile.aboutSCSHowSioWorks = data;
    } else if (contentType === CUSTOM_PROFILE.HISTORICAL_PERFORMANCE) {
      profile.historicalPerformance = data;
    } else if (contentType === CUSTOM_PROFILE.HISTORY_GUIDE) {
      profile.historyMayBeAGuide = data;
    } else if (contentType === CUSTOM_PROFILE.HISTORICAL_PERFORMANCE_PLUS) {
      profile.historyMayBeAGuidePlus = data;
    }
    this.saveProfile(profiles);
  });
}

/***
 *  get resource for a series
 ***/
getProfile(): Observable<Profile[]> {
  return from(
    this.storageService.getValueByKey(this.flagService.featureFlags.profile)
  );
}

/***
 *  get resource for a series
 ***/
saveProfile(profiles: Profile[]): Observable<any> {
  return from(
    this.storageService.setValueByKey(
      this.flagService.featureFlags.profile,
      profiles
    )
  );
}

/***
 *  get delete profile
 ***/
deleteProfile(profileName: string) {
  return this.getProfile().pipe(
    switchMap((profiles) => {
      const index = profiles.findIndex(
        (x) => x.name === profileName && x.currentSerie === this.series
      );
      profiles.splice(index, 1);
      return this.saveProfile(profiles);
    })
  );
}
//=============================Start how it works Here =============================== //

/***
 *  get master data to fill drop down on how it works
 ***/
getHowItsWorksMasterData(): Observable<any> {
  const reqOptions: RequestOption = {
    method: API_METHODS.GET,
    url: environment.howItsWorks,
  };
  return this.httpRequestService.request(reqOptions);
}

getHowItsWorksIEMasterData(): Observable<any> {
  const reqOptions: RequestOption = {
    method: API_METHODS.GET,
    url: environment.howItsWorksIE,
  };
  return this.httpRequestService.request(reqOptions);
}

getHowitWorksMenuData() {
  return forkJoin([this.getHowItsWorksMasterData(), this.getProfile()]);
}

getHowitWorksIEMenuData() {
  return forkJoin([this.getHowItsWorksIEMasterData(), this.getProfile()]);
}

getHowitWorksData(seriesName: string) {
  return forkJoin([
    this.getHowItsWorksMasterData(),
    this.fetchHowitWorks(seriesName),
    this.getProfile(),
  ]);
}

getHowitWorksIEData(seriesName: string) {
  return forkJoin([
    this.getHowItsWorksIEMasterData(),
    this.fetchHowitWorks(seriesName),
    this.getProfile(),
  ]);
}

// TODO: We should probably still update this function for calling the dynamic data. 
/***
 *  fetch how it work from server/storage
 ***/
fetchHowitWorks(seriesName: string): Observable<any> {
  
  return this.getIEData().pipe(
    switchMap((ie: any) => {
      const series = this.ie.series.find((x) => x.name == seriesName);
      
      return this.httpRequestService
        .request({
          url: series.performance.howItWorks,
          method: API_METHODS.GET,
        })
        .pipe(
          map((data: any) => {
            return data.body;
          })
        );
    })
  );
}

/***
 *  parse response data for how it works tab
 ***/
parsePerformanceData(performance, segment: string, commission?: number) {
  const segmentRates = [];
  const chartDetails = [];
  const objChartData: Chart = new Chart();
  objChartData.bar = new Array<Bar>();

  if (
    segment != SEGMENT_TYPE.ANNUAL_LOCK &&
    segment != SEGMENT_TYPE.STEP_UP &&
    segment != SEGMENT_TYPE.ENHANCED_UPSIDE && 
    segment != SEGMENT_TYPE.DUAL_DIRECTION
  ) { 
    //sort all scenerio positive first then negative
    performance.performaceRate = this.sortRates(performance.performaceRate);
    console.log("IE rates= "+ performance.performaceRate);
  }

  performance.performaceRate.forEach((element, index) => {
    const objBar = new Bar();
    objBar.id = index;
    let choiceLabel = "";
    if (segment == SEGMENT_TYPE.STEP_UP) {
      choiceLabel =
        element > 0
          ? this.translateService.instant("howItsWorks.upMarket")
          : element == 0
          ? this.translateService.instant("howItsWorks.flatMarket")
          : this.translateService.instant("howItsWorks.downMarket");
    }

/*     else if (segment == SEGMENT_TYPE.ENHANCED_UPSIDE) {
      if (element > 0) {
        choiceLabel = this.translateService.instant("howItsWorks.upMarket")
      }
      else {
        choiceLabel = this.translateService.instant("howItsWorks.downMarket");
      }
    } */

    if (segment == SEGMENT_TYPE.STEP_UP) {
      objBar.label = choiceLabel;
    }
    else if(segment == SEGMENT_TYPE.ENHANCED_UPSIDE) {
      objBar.label = choiceLabel;
    }
    else if(segment == SEGMENT_TYPE.ANNUAL_LOCK) {
      objBar.label =  this.translateService.instant("howItsWorks.year") + " " + (index + 1) // add year labels to annual lock
    }
    else if(segment == SEGMENT_TYPE.STANDARD) {
      if (element > 0) {
        objBar.label = this.translateService.instant("howItsWorks.upMarket")
      }
       else {
        objBar.label = this.translateService.instant("howItsWorks.downMarket");
      } 
    }
    else {
      objBar.label = this.translateService.instant("howItsWorks.scenerio") + " " + (index + 1)
    }

    // Old way of determining the labels above the bar chart
    // objBar.label =
    //   segment == SEGMENT_TYPE.STEP_UP
    //     ? choiceLabel
    //     : (segment == SEGMENT_TYPE.ANNUAL_LOCK
    //         ? this.translateService.instant("howItsWorks.year")
    //         : this.translateService.instant("howItsWorks.scenerio")) +
    //       " " +
    //       (index + 1);

    //for peformace cap rate & Buffer lines for each section
    objBar.lines = new Array<Item>();
    objBar.lines.push({
      label: this.translateService.instant("howItsWorks.performaceCapRate"),
      value: parseFloat(performance.performaceCapRate),
    });

    objBar.lines.push({
      label: this.translateService.instant("howItsWorks.segmentBuffer"),
      value: parseFloat(performance.buffer),
    });

    //push segment(orange)
    //for Index Segment Yearly Rate of Return


    // // TODO: We should create two functions for getting an sSoR, one for dynamic and one from the static .json files
    let segmentRate;

    // console.log(performance.segmentRates)

    if(performance.segmentRates) {
      segmentRate = this.sRoR(
        element,
        performance.performaceCapRate,
        performance.buffer,
        segment,
        commission,
        performance.id,
        performance.additionalCharges,
        index,
        performance.segmentRates[index],
        performance
      );
    }
    else {
      segmentRate = this.sRoR(
        element,
        performance.performaceCapRate,
        performance.buffer,
        segment,
        commission,
        performance.id,
        performance.additionalCharges,
        index,
        0,
        performance
      );
    }

    objBar.data = new Array<Item>();

    //description
    segmentRates.push(segmentRate.return);
    objBar.description = segmentRate.detail;

    // Note that the Index Performance Rate and Segment Rate of Return are swapped on the chart. 
    objBar.data.push({
      value: element,
      label: this.isIE ? this.translateService.instant("howItsWorks.indexPerformanceRateIE") : this.translateService.instant("howItsWorks.indexPerformanceRate"),
      topValue:
        segmentRate.return > 0 && segment == SEGMENT_TYPE.CHOICE
          ? commission
          : 0,
    });

    //for Index Performance Rate
    objBar.data.push({
      value: segmentRate.return,
      label: this.isIE ? this.translateService.instant("howItsWorks.segmentYearlyReturnIE") : this.translateService.instant("howItsWorks.segmentYearlyReturn"),
    });
    objChartData.bar.push(objBar);

    //adding segment details
    chartDetails.push(segmentRate.detail);
  });

  //adding various axis to the chart
  // console.log('xxx3')
  objChartData.axis = new Array<Axis>();
  objChartData.axis.push({
    min: performance.min,
    max: performance.max,
    interval: performance.interval,
    type: "Y",
  });

  //for annual segment
  if (segment == SEGMENT_TYPE.ANNUAL_LOCK) {
    //do some thing for line chart
    objChartData.line = new Line();
    objChartData.line.legends = this.translateService.instant(
      "howItsWorks.annualLockEndingAmount"
    );
    objChartData.line.data = this.calculateAnnualSegment(segmentRates); // UPDATE TO PASS performance INSTEAD OF segmentRates
  }
  return { data: objChartData, details: chartDetails };
}

calculateAnnualSegment(segmentRates): Item[] { // performance or segmentRates
  let value = this.getDollarAmount();
  const annualArray: Array<Item> = new Array<Item>();
  segmentRates.forEach((element, index) => { //  performance.performaceRate OR segmentRates
    value = Math.round(value + value * (element / 100));
    annualArray.push({
      id: index,
      value: value,
      label:
        this.translateService.instant("howItsWorks.year") + " " + (index + 1),
    });
  });
  return annualArray;
}

/***
 *  Get a Segment Rate-of-Return
 **/
sRoR(
  indexPerformance,
  cap,
  buffer,
  segment,
  commission,
  year,
  additionalCharges,
  index,
  segmentRate,
  performance
): any {
  buffer = buffer ? parseFloat(buffer) : 0;
  additionalCharges =
    additionalCharges && segment !== SEGMENT_TYPE.ANNUAL_LOCK
      ? additionalCharges
      : 0;
  let segmentDetails = "";
  let rateOfReturn = indexPerformance;
  if(segment === SEGMENT_TYPE.STEP_UP){
    rateOfReturn = segmentRate;
  }

  // hardcoded fixes for plus guard
  if(this.flagService.featureFlags.isPlusGuard) {
    if(indexPerformance == 0) {
      rateOfReturn = 6.8;
    }
    if(indexPerformance == 4) {
      rateOfReturn = 6.8;
    }
    if(indexPerformance == 11) {
      rateOfReturn = 6.8;
    }
  }

  // cap on positive indexPerformance
  if (indexPerformance > 0 && rateOfReturn <= cap) {
    segmentDetails = this.translateService
      .instant("howItsWorks.segmentReturn.belowPerformanceRate")
      .replace("$$INDEX_RETURN$$", indexPerformance)
      .replace("$$SEGMENT_RETURN$$", rateOfReturn);
  }


  // cap on positive indexPerformance
  if (indexPerformance > 0 && rateOfReturn > cap) {
    rateOfReturn = cap - additionalCharges;
    segmentDetails = this.translateService
      .instant("howItsWorks.segmentReturn.abovePerformanceRate")
      .replace("$$INDEX_RETURN$$", indexPerformance)
      .replace("$$SEGMENT_RETURN$$", rateOfReturn);
  }

  if (indexPerformance > 0 && segment == SEGMENT_TYPE.CHOICE)
    rateOfReturn = rateOfReturn - commission - additionalCharges;



  // buffer on negative indexPerformance
  if (indexPerformance < 0 && segment !== SEGMENT_TYPE.STEP_UP) {
    rateOfReturn = rateOfReturn - buffer < 0 ? rateOfReturn - buffer : 0;
    rateOfReturn = rateOfReturn - additionalCharges; //fix for additional

    segmentDetails = this.translateService
      .instant("howItsWorks.segmentReturn.lossMoreThanBuffer")
      .replace(
        "$$INDEX_RETURN$$",
        indexPerformance.toString().replace("-", "")
      )
      .replace("$$SEGMENT_RETURN$$", rateOfReturn)
      .replace("$$SEGMENT_BUFFER$$", buffer);

    if (indexPerformance < buffer) {
      segmentDetails = this.translateService
        .instant("howItsWorks.segmentReturn.lossLessThanBuffer")
        .replace(
          "$$INDEX_RETURN$$",
          indexPerformance.toString().replace("-", "")
        )
        .replace("$$SEGMENT_RETURN$$", rateOfReturn)
        .replace("$$SEGMENT_BUFFER$$", buffer);
    }
  }

  // uncapped
  if(cap == segmentRate && cap == indexPerformance) {
    console.log('UNCAPPED');

  }

  // IF enhanced upside, display updated content underneath the graphs
  // && this.series != "PLUS21"
  if(segment == SEGMENT_TYPE.ENHANCED_UPSIDE) {
    if(index == 0) {
      segmentDetails = this.translateService
      .instant("howItsWorks.segmentReturnEnhancedUpside.index0")
      .replace("$$INDEX_RETURN$$", indexPerformance)
      .replace("$$ENHANCED_UPSIDE_RATE$$", performance.enhancedUpsideRate)
      .replace("$$SEGMENT_RETURN$$", segmentRate);
    }
    else if(index == 1) {
      segmentDetails = this.translateService
      .instant("howItsWorks.segmentReturnEnhancedUpside.index1")
      .replace("$$INDEX_RETURN$$", indexPerformance)
      .replace("$$ENHANCED_UPSIDE_RATE$$", performance.enhancedUpsideRate)
      .replace("$$SEGMENT_RETURN$$", segmentRate);
    }
    else if(index == 2) {
      segmentDetails = this.translateService
      .instant("howItsWorks.segmentReturnEnhancedUpside.index2")
      .replace("$$INDEX_RETURN$$", indexPerformance)
      .replace("$$ENHANCED_UPSIDE_RATE$$", performance.enhancedUpsideRate)
      .replace("$$SEGMENT_RETURN$$", segmentRate);
    }
    else if(index == 3) {
      segmentDetails = this.translateService
      .instant("howItsWorks.segmentReturnEnhancedUpside.index3")
      .replace("$$INDEX_RETURN$$", indexPerformance)
      .replace("$$ENHANCED_UPSIDE_RATE$$", performance.enhancedUpsideRate)
      .replace("$$SEGMENT_RETURN$$", segmentRate)
      .replace("$$SEGMENT_BUFFER$$", buffer.toString().replace("-", ""))
    }
    else if(index == 4) {
      // index6Updated
      segmentDetails = this.translateService
      .instant("howItsWorks.segmentReturnEnhancedUpside.index4")
      .replace("$$INDEX_RETURN$$", indexPerformance)
      .replace("$$ENHANCED_UPSIDE_RATE$$", performance.enhancedUpsideRate)
      .replace("$$SEGMENT_RETURN$$", segmentRate)
      .replace("$$SEGMENT_BUFFER$$", buffer.toString().replace("-", ""))
    }
  }
        
  // IF enhanced upside, display updated content underneath the graphs
  if(this.isIE) {
    if(segment == SEGMENT_TYPE.STANDARD) {
      
      if(index == 0) {
        segmentDetails = "The index return is positive, but less than the Performance Cap​ Rate. Because the return is below the Performance Cap Rate, the​ segment return before contract fee is equal to the index return.​"
      }
      else if(index == 1) {
        segmentDetails = "The index return is positive.​ Because the gain is in excess of the Performance Cap Rate, the segment​ return before contract fee is equal to the Performance Cap Rate.​"
      }
      else if(index == 2) {
        segmentDetails = "The index return is negative.​ Since the return is within the Segment Buffer,​ the segment return before contract fee is zero.​";
      }
      else if(index == 3) {
        segmentDetails = "The index return is negative.​ Because the negative return exceeds the Segment​ Buffer, segment return before contract fee is negative.​";
      }
      else if(index == 4) {
        console.log("Getting another index???")
       }
    }
    // else if(segment === SEGMENT_TYPE.STEP_UP) {
    //   if(index == 0) {
    //     segmentDetails = "​The index return is negative.​ Because the return exceeds the Segment​ Buffer, the segment return before contract​ fee is negative.​";
    //   }
    //   else if(index == 1) {
    //     segmentDetails = "​The index return is negative.​ Since the return is within the Segment Buffer,​ the segment return before contract fee is zero.​";
    //   }
    //   else if(index == 2) {
    //     segmentDetails = "The index returns 0%.​ Because the return is equal to 0%, the​ segment return before contract fee is​ equal to the performance cap rate.​";
    //   }
    //   else if(index == 3) {
    //     segmentDetails = "The index return is positive, but less than the Performance​ Cap Rate. Because the return is positive, the segment return​ before contract fee is equal to the Performance Cap Rate.​";
    //   }
    //   else if(index == 4) {
    //     segmentDetails = "The index return is positive.​ Because the return is greater than the Performance Cap Rate, the​ segment return before contract fee is equal to the Performance Cap Rate.​";
    //   }
    // }
    // hardcoded fixes for IE
    if(indexPerformance == 4) {
      rateOfReturn = 4;
    }
    if(indexPerformance == 0) {
      rateOfReturn = 8;
    }
  }

  if(segment === SEGMENT_TYPE.STEP_UP) {
    if(index == 0) {
      segmentDetails = "​The index return is negative.​ Because the return exceeds the Segment​ Buffer, the segment return before contract​ fee is negative.​";
    }
    else if(index == 1) {
      segmentDetails = "​The index return is negative.​ Since the return is within the Segment Buffer,​ the segment return before contract fee is zero.​";
    }
    else if(index == 2) {
      segmentDetails = "The index returns 0%.​ Because the return is equal to 0%, the​ segment return before contract fee is​ equal to the performance cap rate.​";
    }
    else if(index == 3) {
      segmentDetails = "The index return is positive, but less than the Performance​ Cap Rate. Because the return is positive, the segment return​ before contract fee is equal to the Performance Cap Rate.​";
    }
    else if(index == 4) {
      segmentDetails = "The index return is positive.​ Because the return is greater than the Performance Cap Rate, the​ segment return before contract fee is equal to the Performance Cap Rate.​";
    }
  }

  //show segment maturity value, if not IE
   if(!this.isIE) {
    segmentDetails = this.populateSegmentMaturityDescriptionUnderGraph(segmentDetails, rateOfReturn);
  } 

  // round the rate of return to 2 decimal places
  if(this.series == IE_SERIES.B) {
    if(this.isIE) {
      rateOfReturn = Math.round(rateOfReturn * 100) / 100; // TODO: How can we append a symbol of * here and still get it to interpret as a number?
    }
    else if(this.flagService.featureFlags.isPrimerica || this.flagService.featureFlags.isPlusGuard) {
      rateOfReturn = Math.round(rateOfReturn * 10) / 10; // round to tenths for plus guard and primerica
    }
    else {
      rateOfReturn = parseFloat(rateOfReturn).toFixed(2);
    }
  }
  else {
    rateOfReturn = Math.round(rateOfReturn * 100) / 100;
  }
  return { return: rateOfReturn, detail: segmentDetails };
}

populateSegmentMaturityDescriptionUnderGraph(segmentDetails, rateOfReturn){
  let descriptionResult;

  // if we are on the national version, display the description with the dollar amount on a new line
  if(this.flagService.featureFlags.isNational) {
    descriptionResult = segmentDetails + this.translateService.instant("howItsWorks.segmentReturn.segmentMaturityValue") + '<p class="seg-value">$' +
    Math.round(this.getDollarAmount() + this.getDollarAmount() * (rateOfReturn / 100)).toLocaleString() + '</p>';
  }
  else if (this.flagService.featureFlags.isPrimerica || this.flagService.featureFlags.isPlusGuard) {
    descriptionResult = `${segmentDetails} Segment maturity value: \$${Math.round(this.getDollarAmount() + this.getDollarAmount() * (rateOfReturn / 100)).toLocaleString()}`
  }

  return descriptionResult; 
}

//sorting positive to first and negative to last
sortRates(rates): Array<number> {
  console.log(rates);
  const positive = [],
    negative = [];
  rates.forEach((element) => {
    if (element > 0) {
      positive.push(element);
    } else {
      negative.push(element);
    }
  });
  return positive.concat(negative);
}

//======================================= End Here ===================================== //

//============================== Start historical performance =============================== //

getHistoricalPerformance(seriesName: string) {
  console.log("HSERVICE");
  console.log("HSERVICE", seriesName);
  console.log("HSERVICE ghpd(seriesName)"), this.getHistoicalPerformanceData(seriesName);
  console.log("HSERVICE ghppv",  this.getHistoicalPerformacePresetValues(seriesName));
  var data =  forkJoin([
    this.getHistoicalPerformanceData(seriesName),
    this.getHistoicalPerformacePresetValues(seriesName),
    this.getAllIndices(),
    this.getProfile(),
  ]);
  console.log("data in ghp service", data)
  return data
}

/***
 *  get historical performace for a series
 ***/
getHistoicalPerformanceData(seriesName: string) {
  return this.getIEData().pipe(
    switchMap((ie: IE) => {
      const series = ie.series.find((x) => x.name == seriesName);
      return this.httpRequestService
        .request({
          url: series.performance.durations,
          method: API_METHODS.GET,
        })
        .pipe(
          map((data: any) => {
            return data.body.historicalPerformance;
          })
        );
    })
  );
}

/***
 *  get historical performace for a series
 ***/
getHistoicalPerformacePresetValues(seriesName: string) {
  return this.getIEData().pipe(
    switchMap((ie: IE) => {
      const series = ie.series.find((x) => x.name == seriesName);
      console.log("getIEData ------------------------------------------------")
      console.log("getIEData", series.performance.historicalPerformance)
      return this.httpRequestService
        .request({
          url: series.performance.historicalPerformance,
          method: API_METHODS.GET,
        })
        .pipe(
          map((data: any) => {
            const body = data.body;
            return body;
          })
        );
    })
  );
}



/***
 *  get Historical Return Values for guide
 ***/
getHistoricalReturnValues(seriesName: string): Observable<any> {
  return this.getIEData().pipe(
    switchMap((ie: IE) => {
      const series = ie.series.find((x) => x.name == seriesName);
      const requestOption: RequestOption = {
        url: series.performance.historicalReturns,
        method: API_METHODS.GET,
      };
      return this.httpRequestService.request(requestOption).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          throw throwError(err);
        })
      );
    })
  );
}

getHardcodedStandardYear3and4Values(data, segment, index, duration, buffer){

  // TODO: Populate the down market stuff

  let object = {
   indexRoR3Year: 0,
   segmentRoR3Year: 0,
   indexRoR4Year: 0,
   segmentRoR4Year: 0,
  }

  if(duration == '1yr') {
    if(buffer == '-10%') {
      if(index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
        object.indexRoR3Year = -8;
        object.segmentRoR3Year = 0;
        object.indexRoR4Year = -12;
        object.segmentRoR4Year = -2;
      }
    }
    else if(buffer == '-15%') {
      if(index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
        object.indexRoR3Year = -8;
        object.segmentRoR3Year = 0;
        object.indexRoR4Year = -12;
        object.segmentRoR4Year = -2;
      }
    }
  }

  else if(duration == '6yr') {
    if(buffer == '-10%') {
      if(index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
        object.indexRoR3Year = -8;
        object.segmentRoR3Year = 0;
        object.indexRoR4Year = -16;
        object.segmentRoR4Year = -6;
      }
    }
    else if(buffer == '-15%') {
      if(index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
        object.indexRoR3Year = -8;
        object.segmentRoR3Year = 0;
        object.indexRoR4Year = -18;
        object.segmentRoR4Year = -3;
      }
    }
    else if(buffer == '-20%') {
      if(index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
        object.indexRoR3Year = -15;
        object.segmentRoR3Year = 0;
        object.indexRoR4Year = -22;
        object.segmentRoR4Year = -2;
      }
    }
    else if(buffer == '-30%') {
      if(index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
        object.indexRoR3Year = -20;
        object.segmentRoR3Year = 0;
        object.indexRoR4Year = -33;
        object.segmentRoR4Year = -3;
      }
    }
  }
  return object;
}


getHardCodedEnhancedUpsideValues(enhancedUpside, buffer) {
  let hardcodedEnhancedUpsides = {
    indexRoRDownMarket1: 0,
    indexRoRDownMarket2: 0,
    segmentRoRDownMarket1: 0,
    segmentRoRDownMarket2: 0,
  }
  
  if(buffer == '-10%') {
    hardcodedEnhancedUpsides.indexRoRDownMarket1 = -5;
    hardcodedEnhancedUpsides.indexRoRDownMarket2 = -15;
    hardcodedEnhancedUpsides.segmentRoRDownMarket1 = 0;
    hardcodedEnhancedUpsides.segmentRoRDownMarket2 = -5;
  }
  else if(buffer == '-15%') {
    hardcodedEnhancedUpsides.indexRoRDownMarket1 = -13;
    hardcodedEnhancedUpsides.indexRoRDownMarket2 = -18;
    hardcodedEnhancedUpsides.segmentRoRDownMarket1 = 0;
    hardcodedEnhancedUpsides.segmentRoRDownMarket2 = -3;
  }

  return hardcodedEnhancedUpsides;
}

getHardCodedDualDirectionValues(data, segment, index, duration, buffer){
  let hardcodedDualDirectionValues = {
    indexRoRDownMarket1: 0,
    indexRoRDownMarket2: 0,
    segmentRoRDownMarket1: 0,
    segmentRoRDownMarket2: 0,
  };

  if(buffer == '-10%') {
    hardcodedDualDirectionValues.indexRoRDownMarket1 = -8;
    hardcodedDualDirectionValues.indexRoRDownMarket2 = -12;
    hardcodedDualDirectionValues.segmentRoRDownMarket1 = -8;
    hardcodedDualDirectionValues.segmentRoRDownMarket2 = -12;
  }
  else if(buffer == '-15%') {
    hardcodedDualDirectionValues.indexRoRDownMarket1 = -12;
    hardcodedDualDirectionValues.indexRoRDownMarket2 = -18;
    hardcodedDualDirectionValues.segmentRoRDownMarket1 = -12;
    hardcodedDualDirectionValues.segmentRoRDownMarket2 = -18;
  }
  else if(buffer == '-20%') {
    hardcodedDualDirectionValues.indexRoRDownMarket1 = -17;
    hardcodedDualDirectionValues.indexRoRDownMarket2 = -23;
    hardcodedDualDirectionValues.segmentRoRDownMarket1 = -17;
    hardcodedDualDirectionValues.segmentRoRDownMarket2 = -23;
  }

  return hardcodedDualDirectionValues;
}

round5(num) {
  // https://stackoverflow.com/questions/18953384/javascript-round-up-to-the-next-multiple-of-5


  // min values are
  // -12, -16, -22, -33

  // -22 is a problem with SP / 6yr / -20

  // if between 1 and 30, use 4 as factor with floor
  if(num == -12) {
    return (Math.ceil(num / 4) * 4); // use floor or ceil
  }
  // else if (num == -16) {
  //   return (Math.ceil(num / 5) * 5); // use floor or ceil
  // }
  // else if (num == -22) {
  //   return num - 5;
  // }
  // else if (num == -33) {
  //   return (Math.ceil(num / 5) * 5); // use floor or ceil
  // }
  else {
    return (Math.floor(num / 5) * 5); // use floor or ceil
  }
}

getIndexName(item) {
  let indexName;

  // ID, long name, short name 
  // DJR    DJ US Real Estate ETF    DJ Real Estate
  // MEM    MSCI Emerging Markets    MSCI EM
  // GSP    Gold SPDR Index    Gold SPDR
  // XLE    Energy Sector SPDR    Energy SPDR
  // MET    MSCI EAFE ETF Index    MSCI EAFE ETF
  // GLD    London Gold Market Fixing Price    Gold Index
  // SP     S&P 500 Index    S&P 500
  // RUS    Russell 2000 Index    Russell 2000
  // MSC    MSCI EAFE Index    MSCI EAFE
  // OIL    NYMEX West Texas Intermd Crude Oil    Oil Index
  // NSD    NASDAQ 100    NASDAQ 100
  // SX5    EURO STOXX 50    EURO STOXX 50
  // FIN    Financial SPDR Index    Financial SPDR

  if(item.indexid == "SP ") {
    indexName = 'S&P 500®'
  }
  else if(item.indexid == "RUS") {
    indexName = 'Russell 2000®'
  }
  else if(item.indexid == "MET") {
    indexName = 'MSCI EAFE ETF'
  }
  else if(item.indexid == "MEM") {
    indexName = 'MSCI EM'
  }
  else if(item.indexid == "NSD") {
    indexName = 'NASDAQ 100®'
  }
  else if(item.indexid == "MSC") {
    indexName = 'MSCI EAFE'
  }
  else if(item.indexid == "SX5") {
    indexName = 'EURO STOXX 50'
  }

  return indexName;
}

//  CHECK options in JSON object FOR PLUS AND PLUS 21
getOptionName(item) {
  let optionName;

  // S16 AL --> option=ALC and segopttype=AL
  // S21 AL --> option=S21 and segopttype=AL

  // PLUS 21
  if(item.option == "S21") {
    optionName = "PLUS21"
  }
  // the annual lock for PLUS uses ALC on the API (for some reason).
  else if(item.option == "S16" || item.option == "ALC") {
    optionName = "PLUS"
  }

  return optionName;
}

getSegmentName(item){
  let segmentName;

  if(item.segopttype == "ST") {
    segmentName = "Standard"
  }
  else if(item.segopttype == "AL") {
    segmentName = "Annual Lock"
  }
  else if(item.segopttype == "SU") {
    segmentName = "Step Up"
  }
  else if(item.segopttype == "DD") {
    segmentName = "Dual Direction"
  }
  else if(item.segopttype == "EU") {
    segmentName = "Enhanced Upside"
  }
  return segmentName;
}

getEnhancedUpsideRate(item) {
  // enhupsiderate
  if(item.enhupsiderate == "125") {
    return '125%'
  }
  else if (item.enhupsiderate == "110") {
    return '110%' 
  }
}

getSegmentProductCode(item){
  // S21ASH
  // S21BSH
  // S21CSH

  // s21
  if(item.prodcode == 'S21ASH') {
    return 'S21ASH';
  }
  // s21
  else if(item.prodcode == 'S21BSH') {
    return 'S21BSH';
  }
  // s21
  else if(item.prodcode == 'S21CSH') {
    return 'S21CSH';
  }
  // s16
  else if(item.prodcode == 'SADSHR') {
    return 'SADSHR';
  }
  // scsincome
  // SINASH
  else if(item.prodcode == 'SINASH') {
    return 'SINASH';
  }
  // scsincome
  // SINBSH 
  else if(item.prodcode == 'SINBSH') {
    return 'SINBSH';
  }
  // IE21BS
  else if(item.prodcode == 'IE21BS') {
    return 'IE21BS';
  }
  // IE21AS
 else if(item.prodcode == 'IE21AS') {
      return 'IE21AS';
    }
  // IE21AS
 else if(item.prodcode == 'IECSHR') {
            return 'IECSHR';
          }
  console.log(item.prodcode);
}

hydrateObject(item, index, additionalCharges, hardcodedMin, performaceRate, segmentRates, segment) {
  let objPerformance = {
    id: item.duration + 'yr',
    max: this.calculateMaxValue(Math.max(item.cases[0].indexROR.toFixed(0), item.cases[1].indexROR.toFixed(0)), segment, item),
    min: hardcodedMin, // Call calculateMinValue function
    interval: this.calculateInterval(parseInt(item.perfcaprate).toFixed(0), segment, item),
    performaceCapRate: this.roundNumberToTenths(item.perfcaprate),
    buffer: item.buffer + '%',
    performaceRate: this.roundArrayToHundreths(performaceRate),
    segmentRates: this.roundArrayToHundreths(segmentRates),
    additionalCharges: additionalCharges,
    index: index,
    option: item.option,
    enhancedUpsideRate: item.enhupsiderate,
    prodcode: item.prodcode,
    segopttype: item.segopttype,
  };

  return objPerformance;
}

getDynamicStandardData(data, segment, index, duration, buffer) {
  let performanceArray = [];

  // compare the buffer and duration from the front-end to the JSON from the API
  // plug this into buffer and duration at some point
  let newBuffer = buffer.toString().substring(0,3);
  let newDuration = duration.toString().substring(0,1);
  let hardCodedProductCode = ''; // hardcode to series A until we deteremine from business what they want rendered.
  if(this.product == PRODUCT_TYPE.IE) {
    hardCodedProductCode = 'IE21BS';
  } 

  //let hardCodedScenario3And4Object = this.getHardcodedStandardYear3and4Values(data, segment, index, duration, buffer); // TODO: try to pass with the values defined here, otherwise move into the data.forEach and pass the item values

  //console.log(hardCodedScenario3And4Object);

  data.forEach(function(item) {
    // the app and API do not have the same index names, so we need to check each one
    let indexName = this.getIndexName(item);
    let optionName = this.getOptionName(item);
    let segmentName = this.getSegmentName(item);
    let productCode = this.getSegmentProductCode(item);
    let objPerformance;

    //index performance rates
    let column3IndexPerformanceRateUpMarket = -12;
    let column4IndexPerformanceRateUpMarket = -8;
    let column5IndexPerformanceRateDownMarket = 0;
    let column1IndexPerformanceRateDownMarket = item.cases[0].indexROR;
    let column2IndexPerformanceRateDownMarket =  item.cases[1].indexROR; 

    //segment rates of return
    let column3SegmentRatesOfReturnUpMarket = -2;
    let column4SegmentRatesOfReturnUpMarket = 0;
    let column5SegmentRatesOfReturnDownMarket = item.perfcaprate;
    let column1SegmentRatesOfReturnDownMarket = item.cases[0].segmentROR;
    let column2SegmentRatesOfReturnDownMarket = item.cases[1].segmentROR;

    let minValue;
    if(duration == '5yr'){
      minValue = -50;
    }else{
      minValue = -20;
    }
          
    if(item.buffer == newBuffer && item.duration == newDuration && segment == segmentName && hardCodedProductCode == productCode){
      objPerformance = this.hydrateObject(
        item, 
        indexName,
        0, 
        minValue, 
        [column3IndexPerformanceRateUpMarket, column4IndexPerformanceRateUpMarket, column5IndexPerformanceRateDownMarket, column1IndexPerformanceRateDownMarket, column2IndexPerformanceRateDownMarket],
        [column3SegmentRatesOfReturnUpMarket, column4SegmentRatesOfReturnUpMarket, column5SegmentRatesOfReturnDownMarket, column1SegmentRatesOfReturnDownMarket, column2SegmentRatesOfReturnDownMarket],
      );
      performanceArray.push(objPerformance);
    }
    // Hardcoded uncapped  exception for MSCI EAFE, 6 year, -10
/*     if(item.buffer == '-10' && item.duration == '6' && item.indexid == "MSC" && segment == segmentName && this.series == optionName && hardCodedProductCode == productCode){
      objPerformance = this.hydrateObject(
        item, 
        'MSCI EAFE', 
        0, 
        -20,
        [105, 150, -8, -16],
        [105, 150, 0, -1]
      );
      performanceArray.push(objPerformance);
    } */

    // if(item.buffer == '-15' && item.duration == '6' && item.indexid == "MSC" && segment == segmentName && this.series == optionName && hardCodedProductCode == productCode){
    //   objPerformance = this.hydrateObject(
    //     item, 
    //     'MSCI EAFE', 
    //     0, 
    //     -20,
    //     [105, 150, -8, -16],
    //     [105, 150, 0, -6]
    //   );
    //   performanceArray.push(objPerformance);
    // }

    // if(item.buffer == '-20' && item.duration == '6' && item.indexid == "MSC" && segment == segmentName && this.series == optionName && hardCodedProductCode == productCode){
    //   objPerformance = this.hydrateObject(
    //     item, 
    //     'MSCI EAFE', 
    //     0, 
    //     -20,
    //     [105, 150, -8, -16],
    //     [105, 150, 0, -6]
    //   );
    //   performanceArray.push(objPerformance);
    // }

    // if(item.cases[0].indexROR == 699.99) {
    //   item.cases[0].indexROR = 
    // }

    // if(item.cases[1].indexROR == 1299.99){

    // }
    
    // hardcoded exception for uncapped value
 /*    if(item.buffer == '-10' && item.duration == '6' && item.indexid == "SP " && segment == segmentName && this.series == optionName && hardCodedProductCode == productCode){
      objPerformance = this.hydrateObject(
        item, 
        'S&P 500®', 
        0, 
        -20,
        [105, 150, -8, -16],
        [105, 150, 0, -6]
      );
      performanceArray.push(objPerformance);
    }

    // hardcoded exception for uncapped value
    if(item.buffer == '-10' && item.duration == '6' && item.indexid == "MET" && segment == segmentName && this.series == optionName && hardCodedProductCode == productCode){
      objPerformance = this.hydrateObject(
        item, 
        'MSCI EAFE ETF', 
        0, 
        -20,
        [105, 150, -8, -16],
        [105, 150, 0, -6]
      );
      performanceArray.push(objPerformance);
    }

    if(item.buffer == newBuffer && item.duration == newDuration && segment == segmentName && this.series == optionName && hardCodedProductCode == productCode){
      objPerformance = this.hydrateObject(
        item, 
        indexName,
        0, 
        this.round5(hardCodedScenario3And4Object.indexRoR4Year), 
        [item.cases[0].indexROR, item.cases[1].indexROR, hardCodedScenario3And4Object.indexRoR3Year, hardCodedScenario3And4Object.indexRoR4Year],
        [item.cases[0].segmentROR, item.cases[1].segmentROR, hardCodedScenario3And4Object.segmentRoR3Year, hardCodedScenario3And4Object.segmentRoR4Year], 
      );
      performanceArray.push(objPerformance);
    } */

  }, this); // https://stackoverflow.com/questions/45175605/how-to-call-this-inside-for-example-foreach-in-class

  return performanceArray;
}

hydrateUpDownMarketObject(item) {
  const upDownMarketObject = {
    upward: {
      0: this.roundNumToHundreths(item.cases[0].segmentROR),
      1: this.roundNumToHundreths(item.cases[0].indexROR),
      2: this.roundNumToHundreths(0),
      3: this.roundNumToHundreths(-5), 
      4: this.roundNumToHundreths(item.cases[1].segmentROR),
      5: this.roundNumToHundreths(item.cases[1].indexROR),
      6: this.roundNumToHundreths(-2),
      7: this.roundNumToHundreths(-12),
      8: this.roundNumToHundreths(item.cases[2].segmentROR),
      9: this.roundNumToHundreths(item.cases[2].indexROR),
      10: this.roundNumToHundreths(item.cases[3].segmentROR),
      11: this.roundNumToHundreths(item.cases[3].indexROR),
    },
    downward: {
      0: this.roundNumToHundreths(item.cases[0].segmentROR),
      1: this.roundNumToHundreths(item.cases[0].indexROR),
      2: this.roundNumToHundreths(0),
      3: this.roundNumToHundreths(-5),
      4: this.roundNumToHundreths(item.cases[1].segmentROR),
      5: this.roundNumToHundreths(item.cases[1].indexROR),
      6: this.roundNumToHundreths(-12),
      7: this.roundNumToHundreths(-22),
      8: this.roundNumToHundreths(0),
      9: this.roundNumToHundreths(-10),
      10: this.roundNumToHundreths(-8),
      11: this.roundNumToHundreths(-18),
    },
    index: this.getIndexName(item)
  }

  return upDownMarketObject;
}

getDynamicAnnualLockData(data, segment, index, duration, buffer, marketTrend) {
  // caseId
  // AL-1.1
  // AL-1.3
  // AL-1.5
  // AL-1.6
  // AL-2.1 // DOWNMARKET
  // AL-2.3

  let performanceArray = [];
  let upDownMarketArray = [];
  let upDownMarketObject = {
    upward: {},
    downward: {},
  };

  let year4indexRoR, year4SegmentRoR, year5indexRoR, year5SegmentRoR, year6IndexRoR, year6SegmentRoR;

  let newBuffer = buffer.toString().substring(0,3);
  let newDuration = duration.toString().substring(0,1);
  let hardCodedProductCode = ''; // hardcode to series A until we deteremine from business what they want rendered. 

  if(this.series == IE_SERIES.B) {
    hardCodedProductCode = 'IE21BS';
  } 

 
  
  data.forEach(function(item) {

    // the app and API do not have the same index names, so we need to check each one
    let indexName = this.getIndexName(item);
    let optionName = this.getOptionName(item);
    let segmentName = this.getSegmentName(item);
    let productCode = this.getSegmentProductCode(item);
    let objPerformance;

    if(item.buffer == newBuffer && item.duration == newDuration && segment == segmentName && this.series == optionName && hardCodedProductCode == productCode){
      
      // IF(Upward, use values from the API. Otherwise, the downmarket scenarios for years 5 and 6 are hardcoded)
      if(marketTrend === MARKET_SCENERIO.UPWARD) {
        year4indexRoR = -12;
        year4SegmentRoR = -2;
        year5indexRoR = item.cases[2].indexROR;
        year5SegmentRoR = item.cases[2].segmentROR;
        year6IndexRoR = item.cases[3].indexROR;
        year6SegmentRoR = item.cases[3].segmentROR;
      }
      else {
        year4indexRoR = -22;
        year4SegmentRoR = -12;
        year5indexRoR = -10;
        year5SegmentRoR = 0;
        year6IndexRoR = -18;
        year6SegmentRoR = -8;
      }

      objPerformance = this.hydrateObject(
        item,
        indexName,
        0,
        -20,
        [item.cases[0].indexROR, -5, item.cases[1].indexROR, year4indexRoR, year5indexRoR, year6IndexRoR],
        [item.cases[0].segmentROR, 0, item.cases[1].segmentROR, year4SegmentRoR, year5SegmentRoR, year6SegmentRoR],
        segment
      );
      performanceArray.push(objPerformance);
      // for annual lock, we need to pass the API an object that includes both the upward and downward values
      upDownMarketObject = this.hydrateUpDownMarketObject(item);
      // push all the updown market objects onto an array
      upDownMarketArray.push(upDownMarketObject);
    }
  }, this);

  // iterate through the array, check if the index is equal, and update the values of the up and down market bars 
  upDownMarketArray.forEach(function(upOrDownMarketObject) {
    if(index == upOrDownMarketObject.index) {
      this.upMarketBars = upOrDownMarketObject.upward;
      this.downMarketBars = upOrDownMarketObject.downward;
    }
    
  }, this);

  return performanceArray;
}

getDynamicStepUpData(data, segment, index, duration, buffer) {
  let performanceArray = []; // object needs to match testObject.performance
  let newBuffer = buffer.toString().substring(0,3);
  let newDuration = duration.toString().substring(0,1);
  let hardCodedProductCode; // hardcode to series B

  if(this.product == PRODUCT_TYPE.IE) {
    hardCodedProductCode = 'IE21BS';
  } 

  data.forEach(function(item) {

    // the app and API do not have the same index names, so we need to check each one
    let indexName = this.getIndexName(item);
    let optionName = this.getOptionName(item);
    let segmentName = this.getSegmentName(item);
    let productCode = this.getSegmentProductCode(item);
    let objPerformance;

    if(item.buffer == newBuffer && item.duration == newDuration && segment == segmentName && hardCodedProductCode == productCode){
      objPerformance = this.hydrateObject(
        item, 
        indexName,
        0, 
        -12, 
        [-12, -8, 0, item.cases[0].indexROR, item.cases[1].indexROR],
        [-2, 0, parseInt(item.perfcaprate), item.cases[0].segmentROR, item.cases[1].segmentROR],
      );
      performanceArray.push(objPerformance);
    }

  }, this); // https://stackoverflow.com/questions/45175605/how-to-call-this-inside-for-example-foreach-in-class

  return performanceArray;
}

getDynamicDualDirectionData(data, segment, index, duration, buffer) {
  let performanceArray = []; 
  let newBuffer = buffer.toString().substring(0,3);
  let newDuration = duration.toString().substring(0,1);
  let hardCodedProductCode; // hardcode to series B 
  
  if(this.product == PRODUCT_TYPE.IE) {
    hardCodedProductCode = 'IE21BS';
  } 

  //let hardCodedScenario3And4Object = this.getHardCodedDualDirectionValues(data, segment, index, duration, buffer); 
  
  data.forEach(function(item) {

    // the app and API do not have the same index names, so we need to check each one
    let indexName = this.getIndexName(item);
    let optionName = this.getOptionName(item);
    let segmentName = this.getSegmentName(item);
    let productCode = this.getSegmentProductCode(item);
    let objPerformance;

    if(item.buffer == newBuffer && item.duration == newDuration && segment == segmentName && hardCodedProductCode == productCode){
      objPerformance = this.hydrateObject(
        item, 
        indexName,
        0, 
        -12, // interval. -10 == 10, -15 = 10, -20 = 5
        [item.cases[0].indexROR, item.cases[1].indexROR, -8, -12],
        [item.cases[0].segmentROR, item.cases[1].segmentROR, -8, -12], 
      );
      performanceArray.push(objPerformance);
    }

  }, this); // https://stackoverflow.com/questions/45175605/how-to-call-this-inside-for-example-foreach-in-class

  return performanceArray;

}

getDynamicEnhancedUpsideData(data, segment, index, duration, buffer, enhancedUpside) {
  // [23] in array, "EU-UP1", "EU-UP2", "EU-UP3"
  let newBuffer = buffer.toString().substring(0,3);
  let newDuration = duration.toString().substring(0,1);
  let hardCodedProductCode = ''; // hardcode to series A until we deteremine from business what they want rendered. 

  if(this.product == IE_SERIES.B) {
    hardCodedProductCode = 'IE21BS';
  } 


  let performanceArray = [];

  let hardCodedDownMarketValues = this.getHardCodedEnhancedUpsideValues(enhancedUpside, buffer);

  // TODO: Figure out why the segment rates of return are not going to the proper values 
  data.forEach(function(item) {

    let indexName = this.getIndexName(item);
    let optionName = this.getOptionName(item);
    let segmentName = this.getSegmentName(item);
    let productCode = this.getSegmentProductCode(item);
    let enhancedUpsideNameFromAPI = this.getEnhancedUpsideRate(item);
    let objPerformance;

    if(item.buffer == newBuffer && item.duration == newDuration && segment == segmentName && this.series == optionName && enhancedUpside == enhancedUpsideNameFromAPI && hardCodedProductCode == productCode){
      objPerformance = this.hydrateObject(
        item,
        indexName,
        0,
        -20,
        [item.cases[0].indexROR, item.cases[1].indexROR, item.cases[2].indexROR, hardCodedDownMarketValues.indexRoRDownMarket1, hardCodedDownMarketValues.indexRoRDownMarket2],
        [item.cases[0].segmentROR, item.cases[1].segmentROR, item.cases[2].segmentROR, hardCodedDownMarketValues.segmentRoRDownMarket1, hardCodedDownMarketValues.segmentRoRDownMarket2],
      );
      performanceArray.push(objPerformance);
    }

  }, this);

  return performanceArray;
}

roundNumberToTenths(num) {
  return Math.round(num * 10) / 10;
}

roundArrayToHundreths(array) {
  // const roundedArray = array.map(num => Math.round(num * 100) / 100);
  const roundedArray = array.map(num => parseFloat(num).toFixed(2));
  return roundedArray;
}

roundNumToHundreths(num) {
  return parseFloat(num).toFixed(2);
}

calculateMaxValue(maxValue, segment, item) {

  // hardcode the max y-axis value for annual lock as it's dependent on the line graph 
  if(segment == SEGMENT_TYPE.ANNUAL_LOCK) {
    return 50;
  }

  if(item.buffer == '-10' && item.duration == '6' && (item.indexid == "SP " || item.indexid == "MET" || item.indexid == "MSC") && (item.cases[0].caseId == "ST-UP1" && item.cases[1].caseId == "ST-UP2")){
    return 150;
  }

  // IF(indexROR is less than 20, add 5 to it)
  if (maxValue <= 20) {
    return maxValue + 2;
  }
  // IF(either indexROR is 0 to 200, add 10 to it)
  else if (maxValue < 200) {
    return maxValue + 10;
  }
  // IF(either indexROR is 200 to 1000, add 50 to it)
  else if (maxValue >= 200 && maxValue <= 1000) {
    return maxValue + 50;
  }
  // IF(either indexROR is greater than 1000, add 100 to it)
  else if (maxValue > 1000) {
    return maxValue + 100;
  }
  else {
    console.error('Return value unknown');
  }
}

// TODO: not sure we will need min value. may need to remain hardcoded
calculateMinValue(minValue) {
  // IF(indexROR is less than 20, add 5 to it)
  if (minValue <= 30) {
    return minValue - 2;
  }
  // IF(either indexROR is 0 to 200, add 10 to it)
  else if (minValue < 200) {
    return minValue - 10;
  }
  // IF(either indexROR is 200 to 1000, add 50 to it)
  else if (minValue >= 200 && minValue <= 1000) {
    return minValue - 50;
  }
  // IF(either indexROR is greater than 1000, add 100 to it)
  else if (minValue > 1000) {
    return minValue - 100;
  }
  else {
    console.error('Return value unknown');
  }
}

calculateInterval(perfcaprate, segment, item) {
  // hardcode the max y-axis value for annual lock as it's dependent on the line graph 
  if(segment == SEGMENT_TYPE.ANNUAL_LOCK) {
    return 10;
  }

  // hardcoded for uncapped rates, MSCI and Standard, 6 year, -10 
  if(item.buffer == '-10' && item.duration == '6' && (item.indexid == "SP " || item.indexid == "MET" || item.indexid == 'MSC') && (item.cases[0].caseId == "ST-UP1" && item.cases[1].caseId == "ST-UP2")){
    return 10;
  }

  if (perfcaprate <= 20) {
    return 2;
  }

  else if (perfcaprate == 80 || perfcaprate == 90) {
    return 10;
  }

  else if (perfcaprate == 200) {
    return 25;
  }

  else if (perfcaprate > 200 && perfcaprate < 300) {
    return 20;
  }

  else if (perfcaprate == 300) {
    return 20;
  }

  else if (perfcaprate >= 125 && perfcaprate <= 150) {
    return 25;
  }

  else if (perfcaprate < 200) {
    return 5;
  }

  else if (perfcaprate <= 1000) {
    return 50;
  }

  else {
    console.error('Return value unknown');
    return 10;
  }
}

//======================================= End Here ===================================== //

/***
 *  Open Modal
 ***/
async openModal(componentName, className) {
  const modal = await this.modalController.create({
    component: componentName,
    cssClass: className,
  });
  return await modal.present();
}

//======================================= End Here ===================================== //
}
