import { TOOLS_NAME } from "./../../../constants/constants";
import { FeatureFlagService } from "./../../../core/services/feature-flag.service";
import { OnInit } from "@angular/core";
import { Location } from '@angular/common';
import { LinkType, BoxButtonIcon, LinkIcon, Orientation, } from "equitable-component-library";
import { Profile, } from "../../../core/models/app.models";
import { UtilityService } from "../../../core/services/utility.service";
import { FormControl, Validators, FormBuilder, } from "@angular/forms";
import { IE_SERIES, ROUTE_PATH, PRODUCT_TYPE, CUSTOMIZATION_TYPE, CONFIG_DATA, } from "../../../constants/constants";
import { SITE } from "src/assets/sites";
import { IeService } from "../../services/ie.service";
import { ModalPopupComponent } from 'src/app/core/components/modal-popup/modal-popup.component';
import { CoreService } from "../../../core/services/core.service";
import { TealiumUtagService } from '../../../tealium/utag.service';
import { TranslateService } from '@ngx-translate/core';
var IeAdvancedToolResourcesDashboardComponent = /** @class */ (function () {
    function IeAdvancedToolResourcesDashboardComponent(utilityService, translate, ieService, flagService, coreService, formBuilder, location, tealium) {
        this.utilityService = utilityService;
        this.translate = translate;
        this.ieService = ieService;
        this.flagService = flagService;
        this.coreService = coreService;
        this.formBuilder = formBuilder;
        this.location = location;
        this.tealium = tealium;
        this.LinkType = LinkType;
        this.LinkIcon = LinkIcon;
        this.Orientation = Orientation;
        this.isDisplay = true;
        this.isIR = CONFIG_DATA.ISIR;
        this.toolsDashboardData = {
            resource: [],
            tools: [],
        };
        this.getSeries = this.ieService.series || IE_SERIES.B;
        this.site = SITE;
    }
    IeAdvancedToolResourcesDashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dashboardForm = this.formBuilder.group({
            seriesDropDown: new FormControl({ value: this.getSeries, disabled: false }, Validators.required),
        });
        //reset selection
        if (this.coreService.product !== PRODUCT_TYPE.IE) {
            this.coreService.profile.next({
                name: "",
                type: CUSTOMIZATION_TYPE.REPORT,
                profile: new Profile(),
            });
            this.coreService.report = new Profile();
        }
        this.coreService.product = PRODUCT_TYPE.IE;
        this.dashboardForm.valueChanges.subscribe(function (data) {
            //reset report data (if any)
            _this.ieService.report = new Profile();
            _this.ieService.customizationType = CUSTOMIZATION_TYPE.REPORT;
            //reset selected profile
            _this.ieService.currentProfile = "";
            _this.dropDownSection(data.seriesDropDown);
            _this.coreService.profile.next({
                name: "",
                type: CUSTOMIZATION_TYPE.REPORT,
                profile: new Profile(),
            });
        });
        // Dropdown Data
        this.translate.get("ieDashboard").subscribe(function (translated) {
            _this.toolTipText = translated.tooltipText;
            _this.investorProfiles = translated.investorProfiles;
            _this.seriesOptions = [];
            if (_this.flagService.featureFlags.isPrimerica) {
                _this.seriesOptions = [
                    {
                        id: IE_SERIES.B,
                        value: translated.seriesB,
                    },
                ];
            }
            else {
                _this.seriesOptions = [
                    {
                        id: IE_SERIES.B,
                        value: translated.seriesB,
                    },
                    {
                        id: IE_SERIES.B,
                        value: translated.seriesB,
                    },
                ];
            }
            _this.isNotPlusGuard = !_this.flagService.featureFlags.isPlusGuard;
            for (var toolName in _this.flagService.featureFlags.ie.tools) {
                switch (toolName) {
                    case TOOLS_NAME.HOW_SIO_WORKS:
                        if (_this.flagService.featureFlags.ie.tools[TOOLS_NAME.HOW_SIO_WORKS]) {
                            _this.toolsDashboardData.tools.push({
                                icon: BoxButtonIcon.HowSIOWorks,
                                label: translated.howItWorks,
                                routePath: ROUTE_PATH.IE.TOOLS.HOW_SIO_WORKS,
                            });
                        }
                        break;
                    case TOOLS_NAME.CREATE_A_REPORT:
                        if (_this.flagService.featureFlags.ie.tools[TOOLS_NAME.CREATE_A_REPORT]) {
                            console.log('CARDCLICK this.IeService.series', _this.ieService.series);
                            _this.toolsDashboardData.tools.push({
                                icon: BoxButtonIcon.CreateAReport,
                                label: translated.createReport,
                                routePath: ROUTE_PATH.IE.TOOLS.CREATE_REPORT,
                            });
                        }
                        break;
                    case TOOLS_NAME.HISTORICAL_PERFORMANCE:
                        if (_this.flagService.featureFlags.ie.tools[TOOLS_NAME.HISTORICAL_PERFORMANCE]) {
                            console.log('CARDCLICK this.IeService.series', _this.ieService.series);
                            _this.toolsDashboardData.tools.push({
                                icon: BoxButtonIcon.HistoricalPerformance,
                                label: translated.historicalPerformance,
                                routePath: ROUTE_PATH.IE.TOOLS.HISTORICAL_PERFORMANCE,
                            });
                        }
                        break;
                    case TOOLS_NAME.PRODUCT_OVERVIEW:
                        if (_this.flagService.featureFlags.ie.tools[TOOLS_NAME.PRODUCT_OVERVIEW]) {
                            _this.toolsDashboardData.tools.push({
                                icon: BoxButtonIcon.ProductOverview,
                                label: translated.productOverview,
                                routePath: ROUTE_PATH.IE.DISCOVER,
                            });
                        }
                        break;
                    case TOOLS_NAME.INVESTOR_PROFILE:
                        if (_this.flagService.featureFlags.ie.tools[TOOLS_NAME.INVESTOR_PROFILE]) {
                            _this.toolsDashboardData.tools.push({
                                icon: BoxButtonIcon.InvestorProfiles,
                                label: translated.investorProfiles,
                                routePath: ROUTE_PATH.IE.TOOLS.INVESTOR_PROFILE,
                            });
                        }
                        break;
                    default:
                        break;
                }
            }
            if (_this.getSeries === IE_SERIES.B) {
                _this.headingName = translated.headerTitlePlus;
            }
            else {
                _this.headingName = translated.headerTitle;
            }
        });
        this.toolsDashboardData.tools = [
            {
                icon: BoxButtonIcon.Calculator,
                label: "ieDashbaord.tools.taxCalculators",
                routePath: ROUTE_PATH.IE.TOOLS.TAX_DEFERRAL,
            },
            {
                icon: BoxButtonIcon.HowSIOWorks,
                label: "ieDashbaord.tools.diversification",
                routePath: ROUTE_PATH.IE.TOOLS.DIVERSIFICATION,
            },
            {
                icon: BoxButtonIcon.HowSIOWorks,
                label: "ieDashbaord.tools.taxEfficientIncome",
                routePath: ROUTE_PATH.IE.TOOLS.HOW_IT_WORKS,
            },
            {
                icon: BoxButtonIcon.HowSIOWorks,
                label: "How SIO works",
                routePath: ROUTE_PATH.IE.TOOLS.HOW_SIO_WORKS,
            },
            {
                icon: BoxButtonIcon.CreateAReport,
                label: "ieDashbaord.tools.createReport",
                routePath: ROUTE_PATH.IE.TOOLS.CREATE_REPORT,
            },
            {
                icon: BoxButtonIcon.HistoricalPerformance,
                label: "Market history",
                routePath: ROUTE_PATH.IE.TOOLS.HISTORICAL_PERFORMANCE_PLUS,
            },
            {
                icon: BoxButtonIcon.InvestorProfiles,
                label: "ieDashbaord.tools.investorProfiles",
                routePath: ROUTE_PATH.IE.TOOLS.INVESTOR_PROFILE,
            },
            {
                icon: BoxButtonIcon.PerformanceCapRates,
                label: "Performance cap rate",
                routePath: ROUTE_PATH.IE.TOOLS.MARKET_TREND,
                newTab: true,
            },
            {
                icon: BoxButtonIcon.ProductOverview,
                label: "ieDashbaord.tools.productOverview",
                routePath: ROUTE_PATH.IE.DISCOVER,
            },
            {
                icon: "growth-opportunities.svg",
                label: "ieDashbaord.tools.fundFocus",
                routePath: ROUTE_PATH.IE.TOOLS.FUND_FOCUS,
            },
        ];
        this.getResource(this.getSeries);
    };
    IeAdvancedToolResourcesDashboardComponent.prototype.dropDownSection = function (seriesDropDown) {
        throw new Error("Method not implemented.");
    };
    IeAdvancedToolResourcesDashboardComponent.prototype.getResource = function (data) {
        var _this = this;
        this.ieService.getIEResources(data).subscribe(function (kits) {
            _this.learningResources = kits;
            console.log('IE SERIES =' + " " + _this.ieService.series);
        });
    };
    // On click Card button
    /*   cardClick(link: string) {
    
        this.utilityService.navigate(link, {
          section: "ie",
        });
      } */
    /*   // On click Card button
      cardClick(link: string, label: string) {
       console.log('CARDCLICK link', link)
       console.log('CARDCLICK label ', link)
       console.log('CARDCLICK this.ieService.series ', this.ieService.series)
     
       this.utilityService.navigate(link, {
           section: "ie"
         });
       
     } */
    // On click Card button
    IeAdvancedToolResourcesDashboardComponent.prototype.cardClick = function (link, label, newTab) {
        if (newTab) {
            window.open(link, '_blank');
        }
        else {
            if (label === this.investorProfiles) {
                this.utilityService.presentWithoutTitleModal(ModalPopupComponent);
            }
            else {
                this.utilityService.navigate(link, {
                    section: "ie"
                });
            }
        }
    };
    // openExternalLink
    IeAdvancedToolResourcesDashboardComponent.prototype.goToResources = function (resource) {
        this.utilityService.visitEquitable(resource.file);
    };
    // back button navigation
    IeAdvancedToolResourcesDashboardComponent.prototype.backButton = function () {
        this.location.back();
        //    this.utilityService.navigate(ROUTE_PATH.HOME);
    };
    IeAdvancedToolResourcesDashboardComponent.prototype.goToScsLandingPage = function () {
        this.utilityService.navigateWithoutParams(ROUTE_PATH.SCS.TOOLS.HOME);
    };
    IeAdvancedToolResourcesDashboardComponent.prototype.goToScsIncomeLandingPage = function () {
        this.utilityService.navigateWithoutParams(ROUTE_PATH.SCSINCOME.TOOLS.HOME);
    };
    IeAdvancedToolResourcesDashboardComponent.prototype.goToRcLandingPage = function () {
        this.utilityService.navigateWithoutParams(ROUTE_PATH.RC.TOOLS.HOME);
    };
    return IeAdvancedToolResourcesDashboardComponent;
}());
export { IeAdvancedToolResourcesDashboardComponent };
