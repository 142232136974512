import { FeatureFlagService } from 'src/app/core/services/feature-flag.service';
import { OnInit, ElementRef } from "@angular/core";
import { Theme, Orientation, Icon } from "equitable-component-library";
import { UtilityService } from "../../../core/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { ROUTE_PATH, SCS_SERIES } from "../../../constants/constants";
import { ScsService } from "../../services/scs.service";
import { TealiumUtagService } from '../../../tealium/utag.service';
var HistoricalPerformanceComponent = /** @class */ (function () {
    function HistoricalPerformanceComponent(utilityService, translate, scsService, featureService, tealium) {
        this.utilityService = utilityService;
        this.translate = translate;
        this.scsService = scsService;
        this.featureService = featureService;
        this.tealium = tealium;
        this.type = Theme.Dark;
        this.orientation = Orientation.Left;
        this.backIcon = Icon.BackButton;
        this.tabsConfig = [];
        this.backButtonSeries = this.scsService.series == SCS_SERIES.PLUS ? SCS_SERIES.PLUS : "";
        this.usePie = false;
    }
    HistoricalPerformanceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.series = this.scsService.series;
        this.translate.get("historicalPerformance").subscribe(function (translated) {
            _this.tabsConfig = [
                {
                    path: ROUTE_PATH.SCS.TOOLS.SEGMENT_PERFORMANCE,
                    label: translated.segmentPerformance,
                    isIcon: false,
                    tooltipText: "",
                },
                {
                    path: ROUTE_PATH.SCS.TOOLS.HISTORY_GUIDE,
                    label: translated.historyMayBeaGuide,
                    isIcon: false,
                    tooltipText: "",
                },
            ];
        });
        this.usePie = this.featureService.featureFlags.usePie ? true : false;
        console.log("USEPIE", this.usePie);
        // this.tealium.link({
        //   tealium_event: 'link',
        //   event_category: 'BodyClick',
        //   event_name: 'Retirement Guide Clicks',
        //   event_label: 'Click on Historical Performance'
        // })
    };
    HistoricalPerformanceComponent.prototype.backButton = function () {
        this.utilityService.navigate(ROUTE_PATH.SCS.TOOLS.HOME);
    };
    HistoricalPerformanceComponent.prototype.getSelectedTab = function (value) {
        if (value == "segment-performance") {
            this.header = this.translate.instant("historicalPerformance.segmentPerformance");
            this.toggleTab = false;
        }
        else {
            this.header = this.translate.instant("historicalPerformance.historyMayBeaGuide");
            this.toggleTab = true;
        }
    };
    return HistoricalPerformanceComponent;
}());
export { HistoricalPerformanceComponent };
