import { Observable, interval } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";
import {
  StripIcon,
  Icon,
  CardImage,
  ATTRIBUTE_TYPE,
  InvestorImages,
} from "equitable-component-library";
import { QuestionnaireResults } from "../models/app.models";
import { PRODUCT_TYPE, INVESTOR_PROFILE } from "src/app/constants/constants";
import { UtilityService } from "./utility.service";
interface Investor {
  investorArray: Array<string>;
  suggestedPlan: string;
}
@Injectable({
  providedIn: "root",
})
export class QuestionnaireService {
  questionnaire = {};
  questionnaireOneData = [];
  questionnaireTwoData = [];
  questionnaireThreeData = [];
  selectableQuestionnaireThreeData = [];
  resultData: QuestionnaireResults;
  investorsProfiles = {};
  annuity = {};

  constructor(
    private translateService: TranslateService,
    private utilityService: UtilityService
  ) {
    this.questionnaire = {
      1: "",
      2: "",
      3: "",
    };
    this.translateService
      .get("questionnaire.careful")
      .subscribe((translated: string) => {
        this.questionnaireOneData = [
          {
            cardMainIcon: Icon.Careful,
            cardMainTitle: translated,
            cardMainContent: this.translateService.instant(
              "questionnaire.careful-description"
            ),
            cardMainHeaderImage: CardImage.Careful,
          },
          {
            cardMainIcon: Icon.Balanced,
            cardMainTitle: this.translateService.instant(
              "questionnaire.balanced"
            ),
            cardMainContent: this.translateService.instant(
              "questionnaire.balanced-description"
            ),
            cardMainHeaderImage: CardImage.Balanced,
          },
          {
            cardMainIcon: Icon.Bold,
            cardMainTitle: this.translateService.instant("questionnaire.bold"),
            cardMainContent: this.translateService.instant(
              "questionnaire.bold-description"
            ),
            cardMainHeaderImage: CardImage.Bold,
          },
        ];
        this.questionnaireTwoData = [
          {
            stripIcon: StripIcon.GuaranteeIncome,
            stripText: this.translateService.instant(
              "questionnaire.strip-text1"
            ),
            stripResultText: this.translateService.instant(
              "questionnaire.strip-result-text1"
            ),
            details: this.translateService.instant(
              "questionnaire.strip-details1"
            ),
          },
          {
            stripIcon: StripIcon.EnsuringDeath,
            stripText: this.translateService.instant(
              "questionnaire.strip-text2"
            ),
            stripResultText: this.translateService.instant(
              "questionnaire.strip-result-text2"
            ),
            details: this.translateService.instant(
              "questionnaire.strip-details2"
            ),
          },
          {
            stripIcon: StripIcon.Profit,
            stripText: this.translateService.instant(
              "questionnaire.strip-text3"
            ),
            stripResultText: this.translateService.instant(
              "questionnaire.strip-result-text3"
            ),
            details: this.translateService.instant(
              "questionnaire.strip-details3"
            ),
          },
          {
            stripIcon: StripIcon.MinimizeLoss,
            stripText: this.translateService.instant(
              "questionnaire.strip-text4"
            ),
            stripResultText: this.translateService.instant(
              "questionnaire.strip-result-text4"
            ),
            details: this.translateService.instant(
              "questionnaire.strip-details4"
            ),
          },
          {
            stripIcon: StripIcon.DecreaseTaxes,
            stripText: this.translateService.instant(
              "questionnaire.strip-text5"
            ),
            stripResultText: this.translateService.instant(
              "questionnaire.strip-result-text5"
            ),
            details: this.translateService.instant(
              "questionnaire.strip-details5"
            ),
          },
          {
            stripIcon: StripIcon.AdjustInvestment,
            stripText: this.translateService.instant(
              "questionnaire.strip-text6"
            ),
            stripResultText: this.translateService.instant(
              "questionnaire.strip-result-text6"
            ),
            details: this.translateService.instant(
              "questionnaire.strip-details6"
            ),
          },
        ];
        this.questionnaireThreeData = [
          {
            cardMainIcon: Icon.Volatility,
            cardMainTitle: this.translateService.instant(
              "questionnaire.cardmain-title1"
            ),
            cardMainContent: this.translateService.instant(
              "questionnaire.cardmain-content1"
            ),
            cardResultContent: this.translateService.instant(
              "questionnaire.cardmain-result-content1"
            ),
            cardMainHeaderImage: CardImage.Volatility,
          },
          {
            cardMainIcon: Icon.Inflation,
            cardMainTitle: this.translateService.instant(
              "questionnaire.cardmain-title2"
            ),
            cardMainContent: this.translateService.instant(
              "questionnaire.cardmain-content2"
            ),
            cardResultContent: this.translateService.instant(
              "questionnaire.cardmain-result-content2"
            ),
            cardMainHeaderImage: CardImage.Inflation,
          },
          {
            cardMainIcon: Icon.InterestRates,
            cardMainTitle: this.translateService.instant(
              "questionnaire.cardmain-title3"
            ),
            cardMainContent: this.translateService.instant(
              "questionnaire.cardmain-content3"
            ),
            cardResultContent: this.translateService.instant(
              "questionnaire.cardmain-result-content3"
            ),
            cardMainHeaderImage: CardImage.InterestRates,
          },
          {
            cardMainIcon: Icon.Longevity,
            cardMainTitle: this.translateService.instant(
              "questionnaire.cardmain-title4"
            ),
            cardMainContent: this.translateService.instant(
              "questionnaire.cardmain-content4"
            ),
            cardResultContent: this.translateService.instant(
              "questionnaire.cardmain-result-content4"
            ),
            cardMainHeaderImage: CardImage.Longevity,
          },
          {
            cardMainIcon: Icon.Taxes,
            cardMainTitle: this.translateService.instant(
              "questionnaire.cardmain-title5"
            ),
            cardMainContent: this.translateService.instant(
              "questionnaire.cardmain-content5"
            ),
            cardResultContent: this.translateService.instant(
              "questionnaire.cardmain-result-content5"
            ),
            cardMainHeaderImage: CardImage.Taxes,
          },
          {
            cardMainIcon: Icon.Legacy,
            cardMainTitle: this.translateService.instant(
              "questionnaire.cardmain-title6"
            ),
            cardMainContent: this.translateService.instant(
              "questionnaire.cardmain-content6"
            ),
            cardResultContent: this.translateService.instant(
              "questionnaire.cardmain-result-content6"
            ),
            cardMainHeaderImage: CardImage.Legacy,
          },
        ];
        this.investorsProfiles = {
          explorer: {
            name: this.translateService.instant("questionnaire.name1"),
            investorType: this.translateService.instant(
              "questionnaire.investor-type1"
            ),
            quote: this.translateService.instant("questionnaire.quote1"),
            currentState: [
              this.translateService.instant(
                "questionnaire.current-statedetails1"
              ),
              this.translateService.instant(
                "questionnaire.current-statedetails2"
              ),
            ],
            upcomingConcerns: [
              this.translateService.instant(
                "questionnaire.upcoming-concernsdetails1"
              ),
              this.translateService.instant(
                "questionnaire.upcoming-concernsdetails2"
              ),
            ],
            description: this.translateService.instant(
              "questionnaire.investor-description1"
            ),
            subDescription: this.translateService.instant(
              "questionnaire.investor-subdescription1"
            ),
            relatedSolution: [
              this.translateService.instant(
                "questionnaire.related-solutionsdetails1"
              ),
            ],
            image: this.utilityService.getPath(
              InvestorImages.Explorer,
              ATTRIBUTE_TYPE.IMAGE
            ),
            icon: this.utilityService.getPath(
              Icon.Explorer,
              ATTRIBUTE_TYPE.ICON
            ),
          },
          entrepreneur: {
            name: this.translateService.instant("questionnaire.name2"),
            investorType: this.translateService.instant(
              "questionnaire.investor-type2"
            ),
            quote: this.translateService.instant("questionnaire.quote2"),
            currentState: [
              this.translateService.instant(
                "questionnaire.current-statedetails1"
              ),
              this.translateService.instant(
                "questionnaire.current-statedetails2"
              ),
            ],
            upcomingConcerns: [
              this.translateService.instant(
                "questionnaire.upcoming-concernsdetails2"
              ),
              this.translateService.instant(
                "questionnaire.upcoming-concernsdetails3"
              ),
            ],
            description: this.translateService.instant(
              "questionnaire.investor-description2"
            ),
            subDescription: this.translateService.instant(
              "questionnaire.investor-subdescription2"
            ),
            relatedSolution: [
              this.translateService.instant(
                "questionnaire.related-solutionsdetails1"
              ),
            ],
            image: this.utilityService.getPath(
              InvestorImages.Entrepreneur,
              ATTRIBUTE_TYPE.IMAGE
            ),
            icon: this.utilityService.getPath(
              Icon.Entrepreneur,
              ATTRIBUTE_TYPE.ICON
            ),
          },
          diversifier: {
            name: this.translateService.instant("questionnaire.name3"),
            investorType: this.translateService.instant(
              "questionnaire.investor-type3"
            ),
            quote: this.translateService.instant("questionnaire.quote3"),
            currentState: [
              this.translateService.instant(
                "questionnaire.current-statedetails1"
              ),
              this.translateService.instant(
                "questionnaire.current-statedetails2"
              ),
            ],
            upcomingConcerns: [
              this.translateService.instant(
                "questionnaire.upcoming-concernsdetails1"
              ),
              this.translateService.instant(
                "questionnaire.upcoming-concernsdetails2"
              ),
            ],
            description: this.translateService.instant(
              "questionnaire.investor-description3"
            ),
            subDescription: this.translateService.instant(
              "questionnaire.investor-subdescription3"
            ),
            relatedSolution: [
              this.translateService.instant(
                "questionnaire.related-solutionsdetails1"
              ),
            ],
            image: this.utilityService.getPath(
              InvestorImages.Defender,
              ATTRIBUTE_TYPE.IMAGE
            ),
            icon: this.utilityService.getPath(
              Icon.Defender,
              ATTRIBUTE_TYPE.ICON
            ),
          },
          saver: {
            name: this.translateService.instant("questionnaire.name4"),
            investorType: this.translateService.instant(
              "questionnaire.investor-type4"
            ),
            quote: this.translateService.instant("questionnaire.quote4"),
            currentState: [
              this.translateService.instant(
                "questionnaire.current-statedetails6"
              ),
              this.translateService.instant(
                "questionnaire.current-statedetails7"
              ),
            ],
            upcomingConcerns: [
              this.translateService.instant(
                "questionnaire.upcoming-concernsdetail10"
              ),
              this.translateService.instant(
                "questionnaire.upcoming-concernsdetails2"
              ),
            ],
            description: this.translateService.instant(
              "questionnaire.investor-description4"
            ),
            subDescription: this.translateService.instant(
              "questionnaire.investor-subdescription4"
            ),
            relatedSolution: [
              this.translateService.instant(
                "questionnaire.related-solutionsdetails1"
              ),
            ],
            image: this.utilityService.getPath(
              InvestorImages.Saver,
              ATTRIBUTE_TYPE.IMAGE
            ),
            icon: this.utilityService.getPath(Icon.Saver, ATTRIBUTE_TYPE.ICON),
          },
          disrupter: {
            name: this.translateService.instant("questionnaire.name5"),
            investorType: this.translateService.instant(
              "questionnaire.investor-type5"
            ),
            quote: this.translateService.instant("questionnaire.quote5"),
            currentState: [
              this.translateService.instant(
                "questionnaire.current-statedetails1"
              ),
              this.translateService.instant(
                "questionnaire.current-statedetails3"
              ),
            ],
            upcomingConcerns: [
              this.translateService.instant(
                "questionnaire.upcoming-concernsdetails4"
              ),
              this.translateService.instant(
                "questionnaire.upcoming-concernsdetails5"
              ),
            ],
            description: this.translateService.instant(
              "questionnaire.investor-description5"
            ),
            subDescription: this.translateService.instant(
              "questionnaire.investor-subdescription5"
            ),
            relatedSolution: [
              this.translateService.instant(
                "questionnaire.related-solutionsdetails2"
              ),
            ],
            image: this.utilityService.getPath(
              InvestorImages.Disrupter,
              ATTRIBUTE_TYPE.IMAGE
            ),
            icon: this.utilityService.getPath(
              Icon.Disrupter,
              ATTRIBUTE_TYPE.ICON
            ),
          },
          protector: {
            name: this.translateService.instant("questionnaire.name6"),
            investorType: this.translateService.instant(
              "questionnaire.investor-type6"
            ),
            quote: this.translateService.instant("questionnaire.quote6"),
            currentState: [
              this.translateService.instant(
                "questionnaire.current-statedetails6"
              ),
              this.translateService.instant(
                "questionnaire.current-statedetails4"
              ),
            ],
            upcomingConcerns: [
              this.translateService.instant(
                "questionnaire.upcoming-concernsdetails6"
              ),
              this.translateService.instant(
                "questionnaire.upcoming-concernsdetails7"
              ),
            ],
            description: this.translateService.instant(
              "questionnaire.investor-description6"
            ),
            subDescription: this.translateService.instant(
              "questionnaire.investor-subdescription6"
            ),
            relatedSolution: [
              this.translateService.instant(
                "questionnaire.related-solutionsdetails2"
              )
            ],
            image: this.utilityService.getPath(
              InvestorImages.Protector,
              ATTRIBUTE_TYPE.IMAGE
            ),
            icon: this.utilityService.getPath(
              Icon.Protector,
              ATTRIBUTE_TYPE.ICON
            ),
          },
          preserver: {
            name: this.translateService.instant("questionnaire.name7"),
            investorType: this.translateService.instant(
              "questionnaire.investor-type7"
            ),
            quote: this.translateService.instant("questionnaire.quote7"),
            currentState: [
              this.translateService.instant(
                "questionnaire.current-statedetails5"
              ),
              this.translateService.instant(
                "questionnaire.current-statedetails4"
              ),
            ],
            upcomingConcerns: [
              this.translateService.instant(
                "questionnaire.upcoming-concernsdetails8"
              ),
              this.translateService.instant(
                "questionnaire.upcoming-concernsdetails9"
              ),
            ],
            description: this.translateService.instant(
              "questionnaire.investor-description7"
            ),
            subDescription: this.translateService.instant(
              "questionnaire.investor-subdescription7"
            ),
            relatedSolution: [
              this.translateService.instant(
                "questionnaire.related-solutionsdetails2"
              )
            ],
            image: this.utilityService.getPath(
              InvestorImages.Preserver,
              ATTRIBUTE_TYPE.IMAGE
            ),
            icon: this.utilityService.getPath(
              Icon.Preserver,
              ATTRIBUTE_TYPE.ICON
            ),
          },
          coordinator: {
            name: this.translateService.instant("questionnaire.name9"),
            investorType: this.translateService.instant(
              "questionnaire.investor-type9"
            ),
            quote: this.translateService.instant("questionnaire.quote9"),
            currentState: [
              this.translateService.instant(
                "questionnaire.current-statedetails1"
              ),
              this.translateService.instant(
                "questionnaire.current-statedetails4"
              ),
            ],
            upcomingConcerns: [
              this.translateService.instant(
                "questionnaire.upcoming-concernsdetail11"
              ),
              this.translateService.instant(
                "questionnaire.upcoming-concernsdetail12"
              ),
            ],
            description: this.translateService.instant(
              "questionnaire.investor-description9"
            ),
            subDescription: this.translateService.instant(
              "questionnaire.investor-subdescription9"
            ),
            relatedSolution: [
              this.translateService.instant(
                "questionnaire.related-solutionsdetails3"
              ),
            ],
            image: this.utilityService.getPath(
              InvestorImages.Protector,
              ATTRIBUTE_TYPE.IMAGE
            ),
            icon: this.utilityService.getPath(
              Icon.Protector,
              ATTRIBUTE_TYPE.ICON
            )
          },
          researcher: {
            name: this.translateService.instant("questionnaire.name8"),
            investorType: this.translateService.instant(
              "questionnaire.investor-type8"
            ),
            quote: this.translateService.instant("questionnaire.quote8"),
            currentState: [
              this.translateService.instant(
                "questionnaire.current-statedetails1"
              ),
              this.translateService.instant(
                "questionnaire.current-statedetails4"
              ),
            ],
            upcomingConcerns: [
              this.translateService.instant(
                "questionnaire.upcoming-concernsdetail11"
              ),
              this.translateService.instant(
                "questionnaire.upcoming-concernsdetail12"
              ),
            ],
            description: this.translateService.instant(
              "questionnaire.investor-description8"
            ),
            subDescription: this.translateService.instant(
              "questionnaire.investor-subdescription8"
            ),
            relatedSolution: [
              this.translateService.instant(
                "questionnaire.related-solutionsdetails3"
              ),
            ],
            image: this.utilityService.getPath(
              InvestorImages.Defender,
              ATTRIBUTE_TYPE.IMAGE
            ),
            icon: this.utilityService.getPath(
              Icon.Defender,
              ATTRIBUTE_TYPE.ICON
            )
          }
        };
        this.annuity = {
          scs: {
            image: "",
            name: this.translateService.instant("questionnaire.annuity-name1"),
            text: this.translateService.instant("questionnaire.annuity-text1"),
            benefits: [
              this.translateService.instant("questionnaire.annuity-benefits1"),
              this.translateService.instant("questionnaire.annuity-benefits2"),
              this.translateService.instant("questionnaire.annuity-benefits3"),
            ],
            id: PRODUCT_TYPE.SCS,
          },
          rc: {
            image: "",
            name: this.translateService.instant("questionnaire.annuity-name2"),
            text: this.translateService.instant("questionnaire.annuity-text2"),
            benefits: [
              this.translateService.instant("questionnaire.rc-benefits1"),
              this.translateService.instant("questionnaire.rc-benefits2"),
              this.translateService.instant("questionnaire.rc-benefits3"),
            ],
            id: PRODUCT_TYPE.RC,
          },
          ie: {
            image: "",
            name: this.translateService.instant("questionnaire.annuity-name3"),
            text: this.translateService.instant("questionnaire.annuity-text3"),
            benefits: [
              this.translateService.instant("questionnaire.ie-benefits1"),
              this.translateService.instant("questionnaire.ie-benefits2"),
              this.translateService.instant("questionnaire.ie-benefits3"),
            ],
            id: PRODUCT_TYPE.IE,
          },
        };
      });
  }

  getQuestionnaire() {
    return this.questionnaire;
  }

  setQuestionnaire(questionnaire: object) {
    this.questionnaire = questionnaire;
  }

  getQuestionnairePageData(pageNo: number): Observable<any> {
    const questionnaireObservable = new Observable((observer) => {
      const dataInterval = interval(100).subscribe(() => {
        if (this.questionnaireOneData.length !== 0) {
          dataInterval.unsubscribe();
          switch (pageNo) {
            case 1:
              observer.next(this.questionnaireOneData);
              break;
            case 2:
              observer.next(this.questionnaireTwoData);
              break;
            case 3:
              observer.next(this.getQuestionnaireThreeData());
              break;
            default:
              break;
          }
        }
      });
    });
    return questionnaireObservable;
  }

  getResults(): Observable<any> {
    const resultObservalble = new Observable((observer) => {
      const dataInterval = interval(100).subscribe(() => {
        if (this.questionnaireOneData.length !== 0) {
          this.resultData = {
            answers: [],
            investorProfiles: [],
            suggestedAnnuity: {
              image: "",
              name: "",
              text: "",
              benefits: [],
              id: "",
            },
            defaultAnnuity: [],
          };
          dataInterval.unsubscribe();
          this.getSuggestedInvestorAndProduct().then((investors: Investor) => {
            const selectedAnswer1 = {
              detail: this.translateService.instant("questionnaire.detail1"),
              heading: this.questionnaireOneData[this.questionnaire[1]]
                .cardMainTitle,
              description: this.questionnaireOneData[this.questionnaire[1]]
                .cardMainContent,
              icon: this.questionnaireOneData[this.questionnaire[1]]
                .cardMainIcon,
            };
            const selectedAnswer2 = {
              detail: this.translateService.instant("questionnaire.detail2"),
              heading: this.questionnaireTwoData[this.questionnaire[2]]
                .stripResultText,
              description: this.questionnaireTwoData[this.questionnaire[2]]
                .details,
              icon: this.questionnaireTwoData[this.questionnaire[2]].stripIcon,
            };
            const selectedAnswer3 = {
              detail: this.translateService.instant("questionnaire.detail3"),
              heading: this.selectableQuestionnaireThreeData[
                this.questionnaire[3]
              ].cardMainTitle,
              description: this.selectableQuestionnaireThreeData[
                this.questionnaire[3]
              ].cardResultContent,
              icon: this.selectableQuestionnaireThreeData[this.questionnaire[3]]
                .cardMainIcon,
            };
            this.resultData.answers.push(selectedAnswer1);
            this.resultData.answers.push(selectedAnswer2);
            this.resultData.answers.push(selectedAnswer3);
            investors.investorArray.forEach((element) => {
              const investorObject = this.investorsProfiles[
                element.toLowerCase()
              ];
              const investor = {
                title: element.toLowerCase(),
                icon: investorObject.icon,
                investorDetails: {
                  image: investorObject.image,
                  name: investorObject.name,
                  title: element,
                  heading: investorObject.quote,
                  text: investorObject.description,
                  subText: investorObject.subDescription,
                },
                keypoints: [
                  {
                    heading: this.translateService.instant(
                      "questionnaire.key-heading1"
                    ),
                    details: investorObject.currentState,
                  },
                  {
                    heading: this.translateService.instant(
                      "questionnaire.key-heading2"
                    ),
                    details: investorObject.upcomingConcerns,
                  },
                  {
                    heading: this.translateService.instant(
                      "questionnaire.key-heading3"
                    ),
                    details: investorObject.relatedSolution,
                  },
                ],
              };
              this.resultData.investorProfiles.push(investor);
            });
            for (const key in this.annuity) {
              if (key === investors.suggestedPlan) {
                this.resultData.suggestedAnnuity = this.annuity[key];
              } else {
                this.resultData.defaultAnnuity.push(this.annuity[key]);
              }
            }
          });
        }
        observer.next(this.resultData);
      });
    });
    return resultObservalble;
  }

  getSuggestedInvestorAndProduct() {
    return new Promise((resolve, reject) => {
      if (this.questionnaire[2] !== "")
        switch (this.questionnaire[2]) {
          case 0:
            resolve({
              investorArray: [
                INVESTOR_PROFILE.DISRUPTER,
                INVESTOR_PROFILE.PROTECTOR,
              ],
              suggestedPlan: PRODUCT_TYPE.RC,
            });
            break;
          case 1:
            resolve({
              investorArray: [
                INVESTOR_PROFILE.PRESERVER,
                INVESTOR_PROFILE.PROTECTOR,
              ],
              suggestedPlan: PRODUCT_TYPE.RC,
            });
            break;
          case 2:
            resolve({
              investorArray: [
                INVESTOR_PROFILE.SAVER,
                INVESTOR_PROFILE.DIVERSIFIER,
              ],
              suggestedPlan: PRODUCT_TYPE.SCS,
            });
            break;
          case 3:
            resolve({
              investorArray: [
                INVESTOR_PROFILE.EXPLORER,
                INVESTOR_PROFILE.DIVERSIFIER,
              ],
              suggestedPlan: PRODUCT_TYPE.SCS,
            });

            break;
          case 4:
            resolve({
              investorArray: [
                INVESTOR_PROFILE.RESEARCHER,
                INVESTOR_PROFILE.COORDINATOR,
              ],
              suggestedPlan: PRODUCT_TYPE.IE,
            });

            break;
          case 5:
            resolve({
              investorArray: [
                INVESTOR_PROFILE.RESEARCHER,
                INVESTOR_PROFILE.COORDINATOR,
              ],
              suggestedPlan: PRODUCT_TYPE.IE,
            });
            break;
          default:
            reject("Error");
            break;
        }
    });
  }

  getQuestionnaireThreeData() {
    this.selectableQuestionnaireThreeData = [];
    switch (this.questionnaire["2"]) {
      case 0:
        this.selectableQuestionnaireThreeData.push(
          this.questionnaireThreeData[3]
        );
        this.selectableQuestionnaireThreeData.push(
          this.questionnaireThreeData[1]
        );
        this.selectableQuestionnaireThreeData.push(
          this.questionnaireThreeData[2]
        );
        break;
      case 1:
        this.selectableQuestionnaireThreeData.push(
          this.questionnaireThreeData[5]
        );
        this.selectableQuestionnaireThreeData.push(
          this.questionnaireThreeData[1]
        );
        this.selectableQuestionnaireThreeData.push(
          this.questionnaireThreeData[2]
        );
        break;
      case 2:
        this.selectableQuestionnaireThreeData.push(
          this.questionnaireThreeData[1]
        );
        this.selectableQuestionnaireThreeData.push(
          this.questionnaireThreeData[2]
        );
        this.selectableQuestionnaireThreeData.push(
          this.questionnaireThreeData[0]
        );
        break;
      case 3:
        this.selectableQuestionnaireThreeData.push(
          this.questionnaireThreeData[0]
        );
        this.selectableQuestionnaireThreeData.push(
          this.questionnaireThreeData[4]
        );
        this.selectableQuestionnaireThreeData.push(
          this.questionnaireThreeData[2]
        );
        break;
      case 4:
        this.selectableQuestionnaireThreeData.push(
          this.questionnaireThreeData[4]
        );
        this.selectableQuestionnaireThreeData.push(
          this.questionnaireThreeData[3]
        );
        this.selectableQuestionnaireThreeData.push(
          this.questionnaireThreeData[1]
        );
        break;
      case 5:
        this.selectableQuestionnaireThreeData.push(
          this.questionnaireThreeData[3]
        );
        this.selectableQuestionnaireThreeData.push(
          this.questionnaireThreeData[2]
        );
        this.selectableQuestionnaireThreeData.push(
          this.questionnaireThreeData[0]
        );
        break;
      default:
        break;
    }
    return this.selectableQuestionnaireThreeData;
  }
}
