/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./market-trend-indicator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../../../../node_modules/equitable-component-library/src/lib/sub-header/sub-header.component.ngfactory";
import * as i5 from "equitable-component-library/src/lib/sub-header/sub-header.component";
import * as i6 from "equitable-component-library/src/lib/equitable-utilities";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../core/components/footer/footer.component.ngfactory";
import * as i9 from "../../../core/components/footer/footer.component";
import * as i10 from "@angular/router";
import * as i11 from "../../../core/services/core.service";
import * as i12 from "../../../core/services/utility.service";
import * as i13 from "./market-trend-indicator.component";
import * as i14 from "../../services/scs.service";
import * as i15 from "../../../core/services/feature-flag.service";
import * as i16 from "../../../tealium/utag.service";
var styles_MarketTrendIndicatorComponent = [i0.styles];
var RenderType_MarketTrendIndicatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MarketTrendIndicatorComponent, data: {} });
export { RenderType_MarketTrendIndicatorComponent as RenderType_MarketTrendIndicatorComponent };
export function View_MarketTrendIndicatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(1, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 6, "div", [["class", "site-tools"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "arround-space"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "equitable-sub-header", [], null, [[null, "backClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backClick" === en)) {
        var pd_0 = (_co.backButton() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_SubHeaderComponent_0, i4.RenderType_SubHeaderComponent)), i1.ɵdid(5, 114688, null, 0, i5.SubHeaderComponent, [i6.EquitableUtilities], { title: [0, "title"], backLinkText: [1, "backLinkText"] }, { backClick: "backClick" }), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 1, "iframe", [["class", "iframe"], ["frameborder", "0"], ["id", "mtiframe"]], [[8, "src", 5], [8, "title", 0]], null, null, null, null)), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "app-footer", [["activeTab", "Performance_cap_trend"], ["isBlueFooterVisible", "true"], ["isWhiteFooterVisible", "true"]], null, null, null, i8.View_FooterComponent_0, i8.RenderType_FooterComponent)), i1.ɵdid(10, 638976, null, 0, i9.FooterComponent, [i10.Router, i11.CoreService, i12.UtilityService, i3.Platform], { isWhiteFooterVisible: [0, "isWhiteFooterVisible"], isBlueFooterVisible: [1, "isBlueFooterVisible"], activeTab: [2, "activeTab"], selectedProduct: [3, "selectedProduct"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("marketTrendIndicator.headerText")), ""); var currVal_1 = _co.backLink; _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_4 = "true"; var currVal_5 = "true"; var currVal_6 = "Performance_cap_trend"; var currVal_7 = _co.series; _ck(_v, 10, 0, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.marketTrendIndicatorUrl; var currVal_3 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 7, 1, i1.ɵnov(_v, 8).transform("marketTrendIndicator.headerText")), ""); _ck(_v, 7, 0, currVal_2, currVal_3); }); }
export function View_MarketTrendIndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-market-trend-indicator", [], null, null, null, View_MarketTrendIndicatorComponent_0, RenderType_MarketTrendIndicatorComponent)), i1.ɵdid(1, 114688, null, 0, i13.MarketTrendIndicatorComponent, [i12.UtilityService, i14.ScsService, i7.TranslateService, i15.FeatureFlagService, i16.TealiumUtagService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MarketTrendIndicatorComponentNgFactory = i1.ɵccf("app-market-trend-indicator", i13.MarketTrendIndicatorComponent, View_MarketTrendIndicatorComponent_Host_0, {}, {}, []);
export { MarketTrendIndicatorComponentNgFactory as MarketTrendIndicatorComponentNgFactory };
