<equitable-tab-slider
  (changeData)="openTab($event)"
  [names]="btns"
></equitable-tab-slider>

<!-- Tab 1  termsOfUse-->
<div *ngIf="activeIndex === 0">
  <div>
    <h1
      class="title-heading"
      [innerHTML]="'disclosure.ie.tab1.heading' | translate"
    ></h1>
    <div class="content">
      <div [innerHTML]="'disclosure.ie.tab1.content3' | translate"></div>
      <div [innerHTML]="'disclosure.ie.tab1.content4' | translate"></div>
      <div [innerHTML]="'disclosure.ie.tab1.content5' | translate"></div>
      <div [innerHTML]="'disclosure.ie.tab1.content6' | translate"></div>
      <div [innerHTML]="'disclosure.ie.tab1.content7' | translate"></div>
      <div [innerHTML]="'disclosure.ie.tab1.content8' | translate"></div>
      <div [innerHTML]="'disclosure.ie.tab1.content9' | translate"></div>
      <div [innerHTML]="'disclosure.ie.tab1.content10' | translate"></div>
      <div [innerHTML]="'disclosure.ie.tab1.content11' | translate"></div>
      <div [innerHTML]="'disclosure.ie.tab1.content12' | translate"></div>
      <div [innerHTML]="'disclosure.ie.tab1.content13' | translate"></div>
      <div [innerHTML]="'disclosure.ie.tab1.content14' | translate"></div>
    </div>
  </div>
</div>

<!-- Tab 2 - Historical Index Return Disclosure -->
<div *ngIf="activeIndex === 1">
  <div>
    <h1
      class="title-heading"
      [innerHTML]="'disclosure.ie.tab2.heading' | translate"
    ></h1>
    <div class="content">
      <div [innerHTML]="'disclosure.ie.tab2.content3' | translate"></div>
      <div [innerHTML]="'disclosure.ie.tab2.content4' | translate"></div>
      <div [innerHTML]="'disclosure.ie.tab2.content5' | translate"></div>
      <div [innerHTML]="'disclosure.ie.tab2.content9' | translate"></div>
      <div [innerHTML]="'disclosure.ie.tab2.content6' | translate"></div>
      <div [innerHTML]="'disclosure.ie.tab2.content8' | translate"></div>
      <div [innerHTML]="'disclosure.ie.tab2.content10' | translate"></div>
    </div>
  </div>
</div>

<!-- Tab 3 - Diversify Your Portfolio Disclosure  -->
<div *ngIf="activeIndex === 2">
  <div>
    <h1
      class="title-heading"
      [innerHTML]="'disclosure.ie.tab3.heading' | translate"
    ></h1>
    <div class="content">
      <div [innerHTML]="'disclosure.ie.tab3.content3' | translate"></div>
    </div>
  </div>
</div>

<!-- Tab 4 - How SIO Works -->
<div *ngIf="activeIndex === 3">
  <div>
    <h1
      class="title-heading"
      [innerHTML]="'disclosure.ie.tab4.heading' | translate"
    ></h1>
    <div class="content">
      <div [innerHTML]="'disclosure.ie.tab4.content3' | translate"></div>
      <div [innerHTML]="'disclosure.ie.tab4.content4' | translate"></div>
      <div [innerHTML]="'disclosure.ie.tab4.content6' | translate"></div>
    </div>
  </div>
</div>