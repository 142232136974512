import { FeatureFlagService } from '../services/feature-flag.service';
import { ViewContainerRef, TemplateRef } from '@angular/core';
var FeatureFlagDirective = /** @class */ (function () {
    function FeatureFlagDirective(viewController, template, featureService) {
        this.viewController = viewController;
        this.template = template;
        this.featureService = featureService;
    }
    FeatureFlagDirective.prototype.ngOnInit = function () {
        if (this.featureService.featureFlags[this.appFeatureFlag]) {
            this.viewController.createEmbeddedView(this.template);
        }
    };
    return FeatureFlagDirective;
}());
export { FeatureFlagDirective };
