/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../node_modules/equitable-component-library/src/lib/header/header.component.ngfactory";
import * as i5 from "equitable-component-library/src/lib/header/header.component";
import * as i6 from "../../node_modules/equitable-component-library/src/lib/hamburger-menu/hamburger-menu.component.ngfactory";
import * as i7 from "equitable-component-library/src/lib/hamburger-menu/hamburger-menu.component";
import * as i8 from "@angular/router";
import * as i9 from "equitable-component-library/src/lib/equitable-utilities";
import * as i10 from "@angular/common";
import * as i11 from "./tealium/utag.service";
import * as i12 from "./app.component";
import * as i13 from "@ionic-native/splash-screen/ngx/index";
import * as i14 from "@ionic-native/status-bar/ngx/index";
import * as i15 from "@ngx-translate/core";
import * as i16 from "@ionic-native/globalization/ngx/index";
import * as i17 from "./core/services/ionic-storage.service";
import * as i18 from "./core/services/core.service";
import * as i19 from "@ionic-native/network/ngx/index";
import * as i20 from "./core/services/utility.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ion-app", [], null, null, null, i2.View_IonApp_0, i2.RenderType_IonApp)), i1.ɵdid(1, 49152, null, 0, i3.IonApp, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "equitable-header", [], null, null, null, i4.View_HeaderComponent_0, i4.RenderType_HeaderComponent)), i1.ɵdid(3, 114688, null, 0, i5.HeaderComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "equitable-hamburger-menu", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).clickOut($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_HamburgerMenuComponent_0, i6.RenderType_HamburgerMenuComponent)), i1.ɵdid(5, 114688, null, 0, i7.HamburgerMenuComponent, [i1.ElementRef, i8.Router, i9.EquitableUtilities, i3.Platform], { menu: [0, "menu"] }, null), (_l()(), i1.ɵeld(6, 16777216, null, 0, 1, "ion-router-outlet", [], null, [[null, "activate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activate" === en)) {
        var pd_0 = (_co.scrollTop() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 212992, null, 0, i3.IonRouterOutlet, [i8.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], [8, null], i3.Config, i3.NavController, i10.Location, i1.ElementRef, i8.Router, i1.NgZone, i8.ActivatedRoute, [3, i3.IonRouterOutlet]], null, { activateEvents: "activate" })], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = _co.menu; _ck(_v, 5, 0, currVal_0); _ck(_v, 7, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵprd(512, null, i11.TealiumUtagService, i11.TealiumUtagService, []), i1.ɵdid(2, 114688, null, 0, i12.AppComponent, [i3.Platform, i13.SplashScreen, i14.StatusBar, i15.TranslateService, i16.Globalization, i17.IonicStorageService, i18.CoreService, i19.Network, i8.Router, i20.UtilityService, i11.TealiumUtagService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i12.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
