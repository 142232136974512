import { OnInit } from "@angular/core";
import { LinkIcon, Orientation, LinkType } from "equitable-component-library";
import { FormControl, FormBuilder } from "@angular/forms";
import { UtilityService } from "../../services/utility.service";
import { GlossaryService } from "../../services/glossary.service";
import { PRODUCT_TYPE } from "../../../constants/constants";
import { ActivatedRoute } from "@angular/router";
var GlossaryComponent = /** @class */ (function () {
    function GlossaryComponent(formBuilder, utilityService, glossaryService, activateRoute) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.utilityService = utilityService;
        this.glossaryService = glossaryService;
        this.activateRoute = activateRoute;
        //back link config
        this.backLinkIconName = LinkIcon.LeftIcon;
        this.backLinkIconOrientation = Orientation.Left;
        this.backLinkType = LinkType.Dark;
        // search result variables
        this.resultCount = -1;
        this.formControlDropDown = new FormControl("");
        this.formControlSearchbar = new FormControl("");
        this.activateRoute.params.subscribe(function (params) {
            if (params.product && params.product == PRODUCT_TYPE.RC) {
                _this.formControlDropDown.setValue(PRODUCT_TYPE.RC);
            }
            else {
                _this.formControlDropDown.setValue(PRODUCT_TYPE.SCS);
            }
        });
    }
    GlossaryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.utilityService.sendScrollStatus(false);
        this.glossaryService.getDropdownData().subscribe(function (res) {
            _this.dropDownOptions = res;
        });
        this.glossaryForm = this.formBuilder.group({
            formControlNameDropDown: this.formControlDropDown,
            formControlNameSearchbar: this.formControlSearchbar
        });
        //default search with "a"
        this.startSearch("a", this.glossaryForm.get("formControlNameDropDown").value);
    };
    //search
    GlossaryComponent.prototype.startSearch = function (value, dropDownVal) {
        var _this = this;
        this.glossaryService.getSearchResults(value, dropDownVal).subscribe(function (res) {
            _this.searchResults = res;
            _this.resultCount = _this.searchResults.length;
        });
    };
    GlossaryComponent.prototype.onChange = function (event) {
        this.startSearch(this.glossaryForm.get("formControlNameSearchbar").value, this.glossaryForm.get("formControlNameDropDown").value);
    };
    return GlossaryComponent;
}());
export { GlossaryComponent };
