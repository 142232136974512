<ion-grid class="wrapper">
  <ion-row>
    <ion-col class="intro" size-xl="4" size="10">
      <ion-row>
        <ion-col size="10" size-md="6" size-xl="10" class="ion-no-padding">
          <div class="heading">
            {{ "preserveLegacy." + activeTab + ".section1Header" | translate }}
          </div>
          <div class="description">
            {{
              "preserveLegacy." + activeTab + ".section1Description" | translate
            }}
          </div>
          <div class="description" *ngIf="activeTab === 'rop'">
            {{
              "preserveLegacy." + activeTab + ".section1Description2"
                | translate
            }}
          </div>
        </ion-col>
        <ion-col
          size="10"
          size-md="3.5"
          size-xl="10"
          class="ion-no-padding"
          offset-md="0.5"
          offset-xl
        >
          <div class="heading">
            {{ "preserveLegacy." + activeTab + ".benefitHeader" | translate }}
          </div>
          <ion-list
            class="ion-no-margin ion-no-padding benefit-list"
            lines="none"
          >
            <ion-item
              *ngFor="let benefit of benefits"
              class="ion-no-margin ion-no-padding"
            >
              <ion-radio #ionRadioElRef checked mode="ios" disabled></ion-radio>
              <ion-label>
                {{ benefit }}
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-col>
    <ion-col class="chart" size-xl="6" size="10">
      <div #chart [ngClass]="chartResize"></div>
    </ion-col>
  </ion-row>
</ion-grid>
