import { OnInit } from "@angular/core";
import { ButtonType, LinkType, Icon } from "equitable-component-library";
import { ROUTE_PATH } from "../../../constants/constants";
import { UtilityService } from "../../../core/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { IeService } from "../../services/ie.service";
import { ScsService } from "../../../scs/services/scs.service";
var DiscoverComponent = /** @class */ (function () {
    function DiscoverComponent(utilityService, translate, scsService, ieService) {
        this.utilityService = utilityService;
        this.translate = translate;
        this.scsService = scsService;
        this.ieService = ieService;
        this.LinkType = LinkType;
        this.ButtonType = ButtonType;
        this.guaranteedIncome = {
            headingSmall: "",
            listDetail: [],
        };
        this.deferYourTaxes = {
            headingSmall: "",
            listDetail: [],
        };
        this.scsincomeBenefits = {
            headingSmall: "",
            listDetail: [],
        };
        this.featureCards = [
            {
                icon: Icon.TaxDefferals,
                title: "understandIE.featureCardIE.diversification",
                description: "understandIE.featureCardIE.diversificationDescription",
            },
            {
                icon: Icon.Associations,
                title: "understandIE.featureCardIE.taxEfficient",
                description: "understandIE.featureCardIE.taxEfficientDescription",
            },
            {
                icon: Icon.Badges,
                title: "understandIE.featureCardIE.deathBenefit",
                description: "understandIE.featureCardIE.deathBenefitDescription",
            },
        ];
        this.videos = []; //environment.videos.ie;
        // for video section
        this.video = [];
    }
    DiscoverComponent.prototype.ngOnInit = function () {
        var _this = this;
        // translations for Explore the other strategies we offer section
        this.translate.get("understandIE").subscribe(function (understandIE) {
            _this.guaranteedIncome = {
                headingSmall: understandIE.benefitsTitle,
                listDetail: [
                    understandIE.investmentAccount,
                    understandIE.protectedBenefit,
                    understandIE.abilityToTransfer,
                ],
            };
            _this.deferYourTaxes = {
                headingSmall: understandIE.benefitsTitle,
                listDetail: [
                    understandIE.partialDownside,
                    understandIE.upsidePotential,
                    understandIE.zeroExplicit,
                ],
            };
            _this.scsincomeBenefits = {
                headingSmall: understandIE.benefitsTitle,
                listDetail: [
                    understandIE.lifetimeIncome,
                    understandIE.levelDownside,
                    understandIE.lowCost,
                ],
            };
        });
        this.ieService.getIEResources(this.ieService.series).subscribe(function (ie) {
            // if (ie.videos && ie.videos.length > 0) this.videos = ie.videos;
        });
        this.ieService.getIEData().subscribe(function (ie) {
            _this.ieData = ie;
            if (ie.videos && ie.videos.length > 0) {
                _this.videos = ie.videos;
            }
        });
    };
    // Chandan: unused code?
    // Navigate to view all disclosure
    // goToDisclosure(): void {
    //   this.utilityService.navigate(ROUTE_PATH.DISCLOSURE, {
    //     appId: PRODUCT_TYPE.IE,
    //   });
    // }
    // Navigate to RC landing Page
    DiscoverComponent.prototype.gotoRCLandingPage = function () {
        this.utilityService.navigateWithoutParams(ROUTE_PATH.RC.DISCOVER);
    };
    // Navigate to SCSINCOME landing Page
    DiscoverComponent.prototype.gotoSCSIncomeLandingPage = function () {
        this.utilityService.navigateWithoutParams(ROUTE_PATH.SCSINCOME.DISCOVER);
    };
    DiscoverComponent.prototype.gotoSCSINCOMELandingPage = function () {
        document.querySelector('.hero-main').scrollIntoView(true);
        this.utilityService.navigate(ROUTE_PATH.SCSINCOME.DISCOVER + '?series=B');
    };
    // Navigate to SCS landing Page
    DiscoverComponent.prototype.gotoSCSLandingPage = function () {
        this.utilityService.navigateWithoutParams(ROUTE_PATH.SCS.DISCOVER);
    };
    // Navigate to IE Dashboard
    DiscoverComponent.prototype.gotoAdvancedToolPage = function () {
        this.utilityService.navigateWithoutParams(ROUTE_PATH.IE.TOOLS.HOME);
    };
    return DiscoverComponent;
}());
export { DiscoverComponent };
