/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./preserve-legacy.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/common";
import * as i7 from "../preserve-legacy-tools/preserve-legacy-tools.component.ngfactory";
import * as i8 from "../preserve-legacy-tools/preserve-legacy-tools.component";
import * as i9 from "../../../core/services/core.service";
import * as i10 from "../../../../../node_modules/equitable-component-library/src/lib/sub-header/sub-header.component.ngfactory";
import * as i11 from "equitable-component-library/src/lib/sub-header/sub-header.component";
import * as i12 from "equitable-component-library/src/lib/equitable-utilities";
import * as i13 from "../../../core/components/footer/footer.component.ngfactory";
import * as i14 from "../../../core/components/footer/footer.component";
import * as i15 from "@angular/router";
import * as i16 from "../../../core/services/utility.service";
import * as i17 from "./preserve-legacy.component";
var styles_PreserveLegacyComponent = [i0.styles];
var RenderType_PreserveLegacyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PreserveLegacyComponent, data: {} });
export { RenderType_PreserveLegacyComponent as RenderType_PreserveLegacyComponent };
function View_PreserveLegacyComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ion-segment-button", [["class", "segment-button-class"], ["mode", "md"]], null, null, null, i2.View_IonSegmentButton_0, i2.RenderType_IonSegmentButton)), i1.ɵdid(1, 49152, null, 0, i3.IonSegmentButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { checked: [0, "checked"], mode: [1, "mode"], value: [2, "value"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "ion-label", [["class", "segment-heading"]], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(3, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(4, 0, ["", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.activeTab === _v.context.$implicit); var currVal_1 = "md"; var currVal_2 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_3 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform((("preserveLegacy." + _v.context.$implicit) + ".title"))); _ck(_v, 4, 0, currVal_3); }); }
function View_PreserveLegacyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "ion-card", [["class", "tools-card"]], null, null, null, i2.View_IonCard_0, i2.RenderType_IonCard)), i1.ɵdid(1, 49152, null, 0, i3.IonCard, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 5, "ion-segment", [["class", "segment-class"], ["mode", "md"]], null, [[null, "ionChange"], [null, "ionBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5)._handleChangeEvent($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ionChange" === en)) {
        var pd_2 = (_co.segmentChanged($event.detail.value) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_IonSegment_0, i2.RenderType_IonSegment)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.SelectValueAccessor]), i1.ɵdid(4, 49152, null, 0, i3.IonSegment, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { mode: [0, "mode"] }, null), i1.ɵdid(5, 16384, null, 0, i3.SelectValueAccessor, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PreserveLegacyComponent_2)), i1.ɵdid(7, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "app-preserve-legacy-tools", [], null, null, null, i7.View_PreserveLegacyToolsComponent_0, i7.RenderType_PreserveLegacyToolsComponent)), i1.ɵdid(9, 4833280, null, 0, i8.PreserveLegacyToolsComponent, [i4.TranslateService, i9.CoreService, i6.DOCUMENT], { activeTab: [0, "activeTab"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "md"; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.segments; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.activeTab; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_PreserveLegacyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(1, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 6, "div", [["class", "legacy-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "equitable-sub-header", [], null, [[null, "backClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backClick" === en)) {
        var pd_0 = (_co.backButton() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_SubHeaderComponent_0, i10.RenderType_SubHeaderComponent)), i1.ɵdid(4, 114688, null, 0, i11.SubHeaderComponent, [i12.EquitableUtilities], { title: [0, "title"], backLinkText: [1, "backLinkText"] }, { backClick: "backClick" }), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreserveLegacyComponent_1)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "app-footer", [["isBlueFooterVisible", "true"], ["isWhiteFooterVisible", "true"], ["selectedProduct", "rc"]], null, null, null, i13.View_FooterComponent_0, i13.RenderType_FooterComponent)), i1.ɵdid(10, 638976, null, 0, i14.FooterComponent, [i15.Router, i9.CoreService, i16.UtilityService, i3.Platform], { isWhiteFooterVisible: [0, "isWhiteFooterVisible"], isBlueFooterVisible: [1, "isBlueFooterVisible"], selectedProduct: [2, "selectedProduct"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("preserveLegacy.pageHeader")), ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 4, 1, i1.ɵnov(_v, 6).transform("preserveLegacy.backButtonText")), ""); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.activeTab; _ck(_v, 8, 0, currVal_2); var currVal_3 = "true"; var currVal_4 = "true"; var currVal_5 = "rc"; _ck(_v, 10, 0, currVal_3, currVal_4, currVal_5); }, null); }
export function View_PreserveLegacyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-preserve-legacy", [], null, null, null, View_PreserveLegacyComponent_0, RenderType_PreserveLegacyComponent)), i1.ɵdid(1, 49152, null, 0, i17.PreserveLegacyComponent, [i16.UtilityService], null, null)], null, null); }
var PreserveLegacyComponentNgFactory = i1.ɵccf("app-preserve-legacy", i17.PreserveLegacyComponent, View_PreserveLegacyComponent_Host_0, {}, {}, []);
export { PreserveLegacyComponentNgFactory as PreserveLegacyComponentNgFactory };
