/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../../node_modules/equitable-component-library/src/lib/button/button.component.ngfactory";
import * as i6 from "equitable-component-library/src/lib/button/button.component";
import * as i7 from "@angular/common";
import * as i8 from "./modal-popup.component";
import * as i9 from "../../services/utility.service";
var styles_ModalPopupComponent = [i0.styles];
var RenderType_ModalPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalPopupComponent, data: {} });
export { RenderType_ModalPopupComponent as RenderType_ModalPopupComponent };
function View_ModalPopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ion-toolbar", [], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(1, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "ion-title", [["class", "modal-popup-title"]], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(3, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(4, 0, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.modalTitle)); _ck(_v, 4, 0, currVal_0); }); }
function View_ModalPopupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "article", [["class", "ion-text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 15, "ion-grid", [], null, null, null, i2.View_IonGrid_0, i2.RenderType_IonGrid)), i1.ɵdid(4, 49152, null, 0, i3.IonGrid, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 13, "ion-row", [], null, null, null, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(6, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 5, "ion-col", [["size", "6"]], null, null, null, i2.View_IonCol_0, i2.RenderType_IonCol)), i1.ɵdid(8, 49152, null, 0, i3.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { size: [0, "size"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 3, "equitable-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.customProfile(_co.customRootPath) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i1.ɵdid(10, 638976, null, 0, i6.ButtonComponent, [], { type: [0, "type"], theme: [1, "theme"] }, null), (_l()(), i1.ɵted(11, 0, ["", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, 0, 5, "ion-col", [["size", "6"]], null, null, null, i2.View_IonCol_0, i2.RenderType_IonCol)), i1.ɵdid(14, 49152, null, 0, i3.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { size: [0, "size"] }, null), (_l()(), i1.ɵeld(15, 0, null, 0, 3, "equitable-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sampleProfile(_co.rootPath) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i1.ɵdid(16, 638976, null, 0, i6.ButtonComponent, [], { type: [0, "type"], theme: [1, "theme"] }, null), (_l()(), i1.ɵted(17, 0, ["", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = "6"; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.btnType; var currVal_3 = _co.btnTheme; _ck(_v, 10, 0, currVal_2, currVal_3); var currVal_5 = "6"; _ck(_v, 14, 0, currVal_5); var currVal_6 = _co.btnType; var currVal_7 = _co.btnTheme; _ck(_v, 16, 0, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("investorProfileText.modalPopSubTitle")); _ck(_v, 1, 0, currVal_0); var currVal_4 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("investorProfileText.customProfiles")); _ck(_v, 11, 0, currVal_4); var currVal_8 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("investorProfileText.sampleProfiles")); _ck(_v, 17, 0, currVal_8); }); }
function View_ModalPopupComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "article", [], null, null, null, null, null))], null, null); }
export function View_ModalPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "section", [["class", "ion-padding modal-popup-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalPopupComponent_1)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ion-img", [["class", "icon-close-popup"], ["src", "./assets/icons/icon-close.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonImg_0, i2.RenderType_IonImg)), i1.ɵdid(4, 49152, null, 0, i3.IonImg, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { src: [0, "src"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalPopupComponent_2)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalPopupComponent_3)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalTitle; _ck(_v, 2, 0, currVal_0); var currVal_1 = "./assets/icons/icon-close.svg"; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.isAddProfileForm; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.isAddProfileForm; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_ModalPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-popup", [], null, null, null, View_ModalPopupComponent_0, RenderType_ModalPopupComponent)), i1.ɵdid(1, 114688, null, 0, i8.ModalPopupComponent, [i3.ModalController, i3.NavParams, i9.UtilityService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalPopupComponentNgFactory = i1.ɵccf("app-modal-popup", i8.ModalPopupComponent, View_ModalPopupComponent_Host_0, {}, {}, []);
export { ModalPopupComponentNgFactory as ModalPopupComponentNgFactory };
