<div class="invest-tax">
  <div class="invest-tax-description">
    <h5 class="header">{{ "rcTool.investTaxSmart.header" | translate }}</h5>
    <p class="header-desc common-prop">
      {{ "rcTool.investTaxSmart.headerDesc" | translate }}
    </p>
    <div class="investment-section">
      <h5 class="font-header">
        {{ "rcTool.investTaxSmart.hypotheticalScene" | translate }}
      </h5>
      <div class="investment-details">
        <div class="left-side">
          <p class="common-prop">
            {{ "rcTool.investTaxSmart.investments" | translate }}
          </p>
          <p class="common-prop invest-color">
            {{ investment | currency: "USD":"symbol":"1.0" }}
          </p>
        </div>
        <div class="right-side">
          <p class="common-prop">
            {{ "rcTool.investTaxSmart.returnRate" | translate }}
          </p>
          <p class="common-prop invest-color">{{ rateOfReturn }}%</p>
        </div>
      </div>
      <div class="dropdowns-group">
        <div [formGroup]="form" class="drop-down-prop">
          <h5>{{ "rcTool.investTaxSmart.federalTaxBracket" | translate }}</h5>
          <equitable-drop-down
            class="equitable-dropdown"
            [formControl]="form.get('taxBracket')"
            formControlName="taxBracket"
            [options]="taxBracketsOptions"
            (change)="getTaxData()"
          ></equitable-drop-down>
          <div>
            <h5>{{ "rcTool.investTaxSmart.duration" | translate }}</h5>
            <equitable-drop-down
              class="equitable-dropdown"
              [formControl]="form.get('duration')"
              formControlName="duration"
              [options]="durationOptions"
              (change)="getTaxData()"
            ></equitable-drop-down>
          </div>
          
        </div>
      </div>
      <div><p>This hypothetical chart does not represent actual performance of any specific product or investment. Withdrawals of tax-deferred earnings are subject to ordinary income tax. A 10% federal tax may also apply if you take the withdrawal before you reach age 59½. Dividends and sales profits on annually taxed investments are generally taxed at capital gains tax rates, which can be lower than ordinary federal income tax rates. Using capital gains tax rates with the taxed-annually investment would reduce the difference between the taxed annually and tax-deferred accounts shown above. Please note that this chart excludes expenses associated with the Retirement Cornerstone® 19 variable annuity. If expenses had been reflected, the tax-deferred amounts would be lower. Consider your personal investment horizon and income tax bracket, both current and anticipated, when making an investment decision. These factors, as well as changes in tax rates and the treatment of investment earnings, may further affect the results of this comparison. Actual results will vary. Rates of return will vary over time, particularly for long-term investments. Investments offering the potential for higher rates of return also involve a higher level of risk.</p></div>
    </div>
  </div>
  <div class="invest-tax-chart">
    <div class="chart-contain">
      <equitable-investment-tax-smart
        [chartData]="chartData"
        [duration]="selectedDuration"
        [maxYscale]="maxYscale"
        [label]="chartLabel"
      ></equitable-investment-tax-smart>
    </div>
  </div>
</div>
