<equitable-tab-slider
  (changeData)="openTab($event)"
  [names]="btns"
  [selectedName]="selectedName"
></equitable-tab-slider>

<!-- Tab 1  termsOfUse-->
<div *ngIf="activeIndex === 0">
  <h1
    class="title-heading"
    [innerHTML]="'disclosure.scsincome.tab1.heading' | translate"
  ></h1>
  <div class="content">
    <ul>
      <span>{{ "disclosure.scsincome.tab1.content1" | translate }}</span>
      <li *ngIf="'disclosure.scsincome.tab1.content2' | translate"></li>
    </ul>
    <div [innerHTML]="'disclosure.scsincome.tab1.content3' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab1.content4' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab1.content5' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab1.content6' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab1.content7' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab1.content8' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab1.content9' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab1.content10' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab1.content11' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab1.content12' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab1.content13' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab1.content14' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab1.content15' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab1.content16' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab1.content17' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab1.content18' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab1.content19' | translate"></div>
  </div>
</div>

<!-- Tab 2  Get Started-->
<div *ngIf="activeIndex === 1">
  <h1
    class="title-heading"
    [innerHTML]="'disclosure.scsincome.tab2.heading' | translate"
  ></h1>
  <div class="content">
    <ul>
      <span>{{ "disclosure.scsincome.tab2.content1" | translate }}</span>
      <li *ngIf="'disclosure.scsincome.tab2.content2' | translate">
        <span>{{ "disclosure.scsincome.tab2.content2" | translate }}</span>
      </li>
    </ul>
    <div [innerHTML]="'disclosure.scsincome.tab2.content3' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab2.content4' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab2.content5' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab2.content6' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab2.content7' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab2.content8' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab2.content9' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab2.content10' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab2.content11' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab2.content12' | translate"></div>
    <ul>
      <span>{{ "disclosure.scsincome.tab2.content39" | translate }}</span>
      <li>
        {{ "disclosure.scsincome.tab2.content40" | translate }}
      </li>
      <li>
        {{ "disclosure.scsincome.tab2.content41" | translate }}
      </li>
    </ul>
  </div>
</div>

<!-- Tab 3  Historical Index Return-->
<div *ngIf="activeIndex === 2">
  <h1 class="title-heading">
    {{ "disclosure.scsincome.tab3.heading" | translate }}
  </h1>
  <div class="content">
    <div [innerHTML]="'disclosure.scsincome.tab3.content1' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab3.content2' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab3.content3' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab3.content4' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab3.content5' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab3.content6' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab3.content7' | translate"></div>
    <div [innerHTML]="'disclosure.scsincome.tab3.content8' | translate"></div>
  </div>
</div>