import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DisclosureComponent } from "./../disclosure/disclosure.component";
import { DISCLOSURE } from "../../../constants/constants";
import { FeatureFlagService } from "../../services/feature-flag.service";

@Component({
  selector: "app-disclosure-scs-plus21",
  templateUrl: "./disclosure-scs-plus21.component.html",
  styleUrls: ["./disclosure-scs-plus21.component.scss"],
})
export class DisclosureScsPlus21Component implements OnInit {
  activeIndex = 0;
  btns;
  toolName: string;
  selectedName: number;

  constructor(
    private translate: TranslateService,
    private discloure: DisclosureComponent,
    private flagService: FeatureFlagService
  ) {
    this.toolName = this.discloure.selectedTool;
  }

  ngOnInit() {
    this.translate.get("disclosure.scsPlus21").subscribe((disclosure) => {
      
      this.btns = [
        { id: 0, lable: disclosure.terms },
        { id: 1, lable: disclosure.getStarted },
        { id: 2, lable: disclosure.historicalIndexReturn },
        // { id: 3, lable: disclosure.performanceCapTrend },
        { id: 4, lable: disclosure.investorProfiles },
      ];
      console.log('Tabs')
      console.log(this.btns)
    });
    this.toolSelection();
  }

  // To display div
  openTab(id) {
    this.activeIndex = parseInt(id);
  }

  //tab change selection function
  toolSelection() {
    if (DISCLOSURE.SCS[this.toolName]) {
      this.selectedName = DISCLOSURE.SCS[this.toolName];
    } else {
      this.selectedName = 0;
    }
    this.openTab(this.selectedName);
  }
}
