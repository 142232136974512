import { Platform } from "@ionic/angular";
import { HttpResponse, HttpErrorResponse, } from "@angular/common/http";
import { throwError, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Network } from "@ionic-native/network/ngx";
import { FILE_EXTENSION } from "../../constants/constants";
import { UtilityService } from "../services/utility.service";
import { IonicStorageService } from "../services/ionic-storage.service";
import { FileSystemService } from "../services/file-system.service";
var CacheInterceptor = /** @class */ (function () {
    function CacheInterceptor(platform, network, ionicStorageService, fileSystemService, utilityService) {
        this.platform = platform;
        this.network = network;
        this.ionicStorageService = ionicStorageService;
        this.fileSystemService = fileSystemService;
        this.utilityService = utilityService;
    }
    CacheInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        if ((this.platform.is("cordova") &&
            this.network.type === this.network.Connection.NONE) ||
            !navigator.onLine) {
            return this.returnCachedData(req);
        }
        return next.handle(req).pipe(
        // map((event: HttpEvent<any>) => {
        //   if (event instanceof HttpResponse) {
        //     let parsedBody;
        //     if (req.url.indexOf("xml") != -1 || req.url.indexOf("plist") != -1) {
        //       parsedBody = this.ionicStorageService.parseResponse(
        //         req.url,
        //         event.body
        //       );
        //     }
        //     event = event.clone({ body: parsedBody });
        //     return event;
        //   }
        // }),
        map(function (event) {
            if (event instanceof HttpResponse) {
                event.headers.get("Content-Type");
                //const contentType = event.headers.get("Content-Type");
                var fileExtension = "";
                if (req.url.indexOf("http") == -1) {
                    fileExtension = req.url
                        .substring(req.url.lastIndexOf("."))
                        .replace(".", "");
                }
                else {
                    fileExtension = _this.utilityService.findFileExtension(req.url);
                }
                if (fileExtension) {
                    if (fileExtension === FILE_EXTENSION.JSON ||
                        fileExtension === FILE_EXTENSION.XML ||
                        fileExtension === FILE_EXTENSION.PLIST) {
                        //update xml response to json
                        var parsedBody = void 0;
                        if (req.url.indexOf("xml") != -1 ||
                            req.url.indexOf("plist") != -1) {
                            parsedBody = _this.ionicStorageService.parseResponse(req.url, event.body);
                            event = event.clone({ body: parsedBody });
                        }
                        _this.ionicStorageService.set(req.url, event);
                    }
                    else if (fileExtension === FILE_EXTENSION.JPG ||
                        fileExtension === FILE_EXTENSION.JPEG ||
                        fileExtension === FILE_EXTENSION.PNG ||
                        fileExtension === FILE_EXTENSION.PDF) {
                        if (_this.platform.is("cordova")) {
                            _this.fileSystemService.createAndWrite(_this.utilityService.createFileNameFromUrl(req.url), event.body);
                        }
                    }
                }
                return event;
            }
        }), catchError(function (error) {
            if (error instanceof HttpErrorResponse) {
                return _this.returnCachedData(req);
            }
            else {
                return throwError(error);
            }
        }));
    };
    CacheInterceptor.prototype.returnCachedData = function (req) {
        var fileExtension = this.utilityService.findFileExtension(req.url);
        if (fileExtension === FILE_EXTENSION.JSON ||
            fileExtension === FILE_EXTENSION.XML ||
            fileExtension === FILE_EXTENSION.PLIST) {
            return this.ionicStorageService.get(req.url).pipe(map(function (data) {
                if (data.body)
                    return data.clone({ body: data.body.data });
                else
                    return data;
            }), catchError(function (error) {
                return throwError(error);
            }));
        }
        else if (fileExtension === FILE_EXTENSION.JPG ||
            fileExtension === FILE_EXTENSION.JPEG ||
            fileExtension === FILE_EXTENSION.PNG ||
            fileExtension === FILE_EXTENSION.PDF) {
            if (this.platform.is("cordova")) {
                return this.fileSystemService.readFile(this.utilityService.createFileNameFromUrl(req.url));
            }
            else {
                return of(new HttpResponse({ body: null }));
            }
        }
        else {
            return of(new HttpResponse({ body: null }));
        }
    };
    return CacheInterceptor;
}());
export { CacheInterceptor };
