import * as tslib_1 from "tslib";
import { HttpResponse, HttpHeaders } from "@angular/common/http";
import { from, Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { Storage } from "@ionic/storage";
import { MIME_TYPE, API_METHODS } from "../../constants/constants";
import * as plist from "plist";
import { environment } from "../../../environments/environment";
import { HttpRequestService } from "./http-request.service";
import { FileUrlService } from "./file-url.service";
import * as i0 from "@angular/core";
import * as i1 from "@ionic/storage";
import * as i2 from "./http-request.service";
import * as i3 from "./file-url.service";
var IonicStorageService = /** @class */ (function () {
    function IonicStorageService(storage, httpService, urlService) {
        this.storage = storage;
        this.httpService = httpService;
        this.urlService = urlService;
        this.storage.ready().then(function () { });
    }
    // return the underlying driver used to implement storage
    IonicStorageService.prototype.getDriver = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.ready()];
                    case 1:
                        _a.sent();
                        this.keys = this.storage.keys();
                        return [2 /*return*/, this.storage.driver];
                }
            });
        });
    };
    // save to ionic storage
    IonicStorageService.prototype.set = function (url, response) {
        var body = {
            data: typeof response.body == "string"
                ? JSON.parse(response.body)
                : response.body,
        };
        body["lastModifiedDate"] = new Date().getTime();
        var res = this.storage.set(url, body);
        return res;
    };
    // retrieve from ionic storage
    IonicStorageService.prototype.get = function (url) {
        return from(this.storage.get(url)).pipe(map(function (data) {
            var headers = new HttpHeaders({});
            if (data) {
                var date = (data && data["lastModifiedDate"]
                    ? data["lastModifiedDate"]
                    : new Date().toString) + "";
                headers = new HttpHeaders({
                    "Content-Type": MIME_TYPE.JSON,
                    "X-cached-data": "true",
                    "X-last-modified-date": date + "",
                });
            }
            return new HttpResponse({ body: data, headers: headers });
        }));
    };
    IonicStorageService.prototype.setValueByKey = function (key, value) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.set(key, value)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    IonicStorageService.prototype.getValueByKey = function (key) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get(key)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    IonicStorageService.prototype.hasKey = function (key) {
        return this.keys.findIndex(key) != -1 ? true : false;
    };
    //store all urls in main json file
    IonicStorageService.prototype.storeURL = function (body, baseUrl) {
        var _this = this;
        this.urlService.scanForUrl(body, baseUrl);
        var urls = this.urlService.getAllUrls();
        urls.forEach(function (element) {
            var requst = {
                method: API_METHODS.GET,
                url: element,
                isBluk: true,
            };
            _this.httpService.request(requst).subscribe(function (res) {
                // console.log("response stored");
            }, function (error) {
                //need to check
                //this.storage.clear();
            });
        });
    };
    IonicStorageService.prototype.loadMainData = function () {
        // return forkJoin([this.getSCSInfo(), this.getRCInfo()]);
        return forkJoin([this.getIEInfo(), this.getSCSInfo(), this.getRCInfo()]);
    };
    // added for IE // 
    IonicStorageService.prototype.getIEInfo = function () {
        var _this = this;
        var baseURL = environment.serviceAPIs.baseUrl;
        if (/https.*/.test(environment.serviceAPIs.ie)) {
            baseURL = "";
        }
        console.log("XXXIE", environment.serviceAPIs.ie);
        var requst = {
            method: API_METHODS.GET,
            url: baseURL + environment.serviceAPIs.ie,
        };
        this.httpService.request(requst).subscribe(function (res) {
            //get older storage information. if version is not same then store other
            _this.get(requst.url).subscribe(function (response) {
                var version = response.body ? response.body["data"]["version"].id : 0;
                if (version !== res.body.version.id) {
                    _this.storeURL(res.body);
                }
            });
        });
    };
    //method for getting RC info. May shift from here
    IonicStorageService.prototype.getRCInfo = function () {
        var _this = this;
        var baseURL = environment.serviceAPIs.baseUrl;
        if (/https.*/.test(environment.serviceAPIs.rc)) {
            baseURL = "";
        }
        console.log("XXXRC", environment.serviceAPIs.rc);
        var requst = {
            method: API_METHODS.GET,
            url: baseURL + environment.serviceAPIs.rc,
        };
        this.httpService.request(requst).subscribe(function (res) {
            var body = res.body;
            //get older storage information. if version is not same then store other
            _this.get(requst.url).subscribe(function (response) {
                var version = response.body
                    ? response.body["data"]["versionAvailable"]
                    : 0;
                if (version !== body.versionAvailable) {
                    _this.storeURL(body, body.serverURL); //body.serverURL
                }
            });
        });
    };
    //method for getting RC info. May shift from here
    IonicStorageService.prototype.getSCSInfo = function () {
        var _this = this;
        var baseURL = environment.serviceAPIs.baseUrl;
        if (/https.*/.test(environment.serviceAPIs.scs)) {
            baseURL = "";
        }
        var requst = {
            method: API_METHODS.GET,
            url: baseURL + environment.serviceAPIs.scs,
        };
        this.httpService.request(requst).subscribe(function (res) {
            //get older storage information. if version is not same then store other
            _this.get(requst.url).subscribe(function (response) {
                var version = response.body ? response.body["data"]["version"].id : 0;
                if (version !== res.body.version.id) {
                    _this.storeURL(res.body);
                }
            });
        });
    };
    IonicStorageService.prototype.parseResponse = function (url, data) {
        var xml;
        if (url.indexOf("scs_ipad_HistoricalReturnValues_v2") != -1 ||
            url.indexOf("scs_main") != -1) {
            var parser = new DOMParser();
            xml = parser.parseFromString(data, "text/xml");
        }
        else {
            data =
                data.indexOf("<plist") == -1 ? "<plist>" + data + "</plist>" : data;
            xml = plist.parse(data.toString());
        }
        var json;
        // convert JSON object to ideal JSON object format
        if (url.indexOf("howItWorksDynamicData") != -1) {
            json = this.convertHowItWorksDyanmicData(xml);
        }
        else if (url.indexOf("scs_ipad_CapTrend") != -1) {
            json = this.convertCapTrend(xml);
        }
        else if (url.indexOf("scs_ipad_HistoricalPerformancePresetValues") != -1) {
            json = this.covertHistoricalPresetData(xml);
        }
        else if (url.indexOf("scs_ipad_HistoricalReturnValues_v2") != -1) {
            json = this.covertHistoricalReturnData(xml);
        }
        else if (url.indexOf("scs_ipad_HistoricalPerformanceChartWithDurations") != -1 ||
            url.indexOf("scs_ipad_HistoricalPerformanceChart_SeriesADV") != -1 ||
            url.indexOf("scs_ipad_HistoricalPerformanceChart_SeriesC") != -1 ||
            url.indexOf("scs_ipad_HistoricalPerformance_5year") != -1) {
            json = this.convertHistoricalPerformance(xml);
        }
        else if (url.indexOf("scs_main") != -1) {
            json = this.convertSCS(xml);
        }
        else if (url.indexOf("scs_ipad_threePieCharts_SeriesPLUS") !== -1 ||
            url.indexOf("scs_ipad_threePieCharts_SeriesPLUS21") !== -1 ||
            url.indexOf("scs_ipad_well_fargo_threePieCharts")) {
            json = this.convertPiechart(xml);
        }
        else {
            json = data;
        }
        return json;
    };
    IonicStorageService.prototype.convertSCS = function (xmlDoc) {
        var seriesNodes = xmlDoc.getElementsByTagName("Series")[0].children;
        var series = [];
        function getKits(series) {
            var kits = [];
            var salesKits = series.getElementsByTagName("SalesKitFiles2")[0]
                .children;
            for (var i = 0; i < salesKits.length; i++) {
                var kit = salesKits[i];
                kits.push({
                    type: "SALES",
                    id: "PROSPECTUS",
                    description: kit.children[0].childNodes[0].nodeValue.trim(),
                    image: kit.children[1].childNodes[0].nodeValue.trim(),
                    file: kit.children[2].childNodes[0].nodeValue.trim(),
                });
            }
            return kits;
        }
        for (var i = 0; i < seriesNodes.length; i++) {
            if (seriesNodes[i].nodeName != "#text") {
                var objSeries = {
                    name: seriesNodes[i].nodeName.replace("Serie", ""),
                    performance: {
                        durations: seriesNodes[i].getElementsByTagName("HistoricalPerformanceWithDurationsURLFile").length > 0
                            ? seriesNodes[i]
                                .getElementsByTagName("HistoricalPerformanceWithDurationsURLFile")[0]
                                .childNodes[0].nodeValue.trim()
                            : "",
                        capTrend: seriesNodes[i].getElementsByTagName("PerformanceCapTrendURLFile")
                            .length > 0
                            ? seriesNodes[i]
                                .getElementsByTagName("PerformanceCapTrendURLFile")[0]
                                .childNodes[0].nodeValue.trim()
                            : "",
                        historicalReturns: seriesNodes[i].getElementsByTagName("HistoricalIndexURLFile_v2")
                            .length > 0
                            ? seriesNodes[i]
                                .getElementsByTagName("HistoricalIndexURLFile_v2")[0]
                                .childNodes[0].nodeValue.trim()
                            : "",
                        historicalPerformance: seriesNodes[i].getElementsByTagName("HistoricalPerformancePresetValuesOptions").length > 0
                            ? seriesNodes[i]
                                .getElementsByTagName("HistoricalPerformancePresetValuesOptions")[0]
                                .childNodes[0].nodeValue.trim()
                            : "",
                        howItWorks: seriesNodes[i].getElementsByTagName("HowItWorksDynamicData")
                            .length > 0
                            ? seriesNodes[i]
                                .getElementsByTagName("HowItWorksDynamicData")[0]
                                .childNodes[0].nodeValue.trim()
                            : "",
                        premiumDeathBenefit: seriesNodes[i].getElementsByTagName("ReturnPremiumDeathBenefit")
                            .length > 0
                            ? seriesNodes[i]
                                .getElementsByTagName("ReturnPremiumDeathBenefit")[0]
                                .childNodes[0].nodeValue.trim()
                            : "",
                        threePieChartsData: seriesNodes[i].getElementsByTagName("HMBAG_ThreePieChartsData")
                            .length > 0
                            ? seriesNodes[i]
                                .getElementsByTagName("HMBAG_ThreePieChartsData")[0]
                                .childNodes[0].nodeValue.trim()
                            : "",
                    },
                    kits: getKits(seriesNodes[i]),
                };
                series.push(objSeries);
            }
        }
        var finalObj = {
            version: {
                id: xmlDoc
                    .getElementsByTagName("versionavailable")[0]
                    .childNodes[0].nodeValue.trim(),
                message: xmlDoc
                    .getElementsByTagName("message")[0]
                    .childNodes[0].nodeValue.trim(),
                mandatory: xmlDoc
                    .getElementsByTagName("forceUpdate")[0]
                    .childNodes[0].nodeValue.trim(),
            },
            notifications: {
                mobile: xmlDoc
                    .getElementsByTagName("Notification")[0]
                    .childNodes[0].nodeValue.trim(),
                web: xmlDoc
                    .getElementsByTagName("NotificationWeb")[0]
                    .childNodes[0].nodeValue.trim(),
            },
            languageBaseURL: "",
            series: series,
        };
        return finalObj;
    };
    IonicStorageService.prototype.covertHistoricalReturnData = function (xmlDoc) {
        var data = [];
        var nodes = xmlDoc.documentElement.childNodes;
        function getLosses(obj) {
            var losses = [];
            for (var j = 0; j < obj.attributes.length; j++) {
                if (obj.attributes[j].name.substr(0, 4) == "loss") {
                    losses.push({
                        type: obj.attributes[j].name.replace("loss", "").toLowerCase(),
                        value: obj.attributes[j].value,
                        typeOriginal: obj.attributes[j].name,
                    });
                }
            }
            return losses;
        }
        for (var i = 0; i < nodes.length; i++) {
            if (nodes[i].nodeName == "index") {
                // console.log(nodes[i].childNodes[0].nodeValue);
                var returns = [];
                for (var j = 0; j < nodes[i].children.length; j++) {
                    if (nodes[i].children[j].nodeName == "columm") {
                        var objReturn = {
                            id: j + 1,
                            duration: nodes[i].children[j].attributes["duration"].value,
                            averageReturn: nodes[i].children[j].attributes["averageReturn"].value,
                            totalGainsAndLosses: nodes[i].children[j].attributes["totalGainsAndLosses"].value,
                            numOfGains: nodes[i].children[j].attributes["numOfGains"].value,
                            numOfLosses: nodes[i].children[j].attributes["numOfLosses"].value,
                            losses: getLosses(nodes[i].children[j]),
                            percentTimesAboveHighestBuffer: nodes[i].children[j].attributes["percentTimesAboveHighestBuffer"].value,
                            percentTimesAnyLosses: nodes[i].children[j].attributes["percentTimesAnyLosses"].value,
                        };
                        returns.push(objReturn);
                    }
                }
                var obj = {
                    id: nodes[i].attributes[0].value,
                    label: nodes[i].attributes[1].value,
                    returns: returns,
                };
                data.push(obj);
            }
        }
        //console.log(plist);
        return data;
    };
    IonicStorageService.prototype.convertHistoricalPerformance = function (plist) {
        var data = [];
        for (var key in plist) {
            var historicalPerformance = [];
            for (var bufferAndYear in plist[key]) {
                var indices = [];
                for (var i in plist[key][bufferAndYear].indices) {
                    indices.push({
                        id: plist[key][bufferAndYear].indices[i].Name,
                        name: plist[key][bufferAndYear].indices[i].Name,
                        segmentRateValue: plist[key][bufferAndYear].indices[i].SegmentRateValue,
                        indexReturn: plist[key][bufferAndYear].indices[i].IndexReturn,
                        historicalCapRate: plist[key][bufferAndYear].indices[i].HistoricalCapRate,
                    });
                }
                historicalPerformance.push({
                    id: bufferAndYear,
                    title: bufferAndYear,
                    buffer: plist[key][bufferAndYear].buffer,
                    year: plist[key][bufferAndYear].duration,
                    duration: plist[key][bufferAndYear].duration,
                    indices: indices,
                });
            }
            data.push({
                id: key,
                title: key,
                date: key.split(",")[0],
                historicalPerformance: historicalPerformance,
            });
        }
        return { historicalPerformance: data };
    };
    IonicStorageService.prototype.convertCapTrend = function (plist) {
        var capTrend = [];
        plist.forEach(function (index) {
            // merge the history and dates into a single object
            var history = [];
            for (var i = 0; i < index.History.length; i++) {
                history.push({
                    date: index.Dates[i],
                    value: index.History[i],
                });
            }
            // create the new object from the original object
            capTrend.push({
                id: index.ID,
                position: index.PositionIndex,
                name: index.Name,
                tenor: index.SubTitle1,
                buffer: index.SubTitle2,
                segment: index.Segment,
                metrics: {
                    average: index.Average,
                    min: index.Min,
                    max: index.Max,
                },
                history: history,
            });
        });
        // console.log(capTrend);
        return capTrend;
    };
    //convert How it Works
    IonicStorageService.prototype.convertHowItWorksDyanmicData = function (obj) {
        var perfomance = [];
        function getAllPerformaceRate(obj) {
            var perfArray = [];
            for (var property in obj) {
                if (typeof obj[property] == "object") {
                    perfArray.push(obj[property]["kIndexPerformanceRate"]);
                }
            }
            return perfArray;
        }
        var res = obj[Object.keys(obj)[0]];
        var _loop_1 = function (property) {
            if (typeof res[property] == "object") {
                //add years
                //get all keys in years
                var buffers = Object.keys(res[property]);
                buffers.forEach(function (element) {
                    //get all performace rate
                    var objPerformance = {
                        id: property,
                        max: res[property][element].kMaxValue,
                        min: res[property][element].kMinValue,
                        interval: res[property][element].kIntervalSteep,
                        performaceCapRate: res[property][element].kPerformanceCapRate,
                        buffer: element,
                        performaceRate: getAllPerformaceRate(res[property][element]),
                    };
                    perfomance.push(objPerformance);
                });
            }
        };
        for (var property in res) {
            _loop_1(property);
        }
        var finalObj = {
            id: Object.keys(obj)[0],
            title: res["kCompleteTitle"],
            barTitle: res["kBarTitle"],
            performance: perfomance,
        };
        return finalObj;
    };
    //convert HistoricalData
    IonicStorageService.prototype.covertHistoricalPresetData = function (obj) {
        var data = [];
        var allHistoricalData = Object.keys(obj);
        function getIndices(obj) {
            var indices = [];
            for (var property in obj) {
                if (typeof obj[property] == "object") {
                    var objIndicies = {
                        id: property,
                        name: obj[property]["Name"],
                        percentage: obj[property]["Percentage"],
                    };
                    indices.push(objIndicies);
                }
            }
            return indices;
        }
        allHistoricalData.forEach(function (element, index) {
            var history = {
                id: index + 1,
                option: element,
                maturityDate: obj[element]["Maturity Date"] != undefined &&
                    obj[element]["Maturity Date"] != null &&
                    obj[element]["Maturity Date"] != ""
                    ? obj[element]["Maturity Date"].split(",")[0]
                    : "",
                series: obj[element]["Maturity Date"] != undefined &&
                    obj[element]["Maturity Date"] != null &&
                    obj[element]["Maturity Date"] != ""
                    ? obj[element]["Maturity Date"].split(",")[1].trim()
                    : "",
                duration: obj[element]["Maturity Date"] != undefined
                    ? obj[element]["Maturity Date"]
                    : "",
                buffer: obj[element]["Duration Buffer"] != undefined
                    ? obj[element]["Duration Buffer"]
                    : "",
                // duration:
                //   obj[element]["Duration Buffer"] != undefined &&
                //     obj[element]["Duration Buffer"] != null &&
                //     obj[element]["Duration Buffer"] != ""
                //     ? obj[element]["Duration Buffer"].split("Year")[0] + "Year"
                //     : "",
                // buffer:
                //   obj[element]["Duration Buffer"] != undefined &&
                //     obj[element]["Duration Buffer"] != null &&
                //     obj[element]["Duration Buffer"] != ""
                //     ? obj[element]["Duration Buffer"].split("Year")[1].trim()
                //     : "",
                initialInvestment: obj[element]["Initial Investment"],
                indices: getIndices(obj[element]["indices"]),
            };
            data.push(history);
        });
        return {
            presetData: data,
        };
    };
    //convert dropdownwithChartImage
    IonicStorageService.prototype.convertPiechart = function (plist) {
        var data = [];
        for (var index = 0; index < plist.length; index++) {
            var element = plist[index];
            var dataElement = {};
            dataElement["id"] = element.Index;
            dataElement["value"] = element.Label;
            dataElement["chartImage"] = element.ChartImage;
            data.push(dataElement);
        }
        return {
            pieChartData: data,
        };
    };
    IonicStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IonicStorageService_Factory() { return new IonicStorageService(i0.ɵɵinject(i1.Storage), i0.ɵɵinject(i2.HttpRequestService), i0.ɵɵinject(i3.FileUrlService)); }, token: IonicStorageService, providedIn: "root" });
    return IonicStorageService;
}());
export { IonicStorageService };
