import { RcService } from "../../services/rc.service";
import { OnInit } from "@angular/core";
import { DIVERSIFY } from "../../../constants/constants";
import { UtilityService } from "src/app/core/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { Theme } from "equitable-component-library";
var DiversificationComponent = /** @class */ (function () {
    /***
     *  Constructor
     ***/
    function DiversificationComponent(rcServie, utilityService, translateService) {
        this.rcServie = rcServie;
        this.utilityService = utilityService;
        this.translateService = translateService;
        this.dark = Theme.Dark;
        this.light = Theme.Light;
        this.years = [];
        this.performanceData = [];
        this.activeSubOption = "";
        this.performanceName = "";
        this.performanceJson = { performanceData: {} };
        this.lineWidth = 0;
        this.buttons = [];
        this.buttonsBottom = [];
        this.PRODUCT_TYPE = "";
    }
    /***
     *  page load
     ***/
    DiversificationComponent.prototype.ngOnInit = function () {
        this.loadPageData();
    };
    /***
     *  page load data
     ***/
    DiversificationComponent.prototype.loadPageData = function () {
        var _this = this;
        this.theme = "light";
        this.translateService.get("diversify.buttons").subscribe(function (buttons) {
            _this.buttons = [
                {
                    text: buttons.usStocks,
                    value: DIVERSIFY.uSStocks,
                },
                {
                    text: buttons.usBonds,
                    value: DIVERSIFY.uSBonds,
                },
                {
                    text: buttons.realEstate,
                    value: DIVERSIFY.realEstate,
                },
                {
                    text: buttons.nonUSStocks,
                    value: DIVERSIFY.nonUSStocks,
                },
                {
                    text: buttons.nonUSBonds,
                    value: DIVERSIFY.nonUSBonds,
                },
                {
                    text: buttons.commodities,
                    value: DIVERSIFY.commodities,
                },
            ];
            // this.buttonsBottom = [
            //   {
            //     text: buttons.nonUSStocks,
            //     value: DIVERSIFY.nonUSStocks,
            //   },
            //   {
            //     text: buttons.nonUSBonds,
            //     value: DIVERSIFY.nonUSBonds,
            //   },
            //   {
            //     text: buttons.commodities,
            //     value: DIVERSIFY.commodities,
            //   },
            // ];
        });
        this.rcServie.fetchDiversify().subscribe(function (data) {
            _this.performanceJson = JSON.parse(JSON.stringify(data)).body;
            _this.years = [];
            _this.performanceData = [];
            var tempData = [];
            var performanceData1 = [];
            var performanceData2 = [];
            var performanceData3 = [];
            var performanceData4 = [];
            var performanceData5 = [];
            Object.keys(_this.performanceJson.performanceData).forEach(function (key) {
                _this.years.push(key);
                tempData.push(_this.performanceJson.performanceData[key][0]);
                performanceData1.push(_this.performanceJson.performanceData[key][1]);
                performanceData2.push(_this.performanceJson.performanceData[key][2]);
                performanceData3.push(_this.performanceJson.performanceData[key][3]);
                performanceData4.push(_this.performanceJson.performanceData[key][4]);
                performanceData5.push(_this.performanceJson.performanceData[key][5]);
            });
            _this.performanceData.push(tempData);
            _this.performanceData.push(performanceData1);
            _this.performanceData.push(performanceData2);
            _this.performanceData.push(performanceData3);
            _this.performanceData.push(performanceData4);
            _this.performanceData.push(performanceData5);
        });
    };
    /***
     *  draw line to all segments
     ***/
    DiversificationComponent.prototype.drawLine = function (performanceName) {
        var _this = this;
        this.theme = "dark";
        if (performanceName != "") {
            this.activeSubOption = performanceName;
            var arrLeft_1 = [];
            var clsName = this.returnClassName(performanceName);
            // eslint-disable-next-line angular/document-service
            var className = document.getElementsByClassName(clsName);
            for (var index = 0; index < className.length; index++) {
                var element = className[index];
                arrLeft_1.push(element);
            }
            arrLeft_1.sort(function (a, b) {
                return a.offsetLeft - b.offsetLeft;
            });
            // eslint-disable-next-line angular/timeout-service
            setTimeout(function () {
                _this.performanceName = performanceName;
            }, 400);
            var tempIndexX1_1 = arrLeft_1[0].offsetLeft + arrLeft_1[0].offsetWidth / 2 - this.lineWidth;
            var tempIndexY1_1 = arrLeft_1[0].offsetTop + arrLeft_1[0].offsetHeight / 2 - this.lineWidth;
            Object.keys(this.performanceJson.performanceData).forEach(function (key, index) {
                if (index > 0) {
                    // eslint-disable-next-line angular/timeout-service
                    setTimeout(function () {
                        var toOffSetX = arrLeft_1[index].offsetLeft +
                            arrLeft_1[index].offsetWidth / 2 -
                            _this.lineWidth;
                        var toOffSetY = arrLeft_1[index].offsetTop + arrLeft_1[index].offsetHeight / 2;
                        if (arrLeft_1[index - 1].offsetTop != arrLeft_1[index].offsetTop &&
                            arrLeft_1[index - 1].offsetTop + arrLeft_1[0].offsetHeight !=
                                arrLeft_1[index].offsetTop &&
                            arrLeft_1[index - 1].offsetTop - arrLeft_1[0].offsetHeight !=
                                arrLeft_1[index].offsetTop) {
                            _this.randoRect(performanceName, tempIndexX1_1, tempIndexY1_1, toOffSetX, toOffSetY, 0);
                        }
                        tempIndexX1_1 =
                            arrLeft_1[index].offsetLeft +
                                arrLeft_1[index].offsetWidth / 2 -
                                _this.lineWidth;
                        tempIndexY1_1 =
                            arrLeft_1[index].offsetTop +
                                arrLeft_1[index].offsetHeight / 2 -
                                _this.lineWidth;
                    }, 400);
                }
            });
        }
    };
    /***
     *  return class name based on params
     ***/
    DiversificationComponent.prototype.returnClassName = function (name) {
        var className = [
            { className: DIVERSIFY.t1, name: DIVERSIFY.nonUSStocks },
            { className: DIVERSIFY.t2, name: DIVERSIFY.commodities },
            { className: DIVERSIFY.t3, name: DIVERSIFY.realEstate },
            { className: DIVERSIFY.t4, name: DIVERSIFY.nonUSBonds },
            { className: DIVERSIFY.t5, name: DIVERSIFY.uSStocks },
            { className: DIVERSIFY.t6, name: DIVERSIFY.uSBonds },
        ];
        var clsName = className.filter(function (item) {
            return name === item.name;
        });
        return clsName[0].className;
    };
    /***
     *  Draw rect
     ***/
    DiversificationComponent.prototype.randoRect = function (performanceName, x1, y1, x2, y2, i) {
        var _this = this;
        // eslint-disable-next-line angular/document-service
        var canvasDraw = document.getElementById("cnv");
        var ctxDraw = canvasDraw.getContext("2d");
        ctxDraw.clearRect(0, 0, canvasDraw.width, canvasDraw.height);
        // eslint-disable-next-line angular/timeout-service
        setTimeout(function () {
            _this.drawSquare(performanceName, i, x1, y1, x2, y2, ctxDraw);
        }, 200);
    };
    DiversificationComponent.prototype.drawSquare = function (performanceName, i, x1, y1, x2, y2, ctxDraw) {
        ctxDraw.beginPath();
        ctxDraw.moveTo(x1, y1);
        ctxDraw.lineTo(x2, y2);
        ctxDraw.lineWidth = 3;
        ctxDraw.strokeStyle = DIVERSIFY.lineColor;
        ctxDraw.globalAlpha = 1;
        ctxDraw.stroke();
    };
    return DiversificationComponent;
}());
export { DiversificationComponent };
