import { FeatureFlagService } from "../../../core/services/feature-flag.service";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { PRODUCT_TYPE } from "src/app/constants/constants";
import { ActivatedRoute } from "@angular/router";
import { UtilityService } from "../../services/utility.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-disclosure",
  templateUrl: "./disclosure.component.html",
  styleUrls: ["./disclosure.component.scss"]
})
export class DisclosureComponent implements OnInit {
  resourceForm: FormGroup;
  displayRc: boolean;
  displayScs: boolean;
  displayScsPlus: boolean;
  displayScsPlus21: boolean;
  displayScsIncome: boolean;
  displayIe: boolean;
  discoverRcPage: string;
  resourceOptions: any;
  selectedTool: string;
  showDisclosureDropdown: boolean;

  constructor(
    private route: ActivatedRoute,
    private utilityService: UtilityService,
    private translate: TranslateService,
    private flagService: FeatureFlagService
  ) {
    this.resourceForm = new FormGroup({
      resourceDropDown: new FormControl(
        this.discoverRcPage,
        Validators.required
      )
    });
    this.getSelectedScreen();
  }

  ngOnInit() {
    this.resourceForm.valueChanges.subscribe(data => {
      this.dropDownSection(data.resourceDropDown);
    });

    // dropdown data
    this.translate
      .get("dropDown.investmentEdge")
      .subscribe((translated: string) => {
        var nationalOptions = [
          {
            id: PRODUCT_TYPE.IE,
            value: translated
          },
          {
            id: PRODUCT_TYPE.SCS,
            value: this.translate.instant(
              "dropDown.structuredCapitalStrategies"
            )
          },
          {
            id: PRODUCT_TYPE.SCS_PLUS21,
            value: this.translate.instant(
              "dropDown.structuredCapitalStrategiesPlus21"
            )
          },
          {
            id: PRODUCT_TYPE.SCSINCOME,
            value: this.translate.instant(
              "dropDown.structuredCapitalStrategiesIncome"
            )
          },
          {
            id: PRODUCT_TYPE.SCS_PLUS,
            value: this.translate.instant(
              "dropDown.structuredCapitalStrategiesPlus"
            )
          },
          {
            id: PRODUCT_TYPE.RC,
            value: this.translate.instant("dropDown.retirementCornerStone")
          }
        ];
        var primericaOptions = [
          {
            id: PRODUCT_TYPE.SCS,
            value: this.translate.instant(
              "dropDown.structuredCapitalStrategies"
            )
          },
          {
            id: PRODUCT_TYPE.SCS_PLUS,
            value: this.translate.instant(
              "dropDown.structuredCapitalStrategiesPlus"
            )
          },
          {
            id: PRODUCT_TYPE.SCS_PLUS21,
            value: this.translate.instant(
              "dropDown.structuredCapitalStrategiesPlus21"
            )
          },
          {
            id: PRODUCT_TYPE.RC,
            value: this.translate.instant("dropDown.retirementCornerStone")
          }
        ];

        this.showDisclosureDropdown = this.flagService.featureFlags.isPrimerica || this.flagService.featureFlags.isNational

        if (this.flagService.featureFlags.isPrimerica) {
        this.resourceOptions = primericaOptions
        } else {
          this.resourceOptions = nationalOptions
        }
      });
  }

  dropDownSection(data) {
    this.displayRc = false;
    this.displayScs = false;
    this.displayScsPlus = false;
    this.displayScsPlus21 = false;
    this.displayIe = false;
    this.displayScsIncome = false;

    if (data === PRODUCT_TYPE.RC) {
      this.displayRc = true;
    } else if (data === PRODUCT_TYPE.SCS_PLUS21) {
      this.displayScsPlus21 = true;
    } else if (data === PRODUCT_TYPE.SCS_PLUS) {
      this.displayScsPlus = true;
    } else if (data === PRODUCT_TYPE.IE) {
      this.displayIe = true;
    } else if (data === PRODUCT_TYPE.SCSINCOME) {
      this.displayScsIncome = true;
    } else {
      this.displayScs = true;
    }
  }

  Section(data) {
    if (data === PRODUCT_TYPE.RC) {
      this.displayRc = true;
      this.resourceForm.get("resourceDropDown").setValue(PRODUCT_TYPE.RC);
    } else if (data === PRODUCT_TYPE.SCS_PLUS21) {
      this.displayScsPlus21 = true;
      this.resourceForm.get("resourceDropDown").setValue(PRODUCT_TYPE.SCS_PLUS21);
    } else if (data === PRODUCT_TYPE.SCS_PLUS) {
      this.displayScsPlus = true;
      this.resourceForm.get("resourceDropDown").setValue(PRODUCT_TYPE.SCS_PLUS);
    } else if (data === PRODUCT_TYPE.SCSINCOME) {
      this.displayScsIncome = true;
      this.resourceForm.get("resourceDropDown").setValue(PRODUCT_TYPE.SCSINCOME);
    } else if (data === PRODUCT_TYPE.IE) {
      this.displayIe = true;
      this.resourceForm.get("resourceDropDown").setValue(PRODUCT_TYPE.IE);
    } else {
      this.displayScs = true;
      this.resourceForm.get("resourceDropDown").setValue(PRODUCT_TYPE.SCS);
    }
  }

  getSelectedScreen() {
    this.route.queryParams.subscribe(params => {
      this.discoverRcPage = params["product"];
      this.selectedTool = params["tool"];
      this.Section(this.discoverRcPage);
    });
  }

  // back button navigation
  backButton() {
    this.utilityService.navigateToBack();
  }
}
