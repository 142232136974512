<ion-content>
  <div class="container">
    <div class="back-link">
      <equitable-link
        text="{{ 'prospectus.linkText.' + productName | translate }}"
        [iconName]="icon"
        [type]="theme"
        [orientation]="linkOrientation"
        (click)="backButton()"
      ></equitable-link>
    </div>
    <div class="resource-header">
      <ion-row>
        <ion-col class="ion-align-self-end">
          <span
            class="resource-title ion-text-left"
            innerHTML="{{
              'prospectus.productName.' + productName | translate
            }} {{ 'prospectus.title' | translate }}"
          ></span>
        </ion-col>
        <ion-col size="auto">
          <div class="resource-dropdown-container">
            <label [class.hidden]="isHide" class="resource-dropdown-label">{{
              "prospectus.filterBySeries" | translate
            }}</label>
            <form target="iFrame" id="ssoform" [action]="url" method="get">
              <select
                class="drop-down"
                (change)="onChange()"
                [class.hidden]="isHide"
                name="pd"
                aria-label="dropdown"
              >
                <option *ngFor="let item of options" [value]="item.id">{{
                  item.label
                }}</option>
              </select>
              <select
                style="display: none;"
                type="hidden"
                name="style"
                aria-label="dropdown"
              >
                <option [value]="displayOptionValue">{{
                  "prospectus.scsDropdown.DisplayOption" | translate
                }}</option>
              </select>
              <input
                *ngFor="let item of InputOptions"
                type="hidden"
                [id]="item.id"
                [name]="item.id"
                [value]="item.value"
              />
            </form>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="ion-padding-top">
        <ion-col>
          <div
            [ngClass]="
              productName === productSCS ? 'scs-container' : 'iframe-container'
            "
          >
            <iframe
              id="iFrame"
              name="iFrame"
              height="100%"
              width="100%"
              frameborder="0"
              scrolling="auto"
              on-load="load()"
              [title]="'prospectus.title' | translate"
            >
            </iframe>
          </div>
        </ion-col>
      </ion-row>
    </div>
  </div>
  <app-footer
    isBlueFooterVisible="true"
    selectedProduct="prospectus"
  ></app-footer>
</ion-content>
