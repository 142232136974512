<ion-content>
  <div class="main-content" [ngClass]="isPrimerica ? 'primerica-content' : ''">
    <div [ngClass]="isPrimerica ? 'site-tools' : 'site-tools'">
      <div class="page-heading">
        <equitable-sub-header
          title="{{ 'historicalPerformanceChart.title' | translate }}"
          backLinkText="{{
            'historicalPerformanceChart.backLinkTextIE' | translate
          }}"
          (backClick)="onBack()"
        ></equitable-sub-header>
      </div>
      <div class="main">
        <ion-card>
          <ion-card-content>
            <ion-row>
              <ion-col class="description">
                <div class="ion-text-left">
                  <div class="heading">
                    {{ "historicalPerformanceChart.heading" | translate }}
                  </div>
                  <p class="sub-heading">
                    {{
                      "historicalPerformanceChart.chartDescriptionIE" | translate
                    }}
                  </p>
                </div>
              </ion-col>
              <span class="charts">
                <ion-col
                  class="dropdown"
                  [formGroup]="formGroup"
                  *ngFor="
                    let dropdowns of formGroup.controls | keyvalue;
                    index as i
                  "
                >
                  <equitable-drop-down
                    [formControl]="dropdowns.value"
                    formControlName="dropdown{{ i + 1 }}"
                    [options]="options"
                  ></equitable-drop-down>
                  <img
                    alt=""
                    *ngIf="dropdowns.value.value"
                    src="{{ imageSrcPath }}{{ dropdowns.value.value }}.png"
                  />
                  <!-- <p *ngIf="i == 2 && isNotPlusGuard">
                    {{ "historicalPerformanceChart.seriesName" | translate
                    }}{{ series }}
                  </p> -->
                  <span *ngIf="i < 2" class="divider"></span>
                </ion-col>
              </span>
            </ion-row>
          </ion-card-content>
        </ion-card>
        <ion-row class="customize-link">
          <ion-col>
            <!-- <equitable-link
              class="customizeProfile"
              text="{{
                'historicalPerformanceChart.customizeProfile' | translate
              }}
          "
              [type]="customizeLinkType"
              [iconName]="customizeLinkIconName"
              [orientation]="customizeLinkIconOrientation"
              (click)="saveProfile(CUSTOMIZATION_TYPE.PROFILE)"
            ></equitable-link> -->
            <equitable-link
              class="customizeReport"
              text="{{
                'historicalPerformanceChart.customizeReport' | translate
              }}
        "
              [type]="customizeLinkType"
              [iconName]="customizeLinkIconName"
              [orientation]="customizeLinkIconOrientation"
              (click)="saveProfile(CUSTOMIZATION_TYPE.REPORT)"
            ></equitable-link>
          </ion-col>
        </ion-row>
      </div>
    </div>
    <app-footer
      isWhiteFooterVisible="true"
      isBlueFooterVisible="true"
      selectedProduct="ie"
    ></app-footer>
  </div>
</ion-content>
