import { FeatureFlagService } from "./../../core/services/feature-flag.service";
import { Injectable } from "@angular/core";
import { map, switchMap, catchError } from "rxjs/operators";
import { HttpRequestService } from "../../core/services/http-request.service";
import {
  RequestOption,
  Chart,
  Item,
  Bar,
  Axis,
  Line,
  SCSINCOME,
  Kits,
  Series,
  Profile,
  Preset,
} from "../../core/models/app.models";
import {
  API_METHODS,
  SEGMENT_TYPE,
  SCSINCOME_PROFILE_KEY,
  SCSINCOME_SERIES,
  CUSTOM_PROFILE,
  CUSTOMIZATION_TYPE,
  INVESTOR_MODE,
  MARKET_SCENERIO,
} from "../../../app/constants/constants";
import { of, Observable, from, forkJoin, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { IonicStorageService } from "../../core/services/ionic-storage.service";
import { CoreService } from "../../core/services/core.service";
import { ModalController } from "@ionic/angular";
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { UtilityService } from "../../core/services/utility.service";

@Injectable({
  providedIn: "root",
})
export class ScsIncomeService {
  //property for storing main scs data
  private scsincome: SCSINCOME;
  private reportData: Profile;
  private _series: string;
  customizationType = CUSTOMIZATION_TYPE.REPORT;
  currentProfile = ""; //for storing current profile in session
  investorData = {
    segment: "",
    index: "",
    protection: "",
    duration: "",
    mode: "",
    preset: new Preset(),
  };

  isIE;
  public dynamicData;

  public upMarketBars = {};
  public downMarketBars = {};
  public dynamicDollarAmount = 100000;

  /***
   *  Constructor for SCS Service
   ***/
  constructor(
    private httpRequestService: HttpRequestService,
    private translateService: TranslateService,
    private storageService: IonicStorageService,
    private coreService: CoreService,
    public modalController: ModalController,
    private flagService: FeatureFlagService,
    private http: HttpClient,
    private utilityService: UtilityService,
  ) {
    this.reportData = new Profile();
    if (
      this.flagService &&
      this.flagService.featureFlags &&
      this.flagService.featureFlags.scsincome
    ) {
      this.series = this.flagService.featureFlags.scsincome.series;
    }

    this.coreService.profile.subscribe((profile) => {
      this.currentProfile = profile.name;
      this.customizationType = profile.type
        ? profile.type
        : CUSTOMIZATION_TYPE.REPORT;
      if (this.currentProfile === "") {
        this.investorData.mode = INVESTOR_MODE.HOME;
        this.getProfile().subscribe((profiles) => {
          if (profiles && Array.isArray(profiles) && profiles.length > 0) {
            const profile = profiles.find(
              (x) =>
                x.name === CUSTOMIZATION_TYPE.REPORT &&
                x.currentSerie === this.series
            );
            if (profile) {
              this.report = profile;
            }
          }
        });
      } else {
        this.investorData.mode = INVESTOR_MODE.PROFILE;
      }
    });
  }
  

  public get series(): string {
    return this._series;
  }

  public set series(series: string) {
    this.storageService.setValueByKey(
      this.flagService.featureFlags.currentSeries,
      series
    );
    localStorage.setItem(this.flagService.featureFlags.currentSeries, series);
    this._series = series;
  }

  public get report(): Profile {
    return this.reportData;
  }

  public set report(reportData: Profile) {
    this.reportData = reportData;
  }

  setDollarAmount(dollarInputValue: number) {
    this.dynamicDollarAmount = dollarInputValue
  }

  getDollarAmount() {
    return this.dynamicDollarAmount;
  }

  getDynamicSCSData(){
    // https://jasonwatmore.com/post/2019/09/06/angular-http-get-request-examples

    // show loader until all products have rendered from API 
    this.utilityService.showLoader();

    this.http.get(environment.serviceAPIs.dynamicAPIUrl, {
      headers: new HttpHeaders({
        'Ocp-Apim-Subscription-Key': environment.serviceAPIs.dynamicAPISubscriptionKey
      })
    })
    .subscribe(response => {
      this.utilityService.hideLoader();
      // local json for testing: "assets/apis/scs/ratesforirapp.json"
      console.log('Constructor of SCS Service - Calling APIM')
      this.dynamicData = response;
      console.log(this.dynamicData);
    });
  }

  /**
   *  Check the scs data from local variable
   **/
  getSCSData(): Observable<SCSINCOME> {
    if (this.scsincome) {
      return of(this.scsincome);
    } else {
      return this.fetchSCSData(); // fetchSCSDataWithGenesAPI() or fetchSCSData()
    }
  }

  /***
   *  get master data to fill drop down on how it works
   ***/
  getAllIndices(): Observable<any> {
    const reqOptions: RequestOption = {
      method: API_METHODS.GET,
      url: environment.scsIndices,
    };
    return this.httpRequestService.request(reqOptions).pipe(
      map((data: any) => {
        return data.body.indicesData;
      })
    );
  }

  /***
   *  Fetch SCS main data from server
   ***/
  fetchSCSData(): Observable<SCSINCOME> {
    var baseURL = environment.serviceAPIs.baseUrl
    if (/https/.test(environment.serviceAPIs.scsincome)) {
      var baseURL = ""
    } 

    const requestOption: RequestOption = {
      url: baseURL + environment.serviceAPIs.scsincome, //  plugin baseURL to localhost to test. 
      method: API_METHODS.GET,
    };
    return this.httpRequestService.request(requestOption).pipe(
      map((response: any) => {
        this.scsincome = response.body;
        console.log(this.scsincome);
        
        return this.scsincome;
      })
    );
  }

  /***
   *  get resource for a series
   ***/
  getResources(seriesName: string): Observable<Kits[]> {
    return this.getSCSData().pipe(
      switchMap((scsincome: SCSINCOME) => {
        const series: Series = scsincome.series.find((x) => x.name == seriesName);
        return of(series.kits);
      })
    );
  }
  /***
   *  store custom profile
   ***/
  storeProfile(contentType, data, isReport = false) {
    this.getProfile().subscribe((storedProfiles: []) => {
      let profile = new Profile();
      let profiles: Profile[] = [];
      if (
        storedProfiles &&
        Array.isArray(storedProfiles) &&
        storedProfiles.length > 0
      ) {
        profiles = storedProfiles;
        profile =
          profiles.find(
            (x) =>
              x.name ===
                (isReport ? CUSTOMIZATION_TYPE.REPORT : this.currentProfile) &&
              x.currentSerie == this.series
          ) || profile;
      }
      if (!profile.name) {
        profile.name = isReport
          ? CUSTOMIZATION_TYPE.REPORT
          : this.currentProfile;
        profile.currentSerie = this.series;
        profiles.push(profile);
      }
      profile.currentSerie = this.series;

      if (contentType === CUSTOM_PROFILE.HOW_IT_WORKS) {
        profile.aboutSCSHowSioWorks = data;
      } else if (contentType === CUSTOM_PROFILE.HISTORICAL_PERFORMANCE) {
        profile.historicalPerformance = data;
      } else if (contentType === CUSTOM_PROFILE.HISTORY_GUIDE) {
        profile.historyMayBeAGuide = data;
      } else if (contentType === CUSTOM_PROFILE.HISTORICAL_PERFORMANCE_PLUS) {
        profile.historyMayBeAGuidePlus = data;
      }
      this.saveProfile(profiles);
    });
  }

  /***
   *  get resource for a series
   ***/
  getProfile(): Observable<Profile[]> {
    return from(
      this.storageService.getValueByKey(this.flagService.featureFlags.profile)
    );
  }

  /***
   *  get resource for a series
   ***/
  saveProfile(profiles: Profile[]): Observable<any> {
    return from(
      this.storageService.setValueByKey(
        this.flagService.featureFlags.profile,
        profiles
      )
    );
  }
  /***
   *  fetch InvestOptions Json from API
   ***/
  fetchInvestmentOptionsData(): Observable<any> {
    return this.getScsIncomeData().pipe(
      switchMap(() => {
        const requestOptions = {
          method: API_METHODS.GET,
          url:
          "assets/apis/retirement-cornerstone/YourInvestmentOptions.json",
            //environment.serviceAPIs.baseUrl + this.rc.YourInvestmentOptionsJSON,
        };
        return this.httpRequestService.request(requestOptions).pipe(
          map((data: any) => {
            return data.body;
          }),
          catchError((err) => {
            throw throwError(err);
          })
        );
      })
    );
  }
  /***
   *  get delete profile
   ***/
  deleteProfile(profileName: string) {
    return this.getProfile().pipe(
      switchMap((profiles) => {
        const index = profiles.findIndex(
          (x) => x.name === profileName && x.currentSerie === this.series
        );
        profiles.splice(index, 1);
        return this.saveProfile(profiles);
      })
    );
  }
  //=============================Start how it works Here =============================== //

  /***
   *  get master data to fill drop down on how it works
   ***/
  getHowItsWorksMasterData(): Observable<any> {
    const reqOptions: RequestOption = {
      method: API_METHODS.GET,
      url: environment.howItsWorksScsIncome,
    };
    return this.httpRequestService.request(reqOptions);
  }

  getHowItsWorksIEMasterData(): Observable<any> {
    const reqOptions: RequestOption = {
      method: API_METHODS.GET,
      url: environment.howItsWorksIE,
    };
    return this.httpRequestService.request(reqOptions);
  }

  getHowitWorksMenuData() {
    return forkJoin([this.getHowItsWorksMasterData(), this.getProfile()]);
  }

  getHowitWorksIEMenuData() {
    return forkJoin([this.getHowItsWorksIEMasterData(), this.getProfile()]);
  }

  getHowitWorksData(seriesName: string) {
    return forkJoin([
      this.getHowItsWorksMasterData(),
      this.fetchHowitWorks(seriesName),
      this.getProfile(),
    ]);
  }

  getHowitWorksIEData(seriesName: string) {
    return forkJoin([
      this.getHowItsWorksIEMasterData(),
      this.fetchHowitWorks(seriesName),
      this.getProfile(),
    ]);
  }

  // TODO: We should probably still update this function for calling the dynamic data. 
  /***
   *  fetch how it work from server/storage
   ***/
  fetchHowitWorks(seriesName: string): Observable<any> {
    
    return this.getSCSData().pipe(
      switchMap((scs: any) => {
        const series = scs.series.find((x) => x.name == seriesName);
        console.log(seriesName)
        console.log(series)
        console.log(series.performance.howItWorks)
        
        return this.httpRequestService
          .request({
            url: series.performance.howItWorks,
            method: API_METHODS.GET,
          })
          .pipe(
            map((data: any) => {
              return data.body;
            })
          );
      })
    );
  }

  /***
   *  parse response data for how it works tab
   ***/
  parsePerformanceData(performance, segment: string, commission?: number) {
    const segmentRates = [];
    const chartDetails = [];
    const objChartData: Chart = new Chart();
    objChartData.bar = new Array<Bar>();

    if (
      segment != SEGMENT_TYPE.ANNUAL_LOCK &&
      segment != SEGMENT_TYPE.STEP_UP &&
      segment != SEGMENT_TYPE.ENHANCED_UPSIDE && 
      segment != SEGMENT_TYPE.DUAL_DIRECTION &&
      segment != SEGMENT_TYPE.DUAL_STEP_UP &&
      segment != SEGMENT_TYPE.LOSS_LIMITER_90 &&
      segment != SEGMENT_TYPE.LOSS_LIMITER_95
    ) {
      //sort all scenerio positive first then negative
      performance.performaceRate = this.sortRates(performance.performaceRate);
    }

    performance.performaceRate.forEach((element, index) => {
      const objBar = new Bar();
      objBar.id = index;
      let choiceLabel = "";
      if (segment == SEGMENT_TYPE.STEP_UP) {
        choiceLabel =
          element > 0
            ? this.translateService.instant("howItsWorks.upMarket")
            : element == 0
            ? this.translateService.instant("howItsWorks.flatMarket")
            : this.translateService.instant("howItsWorks.downMarket");
      }

      else if (segment == SEGMENT_TYPE.ENHANCED_UPSIDE) {
        if (element > 0) {
          choiceLabel = this.translateService.instant("howItsWorks.upMarket")
        }
        else {
          choiceLabel = this.translateService.instant("howItsWorks.downMarket");
        }
      }

      if(segment == SEGMENT_TYPE.STEP_UP) {
        objBar.label = choiceLabel;
      }
      else if(segment == SEGMENT_TYPE.ENHANCED_UPSIDE) {
        objBar.label = choiceLabel;
      }
      else if(segment == SEGMENT_TYPE.ANNUAL_LOCK) {
        objBar.label =  this.translateService.instant("howItsWorks.year") + " " + (index + 1) // add year labels to annual lock
      }
      else if(segment == SEGMENT_TYPE.STANDARD) {
        if (element > 0) {
          objBar.label = this.translateService.instant("howItsWorks.upMarket")
        }
        else {
          objBar.label = this.translateService.instant("howItsWorks.downMarket");
        }
      }
      else if (segment == SEGMENT_TYPE.DUAL_STEP_UP) {
        // this code is taken from what we have from STEP_UP and converted to if/else statements
        if(element > 0) {
          choiceLabel = this.translateService.instant("howItsWorks.upMarket")
        }
        else if (element == 0) {
          choiceLabel = this.translateService.instant("howItsWorks.flatMarket")
        }
        else {
          choiceLabel = this.translateService.instant("howItsWorks.downMarket")
        }

        objBar.label = choiceLabel;
      }
      else if (segment == SEGMENT_TYPE.LOSS_LIMITER_90) {
        if (element > 0) {
          objBar.label = this.translateService.instant("howItsWorks.upMarket")
        }
        else {
          objBar.label = this.translateService.instant("howItsWorks.downMarket");
        }
      }
      else if (segment == SEGMENT_TYPE.LOSS_LIMITER_95) {
        if (element > 0) {
          objBar.label = this.translateService.instant("howItsWorks.upMarket")
        }
        else {
          objBar.label = this.translateService.instant("howItsWorks.downMarket");
        }
      }
      else {
        objBar.label = this.translateService.instant("howItsWorks.scenerio") + " " + (index + 1)
      }

      // Old way of determining the labels above the bar chart
      // objBar.label =
      //   segment == SEGMENT_TYPE.STEP_UP
      //     ? choiceLabel
      //     : (segment == SEGMENT_TYPE.ANNUAL_LOCK
      //         ? this.translateService.instant("howItsWorks.year")
      //         : this.translateService.instant("howItsWorks.scenerio")) +
      //       " " +
      //       (index + 1);

      //for peformace cap rate & Buffer lines for each section
      objBar.lines = new Array<Item>();
      objBar.lines.push({
        label: this.translateService.instant("howItsWorks.performaceCapRate"),
        value: parseFloat(performance.performaceCapRate),
      });

      objBar.lines.push({
        label: this.translateService.instant("howItsWorks.segmentBuffer"),
        value: parseFloat(performance.buffer),
      });

      //push segment(orange)
      //for Index Segment Yearly Rate of Return


      // // TODO: We should create two functions for getting an sSoR, one for dynamic and one from the static .json files
      let segmentRate;

      // console.log(performance.segmentRates)

      if(performance.segmentRates) {
        segmentRate = this.sRoR(
          element,
          performance.performaceCapRate,
          performance.buffer,
          segment,
          commission,
          performance.id,
          performance.additionalCharges,
          index,
          performance.segmentRates[index],
          performance
        );
      }
      else {
        segmentRate = this.sRoR(
          element,
          performance.performaceCapRate,
          performance.buffer,
          segment,
          commission,
          performance.id,
          performance.additionalCharges,
          index,
          0,
          performance
        );
      }

      objBar.data = new Array<Item>();

      //description
      segmentRates.push(segmentRate.return);
      objBar.description = segmentRate.detail;

      // Note that the Index Performance Rate and Segment Rate of Return are swapped on the chart. 
      objBar.data.push({
        value: element,
        label: this.isIE ? this.translateService.instant("howItsWorks.indexPerformanceRateIE") : this.translateService.instant("howItsWorks.indexPerformanceRate"),
        topValue:
          segmentRate.return > 0 && segment == SEGMENT_TYPE.CHOICE
            ? commission
            : 0,
      });

      //for Index Performance Rate
      objBar.data.push({
        value: segmentRate.return,
        label: this.isIE ? this.translateService.instant("howItsWorks.segmentYearlyReturnIE") : this.translateService.instant("howItsWorks.segmentYearlyReturn"),
      });
      objChartData.bar.push(objBar);

      //adding segment details
      chartDetails.push(segmentRate.detail);
    });

    //adding various axis to the chart
    // console.log('xxx3')
    objChartData.axis = new Array<Axis>();
    objChartData.axis.push({
      min: performance.min,
      max: performance.max,
      interval: performance.interval,
      type: "Y",
    });

    //for annual segment
    if (segment == SEGMENT_TYPE.ANNUAL_LOCK) {
      //do some thing for line chart
      objChartData.line = new Line();
      objChartData.line.legends = this.translateService.instant(
        "howItsWorks.annualLockEndingAmount"
      );
      objChartData.line.data = this.calculateAnnualSegment(segmentRates); // UPDATE TO PASS performance INSTEAD OF segmentRates
    }
    return { data: objChartData, details: chartDetails };
  }

  calculateAnnualSegment(segmentRates): Item[] { // performance or segmentRates
    let value = this.getDollarAmount();
    const annualArray: Array<Item> = new Array<Item>();
    segmentRates.forEach((element, index) => { //  performance.performaceRate OR segmentRates
      value = Math.round(value + value * (element / 100));
      annualArray.push({
        id: index,
        value: value,
        label:
          this.translateService.instant("howItsWorks.year") + " " + (index + 1),
      });
    });
    return annualArray;
  }

  /***
   *  Get a Segment Rate-of-Return
   **/
  sRoR(
    indexPerformance,
    cap,
    buffer,
    segment,
    commission,
    year,
    additionalCharges,
    index,
    segmentRate,
    performance
  ): any {
    buffer = buffer ? parseFloat(buffer) : 0;
    additionalCharges =
      additionalCharges && segment !== SEGMENT_TYPE.ANNUAL_LOCK
        ? additionalCharges
        : 0;
    let segmentDetails = "";
    let rateOfReturn = indexPerformance;

    // hardcoded fixes for plus guard
 
 
    // cap on positive indexPerformance
    if (indexPerformance > 0 && rateOfReturn <= cap) {
      segmentDetails = this.translateService
        .instant("howItsWorks.segmentReturn.belowPerformanceRate")
        .replace("$$INDEX_RETURN$$", indexPerformance)
        .replace("$$SEGMENT_RETURN$$", rateOfReturn);
    }

    if(this.series == SCSINCOME_SERIES.B) {
      if ( indexPerformance > 0 && (indexPerformance == 105 || indexPerformance == 150)) {
        segmentDetails = this.translateService
          .instant("howItsWorks.segmentReturn.uncappedPerformanceRate")
          .replace("$$INDEX_RETURN$$", indexPerformance)
          .replace("$$SEGMENT_RETURN$$", rateOfReturn);
      }
    }

    if(this.series == SCSINCOME_SERIES.ADV) {
      if ( indexPerformance > 0 && (indexPerformance == 105 || indexPerformance == 150)) {
        segmentDetails = this.translateService
          .instant("howItsWorks.segmentReturn.uncappedPerformanceRate")
          .replace("$$INDEX_RETURN$$", indexPerformance)
          .replace("$$SEGMENT_RETURN$$", rateOfReturn);
      }
    }

    // cap on positive indexPerformance
    if (indexPerformance > 0 && rateOfReturn > cap) {
      rateOfReturn = cap - additionalCharges;
      segmentDetails = this.translateService
        .instant("howItsWorks.segmentReturn.abovePerformanceRate")
        .replace("$$INDEX_RETURN$$", indexPerformance)
        .replace("$$SEGMENT_RETURN$$", rateOfReturn);
    }

    if (indexPerformance > 0 && segment == SEGMENT_TYPE.CHOICE)
      rateOfReturn = rateOfReturn - commission - additionalCharges;

    if (indexPerformance >= 0 && (segment == SEGMENT_TYPE.STEP_UP || segment == SEGMENT_TYPE.DUAL_STEP_UP || segment == SEGMENT_TYPE.LOSS_LIMITER_90 || segment == SEGMENT_TYPE.LOSS_LIMITER_95) ) {

      let isDynamic;
      if((this.series == SCSINCOME_SERIES.B || this.series == SCSINCOME_SERIES.ADV) && (!this.flagService.featureFlags.isPrimerica && !this.flagService.featureFlags.isPlusGuard)){
        isDynamic = true; 
      }
      else{
        isDynamic = false;
      }

      segmentDetails = this.translateService
        .instant("howItsWorks.segmentReturn.abovePerformanceRate")
        .replace("$$INDEX_RETURN$$", indexPerformance)
        .replace("$$SEGMENT_RETURN$$", isDynamic ? segmentRate : rateOfReturn);

      if (indexPerformance == 0) {
        segmentDetails = this.translateService
          .instant("howItsWorks.segmentReturn.flatPerformance")
          .replace("$$INDEX_RETURN$$", indexPerformance)
          .replace("$$SEGMENT_RETURN$$", isDynamic ? segmentRate : rateOfReturn);
      }
    }

    // buffer on negative indexPerformance
    if (indexPerformance < 0) {
      rateOfReturn = rateOfReturn - buffer < 0 ? rateOfReturn - buffer : 0;
      rateOfReturn = rateOfReturn - additionalCharges; //fix for additional

      segmentDetails = this.translateService
        .instant("howItsWorks.segmentReturn.lossMoreThanBuffer")
        .replace(
          "$$INDEX_RETURN$$",
          indexPerformance.toString().replace("-", "")
        )
        .replace("$$SEGMENT_RETURN$$", rateOfReturn)
        .replace("$$SEGMENT_BUFFER$$", buffer);

      if (indexPerformance < buffer) {
        segmentDetails = this.translateService
          .instant("howItsWorks.segmentReturn.lossLessThanBuffer")
          .replace(
            "$$INDEX_RETURN$$",
            indexPerformance.toString().replace("-", "")
          )
          .replace("$$SEGMENT_RETURN$$", rateOfReturn)
          .replace("$$SEGMENT_BUFFER$$", buffer);
      }
    }

    // uncapped
    if(cap == segmentRate && cap == indexPerformance) {
      console.log('UNCAPPED');

    }

    // IF enhanced upside, display updated content underneath the graphs
    // && this.series != "PLUS21"
    if(segment == SEGMENT_TYPE.ENHANCED_UPSIDE) {
      if(index == 0) {
        segmentDetails = this.translateService
        .instant("howItsWorks.segmentReturnEnhancedUpside.index0")
        .replace("$$INDEX_RETURN$$", indexPerformance)
        .replace("$$ENHANCED_UPSIDE_RATE$$", performance.enhancedUpsideRate)
        .replace("$$SEGMENT_RETURN$$", segmentRate);
      }
      else if(index == 1) {
        segmentDetails = this.translateService
        .instant("howItsWorks.segmentReturnEnhancedUpside.index1")
        .replace("$$INDEX_RETURN$$", indexPerformance)
        .replace("$$ENHANCED_UPSIDE_RATE$$", performance.enhancedUpsideRate)
        .replace("$$SEGMENT_RETURN$$", segmentRate);
      }
      else if(index == 2) {
        segmentDetails = this.translateService
        .instant("howItsWorks.segmentReturnEnhancedUpside.index2")
        .replace("$$INDEX_RETURN$$", indexPerformance)
        .replace("$$ENHANCED_UPSIDE_RATE$$", performance.enhancedUpsideRate)
        .replace("$$SEGMENT_RETURN$$", segmentRate);
      }
      else if(index == 3) {
        segmentDetails = this.translateService
        .instant("howItsWorks.segmentReturnEnhancedUpside.index3")
        .replace("$$INDEX_RETURN$$", indexPerformance)
        .replace("$$ENHANCED_UPSIDE_RATE$$", performance.enhancedUpsideRate)
        .replace("$$SEGMENT_RETURN$$", segmentRate)
        .replace("$$SEGMENT_BUFFER$$", buffer.toString().replace("-", ""))
      }
      else if(index == 4) {
        // index6Updated
        segmentDetails = this.translateService
        .instant("howItsWorks.segmentReturnEnhancedUpside.index4")
        .replace("$$INDEX_RETURN$$", indexPerformance)
        .replace("$$ENHANCED_UPSIDE_RATE$$", performance.enhancedUpsideRate)
        .replace("$$SEGMENT_RETURN$$", segmentRate)
        .replace("$$SEGMENT_BUFFER$$", buffer.toString().replace("-", ""))
      }
    }

    if(segment == SEGMENT_TYPE.DUAL_STEP_UP) {
      // not sure if we need to use segmentRate or rateOfReturn here. We can test to confirm. If we need to use rateOfReturn, we may need to move this code below where the rateOfReturn is calculated. 

      // at some point, we should put this code into the JSON like this. But we are strapped for time so we can do it like the above. 
        // segmentDetails = this.translateService
        // .instant("howItsWorks.segmentReturnEnhancedUpside.index0")
        // .replace("$$INDEX_RETURN$$", indexPerformance)
        // .replace("$$SEGMENT_RETURN$$", segmentRate);

      if(index == 0) {
        segmentDetails = `The index returns ${indexPerformance}%. The Segment Buffer absorbs the ﬁrst ${buffer}% of the loss, leaving you with only a ${segmentRate}% loss.`
      }
      else if(index == 1) {
        segmentDetails = `The index returns ${indexPerformance}%. Because the return is within the Segment Buffer, the Segment's rate of return is ${segmentRate}%, which is the Performance Cap Rate.`
      }
      else if(index == 2) {
        segmentDetails = `The index returns ${indexPerformance}%. Because the return is equal to ${indexPerformance}%, the Segment's rate of return is ${segmentRate}%, which is the Performance Cap Rate.`
      }
      else if(index == 3) {
        segmentDetails = `The index returns ${indexPerformance}%. Because the return is positive, the Segment's rate of return is ${segmentRate}%, which is the Performance Cap Rate.`
      }
      else if(index == 4) {
        segmentDetails = `The index returns ${indexPerformance}%. Because the gain is in excess of the Performance Cap Rate, the Segment's rate of return is ${segmentRate}%, which is the Performance Cap Rate.`
      }
    }
    else if(segment == SEGMENT_TYPE.LOSS_LIMITER_90 || segment == SEGMENT_TYPE.LOSS_LIMITER_95) {
      let bufferCacluation;

      if(segment == SEGMENT_TYPE.LOSS_LIMITER_90) {
        bufferCacluation = (buffer - 10).toString();
      }
      if(segment == SEGMENT_TYPE.LOSS_LIMITER_95) {
        bufferCacluation = (buffer - 5).toString();
      }

      if(index == 0) {
        segmentDetails = `The index returns ${indexPerformance}%. The Segment Buffer absorbs the first ${buffer}% of loss, and the Segment Investment Protection feature absorbs loss after ${bufferCacluation}%, leaving you with a ${segmentRate}% return.`
      }
      else if(index == 1) {
        segmentDetails = `The index returns ${indexPerformance}%. The Segment Buffer absorbs the first ${buffer}% of loss and you realize a ${segmentRate}% return.`
      }
      else if(index == 2) {
        segmentDetails = `The index returns ${indexPerformance}%. Because the return is within the Segment Buffer, you realize a ${segmentRate}% return.`
      }
      else if(index == 3) {
        segmentDetails = `The index returns ${indexPerformance}%. Because the return is below the Performance Cap Rate, you realize the full ${segmentRate}% return.`
      }
      else if(index == 4) {
        segmentDetails = `The index returns ${indexPerformance}%. Because the gain is in excess of the Performance Cap Rate, the Segment's rate of return is ${segmentRate}%, which is the Performance Cap Rate.`
      }
    }
          
    // IF enhanced upside, display updated content underneath the graphs
    if(this.isIE) {
      if(segment == SEGMENT_TYPE.STANDARD) {
        if(index == 0) {
          segmentDetails = "The index return is positive, but less than the Performance Cap​ Rate. Because the return is below the Performance Cap Rate, the​ segment return before contract fee is equal to the index return.​"
        }
        else if(index == 1) {
          segmentDetails = "The index return is positive.​ Because the gain is in excess of the Performance Cap Rate, the segment​ return before contract fee is equal to the Performance Cap Rate.​"
        }
        else if(index == 2) {
          segmentDetails = "The index return is negative.​ Since the return is within the Segment Buffer,​ the segment return before contract fee is zero.​";
        }
        else if(index == 3) {
          segmentDetails = "The index return is negative.​ Because the negative return exceeds the Segment​ Buffer, segment return before contract fee is negative.​";
        }
      }
      else if(segment == SEGMENT_TYPE.STEP_UP) {
        if(index == 0) {
          segmentDetails = "​The index return is negative.​ Because the return exceeds the Segment​ Buffer, the segment return before contract​ fee is negative.​";
        }
        else if(index == 1) {
          segmentDetails = "​The index return is negative.​ Since the return is within the Segment Buffer,​ the segment return before contract fee is zero.​";
        }
        else if(index == 2) {
          segmentDetails = "The index returns 0%.​ Because the return is equal to 0%, the​ segment return before contract fee is​ equal to the performance cap rate.​";
        }
        else if(index == 3) {
          segmentDetails = "The index return is positive, but less than the Performance​ Cap Rate. Because the return is positive, the segment return​ before contract fee is equal to the Performance Cap Rate.​";
        }
        else if(index == 4) {
          segmentDetails = "The index return is positive.​ Because the return is greater than the Performance Cap Rate, the​ segment return before contract fee is equal to the Performance Cap Rate.​";
        }
      }

      // hardcoded fixes for IE
      if(indexPerformance == 4) {
        rateOfReturn = 8;
      }
      if(indexPerformance == 0) {
        rateOfReturn = 8;
      }
    }

    if((this.series == SCSINCOME_SERIES.B || this.series == SCSINCOME_SERIES.ADV) && (!this.flagService.featureFlags.isPrimerica && !this.flagService.featureFlags.isPlusGuard) && !this.isIE) {
      if(segment == SEGMENT_TYPE.STEP_UP || 
         segment == SEGMENT_TYPE.ENHANCED_UPSIDE || 
         segment == SEGMENT_TYPE.ANNUAL_LOCK || 
         segment == SEGMENT_TYPE.STANDARD || 
         segment == SEGMENT_TYPE.DUAL_STEP_UP || 
         segment == SEGMENT_TYPE.LOSS_LIMITER_90 || 
         segment == SEGMENT_TYPE.LOSS_LIMITER_95) {
        rateOfReturn = segmentRate;
      }
    }

    //show segment maturity value, if not IE
    if(!this.isIE) {
      segmentDetails = this.populateSegmentMaturityDescriptionUnderGraph(segmentDetails, rateOfReturn);
    }

    // round the rate of return to 2 decimal places
    if(this.series == SCSINCOME_SERIES.B || SCSINCOME_SERIES.ADV) {
      if(this.isIE) {
        rateOfReturn = Math.round(rateOfReturn * 100) / 100; // TODO: How can we append a symbol of * here and still get it to interpret as a number?
      }
      else if(this.flagService.featureFlags.isPrimerica || this.flagService.featureFlags.isPlusGuard) {
        rateOfReturn = Math.round(rateOfReturn * 10) / 10; // round to tenths for plus guard and primerica
      }
      else {
        rateOfReturn = parseFloat(rateOfReturn).toFixed(2);
      }
    }
    else {
      rateOfReturn = Math.round(rateOfReturn * 100) / 100;
    }
    return { return: rateOfReturn, detail: segmentDetails };
  }

  populateSegmentMaturityDescriptionUnderGraph(segmentDetails, rateOfReturn){
    let descriptionResult;
    
    // if we are on the national version, display the description with the dollar amount on a new line
    if(this.flagService.featureFlags.isNational) {
      descriptionResult = segmentDetails + this.translateService.instant("howItsWorks.segmentReturn.segmentMaturityValue") + '<p class="seg-value">$' +
      Math.round(this.getDollarAmount() + this.getDollarAmount() * (rateOfReturn / 100)).toLocaleString() + '</p>';
    }
    else if (this.flagService.featureFlags.isPrimerica || this.flagService.featureFlags.isPlusGuard) {
      descriptionResult = `${segmentDetails} Segment maturity value: \$${Math.round(this.getDollarAmount() + this.getDollarAmount() * (rateOfReturn / 100)).toLocaleString()}`
    }

    return descriptionResult; 
  }

  //sorting positive to first and negative to last
  sortRates(rates): Array<number> {
    const positive = [],
      negative = [];
    rates.forEach((element) => {
      if (element > 0) {
        positive.push(element);
      } else {
        negative.push(element);
      }
    });
    return positive.concat(negative);
  }

  //======================================= End Here ===================================== //

  //============================== Start historical performance =============================== //

  getHistoricalPerformance(seriesName: string) {
    console.log("HSERVICE");
    console.log("HSERVICE", seriesName);
    console.log("HSERVICE ghpd(seriesName)"), this.getHistoicalPerformanceData(seriesName);
    console.log("HSERVICE ghppv",  this.getHistoicalPerformacePresetValues(seriesName));
    var data =  forkJoin([
      this.getHistoicalPerformanceData(seriesName),
      this.getHistoicalPerformacePresetValues(seriesName),
      this.getAllIndices(),
      this.getProfile(),
    ]);
    console.log("data in ghp service", data)
    return data
  }

  /***
   *  get historical performace for a series
   ***/
  getHistoicalPerformanceData(seriesName: string) {
    return this.getSCSData().pipe(
      switchMap((scs: SCSINCOME) => {
        const series = scs.series.find((x) => x.name == seriesName);
        return this.httpRequestService
          .request({
            url: series.performance.durations,
            method: API_METHODS.GET,
          })
          .pipe(
            map((data: any) => {
              return data.body.historicalPerformance;
            })
          );
      })
    );
  }

  /***
   *  get historical performace for a series
   ***/
  getHistoicalPerformacePresetValues(seriesName: string) {
    return this.getSCSData().pipe(
      switchMap((scs: SCSINCOME) => {
        const series = scs.series.find((x) => x.name == seriesName);
        console.log("getSCSData ------------------------------------------------")
        console.log("getSCSData", series.performance.historicalPerformance)
        return this.httpRequestService
          .request({
            url: series.performance.historicalPerformance,
            method: API_METHODS.GET,
          })
          .pipe(
            map((data: any) => {
              const body = data.body;
              return body;
            })
          );
      })
    );
  }

  /***
   *  get historical performace for ADV
   ***/
  getThreePieData(isIE): Observable<any> {
    return this.getSCSData().pipe(
      switchMap((scs: SCSINCOME) => {
        const series = scs.series.find((x) => x.name == this.series);
        let url;

        if(isIE) {
          url = './assets/apis/scs/seriesPLUS/scs_ipad_threePieCharts_SeriesPLUS_IE.xml'
        }
        else {
          url = series.performance.threePieChartsData;
          
        }
        
        const requestOption: RequestOption = {
          url: url,
          method: API_METHODS.GET,
        };
        return this.httpRequestService.request(requestOption).pipe(
          map((response: any) => {
            return response.body;
          })
        );
      })
    );
  }

  /***
   *  get Historical Return Values for guide
   ***/
  getHistoricalReturnValues(seriesName: string): Observable<any> {
    return this.getSCSData().pipe(
      switchMap((scs: SCSINCOME) => {
        const series = scs.series.find((x) => x.name == seriesName);
        const requestOption: RequestOption = {
          url: series.performance.historicalReturns,
          method: API_METHODS.GET,
        };
        return this.httpRequestService.request(requestOption).pipe(
          map((response: any) => {
            return response;
          }),
          catchError((err) => {
            throw throwError(err);
          })
        );
      })
    );
  }

  getHardcodedStandardYear3and4Values(data, segment, index, duration, buffer, item){

    let object = {
     indexRoR3Year: 0,
     segmentRoR3Year: 0,
     indexRoR4Year: 0,
     segmentRoR4Year: 0,
     minValue: 0,
    }

    if(duration == '1yr') {
      if(buffer == '-10%') {
        if(index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
          object.indexRoR3Year = -8;
          object.segmentRoR3Year = 0;
          object.indexRoR4Year = -12;
          object.segmentRoR4Year = -2;
          object.minValue = -20;
        }
      }
      else if(buffer == '-15%') {
        if(index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
          object.indexRoR3Year = -12;
          object.segmentRoR3Year = 0;
          object.indexRoR4Year = -17;
          object.segmentRoR4Year = -2;
          object.minValue = -20;
        }
      }
      else if(buffer == '-20%') {
        if(index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
          object.indexRoR3Year = -15;
          object.segmentRoR3Year = 0;
          object.indexRoR4Year = -22;
          object.segmentRoR4Year = -2;
          object.minValue = -30;
        }
      }
        else if(buffer == '-40%') {
          if(index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
            object.indexRoR3Year = -35;
            object.segmentRoR3Year = 0;
            object.indexRoR4Year = -42;
            object.segmentRoR4Year = -2;
            object.minValue = -50;
          }
    }
  }

    else if(duration == '3yr') {
      if(buffer == '-10%') {
        if(index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
          object.indexRoR3Year = -8;
          object.segmentRoR3Year = 0;
          object.indexRoR4Year = -12;
          object.segmentRoR4Year = -2;
          if(index == "MSCI EAFE"){
            object.minValue = -100;
          }else if(index == "Russell 2000®"){
            object.minValue = -25;
          }else{
            object.minValue = -20;
          }
        }
      }
      else if(buffer == '-15%') {
        if(index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
          object.indexRoR3Year = -12;
          object.segmentRoR3Year = 0;
          object.indexRoR4Year = -17;
          object.segmentRoR4Year = -2;
          if(index == "MSCI EAFE"){
            object.minValue = -50;
          }else{
            object.minValue = -30;
          }
        }
      }
      else if(buffer == '-20%') {
        if(index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
          object.indexRoR3Year = -15;
          object.segmentRoR3Year = 0;
          object.indexRoR4Year = -22;
          object.segmentRoR4Year = -2;
          if(index == 'MSCI EAFE'){
            object.minValue = -50;
          }else{
            object.minValue = -30;
          }
        }
      }
      else if(buffer == '-40%') {
        if(index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
          object.indexRoR3Year = -35;
          object.segmentRoR3Year = 0;
          object.indexRoR4Year = -42;
          object.segmentRoR4Year = -2;
          object.minValue = -50;
        }
      }
  }
  else {
    object.minValue = -20;
  }
  return object;
}

  getHardCodedEnhancedUpsideValues(enhancedUpside, buffer) {
    let hardcodedEnhancedUpsides = {
      indexRoRDownMarket1: 0,
      indexRoRDownMarket2: 0,
      segmentRoRDownMarket1: 0,
      segmentRoRDownMarket2: 0,
    }
    
    if(buffer == '-10%') {
      hardcodedEnhancedUpsides.indexRoRDownMarket1 = -5;
      hardcodedEnhancedUpsides.segmentRoRDownMarket1 = 0;
      hardcodedEnhancedUpsides.indexRoRDownMarket2 = -15;
      hardcodedEnhancedUpsides.segmentRoRDownMarket2 = -5;
    }
    else if(buffer == '-15%') {
      hardcodedEnhancedUpsides.indexRoRDownMarket1 = -12;
      hardcodedEnhancedUpsides.segmentRoRDownMarket1 = 0;
      hardcodedEnhancedUpsides.indexRoRDownMarket2 = -17;
      hardcodedEnhancedUpsides.segmentRoRDownMarket2 = -2;
    }

    return hardcodedEnhancedUpsides;
  }

  getHardCodedDualDirectionValues(data, segment, index, duration, buffer){
    let hardcodedDualDirectionValues = {
      indexRoRDownMarket1: 0,
      indexRoRDownMarket2: 0,
      segmentRoRDownMarket1: 0,
      segmentRoRDownMarket2: 0,
    };

    if(buffer == '-10%') {
      hardcodedDualDirectionValues.indexRoRDownMarket1 = -8;
      hardcodedDualDirectionValues.indexRoRDownMarket2 = -12;
      hardcodedDualDirectionValues.segmentRoRDownMarket1 = -8;
      hardcodedDualDirectionValues.segmentRoRDownMarket2 = -12;
    }
    else if(buffer == '-15%') {
      hardcodedDualDirectionValues.indexRoRDownMarket1 = -12;
      hardcodedDualDirectionValues.indexRoRDownMarket2 = -18;
      hardcodedDualDirectionValues.segmentRoRDownMarket1 = -12;
      hardcodedDualDirectionValues.segmentRoRDownMarket2 = -18;
    }
    else if(buffer == '-20%') {
      hardcodedDualDirectionValues.indexRoRDownMarket1 = -17;
      hardcodedDualDirectionValues.indexRoRDownMarket2 = -22;
      hardcodedDualDirectionValues.segmentRoRDownMarket1 = -17;
      hardcodedDualDirectionValues.segmentRoRDownMarket2 = -22;
    }

    return hardcodedDualDirectionValues;
  }

  getHardCodedDualStepUpValues(data, segment, index, duration, buffer, item){
    let object = {
      indexRoRDownMarket1: 0,
      indexRoRDownMarket2: 0,
      segmentRoRDownMarket1: 0,
      segmentRoRDownMarket2: 0,
    };

    if(duration == '1yr') {
      if(buffer == '-10%') {
        if(index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
          object.indexRoRDownMarket1 = -12;
          object.segmentRoRDownMarket1 = -2;
          object.indexRoRDownMarket2 = -4;//item.perfcaprate * 1.1;
          object.segmentRoRDownMarket2 = item.perfcaprate;
        }
      }
      else if(buffer == '-15%') {
        if(index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
          object.indexRoRDownMarket1 = -18;
          object.segmentRoRDownMarket1 = -3;
          object.indexRoRDownMarket2 = -4;//item.perfcaprate * 1.1;
          object.segmentRoRDownMarket2 = item.perfcaprate;
        }
      }
    }


    return object;
  }

  getHardCodedAnnualLockData(data, segment, index, duration, buffer, item){
    let object = {
     indexRoR1YearUp: 0, // item.cases[0].indexROR
     segmentRoR1YearUp: 0, // item.cases[0].segmentROR
     indexRoR2YearUp: 0,  // no way to get from API
     segmentRoR2YearUp: 0, // no way to get from API
     indexRoR3YearUp: 0, // item.cases[1].indexROR
     segmentRoR3YearUp: 0, // item.cases[1].segmentROR
     indexRoR1YearDown: 0, // item.cases[0].indexROR
     segmentRoR1YearDown: 0, // item.cases[0].segmentROR
     indexRoR2YearDown: 0,  // no way to get from API
     segmentRoR2YearDown: 0, // no way to get from API
     indexRoR3YearDown: 0, // item.cases[1].indexROR
     segmentRoR3YearDown: 0, // item.cases[1].segmentROR
     minValueUp: 0,
     minValueDown: 0,
    }

    if(duration == '3yr') {
      if(buffer == '-10%') {
        if(index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
          object.indexRoR1YearUp = item.perfcaprate * 1.3;
          object.segmentRoR1YearUp = item.perfcaprate;
          object.indexRoR2YearUp = -5;
          object.segmentRoR2YearUp = 0;
          object.indexRoR3YearUp = item.perfcaprate;
          object.segmentRoR3YearUp = item.perfcaprate;

          object.indexRoR1YearDown = item.perfcaprate * 0.5;
          object.segmentRoR1YearDown = item.perfcaprate * 0.5;
          object.indexRoR2YearDown = -5;
          object.segmentRoR2YearDown = 0;
          object.indexRoR3YearDown = -16;
          object.segmentRoR3YearDown = -6;

          object.minValueUp = -20;
          object.minValueDown = -20;
        }
      }
    }
    return object;
  }

  getHardcodedStepUpValues(data, segment, index, duration, buffer, item){

    let object = {
     indexDM1: 0,
     segmentDM1: 0,
     indexDM2: 0,
     segmentDM2: 0,
     minValue: 0,
    }

    if(duration == '1yr') {
      if(buffer == '-10%') {
        if(index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
          object.indexDM1 = -12;
          object.segmentDM1 = -2;
          object.indexDM2 = -8;
          object.segmentDM2 = 0;
          object.minValue = -16;
        }
      }
      if(buffer == '-15%') {
        if(index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
          object.indexDM1 = -17;
          object.segmentDM1 = -2;
          object.indexDM2 = -8;
          object.segmentDM2 = 0;
          object.minValue = -20;
        }
      }
    }
    if(duration == '3yr') {
      if(buffer == '-10%') {
        if(index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
          object.indexDM1 = -12;
          object.segmentDM1 = -2;
          object.indexDM2 = -8;
          object.segmentDM2 = 0;
          object.minValue = -20;
        }
      }
    }
    return object;
  }

  round5(num) {
    // https://stackoverflow.com/questions/18953384/javascript-round-up-to-the-next-multiple-of-5


    // min values are
    // -12, -16, -22, -33

    // -22 is a problem with SP / 6yr / -20

    // if between 1 and 30, use 4 as factor with floor
    if(num == -12) {
      return (Math.ceil(num / 4) * 4); // use floor or ceil
    }
    // else if (num == -16) {
    //   return (Math.ceil(num / 5) * 5); // use floor or ceil
    // }
    // else if (num == -22) {
    //   return num - 5;
    // }
    // else if (num == -33) {
    //   return (Math.ceil(num / 5) * 5); // use floor or ceil
    // }
    else {
      return (Math.floor(num / 5) * 5); // use floor or ceil
    }
  }

  getIndexName(item) {
    let indexName;

    // ID, long name, short name 
    // DJR    DJ US Real Estate ETF    DJ Real Estate
    // MEM    MSCI Emerging Markets    MSCI EM
    // GSP    Gold SPDR Index    Gold SPDR
    // XLE    Energy Sector SPDR    Energy SPDR
    // MET    MSCI EAFE ETF Index    MSCI EAFE ETF
    // GLD    London Gold Market Fixing Price    Gold Index
    // SP     S&P 500 Index    S&P 500
    // RUS    Russell 2000 Index    Russell 2000
    // MSC    MSCI EAFE Index    MSCI EAFE
    // OIL    NYMEX West Texas Intermd Crude Oil    Oil Index
    // NSD    NASDAQ 100    NASDAQ 100
    // SX5    EURO STOXX 50    EURO STOXX 50
    // FIN    Financial SPDR Index    Financial SPDR

    if(item.indexid == "SP ") {
      indexName = 'S&P 500®'
    }
    else if(item.indexid == "RUS") {
      indexName = 'Russell 2000®'
    }
    else if(item.indexid == "MET") {
      indexName = 'MSCI EAFE ETF'
    }
    else if(item.indexid == "MEM") {
      indexName = 'MSCI EM'
    }
    else if(item.indexid == "NSD") {
      indexName = 'NASDAQ 100®'
    }
    else if(item.indexid == "MSC") {
      indexName = 'MSCI EAFE'
    }
    else if(item.indexid == "SX5") {
      indexName = 'EURO STOXX 50'
    }

    return indexName;
  }

  //  CHECK options in JSON object FOR PLUS AND PLUS 21
  getOptionName(item) {
    let optionName;

    // S16 AL --> option=ALC and segopttype=AL
    // S21 AL --> option=S21 and segopttype=AL

    // For SCSIncome, The series does not match. The option name is S21. So, we default to using B. 
    // PLUS 21
    if(item.option == "S21") {
      optionName = "B"
    }
    // the annual lock for PLUS uses ALC on the API (for some reason).
    else if(item.option == "S16" || item.option == "ALC") {
      optionName = "PLUS"
    }

    return optionName;
  }

  getSegmentName(item){
    let segmentName;

    if(item.segopttype == "ST") {
      segmentName = "Standard"
    }
    else if(item.segopttype == "AL") {
      segmentName = "Annual Lock"
    }
    else if(item.segopttype == "SU") {
      segmentName = "Step Up"
    }
    else if(item.segopttype == "DD") {
      segmentName = "Dual Direction"
    }
    else if(item.segopttype == "EU") {
      segmentName = "Enhanced Upside"
    }
    else if(item.segopttype == "DS") {
      segmentName = "Dual Step Up"
    }
    else if(item.segopttype == "LL-90") {
      segmentName = "Loss Limiter 90"
    }
    else if(item.segopttype == "LL-95") {
      segmentName = "Loss Limiter 95"
    }
    return segmentName;
  }

  getEnhancedUpsideRate(item) {
    // enhupsiderate
    if(item.enhupsiderate == "125") {
      return '125%'
    }
    else if (item.enhupsiderate == "110") {
      return '110%' 
    }
    else if (item.enhupsiderate == "100") {
      return '100%' 
    }
  }

  getSegmentProductCode(item){
    // s21
    if(item.prodcode == 'S21ASH') {
      return 'S21ASH';
    }
    // s21
    else if(item.prodcode == 'S21BSH') {
      return 'S21BSH';
    }
    // s21
    else if(item.prodcode == 'S21CSH') {
      return 'S21CSH';
    }
    // s16
    else if(item.prodcode == 'SADSHR') {
      return 'SADSHR';
    }
    // scsincome
    // SINASH
    else if(item.prodcode == 'SINASH') {
      return 'SINASH';
    }
    // scsincome
    // SINBSH 
    else if(item.prodcode == 'SINBSH') {
      return 'SINBSH';
    }
  // IE21BS
  else if(item.prodcode == 'IE21BS') {
    return 'IE21BS';
  }
      // IE21AS
 else if(item.prodcode == 'IE21AS') {
        return 'IE21AS';
      }
       // IE21AS
  else if(item.prodcode == 'IECSHR') {
        return 'IECSHR';
      }
  }

  hydrateObject(item, index, additionalCharges, hardcodedMin, performaceRate, segmentRates, segment) {
    let objPerformance = {
      id: item.duration + 'yr',
      max: this.calculateMaxValue(Math.max(item.cases[0].indexROR.toFixed(0), item.cases[1].indexROR.toFixed(0)), segment, item),
      min: hardcodedMin, // Call calculateMinValue function
      interval: this.calculateInterval(parseInt(item.perfcaprate).toFixed(0), segment, item),
      performaceCapRate: this.getPerformanceCapRate(segment, index, item.duration + 'yr', item.buffer + '%', item),
      buffer: item.buffer + '%',
      performaceRate: this.roundArrayToHundreths(performaceRate),
      segmentRates: this.roundArrayToHundreths(segmentRates),
      additionalCharges: additionalCharges,
      index: index,
      option: item.option,
      enhancedUpsideRate: item.enhupsiderate,
      prodcode: item.prodcode,
      segopttype: item.segopttype,
    };

    return objPerformance;
  }

  getPerformanceCapRate(segment, index, duration, buffer, item) {
  
    let performanceCapRate;

    // hardcoding annual lock for segment data 
    if(segment == 'Annual Lock'){
      if(duration == '3yr') {
        if(buffer == '-10%') {
          if(index == "S&P 500®" || index == "Russell 2000®" || index == "MSCI EAFE ETF" || index == "MSCI EM" || index == "NASDAQ 100®" || index == "MSCI EAFE" || index == "EURO STOXX 50") {
            performanceCapRate = 10;
          }
        }
      }
    }
    else {
      performanceCapRate = this.roundNumberToTenths(item.perfcaprate);
    }

    return performanceCapRate;
  }
  
  getDynamicStandardData(data, segment, index, duration, buffer) {
    // TODD: need to take this out of the .plist files and howitWorks.json
    // item.cases[0].caseId == "ST-UP1" && item.cases[1].caseId == "ST-UP2"

    let performanceArray = [];

    // compare the buffer and duration from the front-end to the JSON from the API
    // plug this into buffer and duration at some point
    let newBuffer = buffer.toString().substring(0,3);
    let newDuration = duration.toString().substring(0,1);
    let hardCodedProductCode = 'SINBSH'; // hardcode to series A until we deteremine from business what they want rendered. 

    //let hardCodedScenario3And4Object = this.getHardcodedStandardYear3and4Values(data, segment, index, duration, buffer); // TODO: try to pass with the values defined here, otherwise move into the data.forEach and pass the item values

    //console.log(hardCodedScenario3And4Object);

    data.forEach(function(item) {

      let hardCodedScenario3And4Object = this.getHardcodedStandardYear3and4Values(data, segment, index, duration, buffer, item); // TODO: try to pass with the values defined here, otherwise move into the data.forEach and pass the item values

      // the app and API do not have the same index names, so we need to check each one
      let indexName = this.getIndexName(item);
      let optionName = this.getOptionName(item); // The API returns 'S21' for all income products. B does not match S21. So, we match on the product code of SINBSH
      let segmentName = this.getSegmentName(item);
      let productCode = this.getSegmentProductCode(item);
      let objPerformance;

      // // Hardcoded uncapped  exception for MSCI EAFE, 3 year, -10
      // if(item.buffer == '-10' && item.duration == '3' && item.indexid == "MSC" && segment == segmentName && hardCodedProductCode == productCode){
      //   objPerformance = this.hydrateObject(
      //     item, 
      //     'MSCI EAFE', 
      //     0, 
      //     -20,
      //     [105, 150, -8, -12],
      //     [105, 150, 0, -2]
      //   );
      //   performanceArray.push(objPerformance);
      // }

      // if(item.buffer == '-15' && item.duration == '6' && item.indexid == "MSC" && segment == segmentName && hardCodedProductCode == productCode){
      //   objPerformance = this.hydrateObject(
      //     item, 
      //     'MSCI EAFE', 
      //     0, 
      //     -20,
      //     [105, 150, -8, -16],
      //     [105, 150, 0, -6]
      //   );
      //   performanceArray.push(objPerformance);
      // }

      // if(item.buffer == '-20' && item.duration == '6' && item.indexid == "MSC" && segment == segmentName && hardCodedProductCode == productCode){
      //   objPerformance = this.hydrateObject(
      //     item, 
      //     'MSCI EAFE', 
      //     0, 
      //     -20,
      //     [105, 150, -8, -16],
      //     [105, 150, 0, -6]
      //   );
      //   performanceArray.push(objPerformance);
      // }

      // if(item.cases[0].indexROR == 699.99) {
      //   item.cases[0].indexROR = 
      // }

      // if(item.cases[1].indexROR == 1299.99){

      // }
      
      // hardcoded exception for uncapped value
      // if(item.buffer == '-10' && item.duration == '6' && item.indexid == "SP " && segment == segmentName && hardCodedProductCode == productCode){
      //   objPerformance = this.hydrateObject(
      //     item, 
      //     'S&P 500®', 
      //     0, 
      //     -20,
      //     [105, 150, -8, -16],
      //     [105, 150, 0, -6]
      //   );
      //   performanceArray.push(objPerformance);
      // }

      // // hardcoded exception for uncapped value
      // if(item.buffer == '-10' && item.duration == '6' && item.indexid == "MET" && segment == segmentName && hardCodedProductCode == productCode){
      //   objPerformance = this.hydrateObject(
      //     item, 
      //     'MSCI EAFE ETF', 
      //     0, 
      //     -20,
      //     [105, 150, -8, -16],
      //     [105, 150, 0, -6]
      //   );
      //   performanceArray.push(objPerformance);
      // }

      // TODO: Nothing ever gets pushed to the array because the this.series and optionName are never the same. 
      // console.log(item.buffer)
      // console.log(newBuffer)
      // console.log(item.duration)
      // console.log(newDuration)
      // console.log(segment)
      // console.log(segmentName)
      // console.log(this.series)
      // console.log(optionName)
      // console.log(hardCodedProductCode)
      // console.log(productCode

      //index performance rates
      let column1IndexPerformanceRateUpMarket = item.perfcaprate * 0.7; //item.cases[0].indexROR;
      let column2IndexPerformanceRateUpMarket = item.perfcaprate * 1.1; //item.cases[1].indexROR;
      let column3IndexPerformanceRateDownMarket = hardCodedScenario3And4Object.indexRoR3Year; //dynamic value is item.case[2].indexROR
      let column4IndexPerformanceRateDownMarket = hardCodedScenario3And4Object.indexRoR4Year; //dynamic value is item.case[3].indexROR

      //segment rates of return
      let column1SegmentRatesOfReturnUpMarket = item.perfcaprate * 0.7; //item.cases[0].segmentROR;
      let column2SegmentRatesOfReturnUpMarket = item.perfcaprate; //item.cases[1].segmentROR;
      let column3SegmentRatesOfReturnDownMarket = hardCodedScenario3And4Object.segmentRoR3Year; //dynamic value is item.case[2].indexROR
      let column4SegmentRatesOfReturnDownMarket = hardCodedScenario3And4Object.segmentRoR4Year; //dynamic value is item.case[3].indexROR
      
      if(item.buffer == newBuffer && item.duration == newDuration && segment == segmentName && hardCodedProductCode == productCode){
        objPerformance = this.hydrateObject(
          item, 
          indexName,
          0, 
          hardCodedScenario3And4Object.minValue, // hardcoded min value
          [column1IndexPerformanceRateUpMarket, column2IndexPerformanceRateUpMarket, column3IndexPerformanceRateDownMarket, column4IndexPerformanceRateDownMarket], //index performance rates
          [column1SegmentRatesOfReturnUpMarket, column2SegmentRatesOfReturnUpMarket, column3SegmentRatesOfReturnDownMarket, column4SegmentRatesOfReturnDownMarket], //segment rates of return
        );
        performanceArray.push(objPerformance);
      }

    }, this); // https://stackoverflow.com/questions/45175605/how-to-call-this-inside-for-example-foreach-in-class

    return performanceArray;
  }

  // remove array values after [5] due to only being 3 year. 
  hydrateUpDownMarketObject(item, hardCodedAnnualLockObject) {
    const upDownMarketObject = {
      upward: {
        0: this.roundNumToHundreths(hardCodedAnnualLockObject.segmentRoR1YearUp), // 1 year
        1: this.roundNumToHundreths(hardCodedAnnualLockObject.indexRoR1YearUp), // 1 year
        2: this.roundNumToHundreths(hardCodedAnnualLockObject.segmentRoR2YearUp), // 2 year 
        3: this.roundNumToHundreths(hardCodedAnnualLockObject.indexRoR2YearUp), // 2 year
        4: this.roundNumToHundreths(hardCodedAnnualLockObject.segmentRoR3YearUp), // 3 year
        5: this.roundNumToHundreths(hardCodedAnnualLockObject.indexRoR3YearUp), // 3 year
      },
      downward: {
        0: this.roundNumToHundreths(hardCodedAnnualLockObject.segmentRoR1YearDown), // 1 year
        1: this.roundNumToHundreths(hardCodedAnnualLockObject.indexRoR1YearDown), // 1 year
        2: this.roundNumToHundreths(hardCodedAnnualLockObject.segmentRoR2YearDown), // 2 year 
        3: this.roundNumToHundreths(hardCodedAnnualLockObject.indexRoR2YearDown), // 2 year
        4: this.roundNumToHundreths(hardCodedAnnualLockObject.segmentRoR3YearDown), // 3 year
        5: this.roundNumToHundreths(hardCodedAnnualLockObject.indexRoR3YearDown), // 3 year
      },
      index: this.getIndexName(item)
    }

    return upDownMarketObject;
  }

  getDynamicAnnualLockData(data, segment, index, duration, buffer, marketTrend) {
    // caseId
    // AL-1.1
    // AL-1.3
    // AL-1.5
    // AL-1.6
    // AL-2.1 // DOWNMARKET
    // AL-2.3

    let performanceArray = [];
    let upDownMarketArray = [];
    let upDownMarketObject = {
      upward: {},
      downward: {},
    };

    let newBuffer = buffer.toString().substring(0,3);
    let newDuration = duration.toString().substring(0,1);
    let hardCodedProductCode = 'SINBSH'; // hardcode to series A until we deteremine from business what they want rendered. 
    
    data.forEach(function(item) {
      let hardCodedAnnualLockObject = this.getHardCodedAnnualLockData(data, segment, index, duration, buffer, item);

      // the app and API do not have the same index names, so we need to check each one
      let indexName = this.getIndexName(item);
      let optionName = this.getOptionName(item);
      let segmentName = this.getSegmentName(item);
      let productCode = this.getSegmentProductCode(item);
      let objPerformance;

      if(item.buffer == newBuffer && item.duration == newDuration && segment == segmentName && hardCodedProductCode == productCode){
        
        //index performance rates
        //Up Market
        let column1IndexPerformanceRateUpMarket = hardCodedAnnualLockObject.indexRoR1YearUp;
        let column2IndexPerformanceRateUpMarket = hardCodedAnnualLockObject.indexRoR2YearUp;
        let column3IndexPerformanceRateUpMarket = hardCodedAnnualLockObject.indexRoR3YearUp;
        //Down Market
        let column1IndexPerformanceRateDownMarket = hardCodedAnnualLockObject.indexRoR1YearDown;
        let column2IndexPerformanceRateDownMarket = hardCodedAnnualLockObject.indexRoR2YearDown;
        let column3IndexPerformanceRateDownMarket = hardCodedAnnualLockObject.indexRoR3YearDown;

        //segment rates of return
        //Up Market
        let column1SegmentRatesOfReturnUpMarket = hardCodedAnnualLockObject.segmentRoR1YearUp;
        let column2SegmentRatesOfReturnUpMarket = hardCodedAnnualLockObject.segmentRoR2YearUp;
        let column3SegmentRatesOfReturnUpMarket = hardCodedAnnualLockObject.segmentRoR3YearUp;
        //Down Market
        let column1SegmentRatesOfReturnDownMarket = hardCodedAnnualLockObject.segmentRoR1YearDown;
        let column2SegmentRatesOfReturnDownMarket = hardCodedAnnualLockObject.segmentRoR2YearDown;
        let column3SegmentRatesOfReturnDownMarket = hardCodedAnnualLockObject.segmentRoR3YearDown;

        //min values
        //Up Market
        let minValueUpMarket = hardCodedAnnualLockObject.minValueUp;
        //DownMarket
        let minValueDownMarket = hardCodedAnnualLockObject.minValueDown;

        if(marketTrend === MARKET_SCENERIO.UPWARD) {
          // item, index, additionalCharges, hardcodedMin, performaceRate, segmentRates, segment)
          objPerformance = this.hydrateObject(
            item,
            indexName,
            0,
            minValueUpMarket,
            [column1IndexPerformanceRateUpMarket, column2IndexPerformanceRateUpMarket, column3IndexPerformanceRateUpMarket],//index performance rates
            [column1SegmentRatesOfReturnUpMarket, column2SegmentRatesOfReturnUpMarket, column3SegmentRatesOfReturnUpMarket],//segment rates of return
            segment
          );
        }
        else {
          // item, index, additionalCharges, hardcodedMin, performaceRate, segmentRates, segment)
          objPerformance = this.hydrateObject(
            item,
            indexName,
            0,
            minValueDownMarket,
            [column1IndexPerformanceRateDownMarket, column2IndexPerformanceRateDownMarket, column3IndexPerformanceRateDownMarket],//index performance rates
            [column1SegmentRatesOfReturnDownMarket, column2SegmentRatesOfReturnDownMarket, column3SegmentRatesOfReturnDownMarket],//segment rates of return
            segment
          );
        }


        performanceArray.push(objPerformance);
        // for annual lock, we need to pass the API an object that includes both the upward and downward values
        upDownMarketObject = this.hydrateUpDownMarketObject(item, hardCodedAnnualLockObject);
        // push all the updown market objects onto an array
        upDownMarketArray.push(upDownMarketObject);
      }
    }, this);

    // iterate through the array, check if the index is equal, and update the values of the up and down market bars 
    upDownMarketArray.forEach(function(upOrDownMarketObject) {
      if(index == upOrDownMarketObject.index) {
        this.upMarketBars = upOrDownMarketObject.upward;
        this.downMarketBars = upOrDownMarketObject.downward;
      }
    }, this);

    return performanceArray;
  }

  getDynamicStepUpData(data, segment, index, duration, buffer) {
    let performanceArray = []; // object needs to match testObject.performance

    // indexids
    // [9] = SP
    // [10] = "RUS"
    // [13] = "MET"

    let newBuffer = buffer.toString().substring(0,3);
    let newDuration = duration.toString().substring(0,1);
    let hardCodedProductCode = 'SINBSH'; // hardcode to series A until we deteremine from business what they want rendered. 
    
    data.forEach(function(item) {

      let hardCodedScenarios = this.getHardcodedStepUpValues(data, segment, index, duration, buffer, item);

      // the app and API do not have the same index names, so we need to check each one
      let indexName = this.getIndexName(item);
      let optionName = this.getOptionName(item);
      let segmentName = this.getSegmentName(item);
      let productCode = this.getSegmentProductCode(item);
      let objPerformance;

      //index performance rates
      let column1IndexPerformanceRateDownMarket = hardCodedScenarios.indexDM1; 
      let column2IndexPerformanceRateDownMarket = hardCodedScenarios.indexDM2; 
      let column3IndexPerformanceRateUpMarket = 0;
      let column4IndexPerformanceRateUpMarket = item.perfcaprate * 0.5; //item.cases[0].indexROR;
      let column5IndexPerformanceRateUpMarket = item.perfcaprate * 1.1;//item.cases[1].indexROR;

      //segment rates of return
      let column1SegmentRatesOfReturnUpMarket = hardCodedScenarios.segmentDM1; 
      let column2SegmentRatesOfReturnUpMarket = hardCodedScenarios.segmentDM2; 
      let column3SegmentRatesOfReturnDownMarket = item.perfcaprate;
      let column4SegmentRatesOfReturnDownMarket = item.perfcaprate; //item.cases[0].segmentROR;
      let column5SegmentRatesOfReturnDownMarket = item.cases[1].segmentROR;

      if(item.buffer == newBuffer && item.duration == newDuration && segment == segmentName && hardCodedProductCode == productCode){
        objPerformance = this.hydrateObject(
          item, 
          indexName,
          0, 
          hardCodedScenarios.minValue, 
          [column1IndexPerformanceRateDownMarket, column2IndexPerformanceRateDownMarket, column3IndexPerformanceRateUpMarket, column4IndexPerformanceRateUpMarket, column5IndexPerformanceRateUpMarket], //index performance rate
          [column1SegmentRatesOfReturnUpMarket, column2SegmentRatesOfReturnUpMarket, column3SegmentRatesOfReturnDownMarket, column4SegmentRatesOfReturnDownMarket, column5SegmentRatesOfReturnDownMarket], //segment rate of return
        );
        performanceArray.push(objPerformance);
      }

    }, this); // https://stackoverflow.com/questions/45175605/how-to-call-this-inside-for-example-foreach-in-class

    return performanceArray;
  }

  getDynamicDualDirectionData(data, segment, index, duration, buffer) {
    let performanceArray = []; 

    let newBuffer = buffer.toString().substring(0,3);
    let newDuration = duration.toString().substring(0,1);
    let hardCodedProductCode = 'SINBSH'; // hardcode to series A until we deteremine from business what they want rendered. 

    let hardCodedScenario3And4Object = this.getHardCodedDualDirectionValues(data, segment, index, duration, buffer); 
    
    data.forEach(function(item) {

      // the app and API do not have the same index names, so we need to check each one
      let indexName = this.getIndexName(item);
      let optionName = this.getOptionName(item);
      let segmentName = this.getSegmentName(item);
      let productCode = this.getSegmentProductCode(item);
      let objPerformance;

      //index performance rates
      let scenario1IndexPerformanceRate = item.cases[0].indexROR; 
      let scenario2IndexPerformanceRate = item.cases[1].indexROR; 
      let scenario3IndexPerformance = hardCodedScenario3And4Object.indexRoRDownMarket1;
      let scenario4IndexPerformance = hardCodedScenario3And4Object.indexRoRDownMarket1;

      //segment rates of return - these are used for %
      let scenario1SegmentRatesOfReturn = item.perfcaprate * 1.1;//item.cases[0].segmentROR; 
      let scenario2SegmentRatesOfReturn = item.perfcaprate * 0.7;//item.cases[1].segmentROR; 
      let scenario3SegmentRatesOfReturn = hardCodedScenario3And4Object.segmentRoRDownMarket1; 
      let scenario4SegmentRatesOfReturn = hardCodedScenario3And4Object.segmentRoRDownMarket2; 

      if(item.buffer == newBuffer && item.duration == newDuration && segment == segmentName && hardCodedProductCode == productCode){
        objPerformance = this.hydrateObject(
          item, 
          indexName,
          0, 
          -25, // interval. -10 == 10, -15 = 10, -20 = 5
          [scenario1IndexPerformanceRate, scenario2IndexPerformanceRate, scenario3IndexPerformance, scenario4IndexPerformance],
          [scenario1SegmentRatesOfReturn, scenario2SegmentRatesOfReturn, scenario3SegmentRatesOfReturn, scenario4SegmentRatesOfReturn], 
        );
        performanceArray.push(objPerformance);
      }

    }, this); // https://stackoverflow.com/questions/45175605/how-to-call-this-inside-for-example-foreach-in-class

    return performanceArray;

  }

  getDynamicEnhancedUpsideData(data, segment, index, duration, buffer, enhancedUpside) {
    // [23] in array, "EU-UP1", "EU-UP2", "EU-UP3"
    let newBuffer = buffer.toString().substring(0,3);
    let newDuration = duration.toString().substring(0,1);
    let hardCodedProductCode = 'SINBSH'; // hardcode to series A until we deteremine from business what they want rendered. 

    let performanceArray = [];

    let hardCodedDownMarketValues = this.getHardCodedEnhancedUpsideValues(enhancedUpside, buffer);
 
    // TODO: Figure out why the segment rates of return are not going to the proper values 
    data.forEach(function(item) {

      let indexName = this.getIndexName(item);
      let optionName = this.getOptionName(item);
      let segmentName = this.getSegmentName(item);
      let productCode = this.getSegmentProductCode(item);
      let enhancedUpsideNameFromAPI = this.getEnhancedUpsideRate(item); // we can probably hardcode this to 100 or 110 and remove it from the if statement
      let objPerformance;

      if(item.buffer == newBuffer && item.duration == newDuration && segment == segmentName && enhancedUpside == enhancedUpsideNameFromAPI && hardCodedProductCode == productCode){
        //index performance rates
        let column1IndexPerformanceRateUpMarket = item.cases[0].indexROR; 
        let column2IndexPerformanceRateUpMarket = item.cases[1].indexROR; 
        let column3IndexPerformanceRateUpMarket = item.cases[2].indexROR;
        let column4IndexPerformanceRateDownMarket = hardCodedDownMarketValues.indexRoRDownMarket1;
        let column5IndexPerformanceRateDownMarket = hardCodedDownMarketValues.indexRoRDownMarket2;

        //segment rates of return
        let column1SegmentRatesOfReturnUpMarket = item.cases[0].segmentROR;
        let column2SegmentRatesOfReturnUpMarket = item.cases[1].segmentROR;
        let column3SegmentRatesOfReturnUpMarket = item.cases[2].segmentROR;
        let column4SegmentRatesOfReturnDownMarket = hardCodedDownMarketValues.segmentRoRDownMarket1
        let column5SegmentRatesOfReturnDownMarket = hardCodedDownMarketValues.segmentRoRDownMarket2;
       
        objPerformance = this.hydrateObject(
          item,
          indexName,
          0,
          -20,
          [column1IndexPerformanceRateUpMarket, column2IndexPerformanceRateUpMarket, column3IndexPerformanceRateUpMarket, column4IndexPerformanceRateDownMarket, column5IndexPerformanceRateDownMarket],
          [column1SegmentRatesOfReturnUpMarket, column2SegmentRatesOfReturnUpMarket, column3SegmentRatesOfReturnUpMarket, column4SegmentRatesOfReturnDownMarket, column5SegmentRatesOfReturnDownMarket],
        );
        performanceArray.push(objPerformance);
      }

    }, this);

    return performanceArray;
  }

  getDynamicDualStepUpData(data, segment, index, duration, buffer) {
    let performanceArray = []; 

    let newBuffer = buffer.toString().substring(0,3);
    let newDuration = duration.toString().substring(0,1);
    let hardCodedProductCode = 'SINBSH'; // hardcode to series A until we deteremine from business what they want rendered. 
    
    data.forEach(function(item) {

      let hardCodedValues = this.getHardCodedDualStepUpValues(data, segment, index, duration, buffer, item);

      // the app and API do not have the same index names, so we need to check each one
      let indexName = this.getIndexName(item);
      let optionName = this.getOptionName(item);
      let segmentName = this.getSegmentName(item);
      let productCode = this.getSegmentProductCode(item);
      let objPerformance;

      if(item.buffer == newBuffer && item.duration == newDuration && segment == segmentName && hardCodedProductCode == productCode){
        //index performance rates
        let column1IndexPerformanceRateDownMarket = hardCodedValues.indexRoRDownMarket1; 
        let column2IndexPerformanceRateDownMarket = hardCodedValues.indexRoRDownMarket2; 
        let column3IndexPerformanceRateUpMarket = 0;
        let column4IndexPerformanceRateUpMarket = item.cases[0].indexROR;
        let column5IndexPerformanceRateUpMarket = item.cases[1].indexROR;

        //segment rates of return
        let column1SegmentRatesOfReturnUpMarket = hardCodedValues.segmentRoRDownMarket1; 
        let column2SegmentRatesOfReturnUpMarket = hardCodedValues.segmentRoRDownMarket2; 
        let column3SegmentRatesOfReturnDownMarket = item.perfcaprate;
        let column4SegmentRatesOfReturnDownMarket = item.cases[0].segmentROR;
        let column5SegmentRatesOfReturnDownMarket = item.cases[1].segmentROR;
        
        objPerformance = this.hydrateObject(
          item, 
          indexName,
          0, 
          -20, 
          [column1IndexPerformanceRateDownMarket, column2IndexPerformanceRateDownMarket, column3IndexPerformanceRateUpMarket, column4IndexPerformanceRateUpMarket, column5IndexPerformanceRateUpMarket],
          [column1SegmentRatesOfReturnUpMarket, column2SegmentRatesOfReturnUpMarket, column3SegmentRatesOfReturnDownMarket, column4SegmentRatesOfReturnDownMarket, column5SegmentRatesOfReturnDownMarket],
        );
        performanceArray.push(objPerformance);
      }

    }, this); // https://stackoverflow.com/questions/45175605/how-to-call-this-inside-for-example-foreach-in-class

    return performanceArray;

  }

  getDynamicLossLimiter90Data(data, segment, index, duration, buffer) {
    let performanceArray = []; 

    let newBuffer = buffer.toString().substring(0,3);
    let newDuration = duration.toString().substring(0,1);
    let hardCodedProductCode = 'SINBSH'; // hardcode to series A until we deteremine from business what they want rendered. 
    
    data.forEach(function(item) {

      // the app and API do not have the same index names, so we need to check each one
      let indexName = this.getIndexName(item);
      let optionName = this.getOptionName(item);
      let segmentName = this.getSegmentName(item);
      let productCode = this.getSegmentProductCode(item);
      let objPerformance;

      //index performance rates
      let column1IndexPerformanceRateDownMarket = -30; 
      let column2IndexPerformanceRateDownMarket = -13; 
      let column3IndexPerformanceRateDownMarket = -7;
      let column4IndexPerformanceRateUpMarket = item.perfcaprate * 0.7;//item.cases[0].indexROR;
      let column5IndexPerformanceRateUpMarket = item.perfcaprate * 1.1;//item.cases[1].indexROR;

      //segment rates of return
      let column1SegmentRatesOfReturnDownMarket = -10; 
      let column2SegmentRatesOfReturnDownMarket = -3;
      let column3SegmentRatesOfReturnDownMarket = 0;
      let column4SegmentRatesOfReturnUpMarket = item.perfcaprate * 0.7;//item.cases[0].segmentROR;
      let column5SegmentRatesOfReturnUpMarket = item.cases[1].segmentROR;

      if(item.buffer == newBuffer && item.duration == newDuration && segment == segmentName && hardCodedProductCode == productCode){
        objPerformance = this.hydrateObject(
          item, 
          indexName,
          0, 
          -30, 
          [column1IndexPerformanceRateDownMarket, column2IndexPerformanceRateDownMarket, column3IndexPerformanceRateDownMarket, column4IndexPerformanceRateUpMarket, column5IndexPerformanceRateUpMarket],
          [column1SegmentRatesOfReturnDownMarket, column2SegmentRatesOfReturnDownMarket, column3SegmentRatesOfReturnDownMarket, column4SegmentRatesOfReturnUpMarket, column5SegmentRatesOfReturnUpMarket],
        );
        performanceArray.push(objPerformance);
      }

    }, this); // https://stackoverflow.com/questions/45175605/how-to-call-this-inside-for-example-foreach-in-class

    return performanceArray;
  }

  getDynamicLossLimiter95Data(data, segment, index, duration, buffer) {
    let performanceArray = []; 

    let newBuffer = buffer.toString().substring(0,3);
    let newDuration = duration.toString().substring(0,1);
    let hardCodedProductCode = 'SINBSH'; // hardcode to series A until we deteremine from business what they want rendered. 
    
    data.forEach(function(item) {

      // the app and API do not have the same index names, so we need to check each one
      let indexName = this.getIndexName(item);
      let optionName = this.getOptionName(item);
      let segmentName = this.getSegmentName(item);
      let productCode = this.getSegmentProductCode(item);
      let objPerformance;

      //index performance rates
      let column1IndexPerformanceRateDownMarket = -30; 
      let column2IndexPerformanceRateDownMarket = -13; 
      let column3IndexPerformanceRateDownMarket = -7;
      let column4IndexPerformanceRateUpMarket = item.perfcaprate * 0.7;//item.cases[0].indexROR;
      let column5IndexPerformanceRateUpMarket = item.perfcaprate * 1.1;//item.cases[1].indexROR;

      //segment rates of return
      let column1SegmentRatesOfReturnDownMarket = -5; 
      let column2SegmentRatesOfReturnDownMarket = -3;
      let column3SegmentRatesOfReturnDownMarket = 0;
      let column4SegmentRatesOfReturnUpMarket = item.perfcaprate * 0.7;//item.cases[0].segmentROR;
      let column5SegmentRatesOfReturnUpMarket = item.cases[1].segmentROR;

      if(item.buffer == newBuffer && item.duration == newDuration && segment == segmentName && hardCodedProductCode == productCode){
        objPerformance = this.hydrateObject(
          item, 
          indexName,
          0, 
          -30, 
          [column1IndexPerformanceRateDownMarket, column2IndexPerformanceRateDownMarket, column3IndexPerformanceRateDownMarket, column4IndexPerformanceRateUpMarket, column5IndexPerformanceRateUpMarket],
          [column1SegmentRatesOfReturnDownMarket, column2SegmentRatesOfReturnDownMarket, column3SegmentRatesOfReturnDownMarket, column4SegmentRatesOfReturnUpMarket, column5SegmentRatesOfReturnUpMarket],
        );
        performanceArray.push(objPerformance);
      }

    }, this); // https://stackoverflow.com/questions/45175605/how-to-call-this-inside-for-example-foreach-in-class

    return performanceArray;
  }

  roundNumberToTenths(num) {
    return Math.round(num * 10) / 10;
  }

  roundArrayToHundreths(array) {
    // const roundedArray = array.map(num => Math.round(num * 100) / 100);
    const roundedArray = array.map(num => parseFloat(num).toFixed(2));
    return roundedArray;
  }

  roundNumToHundreths(num) {
    return parseFloat(num).toFixed(2);
  }

  calculateMaxValue(maxValue, segment, item) {

    // hardcode the max y-axis value for annual lock as it's dependent on the line graph 
    if(segment == SEGMENT_TYPE.ANNUAL_LOCK) {
      return 50; // this should be 100 once we get this figured out 
    }

    if(item.buffer == '-10' && item.duration == '6' && (item.indexid == "SP " || item.indexid == "MET" || item.indexid == "MSC") && (item.cases[0].caseId == "ST-UP1" && item.cases[1].caseId == "ST-UP2")){
      return 150;
    }

    // todo need to set new max value here for scs income

    // IF(indexROR is less than 20, add 5 to it)
    if (maxValue <= 20) {
      return maxValue + 2;
    }
    // IF(either indexROR is 0 to 200, add 10 to it)
    else if (maxValue < 200) {
      return maxValue + 10;
    }
    // IF(either indexROR is 200 to 1000, add 50 to it)
    else if (maxValue >= 200 && maxValue <= 1000) {
      return maxValue + 50;
    }
    // IF(either indexROR is greater than 1000, add 100 to it)
    else if (maxValue > 1000) {
      return maxValue + 100;
    }
    else {
      console.error('Return value unknown');
    }
  }

  // TODO: not sure we will need min value. may need to remain hardcoded
  calculateMinValue(minValue) {
    // IF(indexROR is less than 20, add 5 to it)
    if (minValue <= 30) {
      return minValue - 2;
    }
    // IF(either indexROR is 0 to 200, add 10 to it)
    else if (minValue < 200) {
      return minValue - 10;
    }
    // IF(either indexROR is 200 to 1000, add 50 to it)
    else if (minValue >= 200 && minValue <= 1000) {
      return minValue - 50;
    }
    // IF(either indexROR is greater than 1000, add 100 to it)
    else if (minValue > 1000) {
      return minValue - 100;
    }
    else {
      console.error('Return value unknown');
    }
  }

  calculateInterval(perfcaprate, segment, item) {
    // hardcode the max y-axis value for annual lock as it's dependent on the line graph 
    if(segment == SEGMENT_TYPE.ANNUAL_LOCK) {
      return 10;
    }

    // hardcoded for uncapped rates, MSCI and Standard, 3 year, -10 
    if(this.series == SCSINCOME_SERIES.B || this.series == SCSINCOME_SERIES.ADV){
      if((item.buffer == '-10' || item.buffer == '-15') && item.duration == '3' && (item.indexid == 'MSC')){
        if(item.buffer == '-10'){
          return 100;
        }
        else if(item.buffer == '-15'){
          return 50;
        }
      }
    }

    if (perfcaprate <= 20) {
      return 2;
    }

    else if (perfcaprate == 200) {
      return 25;
    }

    else if (perfcaprate > 200 && perfcaprate < 300) {
      return 20;
    }

    else if (perfcaprate == 300) {
      return 20;
    }

    else if (perfcaprate >= 125 && perfcaprate <= 150) {
      return 25;
    }

    else if (perfcaprate < 200) {
      return 10;
    }

    else if (perfcaprate <= 1000) {
      return 20;
    }

    else {
      console.error('Return value unknown');
      return 10;
    }
  }

  //======================================= End Here ===================================== //

  /***
   *  Open Modal
   ***/
  async openModal(componentName, className) {
    const modal = await this.modalController.create({
      component: componentName,
      cssClass: className,
    });
    return await modal.present();
  }

  /***
   *  fetch the DATA from server/storage
   ***/
  fetchScsIncomeData(serviceApi: string) {
    var baseURL = environment.serviceAPIs.baseUrl
    if (/https/.test(this.scsincome[serviceApi])) {
      var baseURL = ""
    } 
    console.log('baseURL', baseURL)
    return this.getScsIncomeData().pipe(
      switchMap(() => {
        return this.httpRequestService
          .request({
            method: API_METHODS.GET,
            url: baseURL + this.scsincome[serviceApi],
          })
          .pipe(
            map((data: any) => {
              return data.body;
            }),
            catchError((err) => {
              throw throwError(err);
            })
          );
      })
    );
  }

    /***
   *  Fetch respurces
   ***/
  fetchResources() {
    var baseURL = environment.serviceAPIs.baseUrl
    if (/https/.test(environment.serviceAPIs.scsincome)) {
      var baseURL = ""
    } 

    const requestOptions = {
      method: API_METHODS.GET,
      url: baseURL + environment.serviceAPIs.scsincome,
    };
    return this.httpRequestService.request(requestOptions).pipe(
      map((data: any) => {
        this.scsincome = data.body;
        return data.body;
      }),
      catchError((err) => {
        throw throwError(err);
      })
    );
  }
  /***
   *  get SCS INCOME Main Data
   ***/
  getScsIncomeData(): Observable<any> {
    if (this.scsincome) {
      return of(this.scsincome);
    } else {
      return this.fetchResources();
    }
  }
  //======================================= End Here ===================================== //
}
