<div class="modal-full bg-primary text-center">
    <h3>Looking for more ways to invest?</h3>
    <div class="animate-contain">We have you covered with
        <div class="animation">
            <div>
                <div>Tax-Smart</div>
            </div>
            <div>
                <div>Investment</div>
            </div>
            <div>
                <div>Income</div>
            </div>
        </div>
        strategies.</div>
    <p>Equitable's history of innovation continues with our Structured Capital Strategies<sup>®</sup> variable annuity product suite.</p>
    <p>View the new <a (click)="visitPage(route.SCS.TOOLS.HOW_SIO_WORKS); closeModal();">Structured Capital Strategies PLUS<sup>®</sup></a> and <a (click)="visitPage(route.SCSINCOME.TOOLS.HOW_SIO_WORKS); closeModal();">Structured
            Capital Strategies<sup>®</sup> Income</a> line up today!</p>
    <ion-row>
        <ion-col class="center-align">
            <equitable-button (click)="closeModal()" class="btn-main" type="primary" theme="dark"><span
                    class="btn-text">{{
        "sioFrontPage.popOver.btnLabel" | translate
        }}</span></equitable-button>
        </ion-col>
    </ion-row>
    <p class="sm">Please see the Disclosures section of this application for important related information and disclosures about Structured Capital Strategies<sup>®</sup>.<br> GE-5453169.1 (02/2023) (Exp. 02/2025)</p>
</div>