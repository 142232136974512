<ion-content>
  <article class="primerica-home-page">
    <section class="protection-section">
      <div class="protection-section-image"></div>

      <div class="protection-section-text">
        <div class="protection-sub-section">
          <div class="page-title">{{ "primerica.pageTitle" | translate }}</div>
          <h1
            class="product-name"
            [innerHTML]="'primerica.productName' | translate"
          ></h1>
          <div class="product-description">
            {{ "primerica.productDescription" | translate }}
          </div>
          <ul class="benefits">
            <li>{{ "primerica.partialProtection" | translate }}</li>
            <li>{{ "primerica.upsidePotential" | translate }}</li>
            <li>{{ "primerica.zeroFees" | translate }}</li>
          </ul>
          <div class="benefit-description-section">
            <div class="benefit-description">
              {{ "primerica.benefitDescription" | translate }}
            </div>
          </div>
        </div>

        <!-- Link To dashboard Page -->
        <div class="benefit-button">
          <equitable-button
            [type]="ButtonType.Primary"
            (click)="visitPage(route.SCS.TOOLS.HOME)"
            class="start-now-btn"
            >{{ "primerica.startNow" | translate }}</equitable-button
          >
        </div>
      </div>
    </section>

    <section class="market-trend-indicator-section">
      <div class="market-trend-indicator">
        <div class="card-title">{{ "primerica.cardTitle" | translate }}</div>
        <p class="card-description">
          {{ "primerica.cardDescription" | translate }}
        </p>

        <!-- link to Market Trend Indicator  -->
        <equitable-button  *appFeatureFlag="'isNational'"
          class="btn-color"
          [type]="ButtonType.Primary"
          (click)="visitPage(route.SCS.TOOLS.MARKET_TREND)"
          >{{ "primerica.learnMore" | translate }}</equitable-button
        >
        <!-- link to Perspectives   -->
        <equitable-button  *appFeatureFlag="'isPlusGuard'"
          class="btn-color"
          [type]="ButtonType.Primary"
          (click)="visitEquitable('https://equitable.com/perspectives/third-party')"
          >{{ "primerica.learnMore" | translate }}</equitable-button
        >
      </div>
    </section>
  </article>
  <app-footer
    isBlueFooterVisible="true"
    [selectedProduct]="selectedProduct"
  ></app-footer>
</ion-content>
