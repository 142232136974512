import { OnInit } from "@angular/core";
import { Validators, FormBuilder } from "@angular/forms";
import { RcService } from "../../services/rc.service";
import { TranslateService } from "@ngx-translate/core";
import { CurrencyPipe } from "@angular/common";
import { UtilityService } from "../../../core/services/utility.service";
var InvestTaxSmartComponent = /** @class */ (function () {
    function InvestTaxSmartComponent(utilityService, rcService, fb, translate, currencyPipe) {
        this.utilityService = utilityService;
        this.rcService = rcService;
        this.fb = fb;
        this.translate = translate;
        this.currencyPipe = currencyPipe;
        this.taxBracketsOptions = [];
        this.durationOptions = [];
        this.maxYscale = 0;
        this.chartLabel = [
            {
                labelHeader: "",
                labelDescription: "",
            },
            {
                labelHeader: "",
                labelDescription: "",
            },
        ];
        //chart Data
        this.chartData = [
            {
                description: "withoutTaxDefferal",
                value: 0,
            },
            {
                description: "withTaxDefferal",
                value: 0,
            },
        ];
        this.form = this.fb.group({
            taxBracket: ["", Validators.required],
            duration: ["", Validators.required],
        });
    }
    InvestTaxSmartComponent.prototype.ngOnInit = function () {
        this.getData();
    };
    /***
     * Update Chart Labels based on selected duration and year
     ***/
    InvestTaxSmartComponent.prototype.getChartLabel = function (withoutTax, withTax, year) {
        var _this = this;
        // dropdown data
        this.translate.get("rcTool.investTaxSmart").subscribe(function (translated) {
            _this.chartLabel = [
                {
                    labelHeader: _this.translate.instant("rcTool.investTaxSmart.noTaxDefferalHeader"),
                    labelDescription: _this.translate.instant("rcTool.investTaxSmart.noTaxDefferallabelDescription", {
                        noTaxValue: _this.currencyPipe.transform(withoutTax, "USD", "$", "1.0"),
                        noTaxYear: year,
                    }),
                },
                {
                    labelHeader: _this.translate.instant("rcTool.investTaxSmart.TaxDefferalHeader"),
                    labelDescription: _this.translate.instant("rcTool.investTaxSmart.TaxDefferallabelDescription", {
                        taxValue: _this.currencyPipe.transform(withTax, "USD", "$", "1.0"),
                        taxYear: year,
                    }),
                },
            ];
        });
    };
    /***
     *  Method to fetch data from API
     ***/
    InvestTaxSmartComponent.prototype.getData = function () {
        var _this = this;
        this.utilityService.showLoader();
        this.rcService
            .fetchInvestTaxSmartChartData()
            .subscribe(function (getData) {
            _this.utilityService.hideLoader();
            _this.data = getData;
            _this.investment = _this.data.options[0].investment;
            _this.rateOfReturn = _this.data.options[0].rateOfReturn;
            _this.maxYscale = _this.data.options[0].maxValue;
            if (getData["taxBrackets"]) {
                getData["taxBrackets"].forEach(function (item) {
                    var obj = {};
                    obj["id"] = item;
                    obj["value"] =
                        item + _this.translate.instant("rcTool.investTaxSmart.percent");
                    _this.taxBracketsOptions.push(obj);
                });
            }
            if (getData["durations"]) {
                getData["durations"].forEach(function (item) {
                    var obj = {};
                    obj["id"] = item;
                    obj["value"] =
                        item +
                            " " +
                            _this.translate.instant("rcTool.investTaxSmart.years");
                    _this.durationOptions.push(obj);
                });
            }
            _this.form.controls["taxBracket"].setValue(_this.data.taxBrackets[0]);
            _this.form.controls["duration"].setValue(_this.data.durations[0]);
            _this.getTaxData();
            _this.getChartLabel(_this.data.options[0].noTaxDeferral, _this.data.options[0].taxDeferral, _this.data.durations[0]);
        });
    };
    /***
     *  Method to get tax Data after selecting duration and taxbracket from dropdown
     ***/
    InvestTaxSmartComponent.prototype.getTaxData = function () {
        var _this = this;
        var chartData = this.chartData;
        this.selectedTaxBracket = this.form.controls["taxBracket"].value;
        this.selectedDuration = this.form.controls["duration"].value;
        var filteredData = this.data.options.find(function (x) {
            return x.taxBracket == parseFloat(_this.selectedTaxBracket) &&
                x.duration == parseInt(_this.selectedDuration);
        });
        chartData[0].value = filteredData.noTaxDeferral;
        chartData[1].value = filteredData.taxDeferral;
        this.chartData = chartData;
        this.getChartLabel(chartData[0].value, chartData[1].value, this.selectedDuration);
    };
    /***
     *  Back button functionality
     ***/
    InvestTaxSmartComponent.prototype.navigateTo = function () {
        this.utilityService.navigate("rc");
    };
    return InvestTaxSmartComponent;
}());
export { InvestTaxSmartComponent };
