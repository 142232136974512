import { Component, OnInit, AfterViewChecked, OnChanges } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { ScsIncomeService } from "../../services/scsincome.service";
import {
  SEGMENT_TYPE,
  MARKET_SCENERIO,
  SCSINCOME_SERIES,
  CHOICE_COMMISION,
  CUSTOMIZATION_TYPE,
  CUSTOM_PROFILE,
  ROUTE_PATH,
  INVESTOR_MODE,
} from "../../../constants/constants";
import { UtilityService } from "../../../core/services/utility.service";
import { pairwise, distinctUntilChanged } from "rxjs/operators";
import {
  Chart,
  Dictionary,
  Profile,
  SCSIncomeHowSioWorks,
} from "../../../core/models/app.models";
import { TranslateService } from "@ngx-translate/core";
import {
  Theme,
  ButtonType,
  Orientation,
  LinkIcon,
} from "equitable-component-library";
import { SioPopupComponent } from "../sio-popup/sio-popup.component";
import { ModalPopupComponent } from "../../../core/components/modal-popup/modal-popup.component";
import { CoreService } from "../../../core/services/core.service";
import { FeatureFlagService } from "./../../../core/services/feature-flag.service";

@Component({
  selector: "app-how-sio-works",
  templateUrl: "./how-sio-works.component.html",
  styleUrls: ["./how-sio-works.component.scss"],
})
export class HowSioWorksComponent implements OnInit, AfterViewChecked {
  CUSTOMIZATION_TYPE = CUSTOMIZATION_TYPE;
  orientation = Orientation.Right;
  type = Theme.Dark;
  btnSaveTheme = Theme.Light;
  btnSaveType = ButtonType.Primary;
  linkOrientation = Orientation.Left;
  linkIcon = LinkIcon.LeftIcon;
  linkRightIcon = LinkIcon.RightIcon;
  SEGMENT_TYPE = SEGMENT_TYPE;
  formGroup: FormGroup;
  chartData: Chart
  performanceData: any; // moved outside of the function so this can be passed to the dual direction component 
  indices: Dictionary[] = [];
  durations: Dictionary[] = [];
  protections: Dictionary[] = [];
  enhancedUpsides: Dictionary[] = [];
  dynamicSegmentMaturityValue: '100000';
  items: Dictionary[] = [];
  series: string;
  howItWorks: any;
  masterData: any = {};
  segment: string;
  chartDetails: any[] = [];
  marketScenerios: Dictionary[];
  annualSegmentData: any;
  stepUpSegmentData: any;
  enhancedUpsideSegmentData: any;
  enhancedUpside10Static: any;
  enhancedUpside15Static: any;
  enhancedUpside10Static125: any;
  enhancedUpside15Static125: any;
  annualRate: string;
  toolHeaderText = "";
  toolFooterTextPerformanceCapRate = "";
  customizationType;
  backLink: string;
  backButtonSeries =
    this.scsincomeService.series == SCSINCOME_SERIES.B ? SCSINCOME_SERIES.ADV + "" : "";
  showInfo = true;
  isInitalLoadCompleted = false;
  isDynamicChart = true;
  isNotBGuard: boolean;
  isADV: boolean;
  isNational: boolean;
  isPlusGuard: boolean;
  isNotPlusGuard: boolean;
  isPrimerica: boolean;
  dollarInputValue;
  minValue = 5000;
  maxValue = 50000000;
  /***
   *  Constructor for how its works
   ***/
  constructor(
    private formBuilder: FormBuilder,
    public scsincomeService: ScsIncomeService,
    private utilityService: UtilityService,
    private translateService: TranslateService,
    private coreService: CoreService,
    private flagService: FeatureFlagService
  ) {}

  /***
   *  Page Load for how its works
   ***/
  ngOnInit() {
    //set series
    this.series = this.scsincomeService.series;
    this.customizationType = this.scsincomeService.customizationType;
    //build form
    this.buildForm();

    //get data for the page
    this.getHowItsWorksData();
    this.isNotBGuard = !this.flagService.featureFlags.isPlusGuard
    this.isNational = this.flagService.featureFlags.isNational;
    this.isPlusGuard = this.flagService.featureFlags.isPlusGuard;
    this.isPrimerica = this.flagService.featureFlags.isPrimerica;

    // this is how we can listen for form changes. we should not need to do this. 
    //  this.formGroup.get('dynamicDollarAmount').valueChanges.subscribe(val => {
    //   const formattedMessage = `dollarInputValue is ${val}.`;
    //   console.log(formattedMessage);
    // });
    
    // get the dollar amount initially
    this.dollarInputValue = this.scsincomeService.getDollarAmount()
  }

  //page load for ionic
  ionViewWillEnter() {
    if (this.scsincomeService.series == SCSINCOME_SERIES.B) {
      this.backLink = this.translateService.instant("howItsWorks.backIncome");
      console.log("BONLY");
    } 
    else if (this.scsincomeService.series == SCSINCOME_SERIES.ADV) {
      this.backLink = this.translateService.instant("howItsWorks.backIncome");
      console.log("ADVONLY");
    }
    else {
      this.backLink = this.translateService.instant("sioFrontPage.backIncome");
    }
  }

  //check content fully loaded
  ngAfterViewChecked() {
  
    if (
      this.formGroup.get("segment").value &&
      this.formGroup.get("index").value &&
      this.formGroup.get("duration").value &&
      this.formGroup.get("protection").value
    ) {
      this.isInitalLoadCompleted = true;
    }

    else if(this.scsincomeService.series == SCSINCOME_SERIES.ADV && 
      this.formGroup.get("segment").value &&
      this.formGroup.get("index").value &&
      this.formGroup.get("duration").value &&
      this.formGroup.get("protection").value &&
      this.formGroup.get("enhancedUpside").value 
      ) {
        this.isInitalLoadCompleted = true;
    }
  
  }

  // TODO: try putting the subscribe function in this file for dynamic data. 

  /***
   *  Create form for how its works
   ***/
  getHowItsWorksData(): void {
    this.utilityService.showLoader();
    this.scsincomeService.getHowitWorksData(this.series).subscribe((data) => {
      this.utilityService.hideLoader();
      if (data[1] && data[0].body) {
        this.howItWorks = data[1];
        this.getMasterData(data[0].body, data[2]);
      }
      
    });
  }

  isValid() {
    return this.dollarInputValue >= this.minValue && this.dollarInputValue <= this.maxValue && this.dollarInputValue % 1 === 0;
  }

  onSubmitOfDollarAmount(){
    this.scsincomeService.setDollarAmount(this.dollarInputValue)
    this.prepareChart();
  }

  /***
   *  Create form for how its works
   ***/
  buildForm(): void {
    //create form
    this.formGroup = this.formBuilder.group({
      segment: new FormControl(
        { value: "", disabled: false },
        Validators.required
      ),
      index: new FormControl(
        { value: "", disabled: false },
        Validators.required
      ),
      duration: new FormControl(
        { value: "", disabled: false },
        Validators.required
      ),
      protection: new FormControl(
        { value: "", disabled: false },
        Validators.required
      ),
      enhancedUpside: new FormControl(
        { value: "", disabled: false },
        Validators.required
      ),
      dynamicDollarAmount: new FormControl(
        { value: this.scsincomeService.getDollarAmount(), disabled: false },
        Validators.required
      ),
      marketScenerio: new FormControl(
        { value: "", disabled: true },
        Validators.required
      ),
    });

    //Form on changes
    this.formGroup.valueChanges
      .pipe(distinctUntilChanged(), pairwise())
      .subscribe((data) => {


        if (
          data[1].segment &&
          data[1].index &&
          data[1].duration &&
          data[1].protection
        ) {
  
          
          data[1].segment =
            typeof data[1].segment === "object"
              ? data[1].segment.id
              : data[1].segment;
          this.indexSelection(data);
          
        
          // IF the segment type is dual direction for primerica or Bguard, we want to display a static image. 
          if (this.segment.toLowerCase() == SEGMENT_TYPE.DUAL_DIRECTION.toLowerCase() && !this.isNational) {
            this.isDynamicChart = false;
            this.saveProfile();
          }
          else {
            this.isDynamicChart = true
            this.prepareChart();
            this.saveProfile();
          }
        }
      });
  }

  /***
   *  get master data for how its works
   ***/
  getMasterData(masterData, savedProfiles: Profile[]): void {
    //get market scenerio master data
    this.translateService
      .get("howItsWorks.marketScenerios")
      .subscribe((marketScenerios) => {
        this.marketScenerios = [
          {
            id: MARKET_SCENERIO.UPWARD,
            value: marketScenerios.upwards,
          },
          {
            id: MARKET_SCENERIO.DOWNWARD,
            value: marketScenerios.downwards,
          },
        ];
      });


      if(this.scsincomeService.series == SCSINCOME_SERIES.B){
        this.enhancedUpsides = [
          {
            id: '125',
            value: '125%'
          },
          // dont have 110 as a default option, otherwise we have a bug
          // {
          //   id: '110',
          //   value: '110%'
          // },
        ]
      }

    //get all drop down master data for all series

    // TODO: Remove these instances as needed. 
    this.masterData = masterData.series.find((x) => x.name === this.series);
    this.annualSegmentData =
      masterData.annualSegmentData && masterData.annualSegmentData.length > 0
        ? masterData.annualSegmentData.find((x) => x.series === this.series)
        : {};
    this.stepUpSegmentData =
      masterData.stepUpSegmentData && masterData.stepUpSegmentData.length > 0
        ? masterData.stepUpSegmentData.find((x) => x.series === this.series)
        : {};
    // enhanced upside
    this.enhancedUpside10Static =
        masterData.enhancedUpside10Static && masterData.enhancedUpside10Static.length > 0
          ? masterData.enhancedUpside10Static.find((x) => x.series === this.series)
          : {};
    this.enhancedUpside15Static =
          masterData.enhancedUpside15Static && masterData.enhancedUpside15Static.length > 0
            ? masterData.enhancedUpside15Static.find((x) => x.series === this.series)
            : {};
    this.enhancedUpside10Static125 =
      masterData.enhancedUpside10Static125 && masterData.enhancedUpside10Static125.length > 0
        ? masterData.enhancedUpside10Static125.find((x) => x.series === this.series)
        : {};
    this.enhancedUpside15Static125 =
      masterData.enhancedUpside15Static125 && masterData.enhancedUpside15Static125.length > 0
        ? masterData.enhancedUpside15Static125.find((x) => x.series === this.series)
        : {};
    this.enhancedUpsideSegmentData =
        masterData.enhancedUpsideSegmentData && masterData.enhancedUpsideSegmentData.length > 0
          ? masterData.enhancedUpsideSegmentData.find((x) => x.series === this.series)
          : {};
    this.items = Array.from(this.masterData.segments.map((x) => x.id)).map<
      Dictionary
    >((x: string) => ({ id: x, value: x }));

    this.indexSelection();

    //set default market scenetio to upwards
    this.formGroup.get("marketScenerio").setValue(this.marketScenerios[0].id);

    // set default enhanced upside to -110
    this.formGroup.get("enhancedUpside").setValue(this.enhancedUpsides[0].id)

    if (
      savedProfiles &&
      savedProfiles.length > 0 &&
      ((this.scsincomeService.customizationType === CUSTOMIZATION_TYPE.PROFILE &&
        this.scsincomeService.currentProfile) ||
        this.scsincomeService.customizationType === CUSTOMIZATION_TYPE.REPORT)
    ) {
      const profile = savedProfiles.find(
        (x) =>
          x.name ===
            (this.scsincomeService.customizationType == CUSTOMIZATION_TYPE.PROFILE
              ? this.scsincomeService.currentProfile
              : CUSTOMIZATION_TYPE.REPORT) &&
          x.currentSerie === this.scsincomeService.series
      );
      if (profile && profile.aboutSCSHowSioWorks) {
        const segment =
          this.scsincomeService.investorData.mode === INVESTOR_MODE.HOME &&
          this.scsincomeService.investorData.segment
            ? this.scsincomeService.investorData.segment
            : profile.aboutSCSHowSioWorks.segmentOption;

        this.formGroup.get("segment").setValue(segment);

        const index =
          this.scsincomeService.investorData.mode === INVESTOR_MODE.HOME &&
          this.scsincomeService.investorData.index
            ? this.scsincomeService.investorData.index
            : profile.aboutSCSHowSioWorks.index;
        this.formGroup.get("index").setValue(index);

        const duration =
          this.scsincomeService.investorData.mode === INVESTOR_MODE.HOME &&
          this.scsincomeService.investorData.duration
            ? this.scsincomeService.investorData.duration
            : profile.aboutSCSHowSioWorks.duration.replace(
                this.translateService.instant("howItsWorks.yr"),
                ""
              );

        this.formGroup.get("duration").setValue(duration);

        const protection =
          this.scsincomeService.investorData.mode === INVESTOR_MODE.HOME &&
          this.scsincomeService.investorData.protection
            ? this.scsincomeService.investorData.protection
            : profile.aboutSCSHowSioWorks.protection.replace("%", "");

        this.formGroup.get("protection").setValue(protection);
        this.segment = profile.aboutSCSHowSioWorks.segmentOption;
      } else {
        this.segment = this.items[0].id;
        this.formGroup
          .get("segment")
          .setValue(
            this.scsincomeService.investorData.segment
              ? this.scsincomeService.investorData.segment
              : this.segment
          );
        this.formGroup
          .get("index")
          .setValue(
            this.scsincomeService.investorData.index
              ? this.scsincomeService.investorData.index
              : this.indices[0].id
          );
        this.formGroup
          .get("duration")
          .setValue(
            this.scsincomeService.investorData.duration
              ? this.scsincomeService.investorData.duration
              : this.durations[0].id
          );
        this.formGroup
          .get("protection")
          .setValue(
            this.scsincomeService.investorData.protection
              ? this.scsincomeService.investorData.protection
              : this.protections[0].id
          );
      }
    } else {
      this.formGroup
        .get("segment")
        .setValue(
          this.scsincomeService.investorData.segment
            ? this.scsincomeService.investorData.segment
            : this.segment
        );
      this.formGroup
        .get("index")
        .setValue(
          this.scsincomeService.investorData.index
            ? this.scsincomeService.investorData.index
            : this.indices[0].id
        );
      this.formGroup
        .get("duration")
        .setValue(
          this.scsincomeService.investorData.duration
            ? this.scsincomeService.investorData.duration
            : this.durations[0].id
        );
      this.formGroup
        .get("protection")
        .setValue(
          this.scsincomeService.investorData.protection
            ? this.scsincomeService.investorData.protection
            : this.protections[0].id
        );
      this.segment = this.items[0].id;
    }
  }

  /***
   *  method to change drop down value in form
   ***/
  indexSelection(data?) {
    let segment =
      typeof this.formGroup.get("segment").value === "object"
        ? this.formGroup.get("segment").value.id
        : this.formGroup.get("segment").value;

    if (segment) {
      this.segment = segment;
    } else {
      this.segment = this.items[0].id;
      segment = this.items[0].id;
    }

    const segmentMaster = this.masterData.segments.find((x) => x.id === segment)
      .data;

    // indeces
    this.indices = Array.from(new Set(segmentMaster.map((x) => x.index))).map<
      Dictionary
    >((x: string) => ({ id: x, value: x }));

    if (
      data &&
      data[0].index &&
      this.indices.findIndex((x) => x.id === data[0].index) === -1
    ) {
      this.formGroup.get("index").setValue(this.indices[0].id);
    }

    // durations
    this.durations = Array.from(
      new Set(
        segmentMaster
          .filter(
            (x) =>
              x.index ==
              (data &&
              this.indices.findIndex((x) => x.id === data[1].index) !== -1
                ? data[1].index
                : this.indices[0].id)
          )
          .map((x: any) => x.duration)
      )
    ).map<Dictionary>((x: string) => ({
      id: x,
      value: x + " " +this.translateService.instant("howItsWorks.year"),
    }));

    if (
      data &&
      data[0].duration &&
      this.durations.findIndex((x) => x.id === data[0].duration) === -1
    ) {
      this.formGroup.get("duration").setValue(this.durations[0].id);
    }

    // protections
    this.protections = segmentMaster
      .filter(
        (x) =>
          x.index ==
            (data &&
            this.indices.findIndex((x) => x.id === data[1].index) !== -1
              ? data[1].index
              : this.indices[0].id) &&
          x.duration ==
            (data &&
            this.durations.findIndex((x) => x.id === data[1].duration) !== -1
              ? data[1].duration
              : this.durations[0].id)
      )[0]
      .buffers.map((x) => ({ id: x, value: x + "%" }));

    if (
      data &&
      data[0].protection &&
      this.protections.findIndex(
        (x) => x.id.toString() === data[0].protection.toString()
      ) === -1
    ) {
      this.formGroup
        .get("protection")
        .setValue(this.protections[0].id.toString());
    }

    // this is where we should populate the data, but we are doing this through a static array above. 
    // enhanced upside
    // this.enhancedUpsides = segmentMaster
    //   .filter(
    //     (x) =>
    //       x.index ==
    //         (data &&
    //         this.indices.findIndex((x) => x.id === data[1].index) !== -1
    //           ? data[1].index
    //           : this.indices[0].id) &&
    //       x.duration ==
    //         (data &&
    //         this.durations.findIndex((x) => x.id === data[1].duration) !== -1
    //           ? data[1].duration
    //           : this.durations[0].id)
    //   )[0]
    //   .enhancedUpside.map((x) => ({ id: x, value: x + "%" }));

    // if (
    //   data &&
    //   data[0].enhancedUpside &&
    //   this.enhancedUpsides.findIndex((x) => x.id === data[0].enhancedUpside) === -1
    // ) {
    //   this.formGroup.get("enhancedUpside").setValue(this.enhancedUpsides[0].id);
    // }
  }

  /***
   *  Get and prepare Bar Chart for how its works
   ***/
  prepareChart() {
    if (this.formGroup.valid && this.howItWorks) {
      
      const segment =
        typeof this.formGroup.get("segment").value === "object"
          ? this.formGroup.get("segment").value.id
          : this.formGroup.get("segment").value;

      const duration =
        this.formGroup.get("duration").value +
        this.translateService.instant("howItsWorks.yr");

      const buffer = this.formGroup.get("protection").value + "%";

      const index = this.formGroup.get("index").value;

      const enhancedUpside = this.formGroup.get("enhancedUpside").value + "%";

      let commission = 0;
      let additionalCharges;

      // TODO: Determine how this is populated. Where is the array of 4 coming from? This is not the correct way to do it, but it works. 
      // Ideally, we want to manipulate this array of objects, howItWorks.performance
      // console.log('this.howItWorks.performance')
      // console.log(this.howItWorks.performance);

      if (segment === SEGMENT_TYPE.STANDARD && this.howItWorks.performance !== undefined) {

        if((this.scsincomeService.series == SCSINCOME_SERIES.B || this.scsincomeService.series == SCSINCOME_SERIES.ADV) && (!this.flagService.featureFlags.isPrimerica && !this.flagService.featureFlags.isPlusGuard)) {
          let hydratedArray = this.scsincomeService.getDynamicStandardData(this.scsincomeService.dynamicData, segment, index, duration, buffer);

          console.log(this.scsincomeService.series)
          console.log('STANDARD - Hydrated array');
          console.log(hydratedArray);
  
          this.performanceData = {
            ...hydratedArray.find(
              (x) => x.id === duration && x.buffer === buffer && x.index === index 
            ),
          };

          console.log('STANDARD - FILTERED ARRAY')
          console.log(this.performanceData)
        }

        // TODO: Delete all dependencies for the .plist files
        else {
          this.performanceData = {
            ...this.howItWorks.performance.find(
              (x) => x.id === duration && x.buffer === buffer
            ),
          };
        }

      } else if (segment === SEGMENT_TYPE.ANNUAL_LOCK) {
        const marketTrend = this.formGroup.get("marketScenerio").value; 

        if((this.scsincomeService.series == SCSINCOME_SERIES.B || this.scsincomeService.series == SCSINCOME_SERIES.ADV) && (!this.flagService.featureFlags.isPrimerica && !this.flagService.featureFlags.isPlusGuard)) {
          let hydratedArray = this.scsincomeService.getDynamicAnnualLockData(this.scsincomeService.dynamicData, segment, index, duration, buffer, marketTrend);

          console.log('ANNUAL LOCK - Hydrated array')
          console.log(hydratedArray);
  
          this.performanceData = {
            ...hydratedArray.find(
              (x) => x.id === duration && x.buffer === buffer && x.index === index
            ),
          };

          console.log(this.performanceData)
        }

        // ELSE (SERIES B or any other series)
        else {
          if (marketTrend === MARKET_SCENERIO.UPWARD) {
            this.performanceData = this.annualSegmentData.upward;
          }
          else {
            this.performanceData = this.annualSegmentData.downward;
          }
        }

        //check for additional charges
        additionalCharges = this.performanceData.additionalCharges;
      } else if (segment === SEGMENT_TYPE.CHOICE) {
        commission = CHOICE_COMMISION; // Choice Commision

        this.performanceData = {
          ...this.howItWorks.performance.find(
            (x) => x.id === duration && x.buffer === buffer
          ),
        };

        //below value is hardcode for some exception //fix
        if (
          duration === "5" + this.translateService.instant("howItsWorks.yr") &&
          buffer === "-10%"
        ) {
          this.performanceData.performaceCapRate = 90;
          this.performanceData.max = 100;
          this.performanceData.performaceRate[0] = 75;
          this.performanceData.performaceRate[2] = 93;
        }
      } else if (segment === SEGMENT_TYPE.STEP_UP) {   

        // IF(B IS SELECTED - Pull data dynamically)
        if((this.scsincomeService.series == SCSINCOME_SERIES.B || this.scsincomeService.series == SCSINCOME_SERIES.ADV) && (!this.flagService.featureFlags.isPrimerica && !this.flagService.featureFlags.isPlusGuard)) {
          let hydratedArray = this.scsincomeService.getDynamicStepUpData(this.scsincomeService.dynamicData, segment, index, duration, buffer);

          console.log('STEP UP - Hydrated array')
          console.log(hydratedArray);
  
          this.performanceData = {
            ...hydratedArray.find(
              (x) => x.id === duration && x.buffer === buffer && x.index === index
            ),
          };

          console.log(this.performanceData)
        }
        // ELSE (NOT B, Pull from static JSON file)
        else {
          this.performanceData = this.stepUpSegmentData.performance; 
        }
        
      } else if (segment === SEGMENT_TYPE.ENHANCED_UPSIDE && (!this.flagService.featureFlags.isPrimerica && !this.flagService.featureFlags.isPlusGuard)) {
          // this.performanceData = this.enhancedUpsideSegmentData.performance; // update to read gene's api, call function called getDyanmicEnhancedUpsideSegmentData()'
  
          // dynamic for B 
          if((this.scsincomeService.series == SCSINCOME_SERIES.B || this.scsincomeService.series == SCSINCOME_SERIES.ADV)) {
            let hydratedArray = this.scsincomeService.getDynamicEnhancedUpsideData(this.scsincomeService.dynamicData, segment, index, duration, buffer, enhancedUpside);

            if(duration == '3yr') {
              this.enhancedUpsides = [
                {
                  id: '110',
                  value: '110%'
                },
                {
                  id: '125',
                  value: '125%'
                },
              ]
            }
            else if(duration == '1yr') {
              this.enhancedUpsides = [
                {
                  id: '125',
                  value: '125%'
                },
              ]
            }
  
            console.log('ENHANCED UPSIDE - Hydrated array')
            console.log(hydratedArray);
    
            this.performanceData = {
              ...hydratedArray.find(
                (x) => x.id === duration && x.buffer === buffer && x.index === index
              ),
            };

            console.log(this.performanceData)
          }
  
          // static
          else {
            if(enhancedUpside == '110%') {
              if(buffer == '-10%') {
                this.performanceData = this.enhancedUpside10Static.performance;
              }
              else if(buffer == '-15%') {
                this.performanceData = this.enhancedUpside15Static.performance;
              }
            }
            if(enhancedUpside == '125%') {
              if(buffer == '-10%') {
                this.performanceData = this.enhancedUpside10Static125.performance;
              }
              else if(buffer == '-15%') {
                this.performanceData = this.enhancedUpside15Static125.performance;
              }
            }
          }
        }
      

      else if (segment === SEGMENT_TYPE.DUAL_DIRECTION) {
        // UNCOMMENT THIS WHEN READY FOR DYNAMIC
        if((this.scsincomeService.series == SCSINCOME_SERIES.B || this.scsincomeService.series == SCSINCOME_SERIES.ADV)  && (!this.flagService.featureFlags.isPrimerica && !this.flagService.featureFlags.isPlusGuard)) {
          let hydratedArray = this.scsincomeService.getDynamicDualDirectionData(this.scsincomeService.dynamicData, segment, index, duration, buffer);

          console.log('DUAL DIRECTION - Hydrated array')
          console.log(hydratedArray);
  
          this.performanceData = {
            ...hydratedArray.find(
              (x) => x.id === duration && x.buffer === buffer && x.index === index
            ),
          };
        }

        // S&P, Russell, MSCI EAFE, NASDAQ 100 
        // 1 year, 3 year
        // -10%
        console.log(this.performanceData);

        let scenario1value = Math.abs(Math.round(this.performanceData.segmentRates[0]));
        let scenario2value = Math.abs(Math.round(this.performanceData.segmentRates[1]));
        let scenario3value = Math.abs(Math.round(this.performanceData.segmentRates[2]));

        // SCS Income - SP, 1 year, -15
        // if(scenario 1 and scenario 3 index performance are equal)
        if(scenario1value == scenario3value){
          // update scenario 3 values to -5 and +5
          this.performanceData.segmentRates[2] = -5;
        }
        // SCS PLUS, MSCI EAFE, 1 year, -15
        // if(scenario 3 index performance is greater than scenario 1 index performance)
        else if(scenario1value < scenario3value) {
          // update scenario 3 values to -5 and +5
          this.performanceData.segmentRates[2] = -5;
        }
        // if(scenario 3 index performance is greater than scenario 2 index performance)
        // else if(scenario2value < scenario3value) {
        //   // update scenario 3 values to -5 and +5
        //   this.performanceData.segmentRates[2] = -5;
        // }
      }
      // DUAL STEP UP
      else if (segment === SEGMENT_TYPE.DUAL_STEP_UP) {
        // IF(B IS SELECTED - Pull data dynamically)
        if((this.scsincomeService.series == SCSINCOME_SERIES.B || this.scsincomeService.series == SCSINCOME_SERIES.ADV) && (!this.flagService.featureFlags.isPrimerica && !this.flagService.featureFlags.isPlusGuard)) {
          let hydratedArray = this.scsincomeService.getDynamicDualStepUpData(this.scsincomeService.dynamicData, segment, index, duration, buffer);

          console.log('DUAL STEP UP - Hydrated array')
          console.log(hydratedArray);
  
          this.performanceData = {
            ...hydratedArray.find(
              (x) => x.id === duration && x.buffer === buffer && x.index === index
            ),
          };

          console.log(this.performanceData)
        }
      }
      // LOSS LIMITER 90
      else if (segment === SEGMENT_TYPE.LOSS_LIMITER_90) {
        // IF(B IS SELECTED - Pull data dynamically)
        if((this.scsincomeService.series == SCSINCOME_SERIES.B || this.scsincomeService.series == SCSINCOME_SERIES.ADV) && (!this.flagService.featureFlags.isPrimerica && !this.flagService.featureFlags.isPlusGuard)) {
          let hydratedArray = this.scsincomeService.getDynamicLossLimiter90Data(this.scsincomeService.dynamicData, segment, index, duration, buffer);

          console.log('DUAL STEP UP - Hydrated array')
          console.log(hydratedArray);
  
          this.performanceData = {
            ...hydratedArray.find(
              (x) => x.id === duration && x.buffer === buffer && x.index === index
            ),
          };

          console.log(this.performanceData)
        }
      }
      // LOSS LIMITER 95
      else if (segment === SEGMENT_TYPE.LOSS_LIMITER_95) {
        // IF(B IS SELECTED - Pull data dynamically)
        if((this.scsincomeService.series == SCSINCOME_SERIES.B || this.scsincomeService.series == SCSINCOME_SERIES.ADV) && (!this.flagService.featureFlags.isPrimerica && !this.flagService.featureFlags.isPlusGuard)) {
          let hydratedArray = this.scsincomeService.getDynamicLossLimiter95Data(this.scsincomeService.dynamicData, segment, index, duration, buffer);

          console.log('DUAL STEP UP - Hydrated array')
          console.log(hydratedArray);
  
          this.performanceData = {
            ...hydratedArray.find(
              (x) => x.id === duration && x.buffer === buffer && x.index === index
            ),
          };

          console.log(this.performanceData)
        }
      }

      const details = this.scsincomeService.parsePerformanceData(
        this.performanceData,
        segment,
        commission
      );

      //for line axis
      if (segment === SEGMENT_TYPE.ANNUAL_LOCK) {
        console.log('XXX1')
        if (details && details.data && details.data.axis) {
           details.data.axis.push(this.annualSegmentData.axis);
        }
        //also deduct any additional charges
        let finalReturn;
        if (additionalCharges) {
          finalReturn =
            details.data.line.data[details.data.line.data.length - 1].value -
            ((this.scsincomeService.getDollarAmount() * additionalCharges) / 100) *
              details.data.line.data.length;
          details.data.line.data[
            details.data.line.data.length - 1
          ].value = finalReturn;
        } else {
          finalReturn =
            details.data.line.data[details.data.line.data.length - 1].value;
        }

        this.annualRate = this.translateService
          .instant("howItsWorks.annualRate")
          .replace("$$YEAR$$", details.data.line.data.length.toString())
          .replace("$$BUFFER$$", buffer)
          .replace(
            "$$RETURN_PERCENTAGE$$",
            (
              ((finalReturn - this.scsincomeService.getDollarAmount()) /
                this.scsincomeService.getDollarAmount()) *
              100
            ).toFixed(2)
          )
          .replace("$$RETURN$$", finalReturn.toLocaleString().toString())
          .replace("$$DBR$$", this.performanceData.additionalCharges);

        details.data.description =
          this.annualRate +
          "<br/><br>" +
          this.translateService.instant("howItsWorks.howAnnualRateCalculated");
      }

      //add description
      details.data.showDescription = true;
      details.data.showLineToolTip = false;

      this.toolFooterTextPerformanceCapRate = this.translateService
      .instant("howItsWorks.toolFooterTextPerformanceCapRate")
      .replace("$$PERFORMANCE_CAP_RATE$$", this.performanceData.performaceCapRate)

      //set heading
      this.toolHeaderText = this.translateService
        .instant("howItsWorks.toolHeaderText")
        .replace("$$YEAR$$", this.formGroup.get("duration").value)
        .replace("$$PERFORMANCE_CAP_RATE$$", this.performanceData.performaceCapRate)
        
        .replace("$$SEGMENT_BUFFER$$", this.formGroup.get("protection").value);

        if(segment === SEGMENT_TYPE.ENHANCED_UPSIDE) {
          if(this.series == SCSINCOME_SERIES.B) {

            if(enhancedUpside == '110%') {
              this.toolHeaderText = this.translateService
              .instant("howItsWorks.toolHeaderTextEnhancedUpside110")
              .replace("$$YEAR$$", this.formGroup.get("duration").value)
              .replace("$$PERFORMANCE_CAP_RATE$$", this.performanceData.performaceCapRate)
              .replace("$$SEGMENT_BUFFER$$", this.formGroup.get("protection").value);
            }
            else if(enhancedUpside == '125%') {
              this.toolHeaderText = this.translateService
              .instant("howItsWorks.toolHeaderTextEnhancedUpside")
              .replace("$$YEAR$$", this.formGroup.get("duration").value)
              .replace("$$PERFORMANCE_CAP_RATE$$", this.performanceData.performaceCapRate)
              .replace("$$SEGMENT_BUFFER$$", this.formGroup.get("protection").value);
            }
          }
  
          else { 
            this.toolHeaderText = this.translateService
            .instant("howItsWorks.toolHeaderTextEnhancedUpside")
            .replace("$$YEAR$$", this.formGroup.get("duration").value) 
            .replace("$$PERFORMANCE_CAP_RATE$$", this.performanceData.performaceCapRate)  
            .replace("$$SEGMENT_BUFFER$$", this.formGroup.get("protection").value);
  
          }
  
        }

      if(this.series == SCSINCOME_SERIES.B && segment === SEGMENT_TYPE.STANDARD && this.performanceData.performaceCapRate == 1000) {
        this.toolHeaderText = this.translateService
        .instant("howItsWorks.toolHeaderTextUncapped")
        .replace("$$YEAR$$", this.formGroup.get("duration").value)
        .replace("$$PERFORMANCE_CAP_RATE$$", 'Uncapped Performance Cap Rate')
        .replace("$$SEGMENT_BUFFER$$", this.formGroup.get("protection").value);
      }

      if(this.series == SCSINCOME_SERIES.ADV && segment === SEGMENT_TYPE.STANDARD && this.performanceData.performaceCapRate == 1000) {
        this.toolHeaderText = this.translateService
        .instant("howItsWorks.toolHeaderTextUncapped")
        .replace("$$YEAR$$", this.formGroup.get("duration").value)
        .replace("$$PERFORMANCE_CAP_RATE$$", 'Uncapped Performance Cap Rate')
        .replace("$$SEGMENT_BUFFER$$", this.formGroup.get("protection").value);
      }

      this.chartData = details.data;
      this.chartDetails = details.details;

      //saving for session
      this.saveProfile();
    }
  }

  segmentChanged(event) {
    this.formGroup.get("marketScenerio").setValue(event.details.value);
  }

  /***
   * back button
   ***/
  backButton() {
    this.utilityService.navigate(ROUTE_PATH.SCSINCOME.TOOLS.HOW_SIO_WORKS);
  }

  /***
   *  Load default options
   ***/
  loadDefaultsOptions() {
    this.formGroup.get("segment").setValue(this.items[0].id);
    this.formGroup.get("index").setValue(this.indices[0].id);
    this.formGroup.get("duration").setValue(this.durations[0].id);
    this.formGroup.get("protection").setValue(this.protections[0].id);
    this.formGroup.get("enhancedUpside").setValue(this.enhancedUpsides[0].id);
  }

  /***
   *  Load custom profile
   ***/
  loadProfile() {
    this.scsincomeService.getProfile().subscribe((profiles: Profile[]) => {
      if (profiles && profiles.length > 0) {
        //get custom profile
        const profile: Profile = profiles.find(
          (x) =>
            x.name === this.scsincomeService.currentProfile &&
            x.currentSerie === this.scsincomeService.series
        );

        //set custom profile
        if (profile && profile.aboutSCSHowSioWorks) {
          this.formGroup
            .get("segment")
            .setValue(profile.aboutSCSHowSioWorks.segmentOption);
          this.formGroup
            .get("index")
            .setValue(profile.aboutSCSHowSioWorks.index);
          this.formGroup
            .get("duration")
            .setValue(parseInt(profile.aboutSCSHowSioWorks.duration));
          this.formGroup
            .get("protection")
            .setValue(parseInt(profile.aboutSCSHowSioWorks.protection));
          // this.formGroup
          //   .get("enhancedUpside")
          //   .setValue(parseInt(profile.aboutSCSHowSioWorks.enhancedUpsides));
        } else {
          this.loadDefaultsOptions();
        }
      } else {
        //set default profile if not data
        this.loadDefaultsOptions();
      }
    });
  }

  /***
   *  Save Profile
   ***/
  saveProfile(mode?: string): void {
    if (this.formGroup.valid) {
      const segment =
        typeof this.formGroup.get("segment").value === "object"
          ? this.formGroup.get("segment").value.id
          : this.formGroup.get("segment").value;

      const duration = this.formGroup.get("duration").value;
      const buffer = this.formGroup.get("protection").value;
      const index = this.formGroup.get("index").value;
      const enhancedUpside = this.formGroup.get("enhancedUpside").value;

      this.scsincomeService.investorData.segment = segment;
      this.scsincomeService.investorData.duration = duration;
      this.scsincomeService.investorData.protection = buffer;
      this.scsincomeService.investorData.index = index;
      // this.scsincomeService.investorData.enhancedUpside = enhancedUpside;

      const hypothetical = new SCSIncomeHowSioWorks();
      hypothetical.segmentBuffer = buffer;
      hypothetical.segmentOption = segment;
      hypothetical.duration = duration;
      hypothetical.index = index;
      hypothetical.duration =
        duration + this.translateService.instant("howItsWorks.yr");
      hypothetical.protection = buffer + "%";
      if(enhancedUpside) {
        hypothetical.enhancedUpside = enhancedUpside;
      }

      // IF (ANNUAL LOCK AND SERIES B - Pass updated values to back-end API)
      if(segment == SEGMENT_TYPE.ANNUAL_LOCK && (this.scsincomeService.series == SCSINCOME_SERIES.B || this.scsincomeService.series == SCSINCOME_SERIES.ADV)) {
        hypothetical.upMarketBars = this.scsincomeService.upMarketBars;
        hypothetical.downMarketBars = this.scsincomeService.downMarketBars;
      }

      // this.chartData.axis[0] controls the percents on the left hand side of the graph.
      // the values axis.min, axis.max, and axis.interval within howItsWorks.json control the dollar values on the right hand side of the graph. 
      if(segment == SEGMENT_TYPE.ANNUAL_LOCK && this.isNational) {
        this.chartData.axis[0].interval = 10;
        this.chartData.axis[0].min = -20;
        this.chartData.axis[0].max = 100;
      }

      if(this.chartData && this.chartData.axis){
      // console.log('XXX2')
      hypothetical.scaleMin = this.chartData.axis[0].min;
      // console.log('XXX4')
      hypothetical.scaleMax = this.chartData.axis[0].max;
      hypothetical.scaleStep = this.chartData.axis[0].interval;
      let id = 0;
      this.chartData.bar.forEach((element, index) => {
        // add bar
        let i = 1; //reverse the bar is report is demanding same
        element.data.forEach((bar) => {
          hypothetical.bars[id] = element.data[i].value;
          id++; // increase id for each bar
          i--; //reverse for 2 count
        });

        // For dual direction, target the specific segment rate here of the 7th bar with the correct segment rate. Otherwise, we pass it the wrong value
        if(segment == SEGMENT_TYPE.DUAL_DIRECTION && this.isNational) {
          hypothetical.bars[1] = this.performanceData.segmentRates[0];
          hypothetical.bars[3] = this.performanceData.segmentRates[1];
          hypothetical.bars[5] = this.performanceData.segmentRates[2];
          hypothetical.bars[7] = this.performanceData.segmentRates[3];
        }

        //add caps
        hypothetical.performanceCap = element.lines[0].value;
        hypothetical.segmentBuffer = element.lines[1].value;
        hypothetical.commissions[index] = element.data[0].topValue;
        hypothetical.scenarios[index] = true;
      });
    }
      hypothetical.scaleHeight = 275; //fix
      hypothetical.scaleHeight = 275; //fix
      hypothetical.segmentValue = this.scsincomeService.getDollarAmount();

      if (
        this.isInitalLoadCompleted &&
        this.scsincomeService.customizationType ===
          CUSTOMIZATION_TYPE.INVESTOR_PROFILE
      ) {
        this.coreService.profile.next({
          name: "",
          type: CUSTOMIZATION_TYPE.REPORT,
          profile: new Profile(),
        });
        this.scsincomeService.customizationType = CUSTOMIZATION_TYPE.REPORT;
      }

      //save for profile
      if (this.scsincomeService.customizationType === CUSTOMIZATION_TYPE.PROFILE) {
        this.scsincomeService.storeProfile(CUSTOM_PROFILE.HOW_IT_WORKS, hypothetical);
      } else {
        //save for report
        this.scsincomeService.report.aboutSCSHowSioWorks = hypothetical;
        this.scsincomeService.storeProfile(
          CUSTOM_PROFILE.HOW_IT_WORKS,
          hypothetical,
          true
        );
      }

      if (mode && mode == CUSTOMIZATION_TYPE.PROFILE) {
        this.utilityService.presentWithoutTitleModal(ModalPopupComponent);
      } else if (mode && mode == CUSTOMIZATION_TYPE.REPORT) {
        this.utilityService.navigate(ROUTE_PATH.SCSINCOME.TOOLS.CREATE_REPORT);
      }
    }
  }

  openModal() {
    this.scsincomeService.openModal(SioPopupComponent, "modal-css");
  }
}
