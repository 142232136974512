import { ErrorHandler, Injector, OnInit } from "@angular/core";
import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { TranslateService } from "@ngx-translate/core";
import { Globalization } from "@ionic-native/globalization/ngx";
import { UtilityService } from "./core/services/utility.service";
import { IonicStorageService } from "./core/services/ionic-storage.service";
import { CoreService } from "./core/services/core.service";
import { LANGUAGE, } from "./constants/constants";
import { Network } from "@ionic-native/network/ngx";
import { Router } from "@angular/router";
import { TealiumUtagService } from './tealium/utag.service';
import { environment } from "../environments/environment";
var AppComponent = /** @class */ (function () {
    function AppComponent(platform, splashScreen, statusBar, translate, globalization, storageService, coreService, network, router, util, tealium) {
        this.platform = platform;
        this.splashScreen = splashScreen;
        this.statusBar = statusBar;
        this.translate = translate;
        this.globalization = globalization;
        this.storageService = storageService;
        this.coreService = coreService;
        this.network = network;
        this.router = router;
        this.util = util;
        this.tealium = tealium;
        this.tealium.setConfig({
            account: 'equitable',
            profile: 'ir-web-ipad-apps',
            environment: environment.tealiumEnvironment,
        });
        this.initializeApp();
    }
    AppComponent.prototype.ngOnInit = function () {
        // The tealium.view function will call the tealium.track function with 'view' as first param
        // Most tags support the 'view' event and many analytics tags also support the 'link' event
        // this.tealium.view({event_name: 'init'});
        this.tealium.track('view', { 'event_name': 'init' });
    };
    AppComponent.prototype.initializeApp = function () {
        var _this = this;
        this.platform.ready().then(function () {
            _this.translate.setDefaultLang(LANGUAGE.EN);
            _this.statusBar.styleDefault();
            _this.splashScreen.hide();
            _this.getDeviceLanguage();
            _this.setHamburgermenu();
            //load main data
            if ((_this.platform.is("cordova") &&
                _this.network.type !== _this.network.Connection.NONE) ||
                navigator.onLine) {
                _this.storageService.loadMainData();
            }
        });
    };
    // Method to get HamburgerMenu data using translateservice
    AppComponent.prototype.setHamburgermenu = function () {
        var _this = this;
        this.coreService.getHamburgermenu().subscribe(function (menu) {
            _this.menu = menu;
            //console.log(this.menu);
        });
    };
    AppComponent.prototype.getDeviceLanguage = function () {
        var _this = this;
        this.globalization
            .getPreferredLanguage()
            .then(function (res) {
            try {
                if (res && JSON.parse(JSON.stringify(res)).value) {
                    var lang = JSON.parse(JSON.stringify(res)).value.split("-")[0];
                    if (lang !== null || lang !== undefined || lang !== "") {
                        _this.deviceLanguage = lang;
                        _this.translate.use(_this.deviceLanguage);
                    }
                    else {
                        _this.translate.use(LANGUAGE.EN);
                    }
                }
            }
            catch (e) {
                _this.translate.use(LANGUAGE.EN);
            }
        })
            .catch(function (e) {
            e;
            _this.translate.use(LANGUAGE.EN);
        });
    };
    AppComponent.prototype.scrollTop = function () {
        if (navigator.userAgent.search("Firefox") >= 0) {
            // eslint-disable-next-line angular/document-service
            document.querySelectorAll("ion-content").forEach(function (content) {
                content.scrollToTop();
            });
        }
    };
    return AppComponent;
}());
export { AppComponent };
var GlobalErrorHandler = /** @class */ (function () {
    function GlobalErrorHandler(injector) {
        this.injector = injector;
    }
    Object.defineProperty(GlobalErrorHandler.prototype, "router", {
        get: function () {
            console.log(this.injector.get(Router));
            return this.injector.get(Router);
        },
        enumerable: true,
        configurable: true
    });
    GlobalErrorHandler.prototype.handleError = function (error) {
        console.log(error);
        if (error &&
            error.message &&
            error.message.indexOf("Error: Cannot match any routes. URL Segment:") !==
                -1) {
            // eslint-disable-next-line angular/window-service
            this.router.navigate(["404"], { skipLocationChange: true });
        }
    };
    return GlobalErrorHandler;
}());
export { GlobalErrorHandler };
