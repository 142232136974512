<ion-content>
  <div class="container">
    <div *ngIf="quesions">
      <app-questionnaire-one
        *ngIf="step === 1"
        [step]="step"
        (selectedOption)="optionSelected($event)"
        (backButtonClicked)="backButtonClicked($event)"
      ></app-questionnaire-one>
      <app-questionnaire-two
        [step]="step"
        *ngIf="step === 2"
        (selectedOption)="optionSelected($event)"
        (backButtonClicked)="backButtonClicked($event)"
      ></app-questionnaire-two>
      <app-questionnaire-three
        *ngIf="step === 3"
        [step]="step"
        (selectedOption)="optionSelected($event)"
        (backButtonClicked)="backButtonClicked($event)"
      ></app-questionnaire-three>
    </div>
  </div>
  <app-questionnaire-result
    *ngIf="result"
    (retakeQuiz)="retakeQuiz()"
  ></app-questionnaire-result>
  <app-footer
    isBlueFooterVisible="true"
    selectedProduct="questionnaire"
  ></app-footer>
</ion-content>
