<ion-content>
  <div class="legacy-content">
    <equitable-sub-header
      title="{{ 'preserveLegacy.pageHeader' | translate }}"
      backLinkText="{{ 'preserveLegacy.backButtonTextIncome' | translate }}"
      (backClick)="backButton()"
    ></equitable-sub-header>
    <ion-card class="tools-card" *ngIf="activeTab">
      <ion-segment
        mode="md"
        class="segment-class"
        (ionChange)="segmentChanged($event.detail.value)"
      >
      
        <ion-segment-button
          *ngFor="let item of segments"
          mode="md"
          class="segment-button-class"
          [value]="item"
          [checked]="activeTab === item"
        >
    <ion-label class="segment-heading {{ item }}"
            >{{ "preserveLegacy." + item + ".title-premium" | translate }}
          </ion-label>
        </ion-segment-button>
      </ion-segment>
      <app-preserve-legacy-tools
        [activeTab]="activeTab"
      ></app-preserve-legacy-tools>
    </ion-card>
  </div>
  <app-footer
    isBlueFooterVisible="true"
    isWhiteFooterVisible="true"
    selectedProduct="scsincome"
  ></app-footer>
</ion-content>
