import { DiversificationComponent } from "./components/diversification/diversification.component";
import { ProtectIncomeComponent } from "./components/protect-income/protect-income.component";
import { Routes } from "@angular/router";
import { DiscoverComponent } from "./components/discover/discover.component";
import { PreserveLegacyComponent } from "./components/preserve-legacy/preserve-legacy.component";
import { RcAdvancedToolResourcesDashboardComponent } from "./components/rc-advanced-tool-resources-dashboard/rc-advanced-tool-resources-dashboard.component";
import { BuildWealthComponent } from "./components/build-wealth/build-wealth.component";
import { InvestTaxSmartComponent } from "./components/invest-tax-smart/invest-tax-smart.component";
import { ROUTE_PATH, PRODUCT_TYPE } from "../constants/constants";
import { ReportRcComponent } from "./components/report-rc/report-rc.component";
import { FeatureFlagGuard } from '../ffguard';
var ɵ0 = {
    flags: PRODUCT_TYPE.RC
}, ɵ1 = {
    flags: PRODUCT_TYPE.RC
}, ɵ2 = {
    flags: PRODUCT_TYPE.RC
}, ɵ3 = {
    flags: PRODUCT_TYPE.RC
}, ɵ4 = {
    flags: PRODUCT_TYPE.RC
}, ɵ5 = {
    flags: PRODUCT_TYPE.RC
};
var routes = [
    {
        path: "",
        pathMatch: "full",
        redirectTo: "/" + ROUTE_PATH.RC.DISCOVER
    },
    {
        path: ROUTE_PATH.RC.DISCOVER,
        component: DiscoverComponent,
        canActivate: [FeatureFlagGuard],
        data: ɵ0,
    },
    {
        path: ROUTE_PATH.RC.TOOLS.BUILD_WEALTH,
        component: BuildWealthComponent,
        children: [
            {
                path: "",
                pathMatch: "full",
                redirectTo: "/" +
                    ROUTE_PATH.RC.TOOLS.BUILD_WEALTH +
                    "/" +
                    ROUTE_PATH.RC.TOOLS.DIVERSIFY
            },
            {
                path: ROUTE_PATH.RC.TOOLS.INVEST_TAX,
                component: InvestTaxSmartComponent
            },
            {
                path: ROUTE_PATH.RC.TOOLS.DIVERSIFY,
                component: DiversificationComponent
            }
        ],
        canActivate: [FeatureFlagGuard],
        data: ɵ1,
    },
    {
        path: ROUTE_PATH.RC.TOOLS.PROTECT_INCOME,
        component: ProtectIncomeComponent,
        canActivate: [FeatureFlagGuard],
        data: ɵ2,
    },
    {
        path: ROUTE_PATH.RC.TOOLS.PRESERVE_LEGACY,
        component: PreserveLegacyComponent,
        canActivate: [FeatureFlagGuard],
        data: ɵ3,
    },
    {
        path: ROUTE_PATH.RC.TOOLS.HOME,
        component: RcAdvancedToolResourcesDashboardComponent,
        canActivate: [FeatureFlagGuard],
        data: ɵ4,
    },
    {
        path: ROUTE_PATH.RC.TOOLS.CREATE_REPORT,
        component: ReportRcComponent,
        canActivate: [FeatureFlagGuard],
        data: ɵ5,
    }
];
var RcRoutingModule = /** @class */ (function () {
    function RcRoutingModule() {
    }
    return RcRoutingModule;
}());
export { RcRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
