import { Routes } from "@angular/router";
var ɵ0 = function () { return import("./scsincome/scsincome.module.ngfactory").then(function (m) { return m.ScsIncomeModuleNgFactory; }); }, ɵ1 = function () { return import("./scs/scs.module.ngfactory").then(function (m) { return m.ScsModuleNgFactory; }); }, ɵ2 = function () { return import("./core/core.module.ngfactory").then(function (m) { return m.CoreModuleNgFactory; }); }, ɵ3 = function () { return import("./rc/rc.module.ngfactory").then(function (m) { return m.RcModuleNgFactory; }); }, ɵ4 = function () { return import("./ie/ie.module.ngfactory").then(function (m) { return m.IeModuleNgFactory; }); };
var routes = [
    {
        path: "scsincome",
        loadChildren: ɵ0,
    },
    {
        path: "scs",
        loadChildren: ɵ1,
    },
    {
        path: "home",
        loadChildren: ɵ2,
    },
    {
        path: "rc",
        loadChildren: ɵ3,
    },
    {
        path: "ie",
        loadChildren: ɵ4,
    },
    {
        path: "",
        redirectTo: "/home",
        pathMatch: "full",
    },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
