import * as tslib_1 from "tslib";
import { OnInit, AfterViewChecked } from "@angular/core";
import { FormControl, Validators, FormBuilder, } from "@angular/forms";
import { ScsIncomeService } from "../../services/scsincome.service";
import { SEGMENT_TYPE, MARKET_SCENERIO, SCSINCOME_SERIES, CHOICE_COMMISION, CUSTOMIZATION_TYPE, CUSTOM_PROFILE, ROUTE_PATH, INVESTOR_MODE, } from "../../../constants/constants";
import { UtilityService } from "../../../core/services/utility.service";
import { pairwise, distinctUntilChanged } from "rxjs/operators";
import { Profile, SCSIncomeHowSioWorks, } from "../../../core/models/app.models";
import { TranslateService } from "@ngx-translate/core";
import { Theme, ButtonType, Orientation, LinkIcon, } from "equitable-component-library";
import { SioPopupComponent } from "../sio-popup/sio-popup.component";
import { ModalPopupComponent } from "../../../core/components/modal-popup/modal-popup.component";
import { CoreService } from "../../../core/services/core.service";
import { FeatureFlagService } from "./../../../core/services/feature-flag.service";
var HowSioWorksComponent = /** @class */ (function () {
    /***
     *  Constructor for how its works
     ***/
    function HowSioWorksComponent(formBuilder, scsincomeService, utilityService, translateService, coreService, flagService) {
        this.formBuilder = formBuilder;
        this.scsincomeService = scsincomeService;
        this.utilityService = utilityService;
        this.translateService = translateService;
        this.coreService = coreService;
        this.flagService = flagService;
        this.CUSTOMIZATION_TYPE = CUSTOMIZATION_TYPE;
        this.orientation = Orientation.Right;
        this.type = Theme.Dark;
        this.btnSaveTheme = Theme.Light;
        this.btnSaveType = ButtonType.Primary;
        this.linkOrientation = Orientation.Left;
        this.linkIcon = LinkIcon.LeftIcon;
        this.linkRightIcon = LinkIcon.RightIcon;
        this.SEGMENT_TYPE = SEGMENT_TYPE;
        this.indices = [];
        this.durations = [];
        this.protections = [];
        this.enhancedUpsides = [];
        this.items = [];
        this.masterData = {};
        this.chartDetails = [];
        this.toolHeaderText = "";
        this.toolFooterTextPerformanceCapRate = "";
        this.backButtonSeries = this.scsincomeService.series == SCSINCOME_SERIES.B ? SCSINCOME_SERIES.ADV + "" : "";
        this.showInfo = true;
        this.isInitalLoadCompleted = false;
        this.isDynamicChart = true;
        this.minValue = 5000;
        this.maxValue = 50000000;
    }
    /***
     *  Page Load for how its works
     ***/
    HowSioWorksComponent.prototype.ngOnInit = function () {
        //set series
        this.series = this.scsincomeService.series;
        this.customizationType = this.scsincomeService.customizationType;
        //build form
        this.buildForm();
        //get data for the page
        this.getHowItsWorksData();
        this.isNotBGuard = !this.flagService.featureFlags.isPlusGuard;
        this.isNational = this.flagService.featureFlags.isNational;
        this.isPlusGuard = this.flagService.featureFlags.isPlusGuard;
        this.isPrimerica = this.flagService.featureFlags.isPrimerica;
        // this is how we can listen for form changes. we should not need to do this. 
        //  this.formGroup.get('dynamicDollarAmount').valueChanges.subscribe(val => {
        //   const formattedMessage = `dollarInputValue is ${val}.`;
        //   console.log(formattedMessage);
        // });
        // get the dollar amount initially
        this.dollarInputValue = this.scsincomeService.getDollarAmount();
    };
    //page load for ionic
    HowSioWorksComponent.prototype.ionViewWillEnter = function () {
        if (this.scsincomeService.series == SCSINCOME_SERIES.B) {
            this.backLink = this.translateService.instant("howItsWorks.backIncome");
            console.log("BONLY");
        }
        else if (this.scsincomeService.series == SCSINCOME_SERIES.ADV) {
            this.backLink = this.translateService.instant("howItsWorks.backIncome");
            console.log("ADVONLY");
        }
        else {
            this.backLink = this.translateService.instant("sioFrontPage.backIncome");
        }
    };
    //check content fully loaded
    HowSioWorksComponent.prototype.ngAfterViewChecked = function () {
        if (this.formGroup.get("segment").value &&
            this.formGroup.get("index").value &&
            this.formGroup.get("duration").value &&
            this.formGroup.get("protection").value) {
            this.isInitalLoadCompleted = true;
        }
        else if (this.scsincomeService.series == SCSINCOME_SERIES.ADV &&
            this.formGroup.get("segment").value &&
            this.formGroup.get("index").value &&
            this.formGroup.get("duration").value &&
            this.formGroup.get("protection").value &&
            this.formGroup.get("enhancedUpside").value) {
            this.isInitalLoadCompleted = true;
        }
    };
    // TODO: try putting the subscribe function in this file for dynamic data. 
    /***
     *  Create form for how its works
     ***/
    HowSioWorksComponent.prototype.getHowItsWorksData = function () {
        var _this = this;
        this.utilityService.showLoader();
        this.scsincomeService.getHowitWorksData(this.series).subscribe(function (data) {
            _this.utilityService.hideLoader();
            if (data[1] && data[0].body) {
                _this.howItWorks = data[1];
                _this.getMasterData(data[0].body, data[2]);
            }
        });
    };
    HowSioWorksComponent.prototype.isValid = function () {
        return this.dollarInputValue >= this.minValue && this.dollarInputValue <= this.maxValue && this.dollarInputValue % 1 === 0;
    };
    HowSioWorksComponent.prototype.onSubmitOfDollarAmount = function () {
        this.scsincomeService.setDollarAmount(this.dollarInputValue);
        this.prepareChart();
    };
    /***
     *  Create form for how its works
     ***/
    HowSioWorksComponent.prototype.buildForm = function () {
        var _this = this;
        //create form
        this.formGroup = this.formBuilder.group({
            segment: new FormControl({ value: "", disabled: false }, Validators.required),
            index: new FormControl({ value: "", disabled: false }, Validators.required),
            duration: new FormControl({ value: "", disabled: false }, Validators.required),
            protection: new FormControl({ value: "", disabled: false }, Validators.required),
            enhancedUpside: new FormControl({ value: "", disabled: false }, Validators.required),
            dynamicDollarAmount: new FormControl({ value: this.scsincomeService.getDollarAmount(), disabled: false }, Validators.required),
            marketScenerio: new FormControl({ value: "", disabled: true }, Validators.required),
        });
        //Form on changes
        this.formGroup.valueChanges
            .pipe(distinctUntilChanged(), pairwise())
            .subscribe(function (data) {
            if (data[1].segment &&
                data[1].index &&
                data[1].duration &&
                data[1].protection) {
                data[1].segment =
                    typeof data[1].segment === "object"
                        ? data[1].segment.id
                        : data[1].segment;
                _this.indexSelection(data);
                // IF the segment type is dual direction for primerica or Bguard, we want to display a static image. 
                if (_this.segment.toLowerCase() == SEGMENT_TYPE.DUAL_DIRECTION.toLowerCase() && !_this.isNational) {
                    _this.isDynamicChart = false;
                    _this.saveProfile();
                }
                else {
                    _this.isDynamicChart = true;
                    _this.prepareChart();
                    _this.saveProfile();
                }
            }
        });
    };
    /***
     *  get master data for how its works
     ***/
    HowSioWorksComponent.prototype.getMasterData = function (masterData, savedProfiles) {
        var _this = this;
        //get market scenerio master data
        this.translateService
            .get("howItsWorks.marketScenerios")
            .subscribe(function (marketScenerios) {
            _this.marketScenerios = [
                {
                    id: MARKET_SCENERIO.UPWARD,
                    value: marketScenerios.upwards,
                },
                {
                    id: MARKET_SCENERIO.DOWNWARD,
                    value: marketScenerios.downwards,
                },
            ];
        });
        if (this.scsincomeService.series == SCSINCOME_SERIES.B) {
            this.enhancedUpsides = [
                {
                    id: '125',
                    value: '125%'
                },
            ];
        }
        //get all drop down master data for all series
        // TODO: Remove these instances as needed. 
        this.masterData = masterData.series.find(function (x) { return x.name === _this.series; });
        this.annualSegmentData =
            masterData.annualSegmentData && masterData.annualSegmentData.length > 0
                ? masterData.annualSegmentData.find(function (x) { return x.series === _this.series; })
                : {};
        this.stepUpSegmentData =
            masterData.stepUpSegmentData && masterData.stepUpSegmentData.length > 0
                ? masterData.stepUpSegmentData.find(function (x) { return x.series === _this.series; })
                : {};
        // enhanced upside
        this.enhancedUpside10Static =
            masterData.enhancedUpside10Static && masterData.enhancedUpside10Static.length > 0
                ? masterData.enhancedUpside10Static.find(function (x) { return x.series === _this.series; })
                : {};
        this.enhancedUpside15Static =
            masterData.enhancedUpside15Static && masterData.enhancedUpside15Static.length > 0
                ? masterData.enhancedUpside15Static.find(function (x) { return x.series === _this.series; })
                : {};
        this.enhancedUpside10Static125 =
            masterData.enhancedUpside10Static125 && masterData.enhancedUpside10Static125.length > 0
                ? masterData.enhancedUpside10Static125.find(function (x) { return x.series === _this.series; })
                : {};
        this.enhancedUpside15Static125 =
            masterData.enhancedUpside15Static125 && masterData.enhancedUpside15Static125.length > 0
                ? masterData.enhancedUpside15Static125.find(function (x) { return x.series === _this.series; })
                : {};
        this.enhancedUpsideSegmentData =
            masterData.enhancedUpsideSegmentData && masterData.enhancedUpsideSegmentData.length > 0
                ? masterData.enhancedUpsideSegmentData.find(function (x) { return x.series === _this.series; })
                : {};
        this.items = Array.from(this.masterData.segments.map(function (x) { return x.id; })).map(function (x) { return ({ id: x, value: x }); });
        this.indexSelection();
        //set default market scenetio to upwards
        this.formGroup.get("marketScenerio").setValue(this.marketScenerios[0].id);
        // set default enhanced upside to -110
        this.formGroup.get("enhancedUpside").setValue(this.enhancedUpsides[0].id);
        if (savedProfiles &&
            savedProfiles.length > 0 &&
            ((this.scsincomeService.customizationType === CUSTOMIZATION_TYPE.PROFILE &&
                this.scsincomeService.currentProfile) ||
                this.scsincomeService.customizationType === CUSTOMIZATION_TYPE.REPORT)) {
            var profile = savedProfiles.find(function (x) {
                return x.name ===
                    (_this.scsincomeService.customizationType == CUSTOMIZATION_TYPE.PROFILE
                        ? _this.scsincomeService.currentProfile
                        : CUSTOMIZATION_TYPE.REPORT) &&
                    x.currentSerie === _this.scsincomeService.series;
            });
            if (profile && profile.aboutSCSHowSioWorks) {
                var segment = this.scsincomeService.investorData.mode === INVESTOR_MODE.HOME &&
                    this.scsincomeService.investorData.segment
                    ? this.scsincomeService.investorData.segment
                    : profile.aboutSCSHowSioWorks.segmentOption;
                this.formGroup.get("segment").setValue(segment);
                var index = this.scsincomeService.investorData.mode === INVESTOR_MODE.HOME &&
                    this.scsincomeService.investorData.index
                    ? this.scsincomeService.investorData.index
                    : profile.aboutSCSHowSioWorks.index;
                this.formGroup.get("index").setValue(index);
                var duration = this.scsincomeService.investorData.mode === INVESTOR_MODE.HOME &&
                    this.scsincomeService.investorData.duration
                    ? this.scsincomeService.investorData.duration
                    : profile.aboutSCSHowSioWorks.duration.replace(this.translateService.instant("howItsWorks.yr"), "");
                this.formGroup.get("duration").setValue(duration);
                var protection = this.scsincomeService.investorData.mode === INVESTOR_MODE.HOME &&
                    this.scsincomeService.investorData.protection
                    ? this.scsincomeService.investorData.protection
                    : profile.aboutSCSHowSioWorks.protection.replace("%", "");
                this.formGroup.get("protection").setValue(protection);
                this.segment = profile.aboutSCSHowSioWorks.segmentOption;
            }
            else {
                this.segment = this.items[0].id;
                this.formGroup
                    .get("segment")
                    .setValue(this.scsincomeService.investorData.segment
                    ? this.scsincomeService.investorData.segment
                    : this.segment);
                this.formGroup
                    .get("index")
                    .setValue(this.scsincomeService.investorData.index
                    ? this.scsincomeService.investorData.index
                    : this.indices[0].id);
                this.formGroup
                    .get("duration")
                    .setValue(this.scsincomeService.investorData.duration
                    ? this.scsincomeService.investorData.duration
                    : this.durations[0].id);
                this.formGroup
                    .get("protection")
                    .setValue(this.scsincomeService.investorData.protection
                    ? this.scsincomeService.investorData.protection
                    : this.protections[0].id);
            }
        }
        else {
            this.formGroup
                .get("segment")
                .setValue(this.scsincomeService.investorData.segment
                ? this.scsincomeService.investorData.segment
                : this.segment);
            this.formGroup
                .get("index")
                .setValue(this.scsincomeService.investorData.index
                ? this.scsincomeService.investorData.index
                : this.indices[0].id);
            this.formGroup
                .get("duration")
                .setValue(this.scsincomeService.investorData.duration
                ? this.scsincomeService.investorData.duration
                : this.durations[0].id);
            this.formGroup
                .get("protection")
                .setValue(this.scsincomeService.investorData.protection
                ? this.scsincomeService.investorData.protection
                : this.protections[0].id);
            this.segment = this.items[0].id;
        }
    };
    /***
     *  method to change drop down value in form
     ***/
    HowSioWorksComponent.prototype.indexSelection = function (data) {
        var _this = this;
        var segment = typeof this.formGroup.get("segment").value === "object"
            ? this.formGroup.get("segment").value.id
            : this.formGroup.get("segment").value;
        if (segment) {
            this.segment = segment;
        }
        else {
            this.segment = this.items[0].id;
            segment = this.items[0].id;
        }
        var segmentMaster = this.masterData.segments.find(function (x) { return x.id === segment; })
            .data;
        // indeces
        this.indices = Array.from(new Set(segmentMaster.map(function (x) { return x.index; }))).map(function (x) { return ({ id: x, value: x }); });
        if (data &&
            data[0].index &&
            this.indices.findIndex(function (x) { return x.id === data[0].index; }) === -1) {
            this.formGroup.get("index").setValue(this.indices[0].id);
        }
        // durations
        this.durations = Array.from(new Set(segmentMaster
            .filter(function (x) {
            return x.index ==
                (data &&
                    _this.indices.findIndex(function (x) { return x.id === data[1].index; }) !== -1
                    ? data[1].index
                    : _this.indices[0].id);
        })
            .map(function (x) { return x.duration; }))).map(function (x) { return ({
            id: x,
            value: x + " " + _this.translateService.instant("howItsWorks.year"),
        }); });
        if (data &&
            data[0].duration &&
            this.durations.findIndex(function (x) { return x.id === data[0].duration; }) === -1) {
            this.formGroup.get("duration").setValue(this.durations[0].id);
        }
        // protections
        this.protections = segmentMaster
            .filter(function (x) {
            return x.index ==
                (data &&
                    _this.indices.findIndex(function (x) { return x.id === data[1].index; }) !== -1
                    ? data[1].index
                    : _this.indices[0].id) &&
                x.duration ==
                    (data &&
                        _this.durations.findIndex(function (x) { return x.id === data[1].duration; }) !== -1
                        ? data[1].duration
                        : _this.durations[0].id);
        })[0]
            .buffers.map(function (x) { return ({ id: x, value: x + "%" }); });
        if (data &&
            data[0].protection &&
            this.protections.findIndex(function (x) { return x.id.toString() === data[0].protection.toString(); }) === -1) {
            this.formGroup
                .get("protection")
                .setValue(this.protections[0].id.toString());
        }
        // this is where we should populate the data, but we are doing this through a static array above. 
        // enhanced upside
        // this.enhancedUpsides = segmentMaster
        //   .filter(
        //     (x) =>
        //       x.index ==
        //         (data &&
        //         this.indices.findIndex((x) => x.id === data[1].index) !== -1
        //           ? data[1].index
        //           : this.indices[0].id) &&
        //       x.duration ==
        //         (data &&
        //         this.durations.findIndex((x) => x.id === data[1].duration) !== -1
        //           ? data[1].duration
        //           : this.durations[0].id)
        //   )[0]
        //   .enhancedUpside.map((x) => ({ id: x, value: x + "%" }));
        // if (
        //   data &&
        //   data[0].enhancedUpside &&
        //   this.enhancedUpsides.findIndex((x) => x.id === data[0].enhancedUpside) === -1
        // ) {
        //   this.formGroup.get("enhancedUpside").setValue(this.enhancedUpsides[0].id);
        // }
    };
    /***
     *  Get and prepare Bar Chart for how its works
     ***/
    HowSioWorksComponent.prototype.prepareChart = function () {
        if (this.formGroup.valid && this.howItWorks) {
            var segment = typeof this.formGroup.get("segment").value === "object"
                ? this.formGroup.get("segment").value.id
                : this.formGroup.get("segment").value;
            var duration_1 = this.formGroup.get("duration").value +
                this.translateService.instant("howItsWorks.yr");
            var buffer_1 = this.formGroup.get("protection").value + "%";
            var index_1 = this.formGroup.get("index").value;
            var enhancedUpside = this.formGroup.get("enhancedUpside").value + "%";
            var commission = 0;
            var additionalCharges = void 0;
            // TODO: Determine how this is populated. Where is the array of 4 coming from? This is not the correct way to do it, but it works. 
            // Ideally, we want to manipulate this array of objects, howItWorks.performance
            // console.log('this.howItWorks.performance')
            // console.log(this.howItWorks.performance);
            if (segment === SEGMENT_TYPE.STANDARD && this.howItWorks.performance !== undefined) {
                if ((this.scsincomeService.series == SCSINCOME_SERIES.B || this.scsincomeService.series == SCSINCOME_SERIES.ADV) && (!this.flagService.featureFlags.isPrimerica && !this.flagService.featureFlags.isPlusGuard)) {
                    var hydratedArray = this.scsincomeService.getDynamicStandardData(this.scsincomeService.dynamicData, segment, index_1, duration_1, buffer_1);
                    console.log(this.scsincomeService.series);
                    console.log('STANDARD - Hydrated array');
                    console.log(hydratedArray);
                    this.performanceData = tslib_1.__assign({}, hydratedArray.find(function (x) { return x.id === duration_1 && x.buffer === buffer_1 && x.index === index_1; }));
                    console.log('STANDARD - FILTERED ARRAY');
                    console.log(this.performanceData);
                }
                // TODO: Delete all dependencies for the .plist files
                else {
                    this.performanceData = tslib_1.__assign({}, this.howItWorks.performance.find(function (x) { return x.id === duration_1 && x.buffer === buffer_1; }));
                }
            }
            else if (segment === SEGMENT_TYPE.ANNUAL_LOCK) {
                var marketTrend = this.formGroup.get("marketScenerio").value;
                if ((this.scsincomeService.series == SCSINCOME_SERIES.B || this.scsincomeService.series == SCSINCOME_SERIES.ADV) && (!this.flagService.featureFlags.isPrimerica && !this.flagService.featureFlags.isPlusGuard)) {
                    var hydratedArray = this.scsincomeService.getDynamicAnnualLockData(this.scsincomeService.dynamicData, segment, index_1, duration_1, buffer_1, marketTrend);
                    console.log('ANNUAL LOCK - Hydrated array');
                    console.log(hydratedArray);
                    this.performanceData = tslib_1.__assign({}, hydratedArray.find(function (x) { return x.id === duration_1 && x.buffer === buffer_1 && x.index === index_1; }));
                    console.log(this.performanceData);
                }
                // ELSE (SERIES B or any other series)
                else {
                    if (marketTrend === MARKET_SCENERIO.UPWARD) {
                        this.performanceData = this.annualSegmentData.upward;
                    }
                    else {
                        this.performanceData = this.annualSegmentData.downward;
                    }
                }
                //check for additional charges
                additionalCharges = this.performanceData.additionalCharges;
            }
            else if (segment === SEGMENT_TYPE.CHOICE) {
                commission = CHOICE_COMMISION; // Choice Commision
                this.performanceData = tslib_1.__assign({}, this.howItWorks.performance.find(function (x) { return x.id === duration_1 && x.buffer === buffer_1; }));
                //below value is hardcode for some exception //fix
                if (duration_1 === "5" + this.translateService.instant("howItsWorks.yr") &&
                    buffer_1 === "-10%") {
                    this.performanceData.performaceCapRate = 90;
                    this.performanceData.max = 100;
                    this.performanceData.performaceRate[0] = 75;
                    this.performanceData.performaceRate[2] = 93;
                }
            }
            else if (segment === SEGMENT_TYPE.STEP_UP) {
                // IF(B IS SELECTED - Pull data dynamically)
                if ((this.scsincomeService.series == SCSINCOME_SERIES.B || this.scsincomeService.series == SCSINCOME_SERIES.ADV) && (!this.flagService.featureFlags.isPrimerica && !this.flagService.featureFlags.isPlusGuard)) {
                    var hydratedArray = this.scsincomeService.getDynamicStepUpData(this.scsincomeService.dynamicData, segment, index_1, duration_1, buffer_1);
                    console.log('STEP UP - Hydrated array');
                    console.log(hydratedArray);
                    this.performanceData = tslib_1.__assign({}, hydratedArray.find(function (x) { return x.id === duration_1 && x.buffer === buffer_1 && x.index === index_1; }));
                    console.log(this.performanceData);
                }
                // ELSE (NOT B, Pull from static JSON file)
                else {
                    this.performanceData = this.stepUpSegmentData.performance;
                }
            }
            else if (segment === SEGMENT_TYPE.ENHANCED_UPSIDE && (!this.flagService.featureFlags.isPrimerica && !this.flagService.featureFlags.isPlusGuard)) {
                // this.performanceData = this.enhancedUpsideSegmentData.performance; // update to read gene's api, call function called getDyanmicEnhancedUpsideSegmentData()'
                // dynamic for B 
                if ((this.scsincomeService.series == SCSINCOME_SERIES.B || this.scsincomeService.series == SCSINCOME_SERIES.ADV)) {
                    var hydratedArray = this.scsincomeService.getDynamicEnhancedUpsideData(this.scsincomeService.dynamicData, segment, index_1, duration_1, buffer_1, enhancedUpside);
                    if (duration_1 == '3yr') {
                        this.enhancedUpsides = [
                            {
                                id: '110',
                                value: '110%'
                            },
                            {
                                id: '125',
                                value: '125%'
                            },
                        ];
                    }
                    else if (duration_1 == '1yr') {
                        this.enhancedUpsides = [
                            {
                                id: '125',
                                value: '125%'
                            },
                        ];
                    }
                    console.log('ENHANCED UPSIDE - Hydrated array');
                    console.log(hydratedArray);
                    this.performanceData = tslib_1.__assign({}, hydratedArray.find(function (x) { return x.id === duration_1 && x.buffer === buffer_1 && x.index === index_1; }));
                    console.log(this.performanceData);
                }
                // static
                else {
                    if (enhancedUpside == '110%') {
                        if (buffer_1 == '-10%') {
                            this.performanceData = this.enhancedUpside10Static.performance;
                        }
                        else if (buffer_1 == '-15%') {
                            this.performanceData = this.enhancedUpside15Static.performance;
                        }
                    }
                    if (enhancedUpside == '125%') {
                        if (buffer_1 == '-10%') {
                            this.performanceData = this.enhancedUpside10Static125.performance;
                        }
                        else if (buffer_1 == '-15%') {
                            this.performanceData = this.enhancedUpside15Static125.performance;
                        }
                    }
                }
            }
            else if (segment === SEGMENT_TYPE.DUAL_DIRECTION) {
                // UNCOMMENT THIS WHEN READY FOR DYNAMIC
                if ((this.scsincomeService.series == SCSINCOME_SERIES.B || this.scsincomeService.series == SCSINCOME_SERIES.ADV) && (!this.flagService.featureFlags.isPrimerica && !this.flagService.featureFlags.isPlusGuard)) {
                    var hydratedArray = this.scsincomeService.getDynamicDualDirectionData(this.scsincomeService.dynamicData, segment, index_1, duration_1, buffer_1);
                    console.log('DUAL DIRECTION - Hydrated array');
                    console.log(hydratedArray);
                    this.performanceData = tslib_1.__assign({}, hydratedArray.find(function (x) { return x.id === duration_1 && x.buffer === buffer_1 && x.index === index_1; }));
                }
                // S&P, Russell, MSCI EAFE, NASDAQ 100 
                // 1 year, 3 year
                // -10%
                console.log(this.performanceData);
                var scenario1value = Math.abs(Math.round(this.performanceData.segmentRates[0]));
                var scenario2value = Math.abs(Math.round(this.performanceData.segmentRates[1]));
                var scenario3value = Math.abs(Math.round(this.performanceData.segmentRates[2]));
                // SCS Income - SP, 1 year, -15
                // if(scenario 1 and scenario 3 index performance are equal)
                if (scenario1value == scenario3value) {
                    // update scenario 3 values to -5 and +5
                    this.performanceData.segmentRates[2] = -5;
                }
                // SCS PLUS, MSCI EAFE, 1 year, -15
                // if(scenario 3 index performance is greater than scenario 1 index performance)
                else if (scenario1value < scenario3value) {
                    // update scenario 3 values to -5 and +5
                    this.performanceData.segmentRates[2] = -5;
                }
                // if(scenario 3 index performance is greater than scenario 2 index performance)
                // else if(scenario2value < scenario3value) {
                //   // update scenario 3 values to -5 and +5
                //   this.performanceData.segmentRates[2] = -5;
                // }
            }
            // DUAL STEP UP
            else if (segment === SEGMENT_TYPE.DUAL_STEP_UP) {
                // IF(B IS SELECTED - Pull data dynamically)
                if ((this.scsincomeService.series == SCSINCOME_SERIES.B || this.scsincomeService.series == SCSINCOME_SERIES.ADV) && (!this.flagService.featureFlags.isPrimerica && !this.flagService.featureFlags.isPlusGuard)) {
                    var hydratedArray = this.scsincomeService.getDynamicDualStepUpData(this.scsincomeService.dynamicData, segment, index_1, duration_1, buffer_1);
                    console.log('DUAL STEP UP - Hydrated array');
                    console.log(hydratedArray);
                    this.performanceData = tslib_1.__assign({}, hydratedArray.find(function (x) { return x.id === duration_1 && x.buffer === buffer_1 && x.index === index_1; }));
                    console.log(this.performanceData);
                }
            }
            // LOSS LIMITER 90
            else if (segment === SEGMENT_TYPE.LOSS_LIMITER_90) {
                // IF(B IS SELECTED - Pull data dynamically)
                if ((this.scsincomeService.series == SCSINCOME_SERIES.B || this.scsincomeService.series == SCSINCOME_SERIES.ADV) && (!this.flagService.featureFlags.isPrimerica && !this.flagService.featureFlags.isPlusGuard)) {
                    var hydratedArray = this.scsincomeService.getDynamicLossLimiter90Data(this.scsincomeService.dynamicData, segment, index_1, duration_1, buffer_1);
                    console.log('DUAL STEP UP - Hydrated array');
                    console.log(hydratedArray);
                    this.performanceData = tslib_1.__assign({}, hydratedArray.find(function (x) { return x.id === duration_1 && x.buffer === buffer_1 && x.index === index_1; }));
                    console.log(this.performanceData);
                }
            }
            // LOSS LIMITER 95
            else if (segment === SEGMENT_TYPE.LOSS_LIMITER_95) {
                // IF(B IS SELECTED - Pull data dynamically)
                if ((this.scsincomeService.series == SCSINCOME_SERIES.B || this.scsincomeService.series == SCSINCOME_SERIES.ADV) && (!this.flagService.featureFlags.isPrimerica && !this.flagService.featureFlags.isPlusGuard)) {
                    var hydratedArray = this.scsincomeService.getDynamicLossLimiter95Data(this.scsincomeService.dynamicData, segment, index_1, duration_1, buffer_1);
                    console.log('DUAL STEP UP - Hydrated array');
                    console.log(hydratedArray);
                    this.performanceData = tslib_1.__assign({}, hydratedArray.find(function (x) { return x.id === duration_1 && x.buffer === buffer_1 && x.index === index_1; }));
                    console.log(this.performanceData);
                }
            }
            var details = this.scsincomeService.parsePerformanceData(this.performanceData, segment, commission);
            //for line axis
            if (segment === SEGMENT_TYPE.ANNUAL_LOCK) {
                console.log('XXX1');
                if (details && details.data && details.data.axis) {
                    details.data.axis.push(this.annualSegmentData.axis);
                }
                //also deduct any additional charges
                var finalReturn = void 0;
                if (additionalCharges) {
                    finalReturn =
                        details.data.line.data[details.data.line.data.length - 1].value -
                            ((this.scsincomeService.getDollarAmount() * additionalCharges) / 100) *
                                details.data.line.data.length;
                    details.data.line.data[details.data.line.data.length - 1].value = finalReturn;
                }
                else {
                    finalReturn =
                        details.data.line.data[details.data.line.data.length - 1].value;
                }
                this.annualRate = this.translateService
                    .instant("howItsWorks.annualRate")
                    .replace("$$YEAR$$", details.data.line.data.length.toString())
                    .replace("$$BUFFER$$", buffer_1)
                    .replace("$$RETURN_PERCENTAGE$$", (((finalReturn - this.scsincomeService.getDollarAmount()) /
                    this.scsincomeService.getDollarAmount()) *
                    100).toFixed(2))
                    .replace("$$RETURN$$", finalReturn.toLocaleString().toString())
                    .replace("$$DBR$$", this.performanceData.additionalCharges);
                details.data.description =
                    this.annualRate +
                        "<br/><br>" +
                        this.translateService.instant("howItsWorks.howAnnualRateCalculated");
            }
            //add description
            details.data.showDescription = true;
            details.data.showLineToolTip = false;
            this.toolFooterTextPerformanceCapRate = this.translateService
                .instant("howItsWorks.toolFooterTextPerformanceCapRate")
                .replace("$$PERFORMANCE_CAP_RATE$$", this.performanceData.performaceCapRate);
            //set heading
            this.toolHeaderText = this.translateService
                .instant("howItsWorks.toolHeaderText")
                .replace("$$YEAR$$", this.formGroup.get("duration").value)
                .replace("$$PERFORMANCE_CAP_RATE$$", this.performanceData.performaceCapRate)
                .replace("$$SEGMENT_BUFFER$$", this.formGroup.get("protection").value);
            if (segment === SEGMENT_TYPE.ENHANCED_UPSIDE) {
                if (this.series == SCSINCOME_SERIES.B) {
                    if (enhancedUpside == '110%') {
                        this.toolHeaderText = this.translateService
                            .instant("howItsWorks.toolHeaderTextEnhancedUpside110")
                            .replace("$$YEAR$$", this.formGroup.get("duration").value)
                            .replace("$$PERFORMANCE_CAP_RATE$$", this.performanceData.performaceCapRate)
                            .replace("$$SEGMENT_BUFFER$$", this.formGroup.get("protection").value);
                    }
                    else if (enhancedUpside == '125%') {
                        this.toolHeaderText = this.translateService
                            .instant("howItsWorks.toolHeaderTextEnhancedUpside")
                            .replace("$$YEAR$$", this.formGroup.get("duration").value)
                            .replace("$$PERFORMANCE_CAP_RATE$$", this.performanceData.performaceCapRate)
                            .replace("$$SEGMENT_BUFFER$$", this.formGroup.get("protection").value);
                    }
                }
                else {
                    this.toolHeaderText = this.translateService
                        .instant("howItsWorks.toolHeaderTextEnhancedUpside")
                        .replace("$$YEAR$$", this.formGroup.get("duration").value)
                        .replace("$$PERFORMANCE_CAP_RATE$$", this.performanceData.performaceCapRate)
                        .replace("$$SEGMENT_BUFFER$$", this.formGroup.get("protection").value);
                }
            }
            if (this.series == SCSINCOME_SERIES.B && segment === SEGMENT_TYPE.STANDARD && this.performanceData.performaceCapRate == 1000) {
                this.toolHeaderText = this.translateService
                    .instant("howItsWorks.toolHeaderTextUncapped")
                    .replace("$$YEAR$$", this.formGroup.get("duration").value)
                    .replace("$$PERFORMANCE_CAP_RATE$$", 'Uncapped Performance Cap Rate')
                    .replace("$$SEGMENT_BUFFER$$", this.formGroup.get("protection").value);
            }
            if (this.series == SCSINCOME_SERIES.ADV && segment === SEGMENT_TYPE.STANDARD && this.performanceData.performaceCapRate == 1000) {
                this.toolHeaderText = this.translateService
                    .instant("howItsWorks.toolHeaderTextUncapped")
                    .replace("$$YEAR$$", this.formGroup.get("duration").value)
                    .replace("$$PERFORMANCE_CAP_RATE$$", 'Uncapped Performance Cap Rate')
                    .replace("$$SEGMENT_BUFFER$$", this.formGroup.get("protection").value);
            }
            this.chartData = details.data;
            this.chartDetails = details.details;
            //saving for session
            this.saveProfile();
        }
    };
    HowSioWorksComponent.prototype.segmentChanged = function (event) {
        this.formGroup.get("marketScenerio").setValue(event.details.value);
    };
    /***
     * back button
     ***/
    HowSioWorksComponent.prototype.backButton = function () {
        this.utilityService.navigate(ROUTE_PATH.SCSINCOME.TOOLS.HOW_SIO_WORKS);
    };
    /***
     *  Load default options
     ***/
    HowSioWorksComponent.prototype.loadDefaultsOptions = function () {
        this.formGroup.get("segment").setValue(this.items[0].id);
        this.formGroup.get("index").setValue(this.indices[0].id);
        this.formGroup.get("duration").setValue(this.durations[0].id);
        this.formGroup.get("protection").setValue(this.protections[0].id);
        this.formGroup.get("enhancedUpside").setValue(this.enhancedUpsides[0].id);
    };
    /***
     *  Load custom profile
     ***/
    HowSioWorksComponent.prototype.loadProfile = function () {
        var _this = this;
        this.scsincomeService.getProfile().subscribe(function (profiles) {
            if (profiles && profiles.length > 0) {
                //get custom profile
                var profile = profiles.find(function (x) {
                    return x.name === _this.scsincomeService.currentProfile &&
                        x.currentSerie === _this.scsincomeService.series;
                });
                //set custom profile
                if (profile && profile.aboutSCSHowSioWorks) {
                    _this.formGroup
                        .get("segment")
                        .setValue(profile.aboutSCSHowSioWorks.segmentOption);
                    _this.formGroup
                        .get("index")
                        .setValue(profile.aboutSCSHowSioWorks.index);
                    _this.formGroup
                        .get("duration")
                        .setValue(parseInt(profile.aboutSCSHowSioWorks.duration));
                    _this.formGroup
                        .get("protection")
                        .setValue(parseInt(profile.aboutSCSHowSioWorks.protection));
                    // this.formGroup
                    //   .get("enhancedUpside")
                    //   .setValue(parseInt(profile.aboutSCSHowSioWorks.enhancedUpsides));
                }
                else {
                    _this.loadDefaultsOptions();
                }
            }
            else {
                //set default profile if not data
                _this.loadDefaultsOptions();
            }
        });
    };
    /***
     *  Save Profile
     ***/
    HowSioWorksComponent.prototype.saveProfile = function (mode) {
        var _this = this;
        if (this.formGroup.valid) {
            var segment_1 = typeof this.formGroup.get("segment").value === "object"
                ? this.formGroup.get("segment").value.id
                : this.formGroup.get("segment").value;
            var duration = this.formGroup.get("duration").value;
            var buffer = this.formGroup.get("protection").value;
            var index = this.formGroup.get("index").value;
            var enhancedUpside = this.formGroup.get("enhancedUpside").value;
            this.scsincomeService.investorData.segment = segment_1;
            this.scsincomeService.investorData.duration = duration;
            this.scsincomeService.investorData.protection = buffer;
            this.scsincomeService.investorData.index = index;
            // this.scsincomeService.investorData.enhancedUpside = enhancedUpside;
            var hypothetical_1 = new SCSIncomeHowSioWorks();
            hypothetical_1.segmentBuffer = buffer;
            hypothetical_1.segmentOption = segment_1;
            hypothetical_1.duration = duration;
            hypothetical_1.index = index;
            hypothetical_1.duration =
                duration + this.translateService.instant("howItsWorks.yr");
            hypothetical_1.protection = buffer + "%";
            if (enhancedUpside) {
                hypothetical_1.enhancedUpside = enhancedUpside;
            }
            // IF (ANNUAL LOCK AND SERIES B - Pass updated values to back-end API)
            if (segment_1 == SEGMENT_TYPE.ANNUAL_LOCK && (this.scsincomeService.series == SCSINCOME_SERIES.B || this.scsincomeService.series == SCSINCOME_SERIES.ADV)) {
                hypothetical_1.upMarketBars = this.scsincomeService.upMarketBars;
                hypothetical_1.downMarketBars = this.scsincomeService.downMarketBars;
            }
            // this.chartData.axis[0] controls the percents on the left hand side of the graph.
            // the values axis.min, axis.max, and axis.interval within howItsWorks.json control the dollar values on the right hand side of the graph. 
            if (segment_1 == SEGMENT_TYPE.ANNUAL_LOCK && this.isNational) {
                this.chartData.axis[0].interval = 10;
                this.chartData.axis[0].min = -20;
                this.chartData.axis[0].max = 100;
            }
            if (this.chartData && this.chartData.axis) {
                // console.log('XXX2')
                hypothetical_1.scaleMin = this.chartData.axis[0].min;
                // console.log('XXX4')
                hypothetical_1.scaleMax = this.chartData.axis[0].max;
                hypothetical_1.scaleStep = this.chartData.axis[0].interval;
                var id_1 = 0;
                this.chartData.bar.forEach(function (element, index) {
                    // add bar
                    var i = 1; //reverse the bar is report is demanding same
                    element.data.forEach(function (bar) {
                        hypothetical_1.bars[id_1] = element.data[i].value;
                        id_1++; // increase id for each bar
                        i--; //reverse for 2 count
                    });
                    // For dual direction, target the specific segment rate here of the 7th bar with the correct segment rate. Otherwise, we pass it the wrong value
                    if (segment_1 == SEGMENT_TYPE.DUAL_DIRECTION && _this.isNational) {
                        hypothetical_1.bars[1] = _this.performanceData.segmentRates[0];
                        hypothetical_1.bars[3] = _this.performanceData.segmentRates[1];
                        hypothetical_1.bars[5] = _this.performanceData.segmentRates[2];
                        hypothetical_1.bars[7] = _this.performanceData.segmentRates[3];
                    }
                    //add caps
                    hypothetical_1.performanceCap = element.lines[0].value;
                    hypothetical_1.segmentBuffer = element.lines[1].value;
                    hypothetical_1.commissions[index] = element.data[0].topValue;
                    hypothetical_1.scenarios[index] = true;
                });
            }
            hypothetical_1.scaleHeight = 275; //fix
            hypothetical_1.scaleHeight = 275; //fix
            hypothetical_1.segmentValue = this.scsincomeService.getDollarAmount();
            if (this.isInitalLoadCompleted &&
                this.scsincomeService.customizationType ===
                    CUSTOMIZATION_TYPE.INVESTOR_PROFILE) {
                this.coreService.profile.next({
                    name: "",
                    type: CUSTOMIZATION_TYPE.REPORT,
                    profile: new Profile(),
                });
                this.scsincomeService.customizationType = CUSTOMIZATION_TYPE.REPORT;
            }
            //save for profile
            if (this.scsincomeService.customizationType === CUSTOMIZATION_TYPE.PROFILE) {
                this.scsincomeService.storeProfile(CUSTOM_PROFILE.HOW_IT_WORKS, hypothetical_1);
            }
            else {
                //save for report
                this.scsincomeService.report.aboutSCSHowSioWorks = hypothetical_1;
                this.scsincomeService.storeProfile(CUSTOM_PROFILE.HOW_IT_WORKS, hypothetical_1, true);
            }
            if (mode && mode == CUSTOMIZATION_TYPE.PROFILE) {
                this.utilityService.presentWithoutTitleModal(ModalPopupComponent);
            }
            else if (mode && mode == CUSTOMIZATION_TYPE.REPORT) {
                this.utilityService.navigate(ROUTE_PATH.SCSINCOME.TOOLS.CREATE_REPORT);
            }
        }
    };
    HowSioWorksComponent.prototype.openModal = function () {
        this.scsincomeService.openModal(SioPopupComponent, "modal-css");
    };
    return HowSioWorksComponent;
}());
export { HowSioWorksComponent };
