import { PRODUCT_TYPE, ROUTE_PATH } from './../../../constants/constants';
import { UtilityService } from './../../services/utility.service';
import { Component, OnInit, Input } from '@angular/core';
import { ButtonType, Theme } from 'equitable-component-library';

@Component({
  selector: 'app-selected-annuity',
  templateUrl: './selected-annuity.component.html',
  styleUrls: ['./selected-annuity.component.scss'],
})
export class SelectedAnnuityComponent implements OnInit {
  @Input() icon: string;
  @Input() headerImage: string;
  @Input() title: string;
  @Input() content: string;
  @Input() benefits: [];
  @Input() selectedProduct: string;
  iconPath: string;
  headerImagePath: string;
  @Input() description: string;
  // Button component Data
  button = {
    type: ButtonType.Main,
    theme: Theme.Light
  }

  constructor(private utilityService: UtilityService) { }

  ngOnInit() {
  }

  navigateToProducts() {
    switch (this.selectedProduct) {
      case PRODUCT_TYPE.SCS:
        this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER);
        break;
      case PRODUCT_TYPE.RC:
        this.utilityService.navigate(ROUTE_PATH.RC.DISCOVER);
        break;
      case PRODUCT_TYPE.IE:
        this.utilityService.navigate(ROUTE_PATH.IE.DISCOVER);
        break;
    }
  }
}
