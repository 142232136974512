import { UtilityService } from "../../services/utility.service";
import { QuestionnaireService } from "../../services/questionnaire.service";
import { OnInit } from "@angular/core";
var QuestionnaireComponent = /** @class */ (function () {
    function QuestionnaireComponent(questionnaireService, utilityService) {
        this.questionnaireService = questionnaireService;
        this.utilityService = utilityService;
    }
    QuestionnaireComponent.prototype.ngOnInit = function () {
        this.init();
        this.utilityService.sendScrollStatus(false);
    };
    QuestionnaireComponent.prototype.init = function () {
        this.step = 1;
        this.quesions = true;
        this.result = false;
    };
    QuestionnaireComponent.prototype.backButtonClicked = function (step) {
        if (step !== 1) {
            this.step = step - 1;
        }
        else {
            this.utilityService.navigateToBack();
        }
    };
    QuestionnaireComponent.prototype.optionSelected = function (selectedoption) {
        var questionnaire = this.questionnaireService.getQuestionnaire();
        questionnaire[selectedoption.step] = selectedoption.index;
        this.questionnaireService.setQuestionnaire(questionnaire);
        if (selectedoption.step !== 3) {
            this.step = selectedoption.step + 1;
        }
        else {
            this.result = true;
            this.quesions = false;
        }
        // eslint-disable-next-line angular/document-service
        document.querySelectorAll("ion-content").forEach(function (content) {
            content.scrollToTop();
        });
    };
    QuestionnaireComponent.prototype.retakeQuiz = function () {
        this.init();
    };
    QuestionnaireComponent.prototype.ionViewDidLeave = function () {
        this.init();
    };
    return QuestionnaireComponent;
}());
export { QuestionnaireComponent };
