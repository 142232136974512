import { Component, OnInit } from "@angular/core";
import { IeService } from "../../services/ie.service";
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { IE_SERIES } from 'src/app/constants/constants';

@Component({
  selector: "app-sio-popup",
  templateUrl: "./sio-popup.component.html",
  styleUrls: ["./sio-popup.component.scss"],
})
export class SioPopupComponent implements OnInit {
  ar;
  series: string;
  popoverDesc: string;

  constructor(private ieService: IeService, 
    public modalController: ModalController, 
    private translateService: TranslateService) { }

  ngOnInit() {
    this.getData();
    console.log("popup popping")
  }

  ionViewWillEnter() {
    this.getData();
  }

  getData() {
    this.ieService.getHowItsWorksIEMasterData().subscribe((masterData) => {
      const series = masterData.body.series.find(
        (x) => x.name === this.ieService.series
      );
      let arrDurations = [];
      series.segments.forEach((element) => {
        const durations = Array.from(
          new Set(element.data.map((x) => x.duration))
        );
        arrDurations = arrDurations.concat(durations);
      });
      arrDurations = Array.from(new Set(arrDurations));

      const id = 0;
      const finalArray = [];
      arrDurations.forEach((duration) => {
        const obj = {
          duration: duration + this.translateService.instant("sioFrontPage.popOver.timeFrame"),
          data: [],
        };

        series.segments.forEach((element) => {
          const selectedIndex = element.data.filter(
            (x) => x.duration == duration
          );
          const objIndex = {
            approach: element.id,
            index: Array.from(new Set(selectedIndex.map((x) => x.index))),
            buffer: Array.from(
              new Set([].concat(...selectedIndex.map((x) => x.buffers)))
            ),
          };
          if (objIndex.index.length > 0) obj.data.push(objIndex);
        });
        finalArray.push(obj);
      });
      this.ar = finalArray;
    });
    this.translateService.get("sioFrontPage.popOver").subscribe((translated) => {
      this.popoverDesc =
          this.translateService.instant(
            "sioFrontPage.popOver.strcturedOption",
            {
              years: "1, 3 or 5"
            });
      
    });
  }

  closeModal() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
