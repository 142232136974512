import * as tslib_1 from "tslib";
import { OnInit, AfterViewChecked } from "@angular/core";
import { FormControl, Validators, FormBuilder, } from "@angular/forms";
import { SEGMENT_TYPE, MARKET_SCENERIO, IE_SERIES, PRODUCT_TYPE, CHOICE_COMMISION, CUSTOMIZATION_TYPE, CUSTOM_PROFILE, ROUTE_PATH, INVESTOR_MODE } from "../../../constants/constants";
import { UtilityService } from "../../../core/services/utility.service";
import { pairwise, distinctUntilChanged } from "rxjs/operators";
import { Profile, SCSHowSioWorks, } from "../../../core/models/app.models";
import { TranslateService } from "@ngx-translate/core";
import { Theme, ButtonType, Orientation, LinkIcon, } from "equitable-component-library";
import { SioPopupComponent } from "../../components/sio-popup/sio-popup.component"; // NEED TO CHANGE TO IE, unless we can pass IE here to remove the popover text
import { ModalPopupComponent } from "../../../core/components/modal-popup/modal-popup.component";
import { CoreService } from "../../../core/services/core.service";
import { FeatureFlagService } from "./../../../core/services/feature-flag.service";
import { IeService } from '../../services/ie.service';
var HowSioWorksComponent = /** @class */ (function () {
    /***
     *  Constructor for how its works
     ***/
    function HowSioWorksComponent(formBuilder, ieService, utilityService, translateService, coreService, flagService) {
        this.formBuilder = formBuilder;
        this.ieService = ieService;
        this.utilityService = utilityService;
        this.translateService = translateService;
        this.coreService = coreService;
        this.flagService = flagService;
        this.CUSTOMIZATION_TYPE = CUSTOMIZATION_TYPE;
        this.orientation = Orientation.Right;
        this.type = Theme.Dark;
        this.btnSaveTheme = Theme.Light;
        this.btnSaveType = ButtonType.Primary;
        this.linkOrientation = Orientation.Left;
        this.linkIcon = LinkIcon.LeftIcon;
        this.linkRightIcon = LinkIcon.RightIcon;
        this.SEGMENT_TYPE = SEGMENT_TYPE;
        this.indices = [];
        this.durations = [];
        this.enhancedUpsides = [];
        this.protections = [];
        this.items = [];
        this.masterData = {};
        this.chartDetails = [];
        this.toolHeaderText = "";
        this.backButtonSeries = this.ieService.series == IE_SERIES.B ? IE_SERIES.ADV + "" : "";
        this.showInfo = true;
        this.isInitalLoadCompleted = false;
        this.isDynamicChart = true;
        this.isIE = true;
        this.product = PRODUCT_TYPE;
        this.minValue = 5000;
        this.maxValue = 50000000;
    }
    /***
     *  Page Load for how its works
     ***/
    HowSioWorksComponent.prototype.ngOnInit = function () {
        //set series
        this.series = this.ieService.series;
        this.customizationType = this.ieService.customizationType;
        //build form
        this.buildForm();
        //get data for the page
        this.getHowItsWorksIEData();
        this.isNotPlusGuard = !this.flagService.featureFlags.isPlusGuard;
        this.isNational = this.flagService.featureFlags.isNational;
        this.isPlusGuard = this.flagService.featureFlags.isPlusGuard;
        this.isPrimerica = this.flagService.featureFlags.isPrimerica;
        //this.ieService.isIE = true;
        this.series = IE_SERIES.B;
        this.flagService.featureFlags.ie;
        console.log(this.series);
        // get the dollar amount initially
        this.dollarInputValue = this.ieService.getDollarAmount();
    };
    //page load for ionic
    HowSioWorksComponent.prototype.ionViewWillEnter = function () {
        if (this.ieService.series == IE_SERIES.B) {
            this.backLink = this.translateService.instant("sioFrontPage.ieBackLink");
        }
        else {
            this.backLink = this.translateService.instant("sioFrontPage.ieBackLink");
        }
    };
    //check content fully loaded
    //check content fully loaded
    HowSioWorksComponent.prototype.ngAfterViewChecked = function () {
        // console.log("IE logging ngAfter tool = " + this.tool);
        if (this.formGroup.get("segment").value &&
            this.formGroup.get("index").value &&
            this.formGroup.get("duration").value &&
            this.formGroup.get("protection").value) {
            this.isInitalLoadCompleted = true;
        }
    };
    // TODO: try putting the subscribe function in this file for dynamic data. 
    /***
     *  Create form for how its works
     ***/
    HowSioWorksComponent.prototype.getHowItsWorksIEData = function () {
        var _this = this;
        this.utilityService.showLoader();
        this.ieService.getHowitWorksIEData(this.series).subscribe(function (data) {
            _this.utilityService.hideLoader();
            if (data[1] && data[0].body) {
                _this.howItWorks = data[1];
                _this.getMasterData(data[0].body, data[2]);
            }
        });
    };
    HowSioWorksComponent.prototype.isValid = function () {
        return this.dollarInputValue >= this.minValue && this.dollarInputValue <= this.maxValue && this.dollarInputValue % 1 === 0;
    };
    HowSioWorksComponent.prototype.onSubmitOfDollarAmount = function () {
        this.ieService.setDollarAmount(this.dollarInputValue);
        this.prepareChart();
    };
    /***
     *  Create form for how its works
     ***/
    HowSioWorksComponent.prototype.buildForm = function () {
        var _this = this;
        //create form
        this.formGroup = this.formBuilder.group({
            segment: new FormControl({ value: "", disabled: false }, Validators.required),
            index: new FormControl({ value: "", disabled: false }, Validators.required),
            duration: new FormControl({ value: "", disabled: false }, Validators.required),
            protection: new FormControl({ value: "", disabled: false }, Validators.required),
            dynamicDollarAmount: new FormControl({ value: this.ieService.getDollarAmount(), disabled: false }, Validators.required),
            marketScenerio: new FormControl({ value: "", disabled: true }, Validators.required),
        });
        //Form on changes
        this.formGroup.valueChanges
            .pipe(distinctUntilChanged(), pairwise())
            .subscribe(function (data) {
            if (data[1].segment &&
                data[1].index &&
                data[1].duration &&
                data[1].protection) {
                data[1].segment =
                    typeof data[1].segment === "object"
                        ? data[1].segment.id
                        : data[1].segment;
                _this.indexSelection(data);
                // IF the segment type is dual direction, we want to display a static image. 
                if (_this.segment.toLowerCase() == SEGMENT_TYPE.DUAL_DIRECTION.toLowerCase()) {
                    _this.isDynamicChart = true;
                    _this.prepareChart();
                    _this.saveProfile();
                }
                else {
                    _this.isDynamicChart = true;
                    _this.prepareChart();
                    _this.saveProfile();
                }
            }
        });
    };
    /***
     *  get master data for how its works
     ***/
    HowSioWorksComponent.prototype.getMasterData = function (masterData, savedProfiles) {
        var _this = this;
        //get market scenerio master data
        console.log("this is getMasterData");
        this.translateService
            .get("howItsWorks.marketScenerios")
            .subscribe(function (marketScenerios) {
            _this.marketScenerios = [
                {
                    id: MARKET_SCENERIO.UPWARD,
                    value: marketScenerios.upwards,
                },
                {
                    id: MARKET_SCENERIO.DOWNWARD,
                    value: marketScenerios.downwards,
                },
            ];
        });
        //get all drop down master data for all series
        // TODO: Remove these instances as needed. 
        this.masterData = masterData.series.find(function (x) { return x.name === _this.series; });
        this.annualSegmentData =
            masterData.annualSegmentData && masterData.annualSegmentData.length > 0
                ? masterData.annualSegmentData.find(function (x) { return x.series === _this.series; })
                : {};
        this.stepUpSegmentData =
            masterData.stepUpSegmentData && masterData.stepUpSegmentData.length > 0
                ? masterData.stepUpSegmentData.find(function (x) { return x.series === _this.series; })
                : {};
        this.items = Array.from(this.masterData.segments.map(function (x) { return x.id; })).map(function (x) { return ({ id: x, value: x }); });
        this.indexSelection();
        //set default market scenetio to upwards
        this.formGroup.get("marketScenerio").setValue(this.marketScenerios[0].id);
        // set default enhanced upside to -110	
        //  this.formGroup.get("enhancedUpside").setValue(this.enhancedUpsides[0].id)
        if (savedProfiles &&
            savedProfiles.length > 0 &&
            ((this.ieService.customizationType === CUSTOMIZATION_TYPE.PROFILE &&
                this.ieService.currentProfile) ||
                this.ieService.customizationType === CUSTOMIZATION_TYPE.REPORT)) {
            var profile = savedProfiles.find(function (x) {
                return x.name ===
                    (_this.ieService.customizationType == CUSTOMIZATION_TYPE.PROFILE
                        ? _this.ieService.currentProfile
                        : CUSTOMIZATION_TYPE.REPORT) &&
                    x.currentSerie === _this.ieService.series;
            });
            if (profile && profile.aboutSCSHowSioWorks) {
                var segment = this.ieService.investorData.mode === INVESTOR_MODE.HOME &&
                    this.ieService.investorData.segment
                    ? this.ieService.investorData.segment
                    : profile.aboutSCSHowSioWorks.segmentOption;
                this.formGroup.get("segment").setValue(segment);
                var index = this.ieService.investorData.mode === INVESTOR_MODE.HOME &&
                    this.ieService.investorData.index
                    ? this.ieService.investorData.index
                    : profile.aboutSCSHowSioWorks.index;
                this.formGroup.get("index").setValue(index);
                var duration = this.ieService.investorData.mode === INVESTOR_MODE.HOME &&
                    this.ieService.investorData.duration
                    ? this.ieService.investorData.duration
                    : profile.aboutSCSHowSioWorks.duration.replace(this.translateService.instant("howItsWorks.yr"), "");
                this.formGroup.get("duration").setValue(duration);
                var protection = this.ieService.investorData.mode === INVESTOR_MODE.HOME &&
                    this.ieService.investorData.protection
                    ? this.ieService.investorData.protection
                    : profile.aboutSCSHowSioWorks.protection.replace("%", "");
                this.formGroup.get("protection").setValue(protection);
                this.segment = profile.aboutSCSHowSioWorks.segmentOption;
            }
            else {
                this.segment = this.items[0].id;
                this.formGroup
                    .get("segment")
                    .setValue(this.ieService.investorData.segment
                    ? this.ieService.investorData.segment
                    : this.segment);
                this.formGroup
                    .get("index")
                    .setValue(this.ieService.investorData.index
                    ? this.ieService.investorData.index
                    : this.indices[0].id);
                this.formGroup
                    .get("duration")
                    .setValue(this.ieService.investorData.duration
                    ? this.ieService.investorData.duration
                    : this.durations[0].id);
                this.formGroup
                    .get("protection")
                    .setValue(this.ieService.investorData.protection
                    ? this.ieService.investorData.protection
                    : this.protections[0].id);
            }
        }
        else {
            this.formGroup
                .get("segment")
                .setValue(this.ieService.investorData.segment
                ? this.ieService.investorData.segment
                : this.segment);
            this.formGroup
                .get("index")
                .setValue(this.ieService.investorData.index
                ? this.ieService.investorData.index
                : this.indices[0].id);
            this.formGroup
                .get("duration")
                .setValue(this.ieService.investorData.duration
                ? this.ieService.investorData.duration
                : this.durations[0].id);
            this.formGroup
                .get("protection")
                .setValue(this.ieService.investorData.protection
                ? this.ieService.investorData.protection
                : this.protections[0].id);
            this.segment = this.items[0].id;
        }
    };
    /***
     *  method to change drop down value in form
     ***/
    HowSioWorksComponent.prototype.indexSelection = function (data) {
        var _this = this;
        var segment = typeof this.formGroup.get("segment").value === "object"
            ? this.formGroup.get("segment").value.id
            : this.formGroup.get("segment").value;
        if (segment) {
            this.segment = segment;
        }
        else {
            this.segment = this.items[0].id;
            segment = this.items[0].id;
        }
        var segmentMaster = this.masterData.segments.find(function (x) { return x.id === segment; })
            .data;
        this.indices = Array.from(new Set(segmentMaster.map(function (x) { return x.index; }))).map(function (x) { return ({ id: x, value: x }); });
        if (data &&
            data[0].index &&
            this.indices.findIndex(function (x) { return x.id === data[0].index; }) === -1) {
            this.formGroup.get("index").setValue(this.indices[0].id);
        }
        this.durations = Array.from(new Set(segmentMaster
            .filter(function (x) {
            return x.index ==
                (data &&
                    _this.indices.findIndex(function (x) { return x.id === data[1].index; }) !== -1
                    ? data[1].index
                    : _this.indices[0].id);
        })
            .map(function (x) { return x.duration; }))).map(function (x) { return ({
            id: x,
            value: x + " " + _this.translateService.instant("howItsWorks.year"),
        }); });
        if (data &&
            data[0].duration &&
            this.durations.findIndex(function (x) { return x.id === data[0].duration; }) === -1) {
            this.formGroup.get("duration").setValue(this.durations[0].id);
        }
        this.protections = segmentMaster
            .filter(function (x) {
            return x.index ==
                (data &&
                    _this.indices.findIndex(function (x) { return x.id === data[1].index; }) !== -1
                    ? data[1].index
                    : _this.indices[0].id) &&
                x.duration ==
                    (data &&
                        _this.durations.findIndex(function (x) { return x.id === data[1].duration; }) !== -1
                        ? data[1].duration
                        : _this.durations[0].id);
        })[0]
            .buffers.map(function (x) { return ({ id: x, value: x + "%" }); });
        if (data &&
            data[0].protection &&
            this.protections.findIndex(function (x) { return x.id.toString() === data[0].protection.toString(); }) === -1) {
            this.formGroup
                .get("protection")
                .setValue(this.protections[0].id.toString());
        }
    };
    /***
     *  Get and prepare Bar Chart for how its works
     ***/
    HowSioWorksComponent.prototype.prepareChart = function () {
        if (this.formGroup.valid && this.howItWorks) {
            var segment = typeof this.formGroup.get("segment").value === "object"
                ? this.formGroup.get("segment").value.id
                : this.formGroup.get("segment").value;
            var duration_1 = this.formGroup.get("duration").value +
                this.translateService.instant("howItsWorks.yr");
            var buffer_1 = this.formGroup.get("protection").value + "%";
            var index_1 = this.formGroup.get("index").value;
            //const enhancedUpside = "125%"; // hardcoded for enhanced upside
            var commission = 0;
            var additionalCharges = void 0;
            // TODO: Determine how this is populated. Where is the array of 4 coming from? This is not the correct way to do it, but it works. 
            // Ideally, we want to manipulate this array of objects, howItWorks.performance
            if (segment === SEGMENT_TYPE.STANDARD) {
                if ((this.ieService.series == IE_SERIES.B || this.ieService.series == IE_SERIES.ADV) && (this.flagService.featureFlags.isNational)) {
                    var hydratedArray = this.ieService.getDynamicStandardData(this.ieService.dynamicData, segment, index_1, duration_1, buffer_1);
                    console.log('STANDARD - Hydrated array');
                    console.log(hydratedArray);
                    this.performanceData = tslib_1.__assign({}, hydratedArray.find(function (x) { return x.id === duration_1 && x.buffer === buffer_1 && x.index === index_1; }));
                }
                // TODO: Delete all dependencies for the .plist files
                else {
                    this.performanceData = tslib_1.__assign({}, this.howItWorks.performance.find(function (x) { return x.id === duration_1 && x.buffer === buffer_1; }));
                }
            }
            else if (segment === SEGMENT_TYPE.DUAL_DIRECTION && this.howItWorks.performance !== undefined) {
                // dynamic data
                if ((!this.flagService.featureFlags.isPrimerica && !this.flagService.featureFlags.isPlusGuard)) {
                    var hydratedArray = this.ieService.getDynamicDualDirectionData(this.ieService.dynamicData, segment, index_1, duration_1, buffer_1);
                    console.log('DUAL DIRECTION - Hydrated array');
                    console.log(hydratedArray);
                    this.performanceData = tslib_1.__assign({}, hydratedArray.find(function (x) { return x.id === duration_1 && x.buffer === buffer_1 && x.index === index_1; }));
                }
                console.log(this.performanceData);
                var scenario1value = Math.abs(Math.round(this.performanceData.segmentRates[0]));
                var scenario2value = Math.abs(Math.round(this.performanceData.segmentRates[1]));
                var scenario3value = Math.abs(Math.round(this.performanceData.segmentRates[2]));
                // SCS Income - SP, 1 year, -15
                // if(scenario 1 and scenario 3 index performance are equal)
                if (scenario1value == scenario3value) {
                    // update scenario 3 values to -5 and +5
                    this.performanceData.segmentRates[2] = -5;
                }
                // SCS PLUS, MSCI EAFE, 1 year, -15
                // if(scenario 3 index performance is greater than scenario 1 index performance)
                else if (scenario1value < scenario3value) {
                    // update scenario 3 values to -5 and +5
                    this.performanceData.segmentRates[2] = -5;
                }
                // if(scenario 3 index performance is greater than scenario 2 index performance)
                // else if(scenario2value < scenario3value) {
                //   // update scenario 3 values to -5 and +5
                //   this.performanceData.segmentRates[2] = -5;
                // }
            }
            else if (segment === SEGMENT_TYPE.CHOICE) {
                commission = CHOICE_COMMISION; // Choice Commision
                this.performanceData = tslib_1.__assign({}, this.howItWorks.performance.find(function (x) { return x.id === duration_1 && x.buffer === buffer_1; }));
                //below value is hardcode for some exception //fix
                if (duration_1 === "5" + this.translateService.instant("howItsWorks.yr") &&
                    buffer_1 === "-10%") {
                    this.performanceData.performaceCapRate = 90;
                    this.performanceData.max = 100;
                    this.performanceData.performaceRate[0] = 75;
                    this.performanceData.performaceRate[2] = 93;
                }
            }
            else if (segment === SEGMENT_TYPE.STEP_UP) {
                // dynamic data
                if ((!this.flagService.featureFlags.isPrimerica && !this.flagService.featureFlags.isPlusGuard)) {
                    var hydratedArray = this.ieService.getDynamicStepUpData(this.ieService.dynamicData, segment, index_1, duration_1, buffer_1);
                    console.log('STEP UP - Hydrated array');
                    console.log(hydratedArray);
                    this.performanceData = tslib_1.__assign({}, hydratedArray.find(function (x) { return x.id === duration_1 && x.buffer === buffer_1 && x.index === index_1; }));
                }
                // // static
                // else {
                //   this.performanceData = this.stepUpSegmentData.performance;
                // }             
            }
            var details = this.ieService.parsePerformanceData(this.performanceData, segment, commission);
            //for line axis
            if (segment === SEGMENT_TYPE.ANNUAL_LOCK) {
                if (details && details.data && details.data.axis) {
                    details.data.axis.push(this.annualSegmentData.axis);
                }
                //also deduct any additional charges
                var finalReturn = void 0;
                if (additionalCharges) {
                    finalReturn =
                        details.data.line.data[details.data.line.data.length - 1].value -
                            ((this.ieService.getDollarAmount() * additionalCharges) / 100) *
                                details.data.line.data.length;
                    details.data.line.data[details.data.line.data.length - 1].value = finalReturn;
                }
                else {
                    finalReturn =
                        details.data.line.data[details.data.line.data.length - 1].value;
                }
                this.annualRate = this.translateService
                    .instant("howItsWorks.annualRate")
                    .replace("$$YEAR$$", details.data.line.data.length.toString())
                    .replace("$$BUFFER$$", buffer_1)
                    .replace("$$RETURN_PERCENTAGE$$", (((finalReturn - this.ieService.getDollarAmount()) /
                    this.ieService.getDollarAmount()) *
                    100).toFixed(2))
                    .replace("$$RETURN$$", finalReturn.toLocaleString().toString())
                    .replace("$$DBR$$", this.performanceData.additionalCharges);
                details.data.description =
                    this.annualRate +
                        "<br/><br>" +
                        this.translateService.instant("howItsWorks.howAnnualRateCalculated");
            }
            //add description
            details.data.showDescription = true;
            details.data.showLineToolTip = false;
            //set heading
            this.toolHeaderText = this.translateService
                .instant("howItsWorks.toolHeaderTextIE")
                .replace("$$YEAR$$", this.formGroup.get("duration").value)
                .replace("$$PERFORMANCE_CAP_RATE$$", this.performanceData.performaceCapRate)
                .replace("$$SEGMENT_BUFFER$$", this.formGroup.get("protection").value);
            if (segment === SEGMENT_TYPE.ENHANCED_UPSIDE) {
                this.toolHeaderText = this.translateService
                    .instant("howItsWorks.toolHeaderTextEnhancedUpside")
                    .replace("$$YEAR$$", this.formGroup.get("duration").value)
                    .replace("$$PERFORMANCE_CAP_RATE$$", this.performanceData.performaceCapRate)
                    .replace("$$SEGMENT_BUFFER$$", this.formGroup.get("protection").value);
            }
            if (this.series == IE_SERIES.B && segment === SEGMENT_TYPE.STANDARD && this.performanceData.performaceCapRate == 1000) {
                this.toolHeaderText = this.translateService
                    .instant("howItsWorks.toolHeaderTextUncapped")
                    .replace("$$YEAR$$", this.formGroup.get("duration").value)
                    .replace("$$PERFORMANCE_CAP_RATE$$", 'Uncapped Performance Cap Rate')
                    .replace("$$SEGMENT_BUFFER$$", this.formGroup.get("protection").value);
            }
            this.chartData = details.data;
            this.chartDetails = details.details;
            //saving for session
            this.saveProfile();
        }
    };
    HowSioWorksComponent.prototype.segmentChanged = function (event) {
        this.formGroup.get("marketScenerio").setValue(event.details.value);
    };
    /***
     * back button
     ***/
    HowSioWorksComponent.prototype.backButton = function () {
        this.utilityService.navigate(ROUTE_PATH.IE.TOOLS.HOW_SIO_WORKS);
    };
    /***
     *  Load default options
     ***/
    HowSioWorksComponent.prototype.loadDefaultsOptions = function () {
        this.formGroup.get("segment").setValue(this.items[0].id);
        this.formGroup.get("index").setValue(this.indices[0].id);
        this.formGroup.get("duration").setValue(this.durations[0].id);
        this.formGroup.get("protection").setValue(this.protections[0].id);
    };
    /***
     *  Load custom profile
     ***/
    HowSioWorksComponent.prototype.loadProfile = function () {
        var _this = this;
        this.ieService.getProfile().subscribe(function (profiles) {
            if (profiles && profiles.length > 0) {
                //get custom profile
                var profile = profiles.find(function (x) {
                    return x.name === _this.ieService.currentProfile &&
                        x.currentSerie === _this.ieService.series;
                });
                //set custom profile
                if (profile && profile.aboutSCSHowSioWorks) {
                    _this.formGroup
                        .get("segment")
                        .setValue(profile.aboutSCSHowSioWorks.segmentOption);
                    _this.formGroup
                        .get("index")
                        .setValue(profile.aboutSCSHowSioWorks.index);
                    _this.formGroup
                        .get("duration")
                        .setValue(parseInt(profile.aboutSCSHowSioWorks.duration));
                    _this.formGroup
                        .get("protection")
                        .setValue(parseInt(profile.aboutSCSHowSioWorks.protection));
                }
                else {
                    _this.loadDefaultsOptions();
                }
            }
            else {
                //set default profile if not data
                _this.loadDefaultsOptions();
            }
        });
    };
    /***
     *  Save Profile
     ***/
    HowSioWorksComponent.prototype.saveProfile = function (mode) {
        var _this = this;
        if (this.formGroup.valid) {
            var segment_1 = typeof this.formGroup.get("segment").value === "object"
                ? this.formGroup.get("segment").value.id
                : this.formGroup.get("segment").value;
            var duration = this.formGroup.get("duration").value;
            var buffer = this.formGroup.get("protection").value;
            var index = this.formGroup.get("index").value;
            //const enhancedUpside = this.formGroup.get("enhancedUpside").value;
            this.ieService.investorData.segment = segment_1;
            this.ieService.investorData.duration = duration;
            this.ieService.investorData.protection = buffer;
            this.ieService.investorData.index = index;
            var hypothetical_1 = new SCSHowSioWorks();
            hypothetical_1.segmentBuffer = buffer;
            hypothetical_1.segmentOption = segment_1;
            hypothetical_1.duration = duration;
            hypothetical_1.index = index;
            hypothetical_1.duration =
                duration + this.translateService.instant("howItsWorks.yr");
            hypothetical_1.protection = buffer + "%";
            // IF (ANNUAL LOCK AND SERIES PLUS - Pass updated values to back-end API)
            if (segment_1 == SEGMENT_TYPE.ANNUAL_LOCK && this.ieService.series == IE_SERIES.B) {
            }
            if (this.chartData && this.chartData.axis) {
                hypothetical_1.scaleMin = this.chartData.axis[0].min;
                hypothetical_1.scaleMax = this.chartData.axis[0].max;
                hypothetical_1.scaleStep = this.chartData.axis[0].interval;
                var id_1 = 0;
                this.chartData.bar.forEach(function (element, index) {
                    // add bar
                    var i = 1; //reverse the bar is report is demanding same
                    element.data.forEach(function (bar) {
                        hypothetical_1.bars[id_1] = element.data[i].value;
                        id_1++; // increase id for each bar
                        i--; //reverse for 2 count
                    });
                    // For dual direction, target the specific segment rate here of the 7th bar with the correct segment rate. Otherwise, we pass it the wrong value
                    if (segment_1 == SEGMENT_TYPE.DUAL_DIRECTION && _this.isNational) {
                        hypothetical_1.bars[1] = _this.performanceData.segmentRates[0];
                        hypothetical_1.bars[3] = _this.performanceData.segmentRates[1];
                        hypothetical_1.bars[5] = _this.performanceData.segmentRates[2];
                        hypothetical_1.bars[7] = _this.performanceData.segmentRates[3];
                    }
                    //add caps
                    hypothetical_1.performanceCap = element.lines[0].value;
                    hypothetical_1.segmentBuffer = element.lines[1].value;
                    hypothetical_1.commissions[index] = element.data[0].topValue;
                    hypothetical_1.scenarios[index] = true;
                });
            }
            hypothetical_1.scaleHeight = 275; //fix
            hypothetical_1.segmentValue = this.ieService.getDollarAmount();
            if (this.isInitalLoadCompleted &&
                this.ieService.customizationType ===
                    CUSTOMIZATION_TYPE.INVESTOR_PROFILE) {
                this.coreService.profile.next({
                    name: "",
                    type: CUSTOMIZATION_TYPE.REPORT,
                    profile: new Profile(),
                });
                this.ieService.customizationType = CUSTOMIZATION_TYPE.REPORT;
            }
            //save for profile
            if (this.ieService.customizationType === CUSTOMIZATION_TYPE.PROFILE) {
                this.ieService.storeProfile(CUSTOM_PROFILE.HOW_IT_WORKS, hypothetical_1);
            }
            else {
                //save for report
                this.ieService.report.aboutSCSHowSioWorks = hypothetical_1;
                this.ieService.storeProfile(CUSTOM_PROFILE.HOW_IT_WORKS, hypothetical_1, true);
            }
            if (mode && mode == CUSTOMIZATION_TYPE.PROFILE) {
                this.utilityService.presentWithoutTitleModal(ModalPopupComponent);
            }
            else if (mode && mode == CUSTOMIZATION_TYPE.REPORT) {
                this.utilityService.navigate(ROUTE_PATH.IE.TOOLS.CREATE_REPORT); // UPDATED
            }
        }
    };
    HowSioWorksComponent.prototype.openModal = function () {
        this.ieService.openModal(SioPopupComponent, "modal-css");
    };
    return HowSioWorksComponent;
}());
export { HowSioWorksComponent };
