import { Component, OnInit } from "@angular/core";
import { Theme, Orientation, Icon } from "equitable-component-library";
import { UtilityService } from "../../../core/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { ROUTE_PATH, BUILD_WEALTH, BUILD_WEALTH_TABS } from "./../../../constants/constants";

@Component({
  selector: "app-build-wealth",
  templateUrl: "./build-wealth.component.html",
  styleUrls: ["./build-wealth.component.scss"],
})
export class BuildWealthComponent implements OnInit {
  activeTab = BUILD_WEALTH.DIVERSIFY;
  type = Theme.Dark;
  orientation = Orientation.Left;
  backIcon = Icon.BackButton;
  tabsConfig: any = [];
  rc: string;
  selectedTab: string;
  segments

  constructor(
    private utilityService: UtilityService,
    private translate: TranslateService
  ) {
    this.segments = [
      BUILD_WEALTH.DIVERSIFY,
      BUILD_WEALTH.INVEST_TAX,

    ];
    this.segmentChanged(this.activeTab);
  }

  ngOnInit() { }

  backButton() {
    this.utilityService.navigateWithoutParams(ROUTE_PATH.RC.TOOLS.HOME);
  }

  segmentChanged(tabName: string) {
    this.activeTab = tabName;
    if (tabName === BUILD_WEALTH.INVEST_TAX) {
      this.selectedTab = BUILD_WEALTH_TABS.INVESTMENT_TAX_SMART;
    } else {
      this.selectedTab = BUILD_WEALTH_TABS.DIVERSIFY_PORTFOLIO;
    }
  }
}
