import { FeatureFlagService } from "../../../core/services/feature-flag.service";
import { OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CoreService } from "../../services/core.service";
import { InvestorProfile } from "../../../core/models/app.models";
import { UtilityService } from "../../../core/services/utility.service";
import { ScsService } from "../../../scs/services/scs.service";
import { SCS_SERIES, PRODUCT_TYPE, ROUTE_PATH, CUSTOMIZATION_TYPE, INVESTOR_PROFILE_REDIRECT, } from "../../../constants/constants";
import { IonicStorageService } from "../../../core/services/ionic-storage.service";
import { LinkIcon } from "equitable-component-library";
var InvestorProfileComponent = /** @class */ (function () {
    function InvestorProfileComponent(coreService, route, utilityService, scsService, storageService, flagService) {
        this.coreService = coreService;
        this.route = route;
        this.utilityService = utilityService;
        this.scsService = scsService;
        this.storageService = storageService;
        this.flagService = flagService;
        this.investorDataItems = [];
        this.listIndex = 0;
        this.isScs = false;
        this.productIndex = "";
        this.productDuration = "";
        this.productProtection = "";
        this.productUrl = "";
        this.linkRightIcon = LinkIcon.RightIcon;
        this.linkLeftIcon = LinkIcon.LeftIcon;
    }
    InvestorProfileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setinvestorDataIData();
        this.route.queryParams.subscribe(function (params) {
            _this.dashboardTool = params["section"];
        });
        this.isNotPlusGuard = !this.flagService.featureFlags.isPlusGuard;
    };
    // Method to get InvestorProfile data using translateservice
    InvestorProfileComponent.prototype.setinvestorDataIData = function () {
        var _this = this;
        this.seriesValue = this.scsService.series
            ? this.scsService.series
            : SCS_SERIES.PLUS;
        this.coreService
            .getInvestorProfileResults()
            .subscribe(function (data) {
            _this.investorDataItems = data;
            _this.investorHeader = _this.investorDataItems.header;
            _this.investorBodyCopy = _this.investorDataItems.bodyCopy;
            if (_this.flagService.featureFlags.isIR) {
                console.log('XXX1');
                if (_this.dashboardTool === PRODUCT_TYPE.SCS) {
                    console.log('XXX12');
                    _this.isScs = true;
                    var itemData_1;
                    _this.investorDataItems.scs.filter(function (item) {
                        if (_this.seriesValue === SCS_SERIES.B) {
                            itemData_1 = item["seriesB"];
                        }
                        else if (_this.seriesValue === SCS_SERIES.C) {
                            itemData_1 = item["seriesC"];
                        }
                        else if (_this.seriesValue === SCS_SERIES.PLUS21) {
                            console.log('XXX321');
                            //itemData = item["seriesPlus21"];
                            itemData_1 = item["seriesPlus"];
                        }
                        else if (_this.seriesValue === SCS_SERIES.PLUS) {
                            console.log('XXX3');
                            itemData_1 = item["seriesPlus"];
                        }
                        else if (_this.seriesValue === SCS_SERIES.ADV) {
                            itemData_1 = item["seriesADV"];
                        }
                    });
                    _this.investorData = itemData_1;
                }
                else if (_this.dashboardTool === PRODUCT_TYPE.SCSINCOME) {
                    _this.isScs = false;
                    _this.investorData = _this.investorDataItems.scsincome;
                }
                else if (_this.dashboardTool === PRODUCT_TYPE.RC) {
                    _this.isScs = false;
                    _this.investorData = _this.investorDataItems.rc;
                }
                else if (_this.dashboardTool === PRODUCT_TYPE.IE) {
                    _this.isScs = false;
                    _this.investorData = _this.investorDataItems.ie;
                }
            }
            else if (!_this.flagService.featureFlags.isPrimerica) {
                console.log('XXX3');
                _this.isScs = true;
                _this.investorData = _this.investorDataItems.wellsFargo;
            }
            else {
                console.log('XXX4');
                if (_this.dashboardTool === PRODUCT_TYPE.RC) {
                    _this.isScs = false;
                    _this.investorData = _this.investorDataItems.rc;
                }
                else {
                    _this.isScs = true;
                    _this.investorData = _this.investorDataItems.primerica;
                }
                if (_this.dashboardTool === PRODUCT_TYPE.SCS) {
                    console.log('XXX5');
                    _this.isScs = true;
                    var itemData_2;
                    _this.investorDataItems.primerica.filter(function (item) {
                        if (_this.seriesValue === SCS_SERIES.B) {
                            itemData_2 = item["seriesB"];
                        }
                        else if (_this.seriesValue === SCS_SERIES.C) {
                            itemData_2 = item["seriesC"];
                        }
                        else if (_this.seriesValue === SCS_SERIES.PLUS) {
                            itemData_2 = item["seriesPlus"];
                        }
                        else if (_this.seriesValue === SCS_SERIES.PLUS21) {
                            itemData_2 = item["seriesPlus21"];
                        }
                        else if (_this.seriesValue === SCS_SERIES.ADV) {
                            itemData_2 = item["seriesADV"];
                        }
                    });
                    _this.investorData = itemData_2;
                }
                else if (_this.dashboardTool === PRODUCT_TYPE.RC) {
                    _this.isScs = false;
                    _this.investorData = _this.investorDataItems.rc;
                }
            }
            if (_this.coreService.profile.getValue().name !== "" &&
                _this.coreService.profile.getValue().type ==
                    CUSTOMIZATION_TYPE.INVESTOR_PROFILE) {
                _this.listIndex = _this.investorData.findIndex(function (x) { return x.profileTitle == _this.coreService.profile.getValue().name; });
            }
            _this.displayDetail(_this.listIndex);
        });
    };
    InvestorProfileComponent.prototype.displayDetail = function (index) {
        this.listIndex = index;
        this.investorDataDetail = this.investorData[index];
        this.profileImage = this.investorDataDetail.profileImage;
        this.profileTitle = this.investorDataDetail.profileTitle;
        this.profileSubTitle = this.investorDataDetail.profileSubTitle;
        this.profileQuote = this.investorDataDetail.profileQuote;
        this.profileDescription = this.investorDataDetail.profileDescription;
        this.profileDetail = this.investorDataDetail.profileDetail;
        this.sectionName = this.investorDataDetail.profileSolutionCTA[0].sectionName;
        this.profileSolutionCTA = this.investorDataDetail.profileSolutionCTA;
        this.scenarios =
            this.investorDataDetail.scenarios !== undefined &&
                this.investorDataDetail.scenarios !== null &&
                this.investorDataDetail.scenarios.length > 0
                ? this.investorDataDetail.scenarios
                : "";
        this.ctaBackButton =
            this.investorDataDetail.ctaBackButton !== undefined &&
                this.investorDataDetail.ctaBackButton !== null
                ? this.investorDataDetail.ctaBackButton
                : "Back";
        if (this.scenarios.length > 0) {
            this.productIndex = this.scenarios[0].index;
            this.productDuration = this.scenarios[0].duration
                ? this.scenarios[0].duration + " Year"
                : this.scenarios[0].duration + " Years";
            this.productProtection = this.scenarios[0].protection + "%";
            this.productUrl = this.scenarios[0].url;
        }
    };
    // back button navigation
    InvestorProfileComponent.prototype.backButton = function () {
        this.utilityService.navigateToBack();
    };
    InvestorProfileComponent.prototype.gotoOverview = function (name) {
        if (this.flagService.featureFlags.isIR) {
            if (name === INVESTOR_PROFILE_REDIRECT.RC) {
                this.utilityService.navigate(ROUTE_PATH.RC.DISCOVER);
            }
            else if (name === INVESTOR_PROFILE_REDIRECT.IE) {
                this.utilityService.navigate(ROUTE_PATH.IE.DISCOVER);
            }
            else {
                this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER);
            }
        }
        else {
            this.utilityService.navigate(ROUTE_PATH.HOME);
        }
    };
    InvestorProfileComponent.prototype.goToButton = function () {
        this.utilityService.navigate(ROUTE_PATH.SCS.TOOLS.CUSTOM_PROFILE);
    };
    // On click Card button
    InvestorProfileComponent.prototype.navigateSelections = function (link, data) {
        this.coreService.profile.next({
            name: this.profileTitle,
            type: CUSTOMIZATION_TYPE.INVESTOR_PROFILE,
        });
        this.scsService.investorData = data;
        this.scsService.customizationType = CUSTOMIZATION_TYPE.INVESTOR_PROFILE;
        this.utilityService.navigate(ROUTE_PATH.SCS.TOOLS.HOW_SIO_WORKS_DETAILS);
    };
    InvestorProfileComponent.prototype.navigateButton = function (link) {
        this.utilityService.navigate(link);
    };
    return InvestorProfileComponent;
}());
export { InvestorProfileComponent };
