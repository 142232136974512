<!-- Mobile design back button -->
<div class="mobile-back">
  <equitable-link
    text="Back"
    iconName="arrow-back.svg"
    [type]="type"
    [orientation]="orientation"
    (click)="backButton()"
  ></equitable-link>
</div>
<!-- Web design back button -->
<div class="back-link">
  <equitable-link
    text=" {{ 'questionnaire.back-btn' | translate }} "
    [iconName]="backIcon"
    [type]="type"
    [orientation]="orientation"
    (click)="backButton()"
  ></equitable-link>
</div>
<div class="stepper">
  {{ 'questionnaire.questionaire1-stepper' | translate }}
</div>
<div class="title">{{ 'questionnaire.questionaire1-title' | translate }}</div>
<div class="brief">
  {{ 'questionnaire.questionaire1-subtitle' | translate }}
</div>
<ion-card-content class="card-container">
  <div class="cards" *ngFor="let card of cardMainconfg; let idx = index">
    <equitable-investor-type
      [iconName]="card.cardMainIcon"
      [title]="card.cardMainTitle"
      [description]="card.cardMainContent"
      (click)="selectedCard(idx)"
    ></equitable-investor-type>
  </div>
</ion-card-content>
