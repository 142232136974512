<ion-content>
  <div class="dashboard-page-wrapper">
    <div class="page-col col-first">
      <div class="back-arrow">
        <equitable-link
          [text]="'scsDashbaord.back' | translate"
          (click)="backButton()"
          [type]="LinkType.Light"
          [iconName]="LinkIcon.LeftIcon"
          [orientation]="Orientation.Left"
        ></equitable-link>
      </div>

      <!-- Dropdown Section -->
      <div class="resource-header-inner" *ngIf="isNotPlusGuard">
        <div class="resource-dropdown-container">
          <label class="resource-dropdown-label">{{
            "disclosure.filterByProduct" | translate
          }}</label>
          <div [formGroup]="dashboardForm" class="drop-down-prop">
            <equitable-drop-down
              class="equitable-dropdown"
              [formControl]="dashboardForm.get('seriesDropDown')"
              formControlName="seriesDropDown"
              [options]="seriesOptions"
            ></equitable-drop-down>
            <equitable-tooltip
              class="info-icon"
              [text]="toolTipText"
              css="--min-width: 412px;"
            ></equitable-tooltip>
          </div>
        </div>
      </div>

      <div class="heading" [innerHTML]="headingName"></div>

      <ul class="benefits" *ngIf="isDisplay">
        <li *appFeatureFlag="'isNational'">
          <span
            class="bold"
            *ngIf="
              'scsDashbaord.plusBenefit.timesOfUncertainty' | translate
            "
            [innerHTML]="
              'scsDashbaord.plusBenefit.timesOfUncertainty' | translate
            "
          ></span>
          
        </li>
        <li>
          <span
            class="bold"
            *ngIf="
              'scsDashbaord.plusBenefit.partialProtectionTitle' | translate
            "
            [innerHTML]="
              'scsDashbaord.plusBenefit.partialProtectionTitle' | translate
            "
          ></span
          ><span
            [innerHTML]="
              'scsDashbaord.plusBenefit.partialProtection' | translate
            "
          ></span>
         
        </li>
        <li>
          <span
            class="bold"
            *ngIf="
              'scsDashbaord.plusBenefit.structuredInvestmentOptionTitle'
                | translate
            "
            [innerHTML]="
              'scsDashbaord.plusBenefit.structuredInvestmentOptionTitle'
                | translate
            "
          ></span
          ><span
            [innerHTML]="
              'scsDashbaord.plusBenefit.structuredInvestmentOption' | translate
            "
          ></span>
          
        </li>
        <li>
          <span
            class="bold"
            *ngIf="'scsDashbaord.plusBenefit.zeroExplicitTitle' | translate"
            [innerHTML]="
              'scsDashbaord.plusBenefit.zeroExplicitTitle' | translate
            "
          ></span
          ><span
            [innerHTML]="'scsDashbaord.plusBenefit.zeroExplicit' | translate"
          ></span>
          
        </li>
      </ul>

      <!-- other benefit -->
      <ul class="benefits" *ngIf="!isDisplay">
        <li *appFeatureFlag="'isNational'">
          <span
            class="bold"
            *ngIf="
              'scsDashbaord.plusBenefit.timesOfUncertainty' | translate
            "
            [innerHTML]="
              'scsDashbaord.plusBenefit.timesOfUncertainty' | translate
            "
          ></span>
        </li>
        <li>
          <span
            class="bold"
            [innerHTML]="
              'scsDashbaord.otherBenefit.partialProtectionTitle' | translate
            "
          ></span>
          <span
            [innerHTML]="
              'scsDashbaord.otherBenefit.partialProtection' | translate
            "
          ></span>
        </li>
        <li>
          <span class="bold">{{
            "scsDashbaord.otherBenefit.structuredInvestmentOptionTitle"
              | translate
          }}</span
          >{{
            "scsDashbaord.otherBenefit.structuredInvestmentOption" | translate
          }}
        </li>
        <li>
          <span class="bold">{{
            "scsDashbaord.otherBenefit.zeroExplicitTitle" | translate
          }}</span>
          <span
            [innerHTML]="'scsDashbaord.otherBenefit.zeroExplicit' | translate"
          ></span>
        </li>
      </ul>
      <div class="separate-line" *ngIf="isNotPlusGuard"></div>
      <div class="title hyperlinkTitle" *ngIf="isNotPlusGuard">
        {{ "scsDashbaord.exploreTitle" | translate }}
      </div>
      <ul class="hyperLink" *appFeatureFlag="'isIR'">
          <li>
              <span
                (click)="goToScsIncomeLandingPage()"
                [innerHTML]="'scsDashbaord.incomeLink' | translate"
              ></span>
            </li>
        <li>
          <span xxxx
            (click)="goToRcLandingPage()"
            [innerHTML]="'scsDashbaord.rcLink' | translate"
          ></span>
        </li>
        <li>
          <span yyyy
            (click)="goToIeLandingPage()"
            [innerHTML]="'scsDashbaord.ieLink' | translate"
          ></span>
        </li>
      </ul>
      <ul class="hyperLink" *appFeatureFlag="'isPrimerica'">
        <li>
          <span xxx
            (click)="goToScsLandingPage()"
            [innerHTML]="'scsDashbaord.headerTitle' | translate"
          ></span>
        </li>
        <li>
          <span yyy
            (click)="goToRcLandingPage()"
            [innerHTML]="'scsDashbaord.rcLink' | translate"
          ></span>
        </li>
      </ul>
    </div>

    <div class="page-col col-second">
      <div class="content-col tools-div">
        <div class="separation-line">
          <!-- Tools START -->
          <div class="square-card" *ngFor="let tool of toolDashboardData.tools">
            <equitable-box-button
              [label]="tool.label"
              [icon]="tool.icon"
              (click)="cardClick(tool.routePath, tool.label, tool.newTab)"
            >
            </equitable-box-button>
          </div>
          <!-- Tools END -->
          <div></div>
        </div>
      </div>

      <div class="content-col learning-resources-div">
        <div class="title learning-resources-title">
          {{ "scsDashbaord.learningResources" | translate }}
        </div>
        <ul class="learning-resources">
          <li *ngFor="let resource of learningResources; let i = index">
            <equitable-link
              [text]="resource.description"
              class="resource-link"
              [type]="LinkType.Dark"
              (click)="goToResources(resource)"
            ></equitable-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <app-footer
    isWhiteFooterVisible="true"
    isBlueFooterVisible="false"
    [selectedProduct]="dashboardForm.get('seriesDropDown').value"
  ></app-footer>
</ion-content>
