import { FeatureFlagService } from "../../../core/services/feature-flag.service";
import { OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { PRODUCT_TYPE } from "src/app/constants/constants";
import { ActivatedRoute } from "@angular/router";
import { UtilityService } from "../../services/utility.service";
import { TranslateService } from "@ngx-translate/core";
var DisclosureComponent = /** @class */ (function () {
    function DisclosureComponent(route, utilityService, translate, flagService) {
        this.route = route;
        this.utilityService = utilityService;
        this.translate = translate;
        this.flagService = flagService;
        this.resourceForm = new FormGroup({
            resourceDropDown: new FormControl(this.discoverRcPage, Validators.required)
        });
        this.getSelectedScreen();
    }
    DisclosureComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.resourceForm.valueChanges.subscribe(function (data) {
            _this.dropDownSection(data.resourceDropDown);
        });
        // dropdown data
        this.translate
            .get("dropDown.investmentEdge")
            .subscribe(function (translated) {
            var nationalOptions = [
                {
                    id: PRODUCT_TYPE.IE,
                    value: translated
                },
                {
                    id: PRODUCT_TYPE.SCS,
                    value: _this.translate.instant("dropDown.structuredCapitalStrategies")
                },
                {
                    id: PRODUCT_TYPE.SCS_PLUS21,
                    value: _this.translate.instant("dropDown.structuredCapitalStrategiesPlus21")
                },
                {
                    id: PRODUCT_TYPE.SCSINCOME,
                    value: _this.translate.instant("dropDown.structuredCapitalStrategiesIncome")
                },
                {
                    id: PRODUCT_TYPE.SCS_PLUS,
                    value: _this.translate.instant("dropDown.structuredCapitalStrategiesPlus")
                },
                {
                    id: PRODUCT_TYPE.RC,
                    value: _this.translate.instant("dropDown.retirementCornerStone")
                }
            ];
            var primericaOptions = [
                {
                    id: PRODUCT_TYPE.SCS,
                    value: _this.translate.instant("dropDown.structuredCapitalStrategies")
                },
                {
                    id: PRODUCT_TYPE.SCS_PLUS,
                    value: _this.translate.instant("dropDown.structuredCapitalStrategiesPlus")
                },
                {
                    id: PRODUCT_TYPE.SCS_PLUS21,
                    value: _this.translate.instant("dropDown.structuredCapitalStrategiesPlus21")
                },
                {
                    id: PRODUCT_TYPE.RC,
                    value: _this.translate.instant("dropDown.retirementCornerStone")
                }
            ];
            _this.showDisclosureDropdown = _this.flagService.featureFlags.isPrimerica || _this.flagService.featureFlags.isNational;
            if (_this.flagService.featureFlags.isPrimerica) {
                _this.resourceOptions = primericaOptions;
            }
            else {
                _this.resourceOptions = nationalOptions;
            }
        });
    };
    DisclosureComponent.prototype.dropDownSection = function (data) {
        this.displayRc = false;
        this.displayScs = false;
        this.displayScsPlus = false;
        this.displayScsPlus21 = false;
        this.displayIe = false;
        this.displayScsIncome = false;
        if (data === PRODUCT_TYPE.RC) {
            this.displayRc = true;
        }
        else if (data === PRODUCT_TYPE.SCS_PLUS21) {
            this.displayScsPlus21 = true;
        }
        else if (data === PRODUCT_TYPE.SCS_PLUS) {
            this.displayScsPlus = true;
        }
        else if (data === PRODUCT_TYPE.IE) {
            this.displayIe = true;
        }
        else if (data === PRODUCT_TYPE.SCSINCOME) {
            this.displayScsIncome = true;
        }
        else {
            this.displayScs = true;
        }
    };
    DisclosureComponent.prototype.Section = function (data) {
        if (data === PRODUCT_TYPE.RC) {
            this.displayRc = true;
            this.resourceForm.get("resourceDropDown").setValue(PRODUCT_TYPE.RC);
        }
        else if (data === PRODUCT_TYPE.SCS_PLUS21) {
            this.displayScsPlus21 = true;
            this.resourceForm.get("resourceDropDown").setValue(PRODUCT_TYPE.SCS_PLUS21);
        }
        else if (data === PRODUCT_TYPE.SCS_PLUS) {
            this.displayScsPlus = true;
            this.resourceForm.get("resourceDropDown").setValue(PRODUCT_TYPE.SCS_PLUS);
        }
        else if (data === PRODUCT_TYPE.SCSINCOME) {
            this.displayScsIncome = true;
            this.resourceForm.get("resourceDropDown").setValue(PRODUCT_TYPE.SCSINCOME);
        }
        else if (data === PRODUCT_TYPE.IE) {
            this.displayIe = true;
            this.resourceForm.get("resourceDropDown").setValue(PRODUCT_TYPE.IE);
        }
        else {
            this.displayScs = true;
            this.resourceForm.get("resourceDropDown").setValue(PRODUCT_TYPE.SCS);
        }
    };
    DisclosureComponent.prototype.getSelectedScreen = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.discoverRcPage = params["product"];
            _this.selectedTool = params["tool"];
            _this.Section(_this.discoverRcPage);
        });
    };
    // back button navigation
    DisclosureComponent.prototype.backButton = function () {
        this.utilityService.navigateToBack();
    };
    return DisclosureComponent;
}());
export { DisclosureComponent };
