import { FeatureFlagService } from '../services/feature-flag.service';
import { Directive, ViewContainerRef, TemplateRef, Input } from '@angular/core';

@Directive({
  selector: '[appFeatureFlag]'
})
export class FeatureFlagDirective {
  @Input() appFeatureFlag: string;

  constructor(private viewController: ViewContainerRef,
    private template: TemplateRef<any>,
    private featureService: FeatureFlagService) { }

  ngOnInit() {
    if (this.featureService.featureFlags[this.appFeatureFlag]) {
      this.viewController.createEmbeddedView(this.template);
    }
  }
}
