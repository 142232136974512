import { HowitworksToolComponent } from "./components/howitworks-tool/howitworks-tool.component";
import { Routes } from "@angular/router";
import { DiscoverComponent } from "./components/discover/discover.component";
import { IeToolsComponent } from "./components/ie-tools/ie-tools.component";
import { IeAdvancedToolResourcesDashboardComponent } from "./components/ie-advanced-tool-resources-dashboard/ie-advanced-tool-resources-dashboard.component";
import { ROUTE_PATH, PRODUCT_TYPE } from "../constants/constants";
import { ReportIeComponent } from "./components/report-ie/report-ie.component";
import { FeatureFlagGuard } from '../ffguard';
import { BuildWealthComponent } from "./components/build-wealth/build-wealth.component";
import { FundFocusComponent } from "./components/fund-focus/fund-focus.component";
import { HowSioWorksComponent } from "./components/how-sio-works/how-sio-works.component";
import { SioComponent } from "./components/sio/sio.component";
import { ScsPiePicturesComponent } from "./components/scs-pie-pictures/scs-pie-pictures.component";
import { MarketTrendIndicatorComponent } from "./components/market-trend-indicator/market-trend-indicator.component";
var ɵ0 = {
    flags: PRODUCT_TYPE.IE
}, ɵ1 = {
    flags: PRODUCT_TYPE.IE
}, ɵ2 = {
    flags: PRODUCT_TYPE.IE
}, ɵ3 = {
    flags: PRODUCT_TYPE.IE
}, ɵ4 = {
    flags: PRODUCT_TYPE.IE
}, ɵ5 = {
    flags: PRODUCT_TYPE.IE
};
var routes = [
    {
        path: "",
        pathMatch: "full",
        redirectTo: "/" + ROUTE_PATH.IE.DISCOVER
    },
    {
        path: ROUTE_PATH.IE.DISCOVER,
        component: DiscoverComponent,
        canActivate: [FeatureFlagGuard],
        data: ɵ0,
    },
    {
        path: "ie/tools",
        component: IeToolsComponent,
        canActivate: [FeatureFlagGuard],
        data: ɵ1,
    },
    {
        path: ROUTE_PATH.IE.TOOLS.HOME,
        component: IeAdvancedToolResourcesDashboardComponent,
        canActivate: [FeatureFlagGuard],
        data: ɵ2,
    },
    {
        path: ROUTE_PATH.IE.TOOLS.HOW_IT_WORKS,
        component: HowitworksToolComponent,
        canActivate: [FeatureFlagGuard],
        data: ɵ3,
    },
    {
        path: ROUTE_PATH.IE.TOOLS.FUND_FOCUS,
        component: FundFocusComponent,
        canActivate: [FeatureFlagGuard],
        data: ɵ4,
    },
    {
        path: ROUTE_PATH.IE.TOOLS.DIVERSIFICATION,
        component: BuildWealthComponent,
        canActivate: [FeatureFlagGuard],
        data: ɵ5,
    },
    /*   {
        path: ROUTE_PATH.IE.TOOLS.HOW_SIO_WORKS_DETAILS,
        component: HowSioWorksComponent,
      },
      {
        path: ROUTE_PATH.IE.TOOLS.HOW_SIO_WORKS,
        component: SioComponent,
        canActivate: [FeatureFlagGuard],
        data: {
          flags: PRODUCT_TYPE.IE
        },
      }, */
    {
        path: ROUTE_PATH.IE.TOOLS.HOW_SIO_WORKS_DETAILS,
        component: HowSioWorksComponent,
    },
    {
        path: ROUTE_PATH.IE.TOOLS.HOW_SIO_WORKS,
        component: SioComponent,
    },
    {
        path: ROUTE_PATH.IE.TOOLS.HISTORICAL_PERFORMANCE_PLUS,
        component: ScsPiePicturesComponent,
    },
    {
        path: ROUTE_PATH.IE.TOOLS.MARKET_TREND,
        component: MarketTrendIndicatorComponent,
    },
    {
        path: ROUTE_PATH.IE.TOOLS.CREATE_REPORT,
        component: ReportIeComponent,
    }
];
var IeRoutingModule = /** @class */ (function () {
    function IeRoutingModule() {
    }
    return IeRoutingModule;
}());
export { IeRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
