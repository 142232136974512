import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { RequestOption } from "../models/app.models";
import { BUTTON_ROLE } from "../../constants/constants";
import { UtilityService } from "./utility.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class HttpRequestService {
  constructor(
    private httpClient: HttpClient,
    private utilityService: UtilityService,
    private translate: TranslateService
  ) {}

  private getHttpHeaders(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json",
    });
  }

  public request<T>(requestOption: RequestOption): Observable<T> {
    return this.httpClient
      .request<T>(requestOption.method, requestOption.url, {
        headers: requestOption.headers
          ? requestOption.headers
          : this.getHttpHeaders(),
        body: requestOption.body,
        params: requestOption.params,
        observe: "response",
        responseType: requestOption.isblob
          ? ("blob" as "json")
          : ("text" as "json"),
      })
      .pipe(
        map((data: any) => {
          const body = data.body;
          if (body) {
            if (typeof body == "string") {
              try {
                data.body = JSON.parse(body);
              } catch (e) {
                data.body = body;
              }
            }
            return data as T;
          } else {
            this.handleError(data, requestOption.isBluk);
          }
        }),
        catchError((data) => {
          this.handleError(data, requestOption.isBluk);
          throw throwError(data.error);
        })
      );
  }

  private handleError = (data, isBluk): Observable<any[]> => {
    console.log("ERROR", data)
    console.log("ERROR", isBluk)
    if (data.status != 200) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const _this = this;
      if (!isBluk) {
        this.utilityService.showAlert({
          message: this.translate.instant("general.serverError"),
          title: "",
          buttons: [
            {
              text: this.translate.instant("general.ok"),
              role: BUTTON_ROLE.OK,
              handler: function () {
                _this.utilityService.alertPresent = false;
              },
            },
          ],
        });
      }
    }
    // this.utilityService.navigate(ROUTE_PATH.HOME);
    this.utilityService.hideLoader();
    throw throwError(data.error);
  };
}
