<ion-content>
  <div class="legacy-content">
    <equitable-sub-header
      title="{{ 'protectSCSIncome.title' | translate }}"
      backLinkText="{{ 'protectSCSIncome.backButtonText' | translate }}"
      (backClick)="backButton()"
    ></equitable-sub-header>
    <ion-card class="tools-card" *ngIf="activeTab">
      <ion-segment
        mode="md"
        class="segment-class"
        (ionChange)="segmentChanged($event.detail.value)"
      >
        <ion-segment-button
          *ngFor="let item of segments"
          mode="md"
          class="segment-button-class"
          [value]="item"
          [checked]="activeTab === item"
        >
          <ion-label class="segment-heading"
            >{{ "protectSCSIncome." + item + ".title" | translate }}
          </ion-label>
        </ion-segment-button>
      </ion-segment>
      <app-rip *ngIf="activeTab === RIP"></app-rip>
      <app-investment-options *ngIf="activeTab === IO"></app-investment-options>
      <app-guaranteed-income *ngIf="activeTab === GIL"></app-guaranteed-income>
      <app-rb *ngIf="activeTab === RB"></app-rb>
    </ion-card>
  </div>
  <app-footer
    isWhiteFooterVisible="true"
    isBlueFooterVisible="true"
    [activeTab]="activeTab"
    selectedProduct="income"
  ></app-footer>
</ion-content>
