import { Injectable } from "@angular/core";
import { HttpResponse, HttpHeaders } from "@angular/common/http";
import { from, Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { File } from "@ionic-native/file/ngx";
import { FILE_EXTENSION, MIME_TYPE } from "../../constants/constants";

@Injectable({
  providedIn: "root"
})
export class FileSystemService {
  constructor(private file: File) {}

  // write to existing file, if does not exist, then create and write
  createAndWrite<T>(filename: string, data: Blob): Promise<T | any> {
    return this.file
      .checkFile(this.file.dataDirectory, filename)
      .then(() => {
        return this.writeFile<T>(filename, data);
      })
      .catch(() => {
        return this.file
          .createFile(this.file.dataDirectory, filename, false)
          .then(() => this.writeFile(filename, data));
      });
  }

  // write to existing file, if does not exist, then create and write
  writeFile<T>(filename: string, data: Blob): Promise<T | any> {
    return this.file.writeFile(this.file.dataDirectory, filename, data, {
      replace: true,
      append: false
    });
  }

  // read binary file
  readFile(filename: string): Observable<HttpResponse<Blob>> {
    const extension: string = filename.split(".").pop();
    let contentType: string;
    if (extension === FILE_EXTENSION.JPG) {
      contentType = MIME_TYPE.JPG;
    } else if (extension === FILE_EXTENSION.JPEG) {
      contentType = MIME_TYPE.JPG;
    } else if (extension === FILE_EXTENSION.PNG) {
      contentType = MIME_TYPE.PNG;
    } else if (extension === FILE_EXTENSION.PDF) {
      contentType = MIME_TYPE.PDF;
    }

    // read content of file
    const contentPromise: Promise<ArrayBuffer> = this.file.readAsArrayBuffer(
      this.file.dataDirectory,
      filename
    );

    // read modification date of file
    const metaPromise: Promise<number> = new Promise((resolve, reject) => {
      this.file
        .resolveLocalFilesystemUrl(this.file.dataDirectory + "/" + filename)
        .then(file => {
          file.getMetadata(
            meta => {
              resolve(new Date(meta.modificationTime).getTime());
            },
            error => {
              reject(error);
            }
          );
        });
    });

    // combine content and modification date
    return from(Promise.all([contentPromise, metaPromise])).pipe(
      catchError(err => {
        console.log("error: " + err + " " + filename);
        return throwError(err);
      }),
      map(data => {
        const headers = new HttpHeaders({
          "Content-Type": contentType,
          "X-cached-data": "true",
          "X-last-modified-date": data[1] + ""
        });
        return new HttpResponse({
          body: new Blob([data[0]]),
          headers: headers
        });
      })
    );
  }
}
