<ion-content>
  <div class="dashboard-page-wrapper">
    <div class="page-col col-first">
      <div class="back-arrow">
        <equitable-link
          [text]="'rcDashbaord.back' | translate"
          (click)="backButton()"
          [type]="LinkType.Light"
          [iconName]="LinkIcon.LeftIcon"
          [orientation]="Orientation.Left"
        ></equitable-link>
      </div>

      <div
        class="heading"
        [innerHTML]="'rcDashbaord.headerTitle' | translate"
      ></div>

      <ul class="benefits">
        <li>
          <span class="bold">{{
            "rcDashbaord.investmentAccountTitle" | translate
          }}</span
          >{{ "rcDashbaord.investmentAccount" | translate }}
        </li>
        <li>
          <span class="bold">{{
            "rcDashbaord.protectedBenefitTitle" | translate
          }}</span
          >{{ "rcDashbaord.protectedBeneift" | translate }}
        </li>
        <li>
          <span class="bold">{{
            "rcDashbaord.abilityToTransferTitle" | translate
          }}</span
          >{{ "rcDashbaord.abilityToTransfer" | translate }}
        </li>
      </ul>
      <div class="separate-line"></div>
      <div class="title hyperlinkTitle">
        {{ "rcDashbaord.exploreTitle" | translate }}
      </div>
      <ul class="hyperLink">
        <li *appFeatureFlag="'isPrimerica'">
          <span
            (click)="goToScsPLUSLandingPage()"
            [innerHTML]="'rcDashbaord.scsPLUSLink' | translate"
          ></span>
        </li>
        <li *appFeatureFlag="'isNational'">
            <span
              (click)="goToScsIncomeLandingPage()"
              [innerHTML]="'rcDashbaord.incomeLink' | translate"
            ></span>
          </li>
        <li>
          <span
            (click)="goToScsLandingPage()"
            [innerHTML]="'rcDashbaord.scsLink' | translate"
          ></span>
        </li>
        <li>
          <span   *appFeatureFlag="'isIR'"
            (click)="goToIeLandingPage()"
            [innerHTML]="'rcDashbaord.ieLink' | translate"
          ></span>
        </li>
      </ul>
    </div>

    <div class="page-col col-second">
      <div class="content-col tools-div">
        <div class="separation-line">
          <!-- Tools START -->
          <div class="square-card" *ngFor="let tool of toolDashboardData.tools">
            <equitable-box-button
              [label]="tool.label | translate"
              [icon]="tool.icon"
              (click)="cardClick(tool.routePath)"
            >
            </equitable-box-button>
          </div>
          <!-- Tools END -->
          <div></div>
        </div>
      </div>

      <div class="content-col learning-resources-div">
        <div class="title learning-resources-title">
          {{ "rcDashbaord.learningResources" | translate }}
        </div>
        <ul class="learning-resources">
          <li *ngFor="let item of toolDashboardData.resource; let i = index">
            <equitable-link
              [text]="item.description"
              class="resource-link"
              [type]="LinkType.Dark"
              (click)="goToResources(i)"
            ></equitable-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <app-footer
    isWhiteFooterVisible="true"
    isBlueFooterVisible="true"
    selectedProduct="rc"
  ></app-footer>
</ion-content>
