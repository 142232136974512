<ion-content>
  <div class="container">
    <div class="arround-space">
      <img
        alt="Image of a sad computer"
        class="img404"
        src="./assets/images/404-icon.svg"
      />
      <div class="heading">
        {{ "pageNotFound.heading" | translate }}
      </div>
      <div class="noFound">
        {{ "pageNotFound.notFound" | translate }}
      </div>
      <div class="content">
        {{ "pageNotFound.content" | translate }}
      </div>
      <div class="content">
        <equitable-button [type]="type" [theme]="theme" (click)="home()">{{
          "hamburgerMenu.home" | translate
        }}</equitable-button>
      </div>
    </div>
  </div>
  <app-footer isBlueFooterVisible="true" selectedProduct="home"></app-footer>
</ion-content>
