import { Component, OnInit } from "@angular/core";
import { ButtonType, LinkType, Icon } from "equitable-component-library";
import { PRODUCT_TYPE, ROUTE_PATH } from "../../../constants/constants";
import { UtilityService } from "../../../core/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { ImageCard } from "../../../core/models/app.models";

@Component({
  selector: "app-discover",
  templateUrl: "./discover.component.html",
  styleUrls: ["./discover.component.scss"],
})
export class DiscoverComponent implements OnInit {
  image: string = "./assets/images/discover-rc-banner.png";
  LinkType = LinkType;
  ButtonType = ButtonType;
  guaranteedIncome: ImageCard = {
    headingSmall: "",
    listDetail: [],
  };
  deferYourTaxes: ImageCard = {
    headingSmall: "",
    listDetail: [],
  };
  scsincomeBenefits: ImageCard = {
    headingSmall: "",
    listDetail: [],
  };

  featureCards: any = [
    {
      icon: Icon.Investment,
      title: "discoverRc.opportunitiesForGrowth",
      description: "discoverRc.opportunitiesForGrowthDescription",
    },
    {
      icon: Icon.Lock,
      title: "discoverRc.protectionThatLasts",
      description: "discoverRc.protectionThatLastsDescription",
    },
    {
      icon: Icon.Arrow,
      title: "discoverRc.flexibilityForTheFuture",
      description: "discoverRc.flexibilityForTheFutureDescription",
    },
  ];

  constructor(
    private utilityService: UtilityService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.translate.get("discoverRc").subscribe((discoverRc) => {
      this.guaranteedIncome = {
        headingSmall: discoverRc.benefitsTitle,
        listDetail: [
          discoverRc.partialDownside,
          discoverRc.upsidePotential,
          discoverRc.zeroExplicit,
        ],
      };
      this.deferYourTaxes = {
        headingSmall: discoverRc.benefitsTitle,
        listDetail: [
          discoverRc.taxDeferral,
          discoverRc.taxEfficient,
          discoverRc.diversification,
        ],
      };
      this.scsincomeBenefits = {
        headingSmall: discoverRc.benefitsTitle,
        listDetail: [
          discoverRc.lifetimeIncome,
          discoverRc.levelDownside,
          discoverRc.lowCost,
        ],
      };
    });
  }

  gotoSCSLandingPage(): void {
    this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER + '?series=scs16')
  }
  gotoSCSPLUSLandingPage(): void {
    this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER + '?series=PLUS')
  }
  gotoSCSINCOMELandingPage(): void {
    document.querySelector('.hero-main').scrollIntoView(true)
    this.utilityService.navigate(ROUTE_PATH.SCSINCOME.DISCOVER + '?series=B')
  }

  gotoSCSPLUS21LandingPage(): void {
    this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER + '?series=PLUS21')
  }

  gotoIELandingPage(): void {
    this.utilityService.navigateWithoutParams(ROUTE_PATH.IE.DISCOVER);
  }

  gotoAdvancedToolPage(): void {
    this.utilityService.navigateWithoutParams(ROUTE_PATH.RC.TOOLS.HOME);
  }

  // Chandan: unused code?
  // Navigate to view all disclosure
  // goToDisclosure(): void {
  //   this.utilityService.navigate(ROUTE_PATH.DISCLOSURE, {
  //     appId: PRODUCT_TYPE.RC,
  //   });
  // }
}
