<ion-content>
  <div class="dashboard-page-wrapper">
    <div class="page-col col-first">
      <div class="back-arrow">
        <equitable-link
          [text]="'ieDashbaord.back' | translate"
          (click)="backButton()"
          [type]="LinkType.Light"
          [iconName]="LinkIcon.LeftIcon"
          [orientation]="Orientation.Left"
        ></equitable-link>
      </div>
      

      <div
        class="heading"
        [innerHTML]="'ieDashbaord.headerTitle' | translate"
      ></div>
      <ul class="benefits">
        <li>
          <span
            class="bold"
            [innerHTML]="'ieDashbaord.investmentAccountTitle' | translate"
          ></span
          ><span
            [innerHTML]="'ieDashbaord.investmentAccount' | translate"
          ></span>
        </li>
        <li>
          <span class="bold">{{
            "ieDashbaord.taxDeferralTitle" | translate
          }}</span
          >{{ "ieDashbaord.taxDeferral" | translate }}
        </li>
        <li>
          <span class="bold">{{
            "ieDashbaord.taxEfficientTitle" | translate
          }}</span
          >{{ "ieDashbaord.taxEfficient" | translate }}
        </li>
      </ul>
      <div class="separate-line"></div>
      <div class="title hyperlinkTitle">
        {{ "ieDashbaord.exploreTitle" | translate }}
      </div>
      <ul class="hyperLink">
        <li>
          <span
            (click)="goToRcLandingPage()"
            [innerHTML]="'ieDashbaord.rcLink' | translate"
          ></span>
        </li>
        <li>
            <span
              (click)="goToScsIncomeLandingPage()"
              [innerHTML]="'ieDashbaord.incomeLink' | translate"
            ></span>
          </li>
        <li>
          <span
            (click)="goToScsLandingPage()"
            [innerHTML]="'ieDashbaord.scsLink' | translate"
          ></span>
        </li>
      </ul>
    </div>

    <div class="page-col col-second">
      <div class="content-col tools-div">
        <div class="separation-line">
          <!-- Tools START -->
          <div class="square-card" *ngFor="let tool of toolsDashboardData.tools">
            <equitable-box-button
              [label]="tool.label | translate"
              [icon]="tool.icon"
              (click)="cardClick(tool.routePath, tool.label, tool.newTab)"
            >
            </equitable-box-button>
          </div>
          <!-- Tools END -->
          <div></div>
        </div>
      </div>

      <div class="content-col learning-resources-div">
        <div class="title learning-resources-title">
          {{ "ieDashbaord.learningResources" | translate }}
        </div>
        <ul class="learning-resources">
          <li *ngFor="let resource of learningResources; let i = index">
            <equitable-link
              [text]="resource.description"
              class="resource-link"
              [type]="LinkType.Dark"
              (click)="goToResources(resource)"
            ></equitable-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <app-footer
    isWhiteFooterVisible="true"
    isBlueFooterVisible="true"
    selectedProduct="ie"
  ></app-footer>
</ion-content>
