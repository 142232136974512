import { FeatureFlagService } from "./feature-flag.service";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { throwError, Subject, BehaviorSubject } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Observable } from "rxjs";
import { HamburgerMenu, Footer, Profile } from "../models/app.models";
import {
  ROUTE_PATH,
  PRODUCT_TYPE,
  PROSPECTUS_PRODUCTS_TYPE,
  BUTTON_ROLE,
  SCS_SERIES,
  IE_SERIES,
  SITE_NAME,
} from "../../constants/constants";
import { SITE } from "src/assets/sites";
import { HttpRequestService } from "../../../app/core/services/http-request.service";
import { API_METHODS, MIME_TYPE } from "../../../app/constants/constants";
import { RequestOption } from "../models/app.models";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { saveAs } from "file-saver";
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { Platform } from "@ionic/angular";
import { UtilityService } from "../services/utility.service";
import { environment } from "../../../environments/environment";


@Injectable({
  providedIn: "root",
})
export class CoreService {
  requestOptions: RequestOption;
  private reportData: Profile;
  product = ""; 


  profile = new BehaviorSubject<{
    name: string;
    type: string;
    profile?: Profile;
  }>({
    name: "",
    type: "",
    profile: new Profile(),
  });
  constructor(
    private translate: TranslateService,
    private httpRequestService: HttpRequestService,
    private fileOpener: FileOpener,
    private platform: Platform,
    private utilityService: UtilityService,
    private flagService: FeatureFlagService,

  ) {
    this.reportData = new Profile();
  }

  getHamburgermenu(): Observable<HamburgerMenu[]> {
    return this.translate.get("hamburgerMenu").pipe(
      map((hamburgerMenu) => {
        var i = 1;
        const menu: HamburgerMenu[] = [
          {
            id: i,
            name: hamburgerMenu.home,
            path: ROUTE_PATH.HOME,
            isPrimary: true,
            isSignIn: false,
            isSubMenu: false,
          },
        ];
        // if (this.flagService.featureFlags["scsPLUS"]) {
          
        //   const subMenu = [];
        //   if (this.flagService.featureFlags["scsPLUS"]["productHomepage"]) {
        //     subMenu.push({
        //       name: hamburgerMenu.products,
        //       path: ROUTE_PATH.SCS.DISCOVER,
        //     });
        //     subMenu.push({
        //       name: hamburgerMenu.tools,
        //       path: ROUTE_PATH.SCS.TOOLS.HOME,
        //     });
        //     i = i + 1;
        //     menu.push({
        //       id: i,
        //       name: hamburgerMenu.scsPLUS,
        //       path: "/",
        //       isPrimary: true,
        //       isSignIn: false,
        //       isSubMenu: true,
        //       subMenu: subMenu,
        //     });
        //   } else if (this.flagService.featureFlags["scsPLUS"]["toolsHomePage"]) {
        //     i = i + 1;
        //     menu.push({
        //       id: i,
        //       name: hamburgerMenu.tools,
        //       path: ROUTE_PATH.SCS.TOOLS.HOME,
        //       isPrimary: true,
        //       isSignIn: false,
        //       isSubMenu: false,
        //     });
        //   }
        // }
        /* if (this.flagService.featureFlags["ie"]) {
          const subMenu = [];
          if (this.flagService.featureFlags["ie"]["productHomepage"]) {
            subMenu.push({
              name: hamburgerMenu.products,
              //path: ROUTE_PATH.SCS.DISCOVER
              path: ROUTE_PATH.IE.DISCOVER + '?series=ie',
            });
            subMenu.push({
              name: hamburgerMenu.tools,
              path: ROUTE_PATH.IE.TOOLS.HOME,
            });
            i = i + 1;
            menu.push({
              id: i,
              name: hamburgerMenu.ie,
              path: "/",
              isPrimary: true,
              isSignIn: false,
              isSubMenu: true,
              subMenu: subMenu,
            });
          } else if (this.flagService.featureFlags["ie"]["toolsHomePage"]) {
            i = i + 1;
            menu.push({
              id: i,
              name: hamburgerMenu.ie,
              path: ROUTE_PATH.IE.TOOLS.HOME,
              isPrimary: true,
              isSignIn: false,
              isSubMenu: false,
            });
          }
        } */


        if (this.flagService.featureFlags["scsincome"]) {
          const subMenu = [];
          if (this.flagService.featureFlags["scsincome"]["productHomepage"]) {
            subMenu.push({
              name: hamburgerMenu.products,
              //path: ROUTE_PATH.SCS.DISCOVER
              path: ROUTE_PATH.SCSINCOME.DISCOVER + '?series=income',
            });
            subMenu.push({
              name: hamburgerMenu.tools,
              path: ROUTE_PATH.SCSINCOME.TOOLS.HOME,
            });
            i = i + 1;
            menu.push({
              id: i,
              name: hamburgerMenu.scsincome,
              path: "/",
              isPrimary: true,
              isSignIn: false,
              isSubMenu: true,
              subMenu: subMenu,
            });
          } else if (this.flagService.featureFlags["scsincome"]["toolsHomePage"]) {
            i = i + 1;
            menu.push({
              id: i,
              name: hamburgerMenu.tools,
              path: ROUTE_PATH.SCSINCOME.TOOLS.HOME,
              isPrimary: true,
              isSignIn: false,
              isSubMenu: false,
            });
          }
        }

    

        if (this.flagService.featureFlags["rc"]) {
          i = i + 1;
          menu.push({
            id: i,
            name: hamburgerMenu.rc,
            path: "/",
            isPrimary: true,
            isSignIn: false,
            isSubMenu: true,
            subMenu: [
              {
                name: hamburgerMenu.products,
                path: ROUTE_PATH.RC.DISCOVER,
              },
              {
                name: hamburgerMenu.tools,
                path: ROUTE_PATH.RC.TOOLS.HOME,
              },
            ],
          });
        }
        
        if (this.flagService.featureFlags["scs"]) {
          const subMenu = [];

          if (this.flagService.featureFlags.isNational) {
            subMenu.push({
              name: hamburgerMenu.products,
              //path: ROUTE_PATH.SCS.DISCOVER
              path: ROUTE_PATH.SCS.DISCOVER + '?series=PLUS21',
            });
            subMenu.push({
              name: hamburgerMenu.tools,
              path: ROUTE_PATH.SCS.TOOLS.HOME,
            });
            i = i + 1;
            menu.push({
              id: i,
              name: hamburgerMenu.scs,
              path: "/",
              isPrimary: true,
              isSignIn: false,
              isSubMenu: true,
              subMenu: subMenu,
            });
          } else if (this.flagService.featureFlags.isPrimerica) {
            subMenu.push({
              name: hamburgerMenu.products,
              //path: ROUTE_PATH.SCS.DISCOVER
              path: ROUTE_PATH.SCS.DISCOVER + '?series=PLUS21',
            });
            subMenu.push({
              name: hamburgerMenu.tools,
              path: ROUTE_PATH.SCS.TOOLS.HOME,
            });
            i = i + 1;
            menu.push({
              id: i,
              name: hamburgerMenu.scs,
              path: "/",
              isPrimary: true,
              isSignIn: false,
              isSubMenu: true,
              subMenu: subMenu,
            });
          } else if (this.flagService.featureFlags["scs"]["toolsHomePage"]) {
            i = i + 1;
            menu.push({
              id: i,
              name: hamburgerMenu.tools,
              path: ROUTE_PATH.SCS.TOOLS.HOME,
              isPrimary: true,
              isSignIn: false,
              isSubMenu: false,
            });
          }
        }

        if (this.flagService.featureFlags["ie"]) {
          i = i + 1
          menu.push({
            id: i,
            name: hamburgerMenu.ie,
            path: "/",
            isPrimary: true,
            isSignIn: false,
            isSubMenu: true,
            subMenu: [
              {
                name: hamburgerMenu.products,
                path: ROUTE_PATH.IE.DISCOVER,
              },
              {
                name: hamburgerMenu.tools,
                path: ROUTE_PATH.IE.TOOLS.HOME,
              },
            ],
          });
        } 
        else if (this.flagService.featureFlags["ie"]["toolsHomePage"]) {
          i = i + 1;
          menu.push({
            id: i,
            name: hamburgerMenu.tools,
            path: ROUTE_PATH.IE.TOOLS.HOME,
            isPrimary: true,
            isSignIn: false,
            isSubMenu: false,
          });
        } 

        
        if (this.flagService.featureFlags["questionnaire"]) {
          i = i + 1
          menu.push({
            id: i,
            name: hamburgerMenu.findMatch,
            path: ROUTE_PATH.QUESTIONNAIRE,
            isPrimary: true,
            isSignIn: false,
            isSubMenu: false,
          });
        }
        if (this.flagService.featureFlags["marketPerspective"]) {
          i = i + 1
          menu.push({
            id: i,
            name: hamburgerMenu.marketPerspective,
            path: SITE.EQUITABLE_PERSPECTIVES_MICROSITE,
            isExternal: true,
            isPrimary: true,
            isSignIn: true,
            isSubMenu: false,
          });
        }
        if (this.flagService.featureFlags["signIn"]) {
          i = i + 1
        menu.push({
          id: i,
          name: hamburgerMenu.signIn,
          path: environment.EQUITABLE,
          isPrimary: false,
          isSignIn: true,
          isSubMenu: false,
          isExternal: true,
        });
      }
      if (this.flagService.featureFlags["contact"]) {
        i = i + 1;
        menu.push({
          id: i,
          name: hamburgerMenu.contactUs,
          path: SITE.EQUITABLE_CONTACT,
          isPrimary: false,
          isSignIn: true,
          isSubMenu: false,
          isExternal: true,
        });
      }
        return menu;
      })
    );
  }

  getFooterDetails(product?: string, tool?: string): Observable<Footer> {
    return this.translate.get("footer").pipe(
      map((footer) => {
        const footerDetails: Footer = {
          addressLine1: footer.address1,
          addressLine2: footer.address2,
          socialLinks: [
            {
              iconUrl: "./assets/icons/facebook.svg",
              url: SITE.EQUITABLE_FACEBOOK,
            },
            {
              iconUrl: "./assets/icons/twitter-alt.svg",
              url: SITE.EQUITABLE_TWITTER,
            },
            {
              iconUrl: "./assets/icons/linkedin.svg",
              url: SITE.EQUITABLE_LINKEDIN,
            },
            {
              iconUrl: "./assets/icons/instagram.svg",
              url: SITE.EQUITABLE_INSTAGRAM,
            },
          ],
          footerElementLeft: [
            {
              text: footer.link0,
              url: SITE.EQUITABLE_ABOUT,
            },
            {
              text: footer.link1,
              url: SITE.EQUITABLE_FOUNDATION,
            },
            {
              text: footer.link2,
              url: SITE.EQUITABLE_CAREERS,
            },
            {
              text: footer.link3,
              url: SITE.QUALTRICS,
            },
            {
              text: footer.link4,
              url: SITE.EQUITABLE_HOLDINGS,
            },
          ],
          privacyAndSecurity: [
            {
              text: footer.privacy,
              url: SITE.EQUITABLE_PRIVACY,
            },
            {
              text: footer.usageAgreement,
              url: SITE.EQUITABLE_USAGE,
            },
          ],
          disclosuresFooter: [],
          copyrights: footer.copyrights,
          footerDescription1: footer.footerdescription1,
          footerDescription2: footer.footerdescription2,
          footerDescription3: footer.footerdescription3,
          finraUrl: SITE.FINRA,
          sipcUrl: SITE.SIPC,
          finraText: footer.finraText,
          sipcText: footer.sipcText,
          pdfLink: this.flagService.featureFlags.prospectusPDF,
        };

        console.log('PRODUCT IS ' + product)
        console.log('TOOL IS ' + tool)

        // investment edge 
      
        // retirement cornerstone 
      if (product === PRODUCT_TYPE.RC) {
        console.log('PRODUCT IS ' + " " + " " + PRODUCT_TYPE + " " + " " + product)
          if (tool && tool !== "") {
            footerDetails.disclosuresFooter.push({
              text:
                footer.retirementCornerstone +
                " " +
                tool.replace(/_/g, " ") +
                " " +
                footer.disclosure,
              url:
                "/" +
                ROUTE_PATH.DISCLOSURE +
                "?product=" +
                PRODUCT_TYPE.RC +
                "&tool=" +
                tool,
            });
          }
          footerDetails.disclosuresFooter.push({
            text:
              (!tool ? footer.retirementCornerstone + " " : "") +
              footer.generalDisclosure,
            url: "/" + ROUTE_PATH.DISCLOSURE + "?product=" + PRODUCT_TYPE.RC,
          });

          console.log("CORESERVICES", "/" + ROUTE_PATH.PROSPECTUS + "?product=series-" + product )
          footerDetails.disclosuresFooter.push({
            text: footer.prospectus,
            url:
              "/" +
              ROUTE_PATH.PROSPECTUS +
              "?product=" +
              PROSPECTUS_PRODUCTS_TYPE.RC,
          });
        } 
        // scs
        // TODO!! scsincome and scs both have product B so something is messing up here. 
        else if (product === PRODUCT_TYPE.SCS && ["B", "C", "ADV", "scs"].indexOf(product) !== -1) {
          console.log('we are in scs, NOT scsincome')
          
          if (tool && tool !== "") {
            footerDetails.disclosuresFooter.push({
              text:
                footer.structuredCapitalStrategies +
                " " +
                tool.replace(/_/g, " ") +
                " " +
                footer.disclosure,
              url:
                "/" +
                ROUTE_PATH.DISCLOSURE +
                "?product=" +
                PRODUCT_TYPE.SCS +
                "&tool=" +
                tool,
            });
          }
          footerDetails.disclosuresFooter.push({
            text:
              (!tool ? footer.structuredCapitalStrategies + " " : "") +
              footer.generalDisclosure,
            url: "/" + ROUTE_PATH.DISCLOSURE + "?product=" + product,
          });
          footerDetails.disclosuresFooter.push({
            text: footer.prospectus,
            url: "/" + ROUTE_PATH.PROSPECTUS + "?product=series-" + product,
          });
        } 
                 // scs income
         // product is 'B' here, not scsincome
         else if (product === PRODUCT_TYPE.SCSINCOME) {
          console.log('we are in scsincome')
          if (tool && tool !== "") {
            footerDetails.disclosuresFooter.push({
              text:
                footer.structuredCapitalStrategiesIncome + ' ' + footer.disclosure,
              url:
                "/" +
                ROUTE_PATH.DISCLOSURE +
                "?product=" +
                product +
                "&tool=" +
                tool,
            });
          }
          footerDetails.disclosuresFooter.push({
            text:
              (!tool ? footer.structuredCapitalStrategiesIncome + " " : "") +
              footer.generalDisclosure,
            url: "/" + ROUTE_PATH.DISCLOSURE + "?product=" + product,
          });
          footerDetails.disclosuresFooter.push({
            text: footer.prospectus,
            url: "/" + ROUTE_PATH.PROSPECTUS + "?product=" + PROSPECTUS_PRODUCTS_TYPE.SCSINCOME, // this was defaulting to RC before we made this update. plugging in product here did not work because it was lowercase 'scsincome'
          });
        } 

        
        // scs plus
        else if (product === "PLUS") {
          if (tool && tool !== "") {
            footerDetails.disclosuresFooter.push({
              text:
                footer.structuredCapitalStrategies +
                " PLUS " +
                tool.replace(/_/g, " ") +
                " " +
                footer.disclosure,
              url:
                "/" +
                ROUTE_PATH.DISCLOSURE +
                "?product=" +
                product +
                "&tool=" +
                tool,
            });
          }
          footerDetails.disclosuresFooter.push({
            text:
              (!tool ? footer.structuredCapitalStrategiesPlus + " " : "") +
              footer.generalDisclosure,
            url: "/" + ROUTE_PATH.DISCLOSURE + "?product=" + product,
          });
          footerDetails.disclosuresFooter.push({
            text: footer.prospectus,
            url: "/" + ROUTE_PATH.PROSPECTUS + "?product=series-" + product,
          });
        } 
       
       
        // scs plus 21
        else if (product === "PLUS21") {
          if (tool && tool !== "") {
            footerDetails.disclosuresFooter.push({
              text:
                footer.structuredCapitalStrategiesPlus +
                " 21 " +
                tool.replace(/_/g, " ") +
                " " +
                footer.disclosure,
              url:
                "/" +
                ROUTE_PATH.DISCLOSURE +
                "?product=" +
                product +
                "&tool=" +
                tool,
            });
          }
          footerDetails.disclosuresFooter.push({
            text:
              (!tool ? footer.structuredCapitalStrategiesPlus + " " : "") +
              footer.generalDisclosure,
            url: "/" + ROUTE_PATH.DISCLOSURE + "?product=" + product,
          });
          footerDetails.disclosuresFooter.push({
            text: footer.prospectus,
            url: "/" + ROUTE_PATH.PROSPECTUS + "?product=series-" + product,
          });
        } 
        // plus guard
        else if (product === "PLUSGUARD") {
          if (tool && tool !== "") {
            footerDetails.disclosuresFooter.push({
              text:
                footer.structuredCapitalStrategies +
                " " +
                tool.replace(/_/g, " ") +
                " " +
                footer.disclosure,
              url:
                "/" +
                ROUTE_PATH.DISCLOSURE +
                "?product=" +
                product +
                "&tool=" +
                tool,
            });
          }
          footerDetails.disclosuresFooter.push({
            text:
              (!tool ? footer.structuredCapitalStrategies + " " : "") +
              footer.generalDisclosure,
            url: "/" + ROUTE_PATH.DISCLOSURE + "?product=" + product,
          });
          footerDetails.disclosuresFooter.push({
            text: footer.prospectus,
            url: "/" + ROUTE_PATH.PROSPECTUS + "?product=series-" + product,
          });
        }
         else if (product === PRODUCT_TYPE.IE) {
          console.log('we are in InvestEDGE')
          console.log('we are in InvestEDGE' + tool)
          footerDetails.disclosuresFooter.push({
            text:
              (!tool ? footer.investmentEdge + " " : "") +
              footer.generalDisclosure,
            url: "/" + ROUTE_PATH.DISCLOSURE + "?product=" + PRODUCT_TYPE.IE,
          });
          footerDetails.disclosuresFooter.push({
            text: footer.prospectus,
            url:
              "/" +
              ROUTE_PATH.PROSPECTUS +
              "?product=" +
              PROSPECTUS_PRODUCTS_TYPE.IE,
          });
        }  
      
        console.log(footerDetails)
        return footerDetails;
      })
    );
  }

  pdfFetchResources(bodyData, product: string) {
    this.utilityService.showLoader();
    const headerData = new HttpHeaders().set(
      "Content-Type",
      MIME_TYPE.FORM_URLENCODED
    );
   console.log(product);
 /*   let url =


    environment.serviceAPIs.utilizeScs
      ? environment.serviceAPIs.utilizeScs

      : product == PRODUCT_TYPE.RC

     ? environment.serviceAPIs.utilizeRc

     : product == PRODUCT_TYPE.SCS_PLUS21

     ? environment.serviceAPIs.utilizeScs21

      : environment.serviceAPIs.utilizeIe;
    
    console.log(PRODUCT_TYPE);*/

   let url;
  

   if (product == SCS_SERIES.PLUS21){
      url = environment.serviceAPIs.utilizeScs21
    }
    else if (product == PRODUCT_TYPE.RC){
      url = environment.serviceAPIs.utilizeRc
    }
  
    else if (product == IE_SERIES.B){
      console.log('we are in IE_SERIES.B')
      url = environment.serviceAPIs.utilizeIe
    }
    else if (product == PRODUCT_TYPE.IE){
      console.log('we are in InvestEDGE')
      url = environment.serviceAPIs.utilizeIe
    }
    else if(product == PRODUCT_TYPE.SCSINCOME) {
      url = environment.serviceAPIs.utilizeIncome
    }
    else{
      url = environment.serviceAPIs.utilizeScs
    }


    const body = new HttpParams().set("data", JSON.stringify(bodyData));
  
      
    this.requestOptions = {
      headers: headerData,
      method: API_METHODS.POST,
      url: url,
      body: body,
      isblob: true,
    };

    return this.httpRequestService
      .request(this.requestOptions)
      .subscribe((res: any) => {
        this.utilityService.hideLoader();
        if (res) {
          if (res.body && res.body["size"]) {
            const SUFFIX = document.location.href.indexOf('/scs/')>-1 ? PRODUCT_TYPE.SCS.toUpperCase() : document.location.href.indexOf('/rc/')>-1 ? PRODUCT_TYPE.RC.toUpperCase() : PRODUCT_TYPE.IE.toUpperCase()
            const fileName =
              (bodyData.name ? bodyData.name.toUpperCase() : SITE_NAME) +
              "_" +
              (new Date().toLocaleDateString("en-US").replace(/\//g, "_")
                .length == 10
                ? new Date().toLocaleDateString("en-US").replace(/\//g, "_")
                : "0" +
                  new Date().toLocaleDateString("en-US").replace(/\//g, "_")) +
              "_" + SUFFIX
            ; 
            if (this.platform.is("mobileweb")) {
              this.utilityService.visitEquitable(URL.createObjectURL(res.body));
              saveAs(res.body, fileName);
            } else if (this.platform.is("cordova")) {
              this.fileOpener
                .open(res.body, MIME_TYPE.PDF)
                .catch((e) => console.log("Error opening file", e));
            } else {
              this.utilityService.visitEquitable(URL.createObjectURL(res.body));
              saveAs(res.body, fileName);
            }
          } else {
            //show error message
            this.utilityService.showAlert({
              buttons: [
                {
                  role: BUTTON_ROLE.OK,
                  text: this.translate.instant("general.ok"),
                },
              ],
              message: this.translate.instant("general.serverError"),
            });
          }
        }
      });
  }

  public get report(): Profile {
    return this.reportData;
  }

  public set report(reportData: Profile) {
    this.reportData = reportData;
  }

  getInvestorProfileResults() {
    const url =
      environment.serviceAPIs.baseUrl + environment.serviceAPIs.investorProfile;
    const requestOptions = {
      method: API_METHODS.GET,
      url: url,
    };
    return this.httpRequestService.request(requestOptions).pipe(
      map((data: any) => {
        return data.body;
      }),
      catchError((err) => {
        throw throwError(err);
      })
    );
  }

  fetchSvg(image: string) {
    const requestOptions = {
      method: API_METHODS.GET,
      url: environment.imagesPath + image,
    };
    return this.httpRequestService.request(requestOptions).pipe(
      map((res: any) => {
        return res.body;
      })
    );
  }
}
