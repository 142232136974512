import { Component, OnInit } from "@angular/core";
import { ButtonType, LinkType, Icon } from "equitable-component-library";
import { PRODUCT_TYPE, ROUTE_PATH } from "../../../constants/constants";
import { UtilityService } from "../../../core/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { ImageCard } from "../../../core/models/app.models";
import { IeService } from "../../services/ie.service";
import { ScsService } from "../../../scs/services/scs.service";


@Component({
  selector: "app-discover",
  templateUrl: "./discover.component.html",
  styleUrls: ["./discover.component.scss"],
})
export class DiscoverComponent implements OnInit {
  LinkType = LinkType;
  ButtonType = ButtonType;
  
  guaranteedIncome: ImageCard = {
    headingSmall: "",
    listDetail: [],
  };
  deferYourTaxes: ImageCard = {
    headingSmall: "",
    listDetail: [],
  };
  scsincomeBenefits: ImageCard = {
    headingSmall: "",
    listDetail: [],
  };

  featureCards: any = [
    {
      icon: Icon.TaxDefferals,
      title: "understandIE.featureCardIE.diversification",
      description: "understandIE.featureCardIE.diversificationDescription",
    },
    {
      icon: Icon.Associations,
      title: "understandIE.featureCardIE.taxEfficient",
      description: "understandIE.featureCardIE.taxEfficientDescription",
    },
    {
      icon: Icon.Badges,
      title: "understandIE.featureCardIE.deathBenefit",
      description: "understandIE.featureCardIE.deathBenefitDescription",
    },
  ];

  videos = []; //environment.videos.ie;
    // for video section
    video: [] = []
    ieData: any;
  constructor(
    private utilityService: UtilityService,
    private translate: TranslateService,
    private scsService: ScsService,
    private ieService: IeService
  ) {}

  ngOnInit() {
    // translations for Explore the other strategies we offer section
    this.translate.get("understandIE").subscribe((understandIE) => {
      this.guaranteedIncome = {
        headingSmall: understandIE.benefitsTitle,
        listDetail: [
          understandIE.investmentAccount,
          understandIE.protectedBenefit,
          understandIE.abilityToTransfer,
        ],
      };
      this.deferYourTaxes = {
        headingSmall: understandIE.benefitsTitle,
        listDetail: [
          understandIE.partialDownside,
          understandIE.upsidePotential,
          understandIE.zeroExplicit,
        ],
      };
      this.scsincomeBenefits = {
        headingSmall: understandIE.benefitsTitle,
        listDetail: [
          understandIE.lifetimeIncome,
          understandIE.levelDownside,
          understandIE.lowCost,
        ],
      };
    });

    this.ieService.getIEResources(this.ieService.series).subscribe((ie) => {
     // if (ie.videos && ie.videos.length > 0) this.videos = ie.videos;
  
   
    });

    this.ieService.getIEData().subscribe((ie) => {
      this.ieData = ie
      if (ie.videos && ie.videos.length > 0) { 
             this.videos = ie.videos
      }
    });
  
  }

  // Chandan: unused code?
  // Navigate to view all disclosure
  // goToDisclosure(): void {
  //   this.utilityService.navigate(ROUTE_PATH.DISCLOSURE, {
  //     appId: PRODUCT_TYPE.IE,
  //   });
  // }

  // Navigate to RC landing Page
  gotoRCLandingPage(): void {
    this.utilityService.navigateWithoutParams(ROUTE_PATH.RC.DISCOVER);
  }

  // Navigate to SCSINCOME landing Page
  gotoSCSIncomeLandingPage(): void {
    this.utilityService.navigateWithoutParams(ROUTE_PATH.SCSINCOME.DISCOVER);
  }
  gotoSCSINCOMELandingPage(): void {
    document.querySelector('.hero-main').scrollIntoView(true)
    this.utilityService.navigate(ROUTE_PATH.SCSINCOME.DISCOVER + '?series=B')
  }
  // Navigate to SCS landing Page
  gotoSCSLandingPage(): void {
    this.utilityService.navigateWithoutParams(ROUTE_PATH.SCS.DISCOVER);
  }

  // Navigate to IE Dashboard
  gotoAdvancedToolPage(): void {
    this.utilityService.navigateWithoutParams(ROUTE_PATH.IE.TOOLS.HOME);
  }
}
