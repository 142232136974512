import { OnInit, OnChanges } from "@angular/core";
import { Router } from "@angular/router";
import { Profile } from "../../../core/models/app.models";
import { ROUTE_PATH, INVESTOR_PROFILE_PAGES, CUSTOMIZATION_TYPE, PRODUCT_TYPE, } from "../../../constants/constants";
import { CoreService } from "../../services/core.service";
import { UtilityService } from "../../services/utility.service";
import { Platform } from "@ionic/angular";
var FooterComponent = /** @class */ (function () {
    function FooterComponent(router, coreService, util, platform) {
        this.router = router;
        this.coreService = coreService;
        this.util = util;
        this.platform = platform;
        this.profileName = "";
    }
    FooterComponent.prototype.setFooterDetails = function (product, tool) {
        var _this = this;
        this.coreService
            .getFooterDetails(product, tool)
            .subscribe(function (footer) {
            _this.footer = footer;
        });
    };
    //hide proflle
    FooterComponent.prototype.hideProfile = function () {
        this.coreService.profile.next({
            name: "",
            type: CUSTOMIZATION_TYPE.REPORT,
            profile: new Profile(),
        });
    };
    FooterComponent.prototype.goToProfile = function () {
        if (this.profile.type === CUSTOMIZATION_TYPE.PROFILE) {
            this.util.navigate(ROUTE_PATH.SCS.TOOLS.CUSTOM_PROFILE);
        }
        else if (this.profile.type === CUSTOMIZATION_TYPE.INVESTOR_PROFILE) {
            this.util.navigate(ROUTE_PATH.SCS.TOOLS.INVESTOR_PROFILE, {
                section: PRODUCT_TYPE.SCS,
            });
        }
    };
    FooterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.platform.ready().then(function () {
            if (_this.platform.is("ipad")) {
                _this.setFooterDetails(_this.product, _this.tool);
            }
        });
    };
    FooterComponent.prototype.ngOnChanges = function () {
        var _this = this;
        this.coreService.profile.subscribe(function (profile) {
            _this.profile = profile;
            _this.profileName = profile.name;
        });
        var urlSegment = this.router.url;
        urlSegment = urlSegment.split("?")[0];
        urlSegment = urlSegment.split(";")[0];
        urlSegment = urlSegment.split("#")[0];
        urlSegment = urlSegment.substring(1, urlSegment.length);
        if (INVESTOR_PROFILE_PAGES.indexOf(urlSegment) !== -1) {
            this.profileName = this.profile.name;
        }
        else {
            this.profileName = "";
        }
        if (this.router.url.indexOf("investor-profile") !== -1 &&
            this.router.url.split("/")[1] !== PRODUCT_TYPE.SCS) {
            this.product = this.router.url.split("/")[1];
            // this.tool = this.selectedProduct;
        }
        else {
            if (this.activeTab) {
                this.tool = this.activeTab;
            }
            if (this.selectedProduct) {
                this.product = this.selectedProduct;
            }
            // if plusguard, split the URL to set the product
            if (document.location.pathname.split('/')[1] === PRODUCT_TYPE.PLUSGUARD.toLowerCase()) {
                this.product = PRODUCT_TYPE.PLUSGUARD;
            }
            // if scsincome, split the URL to set the product
            else if (document.location.pathname.split('/')[1] === PRODUCT_TYPE.SCSINCOME.toLowerCase()) {
                this.product = PRODUCT_TYPE.SCSINCOME;
            }
        }
        this.setFooterDetails(this.product, this.tool);
    };
    return FooterComponent;
}());
export { FooterComponent };
