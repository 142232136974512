import { Component } from "@angular/core";
import {
  ButtonType,
  Theme,
  LinkType,
  LinkIcon,
  Orientation,
} from "equitable-component-library";
import { ROUTE_PATH } from "../../../constants/constants";
import { SITE } from "src/assets/sites";
import { UtilityService } from "../../services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { Platform } from "@ionic/angular";
import { Router } from "@angular/router";
import { FeatureFlagService } from "../../../core/services/feature-flag.service";
import { ModalController } from '@ionic/angular';
import { ScsIncomeService } from "../../../scsincome/services/scsincome.service";
import { SioPopupComponent } from "../../../scsincome/components/sio-popup/sio-popup.component";
import { HomePagePopupComponent } from "../homepage-popup/homepage-popup.component";

@Component({
  selector: "app-home",
  templateUrl: "./home.page.html",
  styleUrls: ["./home.page.scss"],
})
export class HomePage {
  site = SITE;
  route = ROUTE_PATH;

  // for read more link
  readMoreLinkType = LinkType.Light;

  // enum
  buttonType = ButtonType;
  theme = Theme;
  linkIcon = LinkIcon;

  // for links
  externalLinkIcon = LinkIcon.SignIn;
  solutionLinkIcon = LinkIcon.RightIcon;
  solutionLinkIconOrientation = Orientation.Right;
  solutionLinkType = LinkType.Dark;
  lightLink = LinkType.Light;

  // for visit Equitable.com link
  visitEquitableBtnType = ButtonType.Primary;
  visitEquitableBtnTheme = Theme.Light;
  isVisible: boolean;
  isPrimerica: boolean;
  isNational: boolean;
  shiftsInMarket: string;
  shiftsInMarketWithoutBrk: string;

  constructor(
    private utilityService: UtilityService,
    private translate: TranslateService,
    private platform: Platform,
    private router: Router,
    private flagService: FeatureFlagService,
    public modalController: ModalController,
    private scsincomeService: ScsIncomeService,
  ) {}

  ngOnInit() {
    this.isPrimerica = this.flagService.featureFlags.isPrimerica;
    this.isNational = this.flagService.featureFlags.isNational;
    this.platform.ready().then(() => {
      this.translate.get("individualRetirementHome").subscribe((translated) => {
        this.shiftsInMarket = this.translate.instant(
          "individualRetirementHome.shiftsInMarket",
          { break: "<div>" }
        );
        this.shiftsInMarketWithoutBrk = this.translate.instant(
          "individualRetirementHome.shiftsInMarket",
          { break: " " }
        );
      });
    });

    // open the modal if we are on national version of the app
    if(this.isNational) {
      //this.openModal()
    }
  }

  // visit external site Equitable.com
  visitEquitable(url) {
    this.utilityService.visitEquitableMicrosite(url);
  }
  gotToStrategiesSection() {
    this.utilityService.navigate("#ourstrategies", {'fragment': 'ourstrategies'});
    this.router.navigate(['/home'], { fragment: 'ourstrategies' });
    document.querySelector("[name='ourstrategies']").scrollIntoView(false);
  }

  visitPage(route) {
    this.utilityService.navigate(route);
  }

  /* openModal() {
    this.scsincomeService.openModal(HomePagePopupComponent, "modal-css");
  } */
}
