<ion-content>

  <!-- 
      <equitable-header-image
    description="{{ 'discoverScs.scs' | translate }}"
    image="./assets/images/discover-scs-banner.png"
  ></equitable-header-image>

  -->

  <!-- sourced from hader image -->
  <section class="hero-main">
    <div *ngIf="image" class="hero-container" [ngStyle]="{ 'background-image': 'url(' + image + ')' }"></div>

    <div class="banner-text-container">
      <div *ngIf="title" class="banner-title">{{ title }}</div>
      <div *ngIf="bannerDescription" class="banner-description" innerHTML="{{ bannerDescription }}"></div>

      <!-- added code from drop-down component -->
      <div [formGroup]="dashboardForm" class="select-wrapper">
        <select [ngModel]="productValue" #select1 [formControl]="dashboardForm.get('seriesDropDown')"
          (focusin)="status = true" (focusout)="status = false" (change)="productMenuChange(false)" class="drop-down"
          aria-label="drop down" [ngClass]="status ? 'arrowUp' : 'arrowDown'">
          <option *ngFor="let option of options" [value]="option.id" innerHTML="{{ option.value }}"></option>
        </select>
      </div>


    </div>
  </section>
  <!-- // sourced from hader image -->

  <article class="discover-scs">
    <header>
      <p [innerHTML]="pageDescription"></p>
    </header>

    <div class="container">
      <ion-grid class="ion-no-padding">
        <ion-row class="section-space">
          <ion-col>
            <ion-row class="feature-section">
              <ion-col size="12">
                <h2 class="feature-heading">
                  {{ "discoverScs.featuresBenefits" | translate }}
                </h2>
                <!-- equitable-card-benefit  -->
                <div *ngIf="this.productValue == 'scsplus21'" class="benefit-card">
                  <div *ngFor="let card of discoverScsData.featureCards_Plus21" class="benefit-card-inner">
                    <equitable-card-benefit [icon]="card.icon" [description]="card.description | translate"
                      [title]="card.title | translate"></equitable-card-benefit>
                  </div>
                </div>
                <div *ngIf="this.productValue != 'scsplus21'" class="benefit-card">
                  <div *ngFor="let card of discoverScsData.featureCards" class="benefit-card-inner">
                    <equitable-card-benefit [icon]="card.icon" [description]="card.description | translate"
                      [title]="card.title | translate"></equitable-card-benefit>
                  </div>
                </div>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <div *appFeatureFlag="'isIR'">
      <div *ngIf="this.productValue == 'scsplus21'">
        <section *ngIf="videos.length > 0 && !this.isForPLUS">
          <equitable-video #videoFrame class="child-video-section" [videos]="videos"
            [headerText]="'discoverScs.watchVideo' | translate"></equitable-video>
        </section>
        <!--    <section class="video" *ngIf="!this.isForPLUS && videos.length > 0" >
          <equitable-video #videoFrame
            class="child-video-section"
            [videos]="video_PLUS"
            [headerText]="'discoverScs.watchVideo' | translate"
          ></equitable-video>
        </section> -->
      </div>
      <div *ngIf="this.productValue == 'scsplus21'">

      </div>
    </div>



    <div class="container">
      <div class="scenario-description">
        <h2 [innerHTML]="howSCSWorks"></h2>
        <p [innerHTML]="standardSegmentIsAnOption"></p>
      </div>
      <section class="how-scs-works">
        <div class="how-scs-works-scenarios">
          <div class="scenario-examples">
            <h5 class="scenario-examples-heading">
              {{ "discoverScs.4DifferentHypotheticalScenarios" | translate }}
            </h5>
            <img *ngIf="this.isForPLUS" src="./assets/images/scs-scenarios.png"
              alt="{{ 'discoverScs.4HypotheticalScenarios' | translate }}" />
            <img *ngIf="!this.isForPLUS" src="./assets/images/scs-scenarios16.png"
              alt="{{ 'discoverScs.4HypotheticalScenarios' | translate }}" />
          </div>
        </div>

        <div *ngIf="this.isForPLUS" class="how-scs-works-example">
          <img src="./assets/images/6-year-standard-segment.png"
            alt="{{ 'discoverScs.6YearStandardSegment' | translate }}" />
        </div>

        <div *ngIf="!this.isForPLUS" class="how-scs-works-example">
          <img src="./assets/images/6-year-standard-segment.png"
            alt="{{ 'discoverScs.6YearStandardSegment' | translate }}" />
        </div>
      </section>
    </div>

    <div class="container">
      <section XXX1 *ngIf="this.isForPLUS" class="history-may-be-guide">
        <div class="learn-more">
          <h2>{{ "discoverScs.historyMayBeGuide" | translate }}</h2>
          <p>{{ "discoverScs.letsCheckOutHowTheseIndices_PLUS" | translate }}</p>
        </div>
        <div class="slider">
          <p>{{ this.slideShowTitle | translate }}</p>
          <div class="slider-container">
            <div class="slider-nav">
              <img *ngIf="activeSlide > 0" alt="" [src]="iconArrowLeft" (click)="
                  slideTransition(historicalReturnIndexSlider, orientation.Left)
                " />
            </div>
            <div class="ion-slider-container">
              <ion-slides #historicalReturnIndexSlider (ionSlideDidChange)="slideChanged()" [options]="slideOpts">
                <ion-slide>
                  <img alt="" src="./assets/images/history-may-be-guide02.png" />
                </ion-slide>
                <ion-slide>
                  <img alt="" src="./assets/images/history-may-be-guide03.png" />
                </ion-slide>
                <ion-slide>
                  <img alt="" src="./assets/images/history-may-be-guide01.png" />
                </ion-slide>
              </ion-slides>
            </div>
            <div class="slider-nav">
              <img *ngIf="activeSlide < 2" alt="" [src]="iconArrowRight" (click)="
                  slideTransition(
                    historicalReturnIndexSlider,
                    orientation.Right
                  )
                " />
            </div>
          </div>
          <equitable-stepper [steps]="steps" [textAlign]="true"></equitable-stepper>
        </div>
      </section>
      <section *ngIf="!this.isForPLUS" class="history-may-be-guide">
        <div class="learn-more">
          <h2>{{ "discoverScs.historyMayBeGuide" | translate }}</h2>
          <p>{{ "discoverScs.letsCheckOutHowTheseIndices" | translate }}</p>
        </div>
        <div class="slider">
          <p>{{ this.slideShowTitle | translate }}</p>
          <div class="slider-container">
            <div class="slider-nav">
              <img *ngIf="activeSlide > 0" alt="" [src]="iconArrowLeft" (click)="
                  slideTransition(historicalReturnIndexSlider, orientation.Left)
                " />
            </div>
            <div class="ion-slider-container">
              <ion-slides #historicalReturnIndexSlider (ionSlideDidChange)="slideChanged()" [options]="slideOpts">
                <ion-slide *appFeatureFlag="'isNational'">
                  <img alt="" src="./assets/images/SCS21-history-may-be-guide05.png" />
                </ion-slide>
                <ion-slide *appFeatureFlag="'isNational'">
                  <img alt="" src="./assets/images/SCS21-history-may-be-guide06.png" />
                </ion-slide>
                <ion-slide *appFeatureFlag="'isNational'">
                  <img alt="" src="./assets/images/SCS21-history-may-be-guide04.png" />
                </ion-slide>

                <ion-slide *appFeatureFlag="'isPrimerica'">
                  <img alt="" src="./assets/images/primerica-SCS21-history-may-be-guide02.png" />
                </ion-slide>
                <ion-slide *appFeatureFlag="'isPrimerica'">
                  <img alt="" src="./assets/images/primerica-SCS21-history-may-be-guide03.png" />
                </ion-slide>
                <ion-slide *appFeatureFlag="'isPrimerica'">
                  <img alt="" src="./assets/images/primerica-SCS21-history-may-be-guide04.png" />
                </ion-slide>
              </ion-slides>
            </div>
            <div class="slider-nav">
              <img *ngIf="activeSlide < 2" alt="" [src]="iconArrowRight" (click)="
                  slideTransition(
                    historicalReturnIndexSlider,
                    orientation.Right
                  )
                " />
            </div>
          </div>
          <equitable-stepper [steps]="steps" [textAlign]="true"></equitable-stepper>
        </div>
      </section>
    </div>

    <div class="container">
      <section class="explore-other-strategies">
        <h2>{{ "discoverScs.exploreOtherStrategies" | translate }}</h2>

        <div class="card-image-container">







          <equitable-card-image *ngIf="!this.isForPLUS && this.isPrimerica" (eventMore)="gotoSCSPLUSLandingPage()"
            image="./assets/images/protection-from-market.jpg" [heading]="'discoverRc.protectionVolatility' | translate"
            [description]="'discoverRc.protectionVolatilityDescPLUS' | translate" [subContent]="guaranteedIncome">
            {{ "discoverRc.learnMoreBtn" | translate }}
          </equitable-card-image>

          <equitable-card-image *ngIf="this.isForPLUS && this.isPrimerica" (eventMore)="gotoSCSLandingPage()"
            image="./assets/images/protection-from-market.jpg" [heading]="'discoverRc.protectionVolatility' | translate"
            [description]="'discoverRc.protectionVolatilityDesc' | translate" [subContent]="guaranteedIncome">
            {{ "discoverRc.learnMoreBtn" | translate }}
          </equitable-card-image>
          <equitable-card-image (eventMore)="gotoRCLandingPage()" image="./assets/images/guaranteed-income.jpg"
            [heading]="'discoverScs.guaranteedIncome' | translate" [description]="
              'discoverScs.retirementCornerstoneDesigned' | translate
            " [subContent]="guaranteedIncome">
            {{ "discoverScs.learnMore" | translate }}
          </equitable-card-image>


          <equitable-card-image *ngIf="this.isNational" (eventMore)="gotoSCSINCOMELandingPage()"
            image="./assets/images/income-tile.jpg" [heading]="'discoverScs.incomeCardHeading' | translate"
            [description]="'discoverScs.incomeCardCopy' | translate" [subContent]="scsincomeBenefits">
            {{ "discoverScs.learnMoreBtn" | translate }}
          </equitable-card-image>

          <equitable-card-image *appFeatureFlag="'isNational'" (eventMore)="gotoIELandingPage()"
            image="./assets/images/distribute.jpg" [heading]="'discoverScs.deferYourTaxes' | translate"
            [description]="'discoverScs.investmentEdgeHelps' | translate" [subContent]="deferYourTaxes">
            {{ "discoverScs.learnMore" | translate }}
          </equitable-card-image>
        </div>
      </section>
    </div>

    <equitable-advanced-tool image="./assets/images/advanced-tools.png"
      [title]="'discoverScs.advancedTools' | translate"
      [description]="'discoverScs.dynamicGraphsVisualizations' | translate"
      [btnLabel]="'discoverScs.learnMore' | translate" (btnClick)="gotoAdvancedToolsResourcesLandingPage()">
    </equitable-advanced-tool>
  </article>
  <app-footer isWhiteFooterVisible="true" isBlueFooterVisible="true" selectedProduct="PLUS"></app-footer>
</ion-content>