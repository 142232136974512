import { Theme, Orientation, Icon } from 'equitable-component-library';
import { QuestionnaireService } from '../../services/questionnaire.service';
import { OnInit, EventEmitter } from '@angular/core';
var QuestionnaireThreeComponent = /** @class */ (function () {
    function QuestionnaireThreeComponent(questionnaireService) {
        this.questionnaireService = questionnaireService;
        //Outputs
        this.selectedOption = new EventEmitter();
        this.backButtonClicked = new EventEmitter();
        //Class Variables
        this.cardMainconfg = [];
        this.type = Theme.Dark;
        this.backIcon = Icon.BackButton;
        this.orientation = Orientation.Left;
    }
    QuestionnaireThreeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.questionnaireService.getQuestionnairePageData(3).subscribe(function (data) {
            _this.cardMainconfg = data;
        });
    };
    QuestionnaireThreeComponent.prototype.selectedCard = function (index) {
        this.selectedOption.emit({ step: this.step, index: index });
    };
    QuestionnaireThreeComponent.prototype.backButton = function () {
        this.backButtonClicked.emit(this.step);
    };
    return QuestionnaireThreeComponent;
}());
export { QuestionnaireThreeComponent };
