<ion-content>
  <div class="equitable-container">
    <div class="back-link">
      <equitable-link
        class="equitable-link"
        text="{{ 'disclosure.linkText' | translate }}"
        iconName="arrow-back.svg"
        type="dark"
        orientation="left"
        (click)="backButton()"
      ></equitable-link>
    </div>
    <div class="resource-header" *ngIf="showDisclosureDropdown">
      <span class="title ion-text-left">{{
        "disclosure.disclosures" | translate
      }}</span>
      <div class="resource-header-inner">
        <div class="resource-dropdown-container">
          <label class="resource-dropdown-label">{{
            "disclosure.filterByProduct" | translate
          }}</label>
          <div [formGroup]="resourceForm" class="drop-down-prop">
            <equitable-drop-down
              class="equitable-dropdown"
              [formControl]="resourceForm.get('resourceDropDown')"
              formControlName="resourceDropDown"
              [options]="resourceOptions"
            ></equitable-drop-down>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="displayRc">
      <app-disclosure-rc class="disclosure-sub-containers"></app-disclosure-rc>
    </div>
    <div *ngIf="displayScs">
      <app-disclosure-scs
        class="disclosure-sub-containers"
      ></app-disclosure-scs>
    </div>
    <div *ngIf="displayScsPlus21">
      <app-disclosure-scs-plus21
        class="disclosure-sub-containers"
      ></app-disclosure-scs-plus21>
    </div>
    <div *ngIf="displayScsPlus">
      <app-disclosure-scs-plus
        class="disclosure-sub-containers"
      ></app-disclosure-scs-plus>
    </div>
    <div *ngIf="displayScsIncome">
        <app-disclosure-scs-income
          class="disclosure-sub-containers"
        ></app-disclosure-scs-income>
      </div>
    <div *ngIf="displayIe">
      <app-disclosure-ie class="disclosure-sub-containers"></app-disclosure-ie>
    </div>
  </div>
  <app-footer
    isBlueFooterVisible="true"
    selectedProduct="disclosure"
  ></app-footer>
</ion-content>
