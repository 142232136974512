import { OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { ROUTE_PATH } from "../../../constants/constants";
import { UtilityService } from "../../../core/services/utility.service";
import { ButtonType, Theme } from "equitable-component-library";
var ModalPopupComponent = /** @class */ (function () {
    function ModalPopupComponent(modalController, navParams, utilityService) {
        this.modalController = modalController;
        this.utilityService = utilityService;
        this.btnType = ButtonType.Primary;
        this.btnTheme = Theme.Light;
        this.customRootPath = ROUTE_PATH.SCS.TOOLS.CUSTOM_PROFILE;
        this.rootPath = ROUTE_PATH.SCS.TOOLS.INVESTOR_PROFILE;
        this.htmlTamplate = navParams.get("htmlTamplate");
        this.modalTitle = navParams.get("modalTitle");
        this.isCloseIcon = navParams.get("isCloseIcon");
        this.isAddProfileForm =
            navParams.get("isAddProfileForm") !== undefined &&
                navParams.get("isAddProfileForm") !== undefined
                ? navParams.get("isAddProfileForm")
                : false;
    }
    ModalPopupComponent.prototype.ngOnInit = function () { };
    ModalPopupComponent.prototype.closeModal = function () {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalController.dismiss({
            dismissed: true,
        });
    };
    ModalPopupComponent.prototype.sampleProfile = function (link) {
        this.utilityService.navigate(link, {
            section: "scs",
        });
        this.closeModal();
    };
    ModalPopupComponent.prototype.customProfile = function (link) {
        this.utilityService.navigate(link, {
            section: "scs",
        });
        this.closeModal();
    };
    return ModalPopupComponent;
}());
export { ModalPopupComponent };
