<!--THIS IS ACCEL INCOME OPTION-->

<div class="investment-container rb-income">
  <div class="feature-section col-12 col-lg-5">
    <div class="content-container">
      <h2>Take advantage of potential market growth</h2>
      <div class="description">
        <!-- {{ 'ripData.descriptions.0' | translate }} -->
        In addition to the opportunities to increase your income through <strong>Annual Resets</strong> and
        <strong>6% Deferral Incentives</strong>, with Structured Capital Strategies<sup>®</sup> Income you may also
        receive an <strong>Income Rate Bump</strong> until you take your first withdrawal each time there is
        an Annual Reset*.
      </div>

      <h3>Income Rate Bump</h3>

      <div class="description">
        <!-- {{ 'ripData.descriptions.0' | translate }} -->
        With each Annual Reset, your income rate may increase to the income rate that
        corresponds to your age at the time of that reset until you take your first withdrawal.
      </div>

    </div>
  </div>




  <div class="chart-section col-12 col-lg-7">
    <div class="chart-container">
      <div class="">
        <img alt="Accelerted income" src="./assets/images/bump.png" />
      </div>
    </div>

  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="disclaimer">
      * Owners should consider when to take the first withdrawal under the contract, since doing so will prohibit the
      owner from making any further contributions under the contract, and will also terminate all Deferral Incentives
      and income rate increases in connection with Annual Resets under the GLWB rider, which could significantly limit
      increases in the values under the GLWB as well as increases in the contract's account value and death benefit.
    </div>
  </div>
</div>


<!--THIS IS ACCELERATED INCOME OPTION-->