<ion-content class="rc-tool-parent-container">
  <equitable-sub-header
    title="{{ 'ieDashbaord.tools.diversification' | translate }}"
    backLinkText="{{ 'ieDashbaord.headerTitle' | translate }}"
    (backClick)="backButton()"
  ></equitable-sub-header>
  <div class="rc-tool-tab-section">
    <ion-card class="rc-tool-tab-container">
      <equitable-diversification></equitable-diversification>
    </ion-card>
  </div>
  <app-footer
    isWhiteFooterVisible="true"
    isBlueFooterVisible="true"
    selectedProduct="ie"
  ></app-footer>
</ion-content>
