<!--THIS IS LEVEL INCOME OPTION-->



<div class="investment-container level-income">
    
  <div class="feature-section col-md-5 col-12">
    <div class="content-container">
        
         <div class="description">
              <!-- {{ 'ripData.descriptions.0' | translate }} -->
              The Level Income option provides lifetime income. Under this option, the income amount does not change if the account value falls to zero, unless the account value is depleted by an excess withdrawal.<sup>1</sup>  
            </div>
        

        <div class="row">
          <div class="col-12">
            <!-- {{ 'ripData.heading' | translate }} -->
            <div class="profile-image col-4"><span class="img-joe"></span></div>
            <div class="col-8"> <h1>Meet Joe</h1>
            </div>
        <div class="row">
        
            <div class="col-12">
                <h4>Current state</h4>
                <ul>
                
                  <li>Just retired</li>
                </ul>
            </div>

            <div class="col-12">
                <h4>Upcoming concerns</h4>
                <ul>
                  <li>Needs income for everyday expenses</li>
                </ul>
            </div>
            <div class="col-12">
                <h4>Related strategy</h4>
                <ul>
                  <li>Level Income Option</li>
                </ul>
            </div>
          
        </div>
           

          </div>
        </div>
        <ion-col class="ion-no-padding" size-xl="15" size-md="7" size="15">
        
              
              <!-- <div class="subheading">{{ 'ripData.subHeading' | translate }}</div>
               -->
          

          </ion-col>
     
      
    </div>
    
  </div>
  <div class="chart-section col-md-7 col-12 ">
      <div class="chart-container">
        <div class="">
          <img
            alt="Level income"
            src="./assets/images/level.svg"
          />
        </div>
      </div>
  
    </div>
    
</div>



<div class="row">
  <div class="col-12">
    <div class="disclaimer">Joe's 5.50% income rate is multiplied by the Income Base to determine his Guaranteed Annual Income Amount (GAIA). The illustration above assumes no annual resets which may increase future income rates if they occur. 
      This example is a hypothetical intended for illustrative purposes only and is not indicative of the actual performance of any particular product.</div>
<br>
<ol><li>The same level of income assumes no Annual Reset.​</li>

  <li>When Guaranteed Lifetime Withdrawal Benefit (GLWB) is active, an excess withdrawal that reduces the Account Value to zero will be treated as a surrender 
  and the pro-rated fees will be subtracted from the withdrawal. ​</li>
  
 <li>All income rates are hypothetical.</li></ol>
    </div>
  </div>