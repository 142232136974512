<div class="segment-performance-container">
  <ion-row [formGroup]="historicalForm" class="dropdown-container">
    <ion-col class="margin-inbetween">
      <equitable-multiple-select-dropdown
        defaultPlaceholder="{{
          'historicalPerformance.selectIndices' | translate
        }}"
        selectedPlaceholder="{{
          'historicalPerformance.selectedIndexCount' | translate
        }}"
        [formControl]="historicalForm.get('index')"
        formControlName="index"
        [options]="indexOptions"
        [size]="indexSelectionMaxCount"
      >
      </equitable-multiple-select-dropdown>
    </ion-col>
    <ion-col class="margin-inbetween">
      <equitable-drop-down
        class="equitable-dropdown"
        [formControl]="historicalForm.get('duration')"
        formControlName="duration"
        [options]="durationOptions"
      ></equitable-drop-down>
    </ion-col>
    <ion-col>
      <equitable-drop-down
        class="equitable-dropdown"
        [formControl]="historicalForm.get('protection')"
        formControlName="protection"
        [options]="protectionOptions"
      ></equitable-drop-down>
    </ion-col>
  </ion-row>
  <div
    class="bar-chart-container"
    *ngIf="this.selectedIndicies && this.selectedIndicies.length > 0"
  >
    <equitable-bar-chart
      [chartData]="chartData"
      labelNoInformation="{{
        'historicalPerformanceChart.labelNoInformation' | translate
      }}"
    ></equitable-bar-chart>
  </div>
  <div
    class="bar-chart-container"
    *ngIf="!this.selectedIndicies || this.selectedIndicies.length === 0"
  >
    <div class="no-data">
      {{ "historicalPerformanceChart.uptoIndex" | translate }}
    </div>
  </div>
  <div
    class="donut-chart-container"
    *ngIf="this.selectedIndicies && this.selectedIndicies.length > 0"
  >
    <div class="donut-chart-container-padding">
      <div class="ipad-display">
        <p
          class="slider-header-text"
          [innerHTML]="'historicalPerformance.sliderHeaderText' | translate"
        ></p>
      </div>
      <div class="chart-display">
        <div class="section1">
          <div class="web-display">
            <p
              class="slider-header-text"
              [innerHTML]="'historicalPerformance.sliderHeaderText' | translate"
            ></p>
          </div>
          <div>
            <div
              *ngFor="let option of selectedIndicies"
              class="section-slider-data"
            >
              <div class="section-slider-1">
                <div
                  class="dot-sign"
                  [ngStyle]="{ 'background-color': option.color }"
                ></div>
              </div>
              <div
                class="chart-text section-slider-2"
                [innerHTML]="option.name.replace('®', '<sup>®</sup>')"
              ></div>
              <div class="section-slider-3">
                <equitable-slider
                  [min]="HISTORICAL_PERFORMANCE.SLIDER_MIN"
                  [max]="HISTORICAL_PERFORMANCE.SLIDER_MAX"
                  type="secondary"
                  [value]="option.value"
                  disabled="{{ option.disabled || false }}"
                  (slide)="
                    this.selectedInput = undefined; onChange($event, option)
                  "
                ></equitable-slider>
              </div>
              <div class="section-slider-4">
                <input
                  type="text"
                  width="3"
                  [min]="HISTORICAL_PERFORMANCE.SLIDER_MIN"
                  [max]="HISTORICAL_PERFORMANCE.SLIDER_MAX"
                  id="input_{{ option.name }}"
                  value="{{ option.value + '%' }}"
                  class="inputForSlider"
                  aria-label="slider input"
                  (keydown)="validateInvestment($event)"
                  [disabled]="option.disabled || false"
                  (change)="onInputChange($event, option)"
                />
              </div>
              <div class="segmentSlider section-slider-5">
                <span
                  id="lock_{{ option.name }}"
                  class="lock"
                  (click)="toggleLock($event, option)"
                ></span>
              </div>
            </div>
          </div>
        </div>
        <div class="section2">
          <equitable-doughnut-chart
            [data]="doughnutData"
            type="secondary"
          ></equitable-doughnut-chart>
        </div>
        <div class="section3">
          <div class="investment-option1">
            <div class="investment-option-text">
              <p class="chart-text font-16">
                {{ "historicalPerformance.investment" | translate }}
              </p>
            </div>
            <div class="investment-option-input">
              <input
                type="text"
                [value]="investment | currency: 'USD':'symbol':'1.0'"
                (focus)="clearInput()"
                (keydown)="validateInvestment($event)"
                (change)="onInvestmentChange($event.target.value)"
                class="inputForInvestment"
                aria-label="investment input"
              />
            </div>
          </div>
          <div class="bottom-border">
            <div>
              <equitable-slider
                [min]="HISTORICAL_PERFORMANCE.INVESTMENT"
                [max]="HISTORICAL_PERFORMANCE.INVESTMENT_MAX"
                type="secondary"
                [value]="investment"
                (slide)="onInvestmentChange($event.detail.value)"
              ></equitable-slider>
            </div>
          </div>
          <div class="top-alignment bottom-alignment display-property">
            <div class="chart-text display-property1">
              {{ "historicalPerformance.maturityValue" | translate }}
            </div>
            <div class="chart-value display-property2">
              {{ maturityValue | currency: "USD":true:"1.2-2" }}
            </div>
          </div>
          <div class="display-property">
            <div class="chart-text display-property1">
              {{ "historicalPerformance.indexValue" | translate }}
            </div>
            <div class="chart-value display-propert2">
              {{ indexValue | currency: "USD":true:"1.2-2" }}
            </div>
          </div>
        </div>
      </div>
      <div class="series-title">
        {{ "historyGuide.viewingSeries" | translate }} {{ series }}
      </div>
    </div>
  </div>
  <div class="link-container">
    <div class="first-link">
      <equitable-link
        [text]="customProfile | translate"
        [iconName]="linkRightIcon"
        [type]="type"
        [orientation]="orientation"
        (click)="saveProfile(CUSTOMIZATION_TYPE.PROFILE)"
      ></equitable-link>
    </div>
    <div class="second-link">
      <equitable-link
        [text]="customizeReport | translate"
        [iconName]="linkRightIcon"
        [type]="type"
        [orientation]="orientation"
        (click)="saveProfile(CUSTOMIZATION_TYPE.REPORT)"
      ></equitable-link>
    </div>
  </div>
</div>
