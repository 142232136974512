import { FeatureFlagService } from "./feature-flag.service";
import { TranslateService } from "@ngx-translate/core";
import { throwError, BehaviorSubject } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Profile } from "../models/app.models";
import { ROUTE_PATH, PRODUCT_TYPE, PROSPECTUS_PRODUCTS_TYPE, BUTTON_ROLE, SCS_SERIES, IE_SERIES, SITE_NAME, } from "../../constants/constants";
import { SITE } from "src/assets/sites";
import { HttpRequestService } from "../../../app/core/services/http-request.service";
import { API_METHODS, MIME_TYPE } from "../../../app/constants/constants";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { saveAs } from "file-saver";
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { Platform } from "@ionic/angular";
import { UtilityService } from "../services/utility.service";
import { environment } from "../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./http-request.service";
import * as i3 from "@ionic-native/file-opener/ngx/index";
import * as i4 from "@ionic/angular";
import * as i5 from "./utility.service";
import * as i6 from "./feature-flag.service";
var CoreService = /** @class */ (function () {
    function CoreService(translate, httpRequestService, fileOpener, platform, utilityService, flagService) {
        this.translate = translate;
        this.httpRequestService = httpRequestService;
        this.fileOpener = fileOpener;
        this.platform = platform;
        this.utilityService = utilityService;
        this.flagService = flagService;
        this.product = "";
        this.profile = new BehaviorSubject({
            name: "",
            type: "",
            profile: new Profile(),
        });
        this.reportData = new Profile();
    }
    CoreService.prototype.getHamburgermenu = function () {
        var _this = this;
        return this.translate.get("hamburgerMenu").pipe(map(function (hamburgerMenu) {
            var i = 1;
            var menu = [
                {
                    id: i,
                    name: hamburgerMenu.home,
                    path: ROUTE_PATH.HOME,
                    isPrimary: true,
                    isSignIn: false,
                    isSubMenu: false,
                },
            ];
            // if (this.flagService.featureFlags["scsPLUS"]) {
            //   const subMenu = [];
            //   if (this.flagService.featureFlags["scsPLUS"]["productHomepage"]) {
            //     subMenu.push({
            //       name: hamburgerMenu.products,
            //       path: ROUTE_PATH.SCS.DISCOVER,
            //     });
            //     subMenu.push({
            //       name: hamburgerMenu.tools,
            //       path: ROUTE_PATH.SCS.TOOLS.HOME,
            //     });
            //     i = i + 1;
            //     menu.push({
            //       id: i,
            //       name: hamburgerMenu.scsPLUS,
            //       path: "/",
            //       isPrimary: true,
            //       isSignIn: false,
            //       isSubMenu: true,
            //       subMenu: subMenu,
            //     });
            //   } else if (this.flagService.featureFlags["scsPLUS"]["toolsHomePage"]) {
            //     i = i + 1;
            //     menu.push({
            //       id: i,
            //       name: hamburgerMenu.tools,
            //       path: ROUTE_PATH.SCS.TOOLS.HOME,
            //       isPrimary: true,
            //       isSignIn: false,
            //       isSubMenu: false,
            //     });
            //   }
            // }
            /* if (this.flagService.featureFlags["ie"]) {
              const subMenu = [];
              if (this.flagService.featureFlags["ie"]["productHomepage"]) {
                subMenu.push({
                  name: hamburgerMenu.products,
                  //path: ROUTE_PATH.SCS.DISCOVER
                  path: ROUTE_PATH.IE.DISCOVER + '?series=ie',
                });
                subMenu.push({
                  name: hamburgerMenu.tools,
                  path: ROUTE_PATH.IE.TOOLS.HOME,
                });
                i = i + 1;
                menu.push({
                  id: i,
                  name: hamburgerMenu.ie,
                  path: "/",
                  isPrimary: true,
                  isSignIn: false,
                  isSubMenu: true,
                  subMenu: subMenu,
                });
              } else if (this.flagService.featureFlags["ie"]["toolsHomePage"]) {
                i = i + 1;
                menu.push({
                  id: i,
                  name: hamburgerMenu.ie,
                  path: ROUTE_PATH.IE.TOOLS.HOME,
                  isPrimary: true,
                  isSignIn: false,
                  isSubMenu: false,
                });
              }
            } */
            if (_this.flagService.featureFlags["scsincome"]) {
                var subMenu = [];
                if (_this.flagService.featureFlags["scsincome"]["productHomepage"]) {
                    subMenu.push({
                        name: hamburgerMenu.products,
                        //path: ROUTE_PATH.SCS.DISCOVER
                        path: ROUTE_PATH.SCSINCOME.DISCOVER + '?series=income',
                    });
                    subMenu.push({
                        name: hamburgerMenu.tools,
                        path: ROUTE_PATH.SCSINCOME.TOOLS.HOME,
                    });
                    i = i + 1;
                    menu.push({
                        id: i,
                        name: hamburgerMenu.scsincome,
                        path: "/",
                        isPrimary: true,
                        isSignIn: false,
                        isSubMenu: true,
                        subMenu: subMenu,
                    });
                }
                else if (_this.flagService.featureFlags["scsincome"]["toolsHomePage"]) {
                    i = i + 1;
                    menu.push({
                        id: i,
                        name: hamburgerMenu.tools,
                        path: ROUTE_PATH.SCSINCOME.TOOLS.HOME,
                        isPrimary: true,
                        isSignIn: false,
                        isSubMenu: false,
                    });
                }
            }
            if (_this.flagService.featureFlags["rc"]) {
                i = i + 1;
                menu.push({
                    id: i,
                    name: hamburgerMenu.rc,
                    path: "/",
                    isPrimary: true,
                    isSignIn: false,
                    isSubMenu: true,
                    subMenu: [
                        {
                            name: hamburgerMenu.products,
                            path: ROUTE_PATH.RC.DISCOVER,
                        },
                        {
                            name: hamburgerMenu.tools,
                            path: ROUTE_PATH.RC.TOOLS.HOME,
                        },
                    ],
                });
            }
            if (_this.flagService.featureFlags["scs"]) {
                var subMenu = [];
                if (_this.flagService.featureFlags.isNational) {
                    subMenu.push({
                        name: hamburgerMenu.products,
                        //path: ROUTE_PATH.SCS.DISCOVER
                        path: ROUTE_PATH.SCS.DISCOVER + '?series=PLUS21',
                    });
                    subMenu.push({
                        name: hamburgerMenu.tools,
                        path: ROUTE_PATH.SCS.TOOLS.HOME,
                    });
                    i = i + 1;
                    menu.push({
                        id: i,
                        name: hamburgerMenu.scs,
                        path: "/",
                        isPrimary: true,
                        isSignIn: false,
                        isSubMenu: true,
                        subMenu: subMenu,
                    });
                }
                else if (_this.flagService.featureFlags.isPrimerica) {
                    subMenu.push({
                        name: hamburgerMenu.products,
                        //path: ROUTE_PATH.SCS.DISCOVER
                        path: ROUTE_PATH.SCS.DISCOVER + '?series=PLUS21',
                    });
                    subMenu.push({
                        name: hamburgerMenu.tools,
                        path: ROUTE_PATH.SCS.TOOLS.HOME,
                    });
                    i = i + 1;
                    menu.push({
                        id: i,
                        name: hamburgerMenu.scs,
                        path: "/",
                        isPrimary: true,
                        isSignIn: false,
                        isSubMenu: true,
                        subMenu: subMenu,
                    });
                }
                else if (_this.flagService.featureFlags["scs"]["toolsHomePage"]) {
                    i = i + 1;
                    menu.push({
                        id: i,
                        name: hamburgerMenu.tools,
                        path: ROUTE_PATH.SCS.TOOLS.HOME,
                        isPrimary: true,
                        isSignIn: false,
                        isSubMenu: false,
                    });
                }
            }
            if (_this.flagService.featureFlags["ie"]) {
                i = i + 1;
                menu.push({
                    id: i,
                    name: hamburgerMenu.ie,
                    path: "/",
                    isPrimary: true,
                    isSignIn: false,
                    isSubMenu: true,
                    subMenu: [
                        {
                            name: hamburgerMenu.products,
                            path: ROUTE_PATH.IE.DISCOVER,
                        },
                        {
                            name: hamburgerMenu.tools,
                            path: ROUTE_PATH.IE.TOOLS.HOME,
                        },
                    ],
                });
            }
            else if (_this.flagService.featureFlags["ie"]["toolsHomePage"]) {
                i = i + 1;
                menu.push({
                    id: i,
                    name: hamburgerMenu.tools,
                    path: ROUTE_PATH.IE.TOOLS.HOME,
                    isPrimary: true,
                    isSignIn: false,
                    isSubMenu: false,
                });
            }
            if (_this.flagService.featureFlags["questionnaire"]) {
                i = i + 1;
                menu.push({
                    id: i,
                    name: hamburgerMenu.findMatch,
                    path: ROUTE_PATH.QUESTIONNAIRE,
                    isPrimary: true,
                    isSignIn: false,
                    isSubMenu: false,
                });
            }
            if (_this.flagService.featureFlags["marketPerspective"]) {
                i = i + 1;
                menu.push({
                    id: i,
                    name: hamburgerMenu.marketPerspective,
                    path: SITE.EQUITABLE_PERSPECTIVES_MICROSITE,
                    isExternal: true,
                    isPrimary: true,
                    isSignIn: true,
                    isSubMenu: false,
                });
            }
            if (_this.flagService.featureFlags["signIn"]) {
                i = i + 1;
                menu.push({
                    id: i,
                    name: hamburgerMenu.signIn,
                    path: environment.EQUITABLE,
                    isPrimary: false,
                    isSignIn: true,
                    isSubMenu: false,
                    isExternal: true,
                });
            }
            if (_this.flagService.featureFlags["contact"]) {
                i = i + 1;
                menu.push({
                    id: i,
                    name: hamburgerMenu.contactUs,
                    path: SITE.EQUITABLE_CONTACT,
                    isPrimary: false,
                    isSignIn: true,
                    isSubMenu: false,
                    isExternal: true,
                });
            }
            return menu;
        }));
    };
    CoreService.prototype.getFooterDetails = function (product, tool) {
        var _this = this;
        return this.translate.get("footer").pipe(map(function (footer) {
            var footerDetails = {
                addressLine1: footer.address1,
                addressLine2: footer.address2,
                socialLinks: [
                    {
                        iconUrl: "./assets/icons/facebook.svg",
                        url: SITE.EQUITABLE_FACEBOOK,
                    },
                    {
                        iconUrl: "./assets/icons/twitter-alt.svg",
                        url: SITE.EQUITABLE_TWITTER,
                    },
                    {
                        iconUrl: "./assets/icons/linkedin.svg",
                        url: SITE.EQUITABLE_LINKEDIN,
                    },
                    {
                        iconUrl: "./assets/icons/instagram.svg",
                        url: SITE.EQUITABLE_INSTAGRAM,
                    },
                ],
                footerElementLeft: [
                    {
                        text: footer.link0,
                        url: SITE.EQUITABLE_ABOUT,
                    },
                    {
                        text: footer.link1,
                        url: SITE.EQUITABLE_FOUNDATION,
                    },
                    {
                        text: footer.link2,
                        url: SITE.EQUITABLE_CAREERS,
                    },
                    {
                        text: footer.link3,
                        url: SITE.QUALTRICS,
                    },
                    {
                        text: footer.link4,
                        url: SITE.EQUITABLE_HOLDINGS,
                    },
                ],
                privacyAndSecurity: [
                    {
                        text: footer.privacy,
                        url: SITE.EQUITABLE_PRIVACY,
                    },
                    {
                        text: footer.usageAgreement,
                        url: SITE.EQUITABLE_USAGE,
                    },
                ],
                disclosuresFooter: [],
                copyrights: footer.copyrights,
                footerDescription1: footer.footerdescription1,
                footerDescription2: footer.footerdescription2,
                footerDescription3: footer.footerdescription3,
                finraUrl: SITE.FINRA,
                sipcUrl: SITE.SIPC,
                finraText: footer.finraText,
                sipcText: footer.sipcText,
                pdfLink: _this.flagService.featureFlags.prospectusPDF,
            };
            console.log('PRODUCT IS ' + product);
            console.log('TOOL IS ' + tool);
            // investment edge 
            // retirement cornerstone 
            if (product === PRODUCT_TYPE.RC) {
                console.log('PRODUCT IS ' + " " + " " + PRODUCT_TYPE + " " + " " + product);
                if (tool && tool !== "") {
                    footerDetails.disclosuresFooter.push({
                        text: footer.retirementCornerstone +
                            " " +
                            tool.replace(/_/g, " ") +
                            " " +
                            footer.disclosure,
                        url: "/" +
                            ROUTE_PATH.DISCLOSURE +
                            "?product=" +
                            PRODUCT_TYPE.RC +
                            "&tool=" +
                            tool,
                    });
                }
                footerDetails.disclosuresFooter.push({
                    text: (!tool ? footer.retirementCornerstone + " " : "") +
                        footer.generalDisclosure,
                    url: "/" + ROUTE_PATH.DISCLOSURE + "?product=" + PRODUCT_TYPE.RC,
                });
                console.log("CORESERVICES", "/" + ROUTE_PATH.PROSPECTUS + "?product=series-" + product);
                footerDetails.disclosuresFooter.push({
                    text: footer.prospectus,
                    url: "/" +
                        ROUTE_PATH.PROSPECTUS +
                        "?product=" +
                        PROSPECTUS_PRODUCTS_TYPE.RC,
                });
            }
            // scs
            // TODO!! scsincome and scs both have product B so something is messing up here. 
            else if (product === PRODUCT_TYPE.SCS && ["B", "C", "ADV", "scs"].indexOf(product) !== -1) {
                console.log('we are in scs, NOT scsincome');
                if (tool && tool !== "") {
                    footerDetails.disclosuresFooter.push({
                        text: footer.structuredCapitalStrategies +
                            " " +
                            tool.replace(/_/g, " ") +
                            " " +
                            footer.disclosure,
                        url: "/" +
                            ROUTE_PATH.DISCLOSURE +
                            "?product=" +
                            PRODUCT_TYPE.SCS +
                            "&tool=" +
                            tool,
                    });
                }
                footerDetails.disclosuresFooter.push({
                    text: (!tool ? footer.structuredCapitalStrategies + " " : "") +
                        footer.generalDisclosure,
                    url: "/" + ROUTE_PATH.DISCLOSURE + "?product=" + product,
                });
                footerDetails.disclosuresFooter.push({
                    text: footer.prospectus,
                    url: "/" + ROUTE_PATH.PROSPECTUS + "?product=series-" + product,
                });
            }
            // scs income
            // product is 'B' here, not scsincome
            else if (product === PRODUCT_TYPE.SCSINCOME) {
                console.log('we are in scsincome');
                if (tool && tool !== "") {
                    footerDetails.disclosuresFooter.push({
                        text: footer.structuredCapitalStrategiesIncome + ' ' + footer.disclosure,
                        url: "/" +
                            ROUTE_PATH.DISCLOSURE +
                            "?product=" +
                            product +
                            "&tool=" +
                            tool,
                    });
                }
                footerDetails.disclosuresFooter.push({
                    text: (!tool ? footer.structuredCapitalStrategiesIncome + " " : "") +
                        footer.generalDisclosure,
                    url: "/" + ROUTE_PATH.DISCLOSURE + "?product=" + product,
                });
                footerDetails.disclosuresFooter.push({
                    text: footer.prospectus,
                    url: "/" + ROUTE_PATH.PROSPECTUS + "?product=" + PROSPECTUS_PRODUCTS_TYPE.SCSINCOME,
                });
            }
            // scs plus
            else if (product === "PLUS") {
                if (tool && tool !== "") {
                    footerDetails.disclosuresFooter.push({
                        text: footer.structuredCapitalStrategies +
                            " PLUS " +
                            tool.replace(/_/g, " ") +
                            " " +
                            footer.disclosure,
                        url: "/" +
                            ROUTE_PATH.DISCLOSURE +
                            "?product=" +
                            product +
                            "&tool=" +
                            tool,
                    });
                }
                footerDetails.disclosuresFooter.push({
                    text: (!tool ? footer.structuredCapitalStrategiesPlus + " " : "") +
                        footer.generalDisclosure,
                    url: "/" + ROUTE_PATH.DISCLOSURE + "?product=" + product,
                });
                footerDetails.disclosuresFooter.push({
                    text: footer.prospectus,
                    url: "/" + ROUTE_PATH.PROSPECTUS + "?product=series-" + product,
                });
            }
            // scs plus 21
            else if (product === "PLUS21") {
                if (tool && tool !== "") {
                    footerDetails.disclosuresFooter.push({
                        text: footer.structuredCapitalStrategiesPlus +
                            " 21 " +
                            tool.replace(/_/g, " ") +
                            " " +
                            footer.disclosure,
                        url: "/" +
                            ROUTE_PATH.DISCLOSURE +
                            "?product=" +
                            product +
                            "&tool=" +
                            tool,
                    });
                }
                footerDetails.disclosuresFooter.push({
                    text: (!tool ? footer.structuredCapitalStrategiesPlus + " " : "") +
                        footer.generalDisclosure,
                    url: "/" + ROUTE_PATH.DISCLOSURE + "?product=" + product,
                });
                footerDetails.disclosuresFooter.push({
                    text: footer.prospectus,
                    url: "/" + ROUTE_PATH.PROSPECTUS + "?product=series-" + product,
                });
            }
            // plus guard
            else if (product === "PLUSGUARD") {
                if (tool && tool !== "") {
                    footerDetails.disclosuresFooter.push({
                        text: footer.structuredCapitalStrategies +
                            " " +
                            tool.replace(/_/g, " ") +
                            " " +
                            footer.disclosure,
                        url: "/" +
                            ROUTE_PATH.DISCLOSURE +
                            "?product=" +
                            product +
                            "&tool=" +
                            tool,
                    });
                }
                footerDetails.disclosuresFooter.push({
                    text: (!tool ? footer.structuredCapitalStrategies + " " : "") +
                        footer.generalDisclosure,
                    url: "/" + ROUTE_PATH.DISCLOSURE + "?product=" + product,
                });
                footerDetails.disclosuresFooter.push({
                    text: footer.prospectus,
                    url: "/" + ROUTE_PATH.PROSPECTUS + "?product=series-" + product,
                });
            }
            else if (product === PRODUCT_TYPE.IE) {
                console.log('we are in InvestEDGE');
                console.log('we are in InvestEDGE' + tool);
                footerDetails.disclosuresFooter.push({
                    text: (!tool ? footer.investmentEdge + " " : "") +
                        footer.generalDisclosure,
                    url: "/" + ROUTE_PATH.DISCLOSURE + "?product=" + PRODUCT_TYPE.IE,
                });
                footerDetails.disclosuresFooter.push({
                    text: footer.prospectus,
                    url: "/" +
                        ROUTE_PATH.PROSPECTUS +
                        "?product=" +
                        PROSPECTUS_PRODUCTS_TYPE.IE,
                });
            }
            console.log(footerDetails);
            return footerDetails;
        }));
    };
    CoreService.prototype.pdfFetchResources = function (bodyData, product) {
        var _this = this;
        this.utilityService.showLoader();
        var headerData = new HttpHeaders().set("Content-Type", MIME_TYPE.FORM_URLENCODED);
        console.log(product);
        /*   let url =
       
       
           environment.serviceAPIs.utilizeScs
             ? environment.serviceAPIs.utilizeScs
       
             : product == PRODUCT_TYPE.RC
       
            ? environment.serviceAPIs.utilizeRc
       
            : product == PRODUCT_TYPE.SCS_PLUS21
       
            ? environment.serviceAPIs.utilizeScs21
       
             : environment.serviceAPIs.utilizeIe;
           
           console.log(PRODUCT_TYPE);*/
        var url;
        if (product == SCS_SERIES.PLUS21) {
            url = environment.serviceAPIs.utilizeScs21;
        }
        else if (product == PRODUCT_TYPE.RC) {
            url = environment.serviceAPIs.utilizeRc;
        }
        else if (product == IE_SERIES.B) {
            console.log('we are in IE_SERIES.B');
            url = environment.serviceAPIs.utilizeIe;
        }
        else if (product == PRODUCT_TYPE.IE) {
            console.log('we are in InvestEDGE');
            url = environment.serviceAPIs.utilizeIe;
        }
        else if (product == PRODUCT_TYPE.SCSINCOME) {
            url = environment.serviceAPIs.utilizeIncome;
        }
        else {
            url = environment.serviceAPIs.utilizeScs;
        }
        var body = new HttpParams().set("data", JSON.stringify(bodyData));
        this.requestOptions = {
            headers: headerData,
            method: API_METHODS.POST,
            url: url,
            body: body,
            isblob: true,
        };
        return this.httpRequestService
            .request(this.requestOptions)
            .subscribe(function (res) {
            _this.utilityService.hideLoader();
            if (res) {
                if (res.body && res.body["size"]) {
                    var SUFFIX = document.location.href.indexOf('/scs/') > -1 ? PRODUCT_TYPE.SCS.toUpperCase() : document.location.href.indexOf('/rc/') > -1 ? PRODUCT_TYPE.RC.toUpperCase() : PRODUCT_TYPE.IE.toUpperCase();
                    var fileName = (bodyData.name ? bodyData.name.toUpperCase() : SITE_NAME) +
                        "_" +
                        (new Date().toLocaleDateString("en-US").replace(/\//g, "_")
                            .length == 10
                            ? new Date().toLocaleDateString("en-US").replace(/\//g, "_")
                            : "0" +
                                new Date().toLocaleDateString("en-US").replace(/\//g, "_")) +
                        "_" + SUFFIX;
                    if (_this.platform.is("mobileweb")) {
                        _this.utilityService.visitEquitable(URL.createObjectURL(res.body));
                        saveAs(res.body, fileName);
                    }
                    else if (_this.platform.is("cordova")) {
                        _this.fileOpener
                            .open(res.body, MIME_TYPE.PDF)
                            .catch(function (e) { return console.log("Error opening file", e); });
                    }
                    else {
                        _this.utilityService.visitEquitable(URL.createObjectURL(res.body));
                        saveAs(res.body, fileName);
                    }
                }
                else {
                    //show error message
                    _this.utilityService.showAlert({
                        buttons: [
                            {
                                role: BUTTON_ROLE.OK,
                                text: _this.translate.instant("general.ok"),
                            },
                        ],
                        message: _this.translate.instant("general.serverError"),
                    });
                }
            }
        });
    };
    Object.defineProperty(CoreService.prototype, "report", {
        get: function () {
            return this.reportData;
        },
        set: function (reportData) {
            this.reportData = reportData;
        },
        enumerable: true,
        configurable: true
    });
    CoreService.prototype.getInvestorProfileResults = function () {
        var url = environment.serviceAPIs.baseUrl + environment.serviceAPIs.investorProfile;
        var requestOptions = {
            method: API_METHODS.GET,
            url: url,
        };
        return this.httpRequestService.request(requestOptions).pipe(map(function (data) {
            return data.body;
        }), catchError(function (err) {
            throw throwError(err);
        }));
    };
    CoreService.prototype.fetchSvg = function (image) {
        var requestOptions = {
            method: API_METHODS.GET,
            url: environment.imagesPath + image,
        };
        return this.httpRequestService.request(requestOptions).pipe(map(function (res) {
            return res.body;
        }));
    };
    CoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CoreService_Factory() { return new CoreService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.HttpRequestService), i0.ɵɵinject(i3.FileOpener), i0.ɵɵinject(i4.Platform), i0.ɵɵinject(i5.UtilityService), i0.ɵɵinject(i6.FeatureFlagService)); }, token: CoreService, providedIn: "root" });
    return CoreService;
}());
export { CoreService };
