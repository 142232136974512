import { OnInit } from "@angular/core";
import { FormGroup, FormControl, FormBuilder, } from "@angular/forms";
import { UtilityService } from "../../../core/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { ROUTE_PATH, SCS_SERIES, CUSTOMIZATION_TYPE, CHARACTER_LIMIT, PRODUCT_TYPE, } from "../../../constants/constants";
import { Profile, HistoryMayBeAGuide, HistoryMayBeAGuideColumn, } from "../../../core/models/app.models";
import { SITE } from "src/assets/sites";
import { ScsService } from "../../services/scs.service";
import { CoreService } from "../../../core/services/core.service";
import { FeatureFlagService } from "src/app/core/services/feature-flag.service";
import { TealiumUtagService } from '../../../tealium/utag.service';
var UtilizeScsComponent = /** @class */ (function () {
    /***
     *  constructor
     ***/
    function UtilizeScsComponent(formBuilder, utilityService, translate, coreService, scsService, featureService, tealium) {
        this.formBuilder = formBuilder;
        this.utilityService = utilityService;
        this.translate = translate;
        this.coreService = coreService;
        this.scsService = scsService;
        this.featureService = featureService;
        this.tealium = tealium;
        //Variables
        this.route = ROUTE_PATH.SCS.TOOLS;
        this.maxlength = CHARACTER_LIMIT.INPUT;
        this.resourceMaterials = [];
        this.customeContents = [];
        this.series = "";
        this.backButtonSeries = this.scsService.series === SCS_SERIES.PLUS ? SCS_SERIES.PLUS : "";
    }
    /***
     *  Page Init
     ***/
    UtilizeScsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isNotPlusGuard = !this.featureService.featureFlags.isPlusGuard;
        this.series = this.scsService.series;
        this.translate.get("createReport").subscribe(function (translated) {
            if (_this.series === SCS_SERIES.PLUS) {
                _this.backButtonText = translated.backButtonTextPlus;
            }
            else if (_this.series === SCS_SERIES.PLUS21) {
                _this.backButtonText = translated.backButtonTextPlus21;
            }
            else {
                _this.backButtonText = translated.backButtonText;
            }
        });
        //create form
        this.buildForm();
        this.getPageData();
        // this.tealium.link({
        //   tealium_event: 'link',
        //   event_category: 'BodyClick',
        //   event_name: 'Retirement Guide Clicks',
        //   event_label: 'Click on Create a Report'
        // })
    };
    /***
     *  Page Load/Init
     ***/
    UtilizeScsComponent.prototype.ionViewWillEnter = function () {
        this.series = this.scsService.series;
        //create form
        this.buildForm();
        this.getPageData();
    };
    /***
     *  Back button event. Go back to tool home page
     ***/
    UtilizeScsComponent.prototype.backButton = function () {
        this.utilityService.navigate(ROUTE_PATH.SCS.TOOLS.HOME);
    };
    /***
     *  Create form
     ***/
    UtilizeScsComponent.prototype.buildForm = function () {
        var _this = this;
        this.formGroup = this.formBuilder.group({
            professionalName: new FormControl({
                value: this.coreService.report.profName,
                disabled: false,
            }),
            name: new FormControl(this.coreService.report.name),
            firmName: new FormControl(this.coreService.report.firmName),
            contactNumber: new FormControl(this.coreService.report.contactNumber),
            structuredInvestment: new FormControl({
                value: false,
                disabled: this.scsService.report.aboutSCSHowSioWorks ? false : true,
            }),
            historicalPerformance: new FormControl({
                value: false,
                disabled: this.scsService.report.historicalPerformance ? false : true,
            }),
            historyGuide: new FormControl({
                value: false,
                disabled: this.scsService.report.historyMayBeAGuide ? false : true,
            }),
            historicalPerformancePlus: new FormControl({
                value: false,
                disabled: this.scsService.report.historyMayBeAGuidePlus ? false : true,
            })
        });
        //save data to service using form change value
        this.formGroup.valueChanges.subscribe(function (data) {
            _this.coreService.report.name = data.name;
            _this.coreService.report.firmName = data.firmName;
            _this.coreService.report.contactNumber = data.contactNumber;
            _this.coreService.report.profName = data.professionalName;
        });
    };
    /***
     *  get Data for resources
     ***/
    UtilizeScsComponent.prototype.getPageData = function () {
        //get resources for services
        var _this = this;
        //this.utilityService.showLoader();
        this.scsService.getResources(this.scsService.series).subscribe(function (res) {
            _this.resourceMaterials = res;
            //add more form control dynamically to track the resources
            res.forEach(function (element) {
                var control = new FormControl(element.description);
                control.setValue(false);
                _this.formGroup.addControl(element.description, control);
            });
            //add custome content its dynamic based on series B,C, ADV, PLUS
            var customeContents = [
                {
                    formControlName: "structuredInvestment",
                    formControl: _this.formGroup.get("structuredInvestment"),
                    description: _this.translate.instant("createReport.checkboxDescription1"),
                    link: {
                        label: _this.scsService.report.aboutSCSHowSioWorks
                            ? _this.translate.instant("createReport.edit")
                            : _this.translate.instant("createReport.customize"),
                        path: "/" + ROUTE_PATH.SCS.TOOLS.HOW_SIO_WORKS,
                    },
                    imagePath: "./assets/images/report-checkbox.jpg",
                    reportKey: "aboutSCSHowSioWorks",
                },
                {
                    formControlName: "historicalPerformance",
                    formControl: _this.formGroup.get("historicalPerformance"),
                    description: _this.translate.instant("createReport.checkboxDescription2"),
                    link: {
                        label: _this.scsService.report.historicalPerformance
                            ? _this.translate.instant("createReport.edit")
                            : _this.translate.instant("createReport.customize"),
                        path: "/" +
                            ROUTE_PATH.SCS.TOOLS.HISTORICAL_PERFORMANCE +
                            "/" +
                            ROUTE_PATH.SCS.TOOLS.SEGMENT_PERFORMANCE,
                    },
                    imagePath: "./assets/images/report-checkbox2.jpg",
                    reportKey: "historicalPerformance",
                },
                {
                    formControlName: "historyGuide",
                    formControl: _this.formGroup.get("historyGuide"),
                    description: _this.translate.instant("createReport.checkboxDescription3"),
                    link: {
                        label: _this.scsService.report.historyMayBeAGuide
                            ? _this.translate.instant("createReport.edit")
                            : _this.translate.instant("createReport.customize"),
                        path: "/" +
                            ROUTE_PATH.SCS.TOOLS.HISTORICAL_PERFORMANCE +
                            "/" +
                            ROUTE_PATH.SCS.TOOLS.HISTORY_GUIDE,
                    },
                    imagePath: "./assets/images/report-checkbox3.jpg",
                    reportKey: "historyMayBeAGuide",
                },
                {
                    formControlName: "historicalPerformancePlus",
                    formControl: _this.formGroup.get("historicalPerformancePlus"),
                    description: _this.translate.instant("createReport.checkboxDescription3"),
                    link: {
                        label: _this.scsService.report.historyMayBeAGuidePlus
                            ? _this.translate.instant("createReport.edit")
                            : _this.translate.instant("createReport.customize"),
                        path: "/" + ROUTE_PATH.SCS.TOOLS.HISTORICAL_PERFORMANCE_PLUS,
                    },
                    imagePath: "./assets/images/report-checkbox4.jpg",
                    reportKey: "historyMayBeAGuidePlus",
                }
            ];
            //sppecial fix for primerica
            if (_this.featureService.featureFlags.report === "PRIMERICA_REPORT") {
                _this.series === SCS_SERIES.PLUS21;
                customeContents[2].imagePath = "./assets/images/report-checkbox4.jpg";
                customeContents[2].formControlName = "historicalPerformancePlus";
                customeContents[2].formControl = _this.formGroup.get("historicalPerformancePlus");
                customeContents[2].link.label = _this.scsService.report
                    .historyMayBeAGuidePlus
                    ? _this.translate.instant("createReport.edit")
                    : _this.translate.instant("createReport.customize");
                customeContents[2].reportKey = "historyMayBeAGuidePlus";
            }
            if (_this.scsService.customizationType === CUSTOMIZATION_TYPE.PROFILE ||
                _this.scsService.customizationType === CUSTOMIZATION_TYPE.REPORT) {
                _this.scsService.getProfile().subscribe(function (profiles) {
                    if (profiles && profiles.length > 0) {
                        var profile = profiles.find(function (x) {
                            return x.name ===
                                (_this.scsService.customizationType ==
                                    CUSTOMIZATION_TYPE.PROFILE
                                    ? _this.scsService.currentProfile
                                    : CUSTOMIZATION_TYPE.REPORT) &&
                                x.currentSerie === _this.scsService.series;
                        });
                        if (profile) {
                            _this.scsService.report = profile;
                        }
                        else {
                            _this.scsService.report = new Profile();
                        }
                        customeContents.forEach(function (element) {
                            if (_this.scsService.report[element.reportKey]) {
                                _this.formGroup.get(element.formControlName).enable();
                            }
                            else {
                                _this.formGroup.get(element.formControlName).disable();
                            }
                        });
                    }
                });
            }
            //if series plus then only how-sio-works and pie charts
            if (_this.scsService.series === SCS_SERIES.B ||
                _this.scsService.series === SCS_SERIES.ADV ||
                _this.scsService.series === SCS_SERIES.C) {
                customeContents.splice(3, 1);
            }
            else if (_this.scsService.series === SCS_SERIES.PLUS) {
                customeContents.splice(1, 2);
            }
            else if (_this.scsService.series === SCS_SERIES.PLUS21) {
                customeContents.splice(1, 2);
            }
            _this.customeContents = customeContents;
            _this.utilityService.hideLoader();
        });
    };
    /***
     *  get Data for resources
     ***/
    UtilizeScsComponent.prototype.generatePDF = function () {
        var _this = this;
        console.log(PRODUCT_TYPE);
        if (this.formGroup.valid) {
            var utilizeData_1 = new Profile();
            utilizeData_1.sourceURL = SITE.AXA;
            utilizeData_1.name = this.formGroup.get("name").value;
            utilizeData_1.profName = this.formGroup.get("professionalName").value;
            utilizeData_1.firmName = this.formGroup.get("firmName").value;
            utilizeData_1.contactNumber = this.formGroup.get("contactNumber").value;
            //add section to the report if applicable
            var str_1 = [];
            this.customeContents.forEach(function (element) {
                if (element.formControl.value === true) {
                    //add section key to array. PDF generate will based on that
                    str_1.push(element.reportKey);
                    //add section data
                    utilizeData_1[element.reportKey] = _this.scsService.report[element.reportKey];
                }
            });
            //minor fix for remove space
            if (utilizeData_1.aboutSCSHowSioWorks) {
                utilizeData_1.aboutSCSHowSioWorks.segmentOption = utilizeData_1.aboutSCSHowSioWorks.segmentOption
                    .replace(/\s/g, "")
                    .toLowerCase();
                utilizeData_1.aboutSCSHowSioWorks.index = utilizeData_1.aboutSCSHowSioWorks.index.replace("®", "");
            }
            //change string array to string to
            utilizeData_1.sectionsName = str_1.toString();
            //add learning resources
            var obj_1 = {};
            this.resourceMaterials.forEach(function (element) {
                if (_this.formGroup.get(element.description).value === true) {
                    obj_1[element.description] = element.file;
                }
            });
            utilizeData_1.learningResourcesIncluded = obj_1;
            //add series name
            if (this.scsService.series == "PLUS" || this.scsService.series == "PLUS21") {
                utilizeData_1.currentSerie = "PLUS";
            }
            else {
                utilizeData_1.currentSerie = this.scsService.series;
            }
            //sppecial fix for primerica. Series B but behave like PLUS
            if ((this.featureService.featureFlags.report === "PRIMERICA_REPORT" ||
                this.featureService.featureFlags.report === "WF_REPORT") &&
                utilizeData_1.historyMayBeAGuidePlus) {
                utilizeData_1.historyMayBeAGuide = new HistoryMayBeAGuide();
                Object.keys(utilizeData_1.historyMayBeAGuidePlus).forEach(function (element, index) {
                    utilizeData_1.historyMayBeAGuide["column" + (index + 1)] = new HistoryMayBeAGuideColumn();
                    utilizeData_1.historyMayBeAGuide["column" + (index + 1)].label =
                        utilizeData_1.historyMayBeAGuidePlus["columnPlus" + (index + 1)].label;
                    utilizeData_1.historyMayBeAGuide["column" + (index + 1)].url =
                        utilizeData_1.historyMayBeAGuidePlus["columnPlus" + (index + 1)].url;
                });
                delete utilizeData_1.historyMayBeAGuidePlus;
                utilizeData_1.sectionsName = utilizeData_1.sectionsName.replace("historyMayBeAGuidePlus", "historyMayBeAGuide");
            }
            if (this.featureService.featureFlags.report === "WF_REPORT" &&
                utilizeData_1.aboutSCSHowSioWorks) {
                /** for wells fargo create a report, create report API is not expecting data.
                 * it seem everything is hardcoded in API and absed on some key value PDF generated
                 * even bar values are in correct
                 *  */
                // Object.keys(utilizeData.aboutSCSHowSioWorks.bars).forEach((element) => {
                //   console.log("UTILIZE utilizeData.aboutSCSHowSioWorks.bars[element]", element, utilizeData.aboutSCSHowSioWorks.bars[element])
                //   utilizeData.aboutSCSHowSioWorks.bars[element] = 0; //for e.g. every bar value changed to 0 stil working.
                // });
                if (utilizeData_1.aboutSCSHowSioWorks.duration === "6yr" &&
                    (utilizeData_1.aboutSCSHowSioWorks.index
                        .toUpperCase()
                        .indexOf("S&P") !== -1 ||
                        utilizeData_1.aboutSCSHowSioWorks.index
                            .toUpperCase()
                            .indexOf("RUSSELL") !== -1)) {
                    // API demand some data  on specific format
                    if (utilizeData_1.aboutSCSHowSioWorks.index
                        .toUpperCase()
                        .indexOf("RUSSELL") !== -1) {
                        //API has not even check cases. it need to be in specific case
                        utilizeData_1.aboutSCSHowSioWorks.index = "RUSSELL 2000 6 Yr";
                    }
                    else if (utilizeData_1.aboutSCSHowSioWorks.index
                        .toUpperCase()
                        .indexOf("S&P") !== -1) {
                        utilizeData_1.aboutSCSHowSioWorks.index = "S&P 500 6 Yr";
                    }
                }
                else if (utilizeData_1.aboutSCSHowSioWorks.duration === "1yr") {
                    if (utilizeData_1.aboutSCSHowSioWorks.index
                        .toUpperCase()
                        .indexOf("RUSSELL") !== -1) {
                        //API has not even check cases. it need to be in specific case
                        utilizeData_1.aboutSCSHowSioWorks.index = "RUSSELL 2000 1 Yr";
                    }
                    else if (utilizeData_1.aboutSCSHowSioWorks.index
                        .toUpperCase()
                        .indexOf("S&P") !== -1) {
                        utilizeData_1.aboutSCSHowSioWorks.index = "S&P 500 1 Yr";
                    }
                }
            }
            // this.coreService.pdfFetchResources(utilizeData, this.series)
            if (this.series === SCS_SERIES.PLUS || this.series === SCS_SERIES.B || this.series === SCS_SERIES.C || this.series === SCS_SERIES.ADV) {
                this.coreService.pdfFetchResources(utilizeData_1, PRODUCT_TYPE.SCS);
            }
            else if (this.series === SCS_SERIES.PLUS21) {
                this.coreService.pdfFetchResources(utilizeData_1, PRODUCT_TYPE.SCS_PLUS21);
            }
        }
        else {
            this.utilityService.showValidationErrorForControls(this.formGroup);
        }
        // add tealium_event: "Generate_PDF" ?
        this.tealium.link({
            event_name: "Retirement Guide Clicks - SCS",
            tool_name: "BodyClicks",
            event_label: "Create a report"
        });
    };
    /***
     *  scroll event
     ***/
    UtilizeScsComponent.prototype.scroll = function (ev) {
        if (ev.detail.scrollTop >= 1) {
            this.utilityService.sendScrollStatus(false);
        }
        else {
            this.utilityService.sendScrollStatus(false);
        }
    };
    return UtilizeScsComponent;
}());
export { UtilizeScsComponent };
