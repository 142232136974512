<equitable-tab-slider
  (changeData)="openTab($event)"
  [names]="btns"
  [selectedName]="selectedName"
></equitable-tab-slider>

<!-- Tab 1  termsOfUse-->
<div *ngIf="activeIndex === 0">
  <h1
    class="title-heading"
    [innerHTML]="'disclosure.scsPlus21.tab1.heading' | translate"
  ></h1>
  <div class="content">
    <ul>
      <span>{{ "disclosure.scsPlus21.tab1.content1" | translate }}</span>
      <li *ngIf="'disclosure.scsPlus21.tab1.content2' | translate"></li>
    </ul>
    <div [innerHTML]="'disclosure.scsPlus21.tab1.content3' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab1.content4' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab1.content5' | translate"></div>
    <div
      *ngIf="'disclosure.scsPlus21.tab1.content6' | translate"
      [innerHTML]="'disclosure.scsPlus21.tab1.content6' | translate"
    ></div>
    <div
      *ngIf="'disclosure.scsPlus21.tab1.content7' | translate"
      [innerHTML]="'disclosure.scsPlus21.tab1.content7' | translate"
    ></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab1.content8' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab1.content9' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab1.content10' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab1.content11' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab1.content12' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab1.content13' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab1.content14' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab1.content15' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab1.content16' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab1.content17' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab1.content18' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab1.content19' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab1.content20' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab1.content21' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab1.content22' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab1.content23' | translate"></div>
    <div *appFeatureFlag="'isNational'" [innerHTML]="'disclosure.scsPlus21.tab1.content24' | translate"></div>
    <div *appFeatureFlag="'isNational'" [innerHTML]="'disclosure.scsPlus21.tab1.content25' | translate"></div>
    <div *appFeatureFlag="'isNational'" [innerHTML]="'disclosure.scsPlus21.tab1.content26' | translate"></div>
    <div *appFeatureFlag="'isNational'" [innerHTML]="'disclosure.scsPlus21.tab1.content27' | translate"></div>
    <div *appFeatureFlag="'isNational'" [innerHTML]="'disclosure.scsPlus21.tab1.content28' | translate"></div>
    <div *appFeatureFlag="'isNational'" [innerHTML]="'disclosure.scsPlus21.tab1.content29' | translate"></div>
    <div *appFeatureFlag="'isNational'" [innerHTML]="'disclosure.scsPlus21.tab1.content30' | translate"></div>
    <div *appFeatureFlag="'isNational'" [innerHTML]="'disclosure.scsPlus21.tab1.content31' | translate"></div>
    <div *appFeatureFlag="'isNational'" [innerHTML]="'disclosure.scsPlus21.tab1.content32' | translate"></div>
    <div *appFeatureFlag="'isNational'" [innerHTML]="'disclosure.scsPlus21.tab1.content33' | translate"></div>
    <div *appFeatureFlag="'isNational'" [innerHTML]="'disclosure.scsPlus21.tab1.content34' | translate"></div>
    <div *appFeatureFlag="'isNational'" [innerHTML]="'disclosure.scsPlus21.tab1.content35' | translate"></div>
    <div *appFeatureFlag="'isNational'" [innerHTML]="'disclosure.scsPlus21.tab1.content36' | translate"></div>
    <div *appFeatureFlag="'isNational'" [innerHTML]="'disclosure.scsPlus21.tab1.content37' | translate"></div>
    <div *appFeatureFlag="'isNational'" [innerHTML]="'disclosure.scsPlus21.tab1.content38' | translate"></div>
    <div *appFeatureFlag="'isNational'" [innerHTML]="'disclosure.scsPlus21.tab1.content39' | translate"></div>
    <div *appFeatureFlag="'isNational'" [innerHTML]="'disclosure.scsPlus21.tab1.content40' | translate"></div>
  </div>
</div>

<!-- Tab 2  Get Started-->
<div *ngIf="activeIndex === 1">
  <h1
    class="title-heading"
    [innerHTML]="'disclosure.scsPlus21.tab2.heading' | translate"
  ></h1>
  <div class="content">
    <ul>
      <span>{{ "disclosure.scsPlus21.tab2.content1" | translate }}</span>
      <li *ngIf="'disclosure.scsPlus21.tab2.content2' | translate">
        <span>{{ "disclosure.scsPlus21.tab2.content2" | translate }}</span>
      </li>
    </ul>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content3' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content4' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content5' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content6' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content7' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content8' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content9' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content10' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content11' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content12' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content13' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content14' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content15' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content16' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content17' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content18' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content19' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content20' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content21' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content22' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content23' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content24' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content25' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content26' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content27' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content28' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content29' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content30' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content31' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content32' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content33' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content34' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content35' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content36' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content37' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab2.content38' | translate"></div>
    <ul>
      <span>{{ "disclosure.scsPlus21.tab2.content39" | translate }}</span>
      <li>
        {{ "disclosure.scsPlus21.tab2.content40" | translate }}
      </li>
      <li>
        {{ "disclosure.scsPlus21.tab2.content41" | translate }}
      </li>
    </ul>
  </div>
</div>

<!-- Tab 3  Historical Index Return-->
<div *ngIf="activeIndex === 2">
  <h1 class="title-heading">
    {{ "disclosure.scsPlus21.tab3.heading" | translate }}
  </h1>
  <div class="content">
    <div [innerHTML]="'disclosure.scsPlus21.tab3.content1' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab3.content2' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab3.content3' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab3.content4' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab3.content5' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab3.content6' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab3.content7' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab3.content8' | translate"></div>
  </div>
</div>

<!-- Tab 4  Performance Cap Trend-->
<!-- <div *ngIf="activeIndex === 3">
  <h1 class="title-heading">
    {{ "disclosure.scsPlus21.tab4.heading" | translate }}
  </h1>
  <div class="content">
    <div [innerHTML]="'disclosure.scsPlus21.tab4.content1' | translate"></div>
    <div [innerHTML]="'disclosure.scsPlus21.tab4.content2' | translate"></div>
  </div>
</div> -->

<!-- Tab 5  Investor Profiles-->
<div *ngIf="activeIndex === 4">
  <h1 class="title-heading">
    {{ "disclosure.scsPlus21.tab5.heading" | translate }}
  </h1>
  <div class="content">
    <div [innerHTML]="'disclosure.scsPlus21.tab5.content1' | translate"></div>
  </div>
</div>
