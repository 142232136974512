import { OnInit } from "@angular/core";
import { RcService } from "../../services/rc.service";
import { Profile } from "../../../core/models/app.models";
import { UtilityService } from "../../../core/services/utility.service";
import { Location } from '@angular/common';
import { LinkType, BoxButtonIcon, LinkIcon, Orientation, } from "equitable-component-library";
import { ROUTE_PATH, CUSTOMIZATION_TYPE, PRODUCT_TYPE, } from "src/app/constants/constants";
import { CoreService } from "src/app/core/services/core.service";
var RcAdvancedToolResourcesDashboardComponent = /** @class */ (function () {
    function RcAdvancedToolResourcesDashboardComponent(rcService, utilityService, coreService, location) {
        this.rcService = rcService;
        this.utilityService = utilityService;
        this.coreService = coreService;
        this.location = location;
        this.toolDashboardData = {
            getFileUrl: "",
            resource: [],
            tools: [],
        };
        this.LinkType = LinkType;
        this.LinkIcon = LinkIcon;
        this.Orientation = Orientation;
    }
    RcAdvancedToolResourcesDashboardComponent.prototype.ngOnInit = function () {
        this.getResource();
        //reset selection
        if (this.coreService.product !== PRODUCT_TYPE.RC) {
            this.coreService.profile.next({
                name: "",
                type: CUSTOMIZATION_TYPE.REPORT,
                profile: new Profile(),
            });
            this.coreService.report = new Profile();
        }
        this.coreService.product = PRODUCT_TYPE.RC;
        this.toolDashboardData.tools = [
            {
                icon: BoxButtonIcon.HowSIOWorks,
                label: "rcDashbaord.tools.buildWealth",
                routePath: ROUTE_PATH.RC.TOOLS.BUILD_WEALTH,
            },
            {
                icon: BoxButtonIcon.HistoricalPerformance,
                label: "rcDashbaord.tools.protectIncome",
                routePath: ROUTE_PATH.RC.TOOLS.PROTECT_INCOME,
            },
            {
                icon: BoxButtonIcon.PerformanceCapRates,
                label: "rcDashbaord.tools.preserveReport",
                routePath: ROUTE_PATH.RC.TOOLS.PRESERVE_LEGACY,
            },
            {
                icon: BoxButtonIcon.CreateAReport,
                label: "rcDashbaord.tools.createReport",
                routePath: ROUTE_PATH.RC.TOOLS.CREATE_REPORT,
            },
            {
                icon: BoxButtonIcon.ProductOverview,
                label: "rcDashbaord.tools.productOverview",
                routePath: ROUTE_PATH.RC.DISCOVER,
            },
            {
                icon: BoxButtonIcon.InvestorProfiles,
                label: "rcDashbaord.tools.investorProfiles",
                routePath: ROUTE_PATH.RC.TOOLS.INVESTOR_PROFILE,
            },
        ];
    };
    // fetch Resource from API
    RcAdvancedToolResourcesDashboardComponent.prototype.getResource = function () {
        var _this = this;
        this.rcService.fetchResources().subscribe(function (getData) {
            if (getData["SalesKitFiles"]) {
                getData["SalesKitFiles"].forEach(function (item) {
                    _this.toolDashboardData.resource.push(item);
                });
            }
        });
    };
    // on click of resources links
    RcAdvancedToolResourcesDashboardComponent.prototype.goToResources = function (i) {
        var file = this.toolDashboardData.resource[i]["file"];
        this.utilityService.visitEquitable(file);
    };
    // On click Card button
    RcAdvancedToolResourcesDashboardComponent.prototype.cardClick = function (link) {
        this.utilityService.navigate(link, {
            section: "rc",
        });
    };
    // back button navigation
    RcAdvancedToolResourcesDashboardComponent.prototype.backButton = function () {
        this.location.back();
        // this.utilityService.navigate(ROUTE_PATH.HOME);
    };
    RcAdvancedToolResourcesDashboardComponent.prototype.goToScsIncomeLandingPage = function () {
        //  this.utilityService.navigateWithoutParams(ROUTE_PATH.IE.TOOLS.HOME);
        this.utilityService.navigateWithoutParams(ROUTE_PATH.SCSINCOME.TOOLS.HOME);
    };
    RcAdvancedToolResourcesDashboardComponent.prototype.goToScsLandingPage = function () {
        //  this.utilityService.navigateWithoutParams(ROUTE_PATH.IE.TOOLS.HOME);
        this.utilityService.navigateWithoutParams(ROUTE_PATH.SCS.TOOLS.HOME);
    };
    RcAdvancedToolResourcesDashboardComponent.prototype.goToScsPLUSLandingPage = function () {
        this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER + '?series=PLUS');
    };
    RcAdvancedToolResourcesDashboardComponent.prototype.goToScsPLUS21LandingPage = function () {
        this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER + '?series=PLUS21');
    };
    RcAdvancedToolResourcesDashboardComponent.prototype.goToIeLandingPage = function () {
        this.utilityService.navigateWithoutParams(ROUTE_PATH.IE.TOOLS.HOME);
    };
    return RcAdvancedToolResourcesDashboardComponent;
}());
export { RcAdvancedToolResourcesDashboardComponent };
