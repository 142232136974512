/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-not-found.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../../node_modules/equitable-component-library/src/lib/button/button.component.ngfactory";
import * as i6 from "equitable-component-library/src/lib/button/button.component";
import * as i7 from "../footer/footer.component.ngfactory";
import * as i8 from "../footer/footer.component";
import * as i9 from "@angular/router";
import * as i10 from "../../services/core.service";
import * as i11 from "../../services/utility.service";
import * as i12 from "./page-not-found.component";
var styles_PageNotFoundComponent = [i0.styles];
var RenderType_PageNotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageNotFoundComponent, data: {} });
export { RenderType_PageNotFoundComponent as RenderType_PageNotFoundComponent };
export function View_PageNotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(1, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 16, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 15, "div", [["class", "arround-space"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "Image of a sad computer"], ["class", "img404"], ["src", "./assets/images/404-icon.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "noFound"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "equitable-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.home() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i1.ɵdid(16, 638976, null, 0, i6.ButtonComponent, [], { type: [0, "type"], theme: [1, "theme"] }, null), (_l()(), i1.ɵted(17, 0, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(19, 0, null, 0, 1, "app-footer", [["isBlueFooterVisible", "true"], ["selectedProduct", "home"]], null, null, null, i7.View_FooterComponent_0, i7.RenderType_FooterComponent)), i1.ɵdid(20, 638976, null, 0, i8.FooterComponent, [i9.Router, i10.CoreService, i11.UtilityService, i3.Platform], { isBlueFooterVisible: [0, "isBlueFooterVisible"], selectedProduct: [1, "selectedProduct"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.type; var currVal_4 = _co.theme; _ck(_v, 16, 0, currVal_3, currVal_4); var currVal_6 = "true"; var currVal_7 = "home"; _ck(_v, 20, 0, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("pageNotFound.heading")); _ck(_v, 6, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("pageNotFound.notFound")); _ck(_v, 9, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("pageNotFound.content")); _ck(_v, 12, 0, currVal_2); var currVal_5 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("hamburgerMenu.home")); _ck(_v, 17, 0, currVal_5); }); }
export function View_PageNotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-not-found", [], null, null, null, View_PageNotFoundComponent_0, RenderType_PageNotFoundComponent)), i1.ɵdid(1, 114688, null, 0, i12.PageNotFoundComponent, [i11.UtilityService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageNotFoundComponentNgFactory = i1.ɵccf("app-page-not-found", i12.PageNotFoundComponent, View_PageNotFoundComponent_Host_0, {}, {}, []);
export { PageNotFoundComponentNgFactory as PageNotFoundComponentNgFactory };
