import { OnInit } from "@angular/core";
import { ATTRIBUTE_TYPE } from "equitable-component-library";
import { UtilityService } from "../../../core/services/utility.service";
import { SCS_SERIES, ROUTE_PATH } from "../../../constants/constants";
import { ScsService } from "../../../scs/services/scs.service";
import { TranslateService } from "@ngx-translate/core";
import { FeatureFlagService } from "../../../core/services/feature-flag.service";
import { environment } from "../../../../environments/environment";
var MarketTrendIndicatorComponent = /** @class */ (function () {
    function MarketTrendIndicatorComponent(utilityService, scsService, translate, flagService) {
        this.utilityService = utilityService;
        this.scsService = scsService;
        this.translate = translate;
        this.flagService = flagService;
        this.backButtonSeries = this.scsService.series === SCS_SERIES.PLUS ? SCS_SERIES.PLUS : "";
    }
    MarketTrendIndicatorComponent.prototype.ngOnInit = function () {
        this.mtiURL = environment.EQUITABLE_HOMEPAGE + '/retirement/products/variable-annuities/performance-cap-rates/nonav?product=Investment%20Edge%2021';
        console.log('mtiURL is: ');
        console.log(this.mtiURL);
        this.marketTrendIndicatorUrl = this.utilityService.sanitizeDom(ATTRIBUTE_TYPE.URL, this.mtiURL);
    };
    MarketTrendIndicatorComponent.prototype.ionViewWillEnter = function () {
        this.backLink = this.translate.instant("productName.ie");
    };
    /***
     *  back  button
     ***/
    MarketTrendIndicatorComponent.prototype.backButton = function () {
        this.utilityService.navigate(ROUTE_PATH.IE.TOOLS.HOME);
    };
    return MarketTrendIndicatorComponent;
}());
export { MarketTrendIndicatorComponent };
