import { Injectable } from "@angular/core";
import { FILE_EXTENSION } from "../../constants/constants";

@Injectable({
  providedIn: "root",
})
export class FileUrlService {
  urls: string[] = [];

  constructor() {}

  scanForUrl<T>(data: T, baseURL?): void {
  
    if (Array.isArray(data)) {
      for (const el of data) {
        if (typeof el === "string") {
          this.saveUrl(el, baseURL);
        } else {
          this.scanForUrl(el, baseURL);
        }
      }
    } else if (data.constructor === Object) {
      for (const [_, value] of Object.entries(data)) {
        if (typeof value === "string") {
          this.saveUrl(value, baseURL);
        } else {
          this.scanForUrl(value, baseURL);
        }
      }
    }
  }

  saveUrl(data: string, baseURL?): void {
    if (baseURL && data.indexOf("http") == -1) {
      data = baseURL + data;
    }

    const urlPattern = new RegExp(
      `.+.${FILE_EXTENSION.PNG}|${FILE_EXTENSION.JSON}|${FILE_EXTENSION.XML}|${FILE_EXTENSION.JPG}|${FILE_EXTENSION.JPEG}|${FILE_EXTENSION.PDF}$`,
      "i"
    );
    if (data.match(urlPattern)) {
      this.urls.push(data);
    }
  }

  getAllUrls() {
    const uniqueFiles = this.urls.filter((item, index) => {
      return this.urls.indexOf(item) === index;
    });
    this.urls = uniqueFiles;
    return uniqueFiles;
  }
}
