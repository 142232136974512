/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./historical-performance.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../../../../node_modules/equitable-component-library/src/lib/sub-header/sub-header.component.ngfactory";
import * as i5 from "equitable-component-library/src/lib/sub-header/sub-header.component";
import * as i6 from "equitable-component-library/src/lib/equitable-utilities";
import * as i7 from "@ngx-translate/core";
import * as i8 from "@angular/common";
import * as i9 from "../../../../../node_modules/equitable-component-library/src/lib/category-tabs/category-tabs.component.ngfactory";
import * as i10 from "equitable-component-library/src/lib/category-tabs/category-tabs.component";
import * as i11 from "../../../core/components/footer/footer.component.ngfactory";
import * as i12 from "../../../core/components/footer/footer.component";
import * as i13 from "@angular/router";
import * as i14 from "../../../core/services/core.service";
import * as i15 from "../../../core/services/utility.service";
import * as i16 from "./historical-performance.component";
import * as i17 from "../../services/scs.service";
import * as i18 from "../../../core/services/feature-flag.service";
import * as i19 from "../../../tealium/utag.service";
var styles_HistoricalPerformanceComponent = [i0.styles];
var RenderType_HistoricalPerformanceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HistoricalPerformanceComponent, data: {} });
export { RenderType_HistoricalPerformanceComponent as RenderType_HistoricalPerformanceComponent };
export function View_HistoricalPerformanceComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { tabs: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 16, "ion-content", [["class", "rc-tool-parent-container"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(2, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 12, "div", [["class", "site-tools"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "rc-tool-tab-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "equitable-sub-header", [], null, [[null, "backClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backClick" === en)) {
        var pd_0 = (_co.backButton() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_SubHeaderComponent_0, i4.RenderType_SubHeaderComponent)), i1.ɵdid(6, 114688, null, 0, i5.SubHeaderComponent, [i6.EquitableUtilities], { title: [0, "title"], backLinkText: [1, "backLinkText"] }, { backClick: "backClick" }), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 7, "div", [["class", "rc-tool-tab-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 6, "ion-card", [["class", "rc-tool-tab-container card-css"]], null, null, null, i2.View_IonCard_0, i2.RenderType_IonCard)), i1.ɵprd(512, null, i8.ɵNgClassImpl, i8.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(11, 278528, null, 0, i8.NgClass, [i8.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(12, { "tab-height-history": 0, "tab-height-segment": 1, "primerica-pie-chart": 2 }), i1.ɵdid(13, 49152, null, 0, i3.IonCard, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(14, 0, [[1, 0]], 0, 1, "equitable-category-tabs", [], null, [[null, "selectedTab"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedTab" === en)) {
        var pd_0 = (_co.getSelectedTab($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_CategoryTabsComponent_0, i9.RenderType_CategoryTabsComponent)), i1.ɵdid(15, 114688, [["tabs", 4]], 0, i10.CategoryTabsComponent, [i6.EquitableUtilities, i3.PopoverController], { tabs: [0, "tabs"] }, { selectedTab: "selectedTab" }), (_l()(), i1.ɵeld(16, 0, null, 0, 1, "app-footer", [["activeTab", "Historical_performance"], ["isBlueFooterVisible", "true"], ["isWhiteFooterVisible", "true"]], null, null, null, i11.View_FooterComponent_0, i11.RenderType_FooterComponent)), i1.ɵdid(17, 638976, null, 0, i12.FooterComponent, [i13.Router, i14.CoreService, i15.UtilityService, i3.Platform], { isWhiteFooterVisible: [0, "isWhiteFooterVisible"], isBlueFooterVisible: [1, "isBlueFooterVisible"], activeTab: [2, "activeTab"], selectedProduct: [3, "selectedProduct"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 6, 1, i1.ɵnov(_v, 7).transform("historicalPerformanceChart.backLinkText")), ""); _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_2 = "rc-tool-tab-container card-css"; var currVal_3 = _ck(_v, 12, 0, _co.toggleTab, !_co.toggleTab, (_co.toggleTab && _co.usePie)); _ck(_v, 11, 0, currVal_2, currVal_3); var currVal_4 = _co.tabsConfig; _ck(_v, 15, 0, currVal_4); var currVal_5 = "true"; var currVal_6 = "true"; var currVal_7 = "Historical_performance"; var currVal_8 = _co.series; _ck(_v, 17, 0, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_HistoricalPerformanceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-historical-performance", [], null, null, null, View_HistoricalPerformanceComponent_0, RenderType_HistoricalPerformanceComponent)), i1.ɵdid(1, 114688, null, 0, i16.HistoricalPerformanceComponent, [i15.UtilityService, i7.TranslateService, i17.ScsService, i18.FeatureFlagService, i19.TealiumUtagService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HistoricalPerformanceComponentNgFactory = i1.ɵccf("app-historical-performance", i16.HistoricalPerformanceComponent, View_HistoricalPerformanceComponent_Host_0, {}, {}, []);
export { HistoricalPerformanceComponentNgFactory as HistoricalPerformanceComponentNgFactory };
