/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./selected-annuity.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../../node_modules/equitable-component-library/src/lib/button/button.component.ngfactory";
import * as i5 from "equitable-component-library/src/lib/button/button.component";
import * as i6 from "./selected-annuity.component";
import * as i7 from "../../services/utility.service";
var styles_SelectedAnnuityComponent = [i0.styles];
var RenderType_SelectedAnnuityComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectedAnnuityComponent, data: {} });
export { RenderType_SelectedAnnuityComponent as RenderType_SelectedAnnuityComponent };
function View_SelectedAnnuityComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "benefit-item"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
export function View_SelectedAnnuityComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "selected-annuity-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "selected-annuity-card-header"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "scs-image": 0, "rc-image": 1, "ie-image": 2 }), (_l()(), i1.ɵeld(5, 0, null, null, 14, "div", [["class", "selected-annuity-card-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "selected-annuity-card-content-h1"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "h2", [["class", "selected-annuity-content-h2"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "benefits-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "p", [["class", "benefit-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 2, "ul", [["class", "benefits-item"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectedAnnuityComponent_1)), i1.ɵdid(14, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 4, "div", [["class", "learn-more"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 3, "equitable-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToProducts() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ButtonComponent_0, i4.RenderType_ButtonComponent)), i1.ɵdid(17, 638976, null, 0, i5.ButtonComponent, [], { type: [0, "type"], theme: [1, "theme"] }, null), (_l()(), i1.ɵted(18, 0, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "selected-annuity-card-header"; var currVal_1 = _ck(_v, 4, 0, (_co.selectedProduct === "scs"), (_co.selectedProduct === "rc"), (_co.selectedProduct === "ie")); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_5 = _co.benefits; _ck(_v, 14, 0, currVal_5); var currVal_6 = _co.button.type; var currVal_7 = _co.button.theme; _ck(_v, 17, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.title; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.content; _ck(_v, 7, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("questionnaire.benefits")); _ck(_v, 10, 0, currVal_4); var currVal_8 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("questionnaire.explore")); _ck(_v, 18, 0, currVal_8); }); }
export function View_SelectedAnnuityComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-selected-annuity", [], null, null, null, View_SelectedAnnuityComponent_0, RenderType_SelectedAnnuityComponent)), i1.ɵdid(1, 114688, null, 0, i6.SelectedAnnuityComponent, [i7.UtilityService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectedAnnuityComponentNgFactory = i1.ɵccf("app-selected-annuity", i6.SelectedAnnuityComponent, View_SelectedAnnuityComponent_Host_0, { icon: "icon", headerImage: "headerImage", title: "title", content: "content", benefits: "benefits", selectedProduct: "selectedProduct", description: "description" }, {}, []);
export { SelectedAnnuityComponentNgFactory as SelectedAnnuityComponentNgFactory };
