import { UtilityService } from "../../services/utility.service";
import { QuestionnaireService } from "../../services/questionnaire.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-questionnaire",
  templateUrl: "./questionnaire.component.html",
  styleUrls: ["./questionnaire.component.scss"]
})
export class QuestionnaireComponent implements OnInit {
  // class variables
  step: number;
  quesions: boolean;
  result: boolean;

  constructor(
    private questionnaireService: QuestionnaireService,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.init();
    this.utilityService.sendScrollStatus(false);
  }

  init() {
    this.step = 1;
    this.quesions = true;
    this.result = false;
  }

  backButtonClicked(step: number) {
    if (step !== 1) {
      this.step = step - 1;
    } else {
      this.utilityService.navigateToBack();
    }
  }

  optionSelected(selectedoption) {
    const questionnaire = this.questionnaireService.getQuestionnaire();
    questionnaire[selectedoption.step] = selectedoption.index;
    this.questionnaireService.setQuestionnaire(questionnaire);
    if (selectedoption.step !== 3) {
      this.step = selectedoption.step + 1;
    } else {
      this.result = true;
      this.quesions = false;
    }
    // eslint-disable-next-line angular/document-service
    document.querySelectorAll("ion-content").forEach(content => {
      content.scrollToTop();
    });
  }

  retakeQuiz() {
    this.init();
  }

  ionViewDidLeave() {
    this.init();
  }
}
