import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { UtilityService } from "../../../core/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import {
  ROUTE_PATH,
  SCSINCOME_SERIES,
  CUSTOMIZATION_TYPE,
  CHARACTER_LIMIT,
  VALIDATION_TYPE,
  PRODUCT_TYPE,
} from "../../../constants/constants";
import {
  Profile,
  HistoryMayBeAGuide,
  HistoryMayBeAGuideColumn,
  SCSIncomeHowSioWorks,
} from "../../../core/models/app.models";
import { SITE } from "src/assets/sites";
import { ScsIncomeService } from "../../services/scsincome.service";
import { CoreService } from "../../../core/services/core.service";
import { FeatureFlagService } from "src/app/core/services/feature-flag.service";
import { TealiumUtagService } from '../../../tealium/utag.service';

@Component({
  selector: "app-utilize-scs",
  templateUrl: "./utilize-scs.component.html",
  styleUrls: ["./utilize-scs.component.scss"],
})
export class UtilizeScsComponent implements OnInit {
  //Variables
  route = ROUTE_PATH.SCSINCOME.TOOLS;
  formGroup: FormGroup;
  maxlength = CHARACTER_LIMIT.INPUT;
  resourceMaterials = [];
  customeContents = [];
  series = "";
  ScsIncomeService;
  backButtonText;
  backButtonTextIncome;
  backButtonSeries = 'B' // === SCSINCOME_SERIES.B ? SCSINCOME_SERIES.ADV : "";
  isNotPlusGuard: boolean;
  /***
   *  constructor
   ***/
  constructor(
    private formBuilder: FormBuilder,
    private utilityService: UtilityService,
    private translate: TranslateService,
    private coreService: CoreService,
    private scsincomeService: ScsIncomeService,
    private featureService: FeatureFlagService,
    private tealium: TealiumUtagService,
  ) {}

  /***
   *  Page Init
   ***/
  ngOnInit() {
    this.isNotPlusGuard = !this.featureService.featureFlags.isPlusGuard;
    this.series = 'B' // this.scsincomeService.series; // TODO: need to figure out how to update this to be the correct series and pull from this.scsincome.service.series
    this.translate.get("createReport").subscribe((translated) => {
      if (this.series === SCSINCOME_SERIES.B) {
        this.backButtonText = translated.backButtonTextIncome;
      } 
      else if (this.series === SCSINCOME_SERIES.ADV) {
        this.backButtonText = translated.backButtonTextIncome;
      }
      else {
        this.backButtonText = translated.backButtonTextIncome;
      }
    });

    //create form
    this.buildForm();

    this.getPageData();

    // this.tealium.link({
    //   tealium_event: 'link',
    //   event_category: 'BodyClick',
    //   event_name: 'Retirement Guide Clicks',
    //   event_label: 'Click on Create a Report'
    // })
  }

  /***
   *  Page Load/Init
   ***/
  ionViewWillEnter() {
    this.series = 'B'
    //create form
    this.buildForm();

    this.getPageData();
  }

  /***
   *  Back button event. Go back to tool home page
   ***/
  backButton() {
    this.utilityService.navigate(ROUTE_PATH.SCSINCOME.TOOLS.HOME);
  }

  /***
   *  Create form
   ***/
  buildForm() {
    this.formGroup = this.formBuilder.group({
      professionalName: new FormControl({
        value: this.coreService.report.profName,
        disabled: false,
      }),
      name: new FormControl(this.coreService.report.name),
      firmName: new FormControl(this.coreService.report.firmName),
      contactNumber: new FormControl(this.coreService.report.contactNumber),
      structuredInvestment: new FormControl({
        value: false,
        disabled: this.scsincomeService.report.aboutSCSHowSioWorks ? false : true,
      }),
      historicalPerformance: new FormControl({
        value: false,
        disabled: this.scsincomeService.report.historicalPerformance ? false : true,
      }),
      historyGuide: new FormControl({
        value: false,
        disabled: this.scsincomeService.report.historyMayBeAGuide ? false : true,
      }),
      historicalPerformancePlus: new FormControl({
        value: false,
        disabled: this.scsincomeService.report.historyMayBeAGuidePlus ? false : true,
      })
    });

    //save data to service using form change value
    this.formGroup.valueChanges.subscribe((data) => {
      this.coreService.report.name = data.name;
      this.coreService.report.firmName = data.firmName;
      this.coreService.report.contactNumber = data.contactNumber;
      this.coreService.report.profName = data.professionalName;
    });
  }

  /***
   *  get Data for resources
   ***/
  getPageData() {
    //get resources for services

    //this.utilityService.showLoader();


    this.scsincomeService.getResources(this.scsincomeService.series).subscribe((res) => {
      this.resourceMaterials = res;
      //add more form control dynamically to track the resources
      res.forEach((element) => {
        const control = new FormControl(element.description);
        control.setValue(false);
        this.formGroup.addControl(element.description, control);
      });

      //add custome content its dynamic based on series B,C, ADV, PLUS
      const customeContents = [
        {
          formControlName: "structuredInvestment",
          formControl: this.formGroup.get("structuredInvestment"),
          description: this.translate.instant(
            "createReport.checkboxDescription1ScsIncome"
          ),
          link: {
            label: this.scsincomeService.report.aboutSCSHowSioWorks
              ? this.translate.instant("createReport.edit")
              : this.translate.instant("createReport.customize"),
            path: "/" + ROUTE_PATH.SCSINCOME.TOOLS.HOW_SIO_WORKS,
          },
          imagePath: "./assets/images/report-checkbox.jpg",
          reportKey: "aboutSCSHowSioWorks",
        },
      /*   {
          formControlName: "historicalPerformance",
          formControl: this.formGroup.get("historicalPerformance"),
          description: this.translate.instant(
            "createReport.checkboxDescription2"
          ),
          link: {
            label: this.scsincomeService.report.historicalPerformance
              ? this.translate.instant("createReport.edit")
              : this.translate.instant("createReport.customize"),
            path:
              "/" +
              ROUTE_PATH.SCSINCOME.TOOLS.HISTORICAL_PERFORMANCE +
              "/" +
              ROUTE_PATH.SCSINCOME.TOOLS.SEGMENT_PERFORMANCE,
          },
          imagePath: "./assets/images/report-checkbox2.jpg",
          reportKey: "historicalPerformance",
        }, */
        /* {
          formControlName: "historyGuide",
          formControl: this.formGroup.get("historyGuide"),
          description: this.translate.instant(
            "createReport.checkboxDescription3"
          ),
          link: {
            label: this.scsincomeService.report.historyMayBeAGuide
              ? this.translate.instant("createReport.edit")
              : this.translate.instant("createReport.customize"),
            path:
              "/" +
              ROUTE_PATH.SCSINCOME.TOOLS.HISTORICAL_PERFORMANCE +
              "/" +
              ROUTE_PATH.SCSINCOME.TOOLS.HISTORY_GUIDE,
          },
          imagePath: "./assets/images/report-checkbox3.jpg",
          reportKey: "historyMayBeAGuide",
        }, */
        {
          formControlName: "historicalPerformancePlus",
          formControl: this.formGroup.get("historicalPerformancePlus"),
          description: this.translate.instant(
            "createReport.checkboxDescription3"
          ),
          link: {
            label: this.scsincomeService.report.historyMayBeAGuidePlus
              ? this.translate.instant("createReport.edit")
              : this.translate.instant("createReport.customize"),
            path: "/" + ROUTE_PATH.SCSINCOME.TOOLS.HISTORICAL_PERFORMANCE,
          },
          imagePath: "./assets/images/report-checkbox4.jpg",
          reportKey: "historyMayBeAGuidePlus",
        }
      ];

      //sppecial fix for primerica
      if (this.featureService.featureFlags.report === "PRIMERICA_REPORT") {
        customeContents[2].imagePath = "./assets/images/report-checkbox4.jpg";
        customeContents[2].formControlName = "historicalPerformancePlus";
        customeContents[2].formControl = this.formGroup.get(
          "historicalPerformancePlus"
        );
        customeContents[2].link.label = this.scsincomeService.report
          .historyMayBeAGuidePlus
          ? this.translate.instant("createReport.edit")
          : this.translate.instant("createReport.customize");
        customeContents[2].reportKey = "historyMayBeAGuidePlus";
      }

      if (
        this.scsincomeService.customizationType === CUSTOMIZATION_TYPE.PROFILE ||
        this.scsincomeService.customizationType === CUSTOMIZATION_TYPE.REPORT
      ) {
        this.scsincomeService.getProfile().subscribe((profiles) => {
          if (profiles && profiles.length > 0) {
            const profile = profiles.find(
              (x) =>
                x.name ===
                  (this.scsincomeService.customizationType ==
                  CUSTOMIZATION_TYPE.PROFILE
                    ? this.scsincomeService.currentProfile
                    : CUSTOMIZATION_TYPE.REPORT) &&
                x.currentSerie === this.series
            );
            if (profile) {
              this.scsincomeService.report = profile;
            } else {
              this.scsincomeService.report = new Profile();
            }

            customeContents.forEach((element) => {
              if (this.scsincomeService.report[element.reportKey]) {
                this.formGroup.get(element.formControlName).enable();
              } else {
                this.formGroup.get(element.formControlName).disable();
              }
            });
          }
        });
      }

      //if series plus then only how-sio-works and pie charts
      if (
        this.series === SCSINCOME_SERIES.B ||
        this.series === SCSINCOME_SERIES.ADV
      ) {
        customeContents.splice(3, 1);
      } else if (this.scsincomeService.series === SCSINCOME_SERIES.B) {
        customeContents.splice(1, 2);
      }
      else if (this.scsincomeService.series === SCSINCOME_SERIES.ADV) {
        customeContents.splice(1, 2);
      }

      this.customeContents = customeContents;
      this.utilityService.hideLoader();
    });
  }

  /***
   *  get Data for resources
   ***/
  generatePDF() {
    console.log(PRODUCT_TYPE);
    if (this.formGroup.valid) {
      const utilizeData = new Profile();
      utilizeData.sourceURL = SITE.AXA;
      utilizeData.name = this.formGroup.get("name").value;
      utilizeData.profName = this.formGroup.get("professionalName").value;
      utilizeData.firmName = this.formGroup.get("firmName").value;
      utilizeData.contactNumber = this.formGroup.get("contactNumber").value;

      //add section to the report if applicable
      const str = [];
      this.customeContents.forEach((element) => {
        if (element.formControl.value === true) {
          //add section key to array. PDF generate will based on that
          str.push(element.reportKey);
          //add section data

          utilizeData[element.reportKey] = this.scsincomeService.report[
            element.reportKey
          ];
        }
      });

      //minor fix for remove space
      if (utilizeData.aboutSCSHowSioWorks) {
        utilizeData.aboutSCSHowSioWorks.segmentOption = utilizeData.aboutSCSHowSioWorks.segmentOption
          .replace(/\s/g, "")
          .toLowerCase();

        utilizeData.aboutSCSHowSioWorks.index = utilizeData.aboutSCSHowSioWorks.index.replace(
          "®",
          ""
        );
      }

      //change string array to string to
      utilizeData.sectionsName = str.toString();

      //add learning resources
      const obj = {};
      this.resourceMaterials.forEach((element) => {
        if (this.formGroup.get(element.description).value === true) {
          obj[element.description] = element.file;
        }
      });
      utilizeData.learningResourcesIncluded = obj;

      //add series name
      
      utilizeData.currentSerie = this.series;

      //sppecial fix for primerica. Series B but behave like PLUS
      if (
        (this.featureService.featureFlags.report === "PRIMERICA_REPORT" ||
          this.featureService.featureFlags.report === "WF_REPORT") &&
        utilizeData.historyMayBeAGuidePlus
      ) {
        utilizeData.historyMayBeAGuide = new HistoryMayBeAGuide();
        Object.keys(utilizeData.historyMayBeAGuidePlus).forEach(
          (element, index) => {
            utilizeData.historyMayBeAGuide[
              "column" + (index + 1)
            ] = new HistoryMayBeAGuideColumn();
            utilizeData.historyMayBeAGuide["column" + (index + 1)].label =
              utilizeData.historyMayBeAGuidePlus[
                "columnPlus" + (index + 1)
              ].label;
            utilizeData.historyMayBeAGuide["column" + (index + 1)].url =
              utilizeData.historyMayBeAGuidePlus[
                "columnPlus" + (index + 1)
              ].url;
          }
        );
        delete utilizeData.historyMayBeAGuidePlus;
        utilizeData.sectionsName = utilizeData.sectionsName.replace(
          "historyMayBeAGuidePlus",
          "historyMayBeAGuide"
        );
      }

      if (
        this.featureService.featureFlags.report === "WF_REPORT" &&
        utilizeData.aboutSCSHowSioWorks
      ) {
        /** for wells fargo create a report, create report API is not expecting data.
         * it seem everything is hardcoded in API and absed on some key value PDF generated
         * even bar values are in correct
         *  */
        // Object.keys(utilizeData.aboutSCSHowSioWorks.bars).forEach((element) => {
        //   console.log("UTILIZE utilizeData.aboutSCSHowSioWorks.bars[element]", element, utilizeData.aboutSCSHowSioWorks.bars[element])
        //   utilizeData.aboutSCSHowSioWorks.bars[element] = 0; //for e.g. every bar value changed to 0 stil working.
        // });

        if (
          utilizeData.aboutSCSHowSioWorks.duration === "6yr" &&
          (utilizeData.aboutSCSHowSioWorks.index
            .toUpperCase()
            .indexOf("S&P") !== -1 ||
            utilizeData.aboutSCSHowSioWorks.index
              .toUpperCase()
              .indexOf("RUSSELL") !== -1)
        ) {
          // API demand some data  on specific format
          if (
            utilizeData.aboutSCSHowSioWorks.index
              .toUpperCase()
              .indexOf("RUSSELL") !== -1
          ) {
            //API has not even check cases. it need to be in specific case
            utilizeData.aboutSCSHowSioWorks.index = "RUSSELL 2000 6 Yr";
          } else if (
            utilizeData.aboutSCSHowSioWorks.index
              .toUpperCase()
              .indexOf("S&P") !== -1
          ) {
            utilizeData.aboutSCSHowSioWorks.index = "S&P 500 6 Yr";
          }
        } else if (utilizeData.aboutSCSHowSioWorks.duration === "1yr") {
          if (
            utilizeData.aboutSCSHowSioWorks.index
              .toUpperCase()
              .indexOf("RUSSELL") !== -1
          ) {
            //API has not even check cases. it need to be in specific case
            utilizeData.aboutSCSHowSioWorks.index = "RUSSELL 2000 1 Yr";
          } else if (
            utilizeData.aboutSCSHowSioWorks.index
              .toUpperCase()
              .indexOf("S&P") !== -1
          ) {
            utilizeData.aboutSCSHowSioWorks.index = "S&P 500 1 Yr";
          }
        }
      }
      
     // this.coreService.pdfFetchResources(utilizeData, this.series)
     if (this.series === SCSINCOME_SERIES.B || this.series === SCSINCOME_SERIES.ADV) {
        this.coreService.pdfFetchResources(utilizeData, PRODUCT_TYPE.SCSINCOME)
      } 
      
      
     
 

    } else {
      this.utilityService.showValidationErrorForControls(this.formGroup);
    }


    // add tealium_event: "Generate_PDF" ?
    this.tealium.link({
      event_name: "Retirement Guide Clicks - SCS",
      tool_name: "BodyClicks",
      event_label: "Create a report"
    })
  }

  /***
   *  scroll event
   ***/
  scroll(ev: CustomEvent): void {
    if (ev.detail.scrollTop >= 1) {
      this.utilityService.sendScrollStatus(false);
    } else {
      this.utilityService.sendScrollStatus(false);
    }
  }
}
