<ion-content>
  <equitable-header-image
    class="top-space"
    image="./assets/images/discover-rc-banner.png"
    [description]="'discoverRc.title' | translate"
  ></equitable-header-image>

  <ion-grid class="ion-no-padding">
    <!-- RC-discription-section START -->
    <div class="container">
      <ion-row class="section-space">
        <ion-col>
          <ion-row class="rc-section">
            <ion-col size="12">
              <div class="page-description-wrapper">
                <p
                  class="page-description"
                  [innerHTML]="'discoverRc.description' | translate"
                ></p>
              </div>
            </ion-col>
          </ion-row>
          <ion-row class="feature-section">
            <ion-col size="12">
              <h2
                class="feature-heading"
                [innerHtml]="'discoverRc.featureTitle' | translate"
              ></h2>
              <!-- equitable-card-benefit  -->
              <div class="benefit-card">
                <div
                  *ngFor="let card of featureCards"
                  class="benefit-card-inner"
                >
                  <equitable-card-benefit
                    [icon]="card.icon"
                    [description]="card.description | translate"
                    [title]="card.title | translate"
                  ></equitable-card-benefit>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </div>
    <!-- RC-discription-section END -->

    <ion-row class="graph1-section">
      <div class="container">
        <ion-col>
          <h1
            class="graph1-heading"
            [innerHtml]="
              'discoverRc.graphSectionFirst.graph1Heading' | translate
            "
          ></h1>
          <p
            class="graph1-subtext"
            [innerHtml]="
              'discoverRc.graphSectionFirst.graph1SubHeading' | translate
            "
          ></p>
          <div class="graph1-flex">
            <div class="graph1-left">
              <ul>
                <li>
                  {{
                    "discoverRc.graphSectionFirst.graph1Content1" | translate
                  }}
                </li>
                <li>
                  {{
                    "discoverRc.graphSectionFirst.graph1Content2" | translate
                  }}
                </li>
                <li>
                  {{
                    "discoverRc.graphSectionFirst.graph1Content3" | translate
                  }}
                </li>
              </ul>
            </div>
            <div class="graph1-right">
              <ul>
                <li>
                  <span class="graph-txt">{{
                    "discoverRc.graphSectionFirst.graph1Invests" | translate
                  }}</span>
                  <span class="graph-value">{{
                    "discoverRc.graphSectionFirst.investValue" | translate
                  }}</span>
                </li>
                <li>
                  <span class="graph-txt">{{
                    "discoverRc.graphSectionFirst.graph1Withdrawals" | translate
                  }}</span
                  ><span class="graph-value">{{
                    "discoverRc.graphSectionFirst.withdrawalsValue" | translate
                  }}</span>
                </li>
                <li>
                  <span class="graph-txt">{{
                    "discoverRc.graphSectionFirst.graph1DeathBenefits"
                      | translate
                  }}</span
                  ><span class="graph-value">{{
                    "discoverRc.graphSectionFirst.deathBenefitsValue"
                      | translate
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <img
            src="./assets/images/discover-graph1.png"
            alt="{{ 'discoverRc.howRetirementCornerstoneWorks' | translate }}"
          />
        </ion-col>
      </div>
    </ion-row>

    <ion-row class="graph2-section">
      <ion-col>
        <div class="graph2-wrapper container">
          <div class="graph2-right-section">
            <div class="graph2-content">
              <h1
                [innerHtml]="
                  'discoverRc.graphSectionSecond.graph2Heading' | translate
                "
              ></h1>
              <p
                class="graph-para"
                [innerHtml]="
                  'discoverRc.graphSectionSecond.graph2Subheading1' | translate
                "
              ></p>
              <div class="graph2-flex">
                <div>
                  <p>
                    {{
                      "discoverRc.graphSectionSecond.graph2Content1" | translate
                    }}
                    <strong>
                      {{ "discoverRc.graphSectionSecond.value1" | translate }}
                    </strong>
                  </p>
                </div>
                <div>
                  <p>
                    {{
                      "discoverRc.graphSectionSecond.graph2Content2" | translate
                    }}
                    <strong>
                      {{ "discoverRc.graphSectionSecond.value2" | translate }}
                    </strong>
                  </p>
                </div>
                <div>
                  <p>
                    {{
                      "discoverRc.graphSectionSecond.graph2Content3" | translate
                    }}
                    <strong>
                      {{ "discoverRc.graphSectionSecond.value3" | translate }}
                    </strong>
                  </p>
                </div>
              </div>
              <p
                class="graph-para"
                [innerHtml]="
                  'discoverRc.graphSectionSecond.planYourRetirement' | translate
                "
              ></p>
            </div>
          </div>
          <div class="graph2-col">
            <img
              src="./assets/images/g_annual_income.png"
              alt="{{ 'discoverRc.annualIncome' | translate }}"
            />
          </div>
        </div>
      </ion-col>
    </ion-row>

    <!-- Strategies START -->
    <section class="explore-other-strategies">
      <div class="container">
        <h2>{{ "discoverRc.exploreStrategies" | translate }}</h2>
        <div class="card-image-container">
          <equitable-card-image *appFeatureFlag="'isPrimerica'" 
            (eventMore)="gotoSCSPLUSLandingPage()"
            image="./assets/images/distribute.jpg"
            [heading]="'discoverRc.protectionVolatility' | translate"
            [description]="'discoverRc.protectionVolatilityDescPLUS' | translate"
            [subContent]="guaranteedIncome"
          >
            {{ "discoverRc.learnMoreBtn" | translate }}
          </equitable-card-image>
          <equitable-card-image  
            (eventMore)="gotoSCSLandingPage()"
            image="./assets/images/protection-from-market.jpg"
            [heading]="'discoverRc.protectionVolatility' | translate"
            [description]="'discoverRc.protectionVolatilityDesc' | translate"
            [subContent]="guaranteedIncome"
          >
            {{ "discoverRc.learnMoreBtn" | translate }}
          </equitable-card-image>

          <equitable-card-image *appFeatureFlag="'isNational'"
          (eventMore)="gotoSCSINCOMELandingPage()"
          image="./assets/images/income-tile.jpg"
          [heading]="'discoverScs.incomeCardHeading' | translate"
          [description]="'discoverScs.incomeCardCopy' | translate"
          [subContent]="scsincomeBenefits"
        >
          {{ "discoverScs.learnMoreBtn" | translate }}
        </equitable-card-image>


          <equitable-card-image *appFeatureFlag="'isNational'" 
          (eventMore)="gotoIELandingPage()"
          image="./assets/images/distribute.jpg"
          [heading]="'discoverRc.deferYourTaxes' | translate"
          [description]="'discoverRc.deferYourTaxesDesc' | translate"
          [subContent]="deferYourTaxes"
        >
          {{ "discoverRc.learnMoreBtn" | translate }}
        </equitable-card-image>
        </div>
      </div>
    </section>
    <!-- Strategies END -->

    <!-- Advanced Tool And Resourcex START -->
    <ion-row>
      <ion-col>
        <equitable-advanced-tool
          image="./assets/images/advanced-tools.png"
          [title]="'discoverRc.advancedToolsResources' | translate"
          [description]="'discoverRc.advancedToolsResourcesDesc' | translate"
          [btnLabel]="'discoverRc.learnMoreBtn' | translate"
          (btnClick)="gotoAdvancedToolPage()"
        ></equitable-advanced-tool>
      </ion-col>
    </ion-row>
    <!-- Advanced Tool And Resourcex END -->
  </ion-grid>
  <app-footer
    isWhiteFooterVisible="true"
    isBlueFooterVisible="true"
    selectedProduct="rc"
  ></app-footer>
</ion-content>
