import { ButtonType, Theme, LinkType, LinkIcon, Orientation, } from "equitable-component-library";
import { ROUTE_PATH } from "../../../constants/constants";
import { SITE } from "src/assets/sites";
import { UtilityService } from "../../services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { Platform } from "@ionic/angular";
import { Router } from "@angular/router";
import { FeatureFlagService } from "../../../core/services/feature-flag.service";
import { ModalController } from '@ionic/angular';
import { ScsIncomeService } from "../../../scsincome/services/scsincome.service";
var HomePage = /** @class */ (function () {
    function HomePage(utilityService, translate, platform, router, flagService, modalController, scsincomeService) {
        this.utilityService = utilityService;
        this.translate = translate;
        this.platform = platform;
        this.router = router;
        this.flagService = flagService;
        this.modalController = modalController;
        this.scsincomeService = scsincomeService;
        this.site = SITE;
        this.route = ROUTE_PATH;
        // for read more link
        this.readMoreLinkType = LinkType.Light;
        // enum
        this.buttonType = ButtonType;
        this.theme = Theme;
        this.linkIcon = LinkIcon;
        // for links
        this.externalLinkIcon = LinkIcon.SignIn;
        this.solutionLinkIcon = LinkIcon.RightIcon;
        this.solutionLinkIconOrientation = Orientation.Right;
        this.solutionLinkType = LinkType.Dark;
        this.lightLink = LinkType.Light;
        // for visit Equitable.com link
        this.visitEquitableBtnType = ButtonType.Primary;
        this.visitEquitableBtnTheme = Theme.Light;
    }
    HomePage.prototype.ngOnInit = function () {
        var _this = this;
        this.isPrimerica = this.flagService.featureFlags.isPrimerica;
        this.isNational = this.flagService.featureFlags.isNational;
        this.platform.ready().then(function () {
            _this.translate.get("individualRetirementHome").subscribe(function (translated) {
                _this.shiftsInMarket = _this.translate.instant("individualRetirementHome.shiftsInMarket", { break: "<div>" });
                _this.shiftsInMarketWithoutBrk = _this.translate.instant("individualRetirementHome.shiftsInMarket", { break: " " });
            });
        });
        // open the modal if we are on national version of the app
        if (this.isNational) {
            //this.openModal()
        }
    };
    // visit external site Equitable.com
    HomePage.prototype.visitEquitable = function (url) {
        this.utilityService.visitEquitableMicrosite(url);
    };
    HomePage.prototype.gotToStrategiesSection = function () {
        this.utilityService.navigate("#ourstrategies", { 'fragment': 'ourstrategies' });
        this.router.navigate(['/home'], { fragment: 'ourstrategies' });
        document.querySelector("[name='ourstrategies']").scrollIntoView(false);
    };
    HomePage.prototype.visitPage = function (route) {
        this.utilityService.navigate(route);
    };
    return HomePage;
}());
export { HomePage };
