import { OnInit } from "@angular/core";
import { IE_TOOLS_CALCULATORS, ROUTE_PATH } from "../../../constants/constants";
import { ActivatedRoute } from "@angular/router";
import { UtilityService } from "../../../core/services/utility.service";
import { ATTRIBUTE_TYPE, Theme, Orientation, Icon, } from "equitable-component-library";
import { IeService } from "../../services/ie.service";
var IeToolsComponent = /** @class */ (function () {
    function IeToolsComponent(utilityService, route, ieService) {
        this.utilityService = utilityService;
        this.route = route;
        this.ieService = ieService;
        this.type = Theme.Dark;
        this.orientation = Orientation.Left;
        this.backIcon = Icon.BackButton;
        this.activeTab = IE_TOOLS_CALCULATORS.TAX_DEFERRAL;
        this.segments = [
            IE_TOOLS_CALCULATORS.TAX_DEFERRAL,
            IE_TOOLS_CALCULATORS.BREAK_EVEN,
            IE_TOOLS_CALCULATORS.TAX_COMPARISON,
            IE_TOOLS_CALCULATORS.TAX_AND_INFLATION,
            IE_TOOLS_CALCULATORS.FEDERAL_INCOME_TAX,
        ];
        this.segmentChanged(this.activeTab);
    }
    IeToolsComponent.prototype.ngOnInit = function () {
    };
    IeToolsComponent.prototype.loadiFrame = function (params) {
        var _this = this;
        if (params) {
            this.ieService.getIEResources(this.ieService.series).subscribe(function (ie) {
                _this.getIFrameURL(params, ie);
                _this.iFrameUrl = _this.utilityService.sanitizeDom(ATTRIBUTE_TYPE.URL, _this.url);
            });
        }
    };
    // sets iframe url for provided queryParameter
    IeToolsComponent.prototype.getIFrameURL = function (queryParameter, ieCalculators) {
        switch (queryParameter) {
            case IE_TOOLS_CALCULATORS.TAX_DEFERRAL:
                //this.url = ieCalculators.taxDeferraliFrame;
                this.url = 'https://www.calcxml.com/calculators/annuity-calculator?skn=527&r=2&tabs=1&secure=1';
                this.title = "ieDashbaord.tools.taxDeferralCalculator";
                break;
            case IE_TOOLS_CALCULATORS.BREAK_EVEN:
                //this.url = ieCalculators.breakEveniFrame;
                this.url = 'https://www.calcxml.com/calculators/tax-deferral-benefits-of-variable-annuities?skn=527&r=2&tabs=1&secure=1';
                this.title = "ieDashbaord.tools.breakEvenCalculator";
                break;
            case IE_TOOLS_CALCULATORS.TAX_COMPARISON:
                //this.url = ieCalculators.taxComparisoniFrame;
                this.url = 'https://www.calcxml.com/calculators/inc07?skn=527&r=2&tabs=1&secure=1';
                this.title = "ieDashbaord.tools.taxComparisonCalculator";
                break;
            case IE_TOOLS_CALCULATORS.TAX_AND_INFLATION:
                //this.url = ieCalculators.taxAndInflationiFrame;
                this.url = 'https://www.calcxml.com/calculators/investment-return?skn=527&r=2&tabs=1&secure=1';
                this.title = "ieDashbaord.tools.taxInflationCalculator";
                break;
            case IE_TOOLS_CALCULATORS.FEDERAL_INCOME_TAX:
                //this.url = ieCalculators.federalIncomeTaxiFrame;
                this.url = 'https://www.calcxml.com/calculators/federal-income-tax-calculator?skn=527&r=2&tabs=1&secure=1';
                this.title = "ieDashbaord.tools.federalIncomeTaxCalculator";
                break;
            default:
                //this.url = ieCalculators.taxDeferraliFrame;
                this.url = 'https://www.calcxml.com/calculators/annuity-calculator?skn=527&r=2&tabs=1&secure=1';
                this.title = "ieDashbaord.tools.taxDeferralCalculator";
                break;
        }
    };
    IeToolsComponent.prototype.segmentChanged = function (tabName) {
        this.activeTab = tabName;
        if (tabName === IE_TOOLS_CALCULATORS.TAX_DEFERRAL) {
            this.selectedTab = IE_TOOLS_CALCULATORS.TAX_DEFERRAL;
            this.loadiFrame(IE_TOOLS_CALCULATORS.TAX_DEFERRAL);
            console.log('chaned tab to tax deferral');
        }
        else if (tabName === IE_TOOLS_CALCULATORS.BREAK_EVEN) {
            this.selectedTab = IE_TOOLS_CALCULATORS.BREAK_EVEN;
            this.loadiFrame(IE_TOOLS_CALCULATORS.BREAK_EVEN);
            console.log('chaned tab to break even');
        }
        else if (tabName === IE_TOOLS_CALCULATORS.TAX_COMPARISON) {
            this.selectedTab = IE_TOOLS_CALCULATORS.TAX_COMPARISON;
            this.loadiFrame(IE_TOOLS_CALCULATORS.TAX_COMPARISON);
            console.log('changed tab to tax comparison ');
        }
        else if (tabName === IE_TOOLS_CALCULATORS.TAX_AND_INFLATION) {
            this.selectedTab = IE_TOOLS_CALCULATORS.TAX_AND_INFLATION;
            this.loadiFrame(IE_TOOLS_CALCULATORS.TAX_AND_INFLATION);
            console.log('changed tab to tax and inflation ');
        }
        else if (tabName === IE_TOOLS_CALCULATORS.FEDERAL_INCOME_TAX) {
            this.selectedTab = IE_TOOLS_CALCULATORS.FEDERAL_INCOME_TAX;
            this.loadiFrame(IE_TOOLS_CALCULATORS.FEDERAL_INCOME_TAX);
            console.log('changed tab to federal income tax ');
        }
    };
    IeToolsComponent.prototype.backButton = function () {
        this.utilityService.navigate(ROUTE_PATH.IE.TOOLS.HOME);
    };
    return IeToolsComponent;
}());
export { IeToolsComponent };
