<ion-grid class="wrapper">
  <ion-row>
    <ion-col class="ion-no-padding" size="12" size-xl="5">
      <div class="grow-income-value">
        <ion-row>
          <ion-col class="ion-no-padding" size-md="6" size-xl="12" size="12">
            <div class="protect-income-header title-spacing">
              {{ "guaranteedIncome.protectIncome" | translate }}
            </div>
            <div class="protect-income-labels">
              {{ "guaranteedIncome.retirementIncome" | translate }}
            </div>
          </ion-col>
          <ion-col
            class="protect-income-heading"
            size-md="5.5"
            size-xl="12"
            size="12"
          >
            <div class="matter-label">
              {{ "guaranteedIncome.matterLabel" | translate }}
            </div>
            <p class="protect-income-labels">
              {{ "guaranteedIncome.livingLonger" | translate }}
            </p>
          </ion-col>
        </ion-row>
        <div class="protect-income-header multi-year-lock">
          {{ "guaranteedIncome.yearLock" | translate }}
        </div>
        <div class="protect-income-labels year-lock-years">
          <span class="circle-icon first-icon"></span>
          <ion-label>
            {{ "guaranteedIncome.lockSeven" | translate }}
          </ion-label>
        </div>
        <div class="rates-row">
          <div class="income-rates">
            {{ "guaranteedIncome.label1" | translate }}

            {{ takingIncomeRate }}
            <span>
              {{ "guaranteedIncome.label2" | translate }}
            </span>
          </div>
        </div>
        <div class="protect-income-labels year-lock-years">
          <span class="circle-icon second-icon"></span>
          <ion-label>
            {{ "guaranteedIncome.yearLock8" | translate }}
          </ion-label>
        </div>
        <div class="rates-row">
          <div class="income-rates ion-padding-bottom">
            {{ "guaranteedIncome.yearTreasury" | translate }}
          </div>
          <div class="income-rates">
            {{ "guaranteedIncome.yearLock5" | translate }}
            <span> {{ "guaranteedIncome.yearLock5Label" | translate }}</span>
          </div>
        </div>
        <div class="footer-description">
          <p
            innerHTML="
          {{ 'guaranteedIncome.footerParaOneof1' | translate }} {{ date }}{{
              'guaranteedIncome.footerParaOneof2' | translate
            }}"
          ></p>
          <p>
            {{ "guaranteedIncome.footerParaTwo" | translate }}
          </p>
          <p>
            {{ "guaranteedIncome.footerParaThree" | translate }}
          </p>
          <p>
            {{ "guaranteedIncome.footerParaThreePara2" | translate }}
          </p>
        </div>
      </div>
    </ion-col>
    <ion-col size="12" size-xl="7" class="right-colomn ion-no-padding">
      <img alt="" [src]="chartImage" class="chart-image" />
    </ion-col>
  </ion-row>
</ion-grid>
