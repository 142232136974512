import { Component, Injectable, ErrorHandler, Injector, OnInit } from "@angular/core";
import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { TranslateService } from "@ngx-translate/core";
import { Globalization } from "@ionic-native/globalization/ngx";
import { UtilityService } from "./core/services/utility.service";
import { HamburgerMenu } from "./core/models/app.models";
import { IonicStorageService } from "./core/services/ionic-storage.service";
import { CoreService } from "./core/services/core.service";
import {
  LANGUAGE,
  ROUTE_PATH,
  INVESTOR_PROFILE_PAGES,
  CUSTOMIZATION_TYPE,
  PRODUCT_TYPE,
} from "./constants/constants";
import { Network } from "@ionic-native/network/ngx";
import { Router, NavigationStart } from "@angular/router";
import { TealiumUtagService } from './tealium/utag.service';
import { environment } from "../environments/environment";

@Component({
  selector: "app-root",
  providers: [TealiumUtagService],
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  menu: HamburgerMenu[];
  private deviceLanguage: string;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    private globalization: Globalization,
    private storageService: IonicStorageService,
    private coreService: CoreService,
    private network: Network,
    private router: Router,
    private util: UtilityService,
    private tealium: TealiumUtagService,
  ) {
    this.tealium.setConfig({ 
      account: 'equitable',
      profile: 'ir-web-ipad-apps',
      environment: environment.tealiumEnvironment,
    });
    this.initializeApp();
  }

  ngOnInit(): void {
    // The tealium.view function will call the tealium.track function with 'view' as first param
    // Most tags support the 'view' event and many analytics tags also support the 'link' event
    // this.tealium.view({event_name: 'init'});
    this.tealium.track('view', {'event_name' : 'init'});
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.translate.setDefaultLang(LANGUAGE.EN);
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.getDeviceLanguage();
      this.setHamburgermenu();
      //load main data
      if (
        (this.platform.is("cordova") &&
          this.network.type !== this.network.Connection.NONE) ||
        navigator.onLine
      ) {
        this.storageService.loadMainData();
      }
    });
  }

  // Method to get HamburgerMenu data using translateservice
  setHamburgermenu(): void {
    this.coreService.getHamburgermenu().subscribe((menu: HamburgerMenu[]) => {
      this.menu = menu;
      //console.log(this.menu);
    });
  }

  getDeviceLanguage() {
    this.globalization
      .getPreferredLanguage()
      .then((res) => {
        try {
          if (res && JSON.parse(JSON.stringify(res)).value) {
            const lang: string = JSON.parse(JSON.stringify(res)).value.split(
              "-"
            )[0];
            if (lang !== null || lang !== undefined || lang !== "") {
              this.deviceLanguage = lang;
              this.translate.use(this.deviceLanguage);
            } else {
              this.translate.use(LANGUAGE.EN);
            }
          }
        } catch (e) {
          this.translate.use(LANGUAGE.EN);
        }
      })
      .catch((e) => {
        e;
        this.translate.use(LANGUAGE.EN);
      });
  }

  scrollTop() {
    if (navigator.userAgent.search("Firefox") >= 0) {
      // eslint-disable-next-line angular/document-service
      document.querySelectorAll("ion-content").forEach((content) => {
        content.scrollToTop();
      });
    }
  }
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  get router(): Router {
    console.log(this.injector.get(Router))
    return this.injector.get(Router);
  }

  handleError(error) {
    console.log(error);
    if (
      error &&
      error.message &&
      error.message.indexOf("Error: Cannot match any routes. URL Segment:") !==
        -1
    ) {
      // eslint-disable-next-line angular/window-service
      this.router.navigate(["404"], { skipLocationChange: true });
    }
  }
}
