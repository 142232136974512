import { UtilityService } from "../../../../../src/app/core/services/utility.service";
import { OnInit } from "@angular/core";
import { LinkType, LinkIcon, Orientation } from "equitable-component-library";
import { ROUTE_PATH } from "src/app/constants/constants";
var HowitworksToolComponent = /** @class */ (function () {
    /***
     *  Constructor for the page
     ***/
    function HowitworksToolComponent(utilityService) {
        this.utilityService = utilityService;
        //variables
        this.LinkType = LinkType;
        this.LinkIcon = LinkIcon;
        this.Orientation = Orientation;
    }
    /***
     *  Page load for page
     ***/
    HowitworksToolComponent.prototype.ngOnInit = function () {
        console.log("This componenet is being used.");
    };
    /***
   *  back button click event
   ***/
    HowitworksToolComponent.prototype.backButton = function () {
        this.utilityService.navigate(ROUTE_PATH.IE.TOOLS.HOME);
    };
    return HowitworksToolComponent;
}());
export { HowitworksToolComponent };
