import { OnInit } from "@angular/core";
import { RcService } from "../../services/rc.service";
import { take } from "rxjs/operators";
import { UtilityService } from "src/app/core/services/utility.service";
var GuaranteedIncomeComponent = /** @class */ (function () {
    function GuaranteedIncomeComponent(rcService, utilityService) {
        this.rcService = rcService;
        this.utilityService = utilityService;
        this.chartImage = "./assets/images/rc-guaranteed-income-for-life.png";
    }
    GuaranteedIncomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.utilityService.showLoader();
        this.rcService
            .fetchRcData()
            .pipe(take(1))
            .subscribe(function (chartData) {
            _this.utilityService.hideLoader();
            _this.takingIncomeRate = chartData.takingIncomeRate;
            _this.deferralRate = chartData.deferralRate;
            _this.date = chartData.date;
        });
    };
    return GuaranteedIncomeComponent;
}());
export { GuaranteedIncomeComponent };
