import { OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DisclosureComponent } from "./../disclosure/disclosure.component";
import { DISCLOSURE } from "../../../constants/constants";
import { FeatureFlagService } from "../../services/feature-flag.service";
var DisclosureIeComponent = /** @class */ (function () {
    function DisclosureIeComponent(translate, discloure, flagService) {
        this.translate = translate;
        this.discloure = discloure;
        this.flagService = flagService;
        this.activeIndex = 0;
        this.toolName = this.discloure.selectedTool;
    }
    DisclosureIeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translate.get("disclosure.ie").subscribe(function (disclosure) {
            _this.btns = [
                { id: 0, lable: disclosure.terms },
                { id: 1, lable: disclosure.historicalIndexReturn },
                { id: 2, lable: disclosure.diversity },
                { id: 3, lable: disclosure.getStarted },
            ];
            // to remove performanceCapTrend tab
            if (!_this.flagService.featureFlags.isIR) {
                // this.btns.splice(3, 1);
            }
        });
        this.toolSelection();
    };
    // To display div
    DisclosureIeComponent.prototype.openTab = function (id) {
        this.activeIndex = parseInt(id);
    };
    //tab change selection function
    DisclosureIeComponent.prototype.toolSelection = function () {
        if (DISCLOSURE.IE[this.toolName]) {
            this.selectedName = DISCLOSURE.IE[this.toolName];
        }
        else {
            this.selectedName = 0;
        }
        this.openTab(this.selectedName);
    };
    return DisclosureIeComponent;
}());
export { DisclosureIeComponent };
