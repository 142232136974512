import { Component, OnInit } from "@angular/core";
import {
  Theme,
  Orientation,
  Icon,
  EquitableUtilities,
} from "equitable-component-library";
import { UtilityService } from "../../../core/services/utility.service";
import { PROTECT_INCOME_TOOLS, ROUTE_PATH } from "./../../../constants/constants";

@Component({
  selector: "app-protect-income",
  templateUrl: "./protect-income.component.html",
  styleUrls: ["./protect-income.component.scss"],
})
export class ProtectIncomeComponent implements OnInit {
  // class variables
  type = Theme.Dark;
  orientation = Orientation.Left;
  backIcon = Icon.BackButton;
  IO = PROTECT_INCOME_TOOLS.IO;
  GIL = PROTECT_INCOME_TOOLS.GIL;
  RIP = PROTECT_INCOME_TOOLS.RIP;
  RB = PROTECT_INCOME_TOOLS.RB;
  activeTab = PROTECT_INCOME_TOOLS.IO;
  segments: any[];

  constructor(
    private utilityService: UtilityService,
    private equitableUtilities: EquitableUtilities
  ) {
    this.segments = [
      PROTECT_INCOME_TOOLS.IO,
      PROTECT_INCOME_TOOLS.GIL,
      PROTECT_INCOME_TOOLS.RIP,
      PROTECT_INCOME_TOOLS.RB,
    ];
  }

  ngOnInit() { }

  backButton() {
    this.utilityService.navigateToBack();
  }

  segmentChanged(event) {
    this.activeTab = event;
  }
}
