import { HttpRequestService } from "../../core/services/http-request.service";
import { API_METHODS } from "../../constants/constants";
import { environment } from "../../../environments/environment";
import { map, catchError, switchMap } from "rxjs/operators";
import { of, throwError } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "../../core/services/http-request.service";
var RcService = /** @class */ (function () {
    /***
     *  RC Service Constructor
     ***/
    function RcService(httpRequestService) {
        this.httpRequestService = httpRequestService;
    }
    /***
     *  Fetch respurces
     ***/
    RcService.prototype.fetchResources = function () {
        var _this = this;
        var baseURL = environment.serviceAPIs.baseUrl;
        if (/https/.test(environment.serviceAPIs.rc)) {
            var baseURL = "";
        }
        var requestOptions = {
            method: API_METHODS.GET,
            url: baseURL + environment.serviceAPIs.rc,
        };
        return this.httpRequestService.request(requestOptions).pipe(map(function (data) {
            _this.rc = data.body;
            return data.body;
        }), catchError(function (err) {
            throw throwError(err);
        }));
    };
    /***
     *  get RC Main Data
     ***/
    RcService.prototype.getRcData = function () {
        if (this.rc) {
            return of(this.rc);
        }
        else {
            return this.fetchResources();
        }
    };
    /***
     *  fetch the DATA from server/storage
     ***/
    RcService.prototype.fetchRcData = function () {
        var _this = this;
        return this.getRcData().pipe(switchMap(function () {
            return _this.httpRequestService
                .request({
                method: API_METHODS.GET,
                url: environment.serviceAPIs.baseUrl + 'assets/apis' + _this.rc.GuaranteedIncomeForLifeJSON,
            })
                .pipe(map(function (data) {
                return data.body;
            }), catchError(function (err) {
                throw throwError(err);
            }));
        }));
    };
    //
    /***
     *  fetch InvestTaxResource from API
     ***/
    RcService.prototype.fetchInvestTaxSmartChartData = function () {
        var _this = this;
        return this.getRcData().pipe(switchMap(function () {
            var requestOptions = {
                method: API_METHODS.GET,
                url: environment.serviceAPIs.baseUrl + 'assets/apis' + _this.rc.TaxDeferredGrowthURLJSON,
            };
            return _this.httpRequestService.request(requestOptions).pipe(map(function (data) {
                return data.body;
            }), catchError(function (err) {
                throw throwError(err);
            }));
        }));
    };
    /***
     *  fetch Resource from API
     ***/
    RcService.prototype.fetchDiversify = function () {
        var _this = this;
        return this.getRcData().pipe(switchMap(function () {
            var requestOptions = {
                method: API_METHODS.GET,
                url: environment.serviceAPIs.baseUrl + 'assets/apis' + _this.rc.DiversificationJSON,
            };
            return _this.httpRequestService.request(requestOptions);
        }));
    };
    //
    /***
     *  fetch InvestOptions Json from API
     ***/
    RcService.prototype.fetchInvestmentOptionsData = function () {
        var _this = this;
        return this.getRcData().pipe(switchMap(function () {
            var requestOptions = {
                method: API_METHODS.GET,
                url: "assets/apis/retirement-cornerstone/YourInvestmentOptions.json",
            };
            return _this.httpRequestService.request(requestOptions).pipe(map(function (data) {
                return data.body;
            }), catchError(function (err) {
                throw throwError(err);
            }));
        }));
    };
    RcService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RcService_Factory() { return new RcService(i0.ɵɵinject(i1.HttpRequestService)); }, token: RcService, providedIn: "root" });
    return RcService;
}());
export { RcService };
