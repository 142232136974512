import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { UtilizeScsComponent } from "./components/utilize-scs/utilize-scs.component";
import { ScsPiePicturesComponent } from "./components/scs-pie-pictures/scs-pie-pictures.component";
import { HistoryGuideComponent } from "./components/history-guide/history-guide.component";
import { DiscoverScsComponent } from "./components/discover-scs/discover-scs.component";
import { SioComponent } from "./components/sio/sio.component";
import { HistoricalPerformanceComponent } from "./components/historical-performance/historical-performance.component";
import { SegmentPerformanceComponent } from "./components/segment-performance/segment-performance.component";
import { MarketTrendIndicatorComponent } from "./components/market-trend-indicator/market-trend-indicator.component";
import { ScsAdvancedToolResourcesDashboardComponent } from "./components/scs-advanced-tool-resources-dashboard/scs-advanced-tool-resources-dashboard.component";
import { HowSioWorksComponent } from "./components/how-sio-works/how-sio-works.component";
import { ProtectIncomeComponent } from "./components/protect-income/protect-income.component";
import { PreserveLegacyComponent } from "./components/preserve-legacy/preserve-legacy.component";
import { ROUTE_PATH, PRODUCT_TYPE } from "../constants/constants";
import { CustomProfileComponent } from "./components/custom-profile/custom-profile.component";
import { FeatureFlagGuard } from "../ffguard";

const routes: Routes = [
  {
    path: ROUTE_PATH.SCSINCOME.TOOLS.HISTORICAL_PERFORMANCE,
    component: ScsPiePicturesComponent,
  },
  {
    path: ROUTE_PATH.SCSINCOME.TOOLS.PROTECT_INCOME,
    component: ProtectIncomeComponent,
    
  },
  {
    path: ROUTE_PATH.SCSINCOME.TOOLS.PRESERVE_LEGACY,
    component: PreserveLegacyComponent,
    
  },
  {
    path: ROUTE_PATH.SCSINCOME.DISCOVER,
    component: DiscoverScsComponent,
  },
  {
    path: ROUTE_PATH.SCSINCOME.TOOLS.MARKET_TREND,
    component: MarketTrendIndicatorComponent,
  },
  {
    path: "",
    redirectTo: "/" + ROUTE_PATH.SCSINCOME.DISCOVER,
    pathMatch: "full",
  },
  {
    path: ROUTE_PATH.SCSINCOME.TOOLS.HISTORICAL_PERFORMANCE,
    component: HistoricalPerformanceComponent,
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo:
          "/" +
          ROUTE_PATH.SCSINCOME.TOOLS.HISTORICAL_PERFORMANCE +
          "/" +
          ROUTE_PATH.SCSINCOME.TOOLS.SEGMENT_PERFORMANCE,
      },
      {
        path: ROUTE_PATH.SCSINCOME.TOOLS.SEGMENT_PERFORMANCE,
        component: SegmentPerformanceComponent,
      },
      {
        path: ROUTE_PATH.SCSINCOME.TOOLS.HISTORY_GUIDE,
        component: HistoryGuideComponent,
        canActivate: [FeatureFlagGuard],
        data: {
          flags: "usePie",
        },
      },
    ],
  },
  {
    path: ROUTE_PATH.SCSINCOME.TOOLS.HOW_SIO_WORKS_DETAILS,
    component: HowSioWorksComponent,
  },
  {
    path: ROUTE_PATH.SCSINCOME.TOOLS.HOW_SIO_WORKS,
    component: SioComponent,
  },
  {
    path: ROUTE_PATH.SCSINCOME.TOOLS.CREATE_REPORT,
    component: UtilizeScsComponent,
  },
  {
    path: ROUTE_PATH.SCSINCOME.TOOLS.HOME,
    component: ScsAdvancedToolResourcesDashboardComponent,
  },
  {
    path: ROUTE_PATH.SCSINCOME.TOOLS.HISTORY_GUIDE,
    component: HistoryGuideComponent,
  },
  {
    path: ROUTE_PATH.SCSINCOME.TOOLS.CUSTOM_PROFILE,
    component: CustomProfileComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})


export class ScsIncomeRoutingModule {}