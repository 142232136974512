import { Component, OnInit } from "@angular/core";
import { Theme, Orientation, Icon } from "equitable-component-library";
import { UtilityService } from "../../../core/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { ROUTE_PATH, BUILD_WEALTH, BUILD_WEALTH_TABS } from "./../../../constants/constants";

@Component({
  selector: "app-build-wealth",
  templateUrl: "./build-wealth.component.html",
  styleUrls: ["./build-wealth.component.scss"],
})
export class BuildWealthComponent implements OnInit {
  type = Theme.Dark;
  orientation = Orientation.Left;
  backIcon = Icon.BackButton;

  constructor(
    private utilityService: UtilityService,
    private translate: TranslateService
  ) {

  }

  ngOnInit() { }

  backButton() {
    this.utilityService.navigateWithoutParams(ROUTE_PATH.IE.TOOLS.HOME);
  }
}
