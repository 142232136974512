import { TranslateService } from "@ngx-translate/core";
import { throwError } from "rxjs";
import { HttpRequestService } from "./http-request.service";
import { map, catchError } from "rxjs/operators";
//Custom components
import { API_METHODS, PRODUCT_TYPE } from "../../constants/constants";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./http-request.service";
var GlossaryService = /** @class */ (function () {
    function GlossaryService(translateService, httpRequestService) {
        this.translateService = translateService;
        this.httpRequestService = httpRequestService;
    }
    GlossaryService.prototype.getDropdownData = function () {
        return this.translateService.get("productName").pipe(map(function (data) {
            var dropdown = [
                {
                    id: PRODUCT_TYPE.SCS,
                    value: data.scs,
                },
                {
                    id: PRODUCT_TYPE.RC,
                    value: data.rc,
                },
            ];
            return dropdown;
        }));
    };
    GlossaryService.prototype.getSearchResults = function (searchTerm, productType) {
        var url;
        switch (productType) {
            case PRODUCT_TYPE.SCS:
                url = environment.scsTerms;
                break;
            case PRODUCT_TYPE.RC:
                url = environment.rcTerms;
                break;
            default:
                url = environment.scsTerms;
                break;
        }
        var requestOptions = {
            method: API_METHODS.GET,
            url: url,
        };
        return this.httpRequestService.request(requestOptions).pipe(map(function (data) {
            return data.body.terms["en-us"].filter(function (item) {
                return item.word.toString().toLowerCase().includes(searchTerm);
            });
        }), catchError(function (err) {
            throw throwError(err);
        }));
    };
    GlossaryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GlossaryService_Factory() { return new GlossaryService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.HttpRequestService)); }, token: GlossaryService, providedIn: "root" });
    return GlossaryService;
}());
export { GlossaryService };
