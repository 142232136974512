<ion-content class="rc-tool-parent-container">
  <equitable-sub-header
    title="{{ 'rcTool.buildWealth.header' | translate }}"
    backLinkText="{{ 'rcTool.buildWealth.backLink' | translate }}"
    (backClick)="backButton()"
  ></equitable-sub-header>
  <div class="rc-tool-tab-section">
    <ion-card class="rc-tool-tab-container">
      <ion-segment
        mode="md"
        class="segment-class"
        (ionChange)="segmentChanged($event.detail.value)"
      >
        <ion-segment-button
          *ngFor="let item of segments"
          mode="md"
          class="segment-button-class"
          [value]="item"
          [checked]="activeTab === item"
        >
          <ion-label
            *ngIf="item == 'Diversify portfolio'"
            class="segment-heading"
            >{{ "buildWealthTabName.diversify" | translate }}
          </ion-label>
          <ion-label *ngIf="item == 'Invest tax smart'" class="segment-heading"
            >{{ "buildWealthTabName.investTax" | translate }}
          </ion-label>
        </ion-segment-button>
      </ion-segment>

      <app-invest-tax-smart
        *ngIf="activeTab === 'Invest tax smart'"
      ></app-invest-tax-smart>
      <equitable-diversification
        *ngIf="activeTab === 'Diversify portfolio'"
      ></equitable-diversification>
    </ion-card>
  </div>
  <app-footer
    isWhiteFooterVisible="true"
    isBlueFooterVisible="true"
    [activeTab]="selectedTab"
    selectedProduct="rc"
  ></app-footer>
</ion-content>
