import { HelpSprite } from "equitable-component-library";

export const ERROR_RESPONSE = [404, 504, 500, 400, 405];
export const CHOICE_COMMISION = 5;
export const SCS_INITIAL_INVESTMENT = 100000;
export const IE_INITIAL_INVESTMENT = 100000;
export const IE_PROFILE_KEY = "IE_Profile";
export const SCS_PROFILE_KEY = "SCS_PRofile";
export const SCSINCOME_PROFILE_KEY = "SCSIncome_Profile";
export const ADD_PROFILE = "Add Profile";
export const SITE_NAME = "EQUITABLE";

export const API_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

export const BUTTON_ROLE = {
  OK: "ok",
  CANCEL: "cancel",
};

export const INVESTOR_MODE = {
  HOME: "Home",
  PROFILE: "PROFILE",
};

export const RESPONSE_TYPE = {
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
};

export const MESSAGE_TYPE = {
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
};

export const VALIDATION_TYPE = {
  NUMERIC: "[0-9]{0,}",
  ALPHA_NUMERIC: "[A-Za-z0-9 ]+",
  EMAIL: "[A-Z0-9a-z._%+-]{2,}@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}",
  AMOUNT: "([0-9]*[.])?[0-9]+",
  ALPHA: "[a-zA-Z]+([\\s][a-zA-Z]+)*",
  MOBILENUM: "[1-9]{1}[0-9]{0,}",
};

export const MIME_TYPE = {
  XML: "text/xml",
  XML_APP: "application/xml",
  JSON: "application/json",
  JPG: "image/jpeg",
  PNG: "image/png",
  PDF: "application/pdf",
  FORM_URLENCODED: "application/x-www-form-urlencoded",
};

export const FILE_EXTENSION = {
  XML: "xml",
  JSON: "json",
  JPG: "jpg",
  JPEG: "jpeg",
  PNG: "png",
  PDF: "pdf",
  PLIST: "plist",
};

export const LANGUAGE = {
  EN: "en",
  ES: "es",
};
export const HELP_SPRITE = [
  {
    description: "Customize Report",
    url: "/home",
    name: HelpSprite.Report,
  },
  {
    description: "Find Your Investor Profile",
    url: "/home",
    name: HelpSprite.InvestorProfile,
  },
  {
    description: "Contact Us",
    url: "/home",
    name: HelpSprite.Contact,
  },
  {
    description: "View All Resources",
    url: "/home",
    name: HelpSprite.AllResources,
  },
];

export const PRODUCT_TYPE = {
  SCSINCOME: "scsincome",
  SCS: "scs",
  IE: "ie", 
  RC: "rc",
  SCS_PLUS: "PLUS",
  SCS_PLUS21: "PLUS21",
  PLUSGUARD: "PLUSGUARD"
};


export const INVESTOR_PROFILE = {
  DISRUPTER: "Disrupter",
  PRESERVER: "Preserver",
  PROTECTOR: "Protector",
  SAVER: "Saver",
  DIVERSIFIER: "Diversifier",
  EXPLORER: "Explorer",
  COORDINATOR: "Coordinator",
  RESEARCHER: "Researcher",
};

export const SCS_SERIES = {
  B: "B",
  C: "C",
  ADV: "ADV",
  PLUS: "PLUS",
  PLUS21: "PLUS21"
};
export const IE_SERIES = {
  B: "B", 
  ADV: "ADV"
};
export const SCSINCOME_SERIES = {
  B: "B",
  ADV: "ADV"
};

export const SEGMENT_TYPE = {
  STANDARD: "Standard",
  ANNUAL_LOCK: "Annual Lock",
  CHOICE: "Choice",
  STEP_UP: "Step Up",
  DUAL_DIRECTION: "Dual Direction",
  ENHANCED_UPSIDE: "Enhanced Upside",
  DUAL_STEP_UP: "Dual Step Up",
  LOSS_LIMITER_90: "Loss Limiter 90",
  LOSS_LIMITER_95: "Loss Limiter 95",
};

export const MARKET_SCENERIO = {
  UPWARD: "upward",
  DOWNWARD: "downward",
};

export const PRESERVE_LEGACY_TOOLS = {
  ROP: "rop",
  HA: "ha",
  GO: "go",
  RMD: "rmd",
};

export const BUILD_WEALTH = {
  DIVERSIFY: "Diversify portfolio",
  INVEST_TAX: "Invest tax smart",
};

export const HISTORICAL_PERFORMANCE_SEGMENT = {
  SEGMENT: "Segment performance",
  HISTORY: "History may be a guide",
};

export const PROTECT_INCOME_TOOLS = {
  GIL: "Guaranteed_income_for_life",
  RIP: "Rising_income_potential",
  IO: "Investment_options",
  RB: "Income_rate_bump",
};

export const SLIDE_OPTS = {
  initialSlide: 0,
  speed: 400,
  loop: false,
};

export const IE_TOOLS_CALCULATORS = {
  TAX_DEFERRAL: "tax-deferral",
  BREAK_EVEN: "break-even",
  TAX_COMPARISON: "tax-comparison",
  TAX_AND_INFLATION: "tax-and-inflation",
  FEDERAL_INCOME_TAX: "federal-income-tax",
};

export const PROSPECTUS_PRODUCTS_TYPE = {
  RC: "RC",
  IE: "IE",
  SCS: "SCS",
  SCSINCOME: "SCSINCOME",
  ADV: "series-ADV",
  PLUS: "series-PLUS",
  PLUS21: "series-PLUS21",
  B: "series-B",
  C: "series-C",
  PLUSGUARD: "series-PLUSGUARD"
};

export const PROSPECTUS_IFRAME_URL = {
  URL: "https://portal.equitable.com/eqhwebapp/redirect",
};

export const PROSPECTUS_OPTIONS_VALUES = {
  SCS: "28921",
  SCSINCOME: "6400",
  ADV: "54185",
  PLUS: "6022",
  PLUS21: "6200",
  PLUSGUARD: "6028",
  IE: "6888",
  RC: "1479",
  DISPLAY_TYPE: "57",
  VALUE1: "AXAEQUITABLE",
  VALUE2: "external",
  VALUE3: "RRDonnelly",
  ID1: "sourceApp",
  ID2: "ssotype",
  ID3: "appname",
};

export const CURRENCY = {
  USD: "USD",
  USD_SYMBOL: "$",
  LOCALE: "1.0",
};

//path routing for all
export const ROUTE_PATH = {
  HOME: "home",
  DISCLOSURE: "disclosure",
  DISCLOSURE_RC: "disclosure-rc",
  DISCLOSURE_SCS: "disclosure-scs",
  DISCLOSURE_SCS_INCOME: "disclosure-scsincome",
  DISCLOSURE_SCS_PLUS: "disclosure-scs-plus",
  DISCLOSURE_SCS_PLUS21: "disclosure-scs-plus21",
  DISCLOSURE_IE: "disclosure-ie",
  PROSPECTUS: "prospectus",
  QUESTIONNAIRE: "questionnaire",
  GLOSSARY: "glossary",
  SCSINCOME: {
    PRIMERICA: "primerica",
    DISCOVER: "scsincome/discover",
    TOOLS: {
      HOME: "scsincome/tools/dashboard",
      HOW_SIO_WORKS_DETAILS: "scsincome/tools/how-sio-works",
      HOW_SIO_WORKS: "scsincome/tools/how-sio-works-started",
      PERFORMANCE_CAP: "https://equitable.com/retirement/products/variable-annuities/performance-cap-rates#StructuredCapitalStrategiesIncome",
      HISTORY_GUIDE: "history-guide",
      HISTORICAL_PERFORMANCE_PLUS:
        "scsincome/tools/historical-performance/historical-performance-details",
      HISTORICAL_PERFORMANCE: "scsincome/tools/historical-performance",
      SEGMENT_PERFORMANCE: "segment-performance",
      CREATE_REPORT: "scsincome/tools/create-report",
      MARKET_TREND: "https://equitable.com/retirement/products/variable-annuities/performance-cap-rates#StructuredCapitalStrategiesIncome",
      CUSTOM_PROFILE: "scsincome/tools/custom-profile",
      PROTECT_INCOME: "scsincome/tools/protectIncome",
      PRESERVE_LEGACY: "scsincome/tools/preserve-legacy",
    },
  },
  SCS: {
    PRIMERICA: "primerica",
    DISCOVER: "scs/discover",
    TOOLS: {
      HOME: "scs/tools/dashboard",
      HOW_SIO_WORKS_DETAILS: "scs/tools/how-sio-works",
      HOW_SIO_WORKS: "scs/tools/how-sio-works-started",
      PERFORMANCE_CAP: "https://equitable.com/retirement/products/variable-annuities/performance-cap-rates",
      HISTORY_GUIDE: "history-guide",
      HISTORICAL_PERFORMANCE_PLUS:
        "scs/tools/historical-performance/historical-performance-details",
      HISTORICAL_PERFORMANCE: "scs/tools/historical-performance",
      SEGMENT_PERFORMANCE: "segment-performance",
      CREATE_REPORT: "scs/tools/create-report",
      MARKET_TREND: "https://equitable.com/retirement/products/variable-annuities/performance-cap-rates",
      INVESTOR_PROFILE: "scs/tools/investor-profile",
      CUSTOM_PROFILE: "scs/tools/custom-profile",
    },
  },
  RC: {
    DISCOVER: "rc/discover",
    TOOLS: {
      HOME: "rc/tools/dashboard",
      BUILD_WEALTH: "rc/tools/build-wealth",
      INVEST_TAX: "invest-tax",
      DIVERSIFY: "diversify",
      PROTECT_INCOME: "rc/tools/protectIncome",
      PRESERVE_LEGACY: "rc/tools/preserve-legacy",
      CREATE_REPORT: "rc/tools/create-report",
      INVESTOR_PROFILE: "rc/tools/investor-profile",
    },
  },
  IE: {
    DISCOVER: "ie/discover",
    TOOLS: {
      HOME: "ie/tools/dashboard",
      HOW_SIO_WORKS: "ie/tools/how-sio-works-started",
      HOW_SIO_WORKS_DETAILS: "ie/tools/how-sio-works",
      CREATE_REPORT: "ie/tools/create-report",
      CUSTOM_PROFILE: "scs/tools/custom-profile",
      HISTORY_GUIDE: "history-guide",
      HOW_IT_WORKS: "ie/tools/how-it-works",
      TAX_DEFERRAL: "ie/tools?tool=" + IE_TOOLS_CALCULATORS.TAX_DEFERRAL,
      BREAK_EVEN: "ie/tools?tool=" + IE_TOOLS_CALCULATORS.BREAK_EVEN,
      TAX_COMPARISON: "ie/tools?tool=" + IE_TOOLS_CALCULATORS.TAX_COMPARISON,
      TAX_AND_INFLATION:
        "ie/tools?tool=" + IE_TOOLS_CALCULATORS.TAX_AND_INFLATION,
      FEDERAL_INCOME_TAX:
        "ie/tools?tool=" + IE_TOOLS_CALCULATORS.FEDERAL_INCOME_TAX,
      INVESTOR_PROFILE: "ie/tools/investor-profile",
      DIVERSIFICATION: "ie/tools/diversification",
      HISTORICAL_PERFORMANCE_PLUS: "ie/tools/historical-performance/historical-performance-details",
      HISTORICAL_PERFORMANCE: "scs/tools/historical-performance",
      SEGMENT_PERFORMANCE: "segment-performance",
      MARKET_TREND: "https://equitable.com/retirement/products/variable-annuities/performance-cap-rates#InvestmentEdge21",
      FUND_FOCUS: "ie/tools/fund-focus",
    },
  },
};

export const DIVERSIFY = {
  t1: "t1",
  nonUSStocks: "Non-US Stocks",
  t2: "t2",
  commodities: "Commodities",
  t3: "t3",
  realEstate: "Real Estate",
  t4: "t4",
  nonUSBonds: "Non-US Bonds",
  t5: "t5",
  uSStocks: "US Stocks",
  t6: "t6",
  uSBonds: "US Bonds",
  lineColor: "#eaeaea",
};

export const HISTORICAL_PERFORMANCE = {
  INDEX_SELECTION_MAX_COUNT: 5,
  INVESTMENT: 25000,
  INVESTMENT_MAX: 10000000,
  SLIDER_MIN: 0,
  SLIDER_MAX: 100,
  PERCENTAGE_MAX: 100,
  MODE: "PRESET",
};

export const CUSTOMIZATION_TYPE = {
  REPORT: "REPORT",
  PROFILE: "PROFILE",
  INVESTOR_PROFILE: "INVESTOR_PROFILE",
};

export const REPORT_KEY = {
  HOW_SIO_WORKS: "aboutSCSHowSioWorks",
  HISTORICAL_PERFORMANCE: "historicalPerformance",
  HISTORY_GUIDE: "historyMayBeAGuide",
  HISTORICAL_PERFORMANCE_PLUS: "historyMayBeAGuidePlus"
};

export const CUSTOM_PROFILE = {
  HOW_IT_WORKS: "HOW_IT_WORKS",
  HISTORICAL_PERFORMANCE: "HISTORICAL_PERFORMANCE",
  HISTORY_GUIDE: "HISTORY_GUIDE",
  HISTORICAL_PERFORMANCE_PLUS: "HISTORICAL_PERFORMANCE_PLUS"
};

export const DISCLOSURE = {
  SCSINCOME: {
    // eslint-disable-next-line @typescript-eslint/camelcase
    How_SIO_works: 1,
    // eslint-disable-next-line @typescript-eslint/camelcase
    Historical_performance: 2,
    // eslint-disable-next-line @typescript-eslint/camelcase
    Performance_cap_trend: 3,
    // eslint-disable-next-line @typescript-eslint/camelcase
    Investor_profile: 4,
  },
  SCS: {
    // eslint-disable-next-line @typescript-eslint/camelcase
    How_SIO_works: 1,
    // eslint-disable-next-line @typescript-eslint/camelcase
    Historical_performance: 2,
    // eslint-disable-next-line @typescript-eslint/camelcase
    Performance_cap_trend: 3,
    // eslint-disable-next-line @typescript-eslint/camelcase
    Investor_profile: 4,
  },
  RC: {
    // eslint-disable-next-line @typescript-eslint/camelcase
    Diversify_portfolio: 2,
    // eslint-disable-next-line @typescript-eslint/camelcase
    Guaranteed_income_for_life: 3,
    // eslint-disable-next-line @typescript-eslint/camelcase
    Investment_options: 6,
    // eslint-disable-next-line @typescript-eslint/camelcase
    Investment_tax_smart: 7,
    // eslint-disable-next-line @typescript-eslint/camelcase
    Rising_income_potential: 9,
  },
  IE: {
    // eslint-disable-next-line @typescript-eslint/camelcase
    Historical_performance: 1,
    // eslint-disable-next-line @typescript-eslint/camelcase
    Diversify_portfolio: 2,
    // eslint-disable-next-line @typescript-eslint/camelcase
    How_SIO_works: 3,
  }
};

export const BUILD_WEALTH_TABS = {
  INVESTMENT_TAX_SMART: "Investment_tax_smart",
  DIVERSIFY_PORTFOLIO: "Diversify_portfolio",
};

export const CHARACTER_LIMIT = {
  INPUT: 45,
  TEXTAREA: 1000,
};

export const SVG = {
  PL_PRESERVE_PRINCIPAL: "preserve-principal.svg",
  PL_WEALTH_GUARD: "wealth-guard.svg",
  PL_HIGHEST_ANNIVERSARY: "highest-anniversary.svg",
  RIP_MARKET_UP_DOWN: "market-based.svg",
  RC_RIP_MARKET_UP_DOWN: "rc-market-based.svg",
  RIP_RATE_BASED: "rate-based.svg",
  RC_RIP_RATE_BASED: "rc-rate-based.svg",
  RIP_NEED_BASED: "need-based.svg",
  RC_RIP_NEED_BASED: "rc-need-based.svg",
};

export const VIEW_NAME = {
  HOME: "home",
  QUESTIONNAIRE: "questionnaire",
  PROSPECTUS: "prospectus",
  GLOSSARY: "glossary",
  DISCLOSURE: "disclosure",
  DISCOVER: "discover",
  DASHBOARD: "dashboard",
  TOOLS: "tools",
};

export const INVESTOR_PROFILE_REDIRECT = {
  IE: "Investment Edge®",
  RC: "Retirement Cornerstone®",
};

export const LABEL_COLOR = [
  "#19A982",
  "#F79425",
  "#3269FC",
  "#D35919",
  "#73BFFD",
];

export const INVESTOR_PROFILE_PAGES = [
  ROUTE_PATH.SCS.TOOLS.CREATE_REPORT,
  ROUTE_PATH.SCS.TOOLS.SEGMENT_PERFORMANCE,
  ROUTE_PATH.SCS.TOOLS.HISTORICAL_PERFORMANCE,
  ROUTE_PATH.SCS.TOOLS.HISTORICAL_PERFORMANCE +
    "/" +
    ROUTE_PATH.SCS.TOOLS.SEGMENT_PERFORMANCE,
  ROUTE_PATH.SCS.TOOLS.HISTORICAL_PERFORMANCE +
    "/" +
  ROUTE_PATH.SCS.TOOLS.HISTORY_GUIDE,
  ROUTE_PATH.SCS.TOOLS.HISTORICAL_PERFORMANCE_PLUS,
  ROUTE_PATH.SCS.TOOLS.HOW_SIO_WORKS,
  ROUTE_PATH.SCS.TOOLS.HOW_SIO_WORKS_DETAILS,
  ROUTE_PATH.SCS.TOOLS.CUSTOM_PROFILE,
  ROUTE_PATH.SCS.TOOLS.INVESTOR_PROFILE,
  
  ROUTE_PATH.SCSINCOME.TOOLS.CREATE_REPORT,
  ROUTE_PATH.SCSINCOME.TOOLS.SEGMENT_PERFORMANCE,
  ROUTE_PATH.SCSINCOME.TOOLS.HISTORICAL_PERFORMANCE,
  ROUTE_PATH.SCSINCOME.TOOLS.HISTORICAL_PERFORMANCE +
    "/" +
    ROUTE_PATH.SCSINCOME.TOOLS.SEGMENT_PERFORMANCE,
  ROUTE_PATH.SCSINCOME.TOOLS.HISTORICAL_PERFORMANCE +
    "/" +
  ROUTE_PATH.SCSINCOME.TOOLS.HISTORY_GUIDE,
  ROUTE_PATH.SCSINCOME.TOOLS.HISTORICAL_PERFORMANCE_PLUS,
  ROUTE_PATH.SCSINCOME.TOOLS.HOW_SIO_WORKS,
  ROUTE_PATH.SCSINCOME.TOOLS.HOW_SIO_WORKS_DETAILS,
  ROUTE_PATH.SCSINCOME.TOOLS.CUSTOM_PROFILE,
  
  ROUTE_PATH.IE.TOOLS.CREATE_REPORT,
  ROUTE_PATH.IE.TOOLS.SEGMENT_PERFORMANCE,
  ROUTE_PATH.IE.TOOLS.HISTORICAL_PERFORMANCE,
  ROUTE_PATH.IE.TOOLS.HISTORICAL_PERFORMANCE +
    "/" +
    ROUTE_PATH.IE.TOOLS.SEGMENT_PERFORMANCE,
  ROUTE_PATH.IE.TOOLS.HISTORICAL_PERFORMANCE +
    "/" +
  ROUTE_PATH.IE.TOOLS.HISTORY_GUIDE,
  ROUTE_PATH.IE.TOOLS.HISTORICAL_PERFORMANCE_PLUS,
  ROUTE_PATH.IE.TOOLS.HOW_SIO_WORKS,
  ROUTE_PATH.IE.TOOLS.HOW_SIO_WORKS_DETAILS,
  ROUTE_PATH.IE.TOOLS.CUSTOM_PROFILE,
];

export const CONFIG_DATA = {
  ISIR: "isIR",
  USEPIE: "usePie",
  ISPLUSGUARD: "isPlusGuard",
  ISNATIONAL: "isNational"
};

export const TOOLS_NAME = {
  PROTECT_INCOME: "protectIncome",
  HOW_SIO_WORKS: "howSIOWorks",
  HISTORICAL_PERFORMANCE: "historicalPerformance",
  PERFORMANCE_CAP_RATE: "performanceCapRate",
  CREATE_A_REPORT: "createAReport",
  PRODUCT_OVERVIEW: "productOverview",
  INVESTOR_PROFILE: "investorProfile",
  PRESERVE_LEGACY: "preserveLegacy",
  FUND_FOCUS: "fundfocus",
};

export const PRODUCT_NAME = {
  SCSINCOME: "Structured Capital Strategies<sup>®</sup> Income",
  SCS: "Structured Capital Strategies<sup>®</sup>",
  RC: "Retirement Cornerstone<sup>®</sup>",
  IE: "Investment Edge<sup>®</sup>",
};
