import { TOOLS_NAME } from "./../../../constants/constants";
import { FeatureFlagService } from "./../../../core/services/feature-flag.service";
import { OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { LinkType, LinkIcon, Orientation, BoxButtonIcon, } from "equitable-component-library";
import { Profile, } from "../../../core/models/app.models";
import { UtilityService } from "../../../core/services/utility.service";
import { FormControl, Validators, FormBuilder, } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { SCS_SERIES, ROUTE_PATH, PRODUCT_TYPE, CUSTOMIZATION_TYPE, CONFIG_DATA, } from "../../../constants/constants";
import { SITE } from "src/assets/sites";
import { ScsService } from "../../services/scs.service";
import { ModalPopupComponent } from "../../../core/components/modal-popup/modal-popup.component";
import { CoreService } from "../../../core/services/core.service";
import { TealiumUtagService } from '../../../tealium/utag.service';
var ScsAdvancedToolResourcesDashboardComponent = /** @class */ (function () {
    function ScsAdvancedToolResourcesDashboardComponent(utilityService, translate, scsService, flagService, coreService, formBuilder, location, tealium) {
        this.utilityService = utilityService;
        this.translate = translate;
        this.scsService = scsService;
        this.flagService = flagService;
        this.coreService = coreService;
        this.formBuilder = formBuilder;
        this.location = location;
        this.tealium = tealium;
        this.LinkType = LinkType;
        this.LinkIcon = LinkIcon;
        this.Orientation = Orientation;
        this.isDisplay = true;
        this.isIR = CONFIG_DATA.ISIR;
        this.toolDashboardData = {
            tools: [],
        };
        this.site = SITE;
        // SET DEFAULT BASED ON SERIES
        if (this.flagService.featureFlags.isPrimerica) {
            this.getSeries = SCS_SERIES.PLUS21;
        }
        // else if (we are plusguard, we want to default to PLUS, not PLUS 21)
        else if (this.flagService.featureFlags.isPlusGuard) {
            this.getSeries = SCS_SERIES.PLUS;
        }
        else {
            this.getSeries = SCS_SERIES.PLUS21;
        }
    }
    ScsAdvancedToolResourcesDashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        // FORCE SERIES DEFAULT ON LOAD
        this.scsService.series = this.getSeries;
        this.dashboardForm = this.formBuilder.group({
            seriesDropDown: new FormControl({ value: this.getSeries, disabled: false }, Validators.required),
        });
        //reset selection
        if (this.coreService.product !== PRODUCT_TYPE.SCS) {
            this.coreService.profile.next({
                name: "",
                type: CUSTOMIZATION_TYPE.REPORT,
                profile: new Profile(),
            });
            this.coreService.report = new Profile();
        }
        this.coreService.product = PRODUCT_TYPE.SCS;
        this.dashboardForm.valueChanges.subscribe(function (data) {
            //reset report data (if any)
            _this.scsService.report = new Profile();
            _this.scsService.customizationType = CUSTOMIZATION_TYPE.REPORT;
            //reset selected profile
            _this.scsService.currentProfile = "";
            _this.dropDownSection(data.seriesDropDown);
            // FORCE SERIES SWAP WHEN DROPDOWN SELECTED
            _this.scsService.series = data.seriesDropDown;
            _this.coreService.profile.next({
                name: "",
                type: CUSTOMIZATION_TYPE.REPORT,
                profile: new Profile(),
            });
            var tool = _this.toolDashboardData.tools.find(function (t) { return t.icon === BoxButtonIcon.PerformanceCapRates; });
            if (tool) {
                tool.routePath = _this.mtiURL;
                console.log(_this.mtiURL);
            }
        });
        // Dropdown Data
        this.translate.get("scsDashbaord").subscribe(function (translated) {
            _this.toolTipText = translated.tooltipText;
            _this.investorProfiles = translated.investorProfiles;
            _this.seriesOptions = [];
            if (_this.flagService.featureFlags.isPrimerica) {
                _this.seriesOptions = [
                    {
                        id: SCS_SERIES.PLUS21,
                        value: translated.seriesPlus21,
                    },
                    {
                        id: SCS_SERIES.PLUS,
                        value: translated.seriesPlus,
                    },
                    {
                        id: SCS_SERIES.B,
                        value: translated.seriesB,
                    },
                ];
            }
            else {
                _this.seriesOptions = [
                    {
                        id: SCS_SERIES.PLUS21,
                        value: translated.seriesPlus21,
                    },
                    {
                        id: SCS_SERIES.PLUS,
                        value: translated.seriesPlus,
                    },
                    {
                        id: SCS_SERIES.B,
                        value: translated.seriesB,
                    },
                    {
                        id: SCS_SERIES.C,
                        value: translated.seriesC,
                    },
                    {
                        id: SCS_SERIES.ADV,
                        value: translated.seriesAdv,
                    },
                ];
            }
            _this.isNotPlusGuard = !_this.flagService.featureFlags.isPlusGuard;
            for (var toolName in _this.flagService.featureFlags.scs.tools) {
                switch (toolName) {
                    case TOOLS_NAME.HOW_SIO_WORKS:
                        if (_this.flagService.featureFlags.scs.tools[TOOLS_NAME.HOW_SIO_WORKS]) {
                            _this.toolDashboardData.tools.push({
                                icon: BoxButtonIcon.HowSIOWorks,
                                label: translated.howItWorks,
                                routePath: ROUTE_PATH.SCS.TOOLS.HOW_SIO_WORKS,
                            });
                        }
                        break;
                    case TOOLS_NAME.HISTORICAL_PERFORMANCE:
                        if (_this.flagService.featureFlags.scs.tools[TOOLS_NAME.HISTORICAL_PERFORMANCE]) {
                            //console.log('CARDCLICK this.scsService.series', this.scsService.series)
                            //console.log('CARDCLICK SCS_SERIES.PLUS', SCS_SERIES.PLUS)
                            //console.log('CARDCLICK SCS_SERIES.PLUS21', SCS_SERIES.PLUS21)
                            _this.toolDashboardData.tools.push({
                                icon: BoxButtonIcon.HistoricalPerformance,
                                label: translated.historicalPerformance,
                                // SET DEFAULT URL
                                //routePath: "scs/tools/historical-performance/historical-performance-details?section=scs&series=PLUS21",
                                //routePath: SCS_SERIES.PLUS21 ? ROUTE_PATH.SCS.TOOLS.HISTORICAL_PERFORMANCE_PLUS : "/",
                                routePath: ROUTE_PATH.SCS.TOOLS.HISTORICAL_PERFORMANCE_PLUS,
                            });
                        }
                        break;
                    case TOOLS_NAME.PERFORMANCE_CAP_RATE:
                        if (_this.flagService.featureFlags.scs.tools[TOOLS_NAME.PERFORMANCE_CAP_RATE]) {
                            if (_this.getSeries == SCS_SERIES.PLUS) {
                                _this.mtiURL = _this.flagService.featureFlags.marketTrendIndicator_PLUS;
                            }
                            else if (_this.getSeries == SCS_SERIES.PLUS21) {
                                _this.mtiURL = _this.flagService.featureFlags.marketTrendIndicator_PLUS21;
                            }
                            else {
                                _this.mtiURL = _this.flagService.featureFlags.marketTrendIndicator;
                            }
                            _this.toolDashboardData.tools.push({
                                icon: BoxButtonIcon.PerformanceCapRates,
                                label: translated.performanceCapRate,
                                routePath: _this.mtiURL,
                                newTab: true,
                            });
                        }
                        break;
                    case TOOLS_NAME.CREATE_A_REPORT:
                        if (_this.flagService.featureFlags.scs.tools[TOOLS_NAME.CREATE_A_REPORT]) {
                            _this.toolDashboardData.tools.push({
                                icon: BoxButtonIcon.CreateAReport,
                                label: translated.createReport,
                                routePath: ROUTE_PATH.SCS.TOOLS.CREATE_REPORT,
                            });
                        }
                        break;
                    case TOOLS_NAME.PRODUCT_OVERVIEW:
                        if (_this.flagService.featureFlags.scs.tools[TOOLS_NAME.PRODUCT_OVERVIEW]) {
                            _this.toolDashboardData.tools.push({
                                icon: BoxButtonIcon.ProductOverview,
                                label: translated.productOverview,
                                routePath: ROUTE_PATH.SCS.DISCOVER,
                            });
                        }
                        break;
                    case TOOLS_NAME.INVESTOR_PROFILE:
                        if (_this.flagService.featureFlags.scs.tools[TOOLS_NAME.INVESTOR_PROFILE]) {
                            _this.toolDashboardData.tools.push({
                                icon: BoxButtonIcon.InvestorProfiles,
                                label: translated.investorProfiles,
                                routePath: ROUTE_PATH.SCS.TOOLS.INVESTOR_PROFILE,
                            });
                        }
                        break;
                    default:
                        break;
                }
            }
            if (_this.getSeries === SCS_SERIES.PLUS21) {
                _this.headingName = translated.headerTitlePlus21;
            }
            else if (_this.getSeries === SCS_SERIES.PLUS) {
                _this.headingName = translated.headerTitlePlus;
            }
            else {
                _this.headingName = translated.headerTitle;
            }
        });
        //this.dropDownSection(this.getSeries);
        if (this.getSeries === SCS_SERIES.PLUS21) {
            this.isDisplay = true;
            this.headingName = this.translate.instant("scsDashbaord.headerTitlePlus21");
        }
        else if (this.getSeries === SCS_SERIES.PLUS) {
            this.isDisplay = true;
            this.headingName = this.translate.instant("scsDashbaord.headerTitlePlus");
        }
        else {
            this.isDisplay = false;
            this.headingName = this.translate.instant("scsDashbaord.headerTitle");
        }
        // Learning Resources
        this.getResourcesData(this.getSeries);
    };
    // display Benefits
    ScsAdvancedToolResourcesDashboardComponent.prototype.dropDownSection = function (data) {
        if (data === SCS_SERIES.PLUS21) {
            this.isDisplay = true;
            this.getResourcesData(SCS_SERIES.PLUS21);
            this.headingName = this.translate.instant("scsDashbaord.headerTitlePlus21");
            this.mtiURL = this.flagService.featureFlags.marketTrendIndicator_PLUS21;
        }
        else if (data === SCS_SERIES.PLUS) {
            this.isDisplay = false;
            this.getResourcesData(SCS_SERIES.PLUS);
            this.headingName = this.translate.instant("scsDashbaord.headerTitlePlus");
            this.mtiURL = this.flagService.featureFlags.marketTrendIndicator_PLUS;
        }
        else if (data === SCS_SERIES.B) {
            this.isDisplay = false;
            this.getResourcesData(SCS_SERIES.B);
            this.headingName = this.translate.instant("scsDashbaord.headerTitle");
            this.mtiURL = this.flagService.featureFlags.marketTrendIndicator;
        }
        else if (data === SCS_SERIES.C) {
            this.isDisplay = false;
            this.getResourcesData(SCS_SERIES.C);
            this.headingName = this.translate.instant("scsDashbaord.headerTitle");
            this.mtiURL = this.flagService.featureFlags.marketTrendIndicator;
        }
        else if (data === SCS_SERIES.ADV) {
            this.isDisplay = false;
            this.getResourcesData(SCS_SERIES.ADV);
            this.headingName = this.translate.instant("scsDashbaord.headerTitle");
            this.mtiURL = this.flagService.featureFlags.marketTrendIndicator;
        }
        //set series in session
        this.scsService.series = "";
        this.scsService.investorData.index = "";
        this.scsService.investorData.segment = "";
        this.scsService.investorData.duration = "";
        this.scsService.investorData.protection = "";
        if (data === SCS_SERIES.PLUS21)
            this.toolDashboardData.tools[1].routePath =
                ROUTE_PATH.SCS.TOOLS.HISTORICAL_PERFORMANCE_PLUS;
        else if (data === SCS_SERIES.PLUS)
            this.toolDashboardData.tools[1].routePath =
                ROUTE_PATH.SCS.TOOLS.HISTORICAL_PERFORMANCE_PLUS;
        else
            this.toolDashboardData.tools[1].routePath =
                "/" + ROUTE_PATH.SCS.TOOLS.HISTORICAL_PERFORMANCE;
    };
    // Learning Resources
    ScsAdvancedToolResourcesDashboardComponent.prototype.getResourcesData = function (data) {
        var _this = this;
        this.scsService.getResources(data).subscribe(function (kits) {
            _this.learningResources = kits;
        });
    };
    // On click Card button
    ScsAdvancedToolResourcesDashboardComponent.prototype.cardClick = function (link, label, newTab) {
        if (newTab) {
            window.open(link, '_blank');
        }
        else {
            if (label === this.investorProfiles) {
                this.utilityService.presentWithoutTitleModal(ModalPopupComponent);
            }
            else {
                this.utilityService.navigate(link, {
                    section: "scs", series: this.scsService.series
                });
            }
        }
    };
    // openExternalLink
    ScsAdvancedToolResourcesDashboardComponent.prototype.goToResources = function (resource) {
        this.tealium.link({
            tealium_event: 'link',
            event_category: 'BodyClick',
            event_name: 'Retirement Guide Clicks',
            event_label: 'Click on ' + resource.description
        });
        this.utilityService.visitEquitable(resource.file);
    };
    // back button navigation
    ScsAdvancedToolResourcesDashboardComponent.prototype.backButton = function () {
        //this.location.back()
        if (this.flagService.featureFlags.isIR) {
            this.utilityService.navigate(ROUTE_PATH.HOME);
        }
        else {
            this.utilityService.navigate(ROUTE_PATH.HOME);
        }
    };
    ScsAdvancedToolResourcesDashboardComponent.prototype.goToIeLandingPage = function () {
        this.utilityService.navigateWithoutParams(ROUTE_PATH.IE.TOOLS.HOME);
    };
    ScsAdvancedToolResourcesDashboardComponent.prototype.goToScsIncomeLandingPage = function () {
        this.utilityService.navigateWithoutParams(ROUTE_PATH.SCSINCOME.TOOLS.HOME);
    };
    ScsAdvancedToolResourcesDashboardComponent.prototype.goToRcLandingPage = function () {
        this.utilityService.navigateWithoutParams(ROUTE_PATH.RC.TOOLS.HOME);
    };
    ScsAdvancedToolResourcesDashboardComponent.prototype.goToScsLandingPage = function () {
        this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER + '?series=scs16');
    };
    ScsAdvancedToolResourcesDashboardComponent.prototype.goToPlus21LandingPage = function () {
        this.utilityService.navigate(ROUTE_PATH.SCS.DISCOVER + '?series=PLUS21');
    };
    // visit external site Equitable.com
    ScsAdvancedToolResourcesDashboardComponent.prototype.visitEquitable = function (url) {
        this.utilityService.visitEquitableMicrosite(url);
    };
    return ScsAdvancedToolResourcesDashboardComponent;
}());
export { ScsAdvancedToolResourcesDashboardComponent };
