/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../node_modules/equitable-component-library/src/lib/link/link.component.ngfactory";
import * as i4 from "equitable-component-library/src/lib/link/link.component";
import * as i5 from "equitable-component-library/src/lib/equitable-utilities";
import * as i6 from "../../../../../node_modules/equitable-component-library/src/lib/footer/footer.component.ngfactory";
import * as i7 from "equitable-component-library/src/lib/footer/footer.component";
import * as i8 from "@angular/router";
import * as i9 from "@ionic/angular";
import * as i10 from "@angular/common";
import * as i11 from "./footer.component";
import * as i12 from "../../services/core.service";
import * as i13 from "../../services/utility.service";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
function View_FooterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "profile"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "equitable-link", [["class", "equitable-link header-link-hide"], ["orientation", "left"], ["type", "dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToProfile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_LinkComponent_0, i3.RenderType_LinkComponent)), i1.ɵdid(4, 114688, null, 0, i4.LinkComponent, [i5.EquitableUtilities], { orientation: [0, "orientation"], text: [1, "text"], type: [2, "type"] }, null), (_l()(), i1.ɵted(-1, null, [" | "])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "equitable-link", [["class", "equitable-link header-link-hide"], ["orientation", "left"], ["type", "dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideProfile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_LinkComponent_0, i3.RenderType_LinkComponent)), i1.ɵdid(7, 114688, null, 0, i4.LinkComponent, [i5.EquitableUtilities], { orientation: [0, "orientation"], text: [1, "text"], type: [2, "type"] }, null), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = "left"; var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.profileName, ""); var currVal_3 = "dark"; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = "left"; var currVal_5 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 7, 1, i1.ɵnov(_v, 8).transform("general.hide")), ""); var currVal_6 = "dark"; _ck(_v, 7, 0, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("general.profileHead")); _ck(_v, 1, 0, currVal_0); }); }
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "equitable-footer", [], null, null, null, i6.View_FooterComponent_0, i6.RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i7.FooterComponent, [i8.Router, i5.EquitableUtilities, i9.Platform], { footer: [0, "footer"], isWhiteFooterVisible: [1, "isWhiteFooterVisible"], isBlueFooterVisible: [2, "isBlueFooterVisible"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_1)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.footer; var currVal_1 = _co.isWhiteFooterVisible; var currVal_2 = _co.isBlueFooterVisible; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (_co.profileName != ""); _ck(_v, 3, 0, currVal_3); }, null); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 638976, null, 0, i11.FooterComponent, [i8.Router, i12.CoreService, i13.UtilityService, i9.Platform], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i11.FooterComponent, View_FooterComponent_Host_0, { isWhiteFooterVisible: "isWhiteFooterVisible", isBlueFooterVisible: "isBlueFooterVisible", activeTab: "activeTab", selectedProduct: "selectedProduct" }, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
